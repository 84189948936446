/* eslint-disable no-unused-vars */
import React from "react";

import GoogleMapReact from "google-map-react";

const apiIsLoaded = (map, maps, center) => {
  const circle = new maps.Circle({
    strokeColor: "#07A05D",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#07A05D",
    fillOpacity: 0.3,
    map,
    center: center,
    radius: 60000,
  });

  // const marker = new maps.Marker({
  //   position: center,
  //   map,
  //   title: "Pickup Location",
  // });
};

const GoogleMap = ({ item, className }) => {
  const center = {
    lat: item?.pickupLocation?.geometry?.location?.lat,
    lng: item?.pickupLocation?.geometry?.location?.lng
  }
  return (
    <>
      {
        item?.pickupLocation?.geometry?.location && <div className={`${className || "w-full h-96"} mt-8 lg:mt-0`}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_APIKEY }}
            defaultCenter={center}
            defaultZoom={7}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, center)}
          />
        </div>
      }
    </>
  );
};

export default GoogleMap;
