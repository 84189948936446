/* eslint-disable no-unused-vars */
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, } from "react-router-dom";
import store from "store";
import Loader from "../../components/blocks/Loader";
import Header from "../../components/layout/Header";
import AlertModal from "../../components/modals/AlertModal";
import { compressImage } from "../../helpers/compressImage";
import * as actions from "../../store/actions";
import LeftSideContainer from "./LeftSideContainer";
import UploadBorrowerDetailMobile from "./components/UploadBorrowerDetailMobile";
import UploadGoBackLink from "./components/UploadGoBackLink";
import UploadImagesForm from "./components/UploadImagesForm";
import UploadSelectSerialNumbers from "./components/UploadSelectSerialNumbers";
import UploadTitlAndProgressIndicator from "./components/UploadTitlAndProgressIndicator";

const UploadItemImages = (props) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (step === "step2") {
      setStep("step1");
    } else {
      navigate.goBack();
    }
  };

  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [itemDetails, setItemDetails] = useState({});
  const [loggedInUserDetails, setLoggedInUserDetails] = useState({});
  const [availableSerialNumbers, setAvailableSerialNumbers] = useState([]);
  const [
    selectedAvailableSerialNumbers,
    setSelectedAvailableSerialNumbers,
  ] = useState([]);
  const [images, setImages] = useState([""]);
  const [displayImages, setDisplayImages] = useState([""]);

  const [step, setStep] = useState("step1");
  const handleProceed = () => {
    setStep("step2");
  };

  useEffect(() => {
    setLoggedInUserDetails(store.get("temp_usER12_dATa"));
    const queryString = qs.parse(props.location.search);
    const orderId = queryString?.id;
    const itemId = queryString?.itemId;

    fetchOrderDetails(orderId, itemId);
  }, []);

  const fetchOrderDetails = async (orderId, itemId) => {
    setLoading(true);
    Promise.all([
      await props.getSingleItemApi(itemId),
      await props.getSingleOrderApi(orderId),
      await props.getSerialNumberApi(itemId),
    ]);
    setLoading(false);
  };

  useEffect(() => {
    setOrderDetails(props.order);
    if (props?.order?.quantity) {
      const emptyArrayList = Array(4).fill("");
      setImages(emptyArrayList);
      setDisplayImages(emptyArrayList);
    }
  }, [props.order]);

  useEffect(() => {
    setItemDetails(props.item);
  }, [props.item]);

  useEffect(() => {
    setAvailableSerialNumbers(props.availableSerialNumbers);
  }, [props.availableSerialNumbers]);

  const handleImageChange = (e, key) => {
    // compress image
    compressImage(e, (data) => {
      images[key] = data;
      setImages([...images]);
    });

    var reader = new FileReader();
    reader.onload = function (e) {
      displayImages[key] = e.target.result;
      setDisplayImages([...displayImages]);
    };
    reader.readAsDataURL(e[0]);
  };

  const handleRemoveImage = (key) => {
    images[key] = "";
    setImages([...images]);

    displayImages[key] = "";
    setDisplayImages([...displayImages]);
  };

  const handleAddMoreImage = () => {
    setImages([...images, ""]);
    setDisplayImages([...displayImages, ""]);
  };

  const handleAddOrRemoveSerialNumber = (value) => {
    setSelectedAvailableSerialNumbers((prev) => {
      if (prev) {
        if (prev.includes(value)) {
          return prev.filter((a) => a !== value);
        } else if (prev.length >= orderDetails.quantity) {
          const Prev = [...prev];
          Prev.pop();
          return [...Prev, value];
        } else return [...prev, value];
      }
      return [value];
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      const filterImages = images?.filter((a) => a !== "");
      const formData = new FormData();
      for (const key in filterImages) {
        if (Object.hasOwnProperty.call(filterImages, key)) {
          formData.append("images", filterImages[key]);
        }
      }

      const serialNumbersPayload = {
        serialNumbers: selectedAvailableSerialNumbers,
      };

      await Promise.all([
        await props.uploadLenderItemImagesForOrderApi(
          orderDetails?._id,
          formData
        ),
        selectedAvailableSerialNumbers?.length !== 0 &&
          (await props.setSerialNumbersForOrderApi(
            orderDetails?._id,
            serialNumbersPayload
          )),
      ]);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  return (
    <div className={`w-full ${"bg-white"}`}>
      <div className={"container mx-auto px-4"}>
        <div className="flex flex-row flex-wrap">
          {loading ? (
            <div className="mx-auto h-screen flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <>
              <LeftSideContainer
                itemDetails={itemDetails}
                orderDetails={orderDetails}
              />

              <div className="lg:px-20 lg:py-20 overflow-auto flex-1">
                {/* Mobile only */}
                <div className="lg:hidden block">
                  <Header />
                </div>

                <UploadGoBackLink handleGoBack={handleGoBack} />

                <div className="mt-8 lg:mt-10">
                  <UploadTitlAndProgressIndicator
                    progressPercent={step == "step1" ? 30 : 80}
                    title={
                      step == "step1"
                        ? "Upload Images"
                        : `Select ${orderDetails.quantity} Serial Number(s)`
                    }
                    subTitle={
                      step == "step1"
                        ? "Kindly upload the images of the item(s) you are renting out"
                        : "Please select the serial number of the item you are giving out"
                    }
                  />

                  {step === "step1" ? (
                    <UploadImagesForm
                      orderDetails={orderDetails}
                      displayImages={displayImages}
                      handleImageChange={handleImageChange}
                      handleRemoveImage={handleRemoveImage}
                      handleProceed={handleProceed}
                      handleAddMoreImage={handleAddMoreImage}
                    />
                  ) : (
                    <UploadSelectSerialNumbers
                      selectedAvailableSerialNumbers={
                        selectedAvailableSerialNumbers
                      }
                      orderQuantity={orderDetails.quantity}
                      availableSerialNumbers={availableSerialNumbers}
                      handleAddOrRemoveSerialNumber={
                        handleAddOrRemoveSerialNumber
                      }
                      handleSubmit={handleSubmit}
                      submitLoading={submitLoading}
                      orderDetails={orderDetails}
                    />
                  )}
                </div>

                <UploadBorrowerDetailMobile
                  itemDetails={itemDetails}
                  orderDetails={orderDetails}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Trigger Message */}
      {props.alert.message && !submitLoading && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl={`/rental-activities/order/?id=${orderDetails._id}&itemId=${itemDetails._id}`}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  order: state.order.order,
  item: state.items.item,
  availableSerialNumbers: state.items.availableSerialNumbers,
  alert: state.alert,
});

export default (connect(mapStateToProps, actions)(UploadItemImages));
