import React from "react";
import CardImage from "../../../assets/images/png/bigframe.jpg";
import EquipmentLocation from "../../../components/blocks/itemBits/EquipmentLocation";
import EquipmentName from "../../../components/blocks/itemBits/EquipmentName";
import StarRating from "../../../components/blocks/StarRating";

const UploadItemDetails = ({ itemDetails, orderDetails }) => {
  return (
    <div className="flex flex-wrap flex-row mt-44">
      <div className="relative w-32 h-24 rounded-xl bg-primary object-cover">
        <img
          src={itemDetails?.images?.[0]?.image?.url || CardImage}
          className="object-cover w-32 h-24 rounded-xl"
          alt=""
        />

        <div className="px-2 pt-1 text-xs absolute top-2 right-2 rounded-md bg-temp-green-500 font-semibold text-white">
          {orderDetails?.quantity}
        </div>
      </div>
      <div className="pl-6 flex-1">
        <div className="">
          <StarRating starDimension="14px" rating={itemDetails?.rating} />
        </div>
        <EquipmentName item={itemDetails} textClassName="text-base md:text-lg xl:text-xl" />
        <div className="-mt-2">
        <EquipmentLocation item={itemDetails} className="" />
        </div>
       
        <div className="w-9/12 mb-3 text-sm lg:text-sm-15 xl:text-sm-15 text-temp-secondary flex justify-between">
            <p className="">Order#</p>
            <p className="pl-1 font-bold">{orderDetails?.orderNumber}</p>
          </div>
      </div>
    </div>
  );
};

export default UploadItemDetails;
