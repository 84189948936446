import React from "react";
import { Transition } from "@headlessui/react";

const InfoModal = ({ show, handleShow, content, subText }) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <button
            onClick={() => handleShow(false)}
            className="absolute text-temp-green-500 text-lg rounded-full shadow-lg  pt-1 px-2 right-4 sm:right-8 top-2 sm:top-4 focus:outline-none"
          >
            x
          </button>
          <div className="bg-white pt-5 sm:pt-12">
            <div className="text-center px-5 sm:px-12">
              <div className="mt-8 text-left">
                <h3
                  className="leading-6 text-lg md:text-xl font-medium text-temp-primary"
                  id="modal-headline"
                >
                  Why we need this?
                </h3>
                <div className="mt-6">
                  <p className="text-sm md:text-base font-sans text-temp-gray">
                    {content}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 w-full">
              <p className="text-sm md:text-sm-15 font-sans py-4 bg-temp-green-100 text-temp-green-500 px-5 sm:px-12">
                {subText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InfoModal;
