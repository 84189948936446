import { Transition } from "@headlessui/react";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router-dom";
import InputGroup2 from "../../../components/element/InputGroup2";
import AlertModal from "../../../components/modals/AlertModal";
import * as actions from "../../../store/actions";

const AddBankDetailsModal = ({
  show,
  handleCloseModal,
  ...props
}) => {
  const initialState = {
    accountNumber: "",
    bankCode: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.accountNumber) {
      errors.accountNumber = "Required";
    } else if (!/.*?(\d)[^\d]*/g.test(values.accountNumber)) {
      errors.accountNumber = "Input must be a number";
    }

    if (!values.bankCode) {
      errors.bankCode = "Select your bank";
    }

    return errors;
  };

  const [banks, setBanks] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [bankFetchingError, setBankFetchingError] = useState("");

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = async () => {
    await axios
      .get("https://api.paystack.co/bank")
      .then((data) => setBanks(data.data.data))
      .catch((e) => setBankFetchingError(e));
  };

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="text-center">
                <h3
                  className="leading-6 text-2xl md:text-2xl font-medium text-temp-secondary"
                  id="modal-headline"
                >
                  Add Bank Details
                </h3>
                <div className="mt-10">
                  <Formik
                    initialValues={initialState}
                    validate={validate}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);

                      const payload = {
                        accountNumber: values.accountNumber,
                        bankCode: values.bankCode,
                        bankName: banks.find((a) => a.code === values.bankCode)
                          ?.name,
                      };

                      await props.addBankApi(payload, props.history);

                      handleCloseModal();

                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="w-full text-left">
                            <InputGroup2
                              type="text"
                              label="Account Number"
                              name="accountNumber"
                              value={values.accountNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.accountNumber &&
                                touched.accountNumber &&
                                errors.accountNumber
                              }
                            />
                          </div>
                          <div className="w-full text-left">
                            <label
                              htmlFor="bankCode"
                              className="text-temp-primary"
                            >
                              Bank
                            </label>
                            <select
                              name="bankCode"
                              value={values.bankCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`mt-2 rounded-lg placeholder-temp-gray text-sm sm:text-base w-full h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-temp-primary focus:outline-none bg-white border ${
                                errors.bankCode
                                  ? "border-red-600"
                                  : "border-temp-gray-light"
                              }`}
                            >
                              <option>Select Bank</option>
                              {banks?.map((bank) => (
                                <option key={bank.id} value={bank.code}>
                                  {bank.name}
                                </option>
                              ))}
                            </select>
                            <span className="text-red-600 text-sm pt-2">
                              {errors.bankCode &&
                                touched.bankCode &&
                                errors.bankCode}
                            </span>
                          </div>
                        </div>

                        <div className="flex mt-8 items-baseline justify-end flex-end">
                          <button
                            onClick={handleCloseModal}
                            className="text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="mt-5 mb-4 py-3 px-8 text-sm bg-temp-green-500 bg-gradient font-bold text-white rounded-lg focus:outline-none"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    banks: state.banks,
    alert: state.alert,
  };
};

export default (
  connect(mapStateToProps, actions)(AddBankDetailsModal)
);
