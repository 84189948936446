import React from 'react';

const InputGroupWithPrefix = ({
    className,
    label,
    error,
    prefixValue,
    name,
    value,
    type,
    onChange,
    ...props
}) => {
    return <div className={className || "group w-full pt-3"}>
        <div className="mb-8 ">
            <label className="text-temp-primary text-sm lg:text-base">
                {label}
            </label>
            <div className="relative input input-field mb-2 mt-3">
                <div className={`flex flex-row custom-input-prefix-wrapper border bg-white text-temp-secondary text-sm lg:text-base ${error
                    ? "border-red-600"
                    : " border-temp-gray-light"
                    }`}>
                    <div className="custom-input-prefix text-temp-secondary text-sm lg:text-sm h-14">
                        {prefixValue}
                    </div>
                    <input
                        autoComplete="off"
                        name={name}
                        value={value}
                        type={type || "number"}
                        onChange={onChange}
                        className={`custom-input-prefix-field  block px-5 h-14   placeholder-temp-secondary focus:outline-none`}
                        onWheel={() => {
                            return false;
                        }}
                        {...props}
                    />
                </div>
                <span className="text-red-600 text-xs">
                    {error}
                </span>
            </div>
        </div>
    </div>
};

export default InputGroupWithPrefix;
