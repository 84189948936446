import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, } from "react-router-dom";
import GoBackButton from "../../../components/blocks/GoBackButton";
import Loader from "../../../components/blocks/Loader";
import EquipmentDescription from "../../../components/blocks/itemBits/EquipmentDescription";
import EquipmentDiscountDetails from "../../../components/blocks/itemBits/EquipmentDiscountDetails";
import EquipmentFeatures from "../../../components/blocks/itemBits/EquipmentFeatures";
import EquipmentImageGallery from "../../../components/blocks/itemBits/EquipmentImageGallery";
import EquipmentLocationAndRating from "../../../components/blocks/itemBits/EquipmentLocationAndRating";
import EquipmentName from "../../../components/blocks/itemBits/EquipmentName";
import EquipmentProtect from "../../../components/blocks/itemBits/EquipmentProtect";
import MultipleDatePicker from "../../../components/element/MultipleDatePicker";
import MainContainer from "../../../components/layout/MainContainer";
import AlertModal from "../../../components/modals/AlertModal";
import AlertModal2 from "../../../components/modals/AlertModal2";
import DeleteModal from "../../../components/modals/DeleteModal";
import * as actions from "../../../store/actions";
import { scrollToTop } from "../../../utils/scrollToTop";

const UpdateCalendarForm = ({
  blockedDays,
  handleChangeBlockedDates,
  handleUpdateItemCalendar,
  isSubmitting,
}) => {
  return (
    <div className="">
      <form onSubmit={handleUpdateItemCalendar}>
        <div className="group w-full pb-8">
          <MultipleDatePicker
            datePickerType="calendar"
            name="days"
            value={blockedDays}
            onChange={handleChangeBlockedDates}
            multiple
            bottomTip
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className={`block w-full font-bold h-12 px-14 text-white rounded-lg focus:outline-none text-sm lg:text-base ${
              isSubmitting
                ? "bg-gradient opacity-60"
                : "bg-gradient"
            }`}
          >
            {isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

const LenderItemsDetails = (props) => {
  const [itemId, setItemId] = useState(null);
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [blockedDays, setBlockedDays] = useState([]);

  useEffect(() => {
    const parsed = queryString.parse(location?.search);
    setItemId(parsed?.pid);
  }, [queryString.parse(location?.search).pid]);

  useEffect(() => {
    scrollToTop();
    if (itemId) fetchItem(itemId);
  }, [itemId]);

  const fetchItem = async (id) => {
    setLoading(true);
    await props.getSingleItemApi(id);
    setLoading(false);
  };

  useEffect(() => {
    setItem(props.item);

    setBlockedDays(
      props.item?.blockedDays &&
        props.item?.blockedDays?.map((a) => new Date(a))
    );
  }, [props.item]);

  // Update calendar dates
  const handleChangeBlockedDates = (dateObjects) => {
    setBlockedDays(dateObjects.map((dateObject) => dateObject.toDate()));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleUpdateItemCalendar = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();

    const payload = {
      ...item,
      quantity: item?.totalQuantity,
      price: item.price?.amount,
      pickupLocation: item.pickupLocation?._id,
      blockedDays: blockedDays?.map((a) =>
        new Intl.DateTimeFormat("en-US").format(a)
      ),
    };

    await props.updateItemApi(item?.rentalUnitType, itemId, payload, {}, 0);
    setIsSubmitting(false);
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteItem = async () => {
    setDeleteLoading(true);
    await props.deleteItemApi(item._id, props.history);
    setDeleteLoading(false);
  };

  useEffect(() => {
    setShowDeleteModal(false);
  }, [props.deleteItemStatus.type]);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="w-full pt-8 pb-20 relative container px-4 mx-auto">
        <GoBackButton url="/my-items" />
        {loading ? (
          <div className="my-14">
            <Loader />
          </div>
        ) : (
          <>
            <div className="mt-0 mb-10 lg:mt-10 lg:mb-10 relative flex flex-wrap text-temp-primary">
              <div className="w-full lg:w-6/12 xl:w-7/12 mt-8 lg:mt-0 mb-12 order-2 lg:order-1">
                <div className="w-full mb-8">
                  <EquipmentName item={item} />

                  <EquipmentLocationAndRating item={item} />

                  <div className="mt-2 mb-8 hidden lg:block">
                    <EquipmentDiscountDetails item={item} />
                  </div>

                  <EquipmentImageGallery item={item} />

                  <EquipmentProtect item={item} />

                  <div className="mt-2 mb-8 lg:hidden block">
                    <EquipmentDiscountDetails item={item} />
                  </div>

                  <EquipmentDescription item={item} />

                  <EquipmentFeatures item={item} />

                  <div className="lg:hidden block">
                    <UpdateCalendarForm
                      isSubmitting={isSubmitting}
                      blockedDays={blockedDays}
                      handleChangeBlockedDates={handleChangeBlockedDates}
                      handleUpdateItemCalendar={handleUpdateItemCalendar}
                    />
                  </div>
                </div>
              </div>

              {/* Desktop only */}
              <div className="w-full lg:w-6/12 xl:w-5/12 mx-auto px-0 md:px-10 lg:mb-12 order-1 lg:order-2 hidden lg:block">
                <div className="pt-4 pb-10 mx-auto">
                  <div className="grid grid-cols-2 gap-x-8 mx-auto w-full lg:w-11/12">
                    <Link
                      to={`/my-items/${itemId}/edit`}
                      className="w-full cursor-pointer text-center font-bold bg-white border mb-3 border-temp-green-500 text-sm text-temp-green-500 focus:outline-none rounded-lg pb-3 pt-3"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={handleShowDeleteModal}
                      className="w-full text-center font-bold bg-red-500 mb-3 hover:bg-red-600 transition text-sm text-white focus:outline-none rounded-lg pb-3 pt-3"
                    >
                      Delete
                    </button>
                  </div>
                  <div className="w-11/12 mx-auto mt-6">
                    <UpdateCalendarForm
                      isSubmitting={isSubmitting}
                      blockedDays={blockedDays}
                      handleChangeBlockedDates={handleChangeBlockedDates}
                      handleUpdateItemCalendar={handleUpdateItemCalendar}
                    />
                  </div>
                </div>
              </div>

              {/* Mobile only */}
              <div className="w-full lg:hidden block  z-10 bg-white shadow-2xl px-4 py-6 fixed bottom-0 left-0">
                <div className="grid grid-cols-2 gap-6">
                  <Link
                    to={`/my-items/${itemId}/edit`}
                    className="w-full cursor-pointer text-center font-bold bg-white border mr-6 mb-3 border-temp-green-500 text-sm text-temp-green-500 rounded-lg pb-3 pt-3"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={handleShowDeleteModal}
                    className="w-full text-center font-bold transition focus:outline-none bg-red-500 mb-3 text-sm text-white rounded-lg pb-3 pt-3"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Delete modal */}
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          deleteLoading={deleteLoading}
          handleDelete={handleDeleteItem}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}

      {props.deleteItemStatus.type && (
        <AlertModal2
          show={props.deleteItemStatus.type ? true : false}
          type={props.deleteItemStatus.type}
          content={props.deleteItemStatus.message}
        />
      )}

      {/* Trigger Message */}
      {props.alert.message && !isSubmitting && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  item: state.items.item,
  deleteItemStatus: state.items.deleteItemStatus,
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(LenderItemsDetails)
);
