import React from "react";

export const SuccessGradientIcon = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.9674 48.9674L55.8001 71.9959L44.2858 60.102C42.7674 58.5837 40.3634 58.5837 38.9715 59.9755C37.4531 61.4939 37.4531 63.898 38.845 65.2898L53.0164 79.8408C53.7756 80.6 54.7878 80.9796 55.6736 80.9796C56.5593 80.9796 57.5715 80.6 58.3307 79.9673L85.2817 54.4082C86.8001 53.0163 86.9266 50.6123 85.4083 49.0939C83.8899 47.5755 81.4858 47.5755 79.9674 48.9674Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M61.9999 17.7143C36.1877 17.7143 15.1836 38.7184 15.1836 64.5306C15.1836 90.3429 36.1877 111.347 61.9999 111.347C87.8122 111.347 108.816 90.3429 108.816 64.5306C108.816 38.7184 87.8122 17.7143 61.9999 17.7143ZM61.9999 103.755C40.3632 103.755 22.7754 86.1674 22.7754 64.5306C22.7754 42.8939 40.3632 25.3061 61.9999 25.3061C83.6367 25.3061 101.224 42.8939 101.224 64.5306C101.224 86.1674 83.6367 103.755 61.9999 103.755Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="62.151"
          y1="16.7653"
          x2="62.151"
          y2="117.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CE797" />
          <stop offset="1" stopColor="#08A05C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="61.9999"
          y1="16.7653"
          x2="61.9999"
          y2="117.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08A05C" />
          <stop offset="1" stopColor="#4CE797" />
        </linearGradient>
      </defs>
    </svg>
  );
};
