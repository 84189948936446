import React from "react";
import ProfileWithViewButton from "../ProfileWithViewButton";

const LenderInformationWithViewButton = ({
  item,
  title,
  pickupLocation,
  pickupContact,
  className = "lg:px-6 xl:px-8",
  showChatButton
}) => {
  return (
    <>
      <ProfileWithViewButton
        userType="lender"
        title={title}
        user={item.userId}
        image={item.userId?.image}
        firstname={item.userId?.firstname}
        membershipStartDate={item.userId?.createdAt}
        id={item.userId?._id}
        className={className}
        pickupLocation={pickupLocation}
        pickupContact={pickupContact}
        showChatButton={showChatButton}
      />
    </>

  );
};

export default LenderInformationWithViewButton;
