import Actions from "../actions/actionTypes";

const initialState = {
  paymentSuccess: "",
};

const PaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.INITIATE_PAYMENT_WITHDRAWAL:
      return {
        ...state,
        paymentSuccess: action.payload,
      };

    default:
      return state;
  }
};

export default PaymentReducer;
