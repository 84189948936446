import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import MainContainer from '../../components/layout/MainContainer'
import { scrollToTop } from '../../utils/scrollToTop'
import TopSection from './components/TopSection'
import WhatYouWillGetSection from './components/WhatYouWillGetSection'

const Enterprise = () => {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <MainContainer hasMainContainer={false}>
            <Helmet>
                <title>TempOwn | Enterprise</title>
            </Helmet>

            <TopSection />

            <WhatYouWillGetSection />
        </MainContainer>
    )
}

export default Enterprise