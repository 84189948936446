import React from 'react';
import AvatarImg from "../../../assets/images/png/item/item1.png";
import store from "store"
const LateReturnRentalUser = () => {
    const user = store.get("temp_usER12_dATa")
    return <>
        <div className="w-16 h-16 rounded-full object-cover">
            <img
                src={user?.image || AvatarImg}
                className="w-16 h-16 rounded-full object-cover"
                alt="Profile Image"
            />
        </div>

        <h3 className="font-semibold text-2xl lg:text-3xl text-temp-green-500 mt-4">
            Hello {user?.firstname}
        </h3></>
};

export default LateReturnRentalUser;
