import React from "react";

export const AlarmIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0919 15.3192L18.0898 14.6512V8.85156C18.0898 5.15947 15.2529 2.11806 11.6445 1.79132V0.644531C11.6445 0.288578 11.356 0 11 0C10.644 0 10.3555 0.288578 10.3555 0.644531V1.79132C6.74708 2.11806 3.91016 5.15947 3.91016 8.85156V14.6512L2.90813 15.3192C2.72877 15.4388 2.62109 15.6399 2.62109 15.8555V18.4336C2.62109 18.7895 2.90967 19.0781 3.26562 19.0781H7.51416C7.8167 20.7379 9.26411 22 11 22C12.7359 22 14.1833 20.7379 14.4858 19.0781H18.7344C19.0903 19.0781 19.3789 18.7895 19.3789 18.4336V15.8555C19.3789 15.6399 19.2712 15.4387 19.0919 15.3192ZM11 20.7109C9.97782 20.7109 9.11217 20.0209 8.83614 19.0781H13.1639C12.8878 20.0209 12.0222 20.7109 11 20.7109ZM18.0898 17.7891C17.4917 17.7891 4.61424 17.7891 3.91016 17.7891V16.2004L4.91219 15.5323C5.0915 15.4128 5.19922 15.2116 5.19922 14.9961V8.85156C5.19922 5.65301 7.80145 3.05078 11 3.05078C14.1986 3.05078 16.8008 5.65301 16.8008 8.85156V14.9961C16.8008 15.2116 16.9085 15.4128 17.0878 15.5323L18.0898 16.2004V17.7891Z"
        fill="#08A05C"
      />
    </svg>
  );
};
