import React, { useState, useEffect } from "react";
import MainContainer from "../../components/layout/MainContainer";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import GiftImage from "../../assets/images/svg/gift.svg";
import ReferredUserCard from "./components/ReferredUserCard";


const Loading = () => {
  return (
    <div className="flex flex-row flex-wrap items-center">
      <div className="w-14 h-14 lg:h-16 lg:w-16 bg-temp-green-100 rounded-full object-cover"></div>
      <div className="flex flex-row flex-wrap justify-between items-center w-9/12 xl:w-10/12 ml-3">
        <div className="flex flex-col justify-start w-full">
          <p className="h-2 bg-temp-green-100 w-10/12"></p>
          <div className="relative mt-3 py-1 lg:py-2 px-3 lg:px-4 md:mt-1 rounded-md h-1 bg-temp-green-100 w-6/12"></div>
        </div>
        <div className="">
          <p className="text-temp-gray text-xs lg:text-sm mt-2 lg:mt-1 h-2"></p>
        </div>
      </div>
    </div>
  );
};

const Referred = (props) => {
  const [referredList, setReferredList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    setLoading(true);
    await props.getReferredListApi();
    setLoading(false);
  };

  useEffect(() => {
    setReferredList(props.referredList);
  }, [props.referredList]);
  return (
    <MainContainer hasMainContainer={false}>
      <div className="container mx-auto px-4 w-full pt-8 md:pt-10 lg:pt-16 pb-36 relative">
        <div className="">
          <h3 className="font-medium text-2xl lg:text-3xl text-temp-primary">
            People you referred
          </h3>
          {referredList?.length > 0 && (
            <p className="text-temp-green-500 mt-4 text-xs md:text-sm lg:text-base">
              {referredList?.length} people referred
            </p>
          )}
        </div>

        <div className="w-full xl:w-8/12 mx-auto mt-8 lg:mt-14">
          {loading ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-16">
              <Loading />
              <Loading />
              <Loading />
              <Loading />
            </div>
          ) : referredList?.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-16">
              {referredList?.map((a, index) => (
                <ReferredUserCard
                  key={index}
                  hasRented={a?.rented}
                  name={a?.user?.firstname + " " + a?.user?.lastname}
                  createdAt={new Date(a?.user?.createdAt).toDateString()}
                  image={a?.user?.image}
                />
              ))}
            </div>
          ) : (
            <div className="mx-auto text-center mt-16 lg:mt-24 mb-14">
              <img src={GiftImage} className="mx-auto" alt="" />
              <p className="text-sm lg:text-base text-temp-primary mt-6">
               No one has been referred yet.
                <br /> Share your link with family and friends to gain a referral bonus. 
              </p>
            </div>
          )}
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    referredList: state.referral.referredList,
  };
};

export default connect(mapStateToProps, actions)(Referred);
