import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import InputGroup2 from "../../../components/element/InputGroup2";
import { Formik } from "formik";
import CheckBoxGroup from "../../../components/element/CheckBoxGroup";
import { Link } from "react-router-dom";

const AddCardInformationModal = ({
  show,
  handleCloseModal,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isNext, setIsNext] = useState(false);

  const initialState = {};

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="text-center">
                <h3
                  className="leading-6 text-2xl md:text-2xl font-medium text-temp-secondary font-serif"
                  id="modal-headline"
                >
                  Add Card Information
                </h3>
                <div className="mt-10">
                  <Formik
                    initialValues={initialState}
                    onSubmit={async () => {
                      setIsNext(true);
                    }}
                  >
                    {({
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="w-full flex flex-wrap justify-between text-left">
                            <div className="w-full md:w-48">
                              <InputGroup2
                                type="text"
                                label="First Name"
                                name="first_name"
                              />
                            </div>
                            <div className="w-full md:w-48">
                              <InputGroup2
                                type="text"
                                label="Last Name"
                                name="last_name"
                              />
                            </div>
                          </div>
                          <div className="w-full text-left">
                            <InputGroup2
                              type="text"
                              label="Card Number"
                              name="card_number"
                              placeholder="0000 0000 0000 0000"
                            />
                          </div>
                          <div className="w-full flex flex-wrap justify-between text-left">
                            <div className="w-full md:w-48">
                              <InputGroup2
                                type="text"
                                label="Expiry Date"
                                name="expiry_date"
                                placeholder="MM/YY"
                              />
                            </div>
                            <div className="w-full md:w-48">
                              <InputGroup2
                                type="text"
                                label={
                                  <div className="flex justify-between flex-1">
                                    <span>CVV</span>
                                    <p>
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.66724 11.9999C8.66724 12.368 8.36878 12.6665 8.00061 12.6665C7.63245 12.6665 7.33398 12.368 7.33398 11.9999C7.33398 11.6317 7.63245 11.3333 8.00061 11.3333C8.36878 11.3333 8.66724 11.6317 8.66724 11.9999Z"
                                          fill="#08A05C"
                                        />
                                        <path
                                          d="M8 16C3.58862 16 0 12.4114 0 8C0 3.58862 3.58862 0 8 0C12.4114 0 16 3.58862 16 8C16 12.4114 12.4114 16 8 16ZM8 1C4.14001 1 1 4.14001 1 8C1 11.86 4.14001 15 8 15C11.86 15 15 11.86 15 8C15 4.14001 11.86 1 8 1Z"
                                          fill="#08A05C"
                                        />
                                        <path
                                          d="M7.99939 9.83325C7.72339 9.83325 7.49939 9.60925 7.49939 9.33325V8.65991C7.49939 8.02527 7.9021 7.45654 8.50074 7.24524C9.29737 6.9646 9.83277 6.11584 9.83277 5.49988C9.83277 4.48853 9.01075 3.6665 7.99939 3.6665C6.98804 3.6665 6.16602 4.48853 6.16602 5.49988C6.16602 5.77588 5.94202 5.99988 5.66602 5.99988C5.39001 5.99988 5.16602 5.77588 5.16602 5.49988C5.16602 3.93787 6.43677 2.6665 7.99939 2.6665C9.56202 2.6665 10.8328 3.93787 10.8328 5.49988C10.8328 6.61194 9.95472 7.7926 8.83338 8.1886C8.63343 8.25854 8.49939 8.44849 8.49939 8.66052V9.33325C8.49939 9.60925 8.27539 9.83325 7.99939 9.83325Z"
                                          fill="#08A05C"
                                        />
                                      </svg>
                                    </p>
                                  </div>
                                }
                                name="cvv"
                                placeholder="000"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full">
                          <CheckBoxGroup
                            label={
                              <span className="text-temp-info text-sm">
                                I attest to the
                                <Link
                                  to="/terms"
                                  className="font-semibold text-temp-green"
                                >
                                  {" "}
                                  terms
                                </Link>{" "}
                                and{" "}
                                <Link
                                  to="/condtions"
                                  className="font-semibold text-temp-green"
                                >
                                  conditions
                                </Link>{" "}
                                on adding cards
                              </span>
                            }
                            // name="isAgreed"
                            // value={values.isAgreed}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            // error={
                            //   errors.isAgreed &&
                            //   touched.isAgreed &&
                            //   errors.isAgreed
                            // }
                          />
                        </div>

                        <div className="flex mt-8 items-baseline justify-end flex-end">
                          <button
                            onClick={handleCloseModal}
                            className="text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="mt-5 mb-4 py-3 px-8 text-sm bg-temp-green-500 bg-gradient font-bold text-white rounded-lg focus:outline-none"
                          >
                            Confirm
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCardInformationModal;
