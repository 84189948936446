import React from "react";
import Image from "../../../assets/images/png/enterprise.webp";

const TopSection = () => {
    return (
        <section className="">
            <div className="container mx-auto px-4 relative pt-6 md:pt-6 lg:pt-10 pb-16 mb:pb-28">
                <div className="relative flex flex-wrap flex-row justify-between">
                    <div className="w-full md:w-7/12 lg:w-6/12 pt-10 md:pt-16 lg:pt-20 lg:pr-12">
                        <h2 className="font-semibold text-temp-primary text-4xl md:text-5xl lg:text-5xl xl:text-6xl">
                            A <span className="text-temp-secondary">smarter</span>{" "} way to {" "}
                            <span className="text-temp-secondary">manage</span>{" "}and {" "}<span className="text-temp-secondary">rent out</span>{" "}
                            your equipment
                        </h2>

                        <p className="w-full md:w-11/12 text-base lg:text-lg text-temp-gray mt-6 lg:mt-8">
                        We connect thousands of individuals and businesses across Nigeria to rent your equipment. You can control and manage your rentals on one dashboard.
                        </p>

                        <div className="relative flex mt-10">
                            <a
                                href={"mailto:hello@tempown.com"}
                                className="bg-gradient py-4 lg:py-5 px-10 lg:px-12 rounded-xl text-white text-sm md:text-base font-semibold"
                            >
                                Contact Support
                            </a>
                        </div>
                        <p className="text-xs md:text-sm mt-6 lg:mt-8 text-temp-green-500">🔒 Your equipments are safe</p>
                    </div>
                    <div className="w-full lg:w-6/12 relative mt-12 lg:mt-0">
                        <div className="w-full  mx-auto ">
                            <img src={Image} alt="Tempown Enterprise" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopSection;
