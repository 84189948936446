import React from "react";

import Step1Image from "../../../../assets/images/png/lender/lender-step1.webp";
import Step2Image from "../../../../assets/images/png/lender/lender-step2.webp";
import Step3Image from "../../../../assets/images/png/lender/lender-step3.webp";

const HowItWorkStep = ({ id, title, content, image }) => {
  return (
    <div className="pt-14 px-8 rounded-xl flex flex-col justify-between" style={{ background: "#F5F5F5" }}>
      <div className="mb-12">
        <div className="text-2xl font-semibold text-temp-green-500 mb-4">
          {id}
        </div>

        <dt className="text-base md:text-base xl:text-lg leading-6 font-semibold text-black">
          {title}
        </dt>
        <dd className="mt-4 text-sm lg:text-sm-15 lg:leading-6 text-temp-primary">
          {content}
        </dd>
      </div>
      <div className="">
        <img src={image || Step1Image} className="w-full" alt="" />
      </div>
    </div>
  );
};

const LenderHowItWork = () => {
  return (
    <div className="relative mb-12 pt-2 pb-4 ">
      <div className="container mx-auto px-4">
        <div className=" py-14 md:py-16 w-full">
          <h3 className="text-3xl lg:text-3xl xl:text-4xl font-semibold text-temp-primary">
            How it works
          </h3>
          <p className="text-temp-primary text-sm lg:text-base xl:text-lg py-4 leading-6">
            A step-to-step guide to getting started.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <HowItWorkStep
            id="1"
            image={Step1Image}
            title="Join the community"
            content={`This is the largest community of verified equipment borrowers 
                  and vendors there is. Create a personalized 
                  account and provide the necessary information to kick start your rental journey.`}
          />
          <HowItWorkStep
            id="2"
            image={Step2Image}
            title="Get Verified"
            content="Every information you share with us is double-checked for authenticity and kept secure. We care about the safety of our community of users."
          />
          <HowItWorkStep
            id="3"
            image={Step3Image}
            title="Upload Equipment"
            content="Once verified, take photos of your equipment, give proper descriptions, list them and set your rental rates. Manage as much equipment as you own."
          />
        </div>
      </div>
    </div>
  );
};

export default LenderHowItWork;
