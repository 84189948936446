import React from "react";

const RefundPolicy = () => {
  return (
    <div className="mb-16">
      <h3 className="text-xl font-semibold mb-3">OWNER POLICY</h3>
      <p className="leading-7 text-base">
        This Cancellation and Refund Policy (“the Policy”) informs you about
        your right to cancel a service and receive a refund for services
        purchased on our website. It also describes the procedure, rules and
        guidelines relating to the cancellation and refund. Please read this
        Policy carefully to understand your rights and to also understand the
        requirements for the cancellation and refund.
        <br />
        <br />
        TempOwn exclusively, reserves the right to either accept or deny a
        TempOwner’s refund entitlement.
      </p>
      <ol className="mt-8 space-y-8">
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">1.</span> Request Decline
          </p>
          <br />
          <p>
            An Owner may choose to decline a TempOwner’s rental request within
            the 24-hour timeframe as clearly shown on the Owner’s page. Where an
            Owner chooses to decline a rental request, such Owner must state the
            reasons for the decline. For declines of up to 3 times or more, such
            Owner’s account and postings may be suspended or deactivated.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">2.</span> Owner&apos;s Cancellation
          </p>
          <br />
          <p>
            An Owner may not cancel a reservation with the TempOwner after
            accepting a rental request. However, where an Owner chooses to
            cancel, such Owner will be charged an Inconvenience Fee of 10% of
            the rental fee paid by the Borrower for their rental item.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">3.</span>Effects of Cancellation/Owner’s
            no-shows on Owner
          </p>
          <br />
          <ol className="space-y-3 list-inside list-disc">
            <li>
              A cancellation fee of 10% of the rental fee paid by the TempOwner
              will be deducted from the Owner’s account or next payouts (in
              cases of insufficient funds) until it has been paid off.
            </li>
            <li>
              {" "}
              A cancellation review will be left on your profile detailing the
              number of times you have cancelled a rental item before the rental
              start date.
            </li>
            <li>
              For initiated cancellations of up to 3 times or more, your account
              and postings may be suspended or deactivated.
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">4.</span> Owner no-shows
          </p>
          <p>An Owner no-show means:</p>
          <br />
          <ol className="space-y-3 list-inside list-disc">
            <li>
              Where an Owner does not provide the accurate reserved rental item
              at the point of delivery, or
            </li>
            <li>
              Where an Owner does not provide the rental item at the reservation
              agreed date and time, the TempOwner will contact{" "}
              <a
                href="mailto:hello@tempown.com"
                className="text-temp-green-500"
              >
                hello@tempown.com
              </a>{" "}
              right away to get a 100% full refund within 48 hours.
            </li>
          </ol>
          <br />
          TempOwners are not held responsible if the Owner does not show up.
        </li>

        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">5.</span> Effects of Owner’s no-shows on
            Owner
          </p>
          <br />
          <ol className="space-y-3 list-inside list-disc">
            <li>
              A no-show fee of 10% of the rental fee paid by the TempOwner, will
              be deducted from the Owner’s account or next payouts (in cases of
              insufficient funds) until it has been paid off.
            </li>
            <li>
              A no-show review will be left on such an Owner&apos;s profile detailing
              the number of times the Owner has not shown up on a rental
              delivery date.
            </li>
            <li>
              For no-shows of up to 3 times or more, such an Owner’s account and
              postings may be suspended or deactivated.
            </li>
          </ol>
        </li>
      </ol>
      <br />
      <br />
      <h3 className="text-xl font-semibold mb-3">TEMPOWNER POLICY</h3>
      <p className="leading-7 text-base">
        This Cancellation and Refund Policy (“the Policy”) informs you about
        your right to cancel a service and receive a refund for services
        purchased on our website. It also describes the procedure, rules and
        guidelines relating to the cancellation and refund. Please read this
        Policy carefully to understand your rights and to also understand the
        requirements for the cancellation and refund.
        <br />
        <br />
        TempOwn exclusively reserves the right to either accept or deny a
        party’s refund entitlement.
      </p>
      <ol className="mt-8 space-y-8">
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">1.</span> TempOwner Cancellation
          </p>
          <br />
          <ol className="space-y-3 list-inside list-disc">
            <li>
              Cancellations can only happen before the booking is confirmed.
              Booking confirmations will typically happen within 24 hours after
              a rental request is made. When you cancel before your rental
              booking is confirmed, the rental fee you’ve paid will be refunded.
            </li>
            <li>
              If you cancel after a 24-hour period, the rental fee you paid will
              be refunded, minus a cancellation fee of 15%.
            </li>
            <li>
              You can’t cancel after receipt of rental item(s). No refund.
            </li>
          </ol>
          <br />
          TempOwners who repeatedly cancel reservations may be subject to
          additional penalties, including removal from the marketplace.
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">2.</span> TempOwner No-Show
          </p>
          <br />
          <p>
            If you fail to show up to accept the delivery of the rental
            item/equipment or arrange transportation to pick up the reserved
            rental item/equipment(s), you won’t be entitled to a refund.
            <br />
            <br />
            In all instances of a ‘No-show’, you’ll receive no refund of the
            rental fees you’ve paid.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">3.</span> Rental modifications
          </p>
          <br />
          <p>
            For any reservation where the TempOwner requests a rental
            modification within the 24-hour period (as stated in Clause 1), the
            borrower will be charged a modification fee of 5% of the rental fee
            and the modification request will be sent to the Owner for
            modification confirmation. Where the Owner cannot confirm the
            modification, TempOwn will send the request to another Owner as a
            new booking.
            <br />
            <br />
            Please ensure you select the correct dates before booking.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">4.</span> If We Make Changes to Your Booking
          </p>
          <br />
          <p>
            Occasionally it may be necessary for changes to be made to your
            booking (after acceptance) by us or by the Owner. In all such cases,
            we will advise you as soon as reasonably possible before pick-up,
            and if the proposed changes are unacceptable, you will be offered a
            full refund. However, in such circumstances, we shall have no
            additional liability in respect of any direct or indirect losses you
            may suffer as a result of such changes.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">5.</span> Early Returns
          </p>
          <br />
          <p>
            When TempOwners want to return a rental item earlier than the agreed
            reservation date and time. There will be no refunds issued for early
            returns.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">6.</span> Owner no-shows
          </p>
          <br />
          <ol className="space-y-3 list-inside list-disc">
            <li>
              If an Owner does not provide the accurate reserved rental item at
              the point of delivery, or
            </li>
            <li>
              If an Owner does not provide the rental item at the reservation
              agreed date and time, contact{" "}
              <a
                href="mailto:hello@tempown.com"
                className="text-temp-green-500"
              >
                hello@tempown.com
              </a>{" "}
              right away to get a 100% full refund within 7 business days.
              TempOwners are not held responsible if the Owner does not show up.
            </li>
          </ol>
          <br />
          (TempOwners are advised to contact the Owner via email, phone or text
          message given to them prior to the rental start date after a
          confirmation has been booked. To obtain a refund, the borrower will
          need to show proof that the Owner was contacted and failed to
          respond).
        </li>
      </ol>
    </div>
  );
};

export default RefundPolicy;
