import React from 'react';
import Check2Icon from "../../../assets/images/svg/checked-2.svg";

const VerifyCard = ({ title }) => {
    return <div
        className="bg-temp-green-100 pl-4 pr-5 py-3 rounded-xl flex flex-wrap items-center mr-4"
    >
        <div className="flex-shrink flex flex-wrap items-center">
            <div className="pr-3">
                <img src={Check2Icon} alt="" />
            </div>
            <div className="">
                <p className="text-temp-primary text-xs">{title}</p>
            </div>
        </div>
    </div>
}

export default VerifyCard;
