import React from "react";

const Loader2 = () => {
  return (
    <div className="mx-auto text-center">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <p>Fetching Data... Please wait</p>
    </div>
  );
};

export default Loader2;
