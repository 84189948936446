import React from "react";
import { capitalizeFistLetterInSentence } from "../../../utils/capitalizeFirstLetterInSentence";

const EquipmentName = ({ item, textClassName }) => (
  <h3 className={`font-medium text-temp-primary lg:mb-3 ${textClassName || "text-lg md:text-xl xl:text-2xl"}`}>
    {capitalizeFistLetterInSentence(item?.name)}
  </h3>
);

export default EquipmentName;
