import React from "react";

import InsuranceImage from "../../../assets/images/svg/self-insured.svg";

const UploadSelectSerialNumbers = ({
  availableSerialNumbers,
  handleAddOrRemoveSerialNumber,
  selectedAvailableSerialNumbers,
  handleSubmit,
  submitLoading,
  orderDetails
}) => {

  return (
    <>
      <div className="pt-8 relative space-y-8 w-full lg:w-9/12">
        {availableSerialNumbers?.length === 0 ? (
          <div className="mx-auto text-center">
            <img
              src={InsuranceImage}
              className="mx-auto mb-4 lg:mb-6 mt-4"
              alt="No Serial number"
            />

            <p className="text-base lg:text-lg font-semibold text-temp-primary pb-2">
              No serial number included
            </p>

            <p className="text-xs lg:text-sm text-temp-gray">
              Item without serial number means you self-insured the equipment
              and they are safe and secured. Kindly click the{" "}
              <strong>Submit</strong> button below to continue.
            </p>
          </div>
        ) : (
          <>
            {availableSerialNumbers?.map((a, index) => (
              <div
                key={index}
                onClick={() => handleAddOrRemoveSerialNumber(a)}
                className={`cursor-pointer serial-number rounded-lg pt-4 pb-3 px-6 text-sm-15 lg:text-base ${selectedAvailableSerialNumbers?.includes(a) ? "selected" : ""
                  }`}
              >
                {a}
              </div>
            ))}
          </>
        )}

        <div
          className={`group w-full inline-flex flex-row flex-wrap pb-40 lg:pb-0 ${availableSerialNumbers?.length > 0
            ? "justify-end"
            : "justify-center"
            } text-right pt-3 lg:pt-6 space-x-4`}
        >
           <button
            onClick={handleSubmit}
            disabled={
              submitLoading ||
              (availableSerialNumbers?.length > 0 && (selectedAvailableSerialNumbers?.length !== orderDetails?.quantity))
            }
            className={`w-full lg:w-auto font-bold text-xs lg:text-sm h-12 px-14 text-white rounded-lg focus:outline-none bg-temp-green-500 bg-gradient 
                            ${submitLoading ||
                (availableSerialNumbers?.length > 0 && (selectedAvailableSerialNumbers?.length !== orderDetails?.quantity))
                ? "opacity-70 cursor-not-allowed"
                : ""
              }
                        `}
          >
            {submitLoading ? "Processing..." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadSelectSerialNumbers;
