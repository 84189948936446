export const generateItemUrl = (path, item) => {
  const urlPath =  path || "items";
  const itemName = encodeURI(
    item?.name?.toLowerCase()?.replaceAll(" ", "-").trim()
  );
  const category = item.category?.trim()?.replace(" ", "-");
  const equipmentType = item.type?.trim()?.replace(" ", "-");
  const pid = item._id;
  return  (
    urlPath +
    "/" +
    itemName +
    "?category=" +
    category +
    "&equipment-type=" +
    equipmentType +
    "&pid=" +
    pid
  );
};
