import React from 'react';
import { Link } from 'react-router-dom';
import AuthLeftContainer from "./AuthLeftContainer"
import Logo from "../../../assets/images/svg/logo/logo-sm.svg";

const Title = ({ admin, title, subTitle }) => (
    <>
        <h2 className="font-bold text-temp-green-500 text-xl lg:text-2xl xl:text-3xl">
            {title || "Create your TempOwn Account"}
        </h2>
        {admin ? (
            <p className="py-3 text-sm md:text-base">
                This page is for an Admin to sign up a new user without an android
                device.
            </p>
        ) : (
            <p className="py-3 text-sm md:text-base">
                {subTitle || "Join the league of happy people using TempOwn for their rental needs"}
            </p>
        )}
    </>
);

const MainAuthContainer = (props) => {
    return <section className='w-full relative'>
        <div className='flex flex-wrap flex-row'>
            <AuthLeftContainer />

            <div className={`px-6 lg:px-0 w-full lg:w-7/12 2xl:w-6/12 2xl:mx-auto min-h-screen flex flex-col justify-center ${props.className || "pt-6 lg:pt-24 xl:pt-36"}`}>
                {/* Mobile only start*/}
                <div className="mt-6 sm:mt-3 flex justify-start lg:w-0 lg:flex-1 lg:hidden mb-16">
                    <Link to="/">
                        <span className="sr-only">TempOwn</span>
                        <img className="h-6 w-auto md:h-8 lg:h-9" src={Logo} alt="Logo" />
                    </Link>
                </div>
                {/* Mobile only end*/}

                <div className="w-full lg:w-10/12 xl:w-9/12 mx-auto">
                    <div className="flex w-full flex-col pr-0 lg:pr-24">
                        <Title
                            admin={props?.admin}
                            title={props?.title}
                            subTitle={props?.subTitle}
                        />

                        <div className="mt-10 mb-20">
                            {
                                props.children
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default MainAuthContainer;
