import React from 'react'
import TImage1 from "../../../assets/images/png/theft.webp"
import TImage2 from "../../../assets/images/png/damage.webp"

const Card = ({ image, title, content }) => {
    return (
        <div className=" bg-temp-green-100  h-full relative px-4 lg:px-6 py-6 lg:py-8 rounded-xl">
            <div className="flex flex-wrap lg:h-56">
                <div className="w-1/12 md:w-2/12 lg:w-4/12 h-full object-cover rounded-xl">
                    <img
                        src={image}
                        className="w-full h-10 md:h-24 lg:h-full rounded-xl object-cover"
                        alt=""
                    />
                </div>
                <div className="w-11/12 md:w-10/12 lg:w-8/12 pl-4 md:pl-6 lg:pl-10 pr-4 lg:pr-6">
                    <div className="flex flex-col flex-wrap justify-between">
                        <p className="font-medium text-temp-primary pt-4 lg:pt-6 lg:pb-4 text-sm-15 md:text-base lg:text-lg">
                            {title}
                        </p>
                        <p className="text-temp-primary text-sm lg:text-sm-15 leading-6">{content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const WhatIsCoveredSection = () => {
    return (
        <div className="container mx-auto px-4 pt-16 pb-12 lg:pt-20 lg:pb-16">
            <div className="block mb-4">
                <h3 className="wetext-3xl lg:text-3xl xl:text-4xl font-semibold text-temp-primary">
                    What is covered?
                </h3>
                <p className="text-temp-primary text-sm lg:text-base xl:text-lg py-4 leading-6 w-full md:w-9/12 lg:w-7/12">
                    You care about your items, so do we. With our unique Tempown Protect offering, we provide insurance cover on items and equipment rented through the marketplace.
                </p>
            </div>

            <div className="mt-10 w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card
                    image={TImage1}
                    title={"Theft"}
                    content="A borrower is taking too long to return your item? Don't sleep on it. In the case of theft of your Item or equipment during a rental, we will work with you to recover it as soon as possible."
                />

                <Card
                    image={TImage2}
                    title={"Damages"}
                    content="Don't leave any stone unturned. See a tiny scratch on your item or equipment? State your claims with credible background information."
                />
            </div>
        </div>
    )
}

export default WhatIsCoveredSection