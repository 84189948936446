import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Check2Icon from "../../../assets/images/svg/checked-2.svg";
import CheckIcon from "../../../assets/images/svg/checked.svg";
import Image from "../../../assets/images/svg/user.svg";
import StarRating from "../../../components/blocks/StarRating";
import VerifyCard from '../../../components/blocks/itemBits/VerifyCard';

const TooltipData = ({name, value}) => {
    return <div className='flex justify-between mb-3'>
    <p>{name}</p>
    <p className='w-7/12 text-left'>{value}</p>
    <p><img src={Check2Icon} alt="" /></p>
</div>
}

const ProfileUserDetails = ({ user }) => {
    return <div className="">
        <div className="pl-0 pb-3 rounded-lg bg-white">
            <div className="lg:flex flex-wrap items-start text-center lg:text-left">
                <div className="relative mr-4 lg:mr-6 pb-3 flex flex-shrink justify-center lg:block">
                    <img
                        src={user?.image || Image}
                        width="100%"
                        className="h-20 w-20 lg:h-20 lg:w-20 object-cover rounded-full border-none outline-none bg-white"
                        alt=""
                    />
                    <img
                        src={CheckIcon}
                        width="22rem"
                        className="border-2 border-white rounded-full absolute lg:right-0 ml-12 lg:mr-0 bottom-2 lg:bottom-3 -mt-4"
                        alt=""
                    />
                </div>
                <div className="relative block flex-1 mb-3">
                    <h4 className="text-base xl:text-lg font-bold text-temp-primary">
                        {user?.firstname}{" "}
                        <span className="lg:hidden">{user?.lastname}</span>
                    </h4>
                    <p className="text-xs text-temp-gray mt-2 lg:mt-0">
                        Member since{" "}
                        {new Date(user?.createdAt).toLocaleDateString(
                            "en-US",

                            {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            }
                        )}
                    </p>
                    <div className="flex justify-center lg:block">
                        <StarRating rating={user?.rating} />
                    </div>

                    <div className='mt-4 flex flex-wrap flex-row justify-center md:justify-start items-center'>
                        {user?.verified_id && <VerifyCard title="Identity verified" />}
                        <VerifyCard title="Contact verified" />

                        <p className='text-xs text-temp-secondary w-full lg:w-auto pl-6 mt-6 lg:mt-0 cursor-pointer' data-tip data-for='verify-info'>Show verified information</p>
                        <ReactTooltip id='verify-info'  className='verifyProfile-tooltip w-10/12 lg:w-5/12 xl:w-3/12 rounded-xl'>
                            <TooltipData name={"Name"} value={user?.firstname + " " + user?.lastname} />
                            <TooltipData name={"Email"} value={user?.email} />
                            <TooltipData name={"Country"} value={user?.country} />
                        </ReactTooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default ProfileUserDetails;
