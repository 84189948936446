import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const submitFeedbackApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/feedback";
  try {
    const response = await axios.post(url, payload);

    dispatchAction(dispatch, Actions.SUBMIT_FEEDBACK, {
      type: "success",
      message: response.data.message,
    });
  } catch (error) {
    dispatchAction(dispatch, Actions.SUBMIT_FEEDBACK, {
      type: "error",
      message: error.response?.data.message || "Unable to authenticate token",
    });
  }
};

// Function
const dispatchAction = (dispatch, type, payloadData, history) => {
  dispatch({
    type,
    payload: payloadData,
  });
  setTimeout(() => {
    dispatch({
      type,
      payload: {
        type: "",
        message: "",
      },
    });

    return history;
  }, 3000);
};
