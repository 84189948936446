import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import ReferInfoModal from "../../components/modals/referInfoModal";
import MainContainer from "../../components/layout/MainContainer";

import AlertModal from "../../components/modals/AlertModal";
import { numberFormat } from "../../utils/numberFormat";
import { Link } from "react-router-dom";
import ReferContent from "./refercontent";
import ReferLoader from "./components/ReferLoader";
import ReferralStatCard from "./components/ReferralStatCard";
import ReferPendingEarning from "./components/ReferPendingEarning";
import ReferShareLinkSection from "./components/ReferShareLinkSection";

const Refer = (props) => {
  const [referralDetails, setReferralDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleShowModal = (v) => {
    setShowModal(v);
  };

  const fetchDetails = async () => {
    setLoading(true);
    await props.getLoggedInReferralDetailsApi();
    setLoading(false);
  };

  useEffect(() => {
    setReferralDetails(props.referralDetails);
  }, [props.referralDetails]);

  // Copied link
  const [copiedLink, setCopiedLink] = useState(false);
  const handleClickCopy = (value) => {
    setCopiedLink(true);

    var inp = document.createElement("input");
    document.body.appendChild(inp);
    inp.value = value;
    inp.select();
    document.execCommand("copy", false);
    inp.remove();

    setTimeout(() => {
      setCopiedLink(false);
    }, 3000);
  };

  return (
    <MainContainer hasMainContainer={false}>
      <div className="container px-4 mx-auto w-full pt-8 md:pt-10 lg:pt-16 pb-36 relative">
        <div className="">
          <h3 className="font-medium text-2xl lg:text-3xl text-temp-primary">
            Referral
          </h3>
        </div>
        <div className="w-full md:w-7/12 mx-auto mt-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {loading ? (
              <>
                <ReferLoader />
                <ReferLoader />
              </>
            ) : (
              <>
                {" "}
                <ReferralStatCard
                  title="Total Referral"
                  value={referralDetails?.totalReferral}
                />
                <ReferralStatCard
                  title="Referral Earning"
                  value={`₦${numberFormat(referralDetails?.referralEarning)}`}
                />{" "}
              </>
            )}
          </div>

          <ReferPendingEarning referralDetails={referralDetails} />

          <ReferShareLinkSection
            loading={loading}
            referralDetails={referralDetails}
            handleClickCopy={handleClickCopy}
            copiedLink={copiedLink}
          />

          <div className="w-full mx-auto text-center mt-10">
            <p className="text-temp-green-500 text-sm-15 md:text-base lg:text-lg leading-7">
              <span className="text-red-600">
                Referral earnings cannot be withdrawn.
              </span>{" "}
              <br />
              <span className="text-temp-gray text-xs">
                They can only be used for rental purpose on the platform
              </span>
              <br />
              <br />
              <span
                onClick={() => handleShowModal(true)}
                className="font-medium cursor-pointer underline text-sm lg:text-base"
              >
                Learn More
              </span>
            </p>
          </div>

          <div className="mx-auto text-center">
            <Link
              to="/refer/referred"
              className="text-xs lg:text-sm mt-7 text-temp-green-500 border border-temp-green-500 whitespace-nowrap inline-flex items-center justify-center rounded-xl font-bold px-8 py-4 focus:outline-none"
            >
              View Referred
            </Link>
          </div>
        </div>
      </div>
      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
      {showModal && (
        <ReferInfoModal
          show={showModal}
          handleShow={handleShowModal}
          title="Refer and Earn"
          content={<ReferContent />}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    referralDetails: state.referral.referralDetails,
  };
};

export default connect(mapStateToProps, actions)(Refer);
