import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import store from "store";
import SearchIcon from "../../assets/images/svg/search/search.svg";
import InputWithSuffixPrefixGroup from "../element/InputWithSuffixPrefixGroup";

const SearchForm = () => {
  const user = store.get("temp_usER12_dATa");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    const searchTerm = search.trim();
    if (searchTerm !== "") {
      navigate(`/search?q=${searchTerm.toLowerCase().replace(" ", "+")}`);
    }
  };

  return (
    <form className="relative pt-6 pb-6 text-left md:pt-8 md:pb-4 lg:pt-12 lg:pb-8 w-full lg:w-10/12">
      <div className="relative flex-1">
        <InputWithSuffixPrefixGroup
          prefixIcon={SearchIcon}
          onClick={handleSubmitSearch}
          buttonType="submit"
          placeholder="Search item by name or category"
          type="text"
          name="searchInput"
          value={search}
          onChange={handleInputChange}
          btnText="Search"
        />
      </div>

      <div className="mt-6 flex font-medium justify-between items-center">
        <div className="flex flex-row text-xs text-temp-primary space-x-1 lg:space-x-4 items-center">
          <Link
            to={`/${user?.verified_lender ? "upload-item/start" : "lender"}`}>
            Or List an item
          </Link>

          <span className="text-base font-light text-temp-secondary hidden sm:block">
            |
          </span>

          <Link to={user ? "/lender" : "/signup"} className=" hidden sm:block">
            Become an agent
          </Link>
        </div>

        <p className="text-xs text-temp-secondary">
          🔒 All items are fully insured
        </p>
      </div>
    </form>
  );
};

export default SearchForm;
