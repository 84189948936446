import Actions from "../actions/actionTypes";

const initialState = {
  wallets: {},
};

const WalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_WALLETS:
      return {
        ...state,
        wallets: action.payload,
      };

    case Actions.UPDATE_WALLETS_AFTER_WITHDRAWAL:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          balance: action.payload?.balance,
        },
      };

    default:
      return state;
  }
};

export default WalletReducer;
