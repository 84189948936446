import React from 'react';
import Img from "../../../assets/images/svg/user.svg";

const ReferredUserCard = ({ hasRented, image, name, createdAt }) => {
    return (
        <div className="flex flex-row items-center">
            <div className="w-12 h-12 md:w-14 md:h-14 lg:h-16 lg:w-16 rounded-full object-cover">
                <img
                    src={image || Img}
                    className="w-12 h-12 md:w-14 md:h-14 lg:h-16 lg:w-16 object-cover rounded-full"
                    alt=""
                />
            </div>
            <div className="flex flex-row flex-wrap justify-between items-start w-9/12 xl:w-10/12 ml-4">
                <div className="flex flex-col justify-start">
                    <p className="text-temp-green-500 text-sm md:text-sm-15 lg:text-base font-medium">
                        {name?.length > 30 ? name?.slice(0, 28) + "..." : name}
                    </p>
                    <div className="relative mt-1">
                        <span
                            className={`${hasRented ? "completed-status" : "cancelled-status"
                                } py-1 lg:py-2 px-3 lg:px-4 mt-0 md:mt-1 text-xs rounded-md text-center`}
                        >
                            {hasRented ? "Rented an item" : "Yet to rent an item"}
                        </span>
                    </div>
                </div>
                <div className="">
                    <p className="text-temp-gray text-xs mt-2 lg:mt-2">
                        {createdAt}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ReferredUserCard;
