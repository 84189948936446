import { checkUnavailability } from "../../helpers/checkUnavailability";
import Actions from "../actions/actionTypes";

const initialState = {
  items: [],
  pagination: {},
  item: {},
  createItemStatus: {
    status: "",
  },
  updateItemStatus: {},
  deleteItemStatus: {
    type: "",
    message: "",
  },
  itemAvailability: {
    available: null,
  },

  availableSerialNumbers: [],
  seoContent: "",
};

const ItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLEAR_ITEM:
      return {
        ...state,
        items: [],
        pagination: {},
      };

    case Actions.CREATE_ITEM:
      return {
        ...state,
        createItemStatus: action.payload,
      };

    case Actions.UPDATE_ITEM:
      return {
        ...state,
        updateItemStatus: action.payload,
      };

    case Actions.GET_ITEMS:
      return {
        ...state,
        items: checkUnavailability(action.payload?.docs),
        pagination: {
          hasNextPage: action.payload?.hasNextPage,
          hasPrevPage: action.payload?.hasPrevPage,
          limit: action.payload?.limit,
          nextPage: action.payload?.nextPage,
          page: action.payload?.page,
          pagingCounter: action.payload?.pagingCounter,
          prevPage: action.payload?.prevPage,
          totalDocs: action.payload?.totalDocs,
          totalPages: action.payload?.totalPages,
        },
        seoContent:
          action.payload?.page > 1
            ? state.seoContent || undefined
            : action.payload?.seo_content,
      };

    case Actions.GET_SINGLE_ITEM:
      return {
        ...state,
        item: action.payload,
      };

    case Actions.DELETE_ITEM:
      return {
        ...state,
        deleteItemStatus: action.payload,
      };

    case Actions.CHECK_ITEM_AVAILABILITY:
      return {
        ...state,
        itemAvailability: action.payload,
      };

    case Actions.APPLY_VOUCHER:
      return {
        ...state,
        itemAvailability: {
          ...state.itemAvailability,
          summary: {
            ...state.itemAvailability.summary,
            ...action.payload,
          },
        },
      };

    case Actions.APPLY_REFERRAL_BONUS_ON_ITEM_AVAILABILITY:
      return {
        ...state,
        itemAvailability: {
          ...state.itemAvailability,
          summary: {
            ...state.itemAvailability.summary,
            ...action.payload,

            // If total > referralBonus; get the difference
            // If total === referralBonus; divide total by 2 to get 50%
            // If not the above
            total:
              parseInt(state.itemAvailability.summary?.total) >
              action.payload.referralBonus
                ? parseFloat(
                    state.itemAvailability.summary?.total -
                      action.payload.referralBonus
                  )
                : parseInt(state.itemAvailability.summary?.total) ===
                  action.payload.referralBonus
                ? parseFloat(state.itemAvailability.summary?.total / 2)
                : state.itemAvailability.summary?.total,
          },
        },
      };

    case Actions.GET_AVAILABLE_SERIAL_NUMBERS:
      return {
        ...state,
        availableSerialNumbers: action.payload,
      };

    default:
      return state;
  }
};

export default ItemReducer;
