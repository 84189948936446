import React from "react";
import { Link } from "react-router-dom";

const BottomSection = () => {
  return (
    <div className="about-bottom-section pt-14">
      <div className="container mx-auto px-4 py-16 lg:py-24 xl:py-32">
        <div className="mb-32 lg:mb-52">
          <div className="w-full lg:w-11/12 px-4 lg:px-6 mx-auto">
            <h1 className="text-temp-green-500 text-3xl lg:text-4xl xl:text-5xl leading-normal xl:leading-tight font-semibold pb-4 text-center">
              We love the nature
            </h1>
            <p className="text-base lg:text-lg xl:text-xl lg:leading-8 w-11/12 md:w-5/12 lg:w-5/12 xl:w-4/12 mx-auto text-center">
              Learn more about how we are saving the planet with our innovation
            </p>

            <div className="flex justify-center mt-10">
              <Link
                to={"/emission-savings"}
                className="bg-temp-green-100 py-4 px-8 flex items-center rounded-xl"
              >
                <p className="text-temp-green-500 font-medium">Read more</p>
                <p className="pl-3">
                  <svg
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7718 6.98115C17.0705 6.68245 17.0705 6.19816 16.7718 5.89946L11.9042 1.03183C11.6055 0.73313 11.1212 0.733131 10.8225 1.03183C10.5238 1.33053 10.5238 1.81483 10.8225 2.11353L15.1493 6.44031L10.8225 10.7671C10.5238 11.0658 10.5238 11.5501 10.8225 11.8488C11.1212 12.1475 11.6055 12.1475 11.9042 11.8488L16.7718 6.98115ZM0.867676 7.20518L16.231 7.20518L16.231 5.67543L0.867676 5.67543L0.867676 7.20518Z"
                      fill="#08A05C"
                    />
                  </svg>
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
