import React from "react";
import { Link } from "react-router-dom";
import HighlistItem from "../../../components/blocks/HighlistItem";

const WhatYouWillGetSection = () => {
    return (
        <div className="container mx-auto px-4 pt-16 pb-32 lg:pt-20 lg:pb-44">
            <div className="block mb-4">
                <h3 className="text-3xl lg:text-3xl xl:text-4xl font-semibold text-temp-primary">
                    What you will get
                </h3>
                <p className="text-temp-primary text-sm lg:text-base xl:text-lg py-4 leading-6">
                    A marketplace built for you. Do more with us.
                </p>
            </div>

            <div className="mt-10 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <HighlistItem
                    title="Repair"
                    content={`TempOwn insurance covers the repair cost of any item of our credible user that gets damaged while on rent.`}
                />

                <HighlistItem
                    title="Reimbursement"
                    content={`TempOwn protect will reimburse a Tempowner who falls victim to fraud.`}
                />

                <HighlistItem
                    title="Replacement"
                    content={`Stolen items of a Lender while on rent will be replaced by TempOwn.`}
                />
            </div>
            <div className="text-center mt-8 lg:mt-12 mb-12">
                <p className="text-sm lg:text-sm-15 text-temp-primary">Items and equipment without serial numbers are not covered. Learn more about our security 
                    deposits <Link to={"/policy/insurance#depositPolicy"} className="text-temp-secondary font-semibold">Here</Link></p>
            </div>
        </div>
    );
};

export default WhatYouWillGetSection;
