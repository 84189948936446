import React from "react";

const InputGroup3 = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <div className="mb-4">
      <input
        type={type || "text"}
        className={`w-full rounded-xl placeholder-temp-gray text-sm sm:text-base h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-temp-primary focus:outline-none bg-temp-green-100`}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className="text-red-600 text-xs lg:text-sm">{error}</span>
    </div>
  );
};

export default InputGroup3;
