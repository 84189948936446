import React from "react";
import Image from "../../assets/images/svg/user.svg";
import StarRating from "./StarRating";

const ReviewCard = ({ data }) => {
  return (
    <div className="relative">
      <div className="w-full flex flex-wrap items-center justify-between">
        <div className="flex flex-wrap items-center space-x-4 mb-3">
          <img
            src={data?.userId?.image || Image}
            className="h-12 w-12 lg:h-14 lg:w-14 object-cover rounded-full"
            alt=""
          />
          <div className="flex flex-col">
            <p className="text-base lg:text-lg text-temp-primary font-semibold">
              {data?.userId?.firstname}
            </p>
            <p className="text-xs lg:text-sm text-temp-gray pt-1">
              {new Date(data?.createdAt).toDateString()}
            </p>
          </div>
        </div>

        <div className="flex flex-shrink mt-1 mb-3">
          <StarRating
            rating={data?.rating}
            starDimension="18px"
            starSpacing="1px"
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-between mt-6">
        <div className="w-full lg:w-7/12 mb-3">
          <p className="leading-6 lg:leading-7 text-temp-gray text-sm lg:text-base">
            {data?.review}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
