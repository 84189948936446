import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../components/blocks/Loader";
import NoRecordReturned from "../../components/blocks/NoRecordReturned";
import MainContainer from "../../components/layout/MainContainer";
import Conversation from "./components/Conversation";
import * as actions from "../../store/actions";
import store from "store";
import { convertSecondsToDateOnly } from "../../utils/timeFormatAMPM";
import { scrollToTop } from "../../utils/scrollToTop";

const Chat = (props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [isEmptyUserlist, setIsEmptyUserlist] = useState(null);
  const [conversationList, setConversationList] = useState([]);
  const [
    formattedConversationsByDay,
    setFormattedConversationsByDay,
  ] = useState([]);
  const [currentUserUid, setCurrentUserUid] = useState("");
  const [message, setMessage] = useState("");

  // Get list of users
  const fetchRealTimeUsers = async () => {
    setLoading(true);
    try {
      const userId = store.get("tempOwnfiReBaseUserAuth");
      setCurrentUserUid(JSON.parse(userId)?.userId);
      await props.getRealtimeFirebaseChatUsersApi(JSON.parse(userId)?.userId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Get list of all conversation inorder to get the last messages to be displayed on the User listing component
  const fetchConversationList = async () => {
    await props.getRealtimeConversationsListApi(currentUserUid);
  };

  useEffect(() => {
    scrollToTop();

    let unsubscribe;
    unsubscribe = fetchRealTimeUsers()
      .then((unsubscrib) => {
        return unsubscrib;
      })
      .catch((error) => error);

    return () => {
      // Cleanup
      unsubscribe.then((f) => f()).catch((error) => error);
    };
  }, []);

  // set list of users and fetch the top listed user
  useEffect(() => {
    if (props?.chatUsers?.length > 0) {
      setUsers(props?.chatUsers);

      fetchConversationList();

      initializeChatWithUser(props?.chatUsers[0]);
      setIsEmptyUserlist(false);
    } else {
      setIsEmptyUserlist(true);
    }
  }, [props?.chatUsers]);

  // update conversations for each users selected
  useEffect(() => {
    setConversations(props.conversations);

    // reformat the messages in days
    const grouped = props.conversations?.reduce((acc, cum) => {
      const found = acc.find(
        (i) =>
          convertSecondsToDateOnly(i?.createdAt?.seconds) ===
          convertSecondsToDateOnly(cum?.createdAt?.seconds)
      );

      const messages = {
        createdAt: cum.createdAt,
        isView: cum.isView,
        message: cum.message,
        user_uid_1: cum.user_uid_1,
        user_uid_2: cum.user_uid_2,
      };

      if (found) {
        found.conversations = [...found.conversations, messages];
      } else {
        acc.push({
          createdAt: cum.createdAt,
          conversations: [messages],
        });
      }

      return acc;
    }, []);

    setFormattedConversationsByDay(grouped);
  }, [props.conversations]);

  //
  useEffect(() => {
    if (props.conversationsListWithMessage) {
      setConversationList(props.conversationsListWithMessage);
    }
  }, [props.conversationsListWithMessage]);

  // Fetch conversations when click on any users on the list
  const [seletectedChatUser, setSeletectedChatUser] = useState(undefined);
  const initializeChatWithUser = async (user) => {
    setSeletectedChatUser(user);

    const payload = {
      uid_1: currentUserUid,
      uid_2: user?.userId,
    };

    await props.getRealtimeConversationsApi(payload);
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      user_uid_1: currentUserUid,
      user_uid_2: seletectedChatUser?.userId,
      message,
    };

    if (message !== "") {
      await props.sendChatMessageApi(payload);

      setMessage("");
    }
  };

  return (
    <MainContainer hasMainContainer={false} noFooter>
      <div className="container px-4 mx-auto w-full py-10 lg:py-16 pb-32 lg:pb-40">
        <div className="flex justify-between items-baseline flex-wrap">
          <h3 className="font-medium text-2xl lg:text-3xl text-temp-primary pb-3">
            My Messages
          </h3>
        </div>

        <div className="">
          {loading ? (
            <div className="my-20">
              <Loader />
            </div>
          ) : !isEmptyUserlist ? (
            <Conversation
              users={users}
              conversationList={conversationList}
              initializeChatWithUser={initializeChatWithUser}
              seletectedChatUser={seletectedChatUser}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              message={message}
              conversations={conversations}
              formattedConversationsByDay={formattedConversationsByDay}
              currentUserUid={currentUserUid}
            />
          ) : (
            <div className="my-20">
              <NoRecordReturned selectedImage={2} title="No message to show." />
            </div>
          )}
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  chatUsers: state?.chat?.chatUsers,
  conversations: state?.chat?.conversations,
  conversation: state?.chat?.conversation,
  conversationsListWithMessage: state?.chat?.conversationsListWithMessage,
});

export default connect(mapStateToProps, actions)(Chat);
