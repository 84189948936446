import Actions from "../actions/actionTypes";

const initialState = {
  orders: [],
  order: {},
  declineStatus: "",
  confirmPickupStatus: false,
  confirmReturnStatus: false,
  pagination: {},
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CREATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case Actions.GET_ORDERS:
      return {
        ...state,
        orders: action.payload?.docs,
        pagination: {
          hasNextPage: action.payload?.hasNextPage,
          hasPrevPage: action.payload?.hasPrevPage,
          limit: action.payload?.limit,
          nextPage: action.payload?.nextPage,
          page: action.payload?.page,
          pagingCounter: action.payload?.pagingCounter,
          prevPage: action.payload?.prevPage,
          totalDocs: action.payload?.totalDocs,
          totalPages: action.payload?.totalPages,
        },
      };

    case Actions.GET_SINGLE_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case Actions.ACCEPT_ORDER:
      return {
        ...state,
        orders: state.orders?.filter((a) => a._id !== action.payload),
      };

    case Actions.DECLINE_ORDER:
      return {
        ...state,
        orders:
          action.payload?.status === "success"
            ? state.orders?.filter((a) => a._id !== action.payload?.orderId)
            : state.orders,
        declineStatus: action.payload?.status,
      };

    case Actions.CONFIRM_ORDER_PICKUP:
      return {
        ...state,
        confirmPickupStatus: action.payload.confirmPickupStatus,
        orders:
          action.payload.confirmPickupStatus === "success" &&
          state.orders.map((order) =>
            order._id === action.payload?.data?._id
              ? action.payload?.data
              : order
          ),
        order:
          action.payload.confirmPickupStatus === "success"
            ? action.payload?.data
            : state.order,
      };

    case Actions.CONFIRM_ORDER_RETURN:
      return {
        ...state,
        confirmReturnStatus: action.payload.confirmReturnStatus,
        orders:
          action.payload.confirmReturnStatus === "success" &&
          state.orders.map((order) =>
            order._id === action.payload?.data?._id
              ? action.payload?.data
              : order
          ),
        order:
          action.payload.confirmReturnStatus === "success"
            ? action.payload?.data
            : state.order,
      };

    default:
      return state;
  }
};

export default OrderReducer;
