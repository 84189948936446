import Actions from "../actions/actionTypes";

const initialState = {
  createReviewStatus: {
    type: "",
    message: "",
  },
};

const ReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CREATE_REVIEW:
      return {
        ...state,
        createReviewStatus: action.payload,
      };

    default:
      return state;
  }
};

export default ReviewReducer;
