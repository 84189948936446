import moment from "moment";
import { capitalizeFistLetterInSentence } from "../utils/capitalizeFirstLetterInSentence";
import { numberFormat } from "../utils/numberFormat";

export const getFilteredRentalExtensionsData = (extensions = []) => {
    return extensions?.filter((a) => a?.status === "accepted");
};

export const getRentalTotalAmount = (data = {}, isInitialAmount = false) => {
    const total = data?.total;
    const extensions = data?.extensions;
    
    if (!isInitialAmount) {
        if (total && extensions?.length > 0) {
            const filterAcceptedExtension = getFilteredRentalExtensionsData(
                extensions
            );
            let totalExtensionAmount = 0;

            for (let i = 0; i <= filterAcceptedExtension.length - 1; i++) {
                totalExtensionAmount += filterAcceptedExtension[i].total;
            }
            const finalTotal = totalExtensionAmount + total;
            return `₦${numberFormat(finalTotal)}`;
        } else {
            return `₦${numberFormat(total || 0)}`;
        }
    } else {
        return `₦${numberFormat(total || 0)}`;
    }
};

// Get rental unit type
// return string;
export const getRentalUnitType = (data = {}) => {
    const { rentalUnitType, siUnitType, calenderType } = data?.itemId || data;
    if (rentalUnitType) {
        if (rentalUnitType === "bySiUnits") {
            return siUnitType || "hour";
        } else {
            return calenderType || "day";
        }
    }
    return "";
};

export const getRentalUnitOrdered = (data = {}) => {
    const dayCount = data?.dayCount
    const extensions = data?.extensions
    const unitOrdered = data?.unitOrdered
    const rentalUnitType = data?.itemId?.rentalUnitType;
    const filterAcceptedExtension = getFilteredRentalExtensionsData(extensions);

    const _getTotalExtensionsUnitOrdered = (unitType) => {
        let totalExtensionUnitOrdered = 0;
        for (let i = 0; i <= filterAcceptedExtension.length - 1; i++) {
            totalExtensionUnitOrdered += parseInt(
                filterAcceptedExtension[i]?.[unitType]
            );
        }
        return totalExtensionUnitOrdered;
    };

    if (rentalUnitType === "bySiUnits") {
        if (filterAcceptedExtension?.length > 0) {
            return (
                _getTotalExtensionsUnitOrdered("unitOrdered") + parseInt(unitOrdered)
            );
        } else {
            return parseInt(unitOrdered) || 1;
        }
    } else {
        if (filterAcceptedExtension?.length > 0) {
            return _getTotalExtensionsUnitOrdered("dayCount") + parseInt(dayCount);
        } else {
            return parseInt(dayCount) || 1;
        }
    }
};

export const getRentalUnitOrderedAndType = (data = {}) => {
    const rentalType = getRentalUnitType(data);
    const unitOrdered = getRentalUnitOrdered(data);

    const finalRentalType =
        rentalType?.toString().toLowerCase() === "day" && unitOrdered > 1
            ? "days"
            : rentalType;

    return unitOrdered + " " + finalRentalType;
};

export const getRentalStartDate = (data = {}, format) => {
    const startDate = data?.startDate;
    return moment(startDate || "").format(format || "MMMM D");
};

export const getRentalEndDate = (data = {}, isInitialEndDate = false) => {
    const endDate = data?.endDate
    const extensions = data?.extensions

    if (!isInitialEndDate) {
        if (extensions?.length > 0) {
            const filterAcceptedExtension = getFilteredRentalExtensionsData(
                extensions
            );
            const extensionEndDate =
                filterAcceptedExtension[filterAcceptedExtension.length - 1]?.endDate;
            return moment(extensionEndDate).format("MMMM D, YYYY");
        } else {
            return moment(endDate).format("MMMM D, YYYY");
        }
    } else {
        return moment(endDate).format("MMMM D, YYYY");
    }
};

export const getRentalStartAndEndDate = (data = {}) => {
    const startDate = getRentalStartDate(data);
    const endDate = getRentalEndDate(data);
    return startDate + " - " + endDate;
};

// Get Reental Name
// Parameter:
// data:object,
// maxLenth?: number (length to check greater than),
// maxSliceLength?: number (length to slice out)
export const getRentalItemName = (data = {}, maxLength, maxSliceLength) => {
    const name = data?.name || "";
    if (maxLength && maxSliceLength) {
        if (name?.length > maxLength) {
            return capitalizeFistLetterInSentence(name?.slice(0, maxSliceLength)) + "...";
        } else {
            return capitalizeFistLetterInSentence(name);
        }
    }
    return name;
};
