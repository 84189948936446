import React from "react";
import Loader from "../../components/blocks/Loader";
import { CheckMarkCircleIcon } from "../../components/svgs";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MapIcon from "../../assets/images/svg/map.svg";

const AddressCard = ({ address, handleSelectAddress }) => {
  return (
    <div
      key={address._id}
      className={`w-72 h-full text-left inline-flex flex-col justify-between items-start pt-5 lg:pt-7 rounded-2xl ${
        address.isDefault ? "border border-temp-green-light" : "border-thin"
      }`}
    >
      <p
        className={`px-6 lg:px-8 pb-6 lg:pb-8 text-sm lg:text-sm-15 leading-6 w-11/12 h-20 overflow-auto ${
          address.isDefault ? "text-temp-primary" : "text-temp-gray"
        }`}
      >
        {address.address?.length > 60
          ? address.address.slice(0, 60) + "..."
          : address.address}
      </p>

      <div
        className={`h-12 py-3 pr-8 w-full relative ${
          address.isDefault
            ? "border-t border-temp-green-light"
            : "border-thin-top"
        }`}
      >
        {address.isDefault ? (
          <button
            type="button"
            className="w-full text-left inline-flex justify-between items-center focus:outline-none"
          >
            <p className="text-temp-green-500 text-xs md:text-sm px-8 flex-grow">
              Selected Address
            </p>
            <CheckMarkCircleIcon />
          </button>
        ) : (
          <button
            type="button"
            onClick={handleSelectAddress}
            className="w-full text-left inline-flex justify-between items-center focus:outline-none"
          >
            <p className="text-temp-gray text-sm px-8 flex-grow">
              I want to use this
            </p>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="7.5" fill="white" stroke="#616564" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

const PickUpLocation = ({
  handleShowAddAddressModal,
  addresses,
  loading,
  handleSelectAddress,
}) => {
  return (
    <div className="pt-2">
      <div className="w-full flex flex-wrap justify-between text-left">
        <div className="w-full mx-auto">
          {loading ? (
            <div className="relative flex justify-center w-full border border-temp-gray-light py-8 px-6 rounded-lg">
              <Loader />
            </div>
          ) : addresses?.length > 0 ? (
            <>
              <div className="">
                <OwlCarousel
                  className="owl-carousel owl-theme testimonial-slider"
                  items={3}
                  margin={25}
                  loop={true}
                  autoWidth={true}
                  nav={false}
                  dots={true}
                  autoplay={false}
                  responsiveClass={true}
                  responsive={{
                    0: {
                      items: 1,
                      nav: false,
                      loop: false,
                      dots: false
                    },
                    600: {
                      items: 1,
                      nav: false,
                      loop: false,
                      dots: false
                    },
                    1000: {
                      items: 2,
                      nav: false,
                      loop: false,
                    },
                  }}
                >
                  {addresses
                    ?.sort((a) => {
                      if (a.isDefault === true) {
                        return -1;
                      } else {
                        return 0;
                      }
                    })
                    .map((a) => (
                      <React.Fragment key={a._id}>
                        <AddressCard
                          address={a}
                          handleSelectAddress={() => handleSelectAddress(a)}
                        />
                      </React.Fragment>
                    ))}
                </OwlCarousel>

                <button
                  type="button"
                  onClick={handleShowAddAddressModal}
                  className="pt-4 lg:pt-6 text-sm lg:text-sm-15 font-bold text-temp-green-500 border-none outline-none focus:outline-none"
                >
                  Add a new address
                </button>
              </div>
            </>
          ) : (
            <div className="relative flex justify-center w-full border border-temp-gray-light py-8 px-6 rounded-lg">
              <div className="mx-auto text-center">
                <div className="">
                  <img src={MapIcon} className="mx-auto h-10 lg:h-12" alt="" />
                </div>

                <p className="py-4 lg:py-5 text-temp-secondary text-sm">
                  No address added yet
                </p>

                <button
                  type="button"
                  onClick={handleShowAddAddressModal}
                  className="font-bold text-temp-green-500 border-none outline-none focus:outline-none"
                >
                  Add address
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PickUpLocation;
