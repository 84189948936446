import Compress from "compress.js";

const compress = new Compress();

export const compressImage = (files, processDate) => {
  compress
    .compress(files, {
      size: 4, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 1920, // the max width of the output image, defaults to 1920px
      maxHeight: 1920, // the max height of the output image, defaults to 1920px
      resize: true,
    })
    .then((data) => {
      const img1 = data[0];
      const base64str = img1.data;
      const imgExt = img1.ext;
      const file = Compress.convertBase64ToFile(base64str, imgExt);

      processDate(file);
    });
};
