import React from "react";
import ItemListingInCarousel from "../../../components/blocks/itemBits/ItemListingInCarousel";

const PopularItemsSection = ({ items, itemsLoading }) => {
  return (
    <ItemListingInCarousel
      sectionTitle="Newly Listed"
      items={items}
      itemsLoading={itemsLoading}
    />
  );
};

export default PopularItemsSection;
