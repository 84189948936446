import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import store from "store";
import Actions from "./actionTypes";

export const getUserApi = (userId) => async (dispatch) => {
  dispatch(alertActions.clear());
  dispatch({
    type: Actions.INITIATE_USER_DETAILS,
    payload: {},
  });

  const url = userId ? `/user/${userId}` : "/user";
  try {
    const response = await axios.get(url);
    const responseData = response.data?.data;

    dispatch({
      type: Actions.GET_USER_DETAILS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const updateUserProfileApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/user";
  try {
    const response = await axios.put(url, payload);
    const responseData = response.data.data;

    const userData = store.get("temp_usER12_dATa");

    store.set("temp_usER12_dATa", {
      ...userData,
      ...responseData,
    });

    dispatch({
      type: Actions.UPDATE_USER,
      payload: {
        type: "success",
        message: response.data.message,
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.UPDATE_USER,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: Actions.UPDATE_USER,
      payload: {
        type: "error",
        message: error.response.data.message,
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.UPDATE_USER,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  }
};

export const updateUserProfilePictureApi =
  (payload, showSuccessMessage) => async (dispatch) => {
    dispatch(alertActions.clear());

    const url = "/user/profile/image";
    try {
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const responseData = response.data.data;

      const userData = store.get("temp_usER12_dATa");

      store.set("temp_usER12_dATa", {
        ...userData,
        image: responseData.url,
      });

      if (showSuccessMessage) {
        dispatch(alertActions.success(response.data.message));
      }
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
    }
  };
