/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { history } from "../helpers/History";
import useSocketNotification from "../helpers/hooks/useSocketNotification";
import About from "../pages/about/About";
import ForgotPassword from "../pages/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";
import Signin from "../pages/auth/signin/Signin";
import NINSignup from "../pages/auth/signup/NINSignup";
import Signup from "../pages/auth/signup/Signup";
import SignupSuccessful from "../pages/auth/signup/SignupSuccessful";
import CompleteVerification from "../pages/auth/verification/CompleteVerification";
import UploadProfilePicture from "../pages/auth/verification/UploadProfilePicture";
import Verification from "../pages/auth/verification/Verification";
import VerifyEmail from "../pages/auth/verification/VerifyEmail";
import VerifyUserIdentity from "../pages/auth/verification/VerifyUserIdentity";
import Category from "../pages/category/Category";
import Chat from "../pages/chat/Chat";
import EmissionSavings from "../pages/emission_savings/EmissionSavings";
import Enterprise from "../pages/enterprise/Enterprise";
import FaqDetails from "../pages/faqs/FaqDetails";
import Faqs from "../pages/faqs/Faqs";
import Home from "../pages/home/Home";
import ItemOverview from "../pages/items/ItemOverview";
import ItemsAvailabilityAndCheckout from "../pages/items_availablitity_and_checkout/ItemsAvailabilityAndCheckout";
import LateRental from "../pages/late_rental/LateRental";
import LenderGetStarted from "../pages/lender/lender_get_started/LenderGetStarted";
import LenderHome from "../pages/lender/lender_home/LenderHome";
import LenderItemEdit from "../pages/lender/lender_items/LenderItemEdit";
import LenderItems from "../pages/lender/lender_items/LenderItems";
import LenderItemsDetails from "../pages/lender/lender_items/LenderItemsDetails";
import LenderRentalRequest from "../pages/lender/lender_rental_requests/LenderRentalRequest";
import LenderUploadItemForm from "../pages/lender/lender_upload_item/LenderUploadItemForm";
import LenderUploadItemStart from "../pages/lender/lender_upload_item/LenderUploadItemStart";
import LenderWallet from "../pages/lender/lender_wallet/LenderWallet";
import LenderBorrowerProfile from "../pages/lender_borrower_profile/LenderBorrowerProfile";
import Notifications from "../pages/notifications/Notifications";
import Policy from "../pages/policy/Policy";
import Terms from "../pages/policy/Terms";
import AccountInformation from "../pages/profile/AccountInformation";
import Address from "../pages/profile/Address";
import CardAndBanks from "../pages/profile/CardAndBanks";
import NotificationSettings from "../pages/profile/NotificationSettings";
import ProfileMain from "../pages/profile/ProfileMain";
import Security from "../pages/profile/Security";
import Emission from "../pages/profile/emission/Emission";
import Refer from "../pages/referral/Refer";
import Referred from "../pages/referral/Referred";
import RentalActivities from "../pages/rental_activities/RentalActivities";
import RentalActivitiesDetails from "../pages/rental_activities_details/RentalActivitiesDetails";
import Search from "../pages/search/Search";
import SearchByLocation from "../pages/search/SearchByLocation";
import SearchByLocationResult from "../pages/search/SearchByLocationResult";
import TempownProtect from "../pages/tempownProtect";
import UploadItemImages from "../pages/upload_item_images/UploadItemImages";
import configureStore from "../store";
import { alertActions } from "../store/actions";
import visitRecord from "../store/actions/visitActions";
// import useFirebaseRealTimeChatNotification from "../helpers/hooks/useFirebaseRealTimeChatNotification";

const MainRoutes = () => {
  history.listen(() => {
    const store = configureStore();
    store.dispatch(alertActions.clear());
  });
  const [cookies, setCookie] = useCookies(["visits"]);
  const dispatch = useDispatch();
  useEffect(() => {
    setCookie(
      "visit",
      cookies.visit === undefined ? 1 : parseInt(cookies.visit) + 1,
      {
        path: "/",
      }
    );
    dispatch(visitRecord({ visit: cookies.visit }));
  });

  // NEW CHAT MESSAGE POPUP ON REALTIME
  // useFirebaseRealTimeChatNotification(
  //   props.getRealtimeConversationsListApi,
  //   props.conversationsListWithMessage
  // );

  // REALT TIME SOCKET NOTIFICATION
  useSocketNotification();

  return (
    <BrowserRouter>
      <Routes>
           <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/enterprise" element={<Enterprise />} />
        <Route path="/emission-savings" element={<EmissionSavings />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/tempown-signup-with-nin-only" element={<NINSignup />} />
        <Route path="/signup-successful" element={<SignupSuccessful />} />
        <Route path="/verify" element={<Verification />} />
        <Route path="/complete-verification" element={<CompleteVerification />} />
        <Route path="/verify-identity" element={<VerifyUserIdentity />} />
        <Route path="/upload-picture" element={<UploadProfilePicture />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/search" element={<Search />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/faqs/:slug" element={<FaqDetails />} />
        <Route path="/category" element={<Category />} />
        <Route path="/search-by-location" element={<SearchByLocation />} />
        <Route path="/search-by-location/:slug" element={<SearchByLocationResult />} />

        {/* Lender */}
        <Route path="/lender" element={<LenderHome />} />
        <Route path="/get-started" element={<LenderGetStarted />} />
        <Route path="/upload-item/start" element={<LenderUploadItemStart />} />
        <Route path="/upload-item/submission" element={<LenderUploadItemForm />} />
        <Route path="/my-items" element={<LenderItems />} />
        <Route path="/my-items/:slug" element={<LenderItemsDetails />} />
        <Route path="/my-items/:slug/edit" element={<LenderItemEdit />} />
        <Route path="/my-wallet" element={<LenderWallet />} />
        <Route path="/rental-requests" element={<LenderRentalRequest />} />
        <Route path="/profile" element={<ProfileMain />} />
        <Route path="/profile/account-information" element={<AccountInformation />} />
        <Route path="/profile/address" element={<Address />} />
        <Route path="/profile/cards-and-banks" element={<CardAndBanks />} />
        <Route path="/profile/security" element={<Security />} />
        <Route path="/profile/notification-settings" element={<NotificationSettings />} />
        <Route path="/profile/emission-savings" element={<Emission />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/refer" element={<Refer />} />
        <Route path="/refer/:slug" element={<Referred />} />

        {/* Lender & Borrower Visitor's Profile */}
        <Route path="/lender-profile/:slug" element={<LenderBorrowerProfile />} />
        <Route path="/borrower-profile/:slug" element={<LenderBorrowerProfile />} />

        <Route path="/checkout" element={<ItemsAvailabilityAndCheckout />} />
        <Route path="/rental-activities" element={<RentalActivities />} />
        <Route path="/rental-activities/order" element={<RentalActivitiesDetails />} />

        {/* Late rental return */}
        <Route path="/late-rental" element={<LateRental />} />

        <Route path="/order/upload-image" element={<UploadItemImages />} />

        {/* Policy */}
        <Route path="/policy" element={<Policy />} />
        <Route path="/policy/:slug" element={<Policy />} />

        {/* Borrower */}
        <Route path="/items/:slug" element={<ItemOverview />} />

        {/* Terms and conditions */}
        <Route path="/terms-of-service" element={<Terms />} />

        {/* Chat */}
        <Route path="/messages" element={<Chat />} />

        {/* Insurance policy */}
        <Route path="/tempown-protect" element={<TempownProtect />} />
      </Routes>
    </BrowserRouter>
  );
};

// const mapStateToProps = (state) => ({
//   conversationsListWithMessage: state?.chat?.conversationsListWithMessage,
// });

export default MainRoutes
