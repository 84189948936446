import React, { useEffect, useState } from "react";
import SearchForm from "../../../components/blocks/SearchForm";

import CameraImage from "../../../assets/images/png/home/camera.webp";
import DroneImage from "../../../assets/images/png/home/drone.webp";
import TractorImage from "../../../assets/images/png/home/tractor.webp";
import CapsuleImage from "../../../assets/images/png/home/capsule.webp";

import ReeddiLogo from "../../../assets/images/svg/logo/reeddi.svg";

const TopSection = () => {
  const data = [
    {
      id: 1,
      name: "drone",
      image: DroneImage,
    },
    {
        id: 2,
        name: "capsule",
        image: CapsuleImage,
      },
    {
      id: 3,
      name: "camera",
      image: CameraImage,
    },
    {
      id: 4,
      name: "tractor",
      image: TractorImage,
    },
  ];

  let current = 0;
  const [selectedData, setSelectedData] = useState(data[0]);

  useEffect(() => {
    function nextBackground(slide, set) {
      current++;
      current = current % data.length;
      set(data[current]);
    }
    setInterval(() => nextBackground(data, setSelectedData), 2000);

    setSelectedData(data[0]);
  }, []);

  return (
    <section className="home-top-section">
      <div className="container mx-auto px-4 relative pt-14 md:pt-20 lg:pt-32 pb-28">
        <div className="relative flex flex-wrap flex-row">
          <div className="w-full md:w-7/12 lg:w-6/12 2xl:w-5/12">
            <h2 className="font-semibold text-temp-primary text-4xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-7xl">
              Own a{" "}
              <span className="text-temp-secondary">{selectedData?.name}</span>{" "}
              temporarily today!
            </h2>

            <p className="text-base lg:text-lg 2xl:text-xl text-temp-gray mt-6 lg:mt-8">
              TempOwn connects individuals, creatives and project executives to
              thousands of equipment renting owners.
            </p>

            <SearchForm />
          </div>
          <div className="w-full lg:w-6/12 2xl:w-5/12 home-top-section-right relative mt-12 lg:mt-4 mx-auto">
            <div className="w-10/12 md:w-6/12 lg:w-10/12 mx-auto h-52 md:h-auto object-contain">
              <img src={selectedData?.image} className="object-contain h-52 md:h-64 lg:h-auto" alt="" />
            </div>
          </div>
        </div>

        <div className="mx-auto flex justify-center mt-4">
          <div className="absolute bottom-0 mx-auto">
            <div className="mx-auto flex flex-row px-5 pt-2 lg:pt-4 pb-1 lg:pb-2 justify-center items-center space-x-3 text-xs lg:text-sm font-medium home-top-section-reeddi ">
              <p>A product of</p>
              <div className="">
                <a
                  href="https://www.reeddi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ReeddiLogo} alt="Reeddi" />
                </a>
              </div>
              <p>
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.33566 2.87675L1.31492 7.8975L0.490082 7.07267L5.51025 2.05192H1.08567V0.885254H7.50233V7.30192H6.33566V2.87675Z"
                    fill="black"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopSection;
