import Actions from "../actions/actionTypes";

const initialState = {
  emissions: {},
  emissionsLB: {},
};

const EmissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD_EMISSION:
      return {
        ...state,
        createItemStatus: action.payload, //? edit this ASAP
      };

    case Actions.GET_EMISSIONS:
      return {
        ...state,
        emissions: action.payload,
      };

    case Actions.GET_EMISSION_LB:
      return {
        ...state,
        emissionsLB: action.payload,
      };

    default:
      return state;
  }
};

export default EmissionReducer;
