import React, { useEffect, useState } from "react";
import { } from "react-router-dom";
import MainContainer from "../../components/layout/MainContainer";
import { scrollToTop } from "../../utils/scrollToTop";
import TermsAndCondtions from "./component/Terms_of_service";

const Terms = (props) => {
  const [routedLink, setRoutedLink] = useState("");

  useEffect(() => {
    const url = props.location.pathname?.slice(8);
    setRoutedLink(url);

    scrollToTop();
  }, [props.location.pathname]);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="container w-full md:w-11/12 lg:w-9/12 xl:w-8/12 text-temp-primary mx-auto px-4 py-4">
        <div className="pt-14 md:pb-4">
          <h3 className="font-semibold text-2xl md:text-3xl lg:text-4xl text-temp-green-500 leading-7 pb-10 lg:pb-12">
            Terms of Service
          </h3>
          <div>
            <>
              <div className="mb-2">
                <TermsAndCondtions routedLink={routedLink} />
              </div>
            </>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default (Terms);
