import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full bg-gray-200 h-1">
      <div className="bg-temp-green-500 h-1" style={{ width: progress }}></div>
    </div>
  );
};

export default ProgressBar;
