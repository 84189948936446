import Actions from "../actions/actionTypes";

const initialState = {
  referralDetails: {},
  referredList: [],
};

const ReferralReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_LOGGEDIN_REFERRAL_DETAILS:
      return {
        ...state,
        referralDetails: action.payload,
      };

    case Actions.GET_REFERRED_LIST:
      return {
        ...state,
        referredList: action.payload,
      };

    default:
      return state;
  }
};

export default ReferralReducer;
