import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from 'react-router-dom';
import Loader from "../../components/blocks/Loader";
import MainContainer from "../../components/layout/MainContainer";
import { BellIcon } from "../../components/svgs";
import * as actions from "../../store/actions";
import Paginator from "../../utils/pagination";
import MessageModal from "./components/MessageModal";

const EmptyNotification = () => {
  return (
    <div className="text-center mb-10">
      <div className="mb-10">
        <BellIcon className="mx-auto" />
      </div>
      <p className="text-temp-primary text-sm lg:text-base">
        No notifications yet
      </p>
    </div>
  );
};

const NotificationItem = ({ name, value, handleShowMessageModal, isRead }) => {
  return (
    <>
      <div
        className="py-2 flex justify-between items-center cursor-pointer"
        onClick={() => handleShowMessageModal()}
      >
        <div className="text-xs leading-5 md:text-sm lg:text-sm-15 lg:leading-7">
          <span className={`${!isRead && "font-semibold"}`}>
            {name} <span className="pl-2">-</span>
          </span>{" "}
          <span className="lg:ml-3 font-light">{value}</span>
        </div>
        {!isRead && (
          <span className="text-right text-xs px-2 pt-1 pb-1 text-white font-medium bg-temp-orange rounded-xl">
            New
          </span>
        )}
      </div>
      <hr className="my-1 border-t border-temp-green-light" />
    </>
  );
};

const Notifications = (props) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [pagination, setPagination] = useState({});
  const [groupedNotifications, setGroupedNotifications] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleShowMessageModal = (notification) => {
    setShowMessageModal(!showMessageModal);
    setSelectedNotification(notification);
    const justRead = props.userNotifications.filter(
      (e) => e._id === notification._id
    )[0];
    justRead.seen = true;
    const OtherNotifications = props.userNotifications.filter(
      (e) => e._id !== notification._id
    );
    const GroupedNotifications = groupAndSortNotifications([
      ...OtherNotifications,
      justRead,
    ]);
    setGroupedNotifications(GroupedNotifications);
    props.markUserNotificationsApi(notification._id);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
    setSelectedNotification({});
  };

  const fetchNotifications = async (params) => {
    setLoading(true);
    await props.getUserNotificationsApi(params);
    setLoading(false);
  };

  const groupAndSortNotifications = (notifications) => {
    const GroupedNotificationsMap = notifications.reduce(
      (entryMap, e) =>
        entryMap.set(moment(e.createdAt).format("MMMM D YYYY"), [
          ...(entryMap.get(moment(e.createdAt).format("MMMM D YYYY")) || []),
          e,
        ]),
      new Map()
    );
    const GroupedNotifications = Array.from(GroupedNotificationsMap.entries());

    GroupedNotifications.forEach((e) =>
      e[1].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
    );

    return GroupedNotifications.sort(function (a, b) {
      return new Date(b[0]) - new Date(a[0]);
    });
  };

  useEffect(() => fetchNotifications(), []);
  useEffect(() => {
    setPagination(props.pagination);
    if (props.userNotifications) {
      const GroupedNotifications = groupAndSortNotifications(
        props.userNotifications
      );
      setGroupedNotifications(GroupedNotifications);
    }
  }, [props.userNotifications]);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="w-full pt-10 lg:pt-16 pb-36 relative container px-4 mx-auto">
        <h3 className="font-medium mb-4 text-xl md:text-2xl lg:text-2xl text-temp-primary">
          Notifications
        </h3>
        <div className="w-full md:w-10/12 lg:w-8/12 xl:w-6/12 mx-auto mt-14 lg:mt-20">
          {loading ? (
            <div className="my-14">
              <Loader />
            </div>
          ) : groupedNotifications?.length > 0 ? (
            groupedNotifications.map((item) => (
              <>
                <p className="text-sm lg:text-base mt-8 mb-3 text-temp-green-500 font-light uppercase">
                  {moment(item[0]).format("MMMM Do YYYY")}
                </p>
                {item[1].map((notification) => (
                  <div className="text-temp-primary" key={notification?._id}>
                    <NotificationItem
                      key={notification?._id}
                      name={notification?.data?.subject}
                      value={notification?.data?.body}
                      cta={notification?.data?.cta}
                      ctaUrl={notification?.data?.url}
                      isRead={notification?.seen}
                      handleShowMessageModal={() =>
                        handleShowMessageModal(notification)
                      }
                    />
                  </div>
                ))}
              </>
            ))
          ) : (
            <EmptyNotification />
          )}
        </div>
        {
          groupedNotifications?.length > 0 && <div className="mt-20">
            <Paginator
              pageLimit={pagination?.limit}
              maxPages={pagination?.totalPages}
              currentPage={pagination?.page}
              getPageApi={fetchNotifications}
              setLoading={setLoading}
            />
          </div>
        }

      </div>

      {/* Show notification message modal */}
      {showMessageModal && (
        <MessageModal
          show={showMessageModal}
          handleCloseModal={handleCloseModal}
          selectedNotification={selectedNotification}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  userNotifications: state.notifications.userNotifications,
  pagination: state.notifications.pagination,
});

export default (connect(mapStateToProps, actions)(Notifications));
