import React, { useState } from "react";
import AvatarImage from "../../../assets/images/png/rex1.png";
import CardImage from "../../../assets/images/png/bigframe.jpg";
import UploadBorrowerDetails from "./UploadBorrowerDetails";
import EquipmentLocation from "../../../components/blocks/itemBits/EquipmentLocation";
import { getRentalItemName, getRentalStartAndEndDate, getRentalUnitOrderedAndType } from "../../../helpers/rentalFlexibilityHelper";

const OrderDetailsLeftSide = ({ itemDetails, orderDetails }) => {
  return (
    <div className="w-10/12 flex flex-wrap flex-row items-start justify-start">
      <div className="relative w-16 h-12 rounded-xl bg-primary object-cover">
        <img
          src={itemDetails?.images?.[0]?.image?.url || CardImage}
          className="object-cover w-16 h-12 rounded-xl"
          alt=""
        />

        <div
          style={{ fontSize: ".5rem", padding: ".2rem .4rem" }}
          className="absolute top-2 right-2 rounded-md bg-temp-green-500 font-semibold text-white"
        >
          {orderDetails?.quantity}
        </div>
      </div>
      <div className="pl-2 flex-1">
        <p className="text-temp-primary font-medium text-sm-15">
          {getRentalItemName(itemDetails, 16, 14)}
        </p>
        <div className="text-temp-primary flex flex-wrap flex-col item-center">
          <div className="-mt-4">
            <EquipmentLocation item={itemDetails} className="" />
          </div>

          <div className="-mt-4">
            <p
              className="text-temp-green-500 font-semibold"
              style={{ fontSize: ".6rem" }}
            >
              <span className="text-temp-primary font-semibold">
                {getRentalUnitOrderedAndType({
                    ...orderDetails,
                    itemId: {
                        ...itemDetails
                    }
                })}
              </span>{" "}
              ({getRentalStartAndEndDate(orderDetails)})
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserDetailsModal = ({ orderDetails, handleClose }) => {
  return (
    <div
      onMouseLeave={handleClose}
      className="absolute bottom-20 right-4 bg-white z-20 px-6 py-6 rounded-2xl user-profile-modal-popup"
    >
      <UploadBorrowerDetails orderDetails={orderDetails} />
    </div>
  );
};

const UploadBorrowerDetailMobile = ({ itemDetails, orderDetails }) => {
  const [show, setShow] = useState(false);
  const handleToggleShowDetails = () => {
    setShow(!show);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div
      className="lg:hidden block fixed w-full bottom-0 left-0 py-4 px-8 bg-white z-30"
      style={{ boxShadow: "0px 3.39468px 42.4335px rgba(0, 0, 0, 0.1)" }}
    >
      <div className="flex flex-row items-center justify-between">
        <OrderDetailsLeftSide
          itemDetails={itemDetails}
          orderDetails={orderDetails}
        />

        <div className="w-2/12">
          <div className="w-9 h-9 rounded-full object-cover">
            <img
              onClick={handleToggleShowDetails}
              src={orderDetails?.borrower?.image || AvatarImage}
              className="w-9 h-9 rounded-full object-cover"
              alt=""
            />
          </div>
        </div>
      </div>

      {show && (
        <UserDetailsModal
          orderDetails={orderDetails}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default UploadBorrowerDetailMobile;
