import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import MainContainer from "../../../components/layout/MainContainer";
import { scrollToTop } from "../../../utils/scrollToTop";
import GetUpdateSection from "../../home/components/GetUpdateSection";
import FaqSection from "./components/FaqSection";
import LenderHowItWork from "./components/LenderHowItWork";
import TopSection from "./components/TopSection";
import WhatYouWillGetSection from "./components/WhatYouWillGetSection";

const LenderHome = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MainContainer hasMainContainer={false}>
      <Helmet>
        <title>TempOwn | Lender</title>
      </Helmet>

      <TopSection />

      <WhatYouWillGetSection />

      <LenderHowItWork />

      <FaqSection />

      <GetUpdateSection />
    </MainContainer>
  );
};

export default LenderHome;
