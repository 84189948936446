import React from "react"
import { Helmet } from "react-helmet";

export const SEO = ({ title, description }) => {
  const Title = title ? title : "";
  const Description = description ? description : "";

  return (
    <Helmet>
      <title>{`TempOwn | ${Title}`}</title>
      <meta property="og:title" content={`TempOwn | ${Title}`}></meta>
      <meta name="name" content={`TempOwn | ${Title}`}></meta>
      <meta name="twitter:title" content={`TempOwn | ${Title}`}></meta>
      <meta name="description" content={Description}></meta>
      <meta property="og:description" content={Description}></meta>
      <meta name="twitter:description" content={Description}></meta>
      <meta name="description" content={Description}></meta>

      {/* <meta itemprop="name" content="How to use Asana, Asana tutorials, onboarding tips, and team use cases | Product guide • Asana"></meta> */}
      <meta property="author" content="TempOwn"></meta>
    </Helmet>
  );
};
