import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReeddiImage from "../../../assets/images/png/ads/reeddicapsule.webp";
import { Link } from "react-router-dom";

const data = [
  {
    id: 1,
    title: (
      <span>
        Get <span className="text-temp-green-500">24/7</span> power supply
      </span>
    ),
    subTitle: "Power your equipment, rent Reeddi Capsule today!",
    linkUrl: "/search?q=reeddi",
    image: ReeddiImage,
  },
  {
    id: 2,
    title: (
      <span>
        Get <span className="text-temp-green-500">24/7</span> power supply
      </span>
    ),
    subTitle: "Power your equipment, rent Reeddi Capsule today!",
    linkUrl: "/search?q=reeddi",
    image: ReeddiImage,
  },
];

const CTASliderSection = () => {
  return (
    <div className="container mx-auto px-4 pt-10 lg:pt-16 lg:pt-10">
      <OwlCarousel
        className="owl-carousel owl-theme"
        items={1}
        loop={true}
        nav={false}
        dots={true}
        margin={35}
        stageClass="pt-5"
        responsiveClass={true}
      >
        {data?.map((d) => (
          <div
            key={d?.id}
            className="relative rounded-xl pl-8 lg:pl-12 xl:pl-16 w-full ads-banner"
          >
            <div className="flex flex-row flex-wrap justify-between">
              <div className="w-full md:w-5/12 lg:w-6/12 xl:w-4/12 pt-12 pb-28 lg:pb-20 lg:pt-20 ">
                <h2 className="w-full text-black font-semibold text-4xl lg:text-5xl xl:text-6xl">
                  {d?.title}
                </h2>
                <p className="mt-6 text-lg lg:text-xl xl:text-2xl text-black">
                  {d?.subTitle}
                </p>

                <div className="mt-10 lg:mt-12 mb-8 block relative">
                  <Link
                    to={d?.linkUrl || "/"}
                    className="text-white bg-gradient py-5 px-8 lg:px-10 font-semibold rounded-xl focus:outline-none text-base lg:text-lg"
                  >
                    Browse All
                  </Link>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-5/12 relative mt-52 lg:mt-0">
                <div className="absolute bottom-0 right-6">
                  <img src={d?.image} alt="Ad Slider" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default CTASliderSection;
