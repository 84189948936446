/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-else-if */
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "store";
import MailAuthImage from "../../../assets/images/svg/mail-auth.svg";
import OnboardingContainer from "../../../components/layout/OnboardingContainer";
import AlertModal from "../../../components/modals/AlertModal";
import AlertModal2 from "../../../components/modals/AlertModal2";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";
import { countDown } from "../../../utils/countdown";
import ChangePhoneNumberModal from "./ChangePhoneNumberModal";

const Input = ({
  index,
  name,
  value,
  onChange,
  onKeyUp,
  error,
  onFocus,
  className,
}) => {
  return (
    <input
      type="text"
      className={`code-input${index} border flex-shrink ${
        className || "w-20 h-16 mr-8"
      } mb-6 pt-1 text-center font-bold text-xl lg:text-3xl text-temp-secondary rounded-xl focus:outline-none focus:border focus:border-temp-green-500 ${
        error ? "border-red-600" : "border-gray-300 "
      }`}
      maxLength="1"
      name={name}
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
    />
  );
};

const Verification = (props) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [timerOTP, setTimerOTP] = useState(0);
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState([
    {
      id: 1,
      value: "",
    },
    {
      id: 2,
      value: "",
    },
    {
      id: 3,
      value: "",
    },
    {
      id: 4,
      value: "",
    },
  ]);

  useEffect(() => {
    const phoneNumber = store?.get("temp_usER12_dATa")?.phone;
    if (phoneNumber) {
      setPhone(phoneNumber);
      handlePhoneNumberVerification();
    }
    if (store.get("sentOTP") === "true") {
      countDown(59, setTimerOTP);
      store.remove("sentOTP");
    }
  }, []);

  useEffect(() => {
    const userStoreInformation = store.get("temp_usER12_dATa");
    const stat = props.user;
    if (!userStoreInformation?.verified_phone) {
      navigate("/verify");
    } else {
      navigate("/");
    }

    setUserData(userStoreInformation || stat);
  }, [props.user]);

  const handlePhoneNumberVerification = async () => {
    setLoading(true);
    await props.verifyPhoneNumberApi();
    setLoading(false);
    if (store.get("sentOTP") === "true") {
      countDown(59, setTimerOTP);
      store.remove("sentOTP");
    }
  };

  const processSubmitPhoneVerificationCode = async () => {
    setLoading(true);

    let data = "";

    code.map((a) => (data += a.value));

    const payload = {
      code: data,
    };
    await props.complteVerifyPhoneNumberApi(
      payload,
      store?.get("temp_access_token"),
      navigate
    );
    setLoading(false);
  };

  useEffect(() => {
    const find = code.every((a) => a.value !== "");

    if (find) {
      processSubmitPhoneVerificationCode();
    }
  }, [code]);

  const handleInputChange = (e, index) => {
    let d = code[index];
    d["value"] = e.target.value;
    code[index] = d;
    setCode([...code]);

    if (e.target.value) {
      $(`.code-input${index + 1}`).focus();
    }
  };

  const handleOnKeyUp = (e, key) => {
    const d = code[key];

    var keyId = e.keyCode;
    switch (keyId) {
      // enter
      case 13:
        $(`.code-input${key + 1}`).focus();
        break;

      // Backspace
      case 8:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;

      // Delete
      case 46:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;
      default:
        break;
    }
  };

  const [newPhoneNumber, setNewPhoneNumber] = useState(null);
  const [showChangeNumberModal, setShowChangeNumberModal] = useState(false);
  const [errorNumberChange, setErrorNumberChange] = useState("");

  const handleShowChangeNumberModal = () => {
    setShowChangeNumberModal(true);
  };

  const handleCloseChangePhoneModal = () => {
    setShowChangeNumberModal(false);
    setNewPhoneNumber(null);
    setErrorNumberChange("");
  };

  const handleOnChangePhoneNumber = (e) => {
    setNewPhoneNumber(e);
  };

  const handleOnBlurPhoneNumber = () => {
    setErrorNumberChange("");
  };

  const handleSubmitPhoneNumberAndSendOtpCode = async (e) => {
    e.preventDefault();

    if (!newPhoneNumber) {
      setErrorNumberChange("Required");
    } else if (newPhoneNumber?.length < 8) {
      setErrorNumberChange(
        "Must be a valid phone number. e.g (+2349022222222)"
      );
    } else {
      setLoading(true);
      const res = await props.changePhoneNumberAndSendOTPApi({
        phoneNumber:
          "+" +
          (newPhoneNumber?.startsWith("2340")
            ? newPhoneNumber?.replace("2340", "234")
            : newPhoneNumber),
      });

      if (res) {
        const userStoreInformation = store.get("temp_usER12_dATa");
        const newUserStoreInformation = {
          ...userStoreInformation,
          phone:
            "+" +
            (newPhoneNumber?.startsWith("2340")
              ? newPhoneNumber?.replace("2340", "234")
              : newPhoneNumber),
        };
        store.set("temp_usER12_dATa", newUserStoreInformation);
        setUserData(newUserStoreInformation);
        setPhone(
          "+" +
            (newPhoneNumber?.startsWith("2340")
              ? newPhoneNumber?.replace("2340", "234")
              : newPhoneNumber)
        );
      }
      setLoading(false);
    }
  };

  // show change number modal if type === error and close if === success
  useEffect(() => {
    if (props.changeNumberAndResendOtpStatus.type == "success") {
      handleCloseChangePhoneModal();
    }
  }, [props.changeNumberAndResendOtpStatus.type]);

  return (
    <div>
      <OnboardingContainer pageTitle="Verification">
        <div className="flex justify-center mb-10">
          <div className="w-full lg:w-7/12 pt-8 lg:pt-4 mx-auto px-0 lg:px-10 text-center">
            <div className="pt-4 pb-6">
              <img src={MailAuthImage} className="mx-auto" alt="" />
            </div>
            <h2 className="text-temp-green-500 font-semibold text-xl lg:text-2xl xl:text-3xl">
              Verify Phone number
            </h2>

            <p className="text-sm lg:text-base pt-4 w-10/12 lg:w-8/12 lg:px-4 mx-auto">
              We have sent you a verification code to complete your registration
            </p>

            <div className="flex">
              <div className="mt-8 rounded-2xl mx-auto text-center flex flex-row justify-start otp-phone-number py-4 px-8">
                <p className="text-sm md:text-sm-15 lg:text-base">
                  Code was sent to{" "}
                  <span className="text-temp-green-500 font-semibold">
                    {phone.slice(0, 7)} **** {phone.slice(-2)}
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-8 lg:mt-12 mb-4 lg:mb-6 flex flex-wrap justify-center">
              {code.map((a, key) => (
                <Input
                  autoComplate="off"
                  key={key}
                  index={key}
                  name="code"
                  value={a.value}
                  onChange={(e) => handleInputChange(e, key)}
                  onKeyUp={(e) => handleOnKeyUp(e, key)}
                  className="w-12 h-12 mr-4 lg:mr-6 lg:w-16 lg:h-16"
                />
              ))}
            </div>

            <div className="text-temp-gray text-sm lg:text-base">
              <p>
                Didn’t get the code?{" "}
                <button
                  className={`${
                    timerOTP <= 0 ? "text-temp-green-500" : "text-gray-500"
                  } font-semibold focus:outline-none`}
                  onClick={handlePhoneNumberVerification}
                  disabled={timerOTP > 0}>
                  {`Resend ${timerOTP > 0 ? `in 0:${timerOTP}` : ""}`}
                </button>
              </p>
            </div>

            <div className="mt-20 text-temp-gray text-sm lg:text-base">
              <button
                className="text-temp-green-500 underline focus:outline-none"
                onClick={handleShowChangeNumberModal}>
                Change Mobile Number
              </button>
            </div>
          </div>

          {/* Trigger change phone number modal */}
          {showChangeNumberModal && (
            <ChangePhoneNumberModal
              newPhoneNumber={newPhoneNumber}
              show={showChangeNumberModal}
              handleCloseModal={handleCloseChangePhoneModal}
              onChange={handleOnChangePhoneNumber}
              onBlur={handleOnBlurPhoneNumber}
              onSubmit={handleSubmitPhoneNumberAndSendOtpCode}
              error={errorNumberChange}
            />
          )}
          {/* Trigger modal after form submission */}
          {loading && <LoadingModal show={loading} />}

          {/* Resend phone code alert message */}
          {props.resendCodeStatus?.type && (
            <AlertModal2
              show={props.resendCodeStatus.type ? true : false}
              type={props.resendCodeStatus.type}
              content={props.resendCodeStatus.message}
            />
          )}

          {/* Change number and resend otp alert message */}
          {props.changeNumberAndResendOtpStatus?.type && (
            <AlertModal2
              show={props.changeNumberAndResendOtpStatus.type ? true : false}
              type={props.changeNumberAndResendOtpStatus.type}
              content={props.changeNumberAndResendOtpStatus.message}
            />
          )}

          {/* Submission Trigger Message */}
          {props.alert.message && (
            <AlertModal
              show={props.alert.type ? true : false}
              type={props.alert.type}
              content={props.alert.message}
            />
          )}
        </div>
      </OnboardingContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  resendCodeStatus: state.auth.resendCodeStatus,
  changeNumberAndResendOtpStatus: state.auth.changeNumberAndResendOtpStatus,
  user: state.auth.user,
});

export default connect(mapStateToProps, actions)(Verification);
