import React, { useEffect, useState } from "react";
import { Link, useLocation, } from "react-router-dom";
import store from "store";
import Logo from "../../assets/images/svg/logo/logo2.svg";
import AuthHeader from "./headers/AuthHeader";
import UnAuthHeader from "./headers/UnAuthHeader";
import UnAuthMobileMenu from "./headers/UnAuthMobileMenu";

const Header = (props) => {
  const userData = store.get("temp_usER12_dATa");

  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false);
  const [isBorrowerMenuOpen, setIsBorrowerMenuOpen] = useState(false);

  const handleToggleBorrowerMenu = () => {
    setIsBorrowerMenuOpen(!isBorrowerMenuOpen);
  };

  // Close open menu when link changes
  useEffect(() => {
    setIsBorrowerMenuOpen(false);
  }, [location.pathname]);

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const handleCloseMenuDropDown = () => {
    setIsBorrowerMenuOpen(false);
  };

  return (
    <div className={`w-full sticky top-0 z-30 ${props.bg || "bg-white"}`}>
      <div className={!props.hasMainContainer && "container mx-auto px-4"}>
        <header
          className={`w-full text-temp-primary ${props.bg || "bg-white"}`}
        >
          <div className="max-w-full mx-auto">
            <div className="pt-3 pb-6 flex justify-between items-center md:justify-start md:space-x-10">
              <div className="sm:mt-3 flex justify-start lg:w-3/12 ">
                <Link to="/">
                  <span className="sr-only">TempOwn</span>
                  <img
                    className="h-6 w-auto md:h-8 lg:h-9"
                    src={Logo}
                    alt="Logo"
                  />
                </Link>
              </div>

              <div className="hidden lg:flex flex-row space-x-8 lg:space-x-10 xl:space-x-12 text-sm-15 sm:mt-3">
                <Link to={"/enterprise"} className="text-temp-primary hover:text-temp-secondary transition-all cursor-pointer">Enterprise <span className="text-xs coming-soon-tip rounded-full py-2 px-3 ml-1">Coming soon</span></Link>
                <Link to={"/about"} className="text-temp-primary hover:text-temp-secondary transition-all">About</Link>
                <Link to={"/lender"} className="text-temp-primary hover:text-temp-secondary transition-all">List an item</Link>
              </div>

              {userData?._id ? (
                ""
              ) : (
                <div className="-mr-2 -my-2 flex items-center md:hidden">
                  <button
                    type="button"
                    className="bg-white rounded-md ml-4 p-1 inline-flex items-center justify-center text-temp-primary focus:outline-none "
                    onClick={() => setIsOpen(true)}
                  >
                    <span className="sr-only">Open menu</span>
                    <svg
                      className="h-8 w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#08A05C"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              )}

              {userData?._id ? (
                <div className="flex items-center justify-end md:flex-1 lg:w-0">
                  <AuthHeader
                    onClick={handleToggleBorrowerMenu}
                    isOpen={isBorrowerMenuOpen}
                    logOutClick={props.logOutClick}
                    handleLogout={props.handleLogout}
                    handleCloseMenu={handleCloseMenuDropDown}
                    bg={props.bg}
                  />
                </div>
              ) : (
                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  <UnAuthHeader bg={props.bg} />
                </div>
              )}
            </div>
          </div>

          {/* <!--
      Mobile menu, show/hide based on mobile menu state.
    --> */}
          <UnAuthMobileMenu isOpen={isOpen} handleCloseMenu={handleCloseMenu} />
        </header>
      </div>
    </div>
  );
};

export default (Header);
