import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const InputDatePicker = ({
  label,
  labelColor,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  className,
  ...props
}) => {
  return (
    <div className={className || "mb-4 lg:mb-8"}>
      <label
        htmlFor={name}
        className={`text-sm lg:text-base  ${labelColor || "text-temp-primary"}`}
      >
        {label}
      </label>
      <div className="relative input input-field w-full mb-2">
        <DatePicker
          dateFormat="dd-MM-yyyy"
          name={name}
          selected={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholderText={placeholder}
          selectsRange
          className={`mt-3 text-temp-primary block px-5 h-12 lg:h-14 text-sm lg:text-base w-full rounded-xl border bg-white placeholder-temp-gray focus:outline-none ${
            error ? "border-red-600" : " border-temp-gray-light"
          }`}
          minTime={props.minTime || new Date(new Date().setHours(7, 0, 0))}
          maxTime={props.maxTime || new Date(new Date().setHours(19, 0, 0))}
          {...props}
        />
      </div>
      <span className="text-red-600 text-sm">{error}</span>
    </div>
  );
};

export default InputDatePicker;
