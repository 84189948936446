import React from 'react';
import LocationCard from '../../../components/blocks/location/LocationCard';
import { locationData } from '../../../utils/locationData';



const ExploreByCity = () => {
    return <section className='bg-gradient-gray'>
        <div className="container px-4 mx-auto pt-16 lg:pt-20 pb-28">
            <div className="pt-8 lg:pt-14 pb-6 lg:pb-10">
                <h1 className="text-xl md:text-2xl lg:text-4xl font-semibold text-temp-primary">
                    Explore by city
                </h1>
            </div>

            <div className='mt-8 relative grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8'>
                {
                    locationData?.map(datum => <LocationCard
                        id={datum?.id}
                        key={datum?.id}
                        name={datum?.name}
                        itemCount={datum?.itemCount}
                        image={datum?.image}
                        url={`/search-by-location/${datum?.name.toLowerCase()}`}
                    />
                    )
                }
            </div>
        </div>
    </section>
};


export default ExploreByCity;
