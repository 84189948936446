/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";

const PendingOrderCard = ({
    handleCancelOrder,
    orderDetails,
    loggedInUserDetails,
}) => {
    return (
        <div
            className="rounded-xl py-6 lg:py-8 mx-auto"
            style={{
                border: "0.5px solid #B0B0B0",
            }}
        >
            <div className="text-temp-secondary font-semibold px-6 lg:px-8 mb-4 lg:mb-6 text-base lg:text-lg">Order Activities</div>
            <div className="flex flex-wrap items-baseline pr-8">
                <p className="px-6 lg:px-8 text-sm lg:text-sm-15 pb-4 text-temp-gray">
                    Status
                </p>
                <p className="px-4 lg:px-6 font-medium text-xs lg:text-sm pending-status rounded-xl pb-3 pt-3">
                    Pending Approval
                </p>
            </div>

            <div className="px-6 lg:px-8 mt-4 lg:mt-6 pb-6">
                {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
                    <>
                        <button
                            onClick={handleCancelOrder}
                            className="py-4 w-full px-4 mt-5 font-bold text-xs lg:text-sm text-temp-green-500 rounded-lg focus:outline-none border border-temp-green-500"
                        >
                            Cancel
                        </button>

                        <p className="text-xs lg:text-sm pt-4 text-center">
                            Once you cancel, you will be <span className="font-semibold">refunded</span> within{" "}
                            <span className="font-semibold">24 hours</span>
                        </p>
                    </>
                ) : (
                    <>
                        <Link
                            to="/rental-requests"
                            onClick={handleCancelOrder}
                            className="py-4 w-full block text-center px-4 mt-5 font-bold text-xs lg:text-sm bg-gradient text-white rounded-lg focus:outline-none"
                        >
                            Go to Request Page &gt;&gt;
                        </Link>

                        <p className="text-xs lg:text-sm pt-4 text-center">
                            This order is awaiting approval from you. Kindly click
                            on the button above to confirm or decline the order
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default PendingOrderCard;
