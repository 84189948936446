import React, { useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import AlertModal from "../../components/modals/AlertModal";
import LoadingModal from "../../components/modals/LoadingModal";
import * as actions from "../../store/actions";
import LeftSectionPendingAndAccepted from "./components/LeftSectionPendingAndAccepted";
import PendingOrderCard from "./components/PendingOrderCard";

const PendingApprovalDetails = ({
  orderDetails,
  itemDetails,
  loggedInUserDetails,
  ...props
}) => {
  // Cancel order
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const handleCancelOrder = async () => {
    setCancelOrderLoading(true);
    await props.cancelOrdersApi(orderDetails?._id);
    setCancelOrderLoading(false);
  };

  return (
    <div className="my-10 relative flex flex-wrap text-temp-primary">
      <div className="w-full lg:w-5/12 xl:w-6/12 mb-12">
        <LeftSectionPendingAndAccepted
          orderDetails={orderDetails}
          itemDetails={itemDetails}
          withEquipmentPickupNote={true}
          loggedInUserDetails={loggedInUserDetails}
        >
          {/* Mobile only */}
          <div className="mb-12 mt-6 block lg:hidden">
            <PendingOrderCard
              handleCancelOrder={handleCancelOrder}
              itemDetails={itemDetails}
              orderDetails={orderDetails}
              loggedInUserDetails={loggedInUserDetails}
            />
            <p className="mt-4 text-xs text-red-400">
              Once approved, you cannot cancel this booking
            </p>
          </div>
        </LeftSectionPendingAndAccepted>
      </div>

      {/* Desktop only */}
      <div className="hidden lg:block w-full lg:w-6/12 xl:w-5/12 mx-auto px-0 md:px-10 lg:mb-12 mb-6">
        <PendingOrderCard
          handleCancelOrder={handleCancelOrder}
          itemDetails={itemDetails}
          orderDetails={orderDetails}
          loggedInUserDetails={loggedInUserDetails}
        />
        <p className="mt-4 text-xs text-red-400">
          Once approved, you cannot cancel this booking
        </p>
      </div>

      {cancelOrderLoading && (
        <LoadingModal show={cancelOrderLoading} title="Cancelling Order ..." />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl="/rental-activities"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(PendingApprovalDetails)
);
