import React from "react";

const ChargesItem = ({
  name,
  value,
  hasSwitch,
  handleSwitchToggle,
  checkedValue,
  discount,
  details,
}) => {
  return (
    <div className="w-full mb-4">
      <div className="w-full flex flex-1 justify-between mb-1 text-sm lg:text-base">
        <p
          className={`inline-flex ${
            checkedValue && name === "Operator’s Fee"
              ? "text-temp-primary"
              : !checkedValue && name === "Operator’s Fee"
              ? "text-gray-400"
              : "text-temp-primary"
          }`}
        >
          {name}{" "}
          {hasSwitch && (
            <div className="ml-4">
              <label className="toggle" htmlFor={name}>
                <input
                  className="toggle__input"
                  name={name}
                  type="checkbox"
                  id={name}
                  onChange={handleSwitchToggle}
                  checked={checkedValue ? true : false}
                />
                <div className="toggle__fill"></div>
              </label>
            </div>
          )}
        </p>
        <p
          className={`${
            checkedValue && name === "Operator’s Fee"
              ? "text-temp-primary"
              : !checkedValue && name === "Operator’s Fee"
              ? "text-gray-400"
              : "text-temp-primary"
          }`}
        >
          {value}{" "}
          {discount && (
            <span className="text-xs text-temp-green-500">
              / {discount}% off
            </span>
          )}
        </p>
      </div>
      {details}
    </div>
  );
};

export default ChargesItem;
