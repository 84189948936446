import React from "react";

const FixedBottomBox = ({
  title,
  buttonName,
  onClick,
  isCheckoutButton = false,
  checkoutButton,
}) => {
  return (
    <div className="w-full lg:hidden block  z-10 bg-white shadow-2xl px-4 py-6 fixed bottom-0 left-0">
      <div className="flex flex-wrap justify-between items-center ">
        <p
          className={`text-temp-primary text-sm ${
            isCheckoutButton ? "pl-0" : "pl-3"
          }`}
        >
          {title || "Rent this item now"}
        </p>
        {isCheckoutButton ? (
          <>{checkoutButton}</>
        ) : (
          <button
            onClick={onClick}
            className="bg-gradient px-4 py-4 text-white rounded-lg text-center text-xs lg:text-base font-bold focus:outline-none"
          >
            {buttonName || "Check Availability"}
          </button>
        )}
      </div>
    </div>
  );
};

export default FixedBottomBox;
