import React from "react";

const AvailableBalancesCard = ({ handleShowModal, amount }) => {
  return (
    <>
      <div className="wallet-wrapper mb-10 lg:mb-6 h-full">
        <div className="wallet-card bg-temp-green-500 rounded-lg py-12 lg:py-16 px-8 h-full">
          <div className="card-title pr-8">
            <p className="font-serif pb-4" style={{ color: "#9CFFAC" }}>
              Available Balance
            </p>
            <p className="text-white text-2xl lg:text-3xl font-semibold">
              NGN {amount}
            </p>
          </div>
        </div>
        <div className="pt-6">
          <button
            onClick={handleShowModal}
            className="text-temp-primary font-semibold focus:outline-none text-sm lg:text-base"
          >
            Withdraw Fund 💰
          </button>
        </div>
      </div>
    </>
  );
};

export default AvailableBalancesCard;
