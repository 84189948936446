import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const getAddressesApi = () => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/address";
  try {
    const response = await axios.get(url);
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_ADDRESSES,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const createAddressApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/address";
  try {
    const response = await axios.post(url, payload);

    dispatchAction(dispatch, Actions.CREATE_ADDRESS, {
      type: "success",
      message: response.data.message || "Address deleted succefully",
    });
  } catch (error) {
    dispatchAction(dispatch, Actions.CREATE_ADDRESS, {
      type: "error",
      message: error.response?.data.message || "Unable to authenticate token",
    });
  }
};

export const updateAddressApi = (id, payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/address/${id}`;
  try {
    const response = await axios.put(url, payload);

    dispatchAction(dispatch, Actions.UPDATE_ADDRESS, {
      id,
      data: payload,
      type: "success",
      message: response.data.message || "Address updated succefully",
    });
  } catch (error) {
    dispatchAction(dispatch, Actions.UPDATE_ADDRESS, {
      type: "error",
      message: error.response?.data.message || "Unable to authenticate token",
    });
  }
};

export const deleteAddressApi = (id) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/address/${id}`;
  try {
    const response = await axios.delete(url);

    dispatchAction(dispatch, Actions.DELETE_ADDRESS, {
      id,
      type: "success",
      message: response.data.message || "Address deleted succefully",
    });
  } catch (error) {
    dispatchAction(dispatch, Actions.DELETE_ADDRESS, {
      type: "error",
      message: error.response.data.message || "Unable to authenticate token",
    });
  }
};

// Function
const dispatchAction = (dispatch, type, payloadData, history) => {
  dispatch({
    type,
    payload: payloadData,
  });
  setTimeout(() => {
    dispatch({
      type,
      payload: {
        type: "",
        message: "",
      },
    });

    return history;
  }, 3000);
};
