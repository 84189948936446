export const uploadItemStepData = {
    Step0: {
      progress: "12.5%",
      tips: [
        "Items with serial number can be self-insured of insured with TempOwn Protect.",
        "Items without serial number are not insurable on the platform",
      ],
    },
    Step1: {
      progress: "24.5%",
      tips: [
        "If your item(s) or equipment comes with an Operator, you will be required to provide an Operator to manage the equipment during the rental period.",
        "Items or equipment without an Operator will be operated and handled by the borrower.",
      ],
    },
    Step2: {
      progress: "32.5%",
      tips: [
        "For multiple items, the items must have the same specification",
        "There is a limit of 5 items for multiple uploads",
      ],
    },
    Step3: {
      progress: "48.5%",
      tips: [
        "For multiple items, the items must have the same specification",
        "There is a limit of 5 items for multiple uploads",
      ],
    },
    Step4: {
      progress: "52.5%",
      tips: [
        "For multiple items, the items must have the same specification",
        "There is a limit of 5 items for multiple uploads",
      ],
    },
    Step5: {
      progress: "64.5%",
      tips: [
        "For multiple items, the items must have the same specification",
        "There is a limit of 5 items for multiple uploads",
      ],
    },
    Step6: {
      progress: "72.5%",
      tips: [
        "For multiple items, the items must have the same specification",
        "There is a limit of 5 items for multiple uploads",
      ],
    },
    Step7: {
      progress: "84.5%",
      tips: [
        "For multiple items, the items must have the same specification",
        "There is a limit of 5 items for multiple uploads",
      ],
    },
    Step8: {
      progress: "96.5%",
      tips: [
        "For multiple items, the items must have the same specification",
        "There is a limit of 5 items for multiple uploads",
      ],
    },
  };