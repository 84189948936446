import React from "react";
import ItemListing from "../../../components/blocks/itemBits/ItemListing";

const SearchResultList = ({ totalItems, keyword, items }) => {
  return (
    <ItemListing items={items} urlPath="items">
      <div className="mt-8 md:mt-10 mb-14 md:mb-20 w-full relative text-center">
        <h2 className="text-temp-primary text-lg md:text-lg lg:text-xl xl:text-2xl mb-4">
          Search result
        </h2>
        <p className="text-temp-primary text-sm md:text-sm-15 lg:text-base xl:text-lg">
          {totalItems} results found for{" "}
          <strong className="text-temp-green-500">&quot;{keyword}&quot;</strong>
        </p>
      </div>
    </ItemListing>
  );
};

export default SearchResultList;
