import React from "react";
import { Link } from "react-router-dom";
import LenderImage from "../../../../assets/images/png/lender.webp";
import store from "store";
const TopSection = () => {
    const user = store.get("temp_usER12_dATa");
    return (
        <section className="">
            <div className="container mx-auto px-4 relative pt-6 md:pt-6 lg:pt-10 pb-16 mb:pb-28">
                <div className="relative flex flex-wrap flex-row justify-between">
                    <div className="w-full md:w-7/12 lg:w-5/12 pt-10 md:pt-16 lg:pt-20">
                        <h2 className="font-semibold text-temp-primary text-4xl md:text-5xl lg:text-5xl xl:text-6xl">
                            A smarter way to <span className="text-temp-secondary">earn</span>{" "}
                            without hassles
                        </h2>

                        <p className="w-full md:w-10/12 text-base lg:text-lg text-temp-gray mt-6 lg:mt-8">
                            Renting out inventories has never felt this good. Join the train
                            of lenders earning on TempOwn by renting out their equipments.
                        </p>

                        <div className="relative flex mt-10">
                            <Link
                                to={user ? "/get-started" : "/signin"}
                                className="bg-gradient py-4 lg:py-5 px-10 lg:px-12 rounded-xl text-white text-sm md:text-base font-semibold"
                            >
                                Get Started
                            </Link>
                        </div>
                        <p className="text-xs md:text-sm mt-6 lg:mt-8 text-temp-green-500">🔒 Your equipments are safe</p>
                    </div>
                    <div className="w-full lg:w-6/12 relative mt-12 lg:mt-0">
                        <div className="w-full  mx-auto ">
                            <img src={LenderImage} alt="Lending your equipment easily" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopSection;
