import React from 'react'
import { Link } from 'react-router-dom';
import Logo from "../../../assets/images/svg/logo/logo2.svg";

const UploadHeader = () => {
    return (
        <header className={`relative text-temp-primary bg-white`}>
            <div className="max-w-full mx-auto">
                <div className="pt-5 pb-8 flex justify-between items-center md:justify-start md:space-x-10">
                    <div className="sm:mt-3 flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/">
                            <span className="sr-only">TempOwn</span>
                            <img className="h-6 w-auto md:h-8 lg:h-9" src={Logo} alt="Logo" />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default UploadHeader
