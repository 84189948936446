import React from "react";
import { Link } from "react-router-dom";
import AvatarImg from "../../../assets/images/png/item/item1.png";
import { numberFormat } from "../../../utils/numberFormat";

const LateRentalEquipment = ({ item }) => {
  const address = item?.item?.pickupLocation?.address_components;
  const city =
    address?.find((a) => a?.types?.includes("postal_town"))?.short_name ||
    undefined;
  const state =
    address?.find((a) => a?.types?.includes("administrative_area_level_1"))
      ?.short_name || undefined;

  return (
    <>
      <div className="border border-temp-gray-light rounded-xl px-4 py-5 flex flex-row flex-wrap justify-start text-left mt-10 w-full">
        <div className="w-3/12 rounded-xl">
          <img
            src={item?.item?.images[0]?.image?.url || AvatarImg}
            className="rounded-xl w-full h-full object-cover"
            alt="Product"
          />
        </div>
        <div className="w-9/12 pl-4 mt-1 md:mt-3">
          <p className="text-base md:text-lg font-semibold text-temp-primary">
            {item?.item?.name}
          </p>
          <p className="flex items-center">
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_10722_407)">
                <path
                  d="M9.18199 8.82696L5.99999 12.009L2.81799 8.82696C2.18866 8.19762 1.76009 7.3958 1.58646 6.52288C1.41283 5.64997 1.50195 4.74517 1.84254 3.92291C2.18314 3.10064 2.75991 2.39784 3.49993 1.90338C4.23995 1.40892 5.10998 1.145 5.99999 1.145C6.89001 1.145 7.76003 1.40892 8.50005 1.90338C9.24007 2.39784 9.81685 3.10064 10.1574 3.92291C10.498 4.74517 10.5872 5.64997 10.4135 6.52288C10.2399 7.3958 9.81133 8.19762 9.18199 8.82696ZM5.99999 6.64496C6.26521 6.64496 6.51956 6.5396 6.7071 6.35207C6.89464 6.16453 6.99999 5.91017 6.99999 5.64496C6.99999 5.37974 6.89464 5.12539 6.7071 4.93785C6.51956 4.75032 6.26521 4.64496 5.99999 4.64496C5.73478 4.64496 5.48042 4.75032 5.29289 4.93785C5.10535 5.12539 4.99999 5.37974 4.99999 5.64496C4.99999 5.91017 5.10535 6.16453 5.29289 6.35207C5.48042 6.5396 5.73478 6.64496 5.99999 6.64496Z"
                  fill="#616564"
                />
              </g>
              <defs>
                <clipPath id="clip0_10722_407">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(0 0.144958)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="pl-1 pt-1 text-xs md:text-sm text-temp-gray">
              {address && city && state ? city + ", " + state : "Nigeria"}
            </span>
          </p>
          <div className="flex justify-between items-baseline">
            <p className="text-xs md:text-sm text-temp-secondary mt-2 lg:mt-4">
              {item?.lateDaysCount === 1
                ? item?.lateDaysCount + " day"
                : item?.lateDaysCount + " days"}{" "}
              late return
            </p>
            <p className="text-sm md:text-sm-15 text-temp-gray">
              <strong>N{numberFormat(item?.total)}</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4 w-full flex justify-start">
        <Link
          to={`/rental-activities/order/?id=${item?.order?._id}&itemId=${item?.item?._id}`}
          className="text-temp-green-500 font-semibold underline text-xs md:text-sm text-left"
        >
          View rental details
        </Link>
      </div>
    </>
  );
};

export default LateRentalEquipment;
