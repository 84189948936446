import React from "react";
import StarRating from "../../../components/blocks/StarRating";
import TextAreaGroup from "../../../components/element/TextAreaGroup";
import { starTags } from "../../../utils/starTags";

const Tag = ({ title, onClick, selected }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`focus:outline-none rounded-3xl pb-2 pt-3 px-5 border 
        border-temp-green-light text-sm lg:text-base mr-6 mb-6 ${
          selected && "bg-temp-green-light"
        }
      `}
    >
      {title}
    </button>
  );
};

const CompleteOrderReviewForm = ({
  rating,
  ratingTag,
  itemRating,
  handleOnClickRating,
  handleOnChangeRatingTag,
  handleOnClickItemRating,
  review,
  reviewEmptyError,
  handleInputChange,
  handleSubmitReview,
  loading,
  itemDetails,
  orderDetails,
  loggedInUserDetails,
}) => {
  return (
    <div id="complete-order-review-form" className="desc mb-6 mt-4">
      <p className="text-base lg:text-lg xl:text-xl font-bold pb-4">Review</p>

      <div className="mt-2 lg:mt-6">
        <p className="text-sm-15 lg:text-base font-semibold pb-4">Rating</p>
        <div className="lg:flex justify-between mt-4">
          <div className="">
            <p className="text-sm lg:text-sm-15">
              How is your experience with{" "}
              {loggedInUserDetails?._id === orderDetails?.borrower?._id
                ? itemDetails?.userId?.firstname
                : orderDetails?.borrower?.firstname}
              ?
            </p>
          </div>

          <div className="mt-4 lg:mt-1">
            <StarRating
              rating={rating}
              starDimension="18px"
              starSpacing="3px"
              changeRating={handleOnClickRating}
              name="rating"
            />
          </div>
        </div>
      </div>

      <div className="pt-10">
        {starTags
          ?.find((a) => a?.star == rating)
          ?.tags?.map((b, index) => (
            <Tag
              key={index}
              title={b}
              ratingTag={ratingTag}
              onClick={() => handleOnChangeRatingTag(b)}
              selected={ratingTag === b && true}
            />
          ))}
      </div>

      <hr className="mt-6 lg:mt-10 mb-6 border-t-2 border-temp-green-200" />

      <div className="mt-4 lg:mt-8">
        <div className="lg:flex justify-between mt-6 mb-6">
          <div className="">
            <p className="text-sm lg:text-sm-15">
            What was your experience with using this equipment?
            </p>
          </div>

          <div className="mt-4 lg:mt-1">
            <StarRating
              rating={itemRating}
              starDimension="18px"
              starSpacing="3px"
              changeRating={handleOnClickItemRating}
              name="itemRating"
            />
          </div>
        </div>

        <TextAreaGroup
          hasLabel={false}
          placeholder="Write a review"
          showTip={false}
          borderColor="border-2 border-temp-green-light"
          textColor="text-temp-gray placeholder-temp-gray"
          textSize="text-sm lg:text-sm-15"
          rows="7"
          name="review"
          value={review}
          onChange={handleInputChange}
          marginBottom="mb-0"
        />
        {reviewEmptyError && (
          <p className="text-sm text-red-600">{reviewEmptyError}</p>
        )}
      </div>

      <div className="mt-8 text-right">
        <button
          type="button"
          onClick={handleSubmitReview}
          disabled={
            (rating !== 0 || review?.length > 0) && loading !== true
              ? false
              : true
          }
          className={`font-bold text-xs lg:text-sm h-11 lg:h-12 px-12 text-white rounded-lg focus:outline-none  ${
            (rating !== 0 || review?.length > 0) && loading !== true
              ? "bg-temp-green-500"
              : "bg-temp-gray"
          }`}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default CompleteOrderReviewForm;
