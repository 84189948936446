import React from "react";
import ProfileWithViewButton from "../ProfileWithViewButton";

const BorrowerInformationWithViewButton = ({
  user,
  title,
  className = "lg:px-6 xl:px-8",
  showChatButton,
  pickupContact
}) => {
  return (
    <ProfileWithViewButton
      userType="borrower"
      title={title}
      user={user}
      image={user?.image}
      firstname={user?.firstname}
      membershipStartDate={user.createdAt}
      id={user?._id}
      className={className}
      pickupContact={pickupContact}
      showChatButton={showChatButton}
    />
  );
};

export default BorrowerInformationWithViewButton;
