import React from "react";
import { Link } from "react-router-dom";

const RentalPolicy = () => {
  return (
    <div className="mb-16">
      <p className="leading-7 text-base">
        This Rental Policy, together with our terms of use, privacy policy,
        cancellation and refund policy (“Terms and Conditions”), constitute our
        agreement with you, for the services we provide in arranging for the
        rental of items/equipment. For the avoidance of doubt, ‘You’ refers to
        the person making the booking and/or the person paying for the rental
        (“TempOwner”); and the person accepting the rental requests (“Owner”).
        Please note that we will only be able to speak to the person making the
        booking and the person accepting the rental request in respect of a
        booking.
      </p>
      <br />
      <p>
        Together, these are referred to as ‘our terms’. These terms shall apply
        to all contracts for the provision of our services to the exclusion of
        all other terms & conditions. No conduct by TempOwn.com shall be deemed
        to constitute acceptance of any other terms. Acceptance of the services
        or signature on any agreement shall be deemed conclusive evidence of
        your acceptance of these terms. We may change the terms from time to
        time and you are advised to check the website to consider any changes to
        the terms which apply to you.
      </p>
      <br />
      <p>
        These terms apply to any booking that you make with us on or through
        this website, so you should make sure that you are aware of their
        contents. Your booking on this website is conditional on you accepting
        our terms. If you do not agree with any part of them, you must not
        proceed with your booking. By confirming that you wish to book an item/
        equipment through this website, you indicate that you have read and
        understood and accept our terms. If there is any part that you do not
        fully understand, or if you have a query about any of our products,
        please contact us.
      </p>
      <br />
      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Confirming your booking and taking payment{" "}
      </h3>
      <p>
        As soon as you “book” an item/equipment, TempOwn will take the money
        from your card and then confirm the availability of the rental item. In
        most cases, we will confirm your booking within 24 hours using the email
        address provided when the booking is made as well as on the website. You
        can ‘cancel’ the payment at any time within 0 -24 hours after booking a
        rental item but before your booking is accepted by the Owner. Just hit
        “cancel” on the booking page. Note however that you will only be
        entitled to a refund of the “rental fee”.
      </p>
      <br />
      <p>
        When you book an item/equipment on our site, we will email you to
        confirm its availability within 48 hours of booking (or, if earlier, at
        least 24 hours after the booking). We will then send you a booking
        confirmation. We will send all correspondence to the address provided
        when making the booking. For the avoidance of doubt, nothing in these
        terms shall entitle any third party to any benefit or rights under these
        terms and conditions.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Accuracy of Information
      </h3>
      <p>
        Although we strive to ensure the accuracy of the information on this
        website, neither we nor our affiliates, suppliers or agents, employees
        can be held responsible by you for the accuracy of such information. It
        is solely your responsibility to evaluate the accuracy, completeness and
        usefulness of all information provided on this website.
      </p>
      <br />
      <p>
        Please read the specific terms & conditions relating to your rental item
        hire, which you will see during the booking process.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Communication
      </h3>
      <p>
        Every communication between TempOwn, TempOwner and Owner, shall be sent
        to the TempOwner and Owner’s registered email address. Notices and
        information to TempOwn must be sent to TempOwn’s registered email
        address at <a href="mailto:hello@tempown.com">hello@tempown.com</a>
      </p>
      <br />
      <p>
        For verified company accounts managed by company staff or
        representatives, TempOwn must be notified of any change of account
        manager within 24hours to enable us make necessary changes to the
        account.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Daily Rate Calculations / Periods of Hire / Period of Hire Extensions
      </h3>
      <p>
        The price of your rental item will be confirmed at the time of booking
        and calculated on the basis of 24-hour units. If you wish to extend the
        rental period after picking the rental item up, contact TempOwn
        immediately for an extension. For an extension approved by TempOwn, the
        TempOwner will pay the rental fee as charged on the booking confirmation
        ticket. If your extension is not approved by TempOwn because you did not
        inform TempOwn immediately as stated, when you drop off the rental item
        later than stated on your booking confirmation ticket, you would be
        required to pay the rental fee as charged on the booking confirmation
        ticket for the extra days. Also, a late return fee will be charged by
        TempOwn at a daily rate of 15% of the rental fee on your booking
        confirmation ticket.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Late Pick-ups / Early Drop-offs
      </h3>
      <p>
        Your rental starts and finishes according to the pick-up and drop-off
        dates and times detailed on your booking confirmation ticket
        /e-confirmation ticket. Unfortunately, we are unable to provide refunds
        for unused time if a rental item is picked up late or dropped off early.
        If you are unable to pick the rental item up at the agreed time and
        date, it is essential that you contact us as soon as possible. If you do
        not, there is no guarantee that the rental item will still be available
        and you will not be entitled to any refund.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Deliveries & Collections
      </h3>
      <p>
        The collection of rental items will be at a pickup location as specified
        on the booking confirmation ticket. For security reasons, TempOwn
        advises that the pickup location should be a public place. TempOwn shall
        not be liable for injuries, incidents, or accidents sustained by an
        Owner and, or TempOwner, during the collection, delivery, and return of
        rental items.
      </p>
      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Out-of-State Use of Rental Item
      </h3>
      <p>
        TempOwn intends that the rental item be used within the state where it
        was rented. Thus, the use of a rental item must be within the state
        where it is rented.
      </p>
      <br />
      <p>
        Where a TempOwner moves the rental item for use out-of-state (for any
        reason whatsoever), such TempOwner will be subject to civil liability
        for stealing in court as well as be responsible for the cost of
        litigation by TempOwn.
      </p>
      <br />
      <p>
        Where an Owner (without the approval of TempOwn), grants approval for
        the use of a rental item out of the state where it is rented, such an
        Owner will be responsible for all and any losses and damages that may
        happen to the rental item. Approving the use of a rental item/equipment
        outside the state where it was rented nullifies any coverage on such
        rental item/ equipment by TempOwn including TempOwn Protect.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Complaints
      </h3>
      <p>
        If you are dissatisfied with your rental, please report this to us as
        soon as feasible, but at least within 24 hours of dropping the rental
        item off, by contacting our Customer Service Team at{" "}
        <a href="mailto:hello@tempown.com" className="text-temp-green-500">
          hello@tempown.com
        </a>
        . It is difficult for us to assess any claim regarding your rental if
        this action is not taken at the time of drop off. Although when you rent
        an item, you enter a contractual relationship with the Owner. Whenever
        there’s a problem, we will try to help by contacting the Owner on your
        behalf. Please note that as we aim to resolve disputes internally, we
        are not obliged to submit to an ADR procedure operated by any
        independent ADR provider.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Terms of Your Booking on TempOwn.com
      </h3>
      <p>
        It is important to understand the terms of your booking, and in turn,
        rental. The technical steps required for your booking to be complete are
        as follows. When prices are quoted for services on our website, you are
        being invited to make an offer. You do not make this offer until you do
        so by clicking ‘Book Now’ on our ‘Bookings’ page and making the
        necessary payment.
        <br />
        You will have made an offer once you have clicked ‘Check Out’ and
        TempOwn has confirmed the payment. Once payment has been confirmed, a
        “pending acceptance” notification will appear on your dashboard. Once
        the Owner accepts your offer, you will be notified and your booking
        confirmation ticket will be sent to you.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        TempOwn’s Role
      </h3>
      <p>
        TempOwn only provides an online platform for Owners to upload and offer
        items/equipment for rent and TempOwners to rent items/equipment.
      </p>
      <br />
      <p>
        We shall not be liable ourselves or vicariously through the Owner for
        either any failure to perform or any delay in performing any of our
        obligations or the Owner’s obligations under or pursuant to this
        booking, booking confirmation ticket, rental items/equipment, or terms &
        conditions, if and in so far as performance is delayed, hindered or
        prevented by force majeure, which expression shall mean any event beyond
        the reasonable control of the party in question. In the event that the
        force majeure persists for more than 48 hours, we may terminate and
        withdraw this booking by giving 24 hours notice to your email address
        and refunding all rental amount paid prior to the force majeure event.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        TempOwn’s Cancellation of Bookings
      </h3>
      <p>
        We have the right to cancel a booking upon limited or immediate notice
        in certain circumstances, such as the withdrawal of an Owner. In all
        circumstances we will use our reasonable endeavours to arrange for the
        supply of an alternative rental item, but at periods of high demand, it
        may not be possible. Where a TempOwner has made a booking and we notify
        the TempOwner up to 48 hours before pick-up, the TempOwner will receive
        a return of all monies already paid. In the unlikely circumstance, we
        provide a TempOwner with notice of cancellation within 48 hours of
        pick-up, we will provide a full refund of all monies paid.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Making Bookings and Requesting Services
      </h3>
      <p>
        If you wish to make bookings or request services described on this
        website, we may ask you to supply certain data applicable to your
        booking – including, without limitation, debit card information and
        other personally identifiable data about you. You understand that any
        such data will be treated by us in the manner described in our privacy
        policy. You agree that all data that you provide for this purpose will
        be accurate, current and complete. You agree to pay all charges incurred
        by you or any users of your account. You will also be responsible for
        paying any applicable taxes relating to your rental.
        <br />
        <br />
        <p>
          Verification of submitted data may be required prior to your use of
          our services for any booking. Except as otherwise required by law or
          as otherwise expressly stated by us, price, rate and availability of
          products or services are subject to change without notice. You
          acknowledge that bookings, rentals and services are subject to
          additional terms & conditions imposed by us.
        </p>
        <br />
      </p>

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Data Protection
      </h3>
      <p>
        Your privacy is important to us. By using this site, you accept the
        terms of our privacy notice described in our{" "}
        <Link to="/policy/privacy" className="text-temp-green-500">
          privacy policy
        </Link>
        .
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Customer Reviews
      </h3>
      <p>
        Completed reviews may be uploaded onto our website for the sole purpose
        of informing customers of your opinion of the service and quality of
        rental items, and used partially or in their entirety by TempOwn.com on
        our platforms, social media platforms, newsletters, special promotions,
        mobile applications or other channels owned, hosted, used or controlled
        by TempOwn.com. By submitting a review, you are consenting to the above
        use of your review. We reserve the right to adjust, refuse or remove
        reviews at our sole discretion.
      </p>
      <br />
      <p>
        Reviews express the personal opinions of TempOwn.com customers who
        filled out a survey after completion of their rental. These opinions do
        not necessarily represent the views of TempOwn.com.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Limitation of Liability
      </h3>
      <p>
        TempOwn shall not be liable to an Owner and, or a TempOwner, for any
        damages for breach of fiduciary duties by an Owner and, or a TempOwner,
        which results in losses or damages, whether direct, incidental, special,
        or consequential, in profits, goods, or services.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Applicable Law and Dispute Settlements
      </h3>
      <p>
        You agree that this Rental Policy and any contractual obligation between
        TempOwn and You will be governed by the Laws of the Federal Republic of
        Nigeria.
      </p>
      <br />
      <p>
        If a dispute arises as to your visit or use of TempOwn’s website or to
        services you request on our platform, the Parties shall use their best
        endeavour to reach an amicable settlement.
      </p>
      <br />
      <p>
        If any such dispute is not settled by the Parties within 21 days, the
        Parties agree to submit to Mediation at the Lagos State Multi-Door
        Courthouse (LMDC) for resolutions under the Lagos State Multi-Door
        Courthouse (LMDC) Law 2007.
      </p>
      <br />

      <h3 className="text-3xl text-temp-green-500 font-serif mb-6 mt-4">
        Eligibility and Maintenance Policy
      </h3>

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">General</h3>
      <p>
        We recommend that Owners remove all personal property before making the
        rental item available for a reservation (if applicable). In addition,
        Owners should expect normal wear and tear on their rental item and any
        attachments, including minor scrapes and dings, in connection with your
        participation in the Services.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Use of the Rental Item
      </h3>
      <p>
        When you book a rental item from an Owner through TempOwn, you should
        use the rental item in accordance with its intended use. You may not
        access a rental item until the beginning of your reservation period and
        you should return the rental item on time and in the correct location or
        have the Owner pick up the rental item on time to avoid additional fees.
        You should present the Owner with a booking confirmation ticket, a
        current means of identification and/or a reservation code from TempOwn.
        You are required at all times to operate the rental item safely, and in
        compliance with all applicable laws and manufacturer’s specifications.
        <br />
        <br />
        Unintended use, horseplay, usage while under the influences of drugs and
        alcohol, modification of the rental item, are not allowed. You are
        required to wear seat belts during the operation of the rental item (if
        applicable). You shouldn&apos;t leave the rental item unlocked or with the
        keys unsecured (such as in the ignition, if applicable), and the rental
        item should be stored securely and safely.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Condition of the Rental Item
      </h3>
      <p>
        Each Owner is responsible for complying with all legal requirements
        (including ensuring the rental item is registered and insured) and
        maintaining their rental item in a safe, operational and good working
        manner. TempOwners acknowledge that operators should be trained in the
        specific use of the rental item (if applicable). After the request to
        book has been completed and communication has been established, the
        Owner would be required to upload photos of their reserved rental item
        with the TempOwner to the booking page on the website. After the
        reserved piece of rental item is returned to the Owner, post photos
        should be uploaded to the same booking page to ensure no damages were
        incurred. TempOwners are advised to complete a visual inspection and
        upload photos before beginning use of any rental item, i.e. at the
        pickup point. If a TempOwner finds damage in an initial inspection, the
        tempowner should upload photos of such pre-existing damage prior to the
        start of the rental to avoid future disputes. If a tempowner finds
        damage on an initial inspection and fails to report it, the Owner,
        TempOwn, any third-party administrators, may assume that the damage
        occurred during the rental period. If, after the initial inspection, the
        TempOwner believes that the rental item is not safe to operate, please
        do not use the rental item; immediately contact the Owner directly and
        the TempOwn team at{" "}
        <a href="mailto:hello@tempown.com" className="text-temp-green-500">
          hello@tempown.com
        </a>
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Incident Reporting
      </h3>
      <p>
        If there is ever an accident or incident during the rental period, the
        TempOwner should immediately report any damage to the Owner. If an
        accident, incident, or theft has occurred, please immediately notify
        emergency services (E.g., police, fire, etc.) and also please contact
        the Owner and us at:{" "}
        <a href="mailto:hello@tempown.com" className="text-temp-green-500">
          hello@tempown.com
        </a>
        . TempOwners will need to use all reasonable efforts to secure evidence
        from any available witnesses and to provide the Owner, TempOwn or
        third-party claims administrators with a written description of the
        incident and any other information requested, including identity and
        insurance information of any parties involved in the incident.
        TempOwners are also required to cooperate in any loss investigation
        conducted by the Owner, TempOwn, third party claims administrators or
        insurers. After an incident, you may not continue to use the rental item
        unless you have the explicit permission of TempOwn staff. Failure to
        timely report an incident or cooperate in an investigation may reduce or
        invalidate your efforts and claims.
        <br />
        <br />
        Note that TempOwners are responsible for losses or damages that occur
        while in possession of a rental item. This means that you are liable to
        cover the costs of repair or replacement of a rental item if such rental
        item gets damaged, stolen or destroyed while in your care/custody.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Repossession
      </h3>
      <p>
        The Owner may repossess any rental item booked through the Services
        without demand, at the TempOwner&apos;s expense, if the rental item is not
        returned by the end of the reservation; is found illegally parked (if
        applicable); apparently abandoned, or used in violation of applicable
        law or these terms.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Lost/Stolen/Missing Rental Item
      </h3>
      <p>
        If any rental item goes missing, is/are not returned and/or is/are
        stolen during the reservation period (or any extension period),
        TempOwners are obligated to report these changes to TempOwn as soon as
        possible, and in no event later than 24 hours as part of their ongoing
        obligations with respect to the use of the Services, separate and apart
        from any further or other obligations which may be contained in any
        lease or rental agreement. Failure to comply with this obligation may
        lead to TempOwn banning TempOwners from using the Services at our sole,
        absolute and unfettered discretion. Owners hereby acknowledge and agree
        that they are solely responsible for reporting any missing or stolen
        equipment to the authorities should they so choose. Owners and borrowers
        acknowledge and agree that any disputes with respect to loss, missing or
        stolen equipment shall be between themselves alone and TempOwn shall not
        be made a party to any such disputes for any reason whatsoever.
        Complaints relating to loss, missing or stolen rental items may be
        submitted by Owners to{" "}
        <a href="mailto:hello@tempown.com" className="text-temp-green-500">
          hello@tempown.com
        </a>
        . TempOwn has the right, but not the obligation, to act on any such
        complaints received in our sole, absolute and unfettered discretion.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Termination of Rental Reservation
      </h3>
      <p>
        In the event that a defined rental period has been agreed upon, the
        rental relationship shall cease upon its expiry. Notwithstanding the
        foregoing, the contract may be terminated for convenience at any time by
        giving prior notice. Such termination is subject to communication with
        TempOwn and the Owner. The time of return shall be defined as the point
        of time at which the rental item is received by the Owner. This
        provision shall apply regardless of who bears the cost and risk of
        transportation of the rental item.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Extension beyond Rental End Date
      </h3>
      <p>
        In the event that a piece of equipment is returned later than the rental
        end date as specified in the Booking confirmation ticket, you agree that
        TempOwn shall charge the TempOwner’s account for late refund fees. Where
        the amount in the TempOwner’s account cannot satisfy the fee, TempOwn
        shall use all legal means to collect the extension fees or its balance
        from the TempOwner.
        <br />
        <br />
        For details on Cancellation and Refund, please check our cancellation
        and refund policy here.{" "}
        <Link to="/privacy/refund" className="text-temp-green-500">
          here
        </Link>
        .
      </p>
      <br />

      <h3 className="text-3xl text-temp-green-500 font-serif mb-6 mt-4">
        For Owners sharing through TempOwn
      </h3>

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Registration and Verification
      </h3>
      <p>
        When you sign up for services on TempOwn, you will be asked to provide
        certain information about yourself (e.g. information concerning your
        rental item, your address, contact information etc.). You promise that
        any information you provide to TempOwn will be complete and accurate.
        TempOwn may use third party services to verify the information you
        provide to us and to obtain additional related information and
        corrections where applicable and you hereby authorize TempOwn to
        request, receive, use and store such information for a reasonable
        period. TempOwn may, at its discretion, accept or reject your
        registration application.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Information Given at Registration
      </h3>
      <p>
        When you sign up to upload and offer rental items on TempOwn, you will
        have specifications of the rental item(s) that you want to list for
        sharing through the Services. Each rental item should meet the
        requirements found under the rental item criteria. You may only use the
        Services in connection with a rental item that you own or otherwise have
        all the necessary rights and permissions to share for compensation.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Rental Item Availability
      </h3>
      <p>
        Once a rental item is booked, you should make the rental item available
        or deliver the rental item as expected by the TempOwner. If you offer
        the TempOwner the option to pick up the rental item at a specified
        location, you should supply the location of the rental item accurately
        to TempOwn and ensure that the rental item is available at that location
        at the beginning of the reservation period.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Listing Expiration
      </h3>
      <p>
        After a TempOwner requests to book a piece of rental item, the Owner has
        only 24 hours to accept and respond to the TempOwner. If not, the
        listing will automatically expire and become no longer available.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        TempOwn Protect
      </h3>
      <p>
        TempOwn offers a mandatory protection scheme to cover rental equipment
        during a rental period (rental start date to rental end date). This
        scheme is only available for purchase by Owners. Where an Owner does not
        purchase this protection for a piece of rental equipment, such Owner
        will not be entitled to any claim under TempOwn Protect. TempOwn Protect
        is compulsory as an Owner must purchase TempOwn Protect for the
        insurance of a rental item/ equipment for the duration of a rental
        period. Read more on TempOwn Protect{" "}
        <Link to="/privacy/insurance" className="text-temp-green-500">
          here
        </Link>
        .
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Maintenance
      </h3>
      <p>
        Where applicable, you are required to regularly check your rental
        item/equipment for any defects in its operations or safety. Owners
        should promise that, at all times, your rental item will be safe,
        operational, in good mechanical condition, and in full compliance with
        all applicable inspection and registration requirements. You will only
        list rental items with a clean, non-salvaged, non-written off,
        non-washed, and non-branded title. You agree to respond to any
        applicable recall or similar safety notices and to complete any
        recommended action before allowing your rental item/equipment to be
        booked. In addition, if TempOwn believes that your rental item/equipment
        does not conform to reasonable standards, TempOwn may notify you and
        reserve the right to remove or decline listing your rental item until
        its concerns have been resolved. TempOwn may, but does not commit to,
        undertake efforts to ensure the safety of rental items booked through
        the Services. Learn more about our Equipment eligibility requirements
        below.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Rental Item Criteria/ Regulations
      </h3>
      <p>We’d be happy to have you on our platform. Owners must abide by:</p>

      <ul className="space-y-3 list-inside list-disc">
        <li>
          List only eligible pieces of rental items that are registered to you
          or to an owner who has authorized you to list.
        </li>
        <li>
          We advise that Rental items be insured and safely maintained by the
          owner.
        </li>
        <li>
          Maintain your rental item and continue to meet regulated standards and
          manufacture specifications as your rental item ages.
        </li>
        <li>
          Owners must have their ownership documentation on file for any future
          requested questions regarding their rental item.
        </li>
      </ul>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Ineligible Items
      </h3>
      <p>
        All illegal and/or prohibited items in Nigeria are not eligible for
        listing on the TempOwn platform including Firearms, Axes, Machetes etc.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Further Responsibility
      </h3>
      <p>
        Owners must abide by our terms and conditions and safety regulations by
        local laws. Our Services are intended solely for persons who are 18 or
        older. Any use of the Services by anyone that does not meet these age
        requirements is expressly prohibited.
        <br />
        <br />
        Rental items may remain listed as long as they’re in excellent
        condition. They must also continue to meet all legal, safety, and
        mechanical/manufacturer standards. You’re responsible for knowing your
        rental item’s history before listing. TempOwn reserves the right to
        modify its eligibility requirements and remove any listings at any time
        for any reason.
        <br />
        <br />
        Your rental item must comply with all laws and regulations regarding
        safety, condition, and operation. Regulations vary by location, and we
        expect you to remain compliant with local mandates, including any other
        safety regulation.
        <br />
        <br />
        If we learn of a quality, safety, or maintenance issue with your rental
        item, we may temporarily restrict your listing and/or remove your
        listing and profile. We may remove any equipment that has not been
        maintained from our platform.
        <br />
        <br />
        Where applicable, you should get a rental item mechanical inspection and
        safety at least once a year to ensure your rental item is safe to
        operate.
        <br />
        <br />
        You agree to respond to any applicable recall or safety notices and to
        complete any recommended action before allowing your rental item to be
        booked. In addition, if TempOwn believes that your rental item does not
        conform to reasonable standards, TempOwn may notify you and reserve the
        right to remove or decline listing your rental items until their
        concerns have been resolved. TempOwn may, but does not commit to,
        undertake efforts to ensure the safety of rental items booked through
        the Services. All rental items (where applicable) shall have their
        annual inspection records up to date, any manufacture stickers depicting
        maximum loads and information.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Incident Reporting
      </h3>
      <p>
        If you believe that a TempOwner has caused any damage to your rental
        item, we strongly advise you to report that damage as soon as you become
        aware of it and to provide reasonable cooperation in the investigation
        of the damage so that it can be eligible for disputes as between you
        (Owner), the TempOwner and your insurance company (if applicable). You
        must report and record all incidents to your insurance company directly
        as TempOwn is not responsible for any incidents that may occur at any
        time or times before, during or after the use of the Services.
        <br />
        <br />
        Your rental item/equipment must comply with all laws and regulations
        regarding safety, condition, and operation. Regulations vary by
        location, and we expect you to remain compliant with local mandates,
        including any other safety equipment.
        <br />
        <br />
        Where applicable, you should get an equipment mechanical inspection and
        safety at least once a year to ensure your equipment is safe to operate.
        An inspection will include a check of your equipment’s most critical
        safety items, including brakes, steering parts, tires, and lights.
        Equipment cannot be rented unless the annual safety has been completed
        by a certified technician.
        <br />
        <br />
        If we learn of a quality, safety, or maintenance issue with your
        equipment, we may temporarily restrict your listing and/or remove your
        listing and profile. We may remove any equipment that has not been
        maintained from our platform.
      </p>
      <br />

      <h3 className="text-3xl text-temp-green-500 font-serif mb-6 mt-4">
        Other General Terms
      </h3>

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">Waiver</h3>
      <p>
        Neither the failure nor delay of TempOwn to exercise or enforce any of
        its rights under the provisions of this policy shall operate as a waiver
        thereof nor shall it deprive TempOwn of the right thereafter to insist
        upon strict adherence to that term or any term of this policy.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">Taxes</h3>
      <p>
        You understand and acknowledge that appropriate governmental agencies,
        departments, or authorities may take the position that you owe taxes in
        connection with your profits made as a result of your use of the
        Services. It is your responsibility to familiarize yourself with any and
        all applicable tax rules and regulations and to consult with your
        personal/business tax advisor.
      </p>
      <br />

      <h3 className="text-3xl text-temp-green-500 font-serif mb-6 mt-4">
        Fees
      </h3>

      <p>
      TempOwn is a secure and fully insured rental marketplace that ensures the best
        quality of service and selection of items and equipment. Here’s a list
        of the fees you will be charged when booking/ post-booking a rental item
        on the TempOwn platform.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-6 mt-3">
        Applicable to Owners Only
      </h3>
      <ul>
        <li>
          <p className="font-semibold">Service Charge Fee </p>
          <br />
          TempOwn charges a service fee per transaction equivalent to 19% of the
          total rental amount. Once a rental is completed, the Owner receives
          their payment.
          <br />
          <br />
          <strong>Example</strong>: Owner (the lender) and TempOwner (the
          borrower) arrange a booking for a one (1) day rental at a rate of
          #50,000. Upon confirmation of the booking, the Owner is charged a
          service fee equal to 19% of the total rental fee (i.e. #9,500). This
          fee will appear on the Owner&apos;s statement as TempOwn Service Charge.
          <br />
          <br />
          <p className="font-semibold">Cancellation/No-show Fee</p>
          <br />
          An Owner will be charged an Inconvenience Fee of 10% of the rental fee
          paid by the Borrower for a rental item.
        </li>
      </ul>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-6 mt-3">
        Applicable to Borrowers Only
      </h3>
      <ul>
        <li>
          <p className="font-semibold">Rental Fee</p>
          <br />
          Rental fee is the cost of rental item(s) as listed by the Owner of the
          equipment on TempOwn.com. It will be listed per day, per week and per
          month. This fee is fixed by the Owner.
          <br />
          <br />
          <p className="font-semibold">Deposit Fee</p>
          <br />
          A refundable sum of money deposited as security for good use of a
          rental item/equipment.
          <br />
          <br />
          <p className="font-semibold">Late Return Fee</p>
          <br />
          If a TempOwner fails to return any equipment or item on the agreed and
          confirmed date and time, he/she shall be liable to pay for the extra
          days the equipment or item was with him (i.e. 100% rental fee or
          extension fee) with 15% of the rental fee as a late return fee.
          <br />
          <br />
          <strong>Example</strong>: You rent a piece of equipment for 2 days
          with the rental fee of #20,000 (#10,000 per day). You failed to return
          the equipment and it remained in your custody for 2 extra days. You
          are going to pay #20,000 (#10,000 per day) for the 2 extra days and
          also pay 15% of the total rental fee as a late return fee. (i.e. 15%
          of 20,000 which is #3,000) Simply put, you&apos;ll pay extra #20,000 plus
          #3,000. That makes it a total of #23,000. Note: Your account will also
          be flagged for breach of the agreement. If you default on three
          occasions, you may be restricted from renting on the TempOwn platform.
          <br />
          <br />
          <p className="font-semibold">Damages Fee</p>
          <br />
          Damage fee is the fee TempOwn charges a Borrower for damages that
          occur to a rental item while in custody/use of that Borrower. This fee
          will be charged based on
          <ol className="space-y-3 list-inside list-decimal">
            <li>the type of equipment or rental item; and </li>
            <li>
              the level of damage on the equipment/rental item, as determined
              after an inspection has been carried out by the TempOwn team.
            </li>
          </ol>
          <br />
          After the level of damage has been ascertained, this fee will be
          charged on a Borrower’s bank card.
          <br />
          <br />
          <p className="font-semibold">Cancellation Fee</p>
          <br />
          A TempOwner will be charged a cancellation fee of 15% of the rental
          fee paid for a rental item/equipment.
          <br />
          <br />
          <p className="font-semibold">Usage Extension Fee</p>
          <br />
          TempOwners can request for extension of use for a piece of equipment
          before the initially agreed return date. However, a TempOwner will be
          required to confirm the availability of the equipment on our platform
          for the extension period. If approved, you will pay the 100% rental
          fee for the number of days/weeks/months you&apos;re extending for. No extra
          charges would be incurred this way.
          <br />
          <br />
        </li>
      </ul>
      <br />
    </div>
  );
};

export default RentalPolicy;
