import { Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { } from "react-router-dom";
import Loader from "../../../../components/blocks/Loader";
import NoRecordReturned from "../../../../components/blocks/NoRecordReturned";
import * as actions from "../../../../store/actions";
import { capitalizeFistLetterInSentence } from "../../../../utils/capitalizeFirstLetterInSentence";
import { numberFormat } from "../../../../utils/numberFormat";

const TransactionItem = ({ transaction }) => {
  return (
    <div className="income-transaction-item text-temp-primary flex flex-wrap justify-between items-center pt-6 pb-3 border-b border-temp-green-light">
      <div className="flex items-center flex-shrink mb-3">
        <div className="">
          <p className="font-medium mb-2 text-sm lg:text-base">
            ₦{numberFormat(transaction?.amount)}
          </p>
          <p className="text-xs">
            Transaction Status {" - "}
            <span className="text-temp-green-500">
              {capitalizeFistLetterInSentence(transaction.status)}
            </span>
            {" | "}
            {new Date(transaction.createdAt).toDateString()}
          </p>
        </div>
      </div>

      <div className="flex items-center flex-shrink mb-3">
        <p className="pr-6 text-xs lg:text-sm">
          {transaction?.credit?.firstname}
        </p>
        <img
          src={transaction?.credit?.image}
          className="w-10 h-10 lg:w-12 lg:h-12"
          alt=""
        />
      </div>
    </div>
  );
};

const IncomeTransactionHistoryModal = ({
  show,
  date,
  handleCloseIncomeTransactionModal,
  ...props
}) => {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTransactionHistory = async () => {
    setLoading(true);
    let params = {
      type: "income"
    }
    if (date) params.date = new Date(date.setDate(date.getDate() + 1)).toISOString()

    await props.getTransactionsApi(params);
    setLoading(false);
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  useEffect(() => {
    setTransactionHistory(props.transactions);
  }, [props.transactions]);

  const handleSearch = (e) => {
    e.preventDefault();
    setTransactionHistory(
      transactionHistory.filter((a) =>
        a?.userId.firstname.includes(e.target.value)
      )
    );
  };

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-right sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block relative top-0 align-top bg-white text-left overflow-hidden shadow-xl transform transition-all sm:max-w-xl sm:w-full h-screen"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white">
            <div className="">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="px-5 lg:px-12 ">
                <div className="text-right">
                  <button
                    onClick={handleCloseIncomeTransactionModal}
                    className="mt-3 px-4 py-2 rounded-full bg-temp-green-light focus:outline-none"
                  >
                    x
                  </button>
                </div>

                <h3
                  className="leading-6 text-base md:text-lg lg:text-xl font-semibold text-temp-primary pt-6"
                  id="modal-headline"
                >
                  Income Transaction
                </h3>
                <div className="mt-6">
                  <input
                    className="flex-1 rounded-full border border-temp-green-500 placeholder-temp-secondary text-xs lg:text-sm w-full h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-temp-secondary focus:outline-none"
                    placeholder="Search by name"
                    type="text"
                    onChange={handleSearch}
                  />{" "}
                </div>

                <div className="income-transaction-list mt-12 overflow-auto max-h-96">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      {transactionHistory?.length > 0 ? (
                        <>
                          {transactionHistory?.map((trans, index) => (
                            <TransactionItem key={index} transaction={trans} />
                          ))}
                        </>
                      ) : (
                        <NoRecordReturned title="No transaction history record returned." />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.transactions.transactions,
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(IncomeTransactionHistoryModal)
);
