const Actions = {
  ALERT_SUCCESS: "ALERT_SUCCESS",
  ALERT_ERROR: "ALERT_ERROR",
  ALERT_CLEAR: "ALERT_CLEAR",
  ALERT_INFO: "ALERT_INFO",
  ALERT_WARNING: "ALERT_WARNING",

  // Auth
  VERIFY_PASSWORD: "VERIFY_PASSWORD",
  VERIFY_EMAIL: "VERIFY_EMAIL",

  USER: "USER",

  RESEND_EMAIL: "RESEND_EMAIL",
  RESEND_CODE: "RESEND_CODE",

  CHANGE_PHONE_NUMBER_AND_RESEND_CODE: "CHANGE_PHONE_NUMBER_AND_RESEND_CODE",

  VERIFY_PHONE_NUMBER_SUCCESSFUL: "VERIFY_PHONE_NUMBER_SUCCESSFUL",

  // users
  INITIATE_USER_DETAILS: "INITIATE_USER_DETAILS",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  UPDATE_USER: "UPDATE_USER",

  // banks
  GET_BANK_DETAILS: "GET_BANK_DETAILS",
  GET_BANK_CARD: "GET_BANK_CARD",
  SUCCESSFULLY_ADD_BANK_DETAILS: "SUCCESSFULLY_ADD_BANK_DETAILS",

  // transactions
  GET_TRANSACTIONS: "GET_TRANSACTIONS",

  // Wallets
  GET_WALLETS: "GET_WALLETS",
  UPDATE_WALLETS_AFTER_WITHDRAWAL: "UPDATE_WALLETS_AFTER_WITHDRAWAL",

  // Payment
  INITIATE_PAYMENT_WITHDRAWAL: "INITIATE_PAYMENT_WITHDRAWAL",

  // items
  CLEAR_ITEM: "CLEAR_ITEM",
  CREATE_ITEM: "CREATE_ITEM",
  UPDATE_ITEM: "UPDATE_ITEM",
  GET_ITEMS: "GET_ITEMS",
  GET_SINGLE_ITEM: "GET_SINGLE_ITEM",
  DELETE_ITEM: "DELETE_ITEM",
  CHECK_ITEM_AVAILABILITY: "CHECK_ITEM_AVAILABILITY",

  // Category
  GET_CATEGORIES: "GET_CATEGORIES",

  // Type
  GET_TYPES: "GET_TYPES",
  ADD_TYPE: "ADD_TYPE",

  // Address
  CREATE_ADDRESS: "CREATE_ADDRESS",
  GET_ADDRESSES: "GET_ADDRESSES",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  DELETE_ADDRESS: "DELETE_ADDRESS",

  // Contact
  SUBMIT_FEEDBACK: "SUBMIT_FEEDBACK",

  // items
  CREATE_ORDER: "CREATE_ORDER",
  UPDATE_ORDER: "UPDATE_ORDER",
  GET_ORDERS: "GET_ORDERS",
  GET_SINGLE_ORDER: "GET_SINGLE_ORDER",
  DELETE_ORDER: "DELETE_ORDER",
  ACCEPT_ORDER: "ACCEPT_ORDER",
  DECLINE_ORDER: "DECLINE_ORDER",
  CONFIRM_ORDER_PICKUP: "CONFIRM_ORDER_PICKUP",
  CONFIRM_ORDER_RETURN: "CONFIRM_ORDER_RETURN",

  // Review
  CREATE_REVIEW: "CREATE_REVIEW",

  // Visits Count
  SITE_VISITED: "SITE_VISITED",

  //Notifications
  SAVE_NOTIFICATIONS_SETTINGS: "SAVE_NOTIFICATIONS_SETTINGS",

  // Emissions
  GET_EMISSIONS: "GET_EMISSIONS",
  ADD_EMISSION: "ADD_EMISSION",
  GET_EMISSION_LB: "GET_EMISSION_LB",

  // Notifications
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",

  // Referral
  GET_LOGGEDIN_REFERRAL_DETAILS: "GET_LOGGEDIN_REFERRAL_DETAILS",
  GET_REFERRED_LIST: "GET_REFERRED_LIST",

  // Chat
  GET_REALTIME_FIREBASE_CHAT_USERS: "GET_REALTIME_FIREBASE_CHAT_USERS",
  GET_REALTIME_MESSAGES: "GET_REALTIME_MESSAGES",
  GET_REALTIME_FIREBASE_CHAT_USER: "GET_REALTIME_FIREBASE_CHAT_USER",
  GET_REALTIME_MESSAGES_FOR_ALL_USERS: "GET_REALTIME_MESSAGES_FOR_ALL_USERS",
  STORE_CHAT_SENT_MESSAGE: "STORE_CHAT_SENT_MESSAGE",

  // Voucher
  APPLY_VOUCHER: "APPLY_VOUCHER",

  // Apply referral Bonus on item
  APPLY_REFERRAL_BONUS_ON_ITEM_AVAILABILITY:
    "APPLY_REFERRAL_BONUS_ON_ITEM_AVAILABILITY",

    // Serial Number
    GET_AVAILABLE_SERIAL_NUMBERS: "GET_AVAILABLE_SERIAL_NUMBERS",

    // Late return
    GET_LATE_RETURNS: "GET_LATE_RETURNS"
};

export default Actions;
