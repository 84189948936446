/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';

const Tabs = ({ tabList = [], selectedTab, handleChangeCurrentTab }) => {
    return <div
        className="relative pt-4"
    >
        <div className="container mx-auto">
            <div className="relative flex flex-row text-xs lg:text-sm">
                {
                    tabList?.map((t, index) => <button
                        key={index}
                        className={`pb-4 mr-8 relative rental-activity-tab focus:outline-none ${selectedTab === t
                            ? "bg-white text-temp-green-500 active"
                            : ""
                            } `}
                        onClick={() => handleChangeCurrentTab(t)}
                    >
                        {t}
                    </button>)
                }
            </div>
        </div>
    </div>
}

const ProfileHeader = ({
    title,
    currentPageName,
    tabList,
    selectedTab,
    handleChangeCurrentTab
}) => {
    return <section className="rental-activity-header pt-4 pb-2 lg:pb-4">
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between items-center mb-3">
                <h3 className="font-medium text-xl lg:text-2xl text-temp-primary">
                    {title}
                </h3>
            </div>

            {
                currentPageName && (
                    <p className="text-temp-primary mt-4 text-xs lg:text-sm space-x-2 pb-2">
                        <span>
                            <Link to="/profile">Profile</Link>{" "}
                        </span>
                        <span>&gt;</span>
                        <span className='text-temp-secondary'>{currentPageName}</span>
                    </p>
                )

            }

            {
                tabList && <Tabs
                    selectedTab={selectedTab}
                    tabList={tabList}
                    handleChangeCurrentTab={handleChangeCurrentTab}
                />
            }
        </div>
    </section>
};

export default ProfileHeader;
