import React from 'react'


const UploadGoBackLink = ({handleGoBack}) => {
    return (
        <div className="text-sm flex items-center text-temp-gray cursor-pointer"
        onClick={handleGoBack}
        >
            <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    y1="-0.75"
                    x2="7.55688"
                    y2="-0.75"
                    transform="matrix(-0.675384 -0.737466 -0.675385 0.737466 5.89526 10.3997)"
                    stroke="#616564"
                    strokeWidth="1.5"
                />
                <line
                    y1="-0.75"
                    x2="6.99046"
                    y2="-0.75"
                    transform="matrix(0.730108 -0.683331 -0.617468 -0.786596 0.791504 4.82837)"
                    stroke="#616564"
                    strokeWidth="1.5"
                />
            </svg>

            <p className="pl-2 mt-1">Back</p>
        </div>
    )
}

export default UploadGoBackLink
