import React from "react";

const Loader = ({ title }) => {
  return (
    <div className="text-center">
      <div className="py-6 pb-10 w-full mx-auto">
        <div className="loading-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <p className="text-lg text-temp-primary pt-5">
        {title || "Fetching data... please wait"}
      </p>
    </div>
  );
};

export default Loader;
