import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import MainRoutes from './routes';
// eslint-disable-next-line no-unused-vars
// css
import './assets/css/tailwind.css';

import { CookiesProvider } from 'react-cookie';
import 'react-image-gallery/styles/css/image-gallery.css';
import configureStore from './store';

import 'firebase/auth';
import 'firebase/firestore';
import GA4React from 'ga-4-react';
import { Toaster } from 'react-hot-toast';
import { registerServiceWorker } from './serviceWorker';

const ga4react = new GA4React('G-BE4VMJEYNC');

(async () => {
  await ga4react
    .initialize()
    .then(() => console.log('Success.'))
    .catch(() => console.log('Failure.'))
    .finally(() => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        const root = ReactDOM.createRoot(rootElement);
        root.render(
          <CookiesProvider>
            <Provider store={configureStore()}>
              <Toaster />
              <MainRoutes />
            </Provider>
          </CookiesProvider>
        );
      }
    });
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
