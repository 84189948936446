import React from "react";
import ProtectImg from "../../../assets/images/svg/protect.svg";

const EquipmentProtect = ({ item }) => {
  return (
    <>
      {item?.tempownProtect && (
        <p className="pt-3 lg:pt-6 mb-6 lg:mb-8">
          <img src={ProtectImg} alt="" className="protect" />
        </p>
      )}
    </>
  );
};

export default EquipmentProtect;
