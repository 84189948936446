import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";

import ShirtImage from "../../../../assets/images/svg/redeem/shirt.svg";
import WatchImage from "../../../../assets/images/svg/redeem/watch.svg";
import CapImage from "../../../../assets/images/svg/redeem/cap.svg";

const RedeemModal = ({
  show,
  handleCloseModal,
}) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [isNext, setIsNext] = useState(false);

  const initialState = {
    itemSelected: "",
  };

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>

              {!isNext ? (
                <div className="text-center">
                  <h3
                    className="leading-6 text-lg lg:text-xl xl:text-2xl font-medium text-temp-secondary font-serif"
                    id="modal-headline"
                  >
                    Please select any of the items
                  </h3>
                  <div className="mt-10">
                    <Formik
                      initialValues={initialState}
                      onSubmit={async (
                        values,
                      ) => {
                        setSelectedItem(values.itemSelected);
                        setIsNext(true);
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        handleBlur,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="w-full text-center">
                              <div className="grid grid-cols-3 gap-4">
                                <div className="">
                                  <label className="custom-checkbox text-sm lg:text-base text-temp-gray">
                                    <input
                                      type="radio"
                                      name="itemSelected"
                                      value="shirt"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <img
                                      src={ShirtImage}
                                      className="mx-auto mb-3 h-16 w-16  md:h-32 md:w-32 px-6 py-6 rounded-full object-contain bg-temp-green-100"
                                      width="80rem"
                                      alt=""
                                    />
                                    Shirt
                                  </label>
                                </div>

                                <div className="">
                                  <label className="custom-checkbox text-sm lg:text-base text-temp-gray">
                                    <input
                                      type="radio"
                                      name="itemSelected"
                                      value="wristwatch"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <img
                                      src={WatchImage}
                                      className="mx-auto mb-3 h-16 w-16 md:h-32 md:w-32 px-6 py-6 rounded-full object-contain bg-temp-green-100"
                                      width="80rem"
                                      alt=""
                                    />
                                    Wristwatch
                                  </label>
                                </div>
                                <div className="">
                                  <label className="custom-checkbox text-sm lg:text-base text-temp-gray">
                                    <input
                                      type="radio"
                                      name="itemSelected"
                                      value="facecap"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <img
                                      src={CapImage}
                                      className="mx-auto mb-3 h-16 w-16 md:h-32 md:w-32 px-6 py-6 rounded-full object-contain bg-temp-green-100"
                                      width="80rem"
                                      alt=""
                                    />
                                    Facecap
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex mt-8 items-baseline justify-center">
                            <button
                              onClick={handleCloseModal}
                              className="text-xs lg:text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="text-xs lg:text-sm mt-5 mb-4 py-3 px-8 bg-temp-green-500 bg-gradient font-bold text-white rounded-lg focus:outline-none"
                            >
                              Redeem
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <h3
                    className="leading-6 text-xl md:text-2xl lg:text-3xl font-medium text-temp-secondary font-serif"
                    id="modal-headline"
                  >
                    Congratulations
                  </h3>
                  <div className="my-4 text-temp-gray">
                    <p className="pt-3 pb-6 text-sm lg:text-base">
                      You have won a{" "}
                      <span className="font-bold text-lg text-temp-green-500">
                        {selectedItem}
                      </span>
                    </p>

                    <div className="mx-auto text-center mb-8">
                      <img
                        src={
                          selectedItem === "shirt"
                            ? ShirtImage
                            : selectedItem === "wristwatch"
                            ? WatchImage
                            : selectedItem === "facecap"
                            ? CapImage
                            : ""
                        }
                        className="mx-auto h-32 w-32 px-6 py-6 rounded-full object-contain bg-temp-green-100"
                        width="80rem"
                        alt=""
                      />
                    </div>

                    <p className="text-sm lg:text-base w-10/12 md:w-full mx-auto">
                      You will be contacted shortly on collection
                    </p>
                  </div>

                  <div className="flex mt-8 justify-center">
                    <button
                      onClick={handleCloseModal}
                      className="text-xs lg:ext-sm text-center font-semibold rounded-lg bg-temp-green-100 py-2 px-6 text-temp-green-500 mr-8 mb-4 focus:outline-none"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
