import React from 'react';

const ReferralStatCard = ({ title, value }) => {
    return (
        <div className="refer-stat-card px-6 py-6">
            <h3 className="text-temp-green-500 mb-5">{title}</h3>

            <p className="text-4xl text-temp-primary">{value}</p>
        </div>
    );
};

export default ReferralStatCard;
