import React from "react";

export const CheckMarkCircleIcon = ({ fill }) => {
  return (
    <svg
      className="w-6 h-6 mx-auto pt-0.5"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.5"
        cy="10.5"
        r="10"
        fill={fill || "#08A05C"}
        stroke={fill || "#08A05C"}
      />
      <line
        x1="5.39043"
        y1="9.31265"
        x2="8.89043"
        y2="13.6877"
        stroke="white"
      />
      <line
        x1="8.18765"
        y1="13.6096"
        x2="16.9377"
        y2="6.60956"
        stroke="white"
      />
    </svg>
  );
};
