import React from "react";

const InputGroup = ({
  type,
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <div className="mb-6">
      <div className="relative input input-field w-full mb-2">
        <input
          type={type || "text"}
          className={`block px-5 h-14 w-full rounded-xl border bg-white placeholder-temp-gray focus:outline-none text-sm md:text-base ${
            error ? "border-red-600" : " border-temp-gray-light"
          }`}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <label className="text-temp-secondary input-label text-xs lg:text-xs">{label}</label>
      </div>
      <span className="text-red-600 text-xs lg:text-sm">{error}</span>
    </div>
  );
};

export default InputGroup;
