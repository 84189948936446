import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";
import { clearConversation } from ".";

export const createOrderApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/order";
  try {
    const response = await axios.post(url, payload);
    const responseData = response.data.data;

    dispatch({
      type: Actions.CREATE_ORDER,
      payload: responseData,
    });

    dispatch(alertActions.success(response?.data?.message));
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const getOrdersApi = (params) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/order";
  try {
    const response = await axios.get(url, {
      params,
    });
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_ORDERS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const getSingleOrderApi = (orderId) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/${orderId}`;
  try {
    const response = await axios.get(url);
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_SINGLE_ORDER,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const cancelOrdersApi = (orderId) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/${orderId}/cancel`;
  try {
    const response = await axios.get(url);

    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const acceptOrdersApi = (orderId) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/${orderId}/accept`;
  try {
    const response = await axios.get(url);

    dispatch({
      type: Actions.ACCEPT_ORDER,
      payload: orderId,
    });

    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const rejectOrdersApi = (orderId, data) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/${orderId}/reject`;
  try {
    const response = await axios.post(url, data);

    dispatch({
      type: Actions.DECLINE_ORDER,
      payload: {
        orderId,
        status: "success",
      },
    });

    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch({
      type: Actions.DECLINE_ORDER,
      payload: {
        orderId,
        status: "failed",
      },
    });
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const confirmOrderPickupApi = (
  orderId,
  data,
  isPickupCodeConfirmation = false,
  orderDetails
) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = isPickupCodeConfirmation
    ? `/order/${orderId}/confirm-pickup/verify/token`
    : `/order/${orderId}/confirm-pickup`;
  try {
    const response = await axios.post(url, data, {
      headers: !isPickupCodeConfirmation
        ? {
          "Content-Type": "multipart/form-data",
        }
        : {},
    });

    dispatch({
      type: Actions.CONFIRM_ORDER_PICKUP,
      payload: {
        confirmPickupStatus: "success",
        data: isPickupCodeConfirmation
          ? {
            ...orderDetails,
            confirmPickup: {
              ...orderDetails?.confirmPickup,
              tokenConfirmed: true,
            },
          }
          : response?.data?.data,
      },
    });

    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch({
      type: Actions.CONFIRM_ORDER_PICKUP,
      payload: {
        confirmPickupStatus: "failed",
      },
    });
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const confirmOrderReturnApi = (
  orderId,
  data,
  isReturnCodeConfirmation = false,
  orderDetails,
  userId
) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = isReturnCodeConfirmation
    ? `/order/${orderId}/confirm-return/verify/token`
    : `/order/${orderId}/confirm-return`;
  try {
    const response = await axios.post(url, data, {
      headers: !isReturnCodeConfirmation
        ? {
          "Content-Type": "multipart/form-data",
        }
        : {},
    });

    // IF EQUIPMENT IS RETURNED, CLEAR OF CHAT CONVERSATION
    if (!isReturnCodeConfirmation && response?.data.message) {
      await clearConversation(userId)
    }

    dispatch({
      type: Actions.CONFIRM_ORDER_RETURN,
      payload: {
        confirmReturnStatus: "success",
        data: isReturnCodeConfirmation
          ? {
            ...orderDetails,
            confirmReturn: {
              ...orderDetails?.confirmReturn,
              tokenConfirmed: true,
            },
          }
          : response?.data?.data,
      },
    });

    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch({
      type: Actions.CONFIRM_ORDER_RETURN,
      payload: {
        confirmReturnStatus: "failed",
      },
    });
    dispatch(alertActions.error(error.response?.data.message));
  }
};



export const setSerialNumbersForOrderApi = (orderId, payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/${orderId}/set-serial-numbers`;
  try {
    const response = await axios.post(url, payload);
    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const uploadLenderItemImagesForOrderApi = (orderId, payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/${orderId}/upload-lender-items-image`;
  try {
    const response = await axios.post(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const orderUpdate = (order) => {
  return {
    type: Actions.GET_SINGLE_ORDER,
    payload: order
  }
}

export const createOrderComplaintFeedbackApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/feedback-complaint`;
  try {
    const response = await axios.post(url, payload);
    return ({
      ...response?.data?.data
    })
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const uploadOrderComplaintFeedbackImageApi = (feedbackId, payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/order/upload-complaint-image/${feedbackId}`;
  try {
    await axios.put(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(alertActions.success("Complaint Submitted Successfully. Our customer support team will take it from here and get back to you as soon as possible."));
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};