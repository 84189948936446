import React from "react";
import BorrowerInformationWithViewButton from "../../../components/blocks/itemBits/BorrowerInformationWithViewButton";
import LenderInformationWithViewButton from "../../../components/blocks/itemBits/LenderInformationWithViewButton";
import CheckImage from "../../../assets/images/svg/checkgradient.svg";

const PickUpDisplayForLenderOnly = ({ code }) => {
  return (
    <>
      <div className="mt-6 px-6 lg:px-8 py-3">
        <p className="text-temp-primary text-sm-15 text-left">
          Return Code: <span className="font-bold text-temp-secondary pl-2">{code}</span>
        </p>
      </div>
      <div className="px-6 lg:px-8">
        <p className="text-xs lg:text-sm text-temp-gray text-left leading-5">
          Please give the Borrower the code above. You will be required to take
          a picture of the item to confirm return.
        </p>
      </div>
    </>
  );
};

const CodeSubmittedDisplayForLenderOnly = () => {
  return (
    <div className="mt-4 lg:mt-6 text-center mx-auto bg-temp-green-100 py-2 flex flex-wrap justify-center items-center">
      <img src={CheckImage} className="mr-2" alt="" />
      <p className="text-sm text-temp-green-500">Code submitted</p>
    </div>
  );
};

const OngoingCard = ({
  itemDetails,
  orderDetails,
  handleCheckItemAvailabilityModalShow,
  handleShowReturnModal,
  handleShowEnterCodeModal,
  loggedInUserDetails,
  handleShowComplaintForm,
}) => {
  return (
    <div
      className="rounded-xl py-6 lg:py-8 mx-auto"
      style={{
        border: "0.5px solid #B0B0B0",
      }}
    >
      <div className="text-temp-secondary font-semibold px-6 lg:px-8 mb-4 lg:mb-6 text-base lg:text-lg">
        Order Activities
      </div>
      <div className="flex flex-wrap items-baseline pr-8">
        <p className="px-6 lg:px-8 text-sm lg:text-sm-15 pb-4 text-temp-gray">
          Status
        </p>
        <p className="px-4 lg:px-6 font-medium text-xs lg:text-sm ongoing-status rounded-xl pb-3 pt-3">
          Ongoing
        </p>
      </div>

      <div className="mt-4">
        <div className={`px-6 lg:px-8 grid ${loggedInUserDetails?._id === orderDetails?.borrower?._id ? "grid-cols-1 lg:grid-cols-2 lg:gap-4" : "grid-cols-1"}`}>
          {loggedInUserDetails?._id === orderDetails?.borrower?._id && (
            <button
              type="button"
              onClick={handleCheckItemAvailabilityModalShow}
              className="w-full mt-5 font-bold text-xs lg:text-sm py-4 px-4 border border-temp-green-500 text-temp-green-500 rounded-xl focus:outline-none bg-white"
            >
              Extend Usage
            </button>
          )}

          {loggedInUserDetails?._id === orderDetails?.borrower?._id &&
            orderDetails?.confirmReturn?.tokenConfirmed ? (
            ""
          ) : (
            <>
              {orderDetails?.lender?._id !== orderDetails?.borrower?._id && (
                <button
                  onClick={
                    loggedInUserDetails?._id === orderDetails?.lender?._id
                      ? handleShowReturnModal
                      : handleShowEnterCodeModal
                  }
                  className="w-full mt-5 font-bold text-xs lg:text-sm py-4 px-4 text-white rounded-xl focus:outline-none bg-gradient"
                >
                  Confirm Return
                </button>
              )}
            </>
          )}
        </div>

        {loggedInUserDetails?._id === orderDetails?.borrower?._id &&
          orderDetails?.confirmReturn?.tokenConfirmed ? (
          <CodeSubmittedDisplayForLenderOnly />
        ) : (
          <>
            {loggedInUserDetails?._id === orderDetails?.lender?._id &&
              !orderDetails?.confirmReturn?.tokenConfirmed ? (
              <PickUpDisplayForLenderOnly
                code={orderDetails?.confirmReturn?.token?.content}
              />
            ) : loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
              <div className="mt-6 px-6 lg:px-8">
                <p className="text-temp-gray text-sm">
                  Request for return code from the Lender.
                </p>
              </div>
            ) : (
              ""
            )}
          </>
        )}

        {loggedInUserDetails?._id === orderDetails?.borrower?._id &&
          orderDetails?.confirmReturn?.tokenConfirmed ? (
          ""
        ) : (
          <>
            {orderDetails?.lender?._id !== orderDetails?.borrower?._id && (
              <div className="px-6 lg:px-8">
                {loggedInUserDetails?._id === orderDetails?.borrower?._id && (
                  <div className="mt-8 border border-temp-green-500 bg-temp-green-100 px-4 lg:px-6 py-3 rounded-xl text-sm">
                    <p className="text-temp-gray  text-left w-11/12">
                      Are there complications with the equipment within{" "}
                      <span className="text-temp-primary font-semibold">
                        6 hours
                      </span>{" "}
                      of pickup?
                    </p>
                    <p
                      className="font-semibold pt-2 cursor-pointer"
                      onClick={handleShowComplaintForm}
                    >
                      Click here to complain
                    </p>
                  </div>
                )}
              </div>
            )}{" "}
          </>
        )}
      </div>

      {/* Desktop Only */}
      <div className="pt-4 hidden lg:block px-6 lg:px-8">
        {" "}
        {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
          <LenderInformationWithViewButton
            className="px-0"
            title="Lender"
            item={itemDetails}
            pickupContact={orderDetails?.lender?.phone}
          />
        ) : (
          <BorrowerInformationWithViewButton
            className="px-0"
            title="Borrower"
            user={orderDetails?.borrower}
            pickupContact={orderDetails?.lender?.phone}
          />
        )}
      </div>
    </div>
  );
};

export default OngoingCard;
