import Actions from "../actions/actionTypes";

const initialState = {
  verifyPassword: false,
  completePhoneNumberVerification: false,
  verifyEmail: {
    type: "",
    message: "",
  },
  resendEmailStatus: {
    type: "",
    message: "",
  },
  resendCodeStatus: {
    type: "",
    message: "",
  },
  changeNumberAndResendOtpStatus: {
    type: "",
    message: "",
  },

  user: {},
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.VERIFY_PASSWORD:
      return {
        ...state,
        verifyPassword: action.payload,
      };

    case Actions.VERIFY_EMAIL:
      return {
        ...state,
        verifyEmail: action.payload,
      };

    case Actions.RESEND_EMAIL:
      return {
        ...state,
        resendEmailStatus: action.payload,
      };

    case Actions.RESEND_CODE:
      return {
        ...state,
        resendCodeStatus: action.payload,
      };

    case Actions.CHANGE_PHONE_NUMBER_AND_RESEND_CODE:
      return {
        ...state,
        changeNumberAndResendOtpStatus: action.payload,
      };

    case Actions.VERIFY_PHONE_NUMBER_SUCCESSFUL:
      return {
        ...state,
        completePhoneNumberVerification: action.payload
      }

    case Actions.USER:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default AuthReducer;
