import { Transition } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/svg/logo/logo2.svg";

const UnAuthMobileMenu = ({ isOpen, handleCloseMenu }) => {
  return (
    <Transition
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      {(ref) => (
        <div
          ref={ref}
          className="absolute z-30 top-0 inset-x-0 transition transform origin-top-right md:hidden"
          onMouseLeave={handleCloseMenu}
        >
          <div className="z-30 rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-6 w-auto" src={Logo} alt="TempOwn" />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                    onClick={handleCloseMenu}
                  >
                    <span className="sr-only">Close menu</span>

                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className="py-6 px-5 space-y-6">
              <div className="text-sm-15 flex flex-col px-2 space-y-8 pb-6 pt-4">
                <Link to={"/enterprise"} className="text-temp-primary hover:text-temp-secondary transition-all">Enterprise <span className="text-xs coming-soon-tip rounded-full py-2 px-3 ml-1">Coming soon</span></Link>
                <Link to={"/learn"} className="text-temp-primary hover:text-temp-secondary transition-all">Learn</Link>
                <Link to={"/lender"} className="text-temp-primary hover:text-temp-secondary transition-all">List an item</Link>
              </div>
              <div>
                <Link
                  to="/signup"
                  className="w-full text-sm-15 flex items-center justify-center px-4 py-2 border border-transparent rounded-xl shadow-sm font-medium text-white bg-temp-secondary"
                >
                  Get Started
                </Link>
                <p className="mt-6 text-center text-sm text-gray-500">
                  Existing member?{" "}
                  <Link
                    to="/signin"
                    className="font-semibold text-temp-green"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default UnAuthMobileMenu;
