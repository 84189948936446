/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import MainContainer from "../../components/layout/MainContainer";

import CheckIcon from "../../assets/images/svg/checked.svg";
// import ReviewCard from "../../components/blocks/ReviewCard";
import { connect } from "react-redux";
import { } from "react-router";
import { scrollToTop } from "../../utils/scrollToTop";

import Loader from "../../components/blocks/Loader";
import StarRating from "../../components/blocks/StarRating";
import ItemListing from "../../components/blocks/itemBits/ItemListing";
import VerifyCard from "../../components/blocks/itemBits/VerifyCard";
import ItemPreloader from "../../components/blocks/preloader/ItemPreloader";
import * as actions from "../../store/actions";
import Paginator from "../../utils/pagination";

const ProfileCard = ({ userDetails }) => {
  return (
    <div className="relative block flex-1 mb-3">
      <h4 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-temp-primary">
        {userDetails?.firstname} {userDetails?.lastname}
      </h4>
      <p className="text-xs text-temp-gray mt-2 lg:mt-0">
        Member since{" "}
        {new Date(userDetails?.createdAt).toLocaleDateString(
          "en-US",

          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        )}
      </p>
      <div className="flex justify-center lg:block">
        <StarRating rating={userDetails?.rating} />
      </div>

      <div className="mt-4 flex flex-wrap flex-row justify-center md:justify-start items-center">
        {userDetails?.verified_id && <VerifyCard title="Identity verified" />}
        <VerifyCard title="Contact verified" />
      </div>
    </div>
  );
};

const ListedItems = ({ itemLoading, items }) => {
  return (
    <>
      {itemLoading ? (
        <div className="mt-14 mx-auto">
          <ItemPreloader />
        </div>
      ) : items?.length > 0 ? (
        <div className="w-full mx-auto relative mt-14 md:mt-20 lg:mt-24">
          <h3 className="font-medium text-left text-temp-primary text-lg md:text-xl lg:text-2xl pb-12">
            Listed Equipment
          </h3>
          <ItemListing
            items={items}
            gridClassName="grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

// const ReviewsList = () => {
//   return (
//     <div className="order-2 lg:order-3 w-full lg:w-8/12 mx-auto relative mt-20 lg:mt-0">
//       <h3 className="font-serif text-center text-temp-green-500 text-3xl pb-12">
//         Reviews
//       </h3>

//       <div className="space-y-12 lg:space-y-16">
//         <ReviewCard />
//         <ReviewCard />
//         <ReviewCard />
//         <ReviewCard />
//       </div>
//     </div>
//   );
// };

// Main Component
const LenderBorrowerProfile = (props) => {
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userId, setUserId] = useState("");
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    scrollToTop();
    let pathname = props.location.pathname;
    if (pathname?.includes("lender")) {
      pathname = pathname.replace("/lender-profile/", "");
    } else {
      pathname = pathname.replace("/borrower-profile/", "");
    }

    const userId = pathname;

    setUserId(userId);
    fetchData(userId);

    const params = {
      userId,
      limit: 12,
    };

    fetchItem(params);
  }, []);

  const fetchData = async (id) => {
    setLoading(true);
    await props.getUserApi(id);
    setLoading(false);
  };

  useEffect(() => {
    setUserDetails(props.user);
  }, [props.user]);

  const [itemLoading, setItemLoading] = useState(false);

  const fetchItem = async (params) => {
    setItemLoading(true);
    await props.getItemsApi(params);
    setItemLoading(false);
  };

  const fetchPageItem = async (params) => {
    const Params = {
      userId,
      limit: 12,
      ...params,
    };

    fetchItem(Params);
  };

  useEffect(() => {
    setItems(props.items);
    setPagination(props.pagination);
  }, [props.items]);

  return (
    <MainContainer hasMainContainer={false}>
      <section className="relative container px-4 mx-auto">
        {loading ? (
          <div className="mt-32 lg:mt-52 mb-40 lg:mb-52">
            <Loader />
          </div>
        ) : (
          <div className="w-full pt-10 lg:pt-14 pb-36 relative">
            <div className="flex flex-row flex-wrap relative w-full">
              {/* Desktop Start */}
              <div className="hidden lg:block w-2/12">
                <div className="rounded-2xl bg-temp-green-100 pt-8 pb-12 sticky top-36">
                  <div className="pb-3 flex flex-shrink justify-center items-end">
                    <img
                      src={userDetails?.image || Image}
                      width="100%"
                      className="h-20 w-20 lg:h-20 lg:w-20 object-cover rounded-full border-4 border-white outline-none bg-white"
                      alt=""
                    />
                    <img
                      src={CheckIcon}
                      width="22rem"
                      className="border-2 border-white rounded-full absolute ml-12"
                      alt=""
                    />
                  </div>

                  <div className="text-center mt-4">
                    <p className="text-sm text-temp-primary mb-1">User Type</p>
                    <p className="text-base font-medium text-temp-green-500">
                      {userDetails?.verified_lender ? "Lender" : "Borrower"}
                    </p>
                  </div>
                </div>
              </div>
              {/* Desktop End */}
              <div className="w-full lg:w-10/12 lg:pl-14 xl:pl-20">
                <div className="lg:flex lg:flex-col flex-row flex-wrap items-start text-center lg:text-left">
                  {/* Mobile Start */}
                  <div className="relative mr-4 pb-3 flex flex-shrink justify-center lg:hidden">
                    <img
                      src={userDetails?.image || Image}
                      width="100%"
                      className="h-20 w-20 lg:h-20 lg:w-20 object-cover rounded-full border-none outline-none bg-white"
                      alt=""
                    />
                    <img
                      src={CheckIcon}
                      width="22rem"
                      className="border-2 border-white rounded-full absolute lg:right-0 ml-12 lg:mr-0 bottom-2 lg:bottom-3 -mt-4"
                      alt=""
                    />
                  </div>
                  {/* Mobile End */}
                  <ProfileCard userDetails={userDetails} />
                </div>

                <ListedItems itemLoading={itemLoading} items={items} />

                {/* <ReviewsList /> */}
                <Paginator
                  pageLimit={12}
                  maxPages={pagination?.totalPages}
                  currentPage={pagination?.page}
                  getPageApi={fetchPageItem}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    alert: state.alert,
    items: state.items.items,
    pagination: state.items.pagination,
  };
};

export default (
  connect(mapStateToProps, actions)(LenderBorrowerProfile)
);
