import React from "react";

export const RibbonIconLeft = () => {
  return (
    <svg
      width="84"
      height="109"
      viewBox="0 0 84 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9919 93.3788C22.0137 93.3788 21.2209 92.5858 21.2209 91.6078C21.2209 90.6297 20.4287 89.8367 19.4498 89.8367C18.471 89.8367 17.6788 90.6297 17.6788 91.6078C17.6788 92.586 16.8858 93.3788 15.9078 93.3788C14.9289 93.3788 14.1367 94.1718 14.1367 95.1498C14.1367 96.1279 14.9289 96.9209 15.9078 96.9209C16.886 96.9209 17.6788 97.7139 17.6788 98.6919C17.6788 99.6699 18.471 100.463 19.4498 100.463C20.4287 100.463 21.2209 99.6699 21.2209 98.6919C21.2209 97.7137 22.0139 96.9209 22.9919 96.9209C23.9708 96.9209 24.7629 96.1279 24.7629 95.1498C24.7629 94.1718 23.9708 93.3788 22.9919 93.3788Z"
        fill="#EFFBF9"
      />
      <path
        d="M8.93201 68.3503L4.12175 65.4053L0.836843 67.3876L0.129619 69.9325C-0.29382 71.4561 0.344213 73.076 1.69288 73.9018L8.83983 78.2771L10.4955 72.3196C10.9189 70.7959 10.2809 69.1761 8.93201 68.3503Z"
        fill="#EFFBF9"
      />
      <path
        d="M34.1775 9.92709L37.8677 12.1863L42.2726 10.8896L42.9799 8.34468C43.4033 6.82102 42.7653 5.20113 41.4164 4.37533L34.2696 0L32.614 5.95775C32.1906 7.48141 32.8286 9.10129 34.1775 9.92709Z"
        fill="#EFFBF9"
      />
      <path
        d="M14.9744 26.1227L30.9819 35.9225L35.637 34.7793L36.3442 32.2341C36.7676 30.7105 36.1296 29.0906 34.7807 28.2648L18.1014 18.0537L14.118 19.6085L13.4108 22.1533C12.9875 23.677 13.6256 25.2969 14.9744 26.1227Z"
        fill="#EFFBF9"
      />
      <path
        d="M37.2318 19.441L14.1172 19.6086L15.7728 13.6508C16.1963 12.1272 17.5786 11.0687 19.1599 11.0572L42.2745 10.8896L40.6189 16.8474C40.1954 18.3711 38.8132 19.4295 37.2318 19.441Z"
        fill="#EFFBF9"
      />
      <path
        d="M8.32601 50.0123L24.531 59.933L28.9885 58.6687L29.6958 56.1236C30.1192 54.5999 29.4812 52.98 28.1323 52.1542L10.5371 41.3824L7.46955 43.4979L6.76232 46.043C6.3391 47.5669 6.97713 49.1867 8.32601 50.0123Z"
        fill="#EFFBF9"
      />
      <path
        d="M30.5834 43.3305L7.46875 43.4981L9.1244 37.5403C9.54784 36.0166 10.9301 34.9582 12.5115 34.9467L35.6261 34.7791L33.9704 40.7369C33.547 42.2607 32.1649 43.319 30.5834 43.3305Z"
        fill="#EFFBF9"
      />
      <path
        d="M23.9505 67.2202L0.835938 67.3878L2.49159 61.43C2.91503 59.9064 4.29733 58.8479 5.87868 58.8364L28.9933 58.6688L27.3376 64.6266C26.9142 66.1502 25.5319 67.2087 23.9505 67.2202Z"
        fill="#EFFBF9"
      />
      <path
        d="M64.9991 100.16L59.6866 99.8001L57.3789 103.058L57.9311 105.64C58.2618 107.187 59.5776 108.327 61.1556 108.434L69.5162 109L68.2234 102.953C67.8927 101.407 66.5768 100.267 64.9991 100.16Z"
        fill="#EFFBF9"
      />
      <path
        d="M60.3025 36.6896L65.4363 37.0372L67.9225 33.7921L67.37 31.2091C67.0394 29.6627 65.7235 28.5226 64.1456 28.4158L55.7852 27.8495L57.0783 33.8962C57.4087 35.4427 58.7245 36.5827 60.3025 36.6896Z"
        fill="#EFFBF9"
      />
      <path
        d="M50.7923 59.9402L69.4241 61.2022L73.1141 58.0387L72.5617 55.4555C72.231 53.909 70.9152 52.769 69.3372 52.6621L50.9728 51.4182L47.0156 54.5639L47.5681 57.1469C47.8987 58.6935 49.2146 59.8334 50.7923 59.9402Z"
        fill="#EFFBF9"
      />
      <path
        d="M67.4148 43.7068L47.0082 54.5642L45.7153 48.5175C45.3847 46.971 46.1191 45.3927 47.5153 44.6497L67.9219 33.7922L69.2148 39.839C69.5456 41.3854 68.8109 42.9638 67.4148 43.7068Z"
        fill="#EFFBF9"
      />
      <path
        d="M55.9683 84.1871L74.43 85.4376L78.2901 82.2857L77.7376 79.7025C77.407 78.1561 76.0912 77.0161 74.5132 76.9092L55.9117 75.6493L52.1914 78.8109L52.7439 81.3939C53.0745 82.9404 54.3904 84.0802 55.9683 84.1871Z"
        fill="#EFFBF9"
      />
      <path
        d="M72.6023 67.9535L52.1957 78.8109L50.9028 72.7642C50.5721 71.2177 51.3066 69.6394 52.7028 68.8964L73.1094 58.0389L74.4023 64.0857C74.7331 65.6321 73.9984 67.2105 72.6023 67.9535Z"
        fill="#EFFBF9"
      />
      <path
        d="M77.7898 92.2002L57.3832 103.058L56.0903 97.0109C55.7596 95.4645 56.4941 93.8861 57.8903 93.1431L78.2969 82.2857L79.5898 88.3324C79.9206 89.8789 79.1861 91.4573 77.7898 92.2002Z"
        fill="#EFFBF9"
      />
      <path
        d="M81.445 18.9941C80.4668 18.9941 79.674 18.2011 79.674 17.2231C79.674 16.245 78.8818 15.452 77.903 15.452C76.9241 15.452 76.1319 16.245 76.1319 17.2231C76.1319 18.2013 75.3389 18.9941 74.3609 18.9941C73.382 18.9941 72.5898 19.7871 72.5898 20.7651C72.5898 21.7432 73.382 22.5362 74.3609 22.5362C75.3391 22.5362 76.1319 23.3292 76.1319 24.3072C76.1319 25.2852 76.9241 26.0783 77.903 26.0783C78.8818 26.0783 79.674 25.2852 79.674 24.3072C79.674 23.329 80.467 22.5362 81.445 22.5362C82.4239 22.5362 83.2161 21.7432 83.2161 20.7651C83.2161 19.7871 82.4239 18.9941 81.445 18.9941Z"
        fill="#EFFBF9"
      />
      <path
        d="M40.7011 79.2104C37.7666 79.2104 35.3879 76.8315 35.3879 73.8973C35.3879 72.919 34.5951 72.1262 33.6169 72.1262C32.6387 72.1262 31.8459 72.919 31.8459 73.8973C31.8459 76.8317 29.467 79.2104 26.5328 79.2104C25.5545 79.2104 24.7617 80.0032 24.7617 80.9814C24.7617 81.9594 25.5545 82.7524 26.5328 82.7524C29.4672 82.7524 31.8459 85.1313 31.8459 88.0656C31.8459 89.0436 32.6387 89.8366 33.6169 89.8366C34.5951 89.8366 35.3879 89.0436 35.3879 88.0656C35.3879 85.1311 37.7668 82.7524 40.7011 82.7524C41.6793 82.7524 42.4721 81.9594 42.4721 80.9814C42.4721 80.0034 41.6793 79.2104 40.7011 79.2104Z"
        fill="#EFFBF9"
      />
      <path
        d="M67.2675 8.36766C64.333 8.36766 61.9544 5.98882 61.9544 3.05455C61.9544 2.07631 61.1615 1.28351 60.1833 1.28351C59.2051 1.28351 58.4123 2.07631 58.4123 3.05455C58.4123 5.98903 56.0334 8.36766 53.0992 8.36766C52.1209 8.36766 51.3281 9.16046 51.3281 10.1387C51.3281 11.1167 52.1209 11.9097 53.0992 11.9097C56.0336 11.9097 58.4123 14.2886 58.4123 17.2228C58.4123 18.2009 59.2051 18.9939 60.1833 18.9939C61.1615 18.9939 61.9544 18.2009 61.9544 17.2228C61.9544 14.2884 64.3332 11.9097 67.2675 11.9097C68.2457 11.9097 69.0385 11.1167 69.0385 10.1387C69.0385 9.16068 68.2457 8.36766 67.2675 8.36766Z"
        fill="#EFFBF9"
      />
    </svg>
  );
};
