import React from "react";

export const RibbonIconRight = () => {
  return (
    <svg
      width="84"
      height="108"
      viewBox="0 0 84 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.3441 92.0185C61.3081 92.0185 62.0894 91.237 62.0894 90.2733C62.0894 89.3095 62.87 88.528 63.8346 88.528C64.7992 88.528 65.5798 89.3095 65.5798 90.2733C65.5798 91.2372 66.3613 92.0185 67.3251 92.0185C68.2897 92.0185 69.0703 92.8 69.0703 93.7637C69.0703 94.7275 68.2897 95.509 67.3251 95.509C66.3611 95.509 65.5798 96.2904 65.5798 97.2542C65.5798 98.218 64.7992 98.9994 63.8346 98.9994C62.87 98.9994 62.0894 98.218 62.0894 97.2542C62.0894 96.2902 61.3079 95.509 60.3441 95.509C59.3795 95.509 58.5989 94.7275 58.5989 93.7637C58.5989 92.8 59.3795 92.0185 60.3441 92.0185Z"
        fill="#EFFBF9"
      />
      <path
        d="M74.1981 67.3545L78.9383 64.4525L82.1753 66.4059L82.8723 68.9137C83.2895 70.4151 82.6608 72.0114 81.3318 72.8252L74.2889 77.1368L72.6574 71.2661C72.2401 69.7646 72.8689 68.1683 74.1981 67.3545Z"
        fill="#EFFBF9"
      />
      <path
        d="M49.3238 9.78248L45.6873 12.0088L41.3465 10.7309L40.6496 8.22311C40.2323 6.72165 40.8611 5.12536 42.1903 4.31159L49.2329 0L50.8645 5.87095C51.2817 7.37242 50.653 8.9687 49.3238 9.78248Z"
        fill="#EFFBF9"
      />
      <path
        d="M68.2455 25.7421L52.4713 35.3992L47.884 34.2726L47.1871 31.7646C46.7698 30.2631 47.3986 28.6668 48.7278 27.853L65.1642 17.7907L69.0895 19.3228L69.7864 21.8306C70.2035 23.3321 69.5748 24.9284 68.2455 25.7421Z"
        fill="#EFFBF9"
      />
      <path
        d="M46.312 19.1578L69.0898 19.3229L67.4583 13.452C67.041 11.9505 65.6789 10.9075 64.1206 10.8961L41.3427 10.731L42.9742 16.602C43.3915 18.1034 44.7536 19.1465 46.312 19.1578Z"
        fill="#EFFBF9"
      />
      <path
        d="M74.7963 49.2837L58.8274 59.0599L54.4348 57.814L53.7379 55.3059C53.3206 53.8045 53.9493 52.2082 55.2786 51.3944L72.6174 40.7795L75.6403 42.8642L76.3372 45.3722C76.7543 46.8739 76.1255 48.4701 74.7963 49.2837Z"
        fill="#EFFBF9"
      />
      <path
        d="M52.8627 42.6993L75.6406 42.8644L74.0091 36.9934C73.5918 35.492 72.2297 34.4489 70.6713 34.4376L47.8935 34.2725L49.525 40.1434C49.9423 41.6451 51.3042 42.688 52.8627 42.6993Z"
        fill="#EFFBF9"
      />
      <path
        d="M59.3979 66.2409L82.1758 66.406L80.5443 60.5351C80.127 59.0336 78.7648 57.9905 77.2065 57.9792L54.4286 57.8141L56.0602 63.685C56.4774 65.1865 57.8396 66.2296 59.3979 66.2409Z"
        fill="#EFFBF9"
      />
      <path
        d="M18.9478 98.7008L24.1829 98.3463L26.457 101.556L25.9128 104.101C25.587 105.625 24.2903 106.749 22.7354 106.854L14.4965 107.412L15.7706 101.453C16.0964 99.9295 17.3931 98.8061 18.9478 98.7008Z"
        fill="#EFFBF9"
      />
      <path
        d="M23.5758 36.1551L18.5168 36.4977L16.0668 33.2998L16.6112 30.7545C16.937 29.2306 18.2337 28.1071 19.7887 28.0018L28.0273 27.4438L26.7531 33.4024C26.4275 34.9263 25.1308 36.0498 23.5758 36.1551Z"
        fill="#EFFBF9"
      />
      <path
        d="M32.9463 59.067L14.586 60.3106L10.9497 57.1932L11.4941 54.6476C11.8199 53.1237 13.1166 52.0002 14.6716 51.8949L32.7684 50.6691L36.668 53.769L36.1236 56.3144C35.7978 57.8385 34.5011 58.9617 32.9463 59.067Z"
        fill="#EFFBF9"
      />
      <path
        d="M16.5665 43.07L36.6759 53.7693L37.9499 47.8106C38.2757 46.2867 37.552 44.7313 36.1762 43.9992L16.0668 33.2999L14.7928 39.2586C14.4667 40.7825 15.1907 42.3379 16.5665 43.07Z"
        fill="#EFFBF9"
      />
      <path
        d="M27.8484 82.9606L9.65572 84.1929L5.85182 81.087L6.39623 78.5414C6.72203 77.0175 8.01874 75.8941 9.57369 75.7888L27.9042 74.5472L31.5703 77.6628L31.0259 80.2082C30.7001 81.7321 29.4034 82.8553 27.8484 82.9606Z"
        fill="#EFFBF9"
      />
      <path
        d="M11.4572 66.9635L31.5665 77.6628L32.8406 71.7041C33.1664 70.1802 32.4426 68.6248 31.0668 67.8927L10.9574 57.1934L9.68338 63.1521C9.35737 64.676 10.0814 66.2314 11.4572 66.9635Z"
        fill="#EFFBF9"
      />
      <path
        d="M6.34387 90.8571L26.4532 101.556L27.7273 95.5977C28.0531 94.0738 27.3293 92.5184 25.9535 91.7862L5.84416 81.087L4.57011 87.0456C4.24409 88.5695 4.96786 90.1249 6.34387 90.8571Z"
        fill="#EFFBF9"
      />
      <path
        d="M2.74256 18.7174C3.70654 18.7174 4.4878 17.9359 4.4878 16.9722C4.4878 16.0084 5.26843 15.2269 6.23304 15.2269C7.19765 15.2269 7.97827 16.0084 7.97827 16.9722C7.97827 17.9361 8.75974 18.7174 9.72351 18.7174C10.6881 18.7174 11.4688 19.4989 11.4688 20.4626C11.4688 21.4264 10.6881 22.2079 9.72351 22.2079C8.75953 22.2079 7.97827 22.9893 7.97827 23.9531C7.97827 24.9169 7.19765 25.6984 6.23304 25.6984C5.26843 25.6984 4.4878 24.9169 4.4878 23.9531C4.4878 22.9891 3.70633 22.2079 2.74256 22.2079C1.77795 22.2079 0.997325 21.4264 0.997325 20.4626C0.997325 19.4989 1.77795 18.7174 2.74256 18.7174Z"
        fill="#EFFBF9"
      />
      <path
        d="M42.8905 78.0565C45.7823 78.0565 48.1262 75.7123 48.1262 72.8207C48.1262 71.8568 48.9075 71.0755 49.8715 71.0755C50.8354 71.0755 51.6167 71.8568 51.6167 72.8207C51.6167 75.7125 53.9609 78.0565 56.8524 78.0565C57.8164 78.0565 58.5977 78.8377 58.5977 79.8017C58.5977 80.7655 57.8164 81.5469 56.8524 81.5469C53.9607 81.5469 51.6167 83.8911 51.6167 86.7826C51.6167 87.7464 50.8354 88.5279 49.8715 88.5279C48.9075 88.5279 48.1262 87.7464 48.1262 86.7826C48.1262 83.8909 45.782 81.5469 42.8905 81.5469C41.9265 81.5469 41.1453 80.7655 41.1453 79.8017C41.1453 78.8379 41.9265 78.0565 42.8905 78.0565Z"
        fill="#EFFBF9"
      />
      <path
        d="M16.7147 8.24578C19.6065 8.24578 21.9505 5.9016 21.9505 3.01007C21.9505 2.04609 22.7317 1.26483 23.6957 1.26483C24.6597 1.26483 25.4409 2.04609 25.4409 3.01007C25.4409 5.9018 27.7851 8.24578 30.6766 8.24578C31.6406 8.24578 32.4219 9.02704 32.4219 9.99102C32.4219 10.9548 31.6406 11.7363 30.6766 11.7363C27.7849 11.7363 25.4409 14.0804 25.4409 16.972C25.4409 17.9357 24.6597 18.7172 23.6957 18.7172C22.7317 18.7172 21.9505 17.9357 21.9505 16.972C21.9505 14.0802 19.6063 11.7363 16.7147 11.7363C15.7508 11.7363 14.9695 10.9548 14.9695 9.99102C14.9695 9.02725 15.7508 8.24578 16.7147 8.24578Z"
        fill="#EFFBF9"
      />
    </svg>
  );
};
