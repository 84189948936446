/* eslint-disable no-unused-vars */
import React from "react";
import { Transition } from "@headlessui/react";
import MultipleDatePicker from "../element/MultipleDatePicker";
import InputGroupWithPrefix from "../element/InputGroupWithPrefix";

const IncreaseOrDecreaseButton = ({ title, onClick }) => {
  return <button type="button" onClick={onClick} className="w-8 h-8 focus:outline-none text-temp-gray text-sm">
    {title}
  </button>
}

const QuantityInput = ({
  handleChangeIncreaseOrDecrease,
  selectedQuantity,
  item,
  quantityAvailabilityError
}) => {
  return (
    <div className="pt-4 pb-5">
      <div className="flex flex-row flex-wrap justify-between items-center">
        <p className="font-semibold text-xs lg:text-sm" style={{ color: "#363838" }}>Select Quantity</p>

        <div className="flex flex-row items-center">
          <IncreaseOrDecreaseButton title="-" onClick={() => handleChangeIncreaseOrDecrease("decrement")} />
          <input
            name="selectedQuantity"
            value={selectedQuantity}
            type="tel"
            maxLength={item?.totalQuantity?.length}
            onChange={(e) => handleChangeIncreaseOrDecrease("default", e.target.value)}
            className="h-12 w-16 lg:w-20 text-center focus:outline-none text-sm lg:text-base text-temp-gray border border-temp-gray-light rounded-lg"
          />
          <IncreaseOrDecreaseButton title="+" onClick={() => handleChangeIncreaseOrDecrease("increment")} />
        </div>
      </div>

      {
        quantityAvailabilityError && <p className="text-red-500 text-xs pt-4">{quantityAvailabilityError}</p>
      }

      <p className="text-sm pt-4 text-temp-orange">Total Quantity: {item?.totalQuantity}</p>

    </div>
  )
}

const CheckItemAvailabilityModal = ({
  days,
  show,
  handleCloseModal,
  onChange,
  handleCheckItemAvailability,
  showCancelButton = false,
  title,
  labelName,
  checkAvailabilityError,
  itemReturnDate,
  item,
  selectedQuantity,
  handleChangeIncreaseOrDecrease,
  quantityAvailabilityError,
  daysDifferenceError,
  startDate,
  handleChangeStartDate,
  unitOrdered,
  setUnitOrdered,
  error
}) => {
  const rentalUnitType = item?.rentalUnitType

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="modal-min-height absolute md:relative bottom-0 left-0 align-bottom  lg:inline-block lg:align-center bg-white rounded-t-3xl lg:rounded-lg text-left overflow-auto shadow-xl transform transition-all h-5/6 lg:h-full sm:my-8 sm:align-middle max-w-full sm:max-w-lg sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 lg:px-10 pt-2 lg:pt-8 pb-10">
            <div className="">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="">
                {!showCancelButton && (
                  <div className="text-right">
                    <button
                      onClick={handleCloseModal}
                      className="mt-3 px-3 py-1 rounded-full focus:outline-none bg-white shadow-lg text-temp-green-500"
                    >
                      x
                    </button>
                  </div>
                )}
                <h3
                  className={`text-left leading-6 text-lg lg:text-xl font-semibold text-temp-green-500 ${!showCancelButton && ""
                    }`}
                  id="modal-headline"
                >
                  {title}
                </h3>

                <div className={`mt-4 ${showCancelButton && "mt-8"}`}>
                  <form onSubmit={handleCheckItemAvailability}>
                    {labelName && (
                      <p className="text-base lg:text-lg pb-4 text-temp-green-500">
                        {labelName}
                      </p>
                    )}

                    <QuantityInput
                      item={item}
                      selectedQuantity={selectedQuantity}
                      handleChangeIncreaseOrDecrease={handleChangeIncreaseOrDecrease}
                      quantityAvailabilityError={quantityAvailabilityError}
                    />

                    <div className="group w-full">
                      {
                        rentalUnitType === "bySiUnits" ?
                          <>
                            <InputGroupWithPrefix
                              label={<>
                                Set Estimated {item?.rentalUnitType === "bySiUnits" ?
                                  item?.siUnitType || "Hour" :
                                  item?.calenderType
                                } needed<span className="text-red-400">*</span>
                              </>}
                              error={error}
                              prefixValue={item?.rentalUnitType === "bySiUnits" ?
                                item?.siUnitType || "Hour" :
                                item?.calenderType
                              }
                              name={"unitOrdered"}
                              value={unitOrdered}
                              onChange={(e) => setUnitOrdered(e.target.value)}
                            />

                            <MultipleDatePicker
                              minDate={itemReturnDate}
                              datePickerType="calendar"
                              name="startDate"
                              placeholder="Set Start Date"
                              onChange={handleChangeStartDate}
                              showTag={false}
                              todayColor={"#ccccc"}
                            />
                          </>
                          : <MultipleDatePicker
                            minDate={itemReturnDate}
                            datePickerType="calendar"
                            name="days"
                            placeholder="Set Start & End Date"
                            onChange={onChange}
                            range
                            showTag={false}
                            todayColor={"#ccccc"}
                          />
                      }

                      {
                        daysDifferenceError && <p className="text-red-500 text-xs -mt-4 pb-6">{daysDifferenceError}</p>
                      }
                    </div>

                    <div className="flex items-baseline justify-end flex-end">
                      {showCancelButton && (
                        <button
                          onClick={handleCloseModal}
                          className="text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        disabled={(
                          rentalUnitType === "bySiUnits" && !unitOrdered ||
                          rentalUnitType === "bySiUnits" && !startDate ||
                          (rentalUnitType !== "bySiUnits" && days?.length < 2) ||
                          quantityAvailabilityError ||
                          daysDifferenceError) && true}
                        type="submit"
                        className={`${!showCancelButton && "w-full"
                          }  mb-4 py-3 px-8 text-xs lg:text-sm focus:outline-none bg-gradient font-bold text-white rounded-lg ${(
                            rentalUnitType === "bySiUnits" && !unitOrdered ||
                            rentalUnitType === "bySiUnits" && !startDate ||
                            (rentalUnitType !== "bySiUnits" && days?.length < 2) ||
                            quantityAvailabilityError ||
                            daysDifferenceError) && "opacity-50"
                          }`}
                      >
                        Check
                      </button>
                    </div>

                    <p className="text-temp-gray text-xs lg:text-sm pt-4">Minimum rental period is <strong>{item?.minimumBookingDays} day</strong></p>

                    {checkAvailabilityError && (
                      <p className="text-red-600 text-sm pt-6">
                        {checkAvailabilityError}
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckItemAvailabilityModal;
