import toast from "react-hot-toast";
import React from "react"

export const toastPopUp = (title, message, others) => {
  return toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{message}</p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        {others?.url ? (
          <a
            href={others?.url || "/"}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-temp-primary   focus:outline-none"
          >
            {others?.cta || "Click to view"}
          </a>
        ) : (
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-temp-primary   focus:outline-none"
          >
            Close
          </button>
        )}
      </div>
    </div>
  ));
};

// ------------------------------------------------------------------------------------- //
// -------------------------------ARGUMENT INFORMATION --------------------------------- //
//  -------------------------------- title ===> string --------------------------------- //
//  ------------------------------- message ===> string -------------------------------- //
//  -------- others ===> object => {cta: string, url: string, user: object} ----------- //
// ------------------------------------------------------------------------------------ //
