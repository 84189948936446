import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ItemPreloader from "../preloader/ItemPreloader";
import ItemCard from "../ItemCard";
import { numberFormat } from "../../../utils/numberFormat";
import { generateItemUrl } from "../../../utils/generateItemUrl";

const ItemListingInCarousel = ({
  sectionTitle,
  items,
  itemsLoading,
  ...props
}) => {

  return (
    <div className="container mx-auto px-4">
      {(items?.length > 0 || itemsLoading) && (
        <div className=" pt-10 lg:pt-10">
          <h3 className="text-xl md:text-2xl xl:text-3xl text-temp-primary font-semibold leading-7  pb-1 lg:pb-6">
            {sectionTitle}
          </h3>
          <div className="mt-2">
            {itemsLoading ? (
              <div className="mt-8">
                <ItemPreloader />
              </div>
            ) : items?.length > 0 ? (
              <>
                <OwlCarousel
                  className="owl-carousel owl-theme"
                  items={4}
                  loop={true}
                  autoWidth={true}
                  nav={false}
                  dots={false}
                  margin={35}
                  stageClass="pt-5"
                  responsiveClass={true}
                  responsive={{
                    0: {
                      items: 2,
                      nav: false,
                    },
                    600: {
                      items: 3,
                      nav: false,
                    },
                    1000: {
                      items: 4,
                      nav: false,
                      loop: false,
                    },
                  }}
                >
                  {items?.slice(0, 6)?.map((item) => (
                    <div
                      key={item._id}
                      className="item relative mb-16 w-60 md:w-72"
                    >
                      <ItemCard
                        image={item.images[0]?.image && item.images[0]?.image.url}
                        title={item?.name}
                        url={generateItemUrl("items", item)}
                        amount={numberFormat(item.price?.amount)}
                        userName={item.userId?.firstname}
                        userImage={item.userId?.image}
                        rating={item?.rating}
                        reviewCount={item?.reviewCount}
                        imageHeight="h-36"
                        pickupLocation={item?.pickupLocation}
                        availableFrom={item?.availableFrom}
                        createdAt={item?.createdAt}
                        item={item}
                      />
                    </div>
                  ))}
                </OwlCarousel>

                {props.children}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemListingInCarousel;
