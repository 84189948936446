import React from 'react';

const TipCard = ({
    text
}) => {
    return <div className="upload-item-tip py-4 px-4 mt-8 lg:mt-12 mb-4 rounded-lg">
        <p className="text-temp-primary text-xs md:text-sm leading-6">
            {text}
        </p>
    </div>
};

export default TipCard;
