import React from 'react';
import { Transition } from "@headlessui/react";
import { MoreIcon } from '../../../components/svgs';

const ButtonWithOptions = ({
    handleToggleShow,
    toggleShow,
    handleShowEditModal,
}) => {
    return (
        <button className="focus:outline-none" onClick={handleToggleShow}>
            <MoreIcon />

            <Transition
                show={toggleShow}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="absolute w-32 top-6 right-2 bg-temp-white  custom-select-list rounded-xl">
                    <ul className="space-y-3 py-5 px-5 text-xs text-temp-primary cursor-pointer text-left">
                        <li>
                            <button
                                className="focus:outline-none"
                                onClick={handleShowEditModal}
                            >
                                Edit Card
                            </button>
                        </li>
                    </ul>
                </div>
            </Transition>
        </button>
    );
};


export default ButtonWithOptions;
