import axios from "../../axios/axios";

export const createNewsletterApi = async (payload) => {
  const url = "/newsletters";
  try {
    const response = await axios.post(url, payload);
    const responseData = response?.data?.message;
    if (responseData) {
      return {
        type: "success",
        message: response?.data?.message,
      };
    }
  } catch (error) {
    return {
      type: "error",
      message: error?.message,
    };
  }
};
