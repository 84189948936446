import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const getTransactionsApi = (params) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/transactions"
  try {
    const response = await axios.get(url, {
      params
    });
    const responseData = response.data.data.docs;
    console.log(response);

    dispatch({
      type: Actions.GET_TRANSACTIONS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};
