import Actions from "../actions/actionTypes";

const initialState = {
  addBankDetailsSuccess: false,
  bankDetails: {},
  bankCards: [],
};

const BankReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SUCCESSFULLY_ADD_BANK_DETAILS:
      return {
        ...state,
        addBankDetailsSuccess: action.payload,
      };

    case Actions.GET_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      };

    case Actions.GET_BANK_CARD:
      return {
        ...state,
        bankCards: action.payload,
      };

    default:
      return state;
  }
};

export default BankReducer;
