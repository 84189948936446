/* eslint-disable no-unused-vars */
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "store";
import MailAuthImage from "../../../assets/images/svg/mail-auth.svg";
import InputGroup from "../../../components/element/InputGroup";
import OnboardingContainer from "../../../components/layout/OnboardingContainer";
import AlertModal from "../../../components/modals/AlertModal";
import AlertModal2 from "../../../components/modals/AlertModal2";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";

const EmailForm = ({ handleSubmit, email, setEmail, error }) => {
  const navigate = useNavigate();
  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full">
        <InputGroup
          type="email"
          label="Email"
          placeholder="Email Address"
          name="email"
          value={email}
          onChange={(e) => setEmail(e?.target.value)}
          error={error}
        />
      </div>

      <div className="text-center">
        <button
          type="submit"
          disabled={email.length === 0}
          className={`w-full h-12 lg:h-14 rounded-lg text-sm lg:text-base py-4 px-8 text-white font-bold focus:outline-none 
                ${
                  email.length === 0
                    ? "bg-temp-green-500 opacity-60"
                    : "bg-temp-green-500"
                }
                }`}>
          Verify Email
        </button>
      </div>
    </form>
  );
};

const Input = ({
  index,
  name,
  value,
  onChange,
  onKeyUp,
  error,
  onFocus,
  className,
}) => {
  return (
    <input
      type="text"
      className={`code-input${index} border flex-shrink ${
        className || "w-20 h-16 mr-8"
      } mb-6 pt-1 text-center font-bold text-xl lg:text-3xl text-temp-secondary rounded-xl focus:outline-none focus:border focus:border-temp-green-500 ${
        error ? "border-red-600" : "border-gray-300 "
      }`}
      maxLength="1"
      name={name}
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
    />
  );
};

const VerificationCodeInput = ({
  handleInputChange,
  handleOnKeyUp,
  code,
  handleEmailVerification,
  email,
}) => {
  return (
    <>
      <div className="flex">
        <div className="mt-8 rounded-2xl mx-auto text-center flex flex-row justify-start otp-phone-number py-4 px-8">
          <p className="text-sm-15 lg:text-base">
            Code was sent to{" "}
            <span className="text-temp-green-500 font-semibold">
              {email.slice(0, 7)} **** {email.slice(-6)}
            </span>
          </p>
        </div>
      </div>

      <div className="mt-12 mb-4 lg:mb-6 flex flex-wrap justify-center">
        {code.map((a, key) => (
          <Input
            autoComplate="off"
            key={key}
            index={key}
            name="code"
            value={a.value}
            onChange={(e) => handleInputChange(e, key)}
            onKeyUp={(e) => handleOnKeyUp(e, key)}
            className="w-12 h-12 mr-4 lg:mr-6 lg:w-16 lg:h-16"
          />
        ))}
      </div>

      <div className="text-temp-gray text-sm lg:text-base">
        <p>
          Didn’t get the email?{" "}
          <button
            className="text-temp-green-500 text-gray-500 font-semibold focus:outline-none"
            onClick={handleEmailVerification}>
            {`Resend Code`}
          </button>
        </p>
      </div>
    </>
  );
};

const VerifyEmail = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const userData = store.get("temp_usER12_dATa");
    if (!userData) {
      navigate("/");
    }
    if (userData?.verified_email) {
      navigate("/");
    }
  }, []);

  const [sentSuccess, setSentSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Enter a valid email");
    } else {
      await handleEmailVerification();
    }
  };

  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState([
    {
      id: 1,
      value: "",
    },
    {
      id: 2,
      value: "",
    },
    {
      id: 3,
      value: "",
    },
    {
      id: 4,
      value: "",
    },
  ]);

  const handleEmailVerification = async () => {
    setLoading(true);
    const resp = await props.sendOTPVerifyEmailApi({
      email,
    });

    if (resp?.success) {
      setSentSuccess(true);
    } else {
      setSentSuccess(false);
    }
    setLoading(false);
  };

  const processSubmitEmailVerificationCode = async () => {
    setLoading(true);

    let data = "";

    code.map((a) => (data += a.value));

    const payload = {
      code: data,
    };
    await props.completeVerifyEmailApi(payload, navigate);
    setLoading(false);
  };

  useEffect(() => {
    const find = code.every((a) => a.value !== "");

    if (find) {
      processSubmitEmailVerificationCode();
    }
  }, [code]);

  const handleInputChange = (e, index) => {
    let d = code[index];
    d["value"] = e.target.value;
    code[index] = d;
    setCode([...code]);

    if (e.target.value) {
      $(`.code-input${index + 1}`).focus();
    }
  };

  const handleOnKeyUp = (e, key) => {
    const d = code[key];

    var keyId = e.keyCode;
    switch (keyId) {
      // enter
      case 13:
        $(`.code-input${key + 1}`).focus();
        break;

      // Backspace
      case 8:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;

      // Delete
      case 46:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <OnboardingContainer pageTitle="Verification">
        <div className="flex justify-center mb-10">
          <div className="w-full lg:w-7/12 pt-8 lg:pt-4 mx-auto px-0 lg:px-10 text-center">
            <div className="pt-4 pb-6">
              <img src={MailAuthImage} className="mx-auto" alt="" />
            </div>
            <h2 className="text-temp-green-500 font-semibold text-xl lg:text-2xl xl:text-3xl">
              Verify Email
            </h2>

            <p className="text-sm lg:text-base pt-4 w-10/12 lg:w-8/12 lg:px-4 mx-auto">
              Kindly enter your email in the form below and a verification code
              will be sent to you
            </p>

            <div className="mt-20 mb-4 lg:mb-6 w-full lg:w-10/12 mx-auto">
              {sentSuccess ? (
                <VerificationCodeInput
                  email={email}
                  code={code}
                  handleEmailVerification={handleEmailVerification}
                  handleInputChange={handleInputChange}
                  handleOnKeyUp={handleOnKeyUp}
                />
              ) : (
                <EmailForm
                  email={email}
                  setEmail={setEmail}
                  error={error}
                  handleSubmit={handleSubmit}
                />
              )}
            </div>
          </div>

          {/* Trigger modal after form submission */}
          {loading && <LoadingModal show={loading} />}

          {/* Sent email OTP message */}
          {props.resendEmailStatus?.type && (
            <AlertModal2
              show={props.resendEmailStatus.type ? true : false}
              type={props.resendEmailStatus.type}
              content={props.resendEmailStatus.message}
            />
          )}

          {/* Complete Verification Status */}
          {props.verifyEmail?.type && (
            <AlertModal2
              show={props.verifyEmail.type ? true : false}
              type={props.verifyEmail.type}
              content={props.verifyEmail.message}
            />
          )}

          {/* Submission Trigger Message */}
          {props.alert.message && (
            <AlertModal
              show={props.alert.type ? true : false}
              type={props.alert.type}
              content={props.alert.message}
            />
          )}
        </div>
      </OnboardingContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    auth: state.auth,
    resendEmailStatus: state.auth.resendEmailStatus,
    verifyEmail: state.auth.verifyEmail,
  };
};
export default connect(mapStateToProps, actions)(VerifyEmail);
