import React from "react";
import { Link, } from "react-router-dom";

const TermsAndCondtions = () => {
  return (
    <div className="mb-16">
      <p className="leading-7 text-base">
        Welcome to Tempown.com, a platform that provides an online marketplace
        that enables Users to upload, offer, search for, and book items (“the
        Service”).
      </p>
      <br />
      <p>
        TempOwn™ is a product of Reeddi Technologies Limited (“Reeddi
        Technologies”), a subsidiary of Reeddi Inc, which can be assessed
        through the Website (
        <Link to="/" className="text-temp-primary">
          www.tempown.com
        </Link>
        ), mobile, tablet, or other applications made available by Reeddi
        Technologies.
      </p>
      <br />
      <p>
        In this Terms of Use, reference to “User”, “You” or “Your” shall mean a
        User of this Service. The Terms of Use, along with our Privacy Policy
        available here ({" "}
        <Link to="/policy/privacy">https://www.tempown.com/policy</Link>), and
        any other agreement that you have executed with us form a legally
        binding contract between you and Reeddi Technologies regarding your use
        of this Service.
      </p>
      <br />
      <p>
        By visiting our Site and/or performing a transaction on the Site, you
        engage in our &apos; Services&apos; and agree to be bound by the following terms.
        These Terms apply to all users of the Site, including without limitation
        users who are owners, TempOwners, borrowers, customers, and/or
        contributors of content.
      </p>
      <br />
      <p>
        Any new features or tools which are added to the current platform shall
        be subject to these Terms. You can review the most current version of
        the Terms at any time on this page. We reserve the right to update,
        change or replace any part of these Terms. It is your responsibility to
        check this page periodically for changes. Your continued use of or
        access to the Site following the posting of any changes constitutes
        acceptance of these changes.
      </p>
      <br />
      <ol className="mt-8 space-y-8">
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">1.</span>Nature and Applicability of Terms
          </p>
          <br />
          <p>
            This Terms of Use (as amended from time to time by Reeddi
            Technologies in its sole discretion) governs the use of this Service
            and upon your acceptance, constitutes a binding agreement between
            you and Reeddi Technologies. Please read the Terms of Use carefully
            before accessing or using this Service.
          </p>
          <br />
          <p>
            Each time you access or use this Service, you, and if you are acting
            on behalf of a third party, such as an employer, such third party,
            agree to be bound by this Terms of Use and our Privacy Policy
            whether or not you register with us. If you do not agree to be bound
            by the Terms of Use and our Privacy Policy, you may not access or
            use our Website or the Service.
          </p>
        </li>

        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">2.</span>Eligibility for our Service
          </p>
          <br />
          <p>
            By agreeing to these Terms, you represent and warrant that you or
            your authorized representative have attained the age of majority
            where you reside (18 years of age in most jurisdictions) are
            otherwise capable of entering into binding contracts including these
            Terms of Use.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">3.</span>General Terms
          </p>
          <br />
          <p>You acknowledge and agree that:</p>
          <ol className="mt-2 space-y-3 list-inside">
            <li>
              <span className="mr-2">a.</span>You may not use our Services or
              Sites for any illegal or unauthorized purpose.
            </li>
            <li>
              <span className="mr-2">b.</span>You must not transmit any worms or
              viruses or any code of a destructive nature.
            </li>
            <li>
              <span className="mr-2">c.</span>A breach or violation of any of
              the Terms will result in an immediate termination of your account
              and right to use our Service.
            </li>
            <li>
              <span className="mr-2">d.</span>We have the right, but not the
              obligation, to take any of the following actions in our sole
              discretion at any time and for any reason without giving you any
              prior notice:
            </li>
            <ul className="space-y-4 list-inside list-disc">
              <li>
                Restrict, suspend or terminate your access to all or any part of
                our Site;
              </li>
              <li>
                Change, suspend or discontinue all or any part of our services
                or Site;
              </li>
              <li>
                Refine, move, or remove any content that is available on all or
                any part of our Site;
              </li>
              <li>Deactivate or delete your accounts;</li>
              <li>
                Establish general practices and limits concerning use of our
                Site
              </li>
            </ul>
            <li>
              <span className="mr-2">e.</span>You agree that we will not be
              liable to you or any third party for taking any of these actions.
            </li>
            <li>
              <span className="mr-2">f.</span>You understand and agree that our
              Site may include communications such as service announcements and
              administrative or legal notices from us. Please note that you
              cannot opt-out of receiving these notices.
            </li>
            <li>
              <span className="mr-2">g.</span>You understand that your content
              (not including debit card information), may be unencrypted and
              involve (a) transmissions over various networks; and (b) changes
              to conform and adapt to technical requirements of connecting
              networks or devices. Credit card information is always encrypted
              during transfer over networks.
            </li>
            <li>
              <span className="mr-2">h.</span>You agree not to reproduce,
              duplicate, copy, sell, resell or exploit any portion of the Site,
              use of the Site, or access to the Site or any contact on the Site
              without express written permission by us.
            </li>
            <ul className="space-y-4 list-inside">
              <li>
                <span className="mr-2">i.</span>You may not modify, publish,
                transmit, reverse engineer, participate in the transfer or sale,
                create derivative works, or in any way exploit any of the
                content, in whole or in part, found on the Site. TempOwn.com
                content is not for resale. Use of the Site does not entitle
                users to make any unauthorized use of any protected content, and
                in particular, you will not delete or alter any proprietary
                rights or attribution notices in any content. You will use
                protected content solely for your personal use, and will make no
                other use of the content without the express written permission
                of Reeddi Technologies and the copyright owner. You agree that
                you do not acquire any ownership rights in any protected
                content. We do not grant you any licenses, express or implied,
                to the intellectual property of TempOwn or our licensors except
                as expressly authorized by these terms.
              </li>
            </ul>
          </ol>
          <br />
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">4.</span>Creating An Account
          </p>
          <br />
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>Once you create an account with
              us, you are registered on TempOwn.com. The terms &apos;member&apos;,
              &apos;membership&apos;, &apos;Tempowner&apos;, &apos;Owner&apos;, &apos;accounts&apos;, all refer to this
              registration as a member on the Site. If you are merely surfing or
              browsing through the Site and have not yet created an account,
              your use of the site is still subject to this Agreement. If you do
              not agree to this Agreement, do not use the Site.
            </li>
            <li>
              <span className="mr-2">b.</span>When you register for an account,
              you will be required to provide us with some information about
              yourself (such as your name, date of birth, e-mail address,
              physical address, phone number, debit card information or other
              personal information). Some of this information may be of a
              confidential nature and may include personally identifiable
              information or “PII” (all “Your Information”).
            </li>
            <li>
              <span className="mr-2">c.</span>If you provide Your Information to
              us then you agree to provide true, current, complete and accurate
              information, and not to misrepresent your identity. You also agree
              to keep Your Information current and to update Your Information if
              any of Your Information changes.
            </li>
            <li>
              <span className="mr-2">d.</span> Our collection, use and
              disclosure of Your Information is governed by this Terms of Use,
              our Privacy Policy and any applicable law.
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">5.</span>Conduct
          </p>
          <br />
          <p>
            As a user of this Site, you herein acknowledge, understand and agree
            that all information, text, software, data, photographs, messages,
            tags or any other content, whether it is publicly or privately
            posted and/or transmitted, is the expressed sole responsibility of
            the individual from whom the content originated. As such, we do not
            guarantee the accuracy, integrity or quality of such content. It is
            expressly understood that by use of our services, you may be exposed
            to content including, but not limited to, any kind incurred as a
            result of the use of any content provided, emailed, transmitted or
            otherwise made available by TempOwn.
          </p>
          <br />
          <p>
            Furthermore, you herein agree not to make use of TempOwn for the
            purpose of:
          </p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>uploading, posting, emailing,
              transmitting, or otherwise making available any content that shall
              be deemed unlawful, harmful, threatening, abusing, harassing,
              tortious, defamatory, vulgar, obscene, libelous, or invasive of
              another’s privacy or which is hateful, and/or racially, ethnically
              or otherwise objectionable;
            </li>
            <li>
              <span className="mr-2">b.</span>Causing harm to minors in any
              manner whatsoever;
            </li>
            <li>
              <span className="mr-2">c.</span>Impersonating any individual or
              entity, or falsely stating or otherwise misrepresenting any
              affiliation with an individual or entity;
            </li>
            <li>
              <span className="mr-2">d.</span>uploading, posting, emailing,
              transmitting or otherwise offering any content that you personally
              have no right to or that may infringe any patent, copyright,
              trademark, or any other proprietary or intellectual rights of any
              other party;
            </li>
            <li>
              <span className="mr-2">e.</span>uploading, posting, emailing,
              transmitting, or otherwise offering any unsolicited or
              unauthorized advertising, promotional flyers, junks mail, spam or
              any other form of solicitation;
            </li>
            <li>
              <span className="mr-2">f.</span>Disrupting the normal flow of
              communication, or otherwise acting in any manner that would
              negatively affect other users’ ability to participate in any real
              time interactions;
            </li>
            <li>
              <span className="mr-2">g.</span>Interfering with or disrupting any
              TempOwn services, servers and/or networks that may be connected or
              related to our website, including but not limited to the use of
              any device software and/or routine to bypass the robot exclusion
              headers;
            </li>
            <li>
              <span className="mr-2">h.</span>Collecting or storing of any
              personal data relating to any other member or user in connection
              with the prohibited conduct and/or activities which have been set
              forth in the aforementioned paragraphs.
            </li>
          </ol>

          <br />
          <p>
            TempOwn hereby reserves the right to access, preserve and/or
            disclose member account information and/or content if it is
            requested to do so by law or in good faith that any such action is
            deemed necessary for:
          </p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>Compliance with any legal process
            </li>
            <li>
              <span className="mr-2">b.</span>Enforcement of the Terms
            </li>
            <li>
              <span className="mr-2">c.</span>Responding to any claim that
              therein contained content is in violation of the rights of any
              third party
            </li>
            <li>
              <span className="mr-2">d.</span>Protecting the rights, property or
              the personal safety of TempOwn, its visitors, users and members
              including the general public
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">6.</span>Account Management
          </p>
          <br />
          <p>
            If you have been issued an account by TempOwn in connection with
            your use of the Site, you are responsible for safeguarding your
            password and any other credentials used to access that account, even
            if you authorize other parties in your organization to access your
            account. You, and not TempOwn, are responsible for any activity
            occurring on your account, including but not limited to incidents
            where your password has been compromised. If you become aware of any
            unauthorized access to your account, you should notify TempOwn
            immediately. You shall also conduct internal investigations. Where
            it is determined that such an account has been compromised at your
            instance, you shall be solely responsible for the direct losses
            incurred by TempOwn and others due to any unauthorized use of your
            account.
          </p>
          <br />
          <p>
            You acknowledge that TempOwn is not responsible for third party
            access to your account that results from theft or misappropriation
            of your account. Notify us immediately if you believe that someone
            has used your username, email, or password without your
            authorization.
          </p>
          <br />
          <p>
            Furthermore, the registering party hereby acknowledges, understands
            and agrees to:
          </p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>furnish factual, correct and
              complete information with regards to yourself as may be requested
              by the data registration process, and
            </li>
          </ol>
          <br />
          <p>
            if anyone knowingly provides any information of a false, untrue,
            inaccurate, incomplete nature, TempOwn will have sufficient grounds
            and rights to suspend or terminate the member in violation of this
            aspect of the Agreement, and as such refuse any and all current or
            future use of TempOwn services or any portion thereof.
          </p>
          <br />
          <p>
            TempOwn may send notices to the email address or text messages to
            your mobile phone registered with your account. You must keep your
            email address, mobile phone number and, where applicable, your
            contact details and payment details associated with your account
            current and accurate.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">7.</span>Information Accuracy
          </p>
          <br />
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>We attempt to ensure that
              information on this Service is complete, accurate and current.
              Despite our best efforts, the information on our Service may
              occasionally be inaccurate, incomplete or out of date. We make no
              representation, warranty, or guarantee regarding the reliability,
              completeness, accuracy, or currency of any information on the
              Service.
            </li>
            <li>
              <span className="mr-2">b.</span>Furthermore, information on the
              Service may contain typographical errors, inaccuracies, or
              omissions. We reserve the right to correct or make changes in such
              information at any time without notice. Provided that such changes
              shall not affect the user’s ability to access or use the Service.
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">8.</span>Interruption of Service
          </p>
          <br />
          <p>
            Unless otherwise stated in the applicable Service Level Agreement,
            your access and use of our Services may be interrupted for any of
            several reasons, including, without limitation, the malfunction of
            equipment, periodic updating, maintenance or repair of our Services
            or other actions that we may elect to take.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">9.</span>Submitted Content
          </p>
          <br />
          <p>
            TempOwn shall not lay claim to ownership of any content submitted by
            any visitor, member, or user, nor make such content available for
            inclusion on our website Services. Therefore, you hereby grant and
            allow for Reedi Technologies the below listed worldwide,
            royalty-free and non-exclusive licenses, as applicable:
          </p>
          <ol className="mt-2 list-inside space-y-3 ">
            <li>
              <span className="mr-2">a.</span>The content submitted or made
              available for inclusion on the publicly accessible areas of the
              Sites, the license provided to permit to use, distribute,
              reproduce, modify, adapt, publicly perform and/or publicly display
              said Content on our network Services is for the sole purpose of
              providing and promoting the specific area to which the content was
              placed and/or made available for viewing. This license shall be
              available so long as you are a member of TempOwn&apos;s sites, and
              shall terminate at such time when you elect to discontinue your
              membership.
            </li>
            <li>
              <span className="mr-2">b.</span>Photos, videos and/or graphics
              submitted or made available for inclusion on the publicly
              accessible areas of TempOwn&apos;s sites, the license provided to
              permit to use, distribute, reproduce, modify, adapt, publicly
              perform and/or publicly display said Content on our network
              Services are for the sole purpose of providing and promoting the
              specific area in which this content was placed and/or made
              available for viewing. This license shall be available so long as
              you are a member of TempOwn&apos;s sites and shall terminate at such
              time when you elect to discontinue your membership.
            </li>
            <li>
              <span className="mr-2">c.</span>For any other content submitted or
              made available for inclusion on the publicly accessible areas of
              TempOwn&apos;s sites, the continuous, binding and completely
              sub-licensable license which is meant to permit to use,
              distribute, reproduce, modify, adapt, publish, translate, publicly
              perform and/or publicly display said content, whether in whole or
              in part, and the incorporation of any such Content into other
              works in any arrangement or medium currently used or later
              developed.
            </li>
          </ol>
          <br />
          <p>
            These areas which may be deemed publicly accessible areas of
            TempOwn&apos;s Sites are those areas of our network properties which are
            meant to be available to the general public, and which would include
            message boards and groups that are openly available to both users
            and members. However, those areas which are not open to the public,
            and thus available to members only, would include our mail system
            and instant messaging.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">10.</span>Contributions to the Website
          </p>
          <br />
          <p>
            TempOwn may provide an area for users and members to contribute
            feedback to its website. When you submit ideas, suggestions, and/or
            proposals (Contributions) to our site, you acknowledge and agree
            that:
          </p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>Your contribution do not contain
              any type of confidential or proprietary information;
            </li>
            <li>
              <span className="mr-2">b.</span>TempOwn shall not be liable or
              under any obligations to ensure or maintain confidentiality,
              expressed or implied, related to any contributions
            </li>
            <li>
              <span className="mr-2">c.</span>The contributor&apos;s contribution
              shall automatically become the sole property of TempOwn, and
            </li>
            <li>
              <span className="mr-2">d.</span>TempOwn is under no obligation to
              either compensate or provide any form of reimbursement in any
              manner or nature.
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">11.</span>Commercial Reuse of Services
          </p>
          <br />
          <p>
            The member or user herein agrees not to replicate, duplicate, copy,
            trade, sell, resell nor exploit for any commercial reason, any part
            of TempOwn.com.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">12.</span>Indemnity
          </p>
          <br />
          <p>
            All users and/or members agree to hold TempOwn and its owner,
            subsidiaries, affiliates, agents, employees, officers, partners
            and/or licensors blameless or not liable for any claim or demand,
            which may include, but is not limited to, reasonable attorney fees
            made by any third party which may arise from any content a member or
            user of our Site may submit, post, modify, transmit or otherwise
            make available through our Services, the use of services on
            TempOwn.com or your connection with these services. Your violations
            of the Terms of Service and/or your violation of any such rights of
            another person.
          </p>
          <br />
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">13.</span>Termination
          </p>
          <br />
          <p>
            As a member on the TempOwn.com, you may cancel or terminate your
            account, associated email address and/or access to our services by
            submitting a cancellation or termination request to{" "}
            <strong>
              <a href="mailto: hello@tempown.com">hello@tempown.com</a>{" "}
            </strong>{" "}
            As a member, you agree that TempOwn may, without any prior written
            notice, immediately suspend, terminate, discontinue and/or limit
            your account, any email associated with your account, and access to
            any of our Services. The cause for such termination, discontinuance,
            suspension and/or limitation of access shall include, but is not
            limited to:
          </p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>any breach or violation of our
              Terms or any other incorporated agreement, regulation and/or
              guidelines
            </li>
            <li>
              <span className="mr-2">b.</span>By way of requests from law
              enforcements or any other governmental agencies;
            </li>
            <li>
              <span className="mr-2">c.</span>The discontinuance, alteration,
              and/or material modification to our services, or any part thereof;
            </li>
            <li>
              <span className="mr-2">d.</span>Unexpected technical or security
              issues and/or problems;
            </li>
            <li>
              <span className="mr-2">e.</span>Any extended periods of
              inactivity;
            </li>
            <li>
              <span className="mr-2">f.</span>Any engagement by you in
              fraudulent or illegal activities, and/or;
            </li>
            <li>
              <span className="mr-2">g.</span>The nonpayment of any associated
              fees that may be owed by you in association with your Tempown
              account services.
            </li>
          </ol>
          <br />
          <p>
            Furthermore, you herein agree that all terminations, suspension,
            discontinuance and/or limitations of access, shall be made at our
            sole discretion and we shall not be liable to you or any third party
            with regards to the termination of your account, associated email
            address, and/or access to any of our services.
          </p>
          <p>
            The termination of your account with TempOwn.com, shall include any
            and/or all of the following:
          </p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>The removal of any access to all
              or part of the services offered within TempOwn.com
            </li>
            <li>
              <span className="mr-2">b.</span>The deletion of your password and
              any and all related information, files, and such content that may
              be associated with or inside your account, or any part thereof,
              and
            </li>
            <li>
              <span className="mr-2">c.</span>The barring of any further use of
              any or part of our services
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">14.</span>Third Party Links, Services and
            Content
          </p>
          <br />
          <p>
            The Service may contain features, services and functionalities
            linking you to, or providing you with access to third party services
            and content, websites, directories, servers, networks, systems,
            information, databases, applications, software, programs, courses,
            services, and the Internet as a whole. Because we have no control
            over such sites and resources, we are not responsible for the
            availability of such external sites or resources, and do not endorse
            and are not responsible or liable for any content, advertising,
            courses or other materials on or available from such sites or
            resources. When you visit or use a third party’s website you agree
            to read and consent to the third party’s Terms of Use and Privacy
            Policy and you release us from any liability.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">15.</span>Software
          </p>
          <br />
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>Any license is for the sole
              purpose of enabling you to use and enjoy the benefit of the
              Services as provided by Reeddi Technologies, in the manner
              permitted by these terms. You may not copy, modify, distribute,
              sell, or lease any part of our Services or software, nor may you
              reverse engineer or attempt to extract the source code of that
              software.
            </li>
            <li>
              <span className="mr-2">b.</span>The software is subject to
              applicable export laws and regulations. You must comply with all
              domestic and international export laws and regulations that apply
              to the software.
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">16.</span> Intellectual Property Rights
          </p>
          <br />
          <p>
            You acknowledge, understand and agree that all of the TempOwn
            trademarks, copyright, trade name, service marks, and other TempOwn
            logos and any brand features, and/or product and service names are
            trademarks and as such, are and shall remain the property of Reeddi
            Technologies. You herein agree not to display and/or use in any
            manner the TempOwn&apos;s logo or marks without obtaining Reeddi
            Technologies&apos; prior written consent.
          </p>
          <br />
          <p>
            TempOwn will always respect the intellectual property of others, and
            we ask that all of our users do the same. With regards to
            appropriate circumstances and at its sole discretion, TempOwn may
            disable and/or terminate the accounts of any user who violates our
            Terms and/or infringes the rights of others. If you feel that your
            work has been duplicated in such a way that would constitute
            copyright infringement, or if you believe your intellectual property
            rights have been otherwise violated, you should provide to us the
            following information:
          </p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>The electronic or physical
              signature of the individual that is authorized on behalf of the
              owner of the copyright or other intellectual property interest;
            </li>
            <li>
              <span className="mr-2">b.</span>A description of the copyrighted
              work or other intellectual property that you believe has been
              infringed upon;
            </li>
            <li>
              <span className="mr-2">c.</span>A description of the location of
              the site which you allege has been infringing upon your work;
            </li>
            <li>
              <span className="mr-2">d.</span>Your physical address, telephone
              number and email address;
            </li>
            <li>
              <span className="mr-2">e.</span>A statement, in which you state
              that the alleged and disputed use of your work is not authorized
              by the copyright owner, its agents or the law; and
            </li>
            <li>
              <span className="mr-2">f.</span>A statement, made under penalty of
              perjury, that the aforementioned information in your notice is
              truthful and accurate, and that you are the copyright or
              intellectual property owner, representative or agent authorized to
              act on the copyright or intellectual property owner&apos;s behalf.
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">17.</span>Warranty and Disclaimer
          </p>
          <br />
          <p>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT:</p>
          <ol className="mt-2 list-inside space-y-3">
            <li>
              <span className="mr-2">a.</span>THE USE OF TempOwn.com, ITS
              SERVICES AND SOFTWARE ARE YOUR SOLE RISK. OUR SERVICES AND
              SOFTWARE SHALL BE PROVIDED ON AN &quot;AS IS&quot; AND/OR &quot;AS AVAILABLE&quot;
              BASIS. TEMPOWN AND ITS OWNER, SUBSIDIARIES, AFFILIATES, OFFICERS,
              EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY
              AND ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED.
            </li>
            <li>
              <span className="mr-2">b.</span>SAVE AS OTHERWISE AGREED IN
              WRITING BY PARTIES, TEMPOWN DO NOT WARRANT THAT THE SERVICES OR
              THE SERVERS THAT MAKE THE SERVICE AVAILABLE WILL BE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY PRODUCT
              DESCRIPTION OR OTHER CONTENT OFFERED AS PART OF THE SERVICES, ARE
              ACCURATE, RELIABLE, CURRENT OR COMPLETE.
            </li>
            <li>
              <span className="mr-2">c.</span>YOU EXPRESSLY AGREE THAT YOUR USE
              OF THE SERVICE IS AT YOUR SOLE RISK. IF YOU DOWNLOAD ANY CONTENT
              ON THE SERVICE, YOU DO SO AT YOUR OWN DISCRETION AND RISK. YOU
              WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
              OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH CONTENT
              THROUGH THE SERVICE.
            </li>
            <li>
              <span className="mr-2">d.</span>NO ADVICE AND/OR INFORMATION,
              DESPITE WHETHER WRITTEN OR ORAL, THAT MAY BE OBTAINED BY YOU FROM
              TEMPOWN OR BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE
              ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
            </li>
          </ol>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">18.</span>APPLICABLE LAW AND DISPUTE
            SETTLEMENT
          </p>
          <br />
          <p>
            You agree that this Terms of Use and any contractual obligation
            between Reeddi Technologies and User will be governed by the laws of
            the Federal Republic of Nigeria.
          </p>
          <br />
          <p>
            If a dispute or difference arises as to the validity,
            interpretation, effects or rights and obligations of the Parties
            under this Terms of Use, the Parties shall use their best endeavor
            to reach an amicable settlement of dispute.
          </p>
          <br />
          <p>
            If any such dispute is not settled between the Parties within
            twenty-one (21) days, the Parties agree to submit such dispute to
            Mediation at the Lagos State Multi-Door Courthouse (LMDC) for
            resolution under the provisions of Lagos State Multi-Door Courthouse
            (LMDC) Law 2007.
          </p>
        </li>
        <li>
          <p className="font-semibold text-lg">
            <span className="mr-4">19.</span>CONTACT INFORMATION
          </p>
          <br />
          <p>
            If a User has any questions/complaints concerning TempOwn, the
            Website or application, this Terms of Use or anything related to any
            of the foregoing, TempOwn User Support can be reached at the
            following email address:{" "}
            <strong>
              <a href="mailto: hello@tempown.com">hello@tempown.com</a>{" "}
            </strong>{" "}
            <br />
            <br />
            Last updated: July 10th, 2021
          </p>
        </li>
      </ol>
    </div>
  );
};

export default (TermsAndCondtions);
