import React from "react";
import BrowseOtherCategories from "../../../components/BrowseOtherCategories";

// eslint-disable-next-line no-unused-vars
const EmptyCategoryList = ({text, categories }) => {
  return (
    <div>
      <div className="my-16 w-full relative text-center">
        <p className="text-temp-secondary text-lg lg:text-xl">{text || "No Results Found"}</p>
      </div>

      <div className="relative text-center mt-14 lg:mt-20 mb-24">
        <div className="text-center mb-24 lg:mb-32 relative">
          <BrowseOtherCategories />
        </div>
      </div>
    </div>
  );
};

export default EmptyCategoryList;
