import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ItemPreloader from "../../components/blocks/preloader/ItemPreloader";
import MainContainer from "../../components/layout/MainContainer";
import * as actions from "../../store/actions";
import Paginator from "../../utils/pagination";
import { scrollToTop } from "../../utils/scrollToTop";
import EmptySearchResult from "./components/EmptySearchResult";
import SearchInputSection from "./components/SearchInputSection";
import SearchResultList from "./components/SearchResultList";

const Search = (props) => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [searchInput, setSearchInput] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    scrollToTop();
    const searchKeyword = searchParams.get("q");
    setKeyword(searchKeyword || "");
    setSearchInput(searchKeyword || "");

    if (searchKeyword) {
      fetchItem({
        search: searchKeyword,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    setItems(props.items);
    setPagination(props.pagination);
  }, [props.items]);

  const fetchItem = async (params) => {
    setLoading(true);
    await props.getItemsApi(params);
    setLoading(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const searchTerm = searchInput.trim();
    if (searchTerm !== "") {
      navigate(`/search?q=${searchTerm.toLowerCase().replace(" ", "+")}`);
    }
  };

  return (
    <MainContainer hasMainContainer={false}>
      <div className="container px-4 mx-auto mb-24">
        <SearchInputSection
          searchInput={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          handleSubmit={handleSearchSubmit}
        />

        {loading ? (
          <div className="mt-16">
            <ItemPreloader />
          </div>
        ) : items?.length > 0 ? (
          <>
            <SearchResultList
              items={items}
              keyword={keyword}
              totalItems={pagination?.totalDocs}
            />
            <Paginator
              pageLimit={pagination?.limit}
              maxPages={pagination?.totalPages}
              currentPage={pagination?.page}
              getPageApi={fetchItem}
              setLoading={setLoading}
            />
          </>
        ) : (
          <EmptySearchResult keyword={keyword} />
        )}
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  items: state.items.items,
  pagination: state.items.pagination,
});

export default connect(mapStateToProps, actions)(Search);
