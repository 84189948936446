import React from "react";

import Image from "../../assets/images/svg/useractivity.svg";

import Image2 from "../../assets/images/svg/usersystem.svg";

const NoRecordReturned = ({ title, selectedImage = 1 }) => {
  return (
    <div className="mx-auto text-center my-5">
      <img
        src={selectedImage === 1 ? Image : selectedImage === 2 ? Image2 : Image}
        className="mx-auto w-36 lg:w-auto"
        alt=""
      />

      <p className="text-sm md:text-base xl:text-lg pt-3 text-temp-primary">
        {title || "No record returned."}
      </p>
    </div>
  );
};

export default NoRecordReturned;
