import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import CheckBoxGroup from "../../../components/element/CheckBoxGroup";
import TextAreaGroup from "../../../components/element/TextAreaGroup";
import AddressInputAutoComplete from "../../../components/element/AddressInputAutoComplete";

const EditAddressModal = ({
  show,
  handleCloseModal,
  processUpdateAddress,
  selectedAddressData,
}) => {
  const [values, setValues] = useState({
    ...selectedAddressData,
    formatted_address: selectedAddressData?.address
  })
  const [address, setAddress] = useState(selectedAddressData?.address);
  const [additionalNote, setAdditionalNote] = useState(selectedAddressData?.additionalNote);
  const [submitting, setSubmitting] = useState(false);
  const [isDefault, setIsDefault] = useState(selectedAddressData?.isDefault);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!values?.place_id) {
      setError("Make sure you select the address that matches your location or nearest location from the dropdown");
    } else {
      setSubmitting(true);

      const payload = {
        address_components: values?.address_components,
        address: values?.formatted_address,
        additionalNote: additionalNote,
        googlePlaceId: values?.place_id,
        geometry: values?.geometry,
        isDefault
      };

      await processUpdateAddress(payload, selectedAddressData._id);

      setSubmitting(false);
    }
  }

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full w-full overflow-auto"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="text-center">
                <h3
                  className="leading-6 text-xl md:text-2xl font-medium text-temp-secondary"
                  id="modal-headline"
                >
                  Edit Address
                </h3>
                <div className="mt-10">
                  <form onSubmit={handleSubmit}>
                    <div className="w-full">
                      <p className="text-sm-15 lg:text-base text-left mb-4 text-temp-primary">Address</p>
                      <AddressInputAutoComplete
                        placeholder={"Enter Address"}
                        value={address}
                        onPlaceSelected={(place) => {
                          setValues(place);
                          setAddress(place?.formatted_address);
                        }}
                        onChange={(e) => {
                          setValues({})
                          setAddress(e.target.value)
                        }}
                      />

                    </div>

                    <div className="w-full text-left">
                      <TextAreaGroup
                        marginBottom="mb-4"
                        label="Additional Note"
                        name="additionalNote"
                        value={additionalNote}
                        onChange={(e) => setAdditionalNote(e.target.value)}
                        showTip={false}
                      />
                    </div>

                    <div className="w-10/12 lg:w-4/12">
                      <CheckBoxGroup
                        label={
                          <span className="pl-0 text-temp-info text-sm">
                            Set as default
                          </span>
                        }
                        name="isDefault"
                        value={isDefault}
                        onChange={() => setIsDefault(!isDefault)}
                        checked={isDefault ? true : false}
                      />
                    </div>

                    {error && <div className="mt-6 w-full mx-auto text-center text-sm text-red-600">
                      {error}
                    </div>
                    }

                    <div className="flex mt-8 items-baseline justify-end flex-end">
                      <button
                        onClick={handleCloseModal}
                        className="text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={submitting}
                        className={`mt-5 mb-4 py-3 px-8 text-sm bg-temp-green-500 bg-gradient font-bold text-white rounded-lg focus:outline-none ${submitting && "opacity-50"
                          }`}
                      >
                        {submitting ? "Processing..." : "Update"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAddressModal;
