import React from 'react';

const ReferPendingEarning = ({ referralDetails }) => {
    return <div className="w-full relative mt-8">
        <div className="text-sm lg:text-base rounded-xl bg-temp-green-200 px-6 py-4 flex flex-wrap items-center justify-between">
            <p className="text-temp-primary">Pending Earning</p>
            <p className="font-semibold text-temp-primary">
                ₦{referralDetails?.pendingEarning}
            </p>
        </div>
    </div>
};

export default ReferPendingEarning;
