import React from "react";
import { capitalizeFistLetterInSentence } from "../../../utils/capitalizeFirstLetterInSentence";

const CancelledOrRejectedCard = ({
  handleCheckItemAvailabilityModalShow,
  loggedInUserDetails,
  orderDetails,
}) => {
  return (
    <div
      className="rounded-xl py-6 lg:py-8 mx-auto"
      style={{
        border: "0.5px solid #B0B0B0",
      }}
    >
      <div className="text-temp-secondary font-semibold px-6 lg:px-8 mb-4 lg:mb-6 text-base lg:text-lg">
        Order Activities
      </div>
      <div className="flex flex-wrap items-baseline pr-8">
        <p className="px-6 lg:px-8 text-sm lg:text-sm-15 pb-4 text-temp-gray">
          Status
        </p>
        <p className="px-4 lg:px-6 font-medium text-xs lg:text-sm canceled-status rounded-xl pb-3 pt-3">
          {capitalizeFistLetterInSentence(orderDetails?.status)} Approval
        </p>
      </div>

      {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
        <div className="px-6 lg:px-8 mt-2 lg:mt-3">
          <button
            onClick={handleCheckItemAvailabilityModalShow}
            className="py-3 w-full px-4 mt-5 font-bold text-xs lg:text-sm rounded-lg focus:outline-none bg-temp-green-500 bg-gradient text-white"
          >
            Check Availability
          </button>

          <p className="text-xs lg:text-sm text-center pt-4">
            Want to place a new order of same item? Check item availability amd
            proceed.
          </p>
        </div>
      ) : (
        <div className="px-6 lg:px-8 mt-3 lg:mt-5 text-temp-gray text-sm lg:text-sm-15">
          You didn&apos;t approve this rental request so the borrower can not
          borrower the item until next rental request.
        </div>
      )}
    </div>
  );
};

export default CancelledOrRejectedCard;
