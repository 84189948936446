import React from 'react';
import idea from "../../../../../assets/images/svg/idea.svg";

const TipContainer = ({ stepData, currentStep }) => {
    return (
        <div className="order-1 xl:order-2 flex flex-col w-full md:w-10/12 lg:w-8/12 xl:w-3/12 rounded-xl border border-temp-green-light bg-temp-green-100 pt-6 xl:pt-8 pb-8 md:pb-10 xl:pb-14  px-4 mb-12">
            <p className="flex flex-row items-center">
                <img className="mr-2" alt="" src={idea} />
                <span className="pt-1 text-sm lg:text-sm-15 text-temp-secondary font-semibold">TIPS</span>
            </p>
            <ul className="list-disc text-temp-gray ml-6 mt-2 text-xs lg:text-sm">
                {stepData[currentStep]?.tips?.map((e) => (
                    <li key={`${currentStep}-${e?.substring(0, 25)}`} className="mt-2">
                        {e}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TipContainer;
