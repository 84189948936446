import { Link } from "react-router-dom";
import React from "react";

// Borrowers
export const faqsDataForTempOwners = [
  {
    id: 1,
    type: ["general"],
    question: "Who is a TempOwner?",
    answer:
      "As the name goes, a TempOwner is a temporary owner of equipment rented on the TempOwn platform. TempOwners are our verified users who can rent equipment for use on the platform.",
  },
  {
    id: 2,
    type: ["general"],
    question: "Who is an owner?",
    answer:
      "An owner is a verified user and part of our community of vendors who rent out equipment using the TempOwn platform. An owner has a title to an equipment on the platform.",
  },
  {
    id: 3,
    type: ["general"],
    question: "What is TempOwn.com?",
    answer:
      "TempOwn is a secure and fully insured rental marketplace for essential items and equipment.",
  },
  {
    id: 4,
    type: ["renting"],
    question: "Who do I borrow equipment from; Tempown or a third party?",
    answer:
      "The equipment listed on our platform is owned by verified third-party owners. They own equipment and rent them out through our platform while we manage the entire rental process.",
  },
  {
    id: 5,
    type: ["onboarding"],
    question:
      "Why do you request my National Identification Number (NIN) or other identification number?",
    answer:
      "We request for your NIN simply to guarantee safety for all TempOwn users, including you. Your National Identification Number (NIN) and other recognised means of identification are used to tie together all records about you – demographic data, fingerprints, head-to-shoulder facial picture, other biometric data and digital signature – in the National Identity Database making it relatively easy to confirm and verify your identity.",
  },
  {
    id: 6,
    type: ["general", "onboarding", "renting", "payment"],
    question: "How can I contact TempOwn support?",
    answer: (
      <>
        For support, inquiries, complaints, or any other issue, you can send a
        mail to <a href="mailto:hello@tempown.com">hello@tempown.com</a>or call
        the tempown care centre on
        <a href="tel:+2348122888638">+2348122888638</a>.
      </>
    ),
  },
  {
    id: 7,
    type: ["renting", "payment"],
    question: "Who do I pay my rental fee to?",
    answer: `All financial transactions are done on the tempown platform. 
          You pay rental fees to tempown and are not expected to pay directly to the Owner. 
          With this, tempown ensures you have received the equipment 
          in good working condition before paying the owner. 
          This also makes it easy to make a refund when required.`,
  },
  {
    id: 8,
    type: ["renting", "payment"],
    question: "Can I pay for my rental online?",
    answer:
      "Yes, you can. All payment for transactions done on TempOwn is made online using our secured payment platform.",
  },
  {
    id: 9,
    type: ["renting", "payment"],
    question: "Does tempown charge me extra from renting on the platform?",
    answer:
      "We do not charge TempOwners any extra fee for using our platform. TempOwners are only charged the rental fee for the equipment rented.",
  },
  {
    id: 10,
    type: ["renting"],
    question: "How do I ask the owner of an item a question?",
    answer:
      "The tempown platform provides a chatroom interface that allows TempOwners to chat with owners. Tempown may choose to provide the owners with personal contact to the TempOwner when required.",
  },
  {
    id: 11,
    type: ["payment", "renting"],
    question: "How do I get the equipment after making payments?",
    answer: `Upon completion of payment and approval, the equipment 
        can be picked up from the owner's standard pickup point 
        as stated on the owner's bio at an agreed time. We 
        set pickup time during the day and require that 
        pickup must be made at a standard pickup location. 
        In cases where vendors may need to deliver 
        the equipment themselves, a new pickup location can be set.`,
  },
  {
    id: 12,
    type: ["renting", "general"],
    question: "What can I do if I can't reach an owner for pickup?",
    answer: (
      <>
        We encourage owners to ensure they can be reached especially during
        pickup. In the case where the owners can not be reached for pickup, the
        TempOwner can contact our care centre for assistance.
        <br />
        TempOwn will take necessary actions.
      </>
    ),
  },
  {
    id: 13,
    type: ["renting", "general"],
    question: "How can I find the equipment I want to rent?",
    answer:
      "Visit tempown.com or download the app from the App Store. On the platform, you can either browse through equipment categories or use the quick search icon to search for the equipment you need.",
  },
  {
    id: 14,
    type: ["renting", "general"],
    question:
      "What happens when the equipment I rented is discovered to be a previously stolen one?",
    answer: `In this case, tempown would apply all legal options at her disposal.
        The vendor would be prosecuted and subsequently blacklisted from the platform. Borrowers are not liable to any 
        legal action and have the backing of tempown when required. `,
  },
  {
    id: 15,
    type: ["renting", "general"],
    question: "What happens if an equipment is damaged or stolen?",
    answer: `We encourage the TempOwner to take extra caution and care 
        when in possession of equipment rented on our platform. 
        In the case of damage, tempown would arbitrate to determine 
        whether the cause of damage is traceable to the TempOwner. 
        If yes, the TempOwner may be required to cover the repair fee. 
        In the case of theft, the TempOwner would be expected to cover 
        the cost of replacing the equipment. This, however, does not 
        apply if the
         damage or theft occurred under the care of an owner's operator.`,
  },
  {
    id: 16,
    type: ["renting", "general"],
    question: "Can I get equipment operators from tempown?",
    answer: `Currently, no, you can't.`,
  },
  {
    id: 17,
    type: ["renting", "general"],
    question: "How can I assess an owner?",
    answer: `You can assess an owner by checking his profile on our platform. You can check for reviews, badges and the owner's portfolio.`,
  },
  {
    id: 18,
    type: ["renting", "general", "payment"],
    question: "Under what circumstances would I get refunded by tempown?",
    answer: (
      <>
        There are cases where tempown may refund a TempOwner. Firstly, if
        tempown decides for specific reasons not to continue the transaction
        with the TempOwner. Secondly, when an owner can&apos;t fulfil his part
        of the bargain; like inability to provide equipment. And thirdly, when
        the TempOwner opts out from the transaction in line with the tempown
        policy.{" "}
      </>
    ),
  },
  {
    id: 19,
    type: ["renting", "general"],
    question:
      "What happens when I return equipment later than the scheduled return date?",
    answer: (
      <>
        TempOwners are to return rented equipment on the scheduled return date.
        When you default on this, you will be required to pay an extension fee
        and a late return fee of 100% and 50%, respectively, For the extra days
        you have the equipment in your custody. Also, your account will be
        flagged for breach of the agreement. If you default on three occasions,
        you&apos;ll be restricted from renting on the TempOwn platform. Kindly
        check the{" "}
        <Link to="/policy/refund" className="text-temp-green-500">
          refund policy
        </Link>{" "}
        for more information.
      </>
    ),
  },
  {
    id: 20,
    type: ["renting", "general"],
    question: "Can I extend the usage of an equipment?",
    answer: `Yes, you can. However, you will be required to confirm 
        the availability of the equipment on our platform for the extension period. If available, you can request an extension and wait for the approval. Extension of use depends solely on the approval of the owner.`,
  },
  {
    id: 21,
    type: ["renting", "general"],
    question:
      "What happens when I return the equipment earlier than the scheduled return date?",
    answer:
      "There will be no refund if you return the equipment on an earlier date. You should know the actual dates and time-frame you will be needing equipment for.",
  },
  {
    id: 22,
    type: ["renting", "payment"],
    question: "Does TempOwn service charge cover insurance?",
    answer:
      "No, the Tempown service charge does not cover insurance. Tempown Protect is available for owners of items and equipment with identified serial numbers. The insurance fee on each rental is 10% of the total rental cost. For those with general items without serial numbers, you are able to choose between our pre-selected options of deposit amounts which the member/borrower renting must pay before your booking start date. ",
  },
  {
    id: 23,
    type: ["renting", "general"],
    question: "What if my equipment doesn't have a serial number?",
    answer:
      "If you do not have any proof of ownership, your item or equipment should be listed under the general category. Here, you can opt for a deposit that suits you and serve as collateral for your item or equipment",
  },
  {
    id: 24,
    type: ["onboarding", "general"],
    question:
      "Why do you request my National Identification Number (NIN) or other identification number?",
    answer:
      "We request for your NIN simply to guarantee safety for all TempOwn users, including you. Your National Identification Number (NIN) and other recognised means of identification are used to tie together all records about you – demographic data, fingerprints, head-to-shoulder facial picture, other biometric data and digital signature – in the National Identity Database making it relatively easy to confirm and verify your identity. ",
  },
  {
    id: 25,
    type: ["payment"],
    question: "What is a Deposit fund and how does it concern me? ",
    answer:
      "Deposits are basically for Items, tools and equipment without categorization and serial number. This is the General Category. To make sure that items that do not fall under TempOwn Protect are still given some level of security, borrowers will be made to pay deposits as a form of collateral before renting.",
  },
  {
    id: 26,
    type: ["renting", "general"],
    question:
      "What happens when I pick up an equipment and later find out it has an issue?",
    answer:
      "It is crucial that you thoroughly inspect the equipment at the pickup time and venue to ensure that you do not incur the cost of damages for equipment that already has an issue or underlying problem. If you find an underlying issue, go to the vendor about it, showing him the flaw and asking for any further information he may have about the usage. Don't stop there; take a clear photograph of the equipment and upload it on the platform. You should file a complaint if you discover the problem after you've left the pickup location. Such complaints must be made within six hours of the collection time.",
  },
];

// Lenders
export const faqsDataForOwners = [
  {
    id: 1,
    type: ["general"],
    question: "Who is a owner?",
    answer:
      "An owner is a verified user and part of our community of vendors who rent out equipment using the TempOwn platform. An owner has a title to an equipment on the platform.",
  },
  {
    id: 2,
    type: ["general"],
    question: "Who is a TempOwner?",
    answer:
      "As the name goes, a TempOwner is a temporary owner of equipment rented on the TempOwn platform. TempOwners are our verified users who can rent equipment for use on the platform.",
  },
  {
    id: 3,
    type: ["general"],
    question: "What is TempOwn.com?",
    answer:
      "TempOwn is a secure and fully insured rental marketplace for essential items and equipment.",
  },
  {
    id: 4,
    type: ["general", "payment"],
    question: "Do I have to pay to get my equipment on the platform?",
    answer:
      "No, you do not have to pay to get your equipment listed on our platform. It's a free world.",
  },
  {
    id: 5,
    type: ["general", "payment"],
    question: "Why do you request for my account details?",
    answer:
      "Your account details are required for you to withdraw funds from your wallet. When a borrower pays the rental fee for an equipment, the fee is deposited into your wallet. Upon withdrawal request from you, it is paid into your bank account you provide.",
  },
  {
    id: 6,
    type: ["general", "onboarding"],
    question:
      "Why do you request my National Identification Number (NIN) or other identification number?",
    answer:
      "We request for your NIN simply to guarantee safety for all TempOwn users, including you. Your National Identification Number (NIN) and other recognised means of identification are used to tie together all records about you – demographic data, fingerprints, head-to-shoulder facial picture, other biometric data and digital signature – in the National Identity Database making it relatively easy to confirm and verify your identity.",
  },
  {
    id: 7,
    type: ["general", "payment"],
    question: "How does TempOwn charge?",
    answer: `TempOwn offers her services for a commission on transactions. Our service charge is done per rental basis. We charge you a service fee for every piece of equipment and items rented via the platform. Our base service charge is 19% of the total rental cost.`,
  },
  {
    id: 8,
    type: ["general", "payment"],
    question: "Does TempOwn service charge cover insurance?",
    answer:
      "No, the Tempown service charge does not cover insurance. Tempown Protect is available for owners of items and equipment with identified serial numbers. The insurance fee on each rental is 10% of the total rental cost. For those with general items without serial numbers, you are able to choose between our pre-selected options of deposit amounts which the member/borrower renting must pay before your booking start date.",
  },
  {
    id: 9,
    type: ["general"],
    question: "Who does the TempOwner pay to?",
    answer:
      "TempOwners pay directly to Tempown through our secure payment platform.",
  },
  {
    id: 10,
    type: ["general", "payment"],
    question: "Is payment secure?",
    answer:
      "Yes, payment is secure. We have an integrated payment model that protects every financial transaction from any possible hack.",
  },
  {
    id: 11,
    type: ["general", "renting"],
    question: "What happens when I cancel a rental?",
    answer: `You will be charged an Inconvenience Fee which will be 
    deducted on the next payouts until it has been paid off. 
    The inconvenience fee will be 4.9% of the total rental 
    amount of that particular item or equipment you canceled. 
    A cancellation review will be left on your profile explaining 
    you had canceled an item before the booking start date. If 
    you cancel more than 3 times, your account and listings may 
    be suspended or deactivated upon review by TempOwn. 
    You may lose your title as a Pro Owner depending on the case.`,
  },
  {
    id: 12,
    type: ["general", "payment"],
    question: "When do I get paid?",
    answer: `You get paid when the rental transaction is complete.`,
  },
  {
    id: 13,
    type: ["general", "payment"],
    question: "How do I get paid?",
    answer: `Your wallet will be credited with the rental fee by TempOwn when you accept a rental request from a TempOwner, and equipment exchanged has been made. You can then make withdrawals into your bank account.`,
  },
  {
    id: 14,
    type: ["general", "renting"],
    question:
      "Is there any form of insurance for my equipment when it is rented via the tempown platform?",
    answer:
      "If your item or equipment falls under Tempown Protect category, 10% of the total rental cost would be deducted for insurance and it is per rental basis. For items without serial numbers, owners can choose to ask the borrower to pay a deposit as collateral to guarantee that the equipment and items are in safe hands. Upon return, the borrower is entitled to get the deposit back. In cases of damage and loss, the deposit will be transferred to the owner.",
  },
  {
    id: 15,
    type: ["general", "renting"],
    question: "How does TempOwn Protect work?",
    answer:
      "Tempown Protect is our insurance package for equipment and item owners who have unique serial numbers attached to their equipment. The TempOwn Protect fee is 10% of the total rental cost.",
  },
  {
    id: 16,
    type: ["general", "renting"],
    question: "How can I help my items stand out?",
    answer: `Take clean and clear photos of your items, give proper and 
      accurate descriptions, ensure your equipment are in good shape, 
      give TempOwners a good experience during exchange; this gets you good 
      reviews and badges. With these, you and your equipment would stand out.`,
  },
  {
    id: 17,
    type: ["general", "renting"],
    question: "What happens if someone steals or never returns my item?",
    answer: `If a piece of equipment is stolen by the TempOwner, TempOwn 
    would use legal means to find the TempOwner using the TempOwner's 
    verified information in our database. Also, if the equipment 
    falls under the TempOwn Protect cover, the owner would get a 
    replacement. If the equipment was stolen from the TempOwner, 
    TempOwn would arbitrate between the two parties to work out a 
    replacement plan. 
    This applies to equipment that TempOwn Protect does not cater for.`,
  },
  {
    id: 18,
    type: ["general", "rental", "onboarding"],
    question:
      "What kind of equipment or item can be rented out on the tempown platform?",
    answer: `Essential equipment and tools cutting across industries and 
    sectors can be listed on the tempown platform. However, 
    stolen equipment, machines, tools cannot be listed on the platform. 
    Also, equipment and tools categorized as illegal and non-essential 
    under existing laws like 
    firearms and weapons cannot be listed on the tempown platform.`,
  },
  {
    id: 19,
    type: ["general", "renting"],
    question: "Why does tempown request equipment and items' serial numbers?",
    answer: `The serial number of the item or equipment is used as proof of ownership. At times, just because you own the items doesn’t mean they are all fully covered. You need proof to show that you are a bonafide owner. This is also to protect our third party insurance company from fraud. Always input your serial numbers.`,
  },
  {
    id: 20,
    type: ["general", "payment"],
    question: "What is a Deposit fund and how does it concern me?",
    answer: `Deposits are basically for Items, tools and equipment without categorization and serial number. This is the General Category. As the Owner, you are able to choose between our pre-selected options of deposit amounts which the borrower renting must pay before the  booking start date. Having a required deposit on your item will force the member renting your Item to take good care of it since he would want to get a refund. In the occurrence of a claim, you will only be able to claim a maximum amount of the total deposit. `,
  },
  {
    id: 21,
    type: ["general", "renting", "payment"],
    question: "What does insurance on my Equipment cover?",
    answer: `If at any time during the period of insurance, the equipment or item suffers any accidental loss or damage, TempOwn shall make sure that the insured item or equipment is repaired or replaced as the case may be. For any of this to happen, a valid claim must be laid.`,
  },
  {
    id: 22,
    type: ["general", "onboarding"],
    question: "Can I upload all my equipment at once?",
    answer: `Currently, you can't upload multiple items at once. You would need to upload them one at a time.`,
  },
  {
    id: 23,
    type: ["general", "onboarding"],
    question: "Why was my listed item not approved?",
    answer: <>
      <span>Your listed item was not approved because of one of the following;</span>
      <ul className="list-disc list-outside pl-4 pt-3">
        <li>Your equipment or item does not match the item name imputed</li>
        <li>You placed the item in the wrong category</li>
        <li>You submitted an invalid serial number</li>
        <li>You added your phone number to the item description</li>
        <li>You don’t have a well detailed item description</li>
        <li>You uploaded low quality images</li>
        <li>You listed an authorized equipment or item</li>
      </ul>
    </>
  },
];
