import React, { useState } from "react";
import MainContainer from "../../../components/layout/MainContainer";
import store from "store";
import Documents from "../../../assets/images/svg/documents_get_started.svg";
import Images from "../../../assets/images/svg/images_get_started.svg";
import Money from "../../../assets/images/svg/money_get_started.svg";
import AlertIcon from "../../../assets/images/svg/alarm.svg";
import { Link } from "react-router-dom";

const ListItem = ({ title, content, img, bgStyle }) => {
  return (
    <div className="flex flex-row text-temp-primary mb-8 lg:mb-14">
      <div
        className={`${bgStyle} w-16 lg:w-20 flex h-16 lg:h-20 justify-center items-center mr-6 lg:mr-8 border  rounded-lg`}>
        <img src={img} alt="" className="w-8 h-8 lg:w-12 lg:h-12" />
      </div>
      <div className="w-10/12 md:pl-2">
        <p className="text-sm-15 md:text-base lg:text-lg xl:text-lg leading-7 font-medium">
          {title}
        </p>
        <p className="leading-6 pt-2 text-sm md:text-sm-15 lg:text-base font-light text-temp-gray">
          {content}
        </p>
      </div>
    </div>
  );
};

const LenderUploadItemStart = () => {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(store.get("temp_usER12_dATa"));

  return (
    <MainContainer hasMainContainer={false}>
      <div className="container relative px-4 mx-auto w-full md:w-8/12 pt-6 md:pt-14 pb-20 lg:py-20">
        <div className="flex flex-wrap flex-row justify-center">
          <div className="w-full lg:w-10/12 xl:w-8/12 pr-0 xl:pr-12">
            <h3 className="text-temp-green-500 text-xl lg:text-2xl font-medium pb-8 md:pb-12">
              Getting Started
            </h3>

            <ListItem
              title="Images of the items"
              img={Images}
              bgStyle="bg-yellow-50 border-yellow-500"
              content="Take top class photographs of your items. Give it your best
                shot. Nice-looking images help you stand out."
            />

            <ListItem
              title="Set your rental rate"
              img={Money}
              bgStyle="bg-temp-primary-100 border-temp-primary"
              content="Study the market, set pricing to your advantage, provide
              discounts for consistent use."
            />

            <ListItem
              title="Add descriptions and features"
              img={Documents}
              bgStyle="bg-temp-green-100 border-temp-green-500"
              content="A well-detailed description of your inventory gives you an edge
              over others. Add as many features as you can."
            />

            <div className="flex justify-center mt-12 xl:mt-8 mb-20">
              {/* IF ACCOUNT IS NOT VERIFIED */}
              {!user?.verified_id ||
                !user?.verified_phone ||
                !user?.image ? (
                <div className="inline-flex space-x-4 items-center  mt-6">
                  <img src={AlertIcon} alt="" width="20rem" />
                  <p className="text-red-600 text-xs lg:text-sm">
                    Account verification not complete. Click{" "}
                    <Link
                      to={
                        !user?.verified_id || !user?.image
                          ?
                          "/complete-verification" :
                          "/verify-email"
                      }
                      className="font-bold">
                      here
                    </Link>{" "}
                    to complete verification and continue.
                  </p>
                </div>
              ) : (
                <Link
                  to="/upload-item/submission"
                  className="bg-gradient upload-start w-full text-right bg-temp-green-500 whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white font-semibold text-sm px-10 py-4"
                >
                  Yes, I&apos;m all set
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default LenderUploadItemStart;
