import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../../../components/blocks/Container";
import { faqsDataForOwners } from "../../../../utils/faqsData";
const FaqSection = () => {
  const [selectedID, setSelectedID] = useState(1);
  const [show, setShow] = useState(true);
  const handleToggleDropdown = (val) => {
    setSelectedID(val);
    if (val == selectedID) {
      setShow(!show);
    } else {
      setShow(true);
    }
  };

  return (
    <div className="py-16 lg:py-24 bg-white relative w-full">
      <Container>
        <div className="">
          <h3 className="text-2xl md:text-4xl font-semibold text-temp-primary text-center">
            Frequently asked questions{" "}
          </h3>

          <div className="w-full md:w-10/12 xl:w-9/12 mx-auto mt-6 mb-6 md:mt-16 md:mb-12">
            <dl className="mt-8 mx-auto">
              <div className="w-full">
                {/* Display faqs for Ownwers only */}
                {faqsDataForOwners.slice(0, 4).map((d) => (
                  <div
                    key={d.id}
                    className="question-and-answer select-none cursor-pointer my-3 lg:my-6 px-6 lg:px-8  py-6 lg:py-8 group"
                    style={{
                      background: "#F9F9F9",
                    }}
                  >
                    <dt
                      className="question"
                      onClick={() => handleToggleDropdown(d.id)}
                    >
                      <div className="flex justify-between">
                        <div
                          className={`text-sm-15 md:text-base lg:text-lg font-semibold question-title ${
                            selectedID === d.id && show
                              ? "text-temp-green-500"
                              : "text-temp-primary"
                          }`}
                        >
                          {d.question}
                        </div>
                        <div className={`faq-arrow`}>
                          {selectedID === d.id && show ? (
                            <svg
                              width="10"
                              height="4"
                              viewBox="0 0 10 4"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.0273791 3.7663V0.634298H9.36538V3.7663H0.0273791Z"
                                fill="#08A05C"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="10"
                              height="9"
                              viewBox="0 0 10 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.13627 3.57009V5.51409H5.66227V8.97009H3.73627V5.51409H0.262274V3.57009H3.71827V0.114089H5.66227V3.57009H9.13627Z"
                                fill="black"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </dt>
                    <dd
                      className={`answer mt-5 text-temp-primary w-full md:w-9/12 leading-6 lg:leading-8 text-sm lg:text-base ${
                        selectedID === d.id && show ? "block" : "hidden"
                      } `}
                    >
                      {d.answer}
                    </dd>
                  </div>
                ))}
              </div>
            </dl>
          </div>

          <div className="text-center mx-auto pt-6">
            <Link to={"/faqs"} className="text-temp-secondary font-semibold text-base lg:text-lg text-center">
                Want to access more FAQs? Click <span className="underline">here</span> to continue
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FaqSection;
