// Generate Aveage total star rating value for reviews

export const getTotalStarRatingValue = (reviews) => {
  const oneStarRating = reviews?.filter((a) => a.rating === 1);
  const secondStarRating = reviews?.filter((a) => a.rating === 2);
  const thirdStarRating = reviews?.filter((a) => a.rating === 3);
  const fourthStarRating = reviews?.filter((a) => a.rating === 4);
  const fifthStarRating = reviews?.filter((a) => a.rating === 5);

  const oneStarTotal = oneStarRating?.length * 1;
  const twoStarTotal = secondStarRating?.length * 2;
  const threeStarTotal = thirdStarRating?.length * 3;
  const fourStarTotal = fourthStarRating?.length * 4;
  const fiveStarTotal = fifthStarRating?.length * 5;

  const totalRatings = reviews?.length;

  const totalStars =
    oneStarTotal +
    twoStarTotal +
    threeStarTotal +
    fourStarTotal +
    fiveStarTotal;

  const averageStarTotal = totalStars / totalRatings;

  return averageStarTotal;
};
