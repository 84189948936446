import React from "react";

export const UserIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 0C5.60762 0 0 5.60762 0 12.5C0 19.3923 5.60762 24.9999 12.5 24.9999C19.3923 24.9999 24.9999 19.3923 24.9999 12.5C24.9999 5.60762 19.3924 0 12.5 0ZM12.5 23.913C6.2065 23.913 1.08694 18.7934 1.08694 12.5C1.08694 6.2065 6.2065 1.08694 12.5 1.08694C18.7934 1.08694 23.913 6.2065 23.913 12.5C23.913 18.7934 18.7934 23.913 12.5 23.913Z"
        fill="#08A05C"
      />
      <path
        d="M12.4988 11.9565C8.60316 11.9565 5.43359 15.1261 5.43359 19.0218C5.43359 19.3218 5.67709 19.5653 5.97709 19.5653C6.27709 19.5653 6.52058 19.3217 6.52058 19.0217C6.52058 15.725 9.20209 13.0435 12.4988 13.0435C15.7956 13.0435 18.4771 15.725 18.4771 19.0217C18.4771 19.3217 18.7206 19.5652 19.0206 19.5652C19.3206 19.5652 19.5641 19.3217 19.5641 19.0217C19.5641 15.1261 16.3945 11.9565 12.4988 11.9565Z"
        fill="#08A05C"
      />
      <path
        d="M12.4997 3.26074C10.4019 3.26074 8.69531 4.96725 8.69531 7.0651C8.69531 9.16296 10.4018 10.8695 12.4997 10.8695C14.5975 10.8695 16.304 9.16291 16.304 7.0651C16.304 4.9673 14.5975 3.26074 12.4997 3.26074ZM12.4997 9.78248C11.0019 9.78248 9.7823 8.56291 9.7823 7.0651C9.7823 5.5673 11.0019 4.34773 12.4997 4.34773C13.9975 4.34773 15.2171 5.5673 15.2171 7.0651C15.2171 8.56291 13.9975 9.78248 12.4997 9.78248Z"
        fill="#08A05C"
      />
    </svg>
  );
};
