import React from "react";
import { Transition } from "@headlessui/react";

const Input = ({
  index,
  name,
  value,
  onChange,
  onKeyUp,
  error,
  onFocus,
  className,
  ...props
}) => {
  return (
    <input
      type="text"
      className={`code-input${index} border flex-shrink ${className || "w-20 h-16 mr-8"
        } mb-6 pt-1 text-center font-bold text-xl lg:text-3xl text-temp-secondary rounded-xl focus:outline-none focus:border focus:border-temp-green-500 ${error ? "border-red-600" : "border-gray-300 "
        }`}
      maxLength="1"
      name={name}
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      {...props}
    />
  );
};

const ConfirmReturnEnterCodeModal = ({
  show,
  handleCloseModal,
  processSubmitReturnCode,
  returnCodeSubmitLoading,
  code,
  handleCodeInputChange,
  handleCodeOnKeyUp,
  codeError,
}) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="">
                <h3
                  className="text-center leading-6 text-xl md:text-2xl font-medium text-temp-secondary"
                  id="modal-headline"
                >
                  Confirm Return
                </h3>
                <p className="text-temp-gray text-sm pt-4 text-center">
                  Please ask for the return code from the lender and enter
                  below.
                </p>
                <div className="mt-10">
                  <form autoComplete="off" onSubmit={processSubmitReturnCode}>
                    <div className="mt-4 mb-4 flex flex-wrap justify-center">
                      {code.map((a, key) => (
                        <Input
                          key={key}
                          index={key}
                          name="code"
                          value={a.value}
                          autoComplete="off"
                          onChange={(e) => handleCodeInputChange(e, key)}
                          onKeyUp={(e) => handleCodeOnKeyUp(e, key)}
                          className="w-10 h-12 mr-4 lg:mr-8 lg:w-16 lg:h-16"
                        />
                      ))}
                    </div>
                    <div className="flex items-baseline justify-end flex-end">
                      <button
                        onClick={handleCloseModal}
                        className="text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={returnCodeSubmitLoading}
                        className={`mt-5 mb-4 py-3 px-8 text-sm bg-temp-green-500 bg-gradient font-bold text-white rounded-lg focus:outline-none ${returnCodeSubmitLoading && "opacity-70"
                          }`}
                      >
                        {returnCodeSubmitLoading ? "Processing ..." : "Confirm"}
                      </button>
                    </div>

                    <div className="text-red-600 text-sm pt-2 text-center">
                      {codeError}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="py-2" style={{ background: "#FEFBB2" }}>
            <p className="text-xs text-center text-temp-gray">
              Ensure the lender take a picture of item while returning
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmReturnEnterCodeModal;
