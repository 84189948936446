import Actions from "./actionTypes";

const visitRecord = (payload) => {
  return {
    type: Actions.SITE_VISITED,
    payload: {
      visit: payload.visit,
    },
  };
};

export default visitRecord;
