import LagosImage from "../assets/images/png/location/lagos.webp"
import AbujaImage from "../assets/images/png/location/abuja.webp"
import OgunImage from "../assets/images/png/location/ogun.webp"
import IbadanImage from "../assets/images/png/location/ibadan.webp"

export const locationData = [
    {
        id: 1,
        name: "Lagos",
        itemCount: "200",
        image: LagosImage
    },
    {
        id: 2,
        name: "Abuja",
        itemCount: "200",
        image: AbujaImage
    },
    {
        id: 3,
        name: "Ogun",
        itemCount: "200",
        image: OgunImage
    },
    {
        id: 4,
        name: "Ibadan",
        itemCount: "200",
        image: IbadanImage
    }
]