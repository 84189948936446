import React, { useState, useEffect } from "react";
import MainContainer from "../../components/layout/MainContainer";
import AlertModal2 from "../../components/modals/AlertModal2";
import Loader from "../../components/blocks/Loader";
import {
  updateNotificationPrefApi,
  getNotificationPrefApi,
} from "../../store/actions/notificationActions";
import ProfileHeader from "./components/ProfileHeader";

const AlertMessage = ({ message }) => {
  return (
    <div className="mt-2 bg-temp-green-100 pt-3 rounded-lg pb-3 px-4">
      <p className="text-temp-primary text-xs">{message}</p>
    </div>
  );
};

const SettingSwitch = ({ label, title, checked, handleChange }) => {
  return (
    <div className="flex flex-1 justify-between items-center text-temp-primary py-3">
      <p className="text-sm md:text-sm-15 lg:text-base">{title}</p>
      <label className="toggle" htmlFor={label}>
        <input
          checked={checked}
          onChange={handleChange}
          className="toggle__input"
          name=""
          type="checkbox"
          id={label}
        />
        <div className="toggle__fill"></div>
      </label>
    </div>
  );
};

const NotificationSettings = (props) => {
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifs, setNotifs] = useState({
    pushWhatsapp: false,
    pushSms: false,
    pushEmail: false,
    pushApp: false,
    messageWhatsapp: false,
    messageSms: false,
    messageEmail: false,
    messageApp: false,
    reminderWhatsapp: false,
    reminderSms: false,
    reminderEmail: false,
    reminderApp: false,
    advertWhatsapp: false,
    advertSms: false,
    advertEmail: false,
    advertApp: false,
  });

  useEffect(() => {
    (async function () {
      setLoading(true);
      let data = await getNotificationPrefApi();
      if (data) {
        setNotifs({
          ...notifs,
          pushWhatsapp: data.push.whatsapp,
          pushSms: data.push.sms,
          pushEmail: data.push.email,
          pushApp: data.push.app,
          messageWhatsapp: data.message.whatsapp,
          messageSms: data.message.sms,
          messageEmail: data.message.email,
          messageApp: data.message.app,
          reminderWhatsapp: data.reminder.whatsapp,
          reminderSms: data.reminder.sms,
          reminderEmail: data.reminder.email,
          reminderApp: data.reminder.app,
          advertWhatsapp: data.advert.whatsapp,
          advertSms: data.advert.sms,
          advertEmail: data.advert.email,
          advertApp: data.advert.app,
        });
      }
      setLoading(false);
    })();
  }, []);

  const handleNotifs = (type, value) => {
    let notifChange = {};
    notifChange[type] = value;
    return setNotifs({ ...notifs, ...notifChange });
  };

  const handleSave = async (e, payload) => {
    e.preventDefault();
    let val = await updateNotificationPrefApi(payload, props.history);

    if (val) {
      setError(true);
      setShowModal(true);
      setTimeout(() => {
        setError(false);
        setShowModal(false);
      }, 3000);
    }
    // postData("http://localhost:8080/v1/notification", notifs);
    // await props.updateUserProfileApi(payload, props.history);
  };

  return (
    <MainContainer hasMainContainer={false}>
      <div className="w-full pb-36 relative">
        <ProfileHeader title="Notification" currentPageName="Notification" />
        <div className="container mx-auto px-4 ">
          {loading ? (
            <div className="my-14">
              <Loader />
            </div>
          ) : (
            <div className="w-full md:w-5/12 mx-auto mt-14 lg:mt-20">
              <div>
                <p className="text-lg text-temp-primary font-medium">
                  Push Notification
                </p>
                <AlertMessage message="These are general notifications about updates and goings-on on the platform" />

                <div className="mt-6">
                  <SettingSwitch
                    title="In-app"
                    label="PushinApp"
                    checked={notifs.pushApp}
                    handleChange={() => handleNotifs("pushApp", !notifs.pushApp)}
                  />
                  <SettingSwitch
                    title="Send to Email"
                    label="PushsendEmail"
                    checked={notifs.pushEmail}
                    handleChange={() =>
                      handleNotifs("pushEmail", !notifs.pushEmail)
                    }
                  />
                  <SettingSwitch
                    title="Text to Phone"
                    label="PushtextPhone"
                    checked={notifs.pushSms}
                    handleChange={() => handleNotifs("pushSms", !notifs.pushSms)}
                  />
                  <SettingSwitch
                    title="Text via Whatsapp"
                    label="PushtextWhatsapp"
                    checked={notifs.pushWhatsapp}
                    handleChange={() =>
                      handleNotifs("pushWhatsapp", !notifs.pushWhatsapp)
                    }
                  />
                </div>
                <hr className="my-6 border-t border-temp-green-light" />
              </div>

              <div>
                <p className="text-lg text-temp-primary font-medium">
                  Message Notification
                </p>
                <AlertMessage message="Receive notification about new messages sent by other users" />

                <div className="mt-6">
                  <SettingSwitch
                    title="In-app"
                    label="MessageinApp"
                    checked={notifs.messageApp}
                    handleChange={() =>
                      handleNotifs("messageApp", !notifs.messageApp)
                    }
                  />
                  <SettingSwitch
                    title="Send to Email"
                    label="MessagesendEmail"
                    checked={notifs.messageEmail}
                    handleChange={() =>
                      handleNotifs("messageEmail", !notifs.messageEmail)
                    }
                  />
                  <SettingSwitch
                    title="Text to Phone"
                    label="MessagetextPhone"
                    checked={notifs.messageSms}
                    handleChange={() =>
                      handleNotifs("messageSms", !notifs.messageSms)
                    }
                  />
                  <SettingSwitch
                    title="Text via Whatsapp"
                    label="MessagetextWhatsapp"
                    checked={notifs.messageWhatsapp}
                    handleChange={() =>
                      handleNotifs("messageWhatsapp", !notifs.messageWhatsapp)
                    }
                  />
                </div>
                <hr className="my-6 border-t border-temp-green-light" />
              </div>

              <div>
                <p className="text-lg text-temp-primary font-medium">
                  Reminder Notification
                </p>
                <AlertMessage message="Receive notification about new, upcoming and ongoing rentals" />

                <div className="mt-6">
                  <SettingSwitch
                    title="In-app"
                    label="ReminApp"
                    checked={notifs.reminderApp}
                    handleChange={() =>
                      handleNotifs("reminderApp", !notifs.reminderApp)
                    }
                  />
                  <SettingSwitch
                    title="Send to Email"
                    label="RemsendEmail"
                    checked={notifs.reminderEmail}
                    handleChange={() =>
                      handleNotifs("reminderEmail", !notifs.reminderEmail)
                    }
                  />
                  <SettingSwitch
                    title="Text to Phone"
                    label="RemtextPhone"
                    checked={notifs.reminderSms}
                    handleChange={() =>
                      handleNotifs("reminderSms", !notifs.reminderSms)
                    }
                  />
                  <SettingSwitch
                    title="Text via Whatsapp"
                    label="RemtextWhatsapp"
                    checked={notifs.reminderWhatsapp}
                    handleChange={() =>
                      handleNotifs("reminderWhatsapp", !notifs.reminderWhatsapp)
                    }
                  />
                </div>
                <hr className="my-6 border-t border-temp-green-light" />
              </div>

              <div>
                <p className="text-lg text-temp-primary font-medium">
                  Adverts and Newsletter
                </p>
                <AlertMessage message="Receive notification about new features, adverts and updates" />

                <div className="mt-6">
                  <SettingSwitch
                    title="In-app"
                    label="AdinApp"
                    checked={notifs.advertApp}
                    handleChange={() =>
                      handleNotifs("advertApp", !notifs.advertApp)
                    }
                  />
                  <SettingSwitch
                    title="Send to Email"
                    label="AdsendEmail"
                    checked={notifs.advertEmail}
                    handleChange={() =>
                      handleNotifs("advertEmail", !notifs.advertEmail)
                    }
                  />
                  <SettingSwitch
                    title="Text to Phone"
                    label="AdtextPhone"
                    checked={notifs.advertSms}
                    handleChange={() =>
                      handleNotifs("advertSms", !notifs.advertSms)
                    }
                  />
                  <SettingSwitch
                    title="Text via Whatsapp"
                    label="AdtextWhatsapp"
                    checked={notifs.advertWhatsapp}
                    handleChange={() =>
                      handleNotifs("advertWhatsapp", !notifs.advertWhatsapp)
                    }
                  />
                </div>
                <hr className="my-6 border-t border-temp-green-light" />
              </div>
              <button
                className="float-right bg-temp-green-500 whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white font-semibold px-6 py-3"
                onClick={(e) => handleSave(e, notifs)}
              >
                Save your Preferences
              </button>
            </div>
          )}
          {showModal && (
            <AlertModal2
              show={showModal ? true : false}
              type={error ? "success" : "error"}
              content={
                error
                  ? "Your Notification preferences have been saved"
                  : "Something went wrong"
              }
            />
          )}
        </div>
      </div>
    </MainContainer>
  );
};

export default NotificationSettings;
