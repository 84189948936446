import React from 'react';

const ReferLoader = () => {
    return <div className="refer-stat-card px-6 py-6">
        <div className="bg-temp-green-500 mb-5 w-4/12 h-3"></div>

        <p className="bg-black opacity-5 w-10 h-7"></p>
    </div>
};


export default ReferLoader;
