import React from "react";
import { Link } from "react-router-dom";
import store from "store";
import { ArrowRightIcon } from "../../../components/svgs";
import Tabs from "./Tabs";

const PageHeader = ({
  currentRentalOwner,
  handleSwitchRentalOwner
}) => {
  const isLender = store.get("temp_usER12_dATa")?.verified_lender;
  return (
    <section className="rental-activity-header pt-4 pb-2 lg:pb-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center mb-3">
          <h3 className="font-medium text-xl lg:text-2xl text-temp-primary">
            My Rental Activities
          </h3>

          {isLender && (
            <Link
              to="/rental-requests"
              className="text-temp-green-500 lg:font-medium inline-flex items-center text-sm lg:text-base pt-3 md:pt-0"
            >
              View Rental Requests{" "}
              <span className="pl-2">
                <ArrowRightIcon />
              </span>
            </Link>
          )}
        </div>

        <Tabs
          isLender={isLender}
          currentRentalOwner={currentRentalOwner}
          handleSwitchRentalOwner={handleSwitchRentalOwner}
        />
      </div>
    </section>

  );
};

export default PageHeader;
