import React, { useState } from "react";
import GetStartedImg from "../../../assets/images/png/lender-start.webp";

import AccountInformation from "./components/AccountInformation";
import Attestation from "./components/Attestation";
import BankDetails from "./components/BankDetails";

const Title = ({ title, subTitle }) => {
  return (
    <div className="block">
      <h3 className="text-temp-green-500 font-semibold text-xl md:text-2xl xl:text-3xl">
        {title}
      </h3>
      <p className="text-temp-primary py-3 lg:py-4 text-sm lg:text-base">
        {subTitle}
      </p>
    </div>
  );
};

const LenderGetStarted = () => {
  const [currentStep, setCurrentStep] = useState("step1");

  const renderTitleBasedOnCurrentStep = () => {
    switch (currentStep) {
      case "step1":
        return (
          <Title
            title="Account Information"
            subTitle="Confirm this information before proceeding"
          />
        );
      case "step2":
        return (
          <Title
            title="Bank Details"
            subTitle=" Please provide your bank Information"
          />
        );
      case "step3":
        return <Title title="Attestation" subTitle="" />;
      default:
        return;
    }
  };

  const renderBasedOnCurrentStep = () => {
    switch (currentStep) {
      case "step1":
        return <AccountInformation handeStepChange={handeStepChange} />;
      case "step2":
        return <BankDetails handeStepChange={handeStepChange} />;
      case "step3":
        return <Attestation handeStepChange={handeStepChange} />;
      default:
        return;
    }
  };

  const handeStepChange = (value) => {
    setCurrentStep(value);
  };

  return (
    <div className="bg-white relative h-screen flex flex-row flex-wrap">
       <div className="hidden lg:block lg:w-4/12 xl:w-5/12 ">
        <img
          src={GetStartedImg}
          className="object-cover h-full rounded-tl-2xl rounded-bl-2xl"
          alt=""
        />
      </div>

      <div className="w-full lg:w-8/12 xl:w-7/12 pl-6 pr-6 lg:pr-0 lg:pl-10 xl:pl-20 pt-16 lg:pt-20 xl:pt-28 pb-20">
        <div className="flex flex-wrap w-full mx-auto">
          <div className="w-full order-1 lg:order-2 pb-4 lg:pb-0">
            {renderTitleBasedOnCurrentStep()}
          </div>
        </div>

        <div className="flex flex-wrap w-full mx-auto">
          {/* Display screens */}
          {renderBasedOnCurrentStep()}
        </div>
      </div>

     
    </div>
  );
};

export default LenderGetStarted;
