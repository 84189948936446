import React, { useState } from "react";
import { Link, } from "react-router-dom";
import AvatarImage from "../../../assets/images/png/rex1.png";
import { ChatIcon } from "../../../components/svgs";
import {
    getRentalStartAndEndDate,
    getRentalUnitOrderedAndType,
} from "../../../helpers/rentalFlexibilityHelper";
import { redirectToChatPageWithUserDataApi } from "../../../store/actions";

const UploadBorrowerDetails = ({ orderDetails, itemDetails, ...props }) => {
  const [loading, setLoading] = useState(false);
  const handleOpenChatPage = async () => {
    const data = {
      ...orderDetails?.borrower,
    };

    setLoading(true);
    await redirectToChatPageWithUserDataApi(data, props.history);
    setLoading(false);
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center lg:justify-start mt-0 lg:mt-20">
      <div className="w-10 h-10 lg:w-12 lg:h-12 rounded-full object-cover mx-auto lg:mx-0 mb-2 lg:mb-0">
        <img
          src={orderDetails?.borrower?.image || AvatarImage}
          className="w-10 h-10 lg:w-12 lg:h-12 rounded-full object-cover"
          alt=""
        />
      </div>

      <div className="flex-1 lg:pl-6 text-center lg:text-left upload-image-user">
        <p className="text-xs lg:text-sm text-temp-primary pb-1">
          {orderDetails?.borrower?.firstname} is borrowing for{" "}
          <strong>
            {getRentalUnitOrderedAndType({
              ...orderDetails,
              itemId: {
                ...itemDetails,
              },
            })}
          </strong>
        </p>
        <p className="text-temp-green-500 text-xs font-semibold">
          ({getRentalStartAndEndDate(orderDetails)})
        </p>

        <div
          className={`flex flex-wrap text-xs upload-image-user-action-button`}
        >
          <Link
            to={`/borrower-profile/${orderDetails?.borrower?._id}`}
            className={`w-auto py-2 lg:py-3 px-4 font-bold inline-flex items-center justify-center text-temp-green-500 rounded-lg focus:outline-none border border-temp-green-500 mt-5`}
          >
            View Profile
          </Link>

          <button
            disabled={loading}
            onClick={handleOpenChatPage}
            className={`w-auto ml-2 md:ml-4 inline-flex py-2 lg:py-3 px-4 pr-6 items-center justify-center mt-5 font-bold text-white rounded-lg focus:outline-none bg-temp-green-500 bg-gradient 
                    ${loading && "opacity-80"}
                `}
          >
            <ChatIcon height="12" width="12" />
            <span className="pl-3">
              {loading ? "Connecting..." : <>Chat</>}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default (UploadBorrowerDetails);
