/* eslint-disable no-unused-vars */
import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import MultipleDatePicker from "../../../components/element/MultipleDatePicker";
import IncreaseOrDecreaseButton from "../../../components/element/IncreaseOrDecreaseButton";
import InputGroupWithPrefix from "../../../components/element/InputGroupWithPrefix";
import moment from "moment"

const QuantityInput = ({
  handleChangeIncreaseOrDecrease,
  selectedQuantity,
  item,
  quantityAvailabilityError
}) => {
  return (
    <div className="pt-4 pb-5">
      <div className="flex flex-row flex-wrap justify-between items-center">
        <p className="font-semibold text-xs lg:text-sm" style={{ color: "#363838" }}>Select Quantity</p>

        <div className="flex flex-row items-center">
          <IncreaseOrDecreaseButton title="-" onClick={() => handleChangeIncreaseOrDecrease("decrement")} />
          <input
            name="selectedQuantity"
            value={selectedQuantity}
            type="tel"
            maxLength={item?.totalQuantity?.length}
            onChange={(e) => handleChangeIncreaseOrDecrease("default", e.target.value)}
            className="h-12 w-16 lg:w-20 text-center focus:outline-none text-sm lg:text-base text-temp-gray border border-temp-gray-light rounded-lg"
          />
          <IncreaseOrDecreaseButton title="+" onClick={() => handleChangeIncreaseOrDecrease("increment")} />
        </div>
      </div>

      {
        quantityAvailabilityError && <p className="text-red-500 text-xs pt-4">{quantityAvailabilityError}</p>
      }

      <p className="text-sm pt-4 text-temp-orange">Total Quantity: {item?.totalQuantity}</p>

    </div>
  )
}
const CheckAvailabilityForm = ({
  days,
  onChange,
  handleCheckItemAvailability,
  checkAvailabilityError,
  item,
  selectedQuantity,
  handleChangeIncreaseOrDecrease,
  quantityAvailabilityError,
  daysDifferenceError,
  startDate,
  handleChangeStartDate,
  unitOrdered,
  setUnitOrdered,
  error
}) => {
  const rentalUnitType = item?.rentalUnitType
  const isCurrentTimeGreaterThanPickupClosingTimeWindow = moment().hour() >= moment(item?.deliveryTimeWindow?.closing).hour()
  const minimumStartDate = isCurrentTimeGreaterThanPickupClosingTimeWindow ? new Date(moment().add(1, 'days').format()) : new Date()

  return (
    <div className=" mb-6 md:md-0 pt-4 px-8 mx-auto rounded-xl box-shadow pb-10 bg-white">
      <form onSubmit={handleCheckItemAvailability}>
        <QuantityInput
          item={item}
          selectedQuantity={selectedQuantity}
          handleChangeIncreaseOrDecrease={handleChangeIncreaseOrDecrease}
          quantityAvailabilityError={quantityAvailabilityError}
        />

        <div className="group w-full">
          {
            rentalUnitType === "bySiUnits" ?
              <>
                <InputGroupWithPrefix
                  label={<>
                    Set Estimated {item?.rentalUnitType === "bySiUnits" ?
                      item?.siUnitType || "Hour" :
                      item?.calenderType
                    } needed<span className="text-red-400">*</span>
                  </>}
                  error={error}
                  prefixValue={item?.rentalUnitType === "bySiUnits" ?
                    item?.siUnitType || "Hour" :
                    item?.calenderType
                  }
                  name={"unitOrdered"}
                  value={unitOrdered}
                  onChange={(e) => setUnitOrdered(e.target.value)}
                />

                <MultipleDatePicker
                  minDate={minimumStartDate}
                  name="startDate"
                  placeholder="Set Start Date"
                  onChange={handleChangeStartDate}
                />
              </>
              : <MultipleDatePicker
                minDate={minimumStartDate}
                name="days"
                placeholder="Set Start & End Date"
                onChange={onChange}
                range
              />
          }

          {
            daysDifferenceError && <p className="text-red-500 text-xs -mt-4 pb-6">{daysDifferenceError}</p>
          }
        </div>


        <button
          disabled={(
            rentalUnitType === "bySiUnits" && !unitOrdered ||
            rentalUnitType === "bySiUnits" && !startDate ||
            (rentalUnitType !== "bySiUnits" && days?.length < 2) ||
            quantityAvailabilityError || daysDifferenceError) && true}
          type="submit"
          className={`w-full text-sm-15 block text-center bg-temp-green-500 bg-gradient focus:outline-none rounded-lg text-white font-semibold py-3 ${(
            rentalUnitType === "bySiUnits" && !unitOrdered ||
            rentalUnitType === "bySiUnits" && !startDate ||
            (rentalUnitType !== "bySiUnits" && days?.length < 2) ||
            quantityAvailabilityError ||
            daysDifferenceError) && "opacity-50"
            }`}
        >
          Check
        </button>

        {
          rentalUnitType !== "bySiUnits" &&
          <p className="text-temp-gray text-sm pt-6">Minimum rental period is <strong>{item?.minimumBookingDays} day</strong></p>
        }

        {checkAvailabilityError && (
          <p className="text-red-500 text-sm pt-6">{checkAvailabilityError}</p>
        )}
      </form>
    </div>
  );
};

export default CheckAvailabilityForm;
