import Actions from "../actions/actionTypes";

const initialState = {
  user: {},
  updateUserStatus: {
    type: "",
    message: "",
  },
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.INITIATE_USER_DETAILS:
      return {
        user: {},
      };
    case Actions.GET_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };

    case Actions.UPDATE_USER:
      return {
        ...state,
        updateUserStatus: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
