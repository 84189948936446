import React from "react";
import { Transition } from "@headlessui/react";

const MessageModal = ({
  show,
  handleCloseModal,
  selectedNotification,
}) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="relative inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <button
            onClick={handleCloseModal}
            className="absolute z-50 top-3 right-6 text-temp-green-500 rounded-full px-3 pt-2 pb-1 focus:outline-none"
            style={{
              background: "#FFFCF4",
              boxShadow: "0px 4.73609px 23.6804px rgba(0, 0, 0, 0.7)",
            }}
          >
            X
          </button>
          <div className="relative bg-white px-5 py-5 sm:p-12">
            <div className="">
              <p className="text-temp-primary text-sm-15 lg:text-base font-bold">
                {selectedNotification?.data?.subject}
              </p>

              <p className="text-temp-primary mt-6 text-sm">
                {selectedNotification?.data?.body}
              </p>
            </div>
            {/* <div className="text-center">
              <Link
                to={selectedNotification?.data?.url}
                className="text-sm lg:text-base text-temp-green-500 text-center focus:outline-none mt-10"
              >
                {selectedNotification?.data?.cta}
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
