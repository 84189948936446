import axios from "axios";
import { history } from "../helpers/History";
import store from "store";
import configureStore from "../store";
import { alertActions } from "../store/actions/alertActions";
import { triggerError } from "../helpers/triggerError";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.get("temp_access_token");
    if (token) config.headers["Authorization"] = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const successHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  const storage = configureStore();
  const status = error.response.status;
  if (status === 401) {
    // store.clearAll();
    storage.dispatch(alertActions.error("Error"));
    triggerError(history);
  }
  return Promise.reject(error);
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default instance;
