/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import store from "store";
import PrivacyIcon from "../../assets/images/svg/policy/privacy.svg";
import RefundIcon from "../../assets/images/svg/policy/refund.svg";
import RentalIcon from "../../assets/images/svg/policy/rental.svg";
import MainContainer from "../../components/layout/MainContainer";
import { scrollToTop } from "../../utils/scrollToTop";
import InsurancePolicy from "./component/InsurancePolicy";
import PrivacyPolicy from "./component/PrivacyPolicy";
import RefundPolicy from "./component/RefundPolicy";
import RentalPolicy from "./component/RentalPolicy";

const Title = ({ title, icon }) => {
  return (
    <div className="pb-10 flex flex-row items-center">
      <div className="">
        <img src={icon || PrivacyIcon} alt="" />
      </div>
      <div className="pl-4">
        <h3 className="font-semibold text-2xl md:text-3xl lg:text-4xl text-temp-green-500 leading-7">
          {title} Policies
        </h3>
      </div>
    </div>
  );
};

const Policy = (props) => {
  const [routedLink, setRoutedLink] = useState("");
  const location = useLocation();

  const [user, setUser] = useState({});

  useEffect(() => {
    if (location?.pathname?.length < 11) {
      props.history.replace("/policy/privacy");
    }
    setUser(store.get("temp_usER12_dATa"));
  }, []);

  useEffect(() => {
    console.log(location);
  }, [location]);

  useEffect(() => {
    const url = props.location.pathname?.slice(8);
    setRoutedLink(url);

    if (!location.hash) scrollToTop();
  }, [props.location.pathname]);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="mb-6 relative container mx-auto px-4 flex flex-wrap flex-row justify-start ">
        <div className="w-full md:w-6/12 lg:w-3/12 sticky top-14 md:top-24 lg:top-32 bg-white py-3 lg:py-4">
          <div className="sticky top-14 md:top-24 lg:top-32 bg-white ">
            <h1 className="text-3xl md:text-4xl xl:text-5xl text-temp-primary mb-4 font-semibold">
              TempOwn Policies
            </h1>
            <div className=" w-full lg:w-10/12 xl:w-8/12 px-4 md:px-8 lg:px-10 pt-0 pb-6 lg:pt-3 lg:pb-12 text-temp-primary text-sm lg:text-sm-15 rental-activity-header rounded-2xl flex flex-row flex-wrap lg:flex-col items-start">
              <Link
                to="/policy/privacy"
                className={`${
                  routedLink === "privacy"
                    ? "policy-active text-temp-green-500 font-medium"
                    : ""
                } pt-5 pr-4`}
              >
                Privacy Policy
              </Link>
              <Link
                to="/policy/refund"
                className={`${
                  routedLink === "refund"
                    ? "policy-active text-temp-green-500 font-medium"
                    : ""
                } pt-5 pr-4`}
              >
                Refund Policy
              </Link>

              <Link
                to="/policy/insurance"
                className={`${
                  routedLink === "insurance"
                    ? "policy-active text-temp-green-500 font-medium"
                    : ""
                } pt-5 pr-4`}
              >
                Insurance Policy
              </Link>

              <Link
                to="/policy/rental"
                className={`${
                  routedLink === "rental"
                    ? "policy-active text-temp-green-500 font-medium"
                    : ""
                } pt-5`}
              >
                Rental Policy
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-9/12 text-temp-primary mx-auto px-4 py-4">
          <div className="w-full lg:w-10/12 pt-2 lg:pt-6 md:pb-4">
            {routedLink === "privacy" ? (
              <Title title={"Privacy"} icon={PrivacyIcon} />
            ) : routedLink === "refund" ? (
              <Title title={"Refund"} icon={RefundIcon} />
            ) : routedLink === "rental" ? (
              <Title title={"Rental"} icon={RentalIcon} />
            ) : routedLink === "insurance" ? (
              <Title title={"Insurance"} icon={PrivacyIcon} />
            ) : (
              ""
            )}

            <div>
              {routedLink === "privacy" ? (
                <PrivacyPolicy />
              ) : routedLink === "refund" ? (
                <RefundPolicy />
              ) : routedLink === "rental" ? (
                <RentalPolicy />
              ) : routedLink === "insurance" ? (
                <InsurancePolicy />
              ) : (
                <>
                  <div className="mb-2">
                    <Title title={"Privacy"} icon={PrivacyIcon} />
                    <PrivacyPolicy />
                  </div>

                  <div className="mb-2">
                    <Title title={"Refund"} icon={RefundIcon} />
                    <RefundPolicy />
                  </div>

                  <div className="mb-2">
                    <Title title={"Insurance"} icon={PrivacyIcon} />
                    <InsurancePolicy />
                  </div>

                  <div className="mb-2">
                    <Title title={"Rental"} icon={RentalIcon} />
                    <RentalPolicy />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default (Policy);
