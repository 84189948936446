import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import AlertModal from "../../components/modals/AlertModal";
import CheckItemAvailabilityModal from "../../components/modals/CheckItemAvailabilityModal";
import LoadingModal from "../../components/modals/LoadingModal";
import * as actions from "../../store/actions";
import CancelledOrRejectedCard from "./components/CancelledOrRejectedCard";
import LeftSectionPendingAndAccepted from "./components/LeftSectionPendingAndAccepted";

const CancelledOrRejectedDetails = ({
  loggedInUserDetails,
  orderDetails,
  itemDetails,
  ...props
}) => {
  const [showItemAvailabilityModal, setShowItemAvailabilityModal] = useState(
    false
  );

  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const handleCheckItemAvailabilityModalShow = () => {
    setShowItemAvailabilityModal(true);
  };

  const [days, setDays] = useState([]);
  const [daysDifferenceError, setDaysDifferenceError] = useState("")
  const handleDaysRangeChange = (dateObjects) => {
    setDaysDifferenceError("")
    setCheckAvailabilityError("");
    setDays(dateObjects.map((dateObject) => dateObject.toDate()));

    const startD = moment(new Date(dateObjects[0]))
    const endD = moment(new Date(dateObjects[1]))
    const dayDifference = endD.diff(startD, "days")
    if (!isNaN(dayDifference) && dayDifference < itemDetails?.minimumBookingDays) {
      setDaysDifferenceError("Please extend the rental period to at least the minimun rental period")
    }
  };


  // To be used for SI UNIT EQUIPMENT TYPE
  const [unitOrdered, setUnitOrdered] = useState("");
  const [startDate, setStartDate] = useState("");
  const [error, setError] = useState("");
  const handleChangeStartDate = (value) => {
    setStartDate(new Date(value));
  }

  const [checkLoading, setCheckLoading] = useState(false);
  const [checkAvailabilityError, setCheckAvailabilityError] = useState("");

  const handleCheckItemAvailability = async (e) => {
    e.preventDefault();

    if (itemDetails?.rentalUnitType === "bySiUnits") {
      if (!unitOrdered) {
        setError("Enter the estimate needed to use the equipment")
      } else {
        setError("")
        setCheckAvailabilityError("");
        setCheckLoading(true);

        const payload = {
          itemId: itemDetails?._id,
          startDate: new Intl.DateTimeFormat("en-US").format(startDate),
          quantity: selectedQuantity,
          unitOrdered: parseInt(unitOrdered || 1)
        };

        await props.checkItemAvailabilityApi(itemDetails?.rentalUnitType, payload, props.history);
        setCheckLoading(false);
      }
    } else {
      const firstDate = new Date(days[0]).getTime();
      const secondDate = new Date(days[1]).getTime();

      if (firstDate < secondDate) {
        setCheckAvailabilityError("");

        setCheckLoading(true);
        const payload = {
          itemId: itemDetails?._id,
          startDate:
            days?.length > 0 && new Intl.DateTimeFormat("en-US").format(days[0]),
          endDate:
            days?.length > 0 && new Intl.DateTimeFormat("en-US").format(days[1]),
          quantity: selectedQuantity
        };

        await props.checkItemAvailabilityApi(itemDetails?.rentalUnitType, payload, props.history);
        setCheckLoading(false);
      } else {
        setCheckAvailabilityError(
          "End Date cannot be on the same day. If you plan to use it for 1 day, select the next date so your order span across the 24hrs"
        );
      }
    }
  };

  const [quantityAvailabilityError, setQuantityAvailabilityError] = useState("")
  const handleChangeIncreaseOrDecrease = (type, value) => {
    setQuantityAvailabilityError("")
    switch (type) {
      case "increment":
        setSelectedQuantity(selectedQuantity + 1 > itemDetails?.totalQuantity ? itemDetails?.totalQuantity : selectedQuantity + 1)
        break;
      case "decrement":
        setSelectedQuantity(selectedQuantity - 1 < 1 ? 1 : selectedQuantity - 1)
        break;
      case "default":
        if (value < 1 || value > itemDetails?.totalQuantity) {
          setQuantityAvailabilityError(`Make sure the quantity entered is between 1 and ${itemDetails?.totalQuantity}.`)
        }
        setSelectedQuantity(value)
        break;
      default:
        break;
    }
  }

  return (
    <div className="my-10 relative flex flex-wrap text-temp-primary">
      <div className="w-full lg:w-5/12 xl:w-6/12 mb-12">
        <LeftSectionPendingAndAccepted
          orderDetails={orderDetails}
          itemDetails={itemDetails}
          loggedInUserDetails={loggedInUserDetails}
        >
          {/* Mobile only */}
          <div className="mb-12 mt-6 block lg:hidden">
            <CancelledOrRejectedCard
              handleCheckItemAvailabilityModalShow={
                handleCheckItemAvailabilityModalShow
              }
              loggedInUserDetails={loggedInUserDetails}
              itemDetails={itemDetails}
              orderDetails={orderDetails}
            />
          </div>
        </LeftSectionPendingAndAccepted>
      </div>

      {/* Desktop only */}
      <div className="hidden lg:block w-full lg:w-6/12 xl:w-5/12 mx-auto px-0 md:px-10 lg:mb-12 mb-6">
        <CancelledOrRejectedCard
          handleCheckItemAvailabilityModalShow={
            handleCheckItemAvailabilityModalShow
          }
          loggedInUserDetails={loggedInUserDetails}
          itemDetails={itemDetails}
          orderDetails={orderDetails}
        />
      </div>

      {showItemAvailabilityModal && (
        <CheckItemAvailabilityModal
          item={itemDetails}
          days={days}
          show={showItemAvailabilityModal}
          itemId={itemDetails?._id}
          onChange={handleDaysRangeChange}
          handleCheckItemAvailability={handleCheckItemAvailability}
          checkAvailabilityError={checkAvailabilityError}
          selectedQuantity={selectedQuantity}
          handleChangeIncreaseOrDecrease={handleChangeIncreaseOrDecrease}
          quantityAvailabilityError={quantityAvailabilityError}
          daysDifferenceError={daysDifferenceError}
          handleCloseModal={() => {
            setCheckAvailabilityError("");
            setShowItemAvailabilityModal(false);
          }}
          title="Check Availability"
          startDate={startDate}
          handleChangeStartDate={handleChangeStartDate}
          unitOrdered={unitOrdered}
          setUnitOrdered={setUnitOrdered}
          error={error}
        />
      )}

      {checkLoading && (
        <LoadingModal
          show={checkLoading}
          title="Checking ..."
          content="Please wait while we check for the availability of the item"
        />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl={"/rental-activities"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(CancelledOrRejectedDetails)
);
