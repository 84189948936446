import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

const options = [
  "all status",
  "accepted",
  "pending",
  "ongoing",
  "completed",
  "canceled",
  "rejected",
  "over-due"
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SelectActivitiesOptions = ({
  selectedOption,
  handleChangeSelectedOption,
}) => {
  return (
    <div className="relative my-8">
      <div className="container mx-auto px-4">
        <Listbox value={selectedOption} onChange={handleChangeSelectedOption}>
          {({ open }) => (
            <>
              <div className="relative">
                <Listbox.Button className="relative w-6/12  md:w-4/12 lg:w-2/12 bg-white border border-temp-light-gray rounded-lg shadow-sm pl-3 pr-12 py-3 text-left cursor-default focus:outline-none text-xs lg:text-sm">
                  <span className="flex items-center">
                    <span className="ml-3 block truncate capitalize text-temp-gray">
                      {selectedOption}
                    </span>
                  </span>
                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 11.5256L14.8313 7.19434L16.0685 8.43159L10.5 14.0001L4.93152 8.43159L6.16877 7.19434L10.5 11.5256Z"
                        fill="#08A05C"
                      />
                    </svg>
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {(ref) => (
                    <Listbox.Options
                      ref={ref}
                      className="absolute z-10 mt-3 w-6/12 md:w-4/12 lg:w-2/12 bg-white shadow-xl space-y-2 pb-4 rounded-lg py-1 text-xs lg:text-sm  overflow-auto focus:outline-none"
                    >
                      {options.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            classNames(
                              active ? "text-temp-green-500" : "text-temp-gray",
                              "cursor-default select-none relative py-2 pl-3 pr-9"
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center capitalize">
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {option}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active
                                      ? "text-temp-green-500"
                                      : "text-temp-gray",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                ></span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  )}
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export default SelectActivitiesOptions;
