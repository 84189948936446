import React from "react";

export const BellIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M36.4791 6.4484e-07C32.2795 6.4484e-07 28.875 3.40448 28.875 7.60412C28.8821 7.96898 28.9158 8.33298 28.9754 8.69299C29.085 9.44651 29.7349 10.0027 30.4963 9.99487C30.6414 9.99473 30.7857 9.9742 30.9251 9.93399C34.5592 8.85881 38.4267 8.85881 42.0606 9.93399C42.4928 10.0409 42.9499 9.95794 43.3169 9.70586C43.6917 9.44951 43.9421 9.04801 44.0074 8.59874C44.062 8.26882 44.0925 7.93547 44.0986 7.60113C44.0969 3.40149 40.691 -0.00171029 36.4914 6.4484e-07C36.4872 6.4484e-07 36.4833 6.4484e-07 36.4791 6.4484e-07Z"
          fill="#F57C00"
        />
        <path
          d="M45.6068 60.8334H27.3568C26.5169 60.8334 25.8359 61.5143 25.8359 62.3543C25.8359 68.2338 30.6022 73.0001 36.4818 73.0001C42.3615 73.0001 47.1277 68.2339 47.1277 62.3543C47.1277 61.5142 46.4469 60.8334 45.6068 60.8334Z"
          fill="#FFA000"
        />
        <path
          d="M64.8331 55.3858C64.3524 54.3243 63.7472 53.138 63.0781 51.83C58.9623 44.8582 56.6187 36.9836 56.2526 28.8959C56.2526 16.8418 49.8651 7.99665 39.9857 6.36327C34.2207 5.43666 28.3443 7.14974 23.9804 11.0291C19.1497 15.246 16.4697 21.4104 16.6804 27.8191C16.5545 36.292 14.2141 44.5843 9.89143 51.8726C9.23742 53.1623 8.64131 54.3364 8.16681 55.3828C6.79606 58.4059 8.13544 61.9679 11.1587 63.3388C11.9471 63.6964 12.8034 63.8793 13.6692 63.8752H59.3428C62.6622 63.8851 65.3612 61.2022 65.371 57.8829C65.3736 57.0219 65.1911 56.1704 64.8359 55.3859H64.8331V55.3858Z"
          fill="#FFC107"
        />
        <path
          d="M16.2004 57.7916C15.3605 57.7916 14.6795 57.1106 14.6797 56.2707C14.6797 56.0342 14.7349 55.8009 14.8408 55.5895L15.3306 54.6284C19.8734 46.7853 22.4345 37.9526 22.7918 28.8959C22.7918 21.1791 26.3779 12.1667 36.4793 12.1667C37.3193 12.1667 38.0002 12.8477 38.0002 13.6876C38.0002 14.5276 37.3193 15.2085 36.4793 15.2085C26.5999 15.2085 25.8334 25.684 25.8334 28.896C25.494 38.4368 22.8171 47.7481 18.0377 56.0125L17.5632 56.9524C17.3048 57.4677 16.7772 57.7928 16.2004 57.7916Z"
          fill="#FFD54F"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="73" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
