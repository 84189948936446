import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const getLoggedInReferralDetailsApi = () => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/referrals`;
  try {
    const response = await axios.get(url);

    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_LOGGEDIN_REFERRAL_DETAILS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error?.response?.data.message));
  }
};

export const getReferredListApi = () => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/referrals/referred`;
  try {
    const response = await axios.get(url);

    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_REFERRED_LIST,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};
