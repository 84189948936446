import React from "react";

const AboutEquipmentCard = ({ icon, name, value }) => {
  return (
    <div className="flex flex-wrap">
      <div className="pb-3 pr-6 lg:w-3/12">
        <img
          src={icon}
          className="h-8 w-8 lg:h-9 lg:w-9 object-contain"
          alt=""
        />
      </div>
      <div className="lg:w-9/12">
        <p className="text-temp-gray pb-2 text-sm lg:text-base">{name}</p>
        <p className="font-bold text-temp-gray text-sm lg:text-base">{value}</p>
      </div>
    </div>
  );
};

export default AboutEquipmentCard;
