import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import MainContainer from "../../components/layout/MainContainer";
import { scrollToTop } from "../../utils/scrollToTop";
import TopSection from "./components/TopSection";
import WhatIsCoveredSection from "./components/WhatIsCoveredSection";
import WhatYouWillGetSection from "./components/WhatYouWillGetSection";

const IempownProtect = () => {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <MainContainer hasMainContainer={false}>
            <Helmet>
                <title>TempOwn | Protect</title>
            </Helmet>

            <TopSection />
            <WhatIsCoveredSection />
            <WhatYouWillGetSection />
        </MainContainer>
    );
};

export default IempownProtect;
