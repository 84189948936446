import Actions from "../actions/actionTypes";

const initialState = {
  submitFeedbackStatus: {},
};

const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SUBMIT_FEEDBACK:
      return {
        ...state,
        submitFeedbackStatus: action.payload,
      };

    default:
      return state;
  }
};

export default ContactReducer;
