import React from "react";
import IncreaseOrDecreaseButton from "./IncreaseOrDecreaseButton";
const ItemCounter = ({ count, handleCount, disabled }) => {
  const handleIncreaseOrDecreaseCount = (type, val) => {
    switch (type) {
      case "increment":
        handleCount("itemCount", count + 1);
        break;
      case "decrement":
        count > 0 && handleCount("itemCount", count - 1);
        break;
      case "default":
        handleCount("itemCount", val);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-row items-center">
      <IncreaseOrDecreaseButton
        title="-"
        disabled={disabled || count < 2}
        onClick={() => handleIncreaseOrDecreaseCount("decrement")}
      />
      <input
        name="itemQuantity"
        value={count}
        type="number"
        readOnly="readonly"
        onChange={(e) =>
          handleIncreaseOrDecreaseCount("default", e.target.value)
        }
        className="h-12 w-16 lg:w-20 text-center focus:outline-none text-md lg:text-base text-temp-gray border border-temp-gray-light rounded-lg"
      />
      <IncreaseOrDecreaseButton
        title="+"
        onClick={() => handleIncreaseOrDecreaseCount("increment")}
        disabled={count > 4}
      />
    </div>
  );
};

export default ItemCounter;
