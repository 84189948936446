import React from 'react';

const SelectButtonWithDescription = ({ title, content, handleClick, selected, icon }) => {
    return (
      <button
        type="button"
        onClick={handleClick}
        className={`upload-item-quantity text-left focus:outline-none py-3 lg:py-4 px-4 lg:px-6 my-6 w-full ${selected ? "bg-temp-green-100 border-temp-green-500" : "bg-transparent border-temp-gray-light"
          } border text-temp-gray text-lg rounded-xl transition-colors duration-200 transform hover:bg-temp-green-100 focus:border-4 focus:border-temp-green-300`}
      >
        <div className="flex text-gray-700 my-2 lg:my-4">
          <div
            className={` ${selected ? "bg-temp-secondary" : "bg-temp-green-100"}  flex cstm-min-w-20 h-10 w-10 justify-center items-center mr-4 md:mr-8  rounded-lg`}
          >
            {icon}
          </div>
          <div>
            <p className="text-temp-primary text-sm md:text-base lg:text-lg  leading-7 font-semibold">
              {title}
            </p>
            <p className="leading-6 pt-1 md:pt-2 text-gray-500 text-xs md:text-sm">
              {content}
            </p>
          </div>
        </div>
      </button>
    );
  };

export default SelectButtonWithDescription;
