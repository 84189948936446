import React from "react";
import EquipmentName from "../../../components/blocks/itemBits/EquipmentName";
import LenderInformationWithViewButton from "../../../components/blocks/itemBits/LenderInformationWithViewButton";
import BorrowerInformationWithViewButton from "../../../components/blocks/itemBits/BorrowerInformationWithViewButton";
import StarRating from "../../../components/blocks/StarRating";
import EquipmentLocation from "../../../components/blocks/itemBits/EquipmentLocation";
import ItemChargesRow from "../../../components/blocks/itemBits/ItemChargesRow";
import EquipmentHowTo from "../../../components/blocks/itemBits/EquipmentHowTo";
import {
  getRentalEndDate,
  getRentalStartDate,
  getRentalTotalAmount,
  getRentalUnitOrderedAndType,
} from "../../../helpers/rentalFlexibilityHelper";

const LeftSectionPendingAndAccepted = ({
  itemDetails,
  orderDetails,
  withEquipmentName = true,
  loggedInUserDetails,
  ...props
}) => {
  return (
    <div className="w-full mb-8">
      {/* Desktop Only */}
      <div className="flex flex-wrap flex-row">
        <div className="w-5/12 md:w-4/12 lg:w-4/12 xl:w-5/12 rounded-xl object-cover">
          <img
            src={itemDetails?.images?.[0]?.image?.url}
            alt={itemDetails?.name}
            className="w-full rounded-xl object-cover"
          />
        </div>
        <div className="w-full md:w-7/12 lg:w-8/12 xl:w-6/12 lg:pl-6 md:pl-8 mt-6 md:mt-0">
          {withEquipmentName && (
            <>
              <div className="">
                <StarRating starDimension="14px" rating={itemDetails?.rating} />
              </div>
              <EquipmentName item={itemDetails} />
              <EquipmentLocation item={itemDetails} className="" />
              <div className="mb-3 text-sm lg:text-sm-15 xl:text-base text-temp-secondary flex justify-between">
                <p className="">Order#</p>
                <p className="pl-1 font-bold">{orderDetails?.orderNumber}</p>
              </div>

              <div className="mt-2">
                <p className="text-temp-primary font-medium mb-3 text-base xl:text-lg">
                  Rental Period
                </p>
                <ItemChargesRow
                  name="Start Date"
                  value={getRentalStartDate(orderDetails, "MMMM D, YYYY")}
                />
                <ItemChargesRow
                  name="End Date"
                  value={getRentalEndDate(orderDetails)}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* Mobile only */}
      <div className="block lg:hidden">
        {/*Disply the card on the right hand size of desktop view here */}
        {props.children}
      </div>

      <div className="w-full lg:w-10/12 xl:w-6/12 mt-12">
        <p className="text-temp-secondary font-medium mb-4 text-base lg:text-lg">
          Booking Details
        </p>
        <ItemChargesRow
          name="Total Fee"
          value={getRentalTotalAmount(orderDetails)}
        />
        <ItemChargesRow
          name="Pickup Day"
          value={getRentalStartDate(orderDetails, "MMMM D, YYYY")}
        />

        <ItemChargesRow
          name="Usage"
          value={getRentalUnitOrderedAndType({
            ...orderDetails,
            itemId: {
              ...itemDetails,
            },
          })}
        />
      </div>

      {/* Mobile only */}
      <div className="block lg:hidden mt-8">
        {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
          <LenderInformationWithViewButton
            className="px-0"
            title="Lender"
            item={itemDetails}
            pickupLocation={orderDetails?.itemId?.pickupLocation}
            pickupContact={orderDetails?.lender?.phone}
          />
        ) : (
          <BorrowerInformationWithViewButton
            className="px-0"
            title="Borrower"
            user={orderDetails?.borrower}
            pickupContact={orderDetails?.lender?.phone}
          />
        )}
      </div>

      <EquipmentHowTo
        orderDetails={orderDetails}
        loggedInUserDetails={loggedInUserDetails}
      />
    </div>
  );
};

export default LeftSectionPendingAndAccepted;
