import React from "react";
import { MoreIcon } from "../../../components/svgs";

const ChatTitleWithOption = () => {
  return (
    <div className="flex flex-row justify-between py-6 px-6">
      <p className="font-semibold text-temp-secondary text-base lg:text-xl">
        Conversations
      </p>
      <div className="">
        <MoreIcon fill="#08A05C" />
      </div>
    </div>
  );
};

export default ChatTitleWithOption;
