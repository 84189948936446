import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import MainContainer from '../../components/layout/MainContainer'
import { scrollToTop } from '../../utils/scrollToTop'
import GetUpdateSection from '../home/components/GetUpdateSection'
import TopSection from './components/TopSection'
import WhitePaperSection from './components/WhitePaperSection'
import WhySection from './components/WhySection'

const EmissionSavings = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <MainContainer hasMainContainer={false}>
            <Helmet>
                <title> TempOwn | Emission Savings</title>
            </Helmet>

            <TopSection />
            <WhitePaperSection />
            <WhySection />

            <GetUpdateSection />
        </MainContainer>
    )
}

export default EmissionSavings