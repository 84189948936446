/* eslint-disable no-unused-vars */
import React from "react";
import ProtectionImage from "../../../assets/images/png/protection.webp";
import SaveThePlanetImage from "../../../assets/images/svg/saving-planet.svg";
import AffordableImage from "../../../assets/images/png/affordable.webp";

import EmissionImage from "../../../assets/images/svg/emission-saving.svg";

import { Link } from "react-router-dom";

const Card = ({ image, title, linkUrl }) => {
  return (
    <div
      className="rounded-xl px-6 py-8 lg:px-8 lg:py-8"
      style={{
        background: `url(${image}) no-repeat, #EFFBF9`,
        backgroundSize: "contain",
        backgroundPosition: "right bottom",
        border: "0.960633px solid #86FEC8",
      }}
    >
      <div className="w-7/12 md:w-5/12 lg:w-5/12">
        <h3 className="text-xl lg:text-2xl font-semibold text-black">
          {title}
        </h3>

        <div className="mt-6 mb-8 md:mb-4 xl:mb-0">
          <Link
            to={linkUrl || "/"}
            className="flex items-center text-temp-secondary text-sm-15 font-medium"
          >
            <span className="pr-3">Read More</span>{" "}
            <svg
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3199 5.43649C12.5458 5.21059 12.5458 4.84434 12.3199 4.61844L8.63865 0.93717C8.41275 0.71127 8.04649 0.71127 7.82059 0.93717C7.59469 1.16307 7.59469 1.52933 7.82059 1.75523L11.0928 5.02746L7.82059 8.2997C7.59469 8.5256 7.59469 8.89186 7.82059 9.11776C8.04649 9.34366 8.41275 9.34366 8.63865 9.11776L12.3199 5.43649ZM0.291992 5.60592L11.9109 5.60592L11.9109 4.44901L0.291992 4.44901L0.291992 5.60592Z"
                fill="#08A05C"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

const FlexibleCard = () => {
  return (
    <div
      className="rounded-xl px-6 py-8 lg:px-8 lg:py-8"
      style={{
        background: `url(${AffordableImage}) no-repeat, #FFF2C4`,
        backgroundSize: "35%",
        backgroundPosition: "right 1.4rem bottom",
        border: "0.771273px solid #FFC439",
      }}
    >
      <Link to="/about">
        <div className="w-7/12 mb-16 md:mb-20 xl:mb-auto">
          <h3 className="text-xl lg:text-2xl font-semibold text-black">
            Flexible & affordable rentals options
          </h3>

          <div className="mt-8"></div>
        </div>
      </Link>
    </div>
  );
};

const WhatWeAreDoingSection = () => {
  return (
    <section className="relative py-20 lg:py-28">
      <div className="container px-4 mx-auto">
        <div className="w-full mx-auto">
          <h2 className="font-semibold text-temp-primary text-3xl lg:text-4xl xl:text-5xl xl:leading-tight w-10/12 lg:w-6/12">
            What we are doing differently at{" "}
            <span className="text-temp-secondary">
              temp<span className="font-bold">own</span>
            </span>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mt-10 lg:mt-14">
            <Card
              image={ProtectionImage}
              title="Providing adequate protection"
              linkUrl="/tempown-protect"
            />
            <Card
              image={SaveThePlanetImage}
              title="Saving the planet"
              linkUrl="/profile/emission-savings"
            />

            <FlexibleCard />
          </div>

          <div className="w-full mt-8 lg:mt-14">
            <div
              className="rounded-xl px-6 py-10 lg:px-12 lg:py-12 xl:py-20"
              style={{
                background: `url(${EmissionImage}) no-repeat, #FEFDF5`,
                backgroundSize: "cover",
                backgroundPosition: "center bottom",
              }}
            >
                <div className="">
                    <p className="text-xs lg:text-sm text-temp-gray mb-2">{new Date()?.toDateString()}</p>

                    <div className="text-center flex flex-col justify-center pb-32 mt-4 lg:mt-0">
                        <p className="text-base lg:text-lg uppercase">Total Emission saved</p>
                        <p className="pt-3 pb-2 font-semibold text-black text-5xl lg:text-6xl">1004</p>
                        <p className="text-temp-secondary font-medium text-xl lg:text-2xl">kgCO<sub>2</sub></p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeAreDoingSection;
