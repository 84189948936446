import { useEffect } from "react";
import io from "socket.io-client";
import { toastPopUp } from "../../components/popup/toastPopUp";
import configureStore from "../../store";
import store from "store";
import { orderUpdate } from "../../store/actions";

export const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
  transports: ["websocket"],
});


const useSocketNotification = () => {
  const configStore = configureStore();
  //Real-Time Notification using Socket.io
  useEffect(() => {
    const user = store.get("temp_usER12_dATa");
    socket.on("connect", () => {
      console.log("successful", socket.connected);
    });

    // Notification
    socket.on("notification", (data) => {
      console.log("connected...", data);
      if (socket.connected) {
        if (user?._id === data?.user?._id) {
          const others = {
            cta: data?.cta,
            url: data?.url,
            user: data?.user,
          };

          toastPopUp(data?.subject || "New Notification", data?.body, others);
        }
      }
    });

    // Order Process
    // verify confirm pickup-code notification
    socket.on("verify-confirm-pickup-code", (data) => {
      console.log("connected...", data);
      if (socket.connected) {
        // if (user?._id === data?.borrower?._id || user?._id === data?.lender?._id) {
          configStore.dispatch(orderUpdate(data.order))
        // }
      }
    });

    // confirm pickup notification
    socket.on("confirm-pickup", (data) => {
      if (socket.connected) {
        // if (user?._id === data?.borrower?._id || user?._id === data?.lender?._id) {
           configStore.dispatch(orderUpdate(data.order))
        // }
      }
    });

    // verify confirm return-code notification
    socket.on("verify-confirm-return-code", (data) => {
      if (socket.connected) {
        // if (user?._id === data?.borrower?._id || user?._id === data?.lender?._id) {
           configStore.dispatch(orderUpdate(data.order))
        // }
      }
    });

    // confirm return notification
    socket.on("confirm-return", (data) => {
      if (socket.connected) {
        // if (user?._id === data?.borrower?._id || user?._id === data?.lender?._id) {
           configStore.dispatch(orderUpdate(data.order))
        // }
      }
    });
    // disconnect socket once unmounted
    return () => {
      socket.on("disconnect", () => {
        console.log(socket.connected); // undefined
      });
    };
  }, []);
};

export default useSocketNotification;
