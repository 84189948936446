import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import store from "store";
import AlertModal from "../../components/modals/AlertModal";
import AlertModal2 from "../../components/modals/AlertModal2";
import CheckItemAvailabilityModal from "../../components/modals/CheckItemAvailabilityModal";
import LoadingModal from "../../components/modals/LoadingModal";
import OrderComplaintFormModal from "../../components/modals/OrderComplaintFormModal";
import { compressImage } from "../../helpers/compressImage";
import * as actions from "../../store/actions";
import ConfirmReturnEnterCodeModal from "./components/ConfirmReturnEnterCodeModal";
import ConfirmReturnModal from "./components/ConfirmReturnModal";
import LeftSectionOngoing from "./components/LeftSectionOngoing";
import OngoingCard from "./components/OngoingCard";
import OngoingOrderReviewForm from "./components/OngoingOrderReviewForm";

const OngoingDetails = ({
  loggedInUserDetails,
  orderDetails,
  itemDetails,
  ...props
}) => {
  useEffect(() => {
    store.remove("extEND_bool1");
    store.remove("extEND_orderId");
  }, [])

  const [showReturnModal, setShowReturnModal] = useState(false);
  const [
    showNotConfirmedErrorModalToLender,
    setShowNotConfirmedErrorModalToLender,
  ] = useState(false);
  const [showEnterCodeModal, setShowEnterCodeModal] = useState(false);

  const handleShowModal = () => {
    if (orderDetails?.confirmReturn?.tokenConfirmed) {
      setShowReturnModal(!showReturnModal);
    } else {
      setShowNotConfirmedErrorModalToLender(
        !showNotConfirmedErrorModalToLender
      );
    }
  };

  const handleShowEnterCodeModal = () => {
    setShowEnterCodeModal(!showEnterCodeModal);
  };

  const [picture, setPicture] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [isItemInGoodCondition, setIsItemInGoodCondition] = useState(true);
  const [errors, setErrors] = useState("");
  const [itemReturnDate, setItemReturnDate] = useState("")

  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const [showItemAvailabilityModal, setShowItemAvailabilityModal] = useState(
    false
  );
  const handleCheckItemAvailabilityModalShow = () => {
    setShowItemAvailabilityModal(true);
  };

  const [days, setDays] = useState([]);
  const [daysDifferenceError, setDaysDifferenceError] = useState("")
  const handleDaysRangeChange = (dateObjects) => {
    setDaysDifferenceError("")
    setCheckAvailabilityError("");
    setDays(dateObjects.map((dateObject) => dateObject.toDate()));

    const startD = moment(new Date(dateObjects[0]))
    const endD = moment(new Date(dateObjects[1]))
    const dayDifference = endD.diff(startD, "days")
    if (!isNaN(dayDifference) && dayDifference < itemDetails?.minimumBookingDays) {
      setDaysDifferenceError("Please extend the rental period to at least the minimun rental period")
    }
  };

    // To be used for SI UNIT EQUIPMENT TYPE
    const [unitOrdered, setUnitOrdered] = useState("");
    const [startDate, setStartDate] = useState("");
    const [error, setError] = useState("");
    const handleChangeStartDate = (value) => {
      setStartDate(new Date(value));
    }

  const [checkLoading, setCheckLoading] = useState(false);
  const [checkAvailabilityError, setCheckAvailabilityError] = useState("");

  const handleCheckItemAvailability = async (e) => {
    e.preventDefault();
    const firstDate = new Date(days[0]).getTime();
    const secondDate = new Date(days[1]).getTime();

    if (itemDetails?.rentalUnitType === "bySiUnits") {
      if (!unitOrdered) {
        setError("Enter the estimate needed to use the equipment")
      } else {
      store.set("extEND_bool1", true)
      store.set("extEND_orderId", orderDetails._id)

        setError("")
        setCheckAvailabilityError("");
        setCheckLoading(true);

        const payload = {
          orderId: orderDetails?._id,
          itemId: itemDetails?._id,
          startDate: new Intl.DateTimeFormat("en-US").format(startDate),
          quantity: selectedQuantity,
          unitOrdered: parseInt(unitOrdered || 1),
          isExtendOrder: true,
        };

        await props.checkItemAvailabilityApi(
          itemDetails?.rentalUnitType,
          payload,
          props.history,
          true,
          true,
          orderDetails._id
        );
        setCheckLoading(false);
      }
    } else {
    if (firstDate < secondDate) {
      setCheckAvailabilityError("");

      store.set("extEND_bool1", true)
      store.set("extEND_orderId", orderDetails._id)

      setCheckLoading(true);
      const payload = {
        orderId: orderDetails?._id,
        itemId: itemDetails?._id,
        startDate:
          days?.length > 0 && new Intl.DateTimeFormat("en-US").format(days[0]),
        endDate:
          days?.length > 0 && new Intl.DateTimeFormat("en-US").format(days[1]),
        isExtendOrder: true,
        quantity: selectedQuantity
      };

      await props.checkItemAvailabilityApi(
        itemDetails?.rentalUnitType,
        payload,
        props.history,
        true,
        true,
        orderDetails._id
      );


      setCheckLoading(false);
    } else {
      setCheckAvailabilityError(
        "End Date cannot be on the same day. If you plan to use it for 1 day, select the next date so your order span across the 24hrs"
      );
    }
  }
  };

  const [quantityAvailabilityError, setQuantityAvailabilityError] = useState("")
  const handleChangeIncreaseOrDecrease = (type, value) => {
    setQuantityAvailabilityError("")
    switch (type) {
      case "increment":
        setSelectedQuantity(selectedQuantity + 1 > itemDetails?.totalQuantity ? itemDetails?.totalQuantity : selectedQuantity + 1)
        break;
      case "decrement":
        setSelectedQuantity(selectedQuantity - 1 < 1 ? 1 : selectedQuantity - 1)
        break;
      case "default":
        if (value < 1 || value > itemDetails?.totalQuantity) {
          setQuantityAvailabilityError(`Make sure the quantity entered is between 1 and ${itemDetails?.totalQuantity}.`)
        }
        setSelectedQuantity(value)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    const extension = orderDetails?.extensions
    const daysList = extension?.length > 0 ? extension[extension?.length - 1]?.days : orderDetails?.days
    setItemReturnDate(new Date(daysList[daysList?.length - 1]))
  }, [orderDetails]);

  // For Borrowers to Confirm Pickup Start by uploading pickup during pickup
  // START--------------------------------------
  //   Upload profile picture from file
  const handleProfilePictureUpload = () => {
    $("#avatar_file").click();
    $("#avatar_file").change(function (e) {
      e.preventDefault();
      profilePhoto(this);
    });
  };

  function profilePhoto(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);

      // compress image
      compressImage([input.files[0]], (data) => {
        setPicture(data);
      });

      setErrors("");
    }
  }

  const handleDeleteImage = () => {
    setSelectedImage("");
    setPicture("");
  };

  const [loadingProcess, setLoadingProcess] = useState(false);

  const handleChangeItemInGoodCondition = () => {
    setIsItemInGoodCondition(!isItemInGoodCondition);
    setErrors({
      ...errors,
      isItemInGoodCondition: "",
    });
  };

  const handleProcessConfirmReturn = async (e) => {
    e.preventDefault();

    if (selectedImage.length === 0) {
      setErrors({
        ...errors,
        selectedImage: "Kindly take a picture of the item",
      });
    } else if (!isItemInGoodCondition) {
      setErrors({
        ...errors,
        isItemInGoodCondition: "Required",
      });
    }
    setLoadingProcess(true);

    const formData = new FormData();

    formData.append("images", picture);
    formData.set("comment", null);
    await props.confirmOrderReturnApi(
      orderDetails?._id,
      formData,
      false,
      orderDetails,
      loggedInUserDetails?._id === orderDetails?.borrower?._id ? itemDetails?.userId?._id : orderDetails?.borrower?._id);

    setLoadingProcess(false);
  };

  useEffect(() => {
    if (props.confirmReturnStatus === "success") {
      setShowReturnModal(false);
      setShowEnterCodeModal(false);
      setShowNotConfirmedErrorModalToLender(false);
    }
  }, [props.confirmReturnStatus]);

  // END -------------------------------------------

  // For Lenders to Confirm Pickup with Code
  // START--------------------------------------
  const [codeError, setCodeError] = useState("");
  const [returnCodeSubmitLoading, setReturnCodeSubmitLoading] = useState(false);
  const [code, setCode] = useState([
    {
      id: 1,
      value: "",
    },
    {
      id: 2,
      value: "",
    },
    {
      id: 3,
      value: "",
    },
    {
      id: 4,
      value: "",
    },
  ]);

  const processSubmitReturnCode = async (e) => {
    e.preventDefault();

    let data = "";

    code.map((a) => (data += a.value));

    const payload = {
      token: parseInt(data),
    };
    if (data?.length < 4) {
      setCodeError("Invalid Code. Make sure you enter the correct code.");
      return;
    }

    setReturnCodeSubmitLoading(true);
    await props.confirmOrderReturnApi(
      orderDetails?._id,
      payload,
      true,
      orderDetails
    );
    setReturnCodeSubmitLoading(false);
  };

  const handleCodeInputChange = (e, index) => {
    let d = code[index];
    d["value"] = e.target.value;
    code[index] = d;
    setCode([...code]);

    if (e.target.value) {
      $(`.code-input${index + 1}`).focus();
    }

    setCodeError("");
  };

  const handleCodeOnKeyUp = (e, key) => {
    const d = code[key];

    var keyId = e.keyCode;
    switch (keyId) {
      // enter
      case 13:
        $(`.code-input${key + 1}`).focus();
        break;

      // Backspace
      case 8:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;

      // Delete
      case 46:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;
      default:
        break;
    }
  };
  // END -------------------------------------------

  // REVIEW -------------------------------------
  // START --------------------------------------
  const [rating, setRating] = useState(0);
  const [ratingTag, setRatingTag] = useState("");
  const [itemRating, setItemRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewEmptyError, setReviewEmptyError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setReviewEmptyError("");
    setReview(e.target.value);
  };

  const handleOnClickRating = (newRating) => {
    setRating(newRating);

    // set to default
    setRatingTag("");
  };
  const handleOnChangeRatingTag = (val) => {
    setRatingTag(val);
  };
  const handleOnClickItemRating = (newRating) => {
    setItemRating(newRating);
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    if (review.length > 0) {
      setReviewEmptyError("");
      setLoading(true);

      const userReviewPayload = {
        ratedUser: orderDetails?.lender?._id,
        rating,
        review: "test",
        tag: ratingTag,
      };

      const itemReviewPayload = {
        itemId: itemDetails?._id,
        rating: itemRating,
        review,
      };

      await Promise.all([
        rating && (await props.createReviewApi(userReviewPayload, true)),
        await props.createReviewApi(itemReviewPayload),
      ]);

      setLoading(false);
    } else {
      setReviewEmptyError("Review is required");
    }
  };

  useEffect(() => {
    if (props.createReviewStatus?.type === "success") {
      setRating(0);
      setItemRating(0);
      setReview("");
      setRatingTag("");
      setLoading(false);
    }
  }, [props.createReviewStatus?.type]);

  const [showComplaintForm, setShowComplaintForm] = useState(false);
  const handleShowComplaintForm = () => {
    setShowComplaintForm(true)
  }

  const handleCloseComplaintModal = () => {
    setShowComplaintForm(false)
  }

  const processSubmitComplaint = async (payload, images) => {
    try {
      const resp = await props.createOrderComplaintFeedbackApi({
        ...payload,
        orderId: orderDetails?._id
      })
      if (resp._id) {
        const filterImages = images?.filter((a) => a !== "");
        const formData = new FormData();
        for (const key in filterImages) {
            if (Object.hasOwnProperty.call(filterImages, key)) {
                formData.append("images", filterImages[key]);
            }
        }
        await props.uploadOrderComplaintFeedbackImageApi(resp?._id, formData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(props.alert?.type === "success"){
        setShowComplaintForm(false)
    }
  }, [props.alert]);
  

  return (
    <div className="my-10 relative flex flex-wrap text-temp-primary">
      <div className="w-full lg:w-5/12 xl:w-6/12 mb-12">
        <LeftSectionOngoing
          orderDetails={orderDetails}
          itemDetails={itemDetails}
          loggedInUserDetails={loggedInUserDetails}
        >
          {/* Mobile only */}
          <div className="mb-12 mt-6 block lg:hidden">
            <OngoingCard
              itemDetails={itemDetails}
              orderDetails={orderDetails}
              handleCheckItemAvailabilityModalShow={
                handleCheckItemAvailabilityModalShow
              }
              handleShowReturnModal={handleShowModal}
              loggedInUserDetails={loggedInUserDetails}
              handleShowEnterCodeModal={handleShowEnterCodeModal}
              handleShowComplaintForm={handleShowComplaintForm}
            />
          </div>
        </LeftSectionOngoing>

        {loggedInUserDetails?._id === orderDetails?.borrower?._id && (
          <OngoingOrderReviewForm
            rating={rating}
            ratingTag={ratingTag}
            itemRating={itemRating}
            handleOnClickRating={handleOnClickRating}
            handleOnChangeRatingTag={handleOnChangeRatingTag}
            handleOnClickItemRating={handleOnClickItemRating}
            review={review}
            reviewEmptyError={reviewEmptyError}
            handleInputChange={handleInputChange}
            handleSubmitReview={handleSubmitReview}
            loading={loading}
            itemDetails={itemDetails}
            orderDetails={orderDetails}
            loggedInUserDetails={loggedInUserDetails}
          />
        )}
      </div>

      {/* Desktop only */}
      <div className="hidden lg:block w-full lg:w-6/12 xl:w-5/12 mx-auto px-0 md:px-8 mb-6 lg:mb-12 ">
        <OngoingCard
          itemDetails={itemDetails}
          orderDetails={orderDetails}
          handleCheckItemAvailabilityModalShow={
            handleCheckItemAvailabilityModalShow
          }
          handleShowReturnModal={handleShowModal}
          loggedInUserDetails={loggedInUserDetails}
          handleShowEnterCodeModal={handleShowEnterCodeModal}
          handleShowComplaintForm={handleShowComplaintForm}
        />
      </div>

      {showItemAvailabilityModal && (
        <CheckItemAvailabilityModal
          item={itemDetails}
          days={days}
          show={showItemAvailabilityModal}
          itemId={itemDetails?._id}
          onChange={handleDaysRangeChange}
          handleCheckItemAvailability={handleCheckItemAvailability}
          checkAvailabilityError={checkAvailabilityError}
          selectedQuantity={selectedQuantity}
          handleChangeIncreaseOrDecrease={handleChangeIncreaseOrDecrease}
          quantityAvailabilityError={quantityAvailabilityError}
          daysDifferenceError={daysDifferenceError}
          handleCloseModal={() => {
            setCheckAvailabilityError("");
            setShowItemAvailabilityModal(false);
          }}
          showCancelButton={true}
          labelName="Check Availability"
          title="Extend Usage"
          itemReturnDate={itemReturnDate}
          startDate={startDate}
          handleChangeStartDate={handleChangeStartDate}
          unitOrdered={unitOrdered}
          setUnitOrdered={setUnitOrdered}
          error={error}
        />
      )}

      {checkLoading && (
        <LoadingModal
          show={checkLoading}
          title="Checking ..."
          content="Please wait while we check for the availability of the item"
        />
      )}

      {showReturnModal && (
        <ConfirmReturnModal
          show={showReturnModal}
          onClose={() => setShowReturnModal(false)}
          handleCloseModal={() => setShowReturnModal(false)}
          picture={picture}
          selectedImage={selectedImage}
          isItemInGoodCondition={isItemInGoodCondition}
          errors={errors}
          handleProfilePictureUpload={handleProfilePictureUpload}
          handleDeleteImage={handleDeleteImage}
          handleChangeItemInGoodCondition={handleChangeItemInGoodCondition}
          loadingProcess={loadingProcess}
          handleProcessConfirmReturn={handleProcessConfirmReturn}
        />
      )}

      {showEnterCodeModal && (
        <ConfirmReturnEnterCodeModal
          show={showEnterCodeModal}
          onClose={() => setShowEnterCodeModal(false)}
          handleCloseModal={() => setShowEnterCodeModal(false)}
          processSubmitReturnCode={processSubmitReturnCode}
          returnCodeSubmitLoading={returnCodeSubmitLoading}
          code={code}
          handleCodeInputChange={handleCodeInputChange}
          handleCodeOnKeyUp={handleCodeOnKeyUp}
          codeError={codeError}
        />
      )}

      {showNotConfirmedErrorModalToLender && (
        <AlertModal
          title="Opps..."
          show={showNotConfirmedErrorModalToLender}
          type="error"
          content={`You cannot confirm return at the moment. 
          Please give the return code to the Borrower. Once it is confirmed, 
          you will be able to confirm return.`}
          onClose={() => setShowNotConfirmedErrorModalToLender(false)}
          linkTitle="Pay Outstanding"
          linkUrl={props?.alert?.message === "Please complete all outstanding payments before you make a new booking." ? "/late-rental" : ""}
        />
      )}

      {
        showComplaintForm && <OrderComplaintFormModal
          show={showComplaintForm}
          handleCloseModal={handleCloseComplaintModal}
          processSubmitComplaint={processSubmitComplaint}
        />
      }

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl="/rental-activities"
        />
      )}

      {/* create review alert message */}
      {props.createReviewStatus?.type && (
        <AlertModal2
          show={props.createReviewStatus.type ? true : false}
          type={props.createReviewStatus.type}
          content={props.createReviewStatus.message}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  confirmReturnStatus: state.order.confirmReturnStatus,
  alert: state.alert,
  createReviewStatus: state.review.createReviewStatus,
});

export default (connect(mapStateToProps, actions)(OngoingDetails));
