import React from 'react';
import EnergyIcon from "../assets/images/png/category/energy.webp";
import MediaIcon from "../assets/images/png/category/media.webp";
import AgricIcon from "../assets/images/png/category/agriculture.webp";
import ConstructionIcon from "../assets/images/png/category/construction.webp";
import GeneralIcon from "../assets/images/png/category/general.webp";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Card = ({ icon, title, url }) => {
    return (
        <div className="mb-6 category-icon-card bg-temp-green-100 rounded-xl">
            <Link
                to={url || "/"}
                className=""
            >
                <div className="w-full">
                    <img src={icon} alt="" />
                </div>
                <p className="pt-3 pb-3 pl-4 lg:pt-4 lg:pb-4 text-sm lg:text-sm-15 text-left font-medium">{title}</p>
            </Link>
        </div>

    );
};

const BrowseOtherCategories = () => {
    return <div className="w-full lg:w-9/12 mx-auto">
        <h3 className="text-temp-primary font-semibold text-base md:text-lg lg:text-xl text-center">Browse by category</h3>
        <div className="mt-4  mx-auto  browse-catergory-list">
            <OwlCarousel
                className="owl-carousel owl-theme"
                items={5}
                loop={true}
                nav={false}
                dots={false}
                margin={25}
                stageClass="pt-5"
                responsiveClass={true}
                responsive={{
                    0: {
                        items: 2,
                        nav: false,
                        margin: 15,
                    },
                    600: {
                        items: 4,
                        nav: false,
                        margin: 20,
                    },
                    1000: {
                        items: 5,
                        nav: false,
                        loop: false,
                        margin: 25,
                    },
                }}
            >
                <Card
                    icon={EnergyIcon}
                    title="Energy"
                    url={"/category/?name=energy"}
                />
                <Card
                    icon={MediaIcon}
                    title="Media"
                    url={"/category/?name=media"}
                />
                <Card
                    icon={AgricIcon}
                    title="Agriculture"
                    url={"/category/?name=agriculture"}
                />
                <Card
                    icon={ConstructionIcon}
                    title="Construction"
                    url={"/category/?name=construction"}
                />
                <Card
                    icon={GeneralIcon}
                    title="General"
                    url={"/category/?name=general"}
                />
            </OwlCarousel>
        </div>
    </div>
};

export default BrowseOtherCategories;
