import { getFirestore } from "firebase/firestore";
import store from "store";
import { firebaseApp } from "../../helpers/firebaseConn";
import Actions from "./actionTypes";
import { alertActions } from "./alertActions";

const db = getFirestore(firebaseApp)

const storeUserData = (userId, user) => {
  return {
    userId: userId,
    firstName: user.firstname,
    lastName: user.lastname,
    accountCreatedAt: user.createdAt,
    profilePicture: user.image,
    userType: user.verifiedLender ? "lender" : "borrower",
    createdAt: new Date(),
    isOnline: true,
  };
};



// Step 1
const createNewAccount = async (userId, user) => {
 
  // Step 1: Create Reciever Account if it doesnt exist
  const responseData = await db
    .collection("users")
    .doc(userId)
    .set(storeUserData(userId, user))
    .then(() => {
      //successful
      const createdAccount = storeUserData(userId, user);
      return createdAccount;
    })
    .catch((error) => error);

  return responseData;
};

// Step 2
const addRecieverOrSenderToFriendlist = async (userId, user) => {
  return user;
};

// GUIDELINE HERE
// STEP 1: CREATE ACCOUNT FOR RECIEVER
// STEP 2: ADD RECIEVER TO LOGGED IN FRIENDLIST
// STEP 3: ADD SENDER TO RECIEVER FRIENDLIST
// STEP 4: GO TO MESSAGE SCREEN AFTER COMPLETED
export const redirectToChatPageWithUserDataApi = async (data, history) => {
 

  // First, get the logged in user information
  const loggedInUser = store.get("temp_usER12_dATa");
  const userData = {
    userId: loggedInUser?._id,
    firstName: loggedInUser?.firstname,
    lastName: loggedInUser?.lastname,
    accountCreatedAt: loggedInUser?.createdAt || new Date(),
    profilePicture: loggedInUser?.image || "",
    userType: loggedInUser?.verified_lender ? "lender" : "borrower",
    createdAt: new Date(),
    isOnline: true,
  };

  // step1: Create account for reciever
  const resp = await createNewAccount(data?._id, data);

  if (resp) {
    // step2: add reciever or sender to friendlist for either the sender or reciever
    // for sender
    const senderFriendList = await addRecieverOrSenderToFriendlist(
      userData?.userId,
      resp
    );

    //for reciever
    const recieverFriendList = await addRecieverOrSenderToFriendlist(
      resp?.userId,
      userData
    );

    // update friendlist
    await db
      .collection("friendList")
      .doc(userData?.userId)
      .set(senderFriendList)
      .then(() => {
        // update reciever friendlist
        db.collection("friendList")
          .doc(resp?.userId)
          .set(recieverFriendList)
          .then(() => {
            history.push("/messages");
          });
      })
      .catch((error) => console.log(error));
  }
};

export const getRealtimeFirebaseChatUsersApi = (userId) => async (dispatch) => {
  dispatch(alertActions.clear());
 
  const unsubscribe = await db
    .collection("friendList")
    .doc(userId)
    .get()
    .then((resp) => {
      dispatch({
        type: `${Actions.GET_REALTIME_FIREBASE_CHAT_USERS}`,
        payload: resp.data() ? [resp.data()] : [],
      });
    });

  return unsubscribe;
};

export const sendChatMessageApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

 
  db.collection("conversations")
    .add({
      ...payload,
      isView: false,
      createdAt: new Date(),
    })
    .then((data) => data
      // {
      //   dispatch({
      //     type: Actions.STORE_CHAT_SENT_MESSAGE,
      //     payload: payload
      //   })
      // }
    )
    .catch((error) => error);
};

export const getRealtimeConversationsApi = (user) => async (dispatch) => {
  dispatch(alertActions.clear());

 
  db.collection("conversations")
    .where("user_uid_1", "in", [user.uid_1, user.uid_2])
    .orderBy("createdAt", "asc")
    .onSnapshot((querySnapshot) => {
      const conversations = [];
      querySnapshot.forEach((doc) => {
        if (
          (doc.data().user_uid_1 == user.uid_1 &&
            doc.data().user_uid_2 == user.uid_2) ||
          (doc.data().user_uid_1 == user.uid_2 &&
            doc.data().user_uid_2 == user.uid_1)
        ) {
          conversations.push(doc.data());
        }
      });

      dispatch({
        type: Actions.GET_REALTIME_MESSAGES,
        payload: conversations,
      });
    });

};

export const getRealtimeConversationsListApi = (userId) => async (dispatch) => {
  dispatch(alertActions.clear());

 
  await db
    .collection("conversations")
    .orderBy("createdAt", "asc")
    .onSnapshot((querySnapshot) => {
      const conversationsList = [];

      querySnapshot.forEach((doc) => {
        conversationsList.push(doc.data());
      });

      const filteredList = conversationsList?.filter(
        (a) => a?.user_uid_1 === userId || a?.user_uid_2 === userId
      );

      const reducedList = filteredList?.reduce((acc, cum) => {
        const found = acc.find(
          (a) =>
            a?.user_uid_1 == cum?.user_uid_1 ||
            a?.user_uid_1 == cum?.user_uid_2 ||
            a?.user_uid_2 == cum?.user_uid_1 ||
            a?.user_uid_2 == cum?.user_uid_2
        );

        const messages = {
          createdAt: cum.createdAt,
          isView: cum.isView,
          message: cum.message,
          user_uid_1: cum.user_uid_1,
          user_uid_2: cum.user_uid_2,
        };

        if (found) {
          found.content = [...found.content, messages];
        } else {
          acc.push({
            user_uid_1: cum?.user_uid_1,
            content: [messages],
          });
        }

        return acc;
      }, []);

      dispatch({
        type: Actions.GET_REALTIME_MESSAGES_FOR_ALL_USERS,
        payload: reducedList,
      });
    });
};

export const clearConversation = async (user2Id) => {
 

  const loggedInUser = store.get("temp_usER12_dATa");
  const userId = loggedInUser?._id

  let conversation_query = await db.collection('conversations').where("user_uid_1", "in", [userId, user2Id]);
  // STORE MESSAGE IN ANOTHER COLLECTION
  conversation_query.get().then(function (querySnapshot) {
    const conversations = [];
    querySnapshot.forEach(function (doc) {
      conversations.push(doc.data());
    });
    db.collection("completedConversations")
      .add({
        [userId + "&" + user2Id]: conversations
      })
      .then((data) => data)
      .catch((error) => error);
  });

  // Delete message
  conversation_query.get().then(function (querySnapshot) {
    querySnapshot.forEach(function (doc) {
      doc.ref.delete();
    });
  });

  // Delete from friendlist
  await db.collection("friendList").doc(userId).delete().then(async () => {
    await db.collection("friendList").doc(user2Id).delete().then(() => {
      console.log("delete successfully")
    }).catch(error => console.log(error))
  }).catch(error => console.log(error))
}


export const getLatestChatMessage = (userId) => async dispatch => {
 
  db.collection("conversations")
    .where("user_uid_2", "in", [userId])
    .orderBy("createdAt", "asc")
    .limit(1)
    .onSnapshot((querySnapshot) => {
      const conversations = [];
      querySnapshot.forEach((doc) => {
       
        conversations.push(doc.data());
      });

      dispatch({
        type: Actions.STORE_CHAT_SENT_MESSAGE,
        payload: conversations[0]
      })
    });
}