import { Transition } from "@headlessui/react";
import React from "react";
import { WarningIcon } from "../svgs";

const DeleteModal = ({
  show,
  title,
  description,
  handleClose,
  handleDelete,
  deleteLoading,
}) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="py-3 pb-3 w-full mx-auto">
                <WarningIcon />
              </div>
              <div className="mt-2 text-center">
                <h3
                  className="leading-6 text-2xl md:text-3xl font-medium text-temp-secondary font-serif"
                  id="modal-headline"
                >
                  {title || "Delete Item"}
                </h3>
                <div className="my-5">
                  <p className="text-sm md:text-base font-sans text-temp-gray">
                    {description ||
                      "Are you sure you want to delete this item?"}
                  </p>
                  <div className="mt-5 inline-flex flex-wrap space-x-4">
                    <button
                      onClick={handleDelete}
                      disabled={deleteLoading}
                      className={`h-12 px-9 bg-temp-green-500 bg-gradient text-white rounded-lg focus:outline-none ${
                        deleteLoading ? "opacity-50" : ""
                      }`}
                    >
                      {deleteLoading ? "Deleting..." : "Yes, Delete"}
                    </button>
                    <button
                      onClick={handleClose}
                      className={`h-12 px-9 bg-white border text-red-600 border-red-600 font-semibold rounded-lg focus:outline-none ${
                        deleteLoading ? "opacity-50" : ""
                      }`}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
