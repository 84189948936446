/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import store from "store";
import AddressInputAutoComplete from "../../components/element/AddressInputAutoComplete";
import InputGroup3 from "../../components/element/InputGroup3";
import InputPhoneNumberGroup2 from "../../components/element/InputPhoneNumberGroup2";
import ProfilePictureUpload from "../../components/element/ProfilePictureUpload";
import TextAreaGroup from "../../components/element/TextAreaGroup";
import MainContainer from "../../components/layout/MainContainer";
import AlertModal2 from "../../components/modals/AlertModal2";
import LoadingModal from "../../components/modals/LoadingModal";
import { compressImage } from "../../helpers/compressImage";
import * as actions from "../../store/actions";
import ProfileHeader from "./components/ProfileHeader";

const AccountInformation = (props) => {
    const userData = store.get("temp_usER12_dATa");
    const [userDetails, setUserDetails] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        bio: "",
        address: "",
        image: "",
    });
    const [loading, setLoading] = useState(false);

    const [uploadedProfilePicture, setUploadedProfilePicture] = useState("");

    const [image, setImage] = useState("");

    const fetchData = async () => {
        setLoading(true);
        await props.getUserApi();
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setUserDetails({
            ...props?.user,
        });
    }, [props?.user]);

    const validate = (values) => {
        const errors = {};
        if (!values.firstname) {
            errors.firstname = "Required";
        } else if (values.firstname.length < 1) {
            errors.firstName = "Must be a valid name";
        }

        if (!values.lastname) {
            errors.lastname = "Required";
        } else if (values.lastname.length < 1) {
            errors.lastname = "Must be a valid name";
        }

        if (!values.email) {
            errors.email = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }

        if (!values.phone) {
            errors.phone = "Required";
        } else if (values.phone?.length < 8) {
            errors.phone =
                "Invalid phone number format try this format +234 81x xxx xxx x";
        }

        return errors;
    };

    return (
        <MainContainer hasMainContainer={false}>
            <div className="w-full pb-36 relative">
                <ProfileHeader title="Edit Profile" currentPageName="Edit Profile" />
                <div className="container mx-auto px-4 w-full md:w-10/12 lg:w-8/12 xl:w-6/12 mt-20">
                    {userDetails?.firstname?.length > 0 && (
                        <Formik
                            initialValues={userDetails}
                            validate={validate}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);

                                const payload = {
                                    firstname: values.firstname,
                                    lastname: values.lastname,
                                    phone:
                                        props.user?.phone === values.phone
                                            ? values.phone
                                            : "+" +
                                            (values?.phone?.startsWith("2340")
                                                ? values?.phone?.replace("2340", "234")
                                                : values?.phone),
                                    email: values.email,
                                    bio: values.bio || undefined,
                                    address: values.address || undefined,
                                };

                                if (uploadedProfilePicture?.name) {
                                    setSubmitting(true);
                                    const formData = new FormData();
                                    formData.append("image", values?.image);

                                    await Promise.all([
                                        await props.updateUserProfilePictureApi(
                                            formData,
                                            props.history
                                        ),
                                        await props.updateUserProfileApi(payload, props.history),
                                    ]);

                                    setSubmitting(false);
                                } else {
                                    await props.updateUserProfileApi(payload, props.history);
                                }

                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="">
                                        <div className="w-full mx-auto text-center mb-6 lg:mb-10">
                                            <ProfilePictureUpload
                                                name="image"
                                                image={image}
                                                profilePicture={userData?.image}
                                                onChange={(e) => {
                                                    // compress image
                                                    const file = e.target.files[0];
                                                    compressImage([file], (data) => {
                                                        setFieldValue("image", data);
                                                    });

                                                    var reader = new FileReader();
                                                    reader.onload = function (e) {
                                                        setImage(e.target.result);
                                                    };
                                                    e.target.files[0] &&
                                                        reader?.readAsDataURL(e.target.files[0]);
                                                    setUploadedProfilePicture(e.target.files[0]);
                                                }}
                                                error={errors.image && touched.image && errors.image}
                                                capture="user"
                                            />
                                        </div>
                                        <div className="grid grid-cols-2 gap-6">
                                            <div className="w-full">
                                                <InputGroup3
                                                    placeholder="First Name"
                                                    type="text"
                                                    name="firstname"
                                                    value={values.firstname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.firstname &&
                                                        touched.firstname &&
                                                        errors.firstname
                                                    }
                                                />
                                            </div>
                                            <div className="w-full">
                                                <InputGroup3
                                                    placeholder="Last Name"
                                                    type="text"
                                                    name="lastname"
                                                    value={values.lastname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.lastname &&
                                                        touched.lastname &&
                                                        errors.lastname
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <InputGroup3
                                            placeholder="Email Address"
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.email && touched.email && errors.email}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <InputPhoneNumberGroup2
                                            disabled
                                            placeholder="Phone Number"
                                            name="phone"
                                            value={values.phone}
                                            onChange={(e) => {
                                                setFieldValue("phone", e);
                                            }}
                                            onBlur={handleBlur}
                                            error={errors.phone && touched.phone && errors.phone}
                                            tip={
                                                <>
                                                    <strong>NOTE:</strong> This phone number is used to
                                                    login on the platform. If updated, use the new number to login later.
                                                </>
                                            }
                                        />
                                    </div>
                                    <div className="w-full">
                                        <AddressInputAutoComplete
                                            placeholder={"Enter Address"}
                                            value={values?.address}
                                            onPlaceSelected={(place) => {
                                                setFieldValue("address", place?.formatted_address);
                                            }}
                                            onChange={(e) => {
                                                setFieldValue("address", e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextAreaGroup
                                            placeholder="Description"
                                            rows="7"
                                            hasLabel={false}
                                            name="bio"
                                            value={values.bio}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                    <div className="block relative mt-6 w-full text-right">
                                        <button
                                            type="submit"
                                            disabled={isSubmitting && true}
                                            className={` w-full md:w-auto bg-temp-green-500 bg-gradient focus:outline-none whitespace-nowrap inline-flex items-center justify-center rounded-lg text-temp-white text-sm font-bold px-8 py-3
                      ${isSubmitting && "opacity-50"}`}
                                        >
                                            {isSubmitting ? "Updating... Please wait" : "Update"}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    )}
                </div>
                {/* Trigger modal after form submission */}
                {loading && <LoadingModal show={loading} />}

                {/* Trigger Message */}
                {props.updateUserStatus?.type && (
                    <AlertModal2
                        show={props.updateUserStatus.type ? true : false}
                        type={props.updateUserStatus.type}
                        content={props.updateUserStatus.message}
                    />
                )}
            </div>
        </MainContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.users?.user,
        alert: state.alert,
        updateUserStatus: state.users.updateUserStatus,
    };
};

export default (
    connect(mapStateToProps, actions)(AccountInformation)
);
