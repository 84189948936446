/* eslint-disable no-unused-vars */
import React from "react";
import Item1Img from "../../assets/images/png/abstract.png";
import User1Img from "../../assets/images/svg/user.svg";
import PropTypes from "prop-types";
import { capitalizeFistLetterInSentence } from "../../utils/capitalizeFirstLetterInSentence";
import moment from "moment";
import { getRentalUnitType } from "../../helpers/rentalFlexibilityHelper";

const CardImage = ({ image, imageHeight }) => {
  return (
    <div className="card-header rounded-xl">
      <img
        src={image || Item1Img}
        className={`w-full object-cover rounded-tl-xl rounded-tr-xl bg-temp-gray-light ${
          imageHeight || "h-32"
        } md:h-52 `}
        alt=""
      />
    </div>
  );
};

const Title = ({ title }) => {
  return (
    <p className="text-base lg:text-lg text-temp-primary font-medium leading-7 one-liner">
      {capitalizeFistLetterInSentence(title)}
    </p>
  );
};

const Location = ({ pickupLocation }) => {
  const address = pickupLocation?.address_components;
  const city =
    address?.find((a) => a?.types?.includes("administrative_area_level_2"))
      ?.short_name || undefined;
  const state =
    address?.find((a) => a?.types?.includes("administrative_area_level_1"))
      ?.short_name || undefined;

  return (
    <div className="flex justify-start">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.182 8.682L6 11.864L2.818 8.682C2.18867 8.05266 1.76009 7.25084 1.58646 6.37792C1.41284 5.50501 1.50195 4.60021 1.84255 3.77795C2.18315 2.95569 2.75992 2.25288 3.49994 1.75842C4.23996 1.26396 5.10999 1.00004 6 1.00004C6.89002 1.00004 7.76004 1.26396 8.50006 1.75842C9.24008 2.25288 9.81686 2.95569 10.1575 3.77795C10.4981 4.60021 10.5872 5.50501 10.4135 6.37792C10.2399 7.25084 9.81133 8.05266 9.182 8.682ZM6 6.5C6.26522 6.5 6.51957 6.39464 6.70711 6.20711C6.89464 6.01957 7 5.76522 7 5.5C7 5.23478 6.89464 4.98043 6.70711 4.79289C6.51957 4.60536 6.26522 4.5 6 4.5C5.73479 4.5 5.48043 4.60536 5.2929 4.79289C5.10536 4.98043 5 5.23478 5 5.5C5 5.76522 5.10536 6.01957 5.2929 6.20711C5.48043 6.39464 5.73479 6.5 6 6.5Z"
          fill="#616564"
        />
      </svg>

      <p className="text-xs pl-2 text-temp-gray">
        {address && city && state ? city + ", " + state : "Nigeria"}
      </p>
    </div>
  );
};

const AvailableFrom = ({ date }) => {
  return (
    <div className="flex justify-start">
      <p className="text-xs mt-4 pl-2 -pb-3 text-temp-gray">
        Available from <span className="font-semibold">{date}</span>
      </p>
    </div>
  );
};

const ReviewRating = ({ rating, reviewCount, newlyListed }) => {
  return (
    <div className="flex">
      {(rating === 0 || newlyListed === undefined || newlyListed) && (
        <svg
          width="12"
          height="12"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0L7.95934 4.49139H12.6819L8.86126 7.26722L10.3206 11.7586L6.5 8.98278L2.6794 11.7586L4.13874 7.26722L0.318133 4.49139H5.04066L6.5 0Z"
            fill="#08A05C"
          />
        </svg>
      )}

      <p className="text-xs text-temp-primary pl-1">
        {rating === 0 || newlyListed ? "Newly Listed" : ""}{" "}
        {reviewCount > 0 && `(${reviewCount} reviews)`}
      </p>
    </div>
  );
};

const Lender = ({ userName, userImage }) => {
  return (
    <div className="flex items-center">
      <div className="w-6 h-6 md:h-6 md:w-6 mr-2 md:mr-2">
        <img
          src={userImage || User1Img}
          className="w-6 h-6 md:h-6 md:w-6 object-cover rounded-full"
          alt=""
        />
      </div>

      <div className="flex flex-col">
        <p className="text-xs md:text-sm text-temp-gray">
          {capitalizeFistLetterInSentence(
            userName?.length > 9
              ? userName?.slice(0, 8)?.toLowerCase() + "..."
              : userName?.toLowerCase()
          )}
        </p>
      </div>
    </div>
  );
};

const Price = ({ priceClassName, amount, item }) => {
  return (
    <div className={priceClassName}>
      <p className="text-temp-secondary font-semibold text-xs lg:text-sm bg-white">
        N{amount || "0,000"}
        <span className="font-normal lowercase">
          /{getRentalUnitType(item)}
        </span>
      </p>
    </div>
  );
};

const ItemCard = ({
  image,
  imageHeight,
  title,
  url,
  amount,
  userImage,
  userName,
  rating,
  reviewCount,
  priceClassName,
  pickupLocation,
  availableFrom,
  createdAt,
  item,
}) => {
  const newlyListed = createdAt
    ? moment(createdAt).add(14, "days").isAfter()
    : false;
  return (
    <a href={window.location.origin + "/" + url}>
      <div
        className={`card relative flex flex-col min-w-0 h-full w-full box-border rounded-xl`}
      >
        <CardImage image={image} imageHeight={imageHeight} />

        <div className="card-body text-temp-primary text-sm md:text-base  flex-1 pt-3 md:pt-5 md:pb-2">
          <div className="flex flex-wrap justify-between items-center flex-row w-full  mt-1 mb-2">
            <ReviewRating
              newlyListed={newlyListed}
              reviewCount={reviewCount}
              rating={rating}
            />
            <Lender userImage={userImage} userName={userName} />
          </div>
          <Title title={title} />
        </div>
        <div className="card-footer pb-11">
          <div className="flex flex-wrap justify-between items-center">
            <Location pickupLocation={pickupLocation} />

            <Price
              amount={amount}
              priceClassName={priceClassName}
              item={item}
            />
          </div>
          {availableFrom && (
            <div className="flex flex-wrap justify-end items-center -mb-8">
              <AvailableFrom date={availableFrom?.format("DD/MM/YYYY")} />
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

ItemCard.propTypes = {
  // image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ItemCard;
