/* eslint-disable no-unused-vars */
import queryString from "query-string";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ItemListing from '../../components/blocks/itemBits/ItemListing';
import ItemPreloader from '../../components/blocks/preloader/ItemPreloader';
import MainContainer from '../../components/layout/MainContainer';
import * as actions from "../../store/actions";
import { capitalizeFistLetterInSentence } from "../../utils/capitalizeFirstLetterInSentence";
import Paginator from '../../utils/pagination';
import { scrollToTop } from "../../utils/scrollToTop";
import EmptyCategoryList from '../category/components/EmptyCategoryList';
import SearchInputSection from "./components/SearchInputSection";

const SearchByLocationResult = (props) => {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [currentLocation, setCurrentLocation] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [itemLoading, setItemLoading] = useState(false);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        scrollToTop();
        const pathname = props.location?.pathname
        const convertPathnameToArray = pathname?.trim()?.split("/")
        const filteredArray = convertPathnameToArray.filter(a => a !== "")
        const extractOnlyCity = filteredArray[1]
       
        const qs = queryString.parse(props.location.search);
        const searchKeyword = qs?.q;

        setCurrentLocation(extractOnlyCity)
        setSearchInput(searchKeyword);
       
        fetchItem({
            location: extractOnlyCity,
            search: searchKeyword,
        });
    }, [props.location.search]);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const searchTerm = searchInput.trim();
        if (searchTerm !== "") {
            navigate(
                `/search-by-location/${currentLocation}/?q=${searchTerm.toLowerCase().replace(" ", "+")}`
            );
        }
    };

    useEffect(() => {
        setItems(props.items);
        setPagination(props.pagination);
      }, [props.items]);

    const fetchItem = async (params) => {
        setItemLoading(true);
        await props.getItemsApi(params);
        setItemLoading(false);
    };

    return <MainContainer hasMainContainer={false}>
        <div className="container px-4 mx-auto">
            <div className="pt-6 lg:pt-6 pb-6 lg:pb-10 lg:mt-8 lg:mb-16">
                <div className="space-x-2 mb-6 lg:mb-10 text-sm lg:text-sm-15 cursor-pointer">
                    <span><Link to="/search-by-location">City</Link></span>{" "}
                    <span>&gt;</span>{" "}
                    {currentLocation && <span className="text-temp-secondary">{capitalizeFistLetterInSentence(currentLocation)}</span>}
                </div>
                <div className="relative mt-4 search-location-result-header rounded-2xl lg:rounded-3xl py-8 md:py-10 lg:py-14 px-5 md:px-6 lg:px-10">
                    <h1 className="md:ml-2 text-xl md:text-2xl lg:text-4xl font-bold text-white">
                        {capitalizeFistLetterInSentence(currentLocation)}
                    </h1>

                    <div className='flex justify-start'>
                        <SearchInputSection
                            className="w-full  md:w-11/12 lg:w-7/12 xl:w-5/12 relative pt-6 text-left md:pt-8 lg:pt-12 flex flex-wrap"
                            searchInput={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            handleSubmit={handleSearchSubmit}
                        />
                    </div>
                    <p className='text-xs md:text-sm lg:text-base text-white mt-3 md:mt-4 lg:mt-6'>Available items</p>
                </div>
            </div>

            {itemLoading ? (
                <div className="mt-16">
                    <ItemPreloader />
                </div>
            ) : items?.length > 0 ? (
                <>
                    <ItemListing items={items} />
                    <Paginator
                        pageLimit={pagination?.limit}
                        maxPages={pagination?.totalPages}
                        currentPage={pagination?.page}
                        getPageApi={fetchItem}
                        setLoading={setItemLoading}
                        objectParams={{
                            location: currentLocation,
                            search: searchInput
                        }}
                    />
                </>

            ) : (
                <EmptyCategoryList text="Item is currently not available in your city" />
            )}
        </div>
    </MainContainer>
};

const mapStateToProps = (state) => ({
    items: state.items.items,
    pagination: state.items.pagination,
});

export default (connect(mapStateToProps, actions)(SearchByLocationResult));
