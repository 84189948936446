import { Transition } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";

const LinkItem = ({ name, url }) => {
  return (
    <li className="">
      <Link
        to={url}
        className="text-temp-primary block relative py-3 px-8 menu-link-item font-light text-sm-15"
      >
        {name}
      </Link>
    </li>
  );
};

const AuthMenu = ({ isOpen, handleLogout, user, handleCloseMenu }) => {
  return (
    <Transition
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        className="absolute z-30 right-0 rounded-lg bg-temp-white custom-select-list w-64 text-sm lg:text-base"
        onMouseLeave={handleCloseMenu}
      >
        <div className="pt-4 z-40">
          <ul className=" text-temp-primary cursor-pointer">
            {user?.verified_lender && (
              <>
                <LinkItem url="/my-items" name="My Items" />
                <LinkItem url="/rental-requests" name="Rental Requests" />
              </>
            )}

            <LinkItem url="/rental-activities" name="Rental Activities" />
            <LinkItem url="/messages" name="Messages" />
            <LinkItem url="/notifications" name="Notifications" />

            {user?.verified_lender && (
              <LinkItem url="/my-wallet" name="Wallet" />
            )}

            <LinkItem url="/profile" name="Profile" />
            <LinkItem url="/profile/notification-settings" name="Settings" />
          </ul>
        </div>
        <hr className="menu-line" />{" "}
        <LinkItem url="/faqs" name="FAQs" />
        <hr className="menu-line" />{" "}
        {/* Logout button */}
        <div className="px-3 md:pb-3">
          <ul className="space-y-3 py-2 text-temp-primary cursor-pointer">
            <li className="py-1 px-3">
              <button
                onClick={handleLogout}
                className="text-red-500 font-semibold focus:outline-none"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
        {/* Show on mobile */}
        {user?.verified_lender || user?.type === "lender" ? (
          <div className="block md:hidden mb-2">
            <hr className="menu-line" />{" "}
            <LinkItem
              url="/upload-item/start"
              name={<span className="font-semibold text-temp-secondary">Upload an item</span>}
            />
          </div>
        ) : (
          <div className="block md:hidden mb-2">
            <hr className="menu-line" />{" "}
            <LinkItem
              url="/lender"
              name={<span className="font-semibold text-temp-secondary">Become a Lender</span>}
            />
          </div>
        )}
      </div>
    </Transition>
  );
};

export default AuthMenu;
