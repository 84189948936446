import React from "react";
import ImageGallery from "react-image-gallery";

const ItemImageGallery = ({ images }) => {
  return (
    <div>
      <ImageGallery
        items={images?.map((image) => ({
          original: image?.image?.url,
          thumbnail: image?.image?.url,
        }))}
        lazyLoad={true}
        showNav={false}
        showPlayButton={false}
        showIndex={true}
      />
    </div>
  );
};

export default ItemImageGallery;
