import React from "react";
import shield from "../../../../assets/images/svg/shield.svg";
import umbrella from "../../../../assets/images/svg/umbrella.svg";
import NextButton from "./componentsBit/NextButton";
import PreviousButton from "./componentsBit/PreviousButton";
import SelectButtonWithDescription from "./componentsBit/SelectButtonWithDescription";
import TipCard from "../components/componentsBit/TipCard"
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";

const InsuranceForm = ({
  handleTempownProtect,
  initialState,
  handleChangeCurrentStep,
}) => {
  const { tempownProtect, hasSerialNumber } = initialState;

  return (
    <div className="flex justify-around xl:pr-20">
      <div className="w-4/12 hidden xl:block">
        <PreviousButton
          onClick={() => handleChangeCurrentStep("Step4")}
        />
      </div>
      <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12">
        <h2
          className="text-xl sm:text-2xl text-temp-primary font-medium"
        >
          Insurance
        </h2>
        <TipCard
          text="Pricing on this equipment is calculated in days, weeks and months.
            Having customer-friendly pricing attracts more users to you."
        />

        <div className="mt-4 mb-44 xl:mb-20">
          <div className="mt-6">
            <SelectButtonWithDescription
              img={shield}
              title="Tempown Protect"
              bgStyle="bg-temp-green-100"
              selected={tempownProtect === true}
              handleClick={() => handleTempownProtect(true)}
              disabled={!hasSerialNumber}
              content="Your equipment(s) needs to have a serial number to use Tempown protect."
              icon={
                <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.991984 2.66072L9.20898 0.834717L17.426 2.66072C17.648 2.71008 17.8466 2.83368 17.989 3.01112C18.1313 3.18856 18.2089 3.40923 18.209 3.63672V13.6237C18.2089 14.6115 17.965 15.5839 17.4989 16.4547C17.0328 17.3256 16.3589 18.0679 15.537 18.6157L9.20898 22.8347L2.88098 18.6157C2.05926 18.0679 1.38544 17.3258 0.919333 16.4552C0.45322 15.5845 0.209221 14.6123 0.208984 13.6247V3.63672C0.209023 3.40923 0.286622 3.18856 0.428974 3.01112C0.571326 2.83368 0.769921 2.71008 0.991984 2.66072ZM2.20898 4.43872V13.6237C2.209 14.2822 2.37156 14.9305 2.68225 15.511C2.99293 16.0916 3.44213 16.5864 3.98998 16.9517L9.20898 20.4317L14.428 16.9517C14.9757 16.5865 15.4248 16.0918 15.7355 15.5114C16.0462 14.9311 16.2088 14.283 16.209 13.6247V4.43872L9.20898 2.88472L2.20898 4.43872Z"
                    fill={tempownProtect === true ? "#ffffff" : "#08A05C"}
                  />
                </svg>
              }
            />
            <SelectButtonWithDescription
              img={umbrella}
              title="Self Insurance"
              bgStyle="bg-temp-primary-100"
              selected={tempownProtect === false}
              handleClick={() => handleTempownProtect(false)}
              content="Suitable if you equipment is already insured. If not opt for Tempown protect."
              icon={
                <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.209 1.06685C16.262 1.56785 20.209 5.83185 20.209 11.0168V12.0168H11.209V18.0168C11.209 18.5473 11.4197 19.056 11.7948 19.4311C12.1698 19.8061 12.6786 20.0168 13.209 20.0168C13.7394 20.0168 14.2481 19.8061 14.6232 19.4311C14.9983 19.056 15.209 18.5473 15.209 18.0168V17.0168H17.209V18.0168C17.209 19.0777 16.7876 20.0951 16.0374 20.8453C15.2873 21.5954 14.2699 22.0168 13.209 22.0168C12.1481 22.0168 11.1307 21.5954 10.3806 20.8453C9.63041 20.0951 9.20898 19.0777 9.20898 18.0168V12.0168H0.208984V11.0168C0.208984 5.83185 4.15598 1.56785 9.20898 1.06685V1.01685C9.20898 0.751629 9.31434 0.497275 9.50188 0.309739C9.68941 0.122203 9.94377 0.0168457 10.209 0.0168457C10.4742 0.0168457 10.7286 0.122203 10.9161 0.309739C11.1036 0.497275 11.209 0.751629 11.209 1.01685V1.06685ZM18.147 10.0168C17.903 8.08374 16.9619 6.30612 15.5004 5.01752C14.0389 3.72892 12.1574 3.01792 10.209 3.01792C8.26054 3.01792 6.37905 3.72892 4.91756 5.01752C3.45606 6.30612 2.51502 8.08374 2.27098 10.0168H18.147Z"
                    fill={tempownProtect === false ? "#ffffff" : "#08A05C"}
                  />
                </svg>
              }
            />
          </div>
          <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mt-8">
            <NextButton
              disabled={tempownProtect === null}
              onClick={() => handleChangeCurrentStep("Step6", initialState)}
              title="Next"
            />
          </div>
        </div>

        <FixedBottomPrevNextButton
          handlePrevClick={() => handleChangeCurrentStep("Step4")}
          disabled={tempownProtect === null}
          onClick={() => handleChangeCurrentStep("Step6", initialState)}
          title="Next"
        />
      </div>
    </div>
  );
};

export default InsuranceForm;
