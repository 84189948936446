import React from "react";
import BlankImg from "../../assets/images/svg/user.svg";

const ProfilePictureUpload = ({
  profilePicture,
  name,
  image,
  error,
  onChange,
  ...props
}) => {
  return (
    <>
      <div
        className="image-input image-input-outline"
        id="kt_profile_avatar"
        style={{
          backgroundImage: `url(${profilePicture || BlankImg})`,
        }}
      >
        <div
          className="image-input-wrapper"
          style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
        ></div>
        <label
          className="text-temp-secondary text-sm lg:text-sm-15 cursor-pointer"
          data-action="change"
          data-toggle="tooltip"
          title=""
          data-original-title="Change avatar"
        >
          <p className="mt-4 lg:mt-6">Change Image</p>
          <input
            type="file"
            name={name}
            accept=".png, .jpg, .jpeg"
            onChange={onChange}
            {...props}
          />
        </label>
      </div>

      <br />
      <label className="text-danger">{error}</label>
    </>
  );
};

export default ProfilePictureUpload;
