// Update rental activity card with Classname by order status
export const getRentalActivityClassNameByStatus = (data = {}) => {
  const { status, progressStatus } = data;
  if (status) {
    if (status === "accepted" && progressStatus === "not-started") {
      return "accepted-status";
    } else if (status === "accepted" && progressStatus === "completed") {
      return "completed-status";
    } else if (status === "pending") {
      return "pending-status";
    } else if (status === "canceled" || status === "rejected") {
      return "canceled-status";
    } else if (status === "accepted" && progressStatus === "on-going") {
      return "ongoing-status";
    } else if (status === "accepted" && progressStatus === "over-due") {
      return "overdue-status";
    } else {
      return "unknown-status";
    }
  } else {
    return "unknown-status";
  }
};

export const getRentalActivityNameByStatus = (data = {}) => {
  const { status, progressStatus } = data;
  if (status) {
    if (status === "accepted" && progressStatus === "not-started") {
      return "Accepted";
    } else if (status === "accepted" && progressStatus === "on-going") {
      return "Ongoing";
    } else if (status === "accepted" && progressStatus === "completed") {
      return "Completed";
    } else if (status === "accepted" && progressStatus === "over-due") {
      return "Overdue";
    } else {
      return status;
    }
  } else {
    return "N/A"
  }
};
