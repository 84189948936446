import React from "react";
import CustomSelectGroup from "../../../components/element/CustomSelectGroup";
import { capitalizeFistLetterInSentence } from "../../../utils/capitalizeFirstLetterInSentence";

export const CategoryFilter = ({
  types,
  loadingType,
  handleTypeChange,
  selectedType,
}) => {
  return (
    <div>
      <div className="relative flex flex-row flex-wrap mb-5 lg:mb-8">
        <div className="w-full md:w-4/12">
          <CustomSelectGroup
            placeholder="Select Type"
            isLoading={loadingType}
            isDisabled={loadingType ? true : false}
            name="type"
            value={selectedType || ""}
            options={types?.map((cat) => ({
              value: cat.name,
              label: capitalizeFistLetterInSentence(cat.name),
            }))}
            onChange={handleTypeChange}
          />
        </div>
      </div>
    </div>
  );
};
