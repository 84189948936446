import React, { useEffect, useState } from "react";
import MainContainer from "../../components/layout/MainContainer";

import queryString from "query-string";
import { connect } from "react-redux";
import { } from "react-router";

import moment from "moment";
import Loader from "../../components/blocks/Loader";
import LoadingModal from "../../components/modals/LoadingModal";
import * as actions from "../../store/actions";
import { numberFormat } from "../../utils/numberFormat";
import { scrollToTop } from "../../utils/scrollToTop";

import store from "store";
import EquipmentHowTo from "../../components/blocks/itemBits/EquipmentHowTo";
import EquipmentOverviewDetails from "../../components/blocks/itemBits/EquipmentOverviewDetails";
import AlertModal from "../../components/modals/AlertModal";
import ItemAvailabilityStatus from "./components/ItemAvailabilityStatus";
import ItemChargesAndCheckout from "./components/ItemChargesAndCheckout";

const ItemsAvailabilityAndCheckout = (props) => {
    const [formData, setFormData] = useState({
        deliveryOption: "Pickup from Location",
    });

    const [userData, setUserData] = useState({});
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [days, setDays] = useState("");
    const [feePerDiscount, setFeePerDiscount] = useState(0);
    const [itemAvailability, setItemAvailability] = useState({});
    const [unitOrdered, setUnitOrdered] = useState(1);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        scrollToTop();

        setUserData(store.get("temp_usER12_dATa"));

        const qs = queryString.parse(props.location.search);
        const extendItem = store.get("extEND_bool1");
        const extendOrderId = store.get("extEND_orderId");

        let payload = {
            itemId: qs.id,
            startDate: qs.startDate,
            quantity: parseInt(qs.qty),
        };

        setUnitOrdered(qs?.unitOrdered);

        let hasUnitOrdered;

        if (qs?.unitOrdered) {
            hasUnitOrdered = "bySiUnits";
            payload["unitOrdered"] = qs?.unitOrdered;
        }

        if (qs?.endDate) {
            hasUnitOrdered = "byCalender";
            payload["endDate"] = qs.endDate;
        }

        if (extendItem) {
            payload.isExtendOrder = true;
            payload.orderId = extendOrderId;
        }

        fetchItem(hasUnitOrdered, qs.id, payload);
    }, []);

    const fetchItem = async (hasUnitOrdered, id, availabilityPayload) => {
        setLoading(true);
        await props.getSingleItemApi(id);
        await props.checkItemAvailabilityApi(
            hasUnitOrdered === "bySiUnits" ? "bySiUnits" : "byCalender",
            availabilityPayload,
            props.history,
            false
        );
        setLoading(false);
    };

    useEffect(() => {
        const data = props.item;
        setItem(data);

        if (days < 7) {
            setFeePerDiscount(data.price?.amount);
        } else if (days >= 7 && days <= 27) {
            setFeePerDiscount(
                data.price?.amount -
                (data.price?.discountPerWeek / 100) * data.price?.amount
            );
        } else {
            setFeePerDiscount(
                data.price?.amount -
                (data.price?.discountPerMonth / 100) * data.price?.amount
            );
        }

        setAddress(store.get("temp_usER12_dATa")?.address);
    }, [props.item]);

    useEffect(() => {
        const availability = props?.itemAvailability;
        const availabilitySummary = availability?.summary;
        
        setItemAvailability(availability);

        setStartDate(availabilitySummary?.startDate);
        setEndDate(availabilitySummary?.endDate);
        setQuantity(parseInt(availabilitySummary?.quantity));

        const startD = moment(availabilitySummary?.startDate);
        const endD = moment(availabilitySummary?.endDate);

        const calcuatedDaysForOrder = Math.abs(startD.diff(endD, "days")) + 1;

        setDays(calcuatedDaysForOrder === 1 ? 2 : calcuatedDaysForOrder);
    }, [props.itemAvailability]);

    const componentProps = {
        email: userData?.email,
        amount: parseInt(itemAvailability?.summary?.total).toFixed(2) * 100,
        metadata: {
            name: userData?.firstname + " " + userData?.lastname,
            phone: userData?.phone,
        },
        publicKey: process.env.REACT_APP_PAYSTACK_API,
        text: (
            <>
                {" "}
                Check out{" "}
                <span className="font-normal">
                    ({numberFormat(itemAvailability?.summary?.total)})
                </span>
            </>
        ),
        onSuccess: (response) => {
            // Parent ID gotten from the extended order action
            const parentId = store.get("pt_id");

            let payload = {
                ref: response?.reference,
                deliveryType: formData.deliveryOption,
                address:
                    formData.deliveryOption === "Delivery to Location" ? address : "",
                itemId: String(itemAvailability?.summary?.itemId),
                total: String(itemAvailability?.summary?.total),
                serviceCharge: String(itemAvailability?.summary?.serviceCharge),
                baseFee: String(itemAvailability?.summary?.baseFee),
                startDate: itemAvailability?.summary?.startDate,
                endDate: itemAvailability?.summary?.endDate,
                discountPrice: String(itemAvailability?.summary?.discountPrice),
                quantity: quantity,
                orderUnitType: item?.rentalUnitType,
                unitOrdered: unitOrdered || undefined,
            };
            if (itemAvailability?.summary?.voucher?.discount) {
                payload.discount = String(itemAvailability?.summary?.voucher?.discount);
                payload.voucherCode = String(itemAvailability?.summary?.voucher?.code);
            }

            if (itemAvailability?.summary?.referralBonus) {
                payload.referralBonus = String(
                    itemAvailability?.summary?.referralBonus
                );
            }

            if (parentId) {
                payload.orderId = parentId;
            }

            verifyPaymentAndCreateOrder(payload);
        },
    };

    const [verifyLoading, setVerifyLoading] = useState(false);
    const verifyPaymentAndCreateOrder = async (payload) => {
        setVerifyLoading(true);
        const verifyPayload = {
            ref: payload?.ref,
            itemId: payload?.itemId,
            total: payload?.total,
        };

        const resp = await props.verifyPaymentApi(verifyPayload);

        if (resp?.data?.success) {
            await props.createOrderApi(payload);
        }
        setVerifyLoading(false);
    };

    const handleGoBack = () => {
        props.history.go(-1);
    };

    return (
        <MainContainer hasMainContainer={false}>
            <div className="container px-4 mx-auto">
                {loading ? (
                    <div className="my-14">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="w-full sm:pt-4 md:pt-10 pb-36 relative">
                            <div className="relative flex flex-wrap text-temp-primary ">
                                <div className="w-full lg:w-6/12 mb-12">
                                    <button
                                        onClick={() => handleGoBack()}
                                        className="text-temp-gray text-sm text-left w-full mb-6 ring-0 focus:ring-0 focus:outline-none"
                                    >
                                        &lt;&nbsp; Back
                                    </button>

                                    <ItemAvailabilityStatus
                                        status={itemAvailability?.available}
                                    />
                                    <div className="pt-8 lg:pt-10">
                                        <EquipmentOverviewDetails
                                            item={item}
                                            itemAvailability={itemAvailability}
                                        />
                                    </div>

                                    <div className="lg:hidden block w-full mb-8">
                                        {/* Mobile only */}
                                        <ItemChargesAndCheckout
                                            days={days}
                                            feePerDiscount={feePerDiscount}
                                            startDate={startDate}
                                            endDate={endDate}
                                            itemAvailability={itemAvailability}
                                            item={item}
                                            formData={formData}
                                            handleInputChange={handleInputChange}
                                            componentProps={componentProps}
                                            discountOn={
                                                itemAvailability?.summary?.voucher?.discountOn
                                            }
                                            discount={itemAvailability?.summary?.voucher?.discount}
                                            unitOrdered={unitOrdered}
                                        />
                                    </div>

                                    <EquipmentHowTo
                                        orderDetails={{
                                            borrower: {
                                                _id: userData?._id,
                                            },
                                        }}
                                        loggedInUserDetails={userData}
                                    />
                                </div>

                                {/* Desktop only */}
                                <div className="w-full lg:w-6/12 xl:w-5/12 mx-auto px-0 md:px-10 mb-12 hidden lg:block">
                                    <ItemChargesAndCheckout
                                        days={days}
                                        feePerDiscount={feePerDiscount}
                                        startDate={startDate}
                                        endDate={endDate}
                                        itemAvailability={itemAvailability}
                                        item={item}
                                        formData={formData}
                                        handleInputChange={handleInputChange}
                                        componentProps={componentProps}
                                        discountOn={itemAvailability?.summary?.voucher?.discountOn}
                                        discount={itemAvailability?.summary?.voucher?.discount}
                                        unitOrdered={unitOrdered}
                                    />
                                </div>
                            </div>
                        </div>

                        {verifyLoading && (
                            <LoadingModal
                                show={verifyLoading}
                                content="Payment verification in progress...."
                            />
                        )}

                        {/* Trigger Message */}
                        {props.alert.message && (
                            <AlertModal
                                show={props.alert.type ? true : false}
                                type={props.alert.type}
                                content={props.alert.message}
                                linkUrl={`/rental-activities`}
                            />
                        )}
                    </>
                )}
            </div>
        </MainContainer>
    );
};

const mapStateToProps = (state) => ({
    items: state.items.items,
    item: state.items.item,
    alert: state.alert,
    itemAvailability: state.items.itemAvailability,
});

export default (
    connect(mapStateToProps, actions)(ItemsAvailabilityAndCheckout)
);
