import React from 'react';
import Loader from '../../../components/blocks/Loader';
import ButtonWithOptions from './ButtonWithOptions';

const BanksDetails = ({
    loadingBankDetails,
    handleToggleShow,
    toggleShow,
    bank,
    handleShowEditModal,
}) => {
    return (
        <>
            <div className="my-16 relative w-full flex flex-wrap justify-between">
                {loadingBankDetails ? (
                    <div className="w-full">
                        <Loader />
                    </div>
                ) : (
                    <div className="w-96 mb-6">
                        <div
                            className="px-8 py-7 border border-temp-green-500 rounded-2xl"
                            style={{
                                boxShadow: "0px 3.46067px 17.3034px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <div className="pb-14 lg:pb-20 relative flex justify-between items-start">
                                <p className="flex-shrink w-10/12 text-temp-gray text-sm">
                                    {bank?.bankCode}
                                </p>
                                <ButtonWithOptions
                                    handleToggleShow={handleToggleShow}
                                    toggleShow={toggleShow}
                                    handleShowEditModal={handleShowEditModal}
                                />
                            </div>

                            <p className="text-temp-primary pb-2 text-sm">
                                {bank?.accountName}
                            </p>
                            <p className="text-lg md:text-xl text-temp-secondary pb-2 font-bold">
                                {bank?.accountNumber}
                            </p>
                        </div>
                        <p className="pt-6 text-temp-gray text-sm md:text-base">
                            Default Bank
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default BanksDetails;
