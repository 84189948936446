import React from "react";
import PropTypes from "prop-types";

const HighlistItem = ({ title, content }) => {
  return (
    <dl className="bg-temp-green-100 rounded-xl pt-8 pb-12 px-8">
      <div className="">
        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-temp-white">
          <svg
            width="23"
            height="13"
            viewBox="0 0 23 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.1669 8.00903L12.5789 9.42103L21.0449 0.955027L22.4589 2.36903L12.5789 12.249L6.21494 5.88503L7.62894 4.47103L9.75394 6.59603L11.1669 8.00803V8.00903ZM11.1689 5.18103L16.1209 0.228027L17.5309 1.63803L12.5789 6.59103L11.1689 5.18103ZM8.34194 10.836L6.92894 12.249L0.564941 5.88503L1.97894 4.47103L3.39194 5.88403L3.39094 5.88503L8.34194 10.836Z"
              fill="#08A05C"
            />
          </svg>
        </div>
        <div className="mt-8">
          <dt className="text-base md:text-base xl:text-lg leading-6 font-semibold text-black">
            {title}
          </dt>
          <dd className="mt-4 text-sm lg:text-sm-15 lg:leading-6 text-temp-primary">
            {content}
          </dd>
        </div>
      </div>
    </dl>
  );
};

HighlistItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};
export default HighlistItem;
