import React from "react";

const InputWithSuffixPrefixGroup = ({
  prefixIcon,
  type,
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  //   error,
  disabled,
  isSubmitting,
  btnText,
  onClick,
  buttonType,
}) => {
  return (
    <div className="relative subscribe-form-input flex flex-row items-center justify-between rounded-full">
      {prefixIcon && (
        <p className="w-12 lg:w-14  h-12 md:h-16 lg:h-16 px-4 rounded-l-full flex justify-center items-center">
          <img src={prefixIcon} alt="" />
        </p>
      )}

      <input
        autoComplete="off"
        type={type || "text"}
        label={label || ""}
        placeholder={placeholder || ""}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        // error={error}
        className={`w-8/12 rounded-l-full placeholder-temp-gray-light bg-transparent focus:bg-transparent text-xs sm:text-base  h-12 md:h-16 lg:h-16 text-temp-secondary focus:outline-none ${
          prefixIcon ? "pr-6" : "px-6"
        }`}
      />
      <div className="pr-1 md:pr-2">
        <button
          type={buttonType || "button"}
          onClick={onClick}
          disabled={disabled}
          className={`bg-gradient-secondary w-full md:w-auto rounded-full  md:mt-0 text-xs text-white font-semibold sm:text-sm-15 h-10  md:h-12 lg:h-12 px-6 lg:px-8 focus:outline-none 
                            ${isSubmitting && "bg-opacity-80"}`}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default InputWithSuffixPrefixGroup;
