import React from "react";

export const StarIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || "13"}
      height={height || "12"}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75998 0.640381L8.12727 4.84847H12.5519L8.97231 7.44921L10.3396 11.6573L6.75998 9.05655L3.18037 11.6573L4.54766 7.44921L0.968049 4.84847H5.39269L6.75998 0.640381Z"
        fill={fill || "#000000"}
      />
    </svg>
  );
};
