import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import store from "store";
import OnboardingContainer from "../../../components/layout/OnboardingContainer";
import {
    RibbonIconLeft,
    RibbonIconRight,
    SuccessGradientIcon,
} from "../../../components/svgs";
import * as actions from "../../../store/actions";
import { countDown } from "../../../utils/countdown";

const SignupSuccessful = (props) => {
  const location = useLocation();

  const [timerEmail, setTimerEmail] = useState(0);
  const [userInfo, setuserInfo] = useState(undefined);

  useEffect(() => {
    if (location.state) setuserInfo(location.state);

    if (localStorage.getItem("sentEmail") === "true") {
      countDown(59, setTimerEmail);
      localStorage.removeItem("sentEmail");
    }
  }, []);

  const handleClick = async () => {
    await props.resendVerifyEmailApi({
      email: userStoreInformation?.email,
    });
    if (localStorage.getItem("sentEmail") === "true") {
      countDown(59, setTimerEmail);
      localStorage.removeItem("sentEmail");
    }
  };
  const userStoreInformation = store.get("temp_usER12_dATa");
  return (
    <div>
      <OnboardingContainer pageTitle="Verify Email">
        <div className="flex justify-center">
          <div className="w-full lg:w-8/12 pt-8 lg:pt-4">
            <div className="flex w-full justify-center items-center flex-col px-0 lg:px-10">
              <p className=" pt-8 py-4 font-semibold text-center text-temp-primary text-3xl lg:text-4xl xl:text-5xl">
                Welcome to TempOwn
              </p>
              <div className="mt-1 lg:mt-4 text-center mx-auto">
                <h2 className="text-base md:text-lg lg:text-xl text-temp-green-500">
                  Your registration is successful.
                </h2>
                <div className="relative flex justify-center text-center mx-auto w-full py-6">
                  <div className="relative left-8">
                    <RibbonIconLeft />
                  </div>
                  <div className="z-10">
                    <SuccessGradientIcon />
                  </div>
                  <div className="relative -left-8">
                    <RibbonIconRight />
                  </div>
                </div>

                {userInfo ? (
                  <>
                    <div className="mt-4 pb-2 mx-auto w-10/12 lg:w-full">
                      <p className="text-temp-gray text-sm lg:text-base">
                        You can use the details below to{" "}
                        <Link
                          to="/signin"
                          className="text-temp-green-500 font-semibold"
                        >
                          login with the new user{" "}
                        </Link>
                        account.
                      </p>
                    </div>

                    <div className="pl-12 text-left bg-temp-green-200 text-xs lg:text-sm pt-5 pb-3 px-4 rounded-lg mt-4 mb-14">
                      <ul className="text-left mt-2 mb-3 space-y-1 text-temp-green-500 list-inside list-disc">
                        <li>
                          Email Address:{" "}
                          <span className="font-semibold">
                            {userInfo?.email}
                          </span>
                        </li>
                        <li>
                          Password:{" "}
                          <span className="font-semibold">
                            {userInfo?.phone}
                          </span>
                        </li>
                      </ul>

                      <Link to="/tempown-signup-with-nin-only">
                        <button
                          className={`bg-temp-green-500 hover:bg-green-600 text-temp-white pt-2 py-1.5 px-4 rounded-md font-bold focus:outline-none`}
                          onClick={handleClick}
                        >
                          Register another user
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-4 pb-2 mx-auto w-10/12 lg:w-full">
                      <p className="text-temp-gray text-sm lg:text-base">
                        Please{" "}
                        <span className="text-temp-green-500 font-semibold">
                          check your email
                        </span>{" "}
                        for verification link to verify your account.
                      </p>
                    </div>

                    <div className="pl-12 text-left bg-temp-green-200 text-xs lg:text-sm pt-5 pb-3 px-4 rounded-lg mt-4 mb-14">
                      <span className="text-temp-primary">
                        You will need to verify the following;
                      </span>{" "}
                      <br />
                      <ul className="text-left mt-2 mb-3 font-semibold space-y-1 text-temp-green-500 list-inside list-disc">
                        <li>Email Address</li>
                        <li>Phone Number</li>
                        <li>Identity</li>
                        <li>Profile Picture</li>
                      </ul>
                      <span className="text-temp-primary">
                        Didn’t get a mail?{" "}
                      </span>
                      <button
                        className={`${
                          timerEmail <= 0
                            ? "bg-temp-green-500 bg-gradient hover:bg-green-600"
                            : "bg-gray-500"
                        } text-temp-white pt-2 py-1.5 px-4 rounded-md font-bold focus:outline-none`}
                        onClick={handleClick}
                        disabled={timerEmail > 0}
                      >
                        {`Resend ${timerEmail > 0 ? `in 0:${timerEmail}` : ""}`}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </OnboardingContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  resendEmailStatus: state.auth.resendEmailStatus,
  verifyEmail: state.auth.verifyEmail,
  user: state.auth.user,
});
export default (connect(mapStateToProps, actions)(SignupSuccessful));
