import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from 'react-router-dom';
import store from "store";
import Loader from "../../../components/blocks/Loader";
import InputDatePicker from "../../../components/element/InputDatePicker";
import MainContainer from "../../../components/layout/MainContainer";
import AlertModal from "../../../components/modals/AlertModal";
import * as actions from "../../../store/actions";
import AvailableBalancesCard from "./components/AvailableBalancesCard";
import IncomeTransactionCard from "./components/IncomeTransactionCard";
import IncomeTransactionHistoryModal from "./components/IncomeTransactionHistoryModal";
import WithdrawFundsModal from "./components/WithdrawFundsModal";
import WithdrawalHistoryCard from "./components/WithdrawalHistoryCard";
import WithdrawalHistoryModal from "./components/WithdrawalHistoryModal";

const LenderWallet = (props) => {
  const userData = store.get("temp_usER12_dATa");

  const [showModal, setShowModal] = useState(false);
  const [showIncomeTransactionModal, setShowIncomeTransactionModal] = useState(
    false
  );

  const [showWithdrawalHistoryModal, setShowWithdrawalHistoryModal] = useState(
    false
  );

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowIncomeTransactionModal = () => {
    setShowIncomeTransactionModal(!showModal);
  };

  const handleCloseIncomeTransactionModal = () => {
    setShowIncomeTransactionModal(false);
  };

  const handleShowWithdrawalHistoryModal = () => {
    setShowWithdrawalHistoryModal(!showModal);
  };

  const handleCloseWithdrawalHistoryModal = () => {
    setShowWithdrawalHistoryModal(false);
  };

  const [loading, setLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState({});

  const fetchWalletDetails = async (params) => {
    setLoading(true);
    await props.getWalletsApi(params);
    setLoading(false);
  };

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  useEffect(() => {
    setWalletDetails(props.wallets);
  }, [props.wallets]);

  useEffect(() => {
    if (props.paymentSuccess?.length > 1) {
      handleCloseModal();
    }
  }, [props.paymentSuccess]);

  const [date, setDate] = useState("")

  // Filter Details by month and year
  const handleChange = async (e) => {
    const val = new Date(e)

    setDate(val)
    const params = {
      date: new Date(val.setDate(val.getDate() + 1)).toISOString()
    }
    await fetchWalletDetails(params)
  }

  return (
    <MainContainer hasMainContainer={false}>
      <div className="container px-4 mx-auto w-full pt-10 lg:pt-16 pb-20 lg:pb-32">
        <div className="">
          <h3 className="font-semibold text-2xl lg:text-3xl text-temp-primary">
            My Wallet
          </h3>
          <p className="font-light text-temp-primary py-4 text-sm lg:text-base">
            Hello {userData?.firstname}, this is your financials. Keep earning
            💵 keep growing 🎯
          </p>
        </div>


        <div className="flex flex-wrap justify-between items-center">
          <div className="group mt-7">
            <InputDatePicker
              autoComplete="off"
              name="date"
              value={date}
              placeholderText="Filter By Date"
              onChange={handleChange}
              dateFormat="MMMM, yyyy"
              showMonthYearPicker
            />
          </div>


          {
            !loading &&
            <div className="w-full md:w-7/12 lg:w-5/12 pt-3 px-5 bg-temp-green-200 rounded-xl flex flex-wrap justify-between">
              <div className="flex flex-wrap justify-between">
                <p className="font-medium text-temp-green-500 mr-8 pb-2 text-base">
                  Pending Income
                </p>
                <p className="font-semibold text-temp-primary text-base lg:text-lg pb-2">
                  NGN {walletDetails.pending_income || 0.0}
                </p>
              </div>
              <div className="pb-2 lg:pb-3">
                <p className="text-xs lg:text-sm text-temp-primary">
                  You will be able to withdraw once the borrower picks up the
                  item.😉😉
                </p>
              </div>
            </div>
          }
        </div>

        {loading ? (
          <div className="py-16 flex flex-wrap items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>

            <div className="py-12 flex flex-wrap justify-between">
              <AvailableBalancesCard
                amount={walletDetails.balance || 0.0}
                handleShowModal={handleShowModal}
              />

              <IncomeTransactionCard
                date={date}
                amount={walletDetails.total_income || 0.0}
                handleShowModal={handleShowIncomeTransactionModal}
              />

              <WithdrawalHistoryCard
                date={date}
                amount={walletDetails.total_withdrawal || 0.0}
                handleShowModal={handleShowWithdrawalHistoryModal}
              />
            </div>
          </>
        )}
      </div>

      {showModal && (
        <WithdrawFundsModal
          show={showModal}
          handleCloseModal={handleCloseModal}
        />
      )}

      {showIncomeTransactionModal && (
        <IncomeTransactionHistoryModal
          date={date}
          show={showIncomeTransactionModal}
          handleCloseIncomeTransactionModal={handleCloseIncomeTransactionModal}
        />
      )}

      {showWithdrawalHistoryModal && (
        <WithdrawalHistoryModal
          date={date}
          show={showWithdrawalHistoryModal}
          handleCloseWithdrawalHistoryModal={handleCloseWithdrawalHistoryModal}
        />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  wallets: state.wallets.wallets,
  paymentSuccess: state.payments?.paymentSuccess,
});

export default (connect(mapStateToProps, actions)(LenderWallet));
