import { Formik } from "formik";
import React from "react";
import CheckBoxGroup from "../../../../components/element/CheckBoxGroup";
import CustomSelectGroup from "../../../../components/element/CustomSelectGroup";
import InputGroup2 from "../../../../components/element/InputGroup2";
import TextAreaGroup from "../../../../components/element/TextAreaGroup";
import { capitalizeFistLetterInSentence } from "../../../../utils/capitalizeFirstLetterInSentence";
import PreviousButton from "./componentsBit/PreviousButton";
import NextButton from "./componentsBit/NextButton";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";

const ItemFeature = ({
  hasOnBlur,
  hasLabel,
  hasName,
  hasValue,
  hasOnChange,
  type,
  name,
  value,
  onChange,
  handleClearButtonClick,
  hasCheckValue,
  ...props
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="text-sm lg:mb-6">
        <CheckBoxGroup
          label={<p className="pl-8 pt-1 text-sm text-temp-gray">{hasLabel}</p>}
          name={hasName}
          value={hasValue}
          onChange={hasOnChange}
          onBlur={hasOnBlur}
          checked={hasCheckValue ? true : false}
          {...props}
        />
      </div>
      <div className="lg:pl-14 mb-6 lg:mb-4 inline-flex">
        {hasCheckValue && (
          <>
            <input
              type={type || "text"}
              className="h-6 border-b border-temp-green-light focus:outline-none text-temp-gray text-sm"
              name={name}
              value={value}
              onChange={onChange}
            />
            <button
              type="button"
              onClick={handleClearButtonClick}
              className="text-temp-gray focus:outline-none text-xs hover:text-red-600 ml-3"
            >
              X
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const EquipmentInfoForm = ({
  initialState,
  handleChangeCurrentStep,
  loadingCategory,
  loadingType,
  processFetchTypeData,
  categories,
  types,
  features,
  hasFeatures,
  handeChangeFeatures,
  handleCheckedFeature,
  handleOnChangeCheckedInput,
  handleClearSelectedFeaturesField,
  selectedFeatures,
  handleShowAddEquipmentTypeField,
  showAddEquipmentTypeField,
}) => {
  const { serialNumbers } = initialState;

  const handleSubmitable = (isSubmitting, selectedFeatures, values, errors) => {
    if (isSubmitting) return false;
    if (Object.keys(errors).length !== 0) return false;
    if (
      values?.category?.value === "general" &&
      Object.values(selectedFeatures)?.length < 3
    )
      return true;
    if (Object.values(selectedFeatures)?.length < 3) return false;
    return true;
  };

  const handleSubmitForm = (values) => {
    values.serialNumbers = values.serialNumbers.map((e, i) => {
      const val = values[`serialNumber-${i + 1}`];
      delete values[`serialNumber-${i + 1}`];
      return val;
    });
    const payload = {
      name: values.name,
      category: values.category,
      type: values.type,
      serialNumbers: values.serialNumbers,
      description: values.description,
    };
    handleChangeCurrentStep("Step4", payload);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name || values.name.length < 1) {
      errors.name = "Item name is required";
    }

    if (!values.category || values.category.length < 1) {
      errors.category = "Item Category is required";
    }

    if (!values.type || values.type.length < 1) {
      errors.type = "Item Type is required";
    }

    if (!values.description || values.description.length < 1) {
      errors.description = "Description is required";
    }
    if (values.hasSerialNumber === true) {
      values.serialNumbers?.map((e, i) => {
        if (
          !values[`serialNumber-${i + 1}`] ||
          values[`serialNumber-${i + 1}`].length < 1
        ) {
          errors[`serialNumber-${i + 1}`] = `Serial number for equipment ${i + 1
            } is required`;
        }
      });
    }
    return errors;
  };
  return (
    <div className="flex justify-around lg:pr-10 xl:pr-20">
      <div className="w-4/12 hidden xl:block">
        <PreviousButton
          onClick={() => handleChangeCurrentStep("Step2")}
        />
      </div>
      <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12">
        <h2
          className="text-xl sm:text-2xl text-temp-primary font-medium"
        >
          Equipment Information
        </h2>

        <div className="mt-4">
          <Formik
            initialValues={{
              ...initialState,
              ...serialNumbers.reduce(
                (a, v, i) => ({ ...a, [`serialNumber-${i + 1}`]: v }),
                {}
              ),
            }}
            validate={validate}
            onSubmit={async (values) => {
              handleSubmitForm(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <form className="mt-14" onSubmit={handleSubmit}>
                <div className="mb-44 xl:mb-20">
                  <div className="group w-full">
                    <InputGroup2
                      autoComplete="off"
                      label={
                        <span>
                          Name of equipment<span className="text-red-400">*</span>
                        </span>
                      }
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.name && touched.name && errors.name}
                    />
                  </div>

                  <div className="group w-full">
                    <CustomSelectGroup
                      isLoading={loadingCategory}
                      isDisabled={loadingCategory ? true : false}
                      label={
                        <span>
                          Select Category<span className="text-red-400">*</span>
                        </span>
                      }
                      name="category"
                      value={values.category}
                      error={
                        errors.category && touched.category && errors.category
                      }
                      options={categories?.map((cat) => ({
                        value: cat.name,
                        label: capitalizeFistLetterInSentence(cat.name),
                      }))}
                      onChange={(e) => {
                        setFieldValue("category", e);

                        setFieldValue("type", "");

                        if (e.value === "general") {
                          handleShowAddEquipmentTypeField(true);
                          handeChangeFeatures([]);
                        } else {
                          handeChangeFeatures(
                            categories?.find((a) => a.name === e.value)?.features
                          );

                          processFetchTypeData(
                            categories?.find((a) => a.name === e.value)?._id
                          );
                          handleShowAddEquipmentTypeField(false);
                        }
                      }}
                    />
                  </div>

                  <div className="group w-full">
                    {showAddEquipmentTypeField ? (
                      <>
                        <InputGroup2
                          autoComplete="off"
                          label={
                            <span>
                              Equipment Type
                              <span className="text-red-400">*</span>
                            </span>
                          }
                          placeholder="e.g Chair, Table, etc."
                          name="type"
                          value={values.type}
                          onChange={(e) => {
                            setFieldValue("type", e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={errors.type && touched.type && errors.type}
                        />
                      </>
                    ) : (
                      <CustomSelectGroup
                        isLoading={loadingType}
                        isDisabled={loadingType ? true : false}
                        label={
                          <span>
                            Select equipment type
                            <span className="text-red-400">*</span>
                          </span>
                        }
                        name="type"
                        value={values.type}
                        error={errors.type && touched.type && errors.type}
                        options={types?.map((cat) => ({
                          value: cat.name,
                          label: capitalizeFistLetterInSentence(cat.name),
                        }))}
                        onChange={(e) => {
                          setFieldValue("type", e);
                        }}
                      />
                    )}
                  </div>

                  {values.hasSerialNumber && (
                    <div className="group w-full">
                      {values.serialNumbers?.map((no, i) => (
                        <InputGroup2
                          autoComplete="off"
                          key={`${no} ${i}`}
                          label={
                            <span>
                              Serial Number for Equipment {i + 1}
                              <span className="text-red-400">*</span>
                            </span>
                          }
                          name={`serialNumber-${i + 1}`}
                          value={values[`serialNumber-${i + 1}`]}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          error={
                            touched[`serialNumber-${i + 1}`] &&
                            errors[`serialNumber-${i + 1}`]
                          }
                        />
                      ))}
                    </div>
                  )}

                  <div className="mb-10 lg:mb-20">
                    <div className="group w-full">
                      <TextAreaGroup
                        label={
                          <span>
                            Description
                            <span className="text-red-400">*</span>
                          </span>
                        }
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.description &&
                          touched.description &&
                          errors.description
                        }
                        tip={
                          <span className="text-temp-green-500 leading-6">
                            Please briefly describe your equipment and its
                            features. Let the tempowner know what makes your
                            equipment unique. Tell them what added value comes
                            with the use of the equipment if any. Add a good and
                            clear picture of your equipment.
                            <br />
                            <span className="font-semibold">
                              PS: Be sincere with this information.
                            </span>
                          </span>
                        }
                        rows="10"
                      />
                    </div>

                    {features?.length > 0 && (
                      <div className="group w-full pt-6">
                        <label
                          htmlFor="uploadImage"
                          className="text-temp-primary"
                        >
                          Add Features<span className="text-red-400">*</span>
                        </label>

                        <div className="mt-6">
                          {features?.map((feature, index) => {
                            const hasFeatureName = `has${feature.name.replaceAll(
                              " ",
                              ""
                            )}`;
                            return (
                              <React.Fragment key={index}>
                                <ItemFeature
                                  hasLabel={feature?.name}
                                  hasName={hasFeatureName}
                                  hasValue={feature?.name}
                                  hasCheckValue={
                                    hasFeatures[index][hasFeatureName]
                                  }
                                  hasOnChange={(e) => {
                                    handleCheckedFeature(e, index);
                                  }}
                                  hasOnBlur={handleBlur}
                                  type={feature?.input}
                                  name={feature?.name}
                                  value={selectedFeatures[feature?.name] || ""}
                                  onChange={(e) => handleOnChangeCheckedInput(e)}
                                  handleClearButtonClick={() =>
                                    handleClearSelectedFeaturesField(
                                      feature?.name
                                    )
                                  }
                                />
                              </React.Fragment>
                            );
                          })}

                          <p className="text-sm text-temp-green-500 w-11/12 leading-7">
                            Make sure you add a minimum of{" "}
                            <strong>three (3) </strong>
                            features of the item you want to upload before
                            proceeding.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="hidden xl:inline-flex group w-full  flex-row flex-wrap justify-end text-right mb-28">
                    <NextButton
                      type="submit"
                      disabled={
                        !handleSubmitable(
                          isSubmitting,
                          selectedFeatures,
                          values,
                          errors
                        )
                      }
                      onClick={() => false}
                      title="Next"
                    />
                  </div>
                </div>

                {/* Mobile only */}
                <FixedBottomPrevNextButton
                  handlePrevClick={() => handleChangeCurrentStep("Step2")}
                  type="submit"
                  disabled={
                    !handleSubmitable(
                      isSubmitting,
                      selectedFeatures,
                      values,
                      errors
                    )
                  }
                  onClick={() => false}
                  title="Next"
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EquipmentInfoForm;
