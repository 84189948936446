import React from "react";
import { generateItemUrl } from "../../../utils/generateItemUrl";
import { numberFormat } from "../../../utils/numberFormat";
import ItemCard from "../ItemCard";

const ItemListing = ({ items, urlPath, gridClassName, ...props }) => {
  return (
    <div className="mb-10 md:mb-20">
      {props.children}

      <div className={`grid ${gridClassName || "grid-cols-2 md:grid-cols-2 lg:grid-cols-4"} gap-4 md:gap-8 justify-center md:justify-between`}>
        {items?.map((item) => (
          <div key={item?._id} className="item relative mb-4 md:mb-8">
            <ItemCard
              image={item.images[0]?.image?.url}
              title={item.name}
              url={generateItemUrl(urlPath, item)}
              amount={numberFormat(item.price?.amount)}
              userName={item.userId?.firstname}
              userImage={item.userId?.image}
              rating={item.rating}
              reviewCount={item.reviewCount}
              priceClassName="mt-2"
              pickupLocation={item?.pickupLocation}
              availableFrom={item.availableFrom}
              createdAt={item.createdAt}
              item={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemListing;
