import React from "react";
import UploadHeader from "./components/UploadHeader";
import UploadItemDetails from "./components/UploadItemDetails";
import UploadBorrowerDetails from "./components/UploadBorrowerDetails";
import styles from "./styles.module.css";

const LeftSideContainer = ({ itemDetails, orderDetails }) => {
  return (
    <div
      className={`hidden lg:block w-4/12 pr-8 h-screen min-h-screen ${styles.containerLeft}`}
    >
      <UploadHeader />
      <UploadItemDetails
        itemDetails={itemDetails}
        orderDetails={orderDetails}
      />
      <UploadBorrowerDetails
        itemDetails={itemDetails}
        orderDetails={orderDetails}
      />
    </div>
  );
};

export default LeftSideContainer;
