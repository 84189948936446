import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const getCategoriesApi = (params) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/category";
  try {
    const response = await axios.get(url, {
      params,
    });
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_CATEGORIES,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const getTypesApi = (categoryId, params) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/type/${categoryId}`;
  try {
    const response = await axios.get(url, {
      params,
    });
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_TYPES,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const addTypeApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/type";
  try {
    const response = await axios.post(url, payload);

    dispatch({
      type: Actions.ADD_TYPE,
      payload: "success",
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: Actions.ADD_TYPE,
      payload: "failed",
    });
    dispatch(alertActions.error(error.response?.data.message));
  }
};
