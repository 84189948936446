import React from "react";

export const FacebookIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20.7856 3.88789e-08H1.21336C0.543152 0.000335732 -0.000167808 0.543991 3.88789e-08 1.21437V20.7866C0.000335732 21.4568 0.543991 22.0002 1.21437 22H20.7856C21.4562 22.0002 21.9998 21.4567 22 20.7861C22 20.786 22 20.7858 22 20.7856V1.21336C21.9997 0.543152 21.456 -0.000167808 20.7856 3.88789e-08Z"
          fill="#4267B2"
        />
        <path
          d="M15.1895 22V13.4922H18.0576L18.4873 10.1621H15.1895V8.0412C15.1895 7.07927 15.4565 6.42383 16.8359 6.42383H18.584V3.45345C18.2798 3.41299 17.2363 3.32253 16.0221 3.32253C13.487 3.32253 11.752 4.8694 11.752 7.71138V10.1621H8.89453V13.4922H11.752V22H15.1895Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
