import { Transition } from "@headlessui/react";
import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from 'react-router-dom';
import Loader2 from "../../../../components/blocks/Loader2";
import InputGroup from "../../../../components/element/InputGroup";
import InputGroup2 from "../../../../components/element/InputGroup2";
import InputPasswordGroup from "../../../../components/element/InputPasswordGroup";
import AlertModal from "../../../../components/modals/AlertModal";
import LoadingModal from "../../../../components/modals/LoadingModal";
import * as actions from "../../../../store/actions";

const ValidatePassword = ({
  handleCloseModal,
  handleNext,
  verifyPasswordApi,
  authMessage,
}) => {
  const initialState = {
    password: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Password too short i.e minimum of 6";
    }

    return errors;
  };

  // Go to next page if password is verified successfully
  useEffect(() => {
    if (authMessage === "Password verification successful") {
      handleNext(true);
    }
  }, [authMessage]);

  return (
    <div className="text-center">
      <h3
        className="leading-6 text-xl md:text-2xl font-medium text-temp-primary"
        id="modal-headline"
      >
        Enter password to continue
      </h3>
      <div className="mt-10">
        <Formik
          initialValues={initialState}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            const payload = {
              password: values.password,
            };
            await verifyPasswordApi(payload);

            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="w-full text-left">
                  <InputPasswordGroup
                    label="Enter password"
                    labelColor="text-temp-gray"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="flex mt-8 items-baseline justify-end flex-end">
                <button
                  onClick={handleCloseModal}
                  className="text-xs lg:text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={
                    (values.password.length < 6 || isSubmitting) && true
                  }
                  className="mt-5 mb-4 py-4 px-8 text-xs lg:text-sm bg-gradient font-bold text-white rounded-lg focus:outline-none"
                >
                  {isSubmitting ? "Validating... Please wait" : "Continue"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const WithdrawFundForm = ({
  handleCloseModal,
  withdrawFundsApi,
  getBankDetailsApi,
  bankDetails,
  addBankApi,
}) => {
  useEffect(() => {
    fetchBankDetails();
  }, []);

  const fetchBankDetails = async () => {
    setLoadingBankDetails(true);
    await getBankDetailsApi();
    setLoadingBankDetails(false);
  };
  const initialState = {
    amount: "",
  };

  const [loadingBankDetails, setLoadingBankDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showChangeBankDetailsModal, setshowChangeBankDetailsModal] =
    useState(false);

  const handleChangeBankDetails = () => {
    setshowChangeBankDetailsModal(!showChangeBankDetailsModal);
  };

  const handleCloseChangeModalAndRefreshPage = async () => {
    setshowChangeBankDetailsModal(false);
    await fetchBankDetails();
  };

  return (
    <div className="text-center">
      <h3
        className="leading-6 text-xl md:text-2xl font-medium text-temp-primary"
        id="modal-headline"
      >
        Withdraw Fund
      </h3>
      <div className="mt-10">
        <Formik
          initialValues={initialState}
          onSubmit={async (values) => {
            setLoading(true);

            const payload = {
              amount: values.amount,
            };

            try {
              await withdrawFundsApi(payload);
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          {({ values, handleChange, handleSubmit, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="w-full text-left">
                  <InputGroup2
                    type="text"
                    label="How much do you want to withdraw?"
                    labelColor="text-temp-gray"
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="text-left">
                <p className="text-temp-gray text-sm lg:text-base">
                  You are withdrawing in this account
                </p>

                <div className="flex py-8 px-6 bg-temp-green-200 mt-4">
                  {loadingBankDetails ? (
                    <Loader2 />
                  ) : (
                    <>
                      <div className="flex-shrink">
                        <svg
                          width="74"
                          height="74"
                          viewBox="0 0 74 74"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M72.8065 26.2581C73.4665 26.2581 74 25.7234 74 25.0645V5.96774C74 5.3089 73.4665 4.77419 72.8065 4.77419H71.6129V1.19355C71.6129 0.53471 71.0794 0 70.4194 0H3.58065C2.92061 0 2.3871 0.53471 2.3871 1.19355V4.77419H1.19355C0.533516 4.77419 0 5.3089 0 5.96774V25.0645C0 25.7234 0.533516 26.2581 1.19355 26.2581H2.3871V66.8387H1.19355C0.533516 66.8387 0 67.3734 0 68.0323V72.8065C0 73.4653 0.533516 74 1.19355 74H72.8065C73.4665 74 74 73.4653 74 72.8065V68.0323C74 67.3734 73.4665 66.8387 72.8065 66.8387H71.6129V26.2581H72.8065ZM4.77419 2.3871H69.2258V4.77419H4.77419V2.3871ZM2.3871 7.16129H71.6129V23.871H2.3871V7.16129ZM69.2258 38.1936H54.9032V31.0323H69.2258V38.1936ZM52.5161 38.1936H38.1936V31.0323H52.5161V38.1936ZM35.8065 38.1936H21.4839V31.0323H35.8065V38.1936ZM19.0968 38.1936H4.77419V31.0323H19.0968V38.1936ZM71.6129 71.6129H2.3871V69.2258H71.6129V71.6129ZM40.5806 66.8387H38.1936V48.9355C38.1936 48.2766 37.66 47.7419 37 47.7419H13.129C12.469 47.7419 11.9355 48.2766 11.9355 48.9355V66.8387H9.54839V45.3548H40.5806V66.8387ZM14.3226 66.8387V50.129H23.871V66.8387H14.3226ZM26.2581 50.129H35.8065V66.8387H26.2581V50.129ZM64.4516 66.8387H50.129V45.3548H64.4516V66.8387ZM66.8387 66.8387V44.1613C66.8387 43.5025 66.3052 42.9677 65.6452 42.9677H48.9355C48.2755 42.9677 47.7419 43.5025 47.7419 44.1613V66.8387H42.9677V44.1613C42.9677 43.5025 42.4342 42.9677 41.7742 42.9677H8.35484C7.69481 42.9677 7.16129 43.5025 7.16129 44.1613V66.8387H4.77419V40.5806H69.2258V66.8387H66.8387ZM69.2258 28.6452H4.77419V26.2581H69.2258V28.6452Z"
                            fill="#D9F1ED"
                          />
                          <path
                            d="M21.4834 9.54834H16.7092C16.0491 9.54834 15.5156 10.083 15.5156 10.7419V20.2903C15.5156 20.9491 16.0491 21.4838 16.7092 21.4838H21.4834C23.4575 21.4838 25.064 19.8773 25.064 17.9032C25.064 16.9865 24.7179 16.1499 24.1498 15.5161C24.7179 14.8823 25.064 14.0456 25.064 13.129C25.064 11.1549 23.4575 9.54834 21.4834 9.54834ZM21.4834 19.0967H17.9027V16.7096H21.4834C22.1422 16.7096 22.6769 17.2455 22.6769 17.9032C22.6769 18.5608 22.1422 19.0967 21.4834 19.0967ZM21.4834 14.3225H17.9027V11.9354H21.4834C22.1422 11.9354 22.6769 12.4713 22.6769 13.129C22.6769 13.7866 22.1422 14.3225 21.4834 14.3225Z"
                            fill="#D9F1ED"
                          />
                          <path
                            d="M32.2263 9.54834C29.5934 9.54834 27.4521 11.6896 27.4521 14.3225V21.4838H29.8392V17.9032H34.6134V21.4838H37.0005V14.3225C37.0005 11.6896 34.8593 9.54834 32.2263 9.54834ZM29.8392 15.5161V14.3225C29.8392 13.006 30.9099 11.9354 32.2263 11.9354C33.5428 11.9354 34.6134 13.006 34.6134 14.3225V15.5161H29.8392Z"
                            fill="#D9F1ED"
                          />
                          <path
                            d="M46.548 16.7094L41.5351 10.0255C41.2272 9.61497 40.6925 9.44668 40.2031 9.609C39.7149 9.77252 39.3867 10.2273 39.3867 10.7417V21.4836H41.7738V14.3223L46.7867 21.0062C47.0159 21.3117 47.3728 21.4836 47.7416 21.4836C47.8669 21.4836 47.9946 21.4633 48.1187 21.4227C48.6069 21.2592 48.9351 20.8045 48.9351 20.2901V9.54813H46.548V16.7094Z"
                            fill="#D9F1ED"
                          />
                          <path
                            d="M58.4845 9.54834H56.0974C56.0974 11.2408 55.1844 12.7614 53.7103 13.5706V9.54834H51.3232V21.4838H53.7103V17.4616C55.1844 18.2708 56.0974 19.7914 56.0974 21.4838H58.4845C58.4845 19.0072 57.1943 16.7765 55.0996 15.5161C57.1943 14.2557 58.4845 12.025 58.4845 9.54834Z"
                            fill="#D9F1ED"
                          />
                          <path
                            d="M53.7092 57.2903H60.8705C61.5305 57.2903 62.064 56.7556 62.064 56.0968V48.9355C62.064 48.2767 61.5305 47.7419 60.8705 47.7419H53.7092C53.0491 47.7419 52.5156 48.2767 52.5156 48.9355V56.0968C52.5156 56.7556 53.0491 57.2903 53.7092 57.2903ZM54.9027 50.129H59.6769V54.9032H54.9027V50.129Z"
                            fill="#D9F1ED"
                          />
                        </svg>
                      </div>

                      <div className="text-temp-primary ml-6 flex-shrink">
                        <p className="text-xs lg:text-sm mb-1">
                          {bankDetails?.accountName}
                        </p>
                        <p className="text-sm lg:text-base font-bold mb-1">
                          {bankDetails?.accountNumber}
                        </p>
                        <p className="text-xs lg:text-sm mb-1">
                          {bankDetails?.bankName}
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <button
                  onClick={handleChangeBankDetails}
                  type="button"
                  className="text-xs lg:text-sm font-bold text-temp-primary mr-8 mb-4 focus:outline-none mt-4"
                >
                  Change bank details
                </button>
              </div>

              <div className="flex items-baseline justify-end flex-end">
                <button
                  onClick={handleCloseModal}
                  className="text-xs lg:text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="mt-5 mb-4 py-4 px-8 text-xs lg:text-sm bg-gradient font-bold text-white rounded-lg focus:outline-none"
                >
                  Withdraw
                </button>
              </div>
            </form>
          )}
        </Formik>

        <ChangeBankDetails
          showChangeBankDetailsModal={showChangeBankDetailsModal}
          handleChangeBankDetails={handleChangeBankDetails}
          addBankApi={addBankApi}
          handleCloseChangeModalAndRefreshPage={
            handleCloseChangeModalAndRefreshPage
          }
        />
      </div>

      {/* Trigger modal after form submission */}
      {loading && <LoadingModal show={loading} />}
    </div>
  );
};

const ChangeBankDetails = ({
  showChangeBankDetailsModal,
  handleChangeBankDetails,
  addBankApi,
  handleCloseChangeModalAndRefreshPage,
}) => {
  const initialState = {
    accountName: "",
    accountNumber: "",
    bankCode: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.accountName) {
      errors.accountName = "Required";
    } else if (values.accountName.length < 1) {
      errors.accountName = "Account name is required.";
    }

    if (!values.accountNumber) {
      errors.accountNumber = "Required";
    } else if (!/.*?(\d)[^\d]*/g.test(values.accountNumber)) {
      errors.accountNumber = "Input must be a number";
    }

    if (!values.bankCode) {
      errors.bankCode = "Select your bank";
    }

    return errors;
  };

  const [banks, setBanks] = useState([]);

  const [bankFetchingError, setBankFetchingError] = useState("");

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = async () => {
    await axios
      .get("https://api.paystack.co/bank")
      .then((data) => setBanks(data.data.data))
      .catch((e) => setBankFetchingError(e));
  };

  return (
    <Transition
      show={showChangeBankDetailsModal}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="absolute w-11/12 bottom-16 right-2 bg-temp-white  custom-select-list rounded-xl">
        <div className="py-6 px-5 text-sm text-temp-primary cursor-pointer text-left">
          <p className="font-bold text-sm text-temp-primary">
            Change Bank Details
          </p>

          <div className="text-right mt-3">
            <div className="space-x-8">
              <Formik
                initialValues={initialState}
                validate={validate}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);

                  const payload = {
                    accountName: values.accountName,
                    accountNumber: values.accountNumber,
                    bankCode: values.bankCode,
                    bankName: banks?.find((a) => a?.code === values?.bankCode)
                      ?.name,
                  };

                  await addBankApi(payload);

                  handleCloseChangeModalAndRefreshPage();

                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="pt-6 pb-6 text-left md:pt-6 md:pb-4 lg:pt-8 lg:pb-8"
                  >
                    <InputGroup
                      placeholder="Account Number"
                      name="accountNumber"
                      value={values.accountNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.accountNumber &&
                        touched.accountNumber &&
                        errors.accountNumber
                      }
                    />

                    <InputGroup
                      placeholder="Account Name"
                      name="accountName"
                      value={values.accountName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.accountName &&
                        touched.accountName &&
                        errors.accountName
                      }
                    />

                    <div className="w-full">
                      <select
                        name="bankCode"
                        value={values.bankCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // error={
                        //   errors.bankCode && touched.bankCode && errors.bankCode
                        // }
                        className={`rounded-lg placeholder-temp-secondary text-sm sm:text-base w-full h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-temp-secondary focus:outline-none bg-white border ${
                          errors.bankCode
                            ? "border-red-600"
                            : "border-temp-gray-light"
                        }`}
                      >
                        <option>Bank</option>
                        {banks?.map((bank) => (
                          <option key={bank.id} value={bank.code}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {bankFetchingError && (
                      <p className="text-temp-primary py-4">
                        {bankFetchingError}
                      </p>
                    )}
                    <div className=" mt-3 lg:mt-4">
                      <button
                        onClick={() => handleChangeBankDetails()}
                        type="button"
                        className="text-temp-green-500 text-xs lg:text-sm font-bold focus:outline-none mr-4"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={
                          isSubmitting ||
                          values.accountName.length === 0 ||
                          values.accountNumber.length === 0 ||
                          values.bankCode.length === 0
                        }
                        type="submit"
                        onSubmit={handleSubmit}
                        className={` whitespace-nowrap inline-flex items-center justify-center rounded text-temp-white text-xs lg:text-sm font-bold px-6 py-3
                  ${
                    isSubmitting ||
                    values.accountName.length === 0 ||
                    values.accountNumber.length === 0 ||
                    values.bankCode.length === 0
                      ? "bg-temp-green-500 opacity-60"
                      : "bg-temp-green-500"
                  }`}
                      >
                        {isSubmitting ? "Process... Please wait" : "Update"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

const WithdrawFundsModal = ({ show, handleCloseModal, ...props }) => {
  const [isNext, setIsNext] = useState(false);

  const handleNext = () => {
    setIsNext(true);
  };

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>

              <div className="lg:mt-10">
                {!isNext ? (
                  <ValidatePassword
                    handleCloseModal={handleCloseModal}
                    handleNext={handleNext}
                    verifyPasswordApi={props.verifyPasswordApi}
                    authMessage={props.auth.verifyPassword}
                  />
                ) : (
                  <WithdrawFundForm
                    withdrawFundsApi={props.withdrawFundsApi}
                    getBankDetailsApi={props.getBankDetailsApi}
                    bankDetails={props.bankDetails}
                    handleCloseModal={handleCloseModal}
                    addBankApi={props.addBankApi}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alert: state.alert,
  payments: state.payments,
  bankDetails: state.banks.bankDetails,
});

export default (
  connect(mapStateToProps, actions)(WithdrawFundsModal)
);
