import React from "react";

export const MapIcon = ({ width, height }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99936 0C5.92548 0 2.61127 3.31421 2.61127 7.38809C2.61127 8.72925 3.21274 10.1717 3.23795 10.2325C3.43221 10.6936 3.81553 11.4098 4.09193 11.8296L9.15755 19.505C9.36485 19.8196 9.67168 20 9.99936 20C10.327 20 10.6339 19.8196 10.8412 19.5054L15.9072 11.8296C16.1841 11.4098 16.5669 10.6936 16.7612 10.2325C16.7864 10.1721 17.3875 8.72968 17.3875 7.38809C17.3875 3.31421 14.0732 0 9.99936 0ZM15.9598 9.89526C15.7864 10.3086 15.4296 10.9748 15.1815 11.3512L10.1154 19.0269C10.0154 19.1786 9.98371 19.1786 9.88376 19.0269L4.8177 11.3512C4.56955 10.9748 4.21274 10.3081 4.03934 9.89483C4.03195 9.87701 3.48045 8.54933 3.48045 7.38809C3.48045 3.79357 6.40484 0.869187 9.99936 0.869187C13.5939 0.869187 16.5183 3.79357 16.5183 7.38809C16.5183 8.55106 15.9655 9.88223 15.9598 9.89526Z"
        fill="#08A05C"
      />
      <path
        d="M9.99954 3.47705C7.84265 3.47705 6.0882 5.23194 6.0882 7.38839C6.0882 9.54485 7.84265 11.2997 9.99954 11.2997C12.1564 11.2997 13.9109 9.54485 13.9109 7.38839C13.9109 5.23194 12.1564 3.47705 9.99954 3.47705ZM9.99954 10.4305C8.32244 10.4305 6.95738 9.06593 6.95738 7.38839C6.95738 5.71086 8.32244 4.34624 9.99954 4.34624C11.6766 4.34624 13.0417 5.71086 13.0417 7.38839C13.0417 9.06593 11.6766 10.4305 9.99954 10.4305Z"
        fill="#08A05C"
      />
    </svg>
  );
};
