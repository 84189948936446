import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, } from "react-router-dom";
import store from "store";
import CheckedIcon from "../../assets/images/svg/checked.svg";
import SecuredIcon from "../../assets/images/svg/secured.svg";
import FixedBottomBox from "../../components/blocks/FixedBottomBox";
import Loader from "../../components/blocks/Loader";
import EquipmentDescription from "../../components/blocks/itemBits/EquipmentDescription";
import EquipmentFeatures from "../../components/blocks/itemBits/EquipmentFeatures";
import EquipmentImageGallery from "../../components/blocks/itemBits/EquipmentImageGallery";
import EquipmentLocationAndRating from "../../components/blocks/itemBits/EquipmentLocationAndRating";
import EquipmentName from "../../components/blocks/itemBits/EquipmentName";
import EquipmentProtect from "../../components/blocks/itemBits/EquipmentProtect";
import ItemNavigation from "../../components/blocks/itemBits/ItemNavigation";
import LenderInformation from "../../components/blocks/itemBits/LenderInformation";
import ReviewsList from "../../components/blocks/itemBits/ReviewsList";
import MainContainer from "../../components/layout/MainContainer";
import AlertModal from "../../components/modals/AlertModal";
import CheckItemAvailabilityModal from "../../components/modals/CheckItemAvailabilityModal";
import LoadingModal from "../../components/modals/LoadingModal";
import * as actions from "../../store/actions";
import { scrollToTop } from "../../utils/scrollToTop";
import CheckAvailabilityForm from "./components/CheckAvailabilityForm";
import ItemsYouMayLike from "./components/ItemsYouMayLike";
// import EquipmentSEODescription from "../../components/blocks/itemBits/EquipmentSEODescription";
import queryString from "query-string";
import EquipmentDiscountDetails from "../../components/blocks/itemBits/EquipmentDiscountDetails";

const removeIDsFromStorage = () => {
  store.remove("pt_id");
  store.remove("extEND_bool1");
  store.remove("extEND_orderId");
};

const ItemOverview = (props) => {
  const location = useLocation();
  const [itemId, setItemId] = useState(null);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [checkLoading, setCheckLoading] = useState(false);
  const [checkAvailabilityError, setCheckAvailabilityError] = useState("");

  const [days, setDays] = useState([]);
  const [daysDifferenceError, setDaysDifferenceError] = useState("");
  const handleDaysRangeChange = (dateObjects) => {
    setDaysDifferenceError("");
    setCheckAvailabilityError("");
    setDays(dateObjects.map((dateObject) => dateObject.toDate()));

    const startD = moment(new Date(dateObjects[0]));
    const endD = moment(new Date(dateObjects[1]));
    const dayDifference = endD.diff(startD, "days");
    if (!isNaN(dayDifference) && dayDifference < item?.minimumBookingDays) {
      setDaysDifferenceError(
        "Please extend the rental period to at least the minimun rental period"
      );
    }
  };

  // To be used for SI UNIT EQUIPMENT TYPE
  const [unitOrdered, setUnitOrdered] = useState("");
  const [startDate, setStartDate] = useState("");
  const [error, setError] = useState("");
  const handleChangeStartDate = (value) => {
    setStartDate(new Date(value));
  };

  useEffect(() => {
    const parsed = queryString.parse(location?.search);

    setItemId(parsed?.pid);
  }, [queryString.parse(location?.search).pid]);

  useEffect(() => {
    scrollToTop();
    if (itemId) fetchItem(itemId);
  }, [itemId]);

  const fetchItem = async (id) => {
    setLoading(true);
    await props.getSingleItemApi(id);
    setLoading(false);
  };

  const fetchItems = async (params) => {
    setItemsLoading(true);
    await props.getItemsApi(params);
    setItemsLoading(false);
  };

  useEffect(() => {
    setItem(props.item);

    if (props?.item.type) {
      fetchItems({
        search: props.item.type,
        limit: 4,
      });
    }
  }, [props.item]);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const handleCheckItemAvailability = async (e) => {
    e.preventDefault();

    //  Remove the ids from localstorage
    removeIDsFromStorage();

    if (item?.rentalUnitType === "bySiUnits") {
      if (!unitOrdered) {
        setError("Enter the estimate needed to use the equipment");
      } else {
        setError("");
        setCheckAvailabilityError("");
        setCheckLoading(true);

        const payload = {
          itemId: itemId,
          startDate: new Intl.DateTimeFormat("en-US").format(startDate),
          quantity: selectedQuantity,
          unitOrdered: parseInt(unitOrdered || 1),
        };

        await props.checkItemAvailabilityApi(
          item?.rentalUnitType,
          payload,
          props.history
        );
        setCheckLoading(false);
      }
    } else {
      const firstDate = new Date(days[0]).getTime();
      const secondDate = new Date(days[1]).getTime();

      if (firstDate < secondDate) {
        setCheckAvailabilityError("");

        setCheckLoading(true);
        const payload = {
          itemId: itemId,
          startDate:
            days?.length > 0 &&
            new Intl.DateTimeFormat("en-US").format(days[0]),
          endDate:
            days?.length > 0 &&
            new Intl.DateTimeFormat("en-US").format(days[1]),
          quantity: selectedQuantity,
        };

        await props.checkItemAvailabilityApi(
          item?.rentalUnitType,
          payload,
          props.history
        );
        setCheckLoading(false);
      } else {
        setCheckAvailabilityError(
          "End Date cannot be on the same day. If you plan to use it for 1 day, select the next date so your order span across the 24hrs"
        );
      }
    }
  };

  const [selectedMenu, setSelectedMenu] = useState("overview");
  const handleSelectedMenu = (menu) => {
    setSelectedMenu(menu);

    document.getElementById(`${menu}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  const [showItemAvailabilityModal, setShowItemAvailabilityModal] = useState(
    false
  );
  const handleCheckItemAvailabilityModalShow = () => {
    setShowItemAvailabilityModal(true);
  };

  /**
   * Remove the order id from
   * localstorage once component gets unmounted
   */
  useEffect(() => {
    return () => {
      removeIDsFromStorage();
    };
  }, []);

  const [quantityAvailabilityError, setQuantityAvailabilityError] = useState(
    ""
  );
  const handleChangeIncreaseOrDecrease = (type, value) => {
    setQuantityAvailabilityError("");
    switch (type) {
      case "increment":
        setSelectedQuantity(
          selectedQuantity + 1 > item?.totalQuantity
            ? item?.totalQuantity
            : selectedQuantity + 1
        );
        break;
      case "decrement":
        setSelectedQuantity(
          selectedQuantity - 1 < 1 ? 1 : selectedQuantity - 1
        );
        break;
      case "default":
        if (value < 1 || value > item?.totalQuantity) {
          setQuantityAvailabilityError(
            item?.totalQuantity === 1
              ? "Make sure the quantity entered is 1"
              : `Make sure the quantity entered is between 1 and ${item?.totalQuantity}.`
          );
        }
        setSelectedQuantity(value);
        break;
      default:
        break;
    }
  };

  return (
    <MainContainer
      pageTitle={item?.name}
      pageDescription={item?.description}
      hasMainContainer={false}
    >
      <ItemNavigation
        selectedMenu={selectedMenu}
        handleSelectedMenu={handleSelectedMenu}
      />
      <div id="overview">
        <div className="w-full pb-20">
          {loading ? (
            <div className="container mx-auto px-4">
              <div className="py-32 lg:py-52">
                <Loader />
              </div>
            </div>
          ) : (
            <>
              <div className="relative mb-10 lg:mb-10 flex flex-wrap items-start text-temp-primary">
                <div className="w-full lg:w-6/12 xl:w-7/12 lg:pt-8 mt-8 lg:mt-0 mb-12 order-2 lg:order-1 pr-4 lg:pr-8 xl:pr-20 pl-4 lg:pl-6 xl:pl-36">
                  <div className="w-full mb-2 md:mb-4 lg:mb-8 mt-0  lg:mt-8">
                    <div className="w-full lg:w-9/12 xl:w-7/12">
                      <EquipmentName item={item} />
                    </div>

                    <EquipmentLocationAndRating item={item} />

                    <EquipmentImageGallery item={item} />

                    <EquipmentProtect item={item} />

                    <div className="mt-6 mb-6 block lg:hidden">
                      <EquipmentDiscountDetails item={item} />
                    </div>

                    <EquipmentDescription item={item} />

                    <EquipmentFeatures item={item} />

                    <ReviewsList item={item} />
                  </div>
                </div>

                <div className="w-full lg:w-6/12 xl:w-5/12 lg:mb-12 order-1 lg:order-2 hidden lg:block sticky top-36">
                  <div className="check-availability-form-section pt-8 pb-10 mx-auto px-0 md:px-10 lg:px-12 xl:px-16">
                    <p className="text-lg font-semibold pb-4 text-temp-green-500">
                      Check Availability
                    </p>

                    <div className="mb-6">
                      <EquipmentDiscountDetails item={item} />
                    </div>

                    <CheckAvailabilityForm
                      item={item}
                      days={days}
                      itemId={itemId}
                      onChange={handleDaysRangeChange}
                      handleCheckItemAvailability={handleCheckItemAvailability}
                      checkAvailabilityError={checkAvailabilityError}
                      selectedQuantity={selectedQuantity}
                      handleChangeIncreaseOrDecrease={
                        handleChangeIncreaseOrDecrease
                      }
                      quantityAvailabilityError={quantityAvailabilityError}
                      daysDifferenceError={daysDifferenceError}
                      startDate={startDate}
                      handleChangeStartDate={handleChangeStartDate}
                      unitOrdered={unitOrdered}
                      setUnitOrdered={setUnitOrdered}
                      error={error}
                    />

                    <div className=" flex-wrap flex-1 mt-2 mb-8 hidden lg:flex">
                      <div className="flex items-center mr-8">
                        <img className="mr-3 w-4" src={CheckedIcon} alt="" />
                        <p className="text-temp-green-500 text-xs md:text-sm">
                          Verified Lenders
                        </p>
                      </div>
                      <div className="flex items-center mr-8">
                        <img className="mr-3 w-4" src={SecuredIcon} alt="" />
                        <p className="text-temp-green-500 text-xs md:text-sm ">
                          Secured plaform
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container mx-auto px-4">
                <LenderInformation item={item} />

                {/* <EquipmentSEODescription /> */}
              </div>
            </>
          )}
        
            <ItemsYouMayLike
              itemsLoading={itemsLoading}
              items={items?.filter((a) => a._id !== item?._id)}
              item={item}
            />

            {/* Mobile only */}
            <FixedBottomBox
              title="Rent this item now"
              buttonName="Check Availability"
              onClick={handleCheckItemAvailabilityModalShow}
            />
        </div>
      </div>

      {showItemAvailabilityModal && (
        <CheckItemAvailabilityModal
          item={item}
          days={days}
          show={showItemAvailabilityModal}
          itemId={itemId}
          onChange={handleDaysRangeChange}
          handleCheckItemAvailability={handleCheckItemAvailability}
          checkAvailabilityError={checkAvailabilityError}
          selectedQuantity={selectedQuantity}
          handleChangeIncreaseOrDecrease={handleChangeIncreaseOrDecrease}
          quantityAvailabilityError={quantityAvailabilityError}
          daysDifferenceError={daysDifferenceError}
          handleCloseModal={() => {
            setCheckAvailabilityError("");
            setShowItemAvailabilityModal(false);
          }}
          title="Check Availability"
          showCancelButton={false}
          startDate={startDate}
          handleChangeStartDate={handleChangeStartDate}
          unitOrdered={unitOrdered}
          setUnitOrdered={setUnitOrdered}
          error={error}
        />
      )}

      {checkLoading && (
        <LoadingModal
          show={checkLoading}
          title="Checking ..."
          content="Please wait while we check for the availability of the item"
        />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkTitle="Pay Outstanding"
          linkUrl={
            props?.alert?.message ===
            "Please complete all outstanding payments before you make a new booking."
              ? "/late-rental"
              : ""
          }
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  items: state.items.items,
  item: state.items.item,
  alert: state.alert,
});

export default (connect(mapStateToProps, actions)(ItemOverview));
