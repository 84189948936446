import React from "react";

const IncomeTransactionCard = ({ handleShowModal, amount, date }) => {
  return (
    <div className="wallet-wrapper mb-10 lg:mb-6 h-full">
      <div
        className="wallet-card rounded-lg py-12 lg:py-16 px-8 h-full"
        style={{ backgroundColor: "#07485E" }}
      >
        <div className="card-title pr-8">
          <p className="font-serif pb-4" style={{ color: "#9CFFAC" }}>
            Total Income (
            {date ?
              new Date(date).toLocaleDateString("en-us", { month: "long" })
              : new Date().toLocaleDateString("en-us", { month: "long" })
            })
          </p>
          <p className="text-white text-2xl lg:text-3xl font-semibold">
            NGN {amount}
          </p>
        </div>
      </div>
      <div className="pt-6">
        <button
          onClick={handleShowModal}
          className="text-temp-primary font-semibold focus:outline-none text-sm lg:text-base"
        >
          View transactions 📃
        </button>
      </div>
    </div>
  );
};

export default IncomeTransactionCard;
