import React from 'react';
import { Transition } from "@headlessui/react";
import { capitalizeFistLetterInSentence } from '../../../utils/capitalizeFirstLetterInSentence';

const Card = ({ icon, title, handleClick }) => {
    return <button
        onClick={handleClick}
        className="outline-none focus:outline-none mb-6 w-full text-center category-icon-card">
        <div className="py-3 px-3 rounded-xl bg-temp-green-100 w-20 lg:w-24 h-20 lg:h-24 flex justify-center items-center mx-auto">
            <img src={icon} alt="" />
        </div>
        <p className="text-sm mt-4 text-center">{title}</p>
    </button>
}

const CategorySelectorModal = ({ show, handleCloseModal, categories = [], handleCategoryChange }) => {
    return <>
        {/* Desktop Only */}
        <div className='hidden md:block w-4/12 rounded-3xl px-8 py-8 z-20 transform transition-all absolute left-40 top-48 bg-white  shadow-2xl category-modal-lg'>
            <div className="w-full text-left grid grid-cols-3 gap-x-4 gap-y-8">
                {
                    categories?.map(cat => <Card
                        key={cat?.id}
                        title={capitalizeFistLetterInSentence(cat?.name)}
                        icon={cat?.icon}
                        handleClick={() => {
                            handleCategoryChange({
                                value: cat.name,
                                label: capitalizeFistLetterInSentence(cat.name),
                            })
                            handleCloseModal()
                        }}
                    />)
                }
            </div>
        </div>

        {/* Mobile Only */}
        <div className="block md:hidden fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={show}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </Transition>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block absolute bottom-0 left-0 align-bottom bg-white rounded-t-3xl text-left overflow-auto shadow-xl transform transition-all w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="relative bg-white px-8 pt-14 pb-10">
                        <button
                            onClick={handleCloseModal}
                            className="absolute text-temp-green-500 text-lg rounded-full shadow-lg  pt-1 px-2 right-8 top-4 focus:outline-none"
                        >
                            x
                        </button>
                        <div className="text-left">
                            <h3
                                className="leading-6 text-lg md:text-xl text-temp-primary text-left font-semibold"
                                id="modal-headline"
                            >
                                Select Category
                            </h3>
                        </div>

                        <div className="mt-10">
                            <div className="w-full text-left grid grid-cols-3 gap-x-4 gap-y-2">
                                {
                                    categories?.map(cat => <Card
                                        key={cat?.id}
                                        title={capitalizeFistLetterInSentence(cat?.name)}
                                        icon={cat?.icon}
                                        handleClick={() => {
                                            handleCategoryChange({
                                                value: cat.name,
                                                label: capitalizeFistLetterInSentence(cat.name),
                                            })
                                            handleCloseModal()
                                        }}
                                    />)
                                }
                            </div>

                            <div className="flex mt-4 md:mt-8 items-baseline justify-center flex-col-reverse md:flex-row md:justify-end md:flex-end">
                                <button
                                    onClick={handleCloseModal}
                                    className="mt-4 md:mt-0 text-center md:text-left w-full md:w-auto text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

};

export default CategorySelectorModal;
