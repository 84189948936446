import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    color: "#000000",
    boxShadow: "none",
    border: state.isFocused || state.isSelected ? "1px solid #d1d1d1" : "",
    outline: state.isFocused ? "none" : "",
    height: "3.5rem",
    borderRadius: "0.5rem",
  }),
  container: (provided, state) => ({
    ...provided,
    color: "#000000",
    boxShadow: "none",
    border: "none",
    outline: state.isFocused ? "none" : "",
  }),
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#000000",
    backgroundColor: state.isFocused ? "#EFFBF9" : "",
    padding: 17,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = state.isFocused ? "#EFFBF9" : "";

    return { ...provided, opacity, color, transition };
  },
};

const CustomSelectGroup = ({
  className,
  label,
  name,
  options,
  error,
  onChange,
  onBlur,
  placeholder,
  ...props
}) => {
  return (
    <div className={className || "mb-8"}>
      <label htmlFor={name} className="text-temp-primary text-sm lg:text-base">
        {label}
      </label>
      <Select
        styles={customStyles}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        options={options}
        className="mt-2 text-sm lg:text-base"
        placeholder={placeholder}
        {...props}
      />

      <span className="text-red-600 text-sm pt-3 block">{error}</span>
    </div>
  );
};

export default CustomSelectGroup;
