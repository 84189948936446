import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactImage from "../../assets/images/png/faq/contact.webp";
import MainContainer from '../../components/layout/MainContainer';
import { faqsDataForOwners, faqsDataForTempOwners } from '../../utils/faqsData';
import { scrollToTop } from '../../utils/scrollToTop';

const FaqDetails = () => {
    const history = useNavigate();
    const [selectedFaq, setSelectedFaq] = useState("")
    const [data, setData] = useState([]);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        const location = history?.location;
        const title = location.pathname?.replaceAll("/faqs/", "")
        setSelectedFaq(title[0].toUpperCase() + title.slice(1));

        const search = location.search?.replaceAll("?type=", "");
        if (search === "tempowners") {
            setData(faqsDataForTempOwners?.filter(a => a?.type?.includes(title)))
        } else {
            setData(faqsDataForOwners?.filter(a => a?.type?.includes(title)))
        }
    }, [])

    return (
        <MainContainer hasMainContainer={false}>
            <div className="container relative mx-auto px-4 pb-24 lg:pb-32">
                <div className='mt-6 lg:mt-10 flex flex-row items-center'>
                    <Link to={"/faqs"} className="flex flex-row items-center">
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.639841 3.64002C0.441029 3.83883 0.441029 4.16117 0.639841 4.35998L3.87968 7.59982C4.07849 7.79863 4.40083 7.79863 4.59964 7.59982C4.79845 7.401 4.79845 7.07867 4.59964 6.87985L1.71979 4L4.59964 1.12015C4.79845 0.921335 4.79845 0.598997 4.59964 0.400184C4.40083 0.201372 4.07849 0.201372 3.87968 0.400184L0.639841 3.64002ZM11.1816 3.49091L0.999823 3.49091L0.999823 4.50909L11.1816 4.50909L11.1816 3.49091Z" fill="black" />
                        </svg>
                        <span className='ml-2 text-sm lg:text-sm-15'>
                            Back
                        </span>
                    </Link>
                </div>

                <div className='mt-12 lg:mt-16 flex flex-row flex-wrap'>
                    <div className='w-full lg:w-7/12 xl:w-6/12'>
                        <div className=''>
                            <h1 className='text-3xl md:text-4xl lg:text-4xl text-temp-green-500 font-semibold'>{selectedFaq}</h1>
                            <p className='mt-4 text-sm-15 lg:text-base text-temp-primary'>{selectedFaq} information on the TempOwn platform</p>
                        </div>
                        <div className='mt-10 lg:mt-12'>
                            {
                                data?.map(datum => <div key={datum?.id} className='mb-6 lg:mb-12'>
                                    <p className='text-base lg:text-lg font-semibold text-temp-primary'>{datum?.question}</p>
                                    <p className='mt-4 text-sm-15 lg:text-base text-temp-primary'>{datum?.answer}</p>
                                </div>)
                            }
                        </div>
                    </div>

                    <div className='w-full lg:w-4/12 mx-auto mt-8 lg:mt-0'>
                        <div className=''>
                            <img src={ContactImage} alt="" />
                        </div>
                        <div className='mt-6 text-sm-15 lg:text-base'>
                            <p>Incase you didnt get answer to any of your questions. Feel free to reach out to use</p>
                        </div>
                        <div className='mt-4'>
                            <a href="mailto:hello@tempown.com"
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-temp-green-500 underline font-semibold text-sm-15 lg:text-base focus:outline-none'>
                                Contact us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}

export default FaqDetails