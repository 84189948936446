import React from "react";
import ItemImageGallery from "../../../components/blocks/ItemImageGallery";

const EquipmentImageGallery = ({ item }) => {
  return (
    <div className="preview mt-3 lg:mt-6 mb-8">
      {item?.images?.length > 0 && <ItemImageGallery images={item?.images} />}
    </div>
  );
};

export default EquipmentImageGallery;
