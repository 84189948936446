import React from "react";

export const CheckMarkIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2385 24.8091C20.0894 24.8091 25.6431 19.2554 25.6431 12.4046C25.6431 5.55371 20.0894 0 13.2385 0C6.38769 0 0.833984 5.55371 0.833984 12.4046C0.833984 19.2554 6.38769 24.8091 13.2385 24.8091Z"
        fill="#4CE797"
      />
      <path
        d="M13.2393 22.3409C18.7269 22.3409 23.1756 17.8922 23.1756 12.4046C23.1756 6.91689 18.7269 2.46826 13.2393 2.46826C7.75161 2.46826 3.30298 6.91689 3.30298 12.4046C3.30298 17.8922 7.75161 22.3409 13.2393 22.3409Z"
        fill="#E7F8FC"
      />
      <path
        d="M11.5738 17.4323C11.2852 17.4326 11.0076 17.3214 10.7989 17.122L7.4712 13.9292C7.36509 13.8274 7.28007 13.7057 7.22098 13.5711C7.1619 13.4365 7.1299 13.2915 7.12683 13.1446C7.12376 12.9976 7.14967 12.8514 7.20308 12.7144C7.25649 12.5775 7.33636 12.4524 7.43812 12.3463C7.53988 12.2402 7.66155 12.1551 7.79617 12.096C7.93079 12.037 8.07573 12.005 8.22272 12.0019C8.3697 11.9988 8.51585 12.0247 8.65282 12.0781C8.7898 12.1316 8.91491 12.2114 9.02101 12.3132L11.4919 14.6831L17.3793 7.77103C17.474 7.65691 17.5904 7.56274 17.7217 7.49396C17.8531 7.42518 17.9968 7.38318 18.1445 7.37039C18.2922 7.35759 18.441 7.37426 18.5822 7.41943C18.7235 7.4646 18.8543 7.53736 18.9672 7.6335C19.0801 7.72964 19.1727 7.84724 19.2398 7.97948C19.3069 8.11172 19.347 8.25596 19.3579 8.40383C19.3688 8.5517 19.3502 8.70026 19.3032 8.84089C19.2562 8.98152 19.1818 9.11142 19.0842 9.22305L12.4261 17.0386C12.3272 17.1547 12.2056 17.2495 12.0687 17.3169C11.9318 17.3844 11.7826 17.4232 11.6302 17.4309C11.6115 17.4318 11.5928 17.4323 11.5738 17.4323Z"
        fill="#4CE797"
      />
    </svg>
  );
};
