import React from "react";

const EquipmentSEODescription = ({title, seoContent}) => {
  return (
    <section className="mt-6 lg:mt-12 xl:mt-20 mb-14 lg:mb-20 equipment-seo-section rounded-xl py-6 lg:py-10 px-6 lg:px-12">
      <p className="text-base lg:text-lg xl:text-xl font-semibold text-temp-secondary pb-4">
        {title || "Title"}
      </p>
      <p className="text-temp-gray text-xs lg:text-sm leading-6 lg:leading-7">
        {seoContent}
      </p>
    </section>
  );
};

export default EquipmentSEODescription;
