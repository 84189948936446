import { Formik } from "formik";
import React, { useState } from "react";
import MultipleDatePicker from "../../../../components/element/MultipleDatePicker";
import CustomSelectGroup from "../../../../components/element/CustomSelectGroup";
import PreviousButton from "./componentsBit/PreviousButton";
import NextButton from "./componentsBit/NextButton";
import TipCard from "./componentsBit/TipCard";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";

const AvailabilityForm = ({ initialState, handleChangeCurrentStep }) => {
  const [minRental, setMinRental] = useState(initialState?.minimumRentalDays);
  const minimumRentalOptions = [
    { value: 1, label: "Daily" },
    { value: 7, label: "Weekly" },
    { value: 30, label: "Monthly" },
  ];

  const handleMinRental = (val) => {
    setMinRental(val);
  };
  const handleSubmitForm = (values) => {
    const payload = {
      days: values.days,
      minimumRentalDays: minRental,
    };

    handleChangeCurrentStep("Step8", payload);
  };
  return (
    <div className="flex justify-around xl:pr-20">
      <div className="w-4/12 hidden xl:block">
        <PreviousButton
          onClick={() => handleChangeCurrentStep("Step6")}
        />
      </div>
      <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12">
        <h2
          className="text-xl sm:text-2xl text-temp-primary font-medium"
        >
          Availability Calendar
        </h2>

        <TipCard
          text="Your equipment is always available. Select the days you don&apos;t want
          it available. You can always make changes after you publish."
        />

        <div className="mt-4">
          <Formik initialValues={initialState}>
            {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
              <form className="mt-14" onSubmit={handleSubmit}>
                <div className="group w-full mb-44 xl:mb-20">
                  {initialState?.rentalUnitType?.value === "byCalender" &&
                    initialState?.calenderType?.value === "day" && (
                      <CustomSelectGroup
                        label="Minimum Rental period"
                        name="minimum availability"
                        options={minimumRentalOptions}
                        error={null}
                        onChange={handleMinRental}
                        value={minRental}
                      />
                    )}

                  <div className="">
                    <MultipleDatePicker
                      datePickerType="calendar"
                      name="days"
                      value={values.days}
                      placeholder="Set Calendar"
                      onChange={(dateObjects) => {
                        setFieldValue(
                          "days",
                          dateObjects.map((dateObject) => dateObject.toDate())
                        );
                      }}
                      multiple
                    />{" "}
                  </div>
                  <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mt-14 mb-28">
                    <NextButton
                      disabled={isSubmitting}
                      onClick={() => handleSubmitForm(values)}
                      title="Next"
                    />
                  </div>
                </div>
                <FixedBottomPrevNextButton
                  handlePrevClick={() => handleChangeCurrentStep("Step6")}
                  disabled={isSubmitting}
                  onClick={() => handleSubmitForm(values)}
                  title="Next"
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityForm;
