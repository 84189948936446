import React from "react";
import UserRatingCard from "../../../components/blocks/UserRatingCard";
import Avatar from "../../../assets/images/svg/user.svg";
import { Link } from "react-router-dom";

const ChatHeader = ({ seletectedChatUser }) => {
  return (
    <div
      className={`${
        !seletectedChatUser && "h-24"
      } relative z-10 chat-header rounded-t-xl w-full flex flex-wrap flex-row justify-between items-center pt-3 px-6`}
    >
      {seletectedChatUser && (
        <>
          <UserRatingCard
            image={seletectedChatUser?.profilePicture || Avatar}
            name={seletectedChatUser?.firstName}
            membershipStartDate={seletectedChatUser?.accountCreatedAt}
            showStarRating={false}
            rating={0}
            padding="p-0"
            backgroundColor="bg-none"
            starRatedColor="#ffffff"
          />

          <div className="text-right md:text-left w-full md:w-auto mb-2 md:mb-0 -mt-6 md:-mt-4">
            <Link
              to={
                seletectedChatUser?.userType === "borrower"
                  ? `/borrower-profile/${seletectedChatUser?.userId}`
                  : `/lender-profile/${seletectedChatUser?.userId}`
              }
              className="text-white text-xs md:text-sm lg:text-base font-medium"
            >
              View Profile
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatHeader;
