/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { } from "react-router";
import AlertModal from "../../components/modals/AlertModal";
import * as actions from "../../store/actions";
import LeftSectionPendingAndAccepted from "./components/LeftSectionPendingAndAccepted";
import OverdueOrderCard from "./components/OverdueOrderCard";

const OverdueDetails = ({
  orderDetails,
  itemDetails,
  loggedInUserDetails,
  ...props
}) => {
  return (
    <div className="my-10 relative flex flex-wrap text-temp-primary">
      <div className="w-full lg:w-5/12 xl:w-6/12 mb-12">
        <LeftSectionPendingAndAccepted
          orderDetails={orderDetails}
          itemDetails={itemDetails}
          withEquipmentPickupNote={true}
          loggedInUserDetails={loggedInUserDetails}
        >
          {/* Mobile only */}
          <div className="mb-12 mt-6 block lg:hidden">
            <OverdueOrderCard loggedInUserDetails={loggedInUserDetails} orderDetails={orderDetails} />
          </div>
        </LeftSectionPendingAndAccepted>
      </div>

      {/* Desktop only */}
      <div className="hidden lg:block w-full lg:w-6/12 xl:w-5/12 mx-auto px-0 md:px-10 lg:mb-12 mb-6">
        <OverdueOrderCard loggedInUserDetails={loggedInUserDetails} orderDetails={orderDetails} />
      </div>

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl="/rental-activities"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default (connect(mapStateToProps, actions)(OverdueDetails));
