import React from "react";
import { Transition } from "@headlessui/react";
import InputPhoneNumberGroup from "../../../components/element/InputPhoneNumberGroup";

const ChangePhoneNumberModal = ({
  show,
  newPhoneNumber,
  onChange,
  handleCloseModal,
  onBlur,
  onSubmit,
  error,
}) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block absolute md:relative bottom-0 md:bottom-auto left-0 align-bottom md:align-center bg-white rounded-t-3xl md:rounded-2xl text-left overflow-auto shadow-xl transform transition-all md:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="relative bg-white px-8 pt-14 pb-10">
            <button
              onClick={handleCloseModal}
              className="absolute text-temp-green-500 text-lg rounded-full shadow-lg  pt-1 px-2 right-8 top-4 focus:outline-none"
            >
              x
            </button>
            <div className="text-left">
              <h3
                className="leading-6 text-lg md:text-xl text-temp-green-500 text-center font-semibold"
                id="modal-headline"
              >
                Change Number
              </h3>
            </div>

            <div className="mt-10">
              <form onSubmit={onSubmit}>
                <div className="w-full text-left">
                  <InputPhoneNumberGroup
                    type="tel"
                    label="Please enter your new mobile number"
                    placeholder="Please enter your new mobile number"
                    name="phone"
                    value={newPhoneNumber}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                  />
                </div>

                <div className="flex mt-4 md:mt-8 items-baseline justify-center flex-col-reverse md:flex-row md:justify-end md:flex-end">
                  <button
                    onClick={handleCloseModal}
                    className="mt-4 md:mt-0 text-center md:text-left w-full md:w-auto text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="w-full md:w-auto mt-5 mb-4 py-3 px-8 text-sm bg-gradient font-bold text-white rounded-xl focus:outline-none"
                  >
                    Change
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePhoneNumberModal;
