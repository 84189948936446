/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router-dom";
import store from "store";
import Loader from "../../components/blocks/Loader";
import MainContainer from "../../components/layout/MainContainer";
import AlertModal from "../../components/modals/AlertModal";
import * as actions from "../../store/actions";
import Paginator from "../../utils/pagination";
import AllActivities from "./components/AllActivities";
import EmptyActivities from "./components/EmptyActivities";
import PageHeader from "./components/PageHeader";
import SelectActivitiesOptions from "./components/SelectActivitiesOptions";

const ActivitiesWrapper = ({ data = [] }) => {
  return (
    <>{data.length > 0 ? <AllActivities data={data} /> : <EmptyActivities />}</>
  );
};

// Main Wrapper
const RentalActivities = (props) => {
  const [userId, setUserId] = useState({});
  const [isLender, setIsLender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [selectedOption, setSelectedOption] = useState("all status");
  const [currentRentalOwner, setCurrentRentalOwner] = useState("ownedByYou");
  const [params, setParams] = useState({});

  const handleSwitchRentalOwner = (val) => {
    setCurrentRentalOwner(val);
    renderOrdersByOwnerStat(val);

    const ownedByYou = val === "ownedByYou" ? true : false;
    const rentedByYou = val === "rentedByYou" ? true : false;

    const newParams = {
        ...params,
        ownedByYou,
        rentedByYou,
      }

    setParams(newParams);
    fetchOrders(newParams);
  };

  const handleChangeSelectedOption = (value) => {
    setSelectedOption(value);
    if (value === "all status") {
      setFilteredData(data);

      const newParams = {
        ...params,
        progressStatus: undefined,
        status: undefined,
      }

      setParams(newParams);
      fetchOrders(newParams);
    } else {
      const param = {
        progressStatus:
          value === "ongoing"
            ? "on-going"
            : value === "completed"
            ? "completed"
            : value === "over-due"
            ? "over-due"
            : undefined,
        status:
          value === "accepted"
            ? "accepted"
            : value === "pending"
            ? "pending"
            : value === "canceled"
            ? "canceled"
            : value === "rejected"
            ? "rejected"
            : undefined,
      };

      const newParams = {
        ...params,
        ...param,
        page: pagination?.page || 1,
      }
      
      setParams(newParams);
      fetchOrders(newParams);
    }
  };

  useEffect(() => {
    const { _id, verified_lender } = store.get("temp_usER12_dATa");
    setUserId(_id);
    setIsLender(verified_lender);
    setCurrentRentalOwner(verified_lender ? "ownedByYou" : "rentedByYou");

    let param = {};
    param[verified_lender ? "ownedByYou" : "rentedByYou"] = true;

    setParams({
      ...params,
      [verified_lender ? "ownedByYou" : "rentedByYou"]: true,
    });

    fetchOrders(param);
  }, []);

  const fetchOrders = async (params) => {
    setLoading(true);
    await props.getOrdersApi(params);
    setLoading(false);
  };

  const renderOrdersByOwnerStat = (stat) => {
    setData(props.orders);
    setFilteredData(props.orders);
  };

  useEffect(() => {
    if (props.orders) renderOrdersByOwnerStat(currentRentalOwner);
    setPagination(props.pagination);
  }, [props.orders]);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="w-full pb-36 relative">
        <PageHeader
          currentRentalOwner={currentRentalOwner}
          handleSwitchRentalOwner={handleSwitchRentalOwner}
        />

        <SelectActivitiesOptions
          selectedOption={selectedOption}
          handleChangeSelectedOption={handleChangeSelectedOption}
        />

        {/* Display Cards */}
        {loading ? (
          <div className="my-14">
            <Loader />
          </div>
        ) : (
          <>
            <ActivitiesWrapper data={filteredData} />
            {filteredData?.length !== 0 && (
              <Paginator
                pageLimit={pagination?.limit}
                maxPages={pagination?.totalPages}
                currentPage={pagination?.page}
                getPageApi={fetchOrders}
                setLoading={setLoading}
                objectParams={params}
              />
            )}
          </>
        )}
      </div>

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    pagination: state.order.pagination,
    orders: state.order.orders,
    order: state.order.order,
    alert: state.alert,
  };
};

export default (connect(mapStateToProps, actions)(RentalActivities));
