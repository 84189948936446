import qs from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, } from "react-router-dom";
import store from "store";
import Loader from "../../components/blocks/Loader";
import MainContainer from "../../components/layout/MainContainer";
import AlertModal from "../../components/modals/AlertModal";
import * as actions from "../../store/actions";
import { scrollToTop } from "../../utils/scrollToTop";
import ApproveBookingDetails from "./ApproveBookingDetails";
import CancelledOrRejectedDetails from "./CancelledOrRejectedDetails";
import CompleteDetails from "./CompleteDetails";
import OngoingDetails from "./OngoingDetails";
import OverdueDetails from "./OverdueDetails";
import PendingApprovalDetails from "./PendingApprovalDetails";

const RentalActivitiesDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [itemDetails, setItemDetails] = useState({});
  const [loggedInUserDetails, setLoggedInUserDetails] = useState({});

  useEffect(() => {
    scrollToTop();

    setLoggedInUserDetails(store.get("temp_usER12_dATa"));

    const queryString = qs.parse(props.location.search);

    const orderId = queryString?.id;
    const itemId = queryString?.itemId;

    fetchOrderDetails(orderId, itemId);
  }, []);

  const fetchOrderDetails = async (orderId, itemId) => {
    setLoading(true);
    await props.getSingleItemApi(itemId);
    await props.getSingleOrderApi(orderId);
    setLoading(false);
  };

  useEffect(() => {
    setOrderDetails(props.order);
  }, [props.order]);

  useEffect(() => {
    setItemDetails(props.item);
  }, [props.item]);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="container mx-auto px-4 w-full pt-12 pb-20 relative">
        <div className="mb-6">
          <p className="text-sm lg:text-base text-temp-gray cursor-pointer">
            <Link to="/rental-activities">&lt; Back </Link>
          </p>
        </div>
        <div>
          {loading ? (
            <div className="my-14">
              <Loader />
            </div>
          ) : (
            <>
              {orderDetails?.status === "accepted" &&
              orderDetails?.progressStatus === "not-started" ? (
                <ApproveBookingDetails
                  orderDetails={orderDetails}
                  itemDetails={itemDetails}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ) : orderDetails?.status === "pending" ? (
                <PendingApprovalDetails
                  orderDetails={orderDetails}
                  itemDetails={itemDetails}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ) : orderDetails?.status === "accepted" &&
                orderDetails?.progressStatus === "on-going" ? (
                <OngoingDetails
                  orderDetails={orderDetails}
                  itemDetails={itemDetails}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ) : orderDetails?.status === "accepted" &&
                orderDetails?.progressStatus === "completed" ? (
                <CompleteDetails
                  orderDetails={orderDetails}
                  itemDetails={itemDetails}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ) : orderDetails?.status === "canceled" ||
                orderDetails?.status === "rejected" ? (
                <CancelledOrRejectedDetails
                  orderDetails={orderDetails}
                  itemDetails={itemDetails}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ) : orderDetails?.status === "accepted" &&
                orderDetails?.progressStatus === "over-due" ? (
                <OverdueDetails
                  orderDetails={orderDetails}
                  itemDetails={itemDetails}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ) : (
                ""
              )}
            </>
          )}

          {/* Trigger Message */}
          {props.alert.message && (
            <AlertModal
              show={props.alert.type ? true : false}
              type={props.alert.type}
              content={props.alert.message}
            />
          )}
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  order: state.order.order,
  item: state.items.item,
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(RentalActivitiesDetails)
);
