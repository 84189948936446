import React, { } from 'react';
import MainContainer from '../../components/layout/MainContainer';
import LocationCard from '../../components/blocks/location/LocationCard';
import { locationData } from '../../utils/locationData';

const SearchByLocation = () => {
    return <MainContainer hasMainContainer={false}>
        <div className="container px-4 mx-auto">
            <div className="pt-8 lg:pt-14 pb-6 lg:pb-10">
                <h1 className="text-xl md:text-2xl lg:text-4xl font-bold text-temp-primary">
                    Explore by city
                </h1>
            </div>

            <div className='mt-8 mb-28 lg:mb-32 relative grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 lg:gap-8'>
                {
                    locationData?.map(datum => <LocationCard
                        id={datum?.id}
                        key={datum?.id}
                        name={datum?.name}
                        itemCount={datum?.itemCount}
                        image={datum?.image}
                        url={`/search-by-location/${datum?.name.toLowerCase()}`}
                    />
                    )
                }
            </div>
        </div>
    </MainContainer >
};

export default SearchByLocation;
