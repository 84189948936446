import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import store from "store";
import SecurityImage from "../../../assets/images/svg/security.svg";
import OnboardingContainer from "../../../components/layout/OnboardingContainer";

const Option = ({
  handleRoute,
  linkUrl,
  title,
  desc,
  isVerified,
  isIdentityVerified,
}) => {
  return (
    <button
      onClick={() => handleRoute(linkUrl)}
      disabled={
        (title === "Identity" && isIdentityVerified) ||
        (title === "Photo" && !isIdentityVerified)
      }
      className="focus:outline-none ring-0 focus:ring-0 w-full flex flex-row items-center py-6 px-6 justify-start text-left cursor-pointer verify-option-card-option">
      <div className="w-8 mx-auto self-start pt-1">
        {isVerified ? (
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.50586 18.3389C4.67323 18.3389 0.755859 14.4215 0.755859 9.58887C0.755859 4.75624 4.67323 0.838867 9.50586 0.838867C14.3385 0.838867 18.2559 4.75624 18.2559 9.58887C18.2559 14.4215 14.3385 18.3389 9.50586 18.3389ZM9.50586 16.5889C11.3624 16.5889 13.1429 15.8514 14.4556 14.5386C15.7684 13.2259 16.5059 11.4454 16.5059 9.58887C16.5059 7.73235 15.7684 5.95187 14.4556 4.63912C13.1429 3.32637 11.3624 2.58887 9.50586 2.58887C7.64934 2.58887 5.86887 3.32637 4.55611 4.63912C3.24336 5.95187 2.50586 7.73235 2.50586 9.58887C2.50586 11.4454 3.24336 13.2259 4.55611 14.5386C5.86887 15.8514 7.64934 16.5889 9.50586 16.5889ZM8.63348 13.0889L4.92086 9.37624L6.15811 8.13899L8.63348 10.6144L13.5825 5.66449L14.8206 6.90174L8.63348 13.0889Z"
              fill="#08A05C"
            />
          </svg>
        ) : (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.58418" cy="9.21845" r="8.05488" stroke="#D1D1D1" />
          </svg>
        )}
      </div>
      <div className="w-10/12">
        <div className="flex justify-between items-baseline">
          <p className="text-sm-15 lg:text-base font-semibold text-temp-primary">
            {title}
          </p>
          {title === "Photo" && !isIdentityVerified && (
            <div className="mr-8">
              <p className="locked-status text-xs">Locked 🔐</p>
            </div>
          )}
        </div>

        <p className="text-xs lg:text-sm text-temp-gray pt-2">{desc}</p>
      </div>
      <div className="text-center mx-auto verify-option-card-option-arrow px-4">
        <p className="">
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56026 12.5913L7.0513 7.09997L1.56026 1.60894L0.936523 1.60894"
              stroke="#08A05C"
              strokeWidth="1.5"
            />
          </svg>
        </p>
      </div>
    </button>
  );
};

const CompleteVerification = (props) => {
  const user = store.get("temp_usER12_dATa");

  const handleRoute = (url) => {
    props.history.push(url);
  };

  useEffect(() => {
    if (!user?.firstname && !user?.verified_phone) {
      props.history.push("/signin");
    } else if (user?.firstname && !user?.verified_phone) {
      props.history.push("/verify");
    } else if (user?.image && user?.verified_phone && user?.verified_id) {
      props.history.push("/");
    }
  }, []);

  return (
    <div>
      <OnboardingContainer pageTitle="Verification">
        <div className="flex flex-col justify-center mb-10">
          <div className="w-full lg:w-8/12 pt-8 lg:pt-4 mx-auto px-0 lg:px-10 text-center">
            <div className="pt-4 pb-6">
              <img src={SecurityImage} className="mx-auto" alt="" />
            </div>
            <h2 className="text-temp-green-500 font-semibold text-xl lg:text-2xl xl:text-3xl">
              Complete Verification
            </h2>

            <p className="text-sm lg:text-base pt-4 w-10/12 lg:w-7/12 lg:px-4 mx-auto">
              To have full access to use the platform, please complete the
              registration process
            </p>
          </div>
          <div>
            <div className="mt-16 mb-4 lg:mb-6 w-full md:w-8/12 lg:w-6/12 xl:w-5/12 mx-auto">
              <div className="verify-option-card divide-y divide-gray-200 rounded-xl">
                <Option
                  isVerified={user?.verified_id}
                  linkUrl="/verify-identity"
                  title={"Identity"}
                  desc="You are required to provide a means for identification"
                  handleRoute={handleRoute}
                />

                <Option
                  isVerified={user?.image}
                  isIdentityVerified={user?.verified_id}
                  linkUrl="/upload-picture"
                  title={"Photo"}
                  desc="You are required to provide your photo"
                  handleRoute={handleRoute}
                />
              </div>
            </div>
          </div>

          <div className="mt-20 text-temp-gray text-sm lg:text-base text-center">
            <Link
              className="text-temp-green-500 underline focus:outline-none"
              to="/">
              I will do this later
            </Link>
          </div>
        </div>
      </OnboardingContainer>
    </div>
  );
};

export default (CompleteVerification);
