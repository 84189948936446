import React from "react";
import StarRating from "../../../components/blocks/StarRating";
import EquipmentLocation from "./EquipmentLocation";

const EquipmentLocationAndRating = ({ item }) => {
  return (
    <div className="relative flex flex-row flex-wrap items-baseline">
      <div className="mr-4 lg:mr-8">
        <StarRating rating={item?.rating} />
      </div>
      <EquipmentLocation item={item} />
    </div>
  );
};

export default EquipmentLocationAndRating;
