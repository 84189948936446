/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from 'react-router-dom';
import Image from "../../../assets/images/png/insurance.png";
import Loader from "../../../components/blocks/Loader";
import NoRecordReturned from "../../../components/blocks/NoRecordReturned";
import MainContainer from "../../../components/layout/MainContainer";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";
import { numberFormat } from "../../../utils/numberFormat";
import Paginator from "../../../utils/pagination";
import { scrollToTop } from "../../../utils/scrollToTop";
import RentalRequestDetailsModal from "./components/RentalRequestDetailsModal";
import RentalRequestDisapproveModal from "./components/RentalRequestDisapproveModal";

const LeftColumn = ({ datum }) => {
  return (
    <div className="w-4/12 pb-6 relative pr-5">
      <img
        src={datum?.itemId?.images?.[0]?.image?.url || Image}
        className="flex-shrink rounded-lg h-20 lg:h-24 object-cover"
        alt=""
      />
    </div>
  );
};

const ExtendFlag = () => {
  return (
    <div
      className="bg-temp-green-500 py-1 pt-2 px-2 pl-3 text-white extend-flag 
                      absolute top-6 right-0 font-bold rounded-tl-lg rounded-bl-lg text-xs"
    >
      Extend Usage
    </div>
  );
};

const NameAmount = ({ name, total }) => {
  return (
    <>
      <h3 className="text-base lg:text-lg font-medium text-temp-primary">
        {name}
      </h3>
      <p className="text-sm-15 lg:text-base font-medium inline-flex mt-2 mb-2 lg:mb-2 text-temp-gray">
        ₦{numberFormat(total)}
      </p>
    </>
  );
};

const DateDetails = ({ item, dayCount, unitOrdered, startDate, endDate }) => {
  return (
    <p className="text-xs lg:text-sm py-2 lg:mb-2 text-temp-primary">
      <span className="text-temp-gray">
        {item?.rentalUnitType === "bySiUnits"
          ? (unitOrdered || 1) + " " + (item?.siUnitType || "hour")
          : (dayCount + 1) + " " + (item?.calenderType || "days")}
      </span>{" "}
      <span className="text-temp-green-500 text-xs">
        ({moment(startDate).format("MMMM D, YYYY")} -{" "} {moment(endDate).format("MMMM D, YYYY")})
      </span>
    </p>
  );
};

const Borrower = ({ datum }) => {
  return (
    <div className="flex items-center mb-4">
      <div className="flex-shrink-0 h-7 w-7">
        <img
          className="w-6 h-6 lg:h-7 lg:w-7 object-cover rounded-full"
          src={datum?.borrower?.image}
          alt=""
        />
      </div>
      <div className="ml-2 lg:ml-4">
        <div className="text-xs lg:text-sm text-temp-gray">
          {datum?.borrower?.firstname} {datum?.borrower?.lastname}
        </div>
      </div>
    </div>
  );
};

const Requests = ({ data, handleShowDetails }) => {
  return (
    <>
      <div className="my-6 lg:my-12 relative w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10">
        {data?.map((datum) => {
          return (
            <div
              key={datum._id}
              className="w-full cursor-pointer"
              onClick={() => handleShowDetails(datum)}
            >
              <div
                className="px-5 pt-4 lg:pt-8 pb-2 lg:pb-4 rounded-2xl flex flex-wrap relative"
                style={{
                  boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.1)",
                }}
              >
                <LeftColumn datum={datum} />
                <div className="w-8/12 flex-shrink">
                  {/* Extend Flag */}
                  {datum?.extensions?.length > 0 ? (
                    <>
                      <ExtendFlag />
                      <NameAmount
                        name={datum?.itemId?.name}
                        total={
                          datum?.extensions?.[datum?.extensions?.length - 1]
                            ?.total || 0
                        }
                      />
                      <br />
                      <DateDetails
                        item={datum?.itemId}
                        unitOrdered={datum?.unitOrdered}
                        dayCount={
                          datum?.extensions?.[datum?.extensions?.length - 1]
                            ?.dayCount
                        }
                        startDate={
                          datum?.extensions?.[datum?.extensions?.length - 1]
                            ?.startDate
                        }
                        endDate={
                          datum?.extensions?.[datum?.extensions?.length - 1]
                            ?.endDate
                        }
                      />
                    </>
                  ) : (
                    <>
                      <NameAmount
                        name={datum?.itemId?.name}
                        total={datum?.total || 0}
                      />
                      <br />
                      <DateDetails
                        item={datum?.itemId}
                        unitOrdered={datum?.unitOrdered}
                        dayCount={datum?.dayCount}
                        startDate={datum?.startDate}
                        endDate={datum?.endDate}
                      />
                    </>
                  )}
                  <Borrower datum={datum} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const LenderRentalRequest = (props) => {
  const [toggleShow, setToggleShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [showDeclineRequest, setShowDeclineRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [pagination, setPagination] = useState({});

  // const handleToggleShow = (key) => {
  //   setToggleShow(!toggleShow);
  //   setSelectedOption(key);
  // };

  const handleShowDetails = (order) => {
    setToggleShow(false);
    setShowDetails(!showDetails);
    setOrderDetails(order);
  };

  const handleCloseRequestModal = () => {
    setToggleShow(false);
    setShowDetails(false);
  };

  const handleShowDisapprove = () => {
    setToggleShow(false);
    setShowDeclineRequest(!showDeclineRequest);
  };

  const handleCloseRequestDisapproveModal = () => {
    setToggleShow(false);
    setShowDeclineRequest(false);
  };

  useEffect(() => {
    scrollToTop();

    fetchRentalRequests();
  }, []);

  const fetchRentalRequests = async (data = {}) => {
    setLoading(true);
    const params = {
      ...data,
      type: "lender_requests",
      status: "pending",
    };
    await props.getOrdersApi(params);

    setLoading(false);
  };

  useEffect(() => {
    setOrders(props.orders);
    setPagination(props.pagination);
  }, [props.orders]);

  const [approvalLoading, setApprovalLoading] = useState(false);
  const processApproval = async (order) => {
    setApprovalLoading(true);

    await props.acceptOrdersApi(order?._id);

    setShowDetails(false);
    setOrderDetails({});
    setSelectedOption(0);
    setToggleShow(false);

    setApprovalLoading(false);
  };

  const [disapprovalLoading, setDisapprovalLoading] = useState(false);
  const processDisapproval = async (order, data) => {
    setDisapprovalLoading(true);
    await props.rejectOrdersApi(order?._id, data);
    setDisapprovalLoading(false);
  };

  useEffect(() => {
    if (props.declineStatus === "success") {
      setShowDetails(false);
      setOrderDetails({});
      setSelectedOption(0);
      setToggleShow(false);
      setShowDeclineRequest(false);
    }
  }, [props.declineStatus]);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="w-full pt-10 pb-32 container px-4 mx-auto">
        <div className="">
          <h3 className="text-2xl lg:text-3xl text-temp-primary font-medium">
            Rental Requests
          </h3>
        </div>

        <div className="mt-5 mb-24">
          <div className="relative">
            <div className="">
              <div className="py-2 align-middle inline-block min-w-full">
                {loading ? (
                  <div className="my-14">
                    <Loader />
                  </div>
                ) : orders?.length > 0 ? (
                  <>
                    <Requests
                      data={orders}
                      handleShowDetails={handleShowDetails}
                    />
                    <Paginator
                      pageLimit={pagination?.limit}
                      maxPages={pagination?.totalPages}
                      currentPage={pagination?.page}
                      getPageApi={fetchRentalRequests}
                      setLoading={setLoading}
                    />
                  </>
                ) : (
                  <NoRecordReturned />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Show details */}

      {showDetails && (
        <RentalRequestDetailsModal
          show={showDetails}
          orderDetails={orderDetails}
          handleShowDisapprove={handleShowDisapprove}
          handleCloseRequestModal={handleCloseRequestModal}
          processApproval={() => processApproval(orderDetails)}
        />
      )}

      {showDeclineRequest && (
        <RentalRequestDisapproveModal
          disapprovalLoading={disapprovalLoading}
          show={showDeclineRequest}
          orderDetails={orderDetails}
          handleCloseModal={handleCloseRequestDisapproveModal}
          processDisapproval={processDisapproval}
        />
      )}

      {approvalLoading && (
        <LoadingModal show={approvalLoading} title="Approving Order ..." />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  order: state.order.order,
  pagination: state.order.pagination,
  declineStatus: state.order.declineStatus,
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(LenderRentalRequest)
);
