import React from "react";
import StarRating from "../StarRating";
import EquipmentLocation from "./EquipmentLocation";
import EquipmentName from "./EquipmentName";
import ItemChargesRow from "./ItemChargesRow";
import moment from "moment";

// This component captueres the equipment image (just one), the name and the price
const EquipmentOverviewDetails = ({ item, itemAvailability }) => {
  return (
    <div className="w-full mb-8">
      {/* Desktop Only */}
      <div className="flex flex-wrap flex-row">
        <div className="w-5/12 md:w-4/12 lg:w-4/12 xl:w-5/12 rounded-xl object-cover">
          <img
            src={item?.images?.[0]?.image?.url}
            alt={item?.name}
            className="w-full rounded-xl object-cover"
          />
        </div>
        <div className="w-full md:w-7/12 lg:w-8/12 xl:w-6/12 lg:pl-6 md:pl-8 mt-6 md:mt-0">
          <div className="">
            <StarRating starDimension="14px" rating={item?.rating} />
          </div>
          <EquipmentName item={item} />
          <EquipmentLocation item={item} className="" />

          <div className="mt-2">
            <p className="text-temp-primary font-medium mb-3 text-base xl:text-lg">
              Rental Period
            </p>
            <ItemChargesRow
              name="Start Date"
              value={moment(itemAvailability?.summary?.startDate).format(
                "MMMM D, YYYY"
              )}
            />
            <ItemChargesRow
              name="End Date"
              value={moment(itemAvailability?.summary?.endDate).format(
                "MMMM D, YYYY"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentOverviewDetails;
