/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import MainContainer from "../../components/layout/MainContainer";
import { faqsDataForTempOwners, faqsDataForOwners } from "../../utils/faqsData";
import { scrollToTop } from "../../utils/scrollToTop";

import GeneralImage from "../../assets/images/png/faq/general.webp"
import OnboardingImage from "../../assets/images/png/faq/onboarding.webp"
import RentImage from "../../assets/images/png/faq/renting.webp"
import PaymentImage from "../../assets/images/png/faq/payment.webp"
import { Link } from "react-router-dom";

const Card = ({ title, content, image, url }) => {
    return <div className="faq-card">
        <Link to={url || "/faqs"}>
            <div className="">
                <img src={image || GeneralImage} alt="" />
            </div>
            <div className="mt-6">
                <p className="font-semibold text-base lg:text-lg">{title}</p>
                <p className="mt-3 text-sm-15 lg:text-base">{content}</p>
            </div>
        </Link>
    </div>
}

const Faqs = () => {
    const [selectedFaq, setSelectedFaq] = useState("tempowners");

    const [faqData, setFaqData] = useState(faqsDataForTempOwners);

    useEffect(() => {
        scrollToTop();
    }, []);

    const handleChangeTab = (selected) => {
        setSelectedFaq(selected);

        if (selected == "tempowners") {
            setFaqData(faqsDataForTempOwners);
        } else {
            setFaqData(faqsDataForOwners);
        }
    };

    return (
        <MainContainer hasMainContainer={false}>
            <div className="container relative mx-auto px-4 pb-24 lg:pb-32">
                <div className="mt-8 relative px-6 lg:px-8 xl:px-12 pt-16 pb-16 mx-auto faq-banner flex flex-col justify-center">
                    <h1 className="text-4xl xl:text-5xl font-semibold text-white">
                        Have any questions?
                    </h1>
                </div>

                <div className="relative mt-14 lg:mt-14 w-full">
                    <div className="inline-flex space-x-6">
                        <div
                            className={`cursor-pointer ${selectedFaq === "tempowners" && "text-temp-green-500 font-semibold border-b-2 border-temp-green-500"
                                }`}
                            onClick={() => handleChangeTab("tempowners")}
                        >
                            <p className="text-base lg:text-lg ">
                                TempOwners
                            </p>
                        </div>
                        <div
                            className={`cursor-pointer ${selectedFaq === "owners" && "text-temp-green-500 font-semibold border-b-2 border-temp-green-500"
                                }`}
                            onClick={() => handleChangeTab("owners")}
                        >
                            <p className="text-base lg:text-lg">
                                Owners
                            </p>
                        </div>
                    </div>

                    <p className="mt-8 lg:mt-12 text-sm-15 lg:text-base">{selectedFaq === "tempowners" ?
                        "Are you a borrower and need help as regards our services? We have answered some qustions you might have" :
                        "Are you a lender and need help as regards our services? Click to view the frequently asked questions."}
                    </p>
                </div>

                <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <Card
                        image={GeneralImage}
                        title="General"
                        url={`/faqs/general?type=${selectedFaq}`}
                        content={"General information about the TempOwn platform"}
                    />
                    <Card
                        image={OnboardingImage}
                        title="Onboarding"
                        url={`/faqs/onboarding?type=${selectedFaq}`}
                        content={"Onboarding information on TempOwn platform"}
                    />
                    <Card
                        image={RentImage}
                        url={`/faqs/renting?type=${selectedFaq}`}
                        title="Renting Equipment"
                        content={"Rental information on the TempOwn platform"}
                    />
                    <Card
                        image={PaymentImage}
                        url={`/faqs/payment?type=${selectedFaq}`}
                        title="Payment"
                        content={"General information about the TempOwn platform"}
                    />
                </div>
            </div>
        </MainContainer>
    );
};

export default Faqs;
