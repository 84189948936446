/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import TextAreaGroup from "../element/TextAreaGroup";
import InputFileGroup from "../element/InputFileGroup";
import { compressImage } from "../../helpers/compressImage";
import CustomSelectGroup from "../element/CustomSelectGroup";

const SendReportModal = ({
    show,
    handleCloseModal,
    processSubmitFeedback,
    items = []
}) => {
    const [images, setImages] = useState(["", "", "", ""]);
    const [displayImages, setDisplayImages] = useState(["", "", "", ""]);
    const [error, setError] = useState("");
    const handleImageChange = (e, key) => {
        setError('');
        // compress image
        compressImage(e, (data) => {
            images[key] = data;
            setImages([...images]);
        });

        var reader = new FileReader();
        reader.onload = function (e) {
            displayImages[key] = e.target.result;
            setDisplayImages([...displayImages]);
        };
        reader.readAsDataURL(e[0]);
    };

    const handleRemoveImage = (key) => {
        images[key] = "";
        setImages([...images]);

        displayImages[key] = "";
        setDisplayImages([...displayImages]);
    };

    const initialState = {
        item: "",
        description: "",
    };

    const validate = (values) => {
        const errors = {};
        if (!values.item) {
            errors.item = "Select the item to report";
        }

        if (!values.description) {
            errors.description = "Description is required";
        }

        return errors;
    };

    return (
        <div className="fixed z-40 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={show}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </Transition>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-white py-10 sm:py-12">
                        <div className="text-center">
                            <div className="pb-3 w-full mx-auto"></div>
                            <div className="relative text-center px-5 sm:px-12">
                                <button
                                    onClick={handleCloseModal}
                                    className="absolute text-temp-green-500 text-lg rounded-full shadow-lg  md:pt-1 px-2 right-8 -top-8 md:-top-10 focus:outline-none"
                                >
                                    x
                                </button>
                                <h3
                                    className="leading-6 text-xl  md:text-2xl lg:text-3xl font-medium text-temp-secondary lg:font-semibold"
                                    id="modal-headline"
                                >
                                    Send Report
                                </h3>
                                <div className="mt-8 md:mt-10">
                                    <Formik
                                        initialValues={initialState}
                                        validate={validate}
                                        onSubmit={async (values, { setSubmitting }) => {
                                            setSubmitting(true);
                                            setError("")
                                            try {
                                                const filterImages = images?.filter((a) => a !== "");
                                                if (filterImages.length > 0) {
                                                    const formData = new FormData();
                                                    for (const key in filterImages) {
                                                        if (Object.hasOwnProperty.call(filterImages, key)) {
                                                            formData.append("images", filterImages[key]);
                                                        }
                                                    }

                                                    formData.set("description", values?.description);
                                                    formData.set("orderId", values?.item?.value);

                                                    await processSubmitFeedback(formData)
                                                } else {
                                                    setError("Kindly add at least one ( 1 ) image to validate the report")
                                                }

                                                setSubmitting(false);
                                            } catch (error) {
                                                setSubmitting(false);
                                            }
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            isSubmitting,
                                            setFieldValue
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <div className="w-full flex flex-wrap justify-between text-left">
                                                        <div className="w-full">
                                                            <div className="w-full text-left">
                                                                <CustomSelectGroup
                                                                    className="mb-1 lg:mb-2"
                                                                    placeholder="Select Item"
                                                                    name="item"
                                                                    value={values.item}
                                                                    options={items?.map(item => ({
                                                                        label: item?.item?.name,
                                                                        value: item?.order?._id
                                                                    }))}
                                                                    onChange={(e) => {
                                                                        setFieldValue("item", e);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    isClearable={true}
                                                                    error={errors.item && touched.item && errors.item}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="w-full">
                                                            <TextAreaGroup
                                                                labelColor="text-temp-gray"
                                                                marginBottom="mb-6"
                                                                label="Describe what happened"
                                                                name="description"
                                                                value={values.description}
                                                                onChange={handleChange}
                                                                showTip={false}
                                                                error={
                                                                    errors.description &&
                                                                    touched.description &&
                                                                    errors.description
                                                                }
                                                                rows={"8"}
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-x-4 md:gap-x-5 lg:gap-x-6 gap-y-6 lg:gap-y-12 mt-3 md:mt-5">
                                                                {
                                                                    displayImages?.map((a, index) => <InputFileGroup
                                                                        key={index}
                                                                        preview={a}
                                                                        onDrop={(e) => handleImageChange(e, index)}
                                                                        accept="image/*"
                                                                        onDeleteClick={() => handleRemoveImage(index)}
                                                                        marginBottom="mb-0"
                                                                        width="w-24 md:w-32"
                                                                        height={"h-24 md:h-32"}
                                                                    />)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {error && <p className="text-center text-sm md:text-sm-15 text-red-600 pt-12 pb-4">{error}</p>}

                                                <div className="mt-10 flex items-baseline justify-end flex-end">
                                                    <button
                                                        onClick={handleCloseModal}
                                                        className="text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        disabled={isSubmitting ? true : false}
                                                        type="submit"
                                                        className="mb-4 py-3 lg:py-5 px-8 lg:px-10 text-sm bg-temp-green-500 bg-gradient font-bold text-white rounded-xl focus:outline-none"
                                                    >
                                                        {isSubmitting ? "Processing..." : "Submit"}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendReportModal;
