export const starTags = [
  {
    star: 1,
    tags: [
      "Not recommended",
      "Delay in return",
      "Impolite tempowner",
      "Poor equipment maintenance",
      "Dishonest tempowner",
      "Delinquent",
    ],
  },
  {
    star: 2,
    tags: ["Reckless owner", "Cunning", "Troublesome", "Clumsy tempowner"],
  },
  {
    star: 3,
    tags: ["Unpunctual", "Tricky", "Disrepectful", "Careless", "Intrusive"],
  },
  {
    star: 4,
    tags: [
      "Proper maintenance",
      "Respectful tempowner",
      "Friendly",
      "Good experience",
      "Tardy tempowner",
    ],
  },
  {
    star: 5,
    tags: [
      "Time conscious",
      "Courteous",
      "Splendid experience",
      "Honest",
      "Proper equipment maintenance",
    ],
  },
];
