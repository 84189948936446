import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { alertActions } from "../../store/actions";
import { Link } from "react-router-dom";
import { ErrorIcon, SuccessIcon, WarningIcon } from "../svgs";

const AlertModal = ({
  type,
  show,
  content,
  onClose = undefined,
  linkUrl,
  linkTitle,
  title,
  ...props
}) => {
  const handleClose = () => {
    props.clear();
  };

  // unmount component
  useEffect(() => {
    return () => {
      handleClose();
      clearTimeout(1000);
    };
  }, []);

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="py-3 pb-3 w-full mx-auto">
                {type === "success" ? (
                  <SuccessIcon />
                ) : type === "error" ? (
                  <ErrorIcon />
                ) : type === "warning" ? (
                  <WarningIcon />
                ) : (
                  ""
                )}
              </div>
              <div className="mt-2 text-center">
                <h3
                  className="leading-6 text-2xl md:text-3xl font-medium text-temp-primary"
                  id="modal-headline"
                >
                  {type === "success"
                    ? title || "Successful !!!"
                    : type === "error"
                    ? title || "Failed !!!"
                    : type === "warning"
                    ? title || "Warning !!!"
                    : ""}
                </h3>
                <div className="my-5">
                  <p className="text-sm md:text-base font-sans text-temp-gray">
                    {content}
                  </p>
                  {type === "success" || linkUrl ? (
                    <div className={`${linkTitle ? "mt-8" : "mt-5"}`}>
                      <Link
                        to={linkUrl}
                        onClick={onClose || handleClose}
                        className="mt-8 py-4 lg:py-5 px-9 bg-temp-green-500 bg-gradient text-white rounded-lg focus:outline-none"
                      >
                        {linkTitle || "Continue"}
                      </Link>

                      {
                        linkTitle && (
                          <button
                          onClick={onClose || handleClose}
                          className="mt-6 lg:mt-8 text-red-600 text-center w-full focus:outline-none ring-0 focus:ring-0"
                        >
                          Close
                        </button>
                        )
                      }
                    </div>
                  ) : (
                    <button
                      onClick={onClose || handleClose}
                      className="mt-5 py-3 px-9 bg-temp-green-500 bg-gradient text-white rounded-lg focus:outline-none"
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(alertActions.clear()),
  };
};
export default connect(null, mapDispatchToProps)(AlertModal);
