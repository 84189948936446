import React from 'react'
import { Link } from 'react-router-dom'
import TreeImage from "../../../assets/images/png/emission/tree.svg"

const WhySection = () => {
    return (
        <div className='relative bg-temp-green-100 pt-14 lg:pt-20 pb-20 lg:pb-24 mb-28 lg:mb-36'>
            <div className='container px-4 mx-auto'>
                <div className='w-full md:w-10/12 lg:w-8/12 mx-auto'>
                    <h2 className='font-semibold text-temp-green-500 text-2xl md:text-3xl lg:text-4xl'>Why we are doing this</h2>
                    <p className='mt-6 lg:mt-8 text-base lg:text-lg lg:leading-8 font-light'>
                        In the last century alone, the earth&apos;s average temperature
                        has increased by about 2° Fahrenheit. Islands and coastal cities are
                        at risk of being destroyed due to rising sea levels, the degradation of
                        the ozone layer is causing extreme heat and poor health conditions, droughts
                        have drastically reduced yield in agriculture.
                        <br /><br />
                        Every time you opt for rental, you save the earth the sum of emission released when
                        producing a piece of new equipment you could have bought. For this, we have designed a
                        reward plan for you. When you rent an equipment on the TempOwn platform, we generate your
                        emission saving score on that equipment. This is the amount of Green House Gas (GHG) emission
                        saved because you opted to rent rather than buying the equipment. Whenever you hit a milestone,
                        you qualify to redeem a prize.
                    </p>

                    <div className='mt-12 bg-white flex flex-row flex-wrap items-center py-8 lg:py-12 px-8'>
                        <div className='w-2/12'>
                            <img src={TreeImage} alt="" />
                        </div>
                        <div className='w-full md:w-9/12 lg:pl-6 mt-4 lg:mt-0'>
                            <p className='text-sm font-light text-temp-primary'>Total Emission saved</p>
                            <p className='mt-3 text-4xl lg:text-5xl font-semibold text-black'>1004
                            <span className="text-temp-secondary font-medium text-xl lg:text-2xl pl-1">kgCO<sub>2</sub></span></p>
                        </div>
                    </div>

                    <div className='mt-10 lg:mt-16 flex flex-wrap flex-col md:flex-row items-center justify-between'>
                        <div className='text-base lg:text-lg text-center md:text-left'>
                            <p className='font-semibold'>Want to be a part of this revolution? </p>
                            <p>Sign up on TempOwn us now</p>
                        </div>
                        <div className='text-sm-15 lg:text-base mt-8 lg:mt-0'>
                            <Link
                                to={"/signup"}
                                className="bg-gradient py-5 lg:py-5 px-10 lg:px-12 rounded-xl text-white text-sm md:text-base font-semibold"
                            >
                                Get Started
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhySection