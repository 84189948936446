import Actions from "../actions/actionTypes";

const initialState = {
  rentals: {},
};

const LateRentalReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_LATE_RETURNS:
      return {
        ...state,
        rentals: action.payload,
      };
    default:
      return state;
  }
};

export default LateRentalReducer;
