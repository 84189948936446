import React from "react";
import { MapBlackIcon } from "../../svgs";

const EquipmentLocation = ({ item, className }) => {
  const address = item?.pickupLocation?.address_components;
  const city =
    address?.find((a) => a?.types?.includes("administrative_area_level_2"))
      ?.long_name || undefined;
  const state =
    address?.find((a) => a?.types?.includes("administrative_area_level_1"))
      ?.long_name || undefined;

  return (
    <div className={className || "mt-3 mb-4"}>
      <p className="text-xs lg:text-sm inline-flex items-center text-temp-gray">
        <MapBlackIcon />
        <span className="ml-2">
          {address && city && state ? city + ", " + state : "Nigeria"}
        </span>
      </p>
    </div>
  );
};

export default EquipmentLocation;
