import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const getWalletsApi = (params) => async (dispatch) => {
  dispatch(alertActions.clear());
  dispatch({
    type: Actions.INITIATE_PAYMENT_WITHDRAWAL,
    payload: "",
  });

  const url = "/user/wallet";
  try {
    const response = await axios.get(url, {
      params
    });
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_WALLETS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data?.message));
  }
};
