import React from 'react';
import leftArrow from "../../../../../assets/images/svg/leftArrow.svg";


const PreviousButton = ({ onClick }) => {
    return <button
        type="button"
        onClick={onClick}
        className={`font-semibold text-base lg:text-lg text-black focus:outline-none flex items-center`}
    >
        <div
            className="h-11 w-5 flex items-center"
        >
            <img src={leftArrow} alt="" className="w-4 h-4" />
        </div>
        Back
    </button>
};

export default PreviousButton;
