import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const createReviewApi = (payload, isUserReview = false) => async (
  dispatch
) => {
  dispatch(alertActions.clear());

  const url = isUserReview ? "/review/user" : "/review";
  try {
    await axios.post(url, payload);

    dispatch({
      type: Actions.CREATE_REVIEW,
      payload: {
        type: "success",
        message: "Review Submitted successfully",
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.CREATE_REVIEW,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: Actions.CREATE_REVIEW,
      payload: {
        type: "error",
        message: error?.response?.data?.message,
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.CREATE_REVIEW,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  }
};
