import React from "react";

const BriefSection = () => {
  return (
    <div className="">
      <div className="container mx-auto px-4 mt-14">
        <div className="pt-16 lg:pt-24 xl:pt-32 pb-10 lg:pb-20">
          <div className="w-full lg:w-11/12 2xl:w-9/12 px-2 md:px-4 lg:px-6 mx-auto">
            <h1 className="text-temp-primary text-3xl lg:text-4xl xl:text-5xl  lg:leading-normal xl:leading-tight font-semibold pb-4 text-center">
              Our vision is to pave the way for the{" "}
              <span className="text-temp-green-500">
                future of online seamless rentals
              </span>
              , enriching people&apos;s lives around the world with safe and
              fully insured rentals while{" "}
              <span className="text-temp-orange">
                lowering carbon emissions.
              </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefSection;
