import Actions from "../actions/actionTypes";

const initialState = {
  visit: 0,
};

export default function VisitReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SITE_VISITED:
      return {
        ...state,
        visit: action.payload.visit,
      };

    default:
      return state;
  }
}
