import React from "react";
import { Transition } from "@headlessui/react";
import CheckIcon from "../../../../assets/images/svg/checked.svg";

const Leader = ({ name, image, address, kg }) => {
  return (
    <div className="flex flex-wrap items-center justify-between pb-6 pr-4">
      <div className="flex flex-wrap items-center pb-3">
        <div className="relative mr-4 pb-3  flex-shrink">
          <img
            src={image}
            width="80%"
            className="w-12 h-12 lg:w-16 lg:h-16 object-cover rounded-full border-2 border-white outline-none bg-white"
            alt=""
          />
          <img
            src={CheckIcon}
            width="22rem"
            className="border-2 border-white rounded-full absolute right-0 -mt-4"
            alt=""
          />
        </div>
        <div className="relative block  flex-1 mb-3">
          <h4 className="text-base lg:text-lg font-semibold tracking-wide text-white">
            {name}
          </h4>
          <p className="text-xs text-white">
            {address || "17, Baptist Church Street"}
          </p>
        </div>
      </div>
      <div className="pb-3">
        <p className="text font-bold text-white text-sm lg:ext-base">{kg}</p>
      </div>
    </div>
  );
};

const LeaderboardModal = ({
  show,
  handleCloseModal,
  data,
}) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center leaderboard-modal-container rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="leaderboard-modal-container px-5 py-5 sm:p-12">
            <div className="text-right">
              <button
                onClick={handleCloseModal}
                className="mt-3 top-0 right-5 absolute text-3xl px-3 py-1 shadow-lg rounded-full text-temp-green-500 bg-white focus:outline-none"
              >
                x
              </button>
            </div>
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="text-left">
                <h3
                  className="leading-6 text-xl md:text-3xl font-medium text-white font-serif pb-2"
                  id="modal-headline"
                >
                  Leaderboard
                </h3>
                <p className="text-xs lg:text-sm" style={{ color: "#FFFCF4" }}>
                  See how much other users around you has saved
                </p>
                <div className="mt-10">
                  <div className="pb-3 max-h-96 overflow-y-auto">
                    {data.map((user, i) => (
                      <Leader
                        image={user.userId.image}
                        name={user.userId.firstname}
                        kg={`${user.score} KgCO2`}
                        key={i}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardModal;
