import $ from "jquery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import AlertModal from "../../components/modals/AlertModal";
import { compressImage } from "../../helpers/compressImage";
import * as actions from "../../store/actions";
import ConfirmPickupCard from "./components/ConfirmPickupCard";
import ConfirmPickupEnterCodeModal from "./components/ConfirmPickupEnterCodeModal";
import ConfirmPickupModal from "./components/ConfirmPickupModal";
import LeftSectionPendingAndAccepted from "./components/LeftSectionPendingAndAccepted";

const ApproveBookingDetails = ({
  loggedInUserDetails,
  orderDetails,
  itemDetails,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [
    showNotConfirmedErrorModalToBorrower,
    setShowNotConfirmedErrorModalToBorrower,
  ] = useState(false);
  const [showEnterCodeModal, setShowEnterCodeModal] = useState(false);

  const handleShowModal = () => {
    if (orderDetails?.confirmPickup?.tokenConfirmed) {
      setShowModal(!showModal);
    } else {
      setShowNotConfirmedErrorModalToBorrower(
        !showNotConfirmedErrorModalToBorrower
      );
    }
  };

  const handleShowEnterCodeModal = () => {
    setShowEnterCodeModal(!showEnterCodeModal);
  };

  const [picture, setPicture] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState("");
  const [isItemInGoodCondition, setIsItemInGoodCondition] = useState(true);
  const [errors, setErrors] = useState("");

  // For Borrowers to Confirm Pickup Start by uploading pickup during pickup
  // START--------------------------------------
  //   Upload profile picture from file
  const handleProfilePictureUpload = () => {
    $("#avatar_file").click();
    $("#avatar_file").change(function (e) {
      e.preventDefault();
      profilePhoto(this);
    });
  };

  function profilePhoto(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);

      // compress image
      compressImage([input.files[0]], (data) => {
        setPicture(data);
      });

      setErrors("");
    }
  }

  const handleShowComment = () => {
    setShowComment(!showComment);

    if (showComment) {
      setComment("");
    }
  };

  const handleDeleteImage = () => {
    setShowComment(false);
    setSelectedImage("");
    setPicture("");
  };

  const handleChangeItemInGoodCondition = () => {
    setIsItemInGoodCondition(!isItemInGoodCondition);
    setErrors({
      ...errors,
      isItemInGoodCondition: "",
    });
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const [loadingProcess, setLoadingProcess] = useState(false);
  const handleProcessConfirmPickup = async (e) => {
    e.preventDefault();

    if (selectedImage.length === 0) {
      setErrors({
        ...errors,
        selectedImage: "Kindly take a picture of the item",
      });
      return;
    } else if (!isItemInGoodCondition) {
      setErrors({
        ...errors,
        isItemInGoodCondition: "Required",
      });
      return;
    }
    setLoadingProcess(true);

    const formData = new FormData();

    formData.append("images", picture);
    formData.set("comment", comment || null);
    await props.confirmOrderPickupApi(orderDetails?._id, formData);

    setLoadingProcess(false);
  };

  useEffect(() => {
    if (props.confirmPickupStatus === "success") {
      setShowModal(false);
      setShowComment(false);
      setShowEnterCodeModal(false);
      setShowNotConfirmedErrorModalToBorrower(false);
    }
  }, [props.confirmPickupStatus]);
  // END -------------------------------------------

  // For Lenders to Confirm Pickup with Code
  // START--------------------------------------
  const [codeError, setCodeError] = useState("");
  const [pickupCodeSubmitLoading, setPickupCodeSubmitLoading] = useState(false);
  const [code, setCode] = useState([
    {
      id: 1,
      value: "",
    },
    {
      id: 2,
      value: "",
    },
    {
      id: 3,
      value: "",
    },
    {
      id: 4,
      value: "",
    },
  ]);

  const processSubmitPickupCode = async (e) => {
    e.preventDefault();

    let data = "";

    code.map((a) => (data += a.value));

    const payload = {
      token: parseInt(data),
    };
    if (data?.length < 4) {
      setCodeError("Invalid Code. Make sure you enter the correct code.");
      return;
    }

    setPickupCodeSubmitLoading(true);
    await props.confirmOrderPickupApi(
      orderDetails?._id,
      payload,
      true,
      orderDetails
    );
    setPickupCodeSubmitLoading(false);
  };

  const handleCodeInputChange = (e, index) => {
    let d = code[index];
    d["value"] = e.target.value;
    code[index] = d;
    setCode([...code]);

    if (e.target.value) {
      $(`.code-input${index + 1}`).focus();
    }

    setCodeError("");
  };

  const handleCodeOnKeyUp = (e, key) => {
    const d = code[key];

    var keyId = e.keyCode;
    switch (keyId) {
      // enter
      case 13:
        $(`.code-input${key + 1}`).focus();
        break;

      // Backspace
      case 8:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;

      // Delete
      case 46:
        d["value"] = "";
        code[key] = d;
        setCode([...code]);
        $(`.code-input${key - 1}`).focus();
        break;
      default:
        break;
    }
  };
  // END ------------------------------------------

  return (
    <div className="my-10 relative flex flex-wrap text-temp-primary">
      <div className="w-full lg:w-5/12 xl:w-6/12 mb-12">
        <LeftSectionPendingAndAccepted
          orderDetails={orderDetails}
          itemDetails={itemDetails}
          loggedInUserDetails={loggedInUserDetails}
        >
          {/* Mobile only */}
          <div className="mb-12 mt-6 block lg:hidden">
            <ConfirmPickupCard
              orderDetails={orderDetails}
              itemDetails={itemDetails}
              handleShowModal={handleShowModal}
              loggedInUserDetails={loggedInUserDetails}
              handleShowEnterCodeModal={handleShowEnterCodeModal}
            />
          </div>
        </LeftSectionPendingAndAccepted>
      </div>

      {/* Desktop only */}
      <div className="hidden lg:block w-full lg:w-6/12 xl:w-5/12 mx-auto px-0 md:px-10 lg:mb-12 mb-6">
        <ConfirmPickupCard
          orderDetails={orderDetails}
          itemDetails={itemDetails}
          handleShowModal={handleShowModal}
          loggedInUserDetails={loggedInUserDetails}
          handleShowEnterCodeModal={handleShowEnterCodeModal}
        />
      </div>

      {showModal && (
        <ConfirmPickupModal
          show={showModal}
          onClose={() => setShowModal(false)}
          handleCloseModal={() => setShowModal(false)}
          picture={picture}
          selectedImage={selectedImage}
          showComment={showComment}
          comment={comment}
          isItemInGoodCondition={isItemInGoodCondition}
          errors={errors}
          handleProfilePictureUpload={handleProfilePictureUpload}
          handleShowComment={handleShowComment}
          handleDeleteImage={handleDeleteImage}
          handleChangeItemInGoodCondition={handleChangeItemInGoodCondition}
          loadingProcess={loadingProcess}
          handleProcessConfirmPickup={handleProcessConfirmPickup}
          handleChangeComment={handleChangeComment}
        />
      )}

      {showEnterCodeModal && (
        <ConfirmPickupEnterCodeModal
          show={showEnterCodeModal}
          onClose={() => setShowEnterCodeModal(false)}
          handleCloseModal={() => setShowEnterCodeModal(false)}
          processSubmitPickupCode={processSubmitPickupCode}
          pickupCodeSubmitLoading={pickupCodeSubmitLoading}
          code={code}
          handleCodeInputChange={handleCodeInputChange}
          handleCodeOnKeyUp={handleCodeOnKeyUp}
          codeError={codeError}
        />
      )}

      {showNotConfirmedErrorModalToBorrower && (
        <AlertModal
          title="Opps..."
          show={showNotConfirmedErrorModalToBorrower}
          type="error"
          content={`You cannot confirm pickup at the moment. 
          Please give the pickup code to the Lender. Once it is confirmed, 
          you will be able to confirm pickup.`}
          onClose={() => setShowNotConfirmedErrorModalToBorrower(false)}
        />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  confirmPickupStatus: state.order.confirmPickupStatus,
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(ApproveBookingDetails)
);
