/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthFooterCompany from "../../../components/blocks/authContainer/AuthFooterCompany";
import MainAuthContainer from "../../../components/blocks/authContainer/MainAuthContainer";
import InputGroup2 from "../../../components/element/InputGroup2";
import InputPasswordGroup from "../../../components/element/InputPasswordGroup";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";
const queryString = require("query-string");

const ResetPassword = (props) => {
  const initialState = {
    token: "",
    password: "",
    retypePassword: "",
  };

  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);

    setPhone("+" + parsed?.tel.trim());
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.token) {
      errors.password = "Required";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Password too short i.e minimum of 8";
    }

    if (
      !values.retypePassword ||
      values.retypePassword !== values.password ||
      values.password.length < 8
    ) {
      errors.retypePassword = "Password does not match";
    }

    return errors;
  };

  return (
    <MainAuthContainer
      admin={props.admin}
      title="Reset Password"
      subTitle={
        "Enter the code sent to your email or SMS and set a new password below."
      }
      className="pt-0">
      <Formik
        initialValues={initialState}
        validate={validate}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setIsLoading(true);

          await props.completeResetPasswordRequestApi(
            { token: values.token, password: values.password },
            phone
          );

          setIsLoading(false);
          setSubmitting(false);

          if (props.alert.type === "success") {
            resetForm({ values: initialState });
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="group w-full">
                <InputGroup2
                  autoComplete="off"
                  label={
                    <span>
                      Enter Code Sent<span className="text-red-400">*</span>
                    </span>
                  }
                  name="token"
                  value={values.token}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.token && touched.token && errors.token}
                />
              </div>
              <div className="w-full">
                <InputPasswordGroup
                  label={"New Password"}
                  placeholder="New Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password && errors.password}
                />
              </div>
              <div className="w-full">
                <InputPasswordGroup
                  label={"Re-Type Password"}
                  placeholder="Re-Type Password"
                  name="retypePassword"
                  value={values.retypePassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.retypePassword &&
                    touched.retypePassword &&
                    errors.retypePassword
                  }
                />
              </div>
            </div>

            <div className="w-full">
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="font-bold w-full lg:w-auto py-4 px-14 bg-gradient text-white rounded-xl focus:outline-none text-sm-15 lg:text-base">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <div className="text-center pt-14 lg:pt-20 mb-7">
        <p className="text-temp-info text-sm lg:text-base">
          Don’t have an account?{" "}
          <Link to="/signup" className="font-bold text-temp-secondary">
            Sign up
          </Link>
        </p>
      </div>

      <div className="mt-20 block lg:hidden">
        <AuthFooterCompany />
      </div>

      {/* Trigger modal after form submission */}
      {isLoading && (
        <LoadingModal show={isLoading} title="Processing Request ..." />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl="/signin"
        />
      )}
    </MainAuthContainer>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, actions)(ResetPassword);
