import React from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

const WelcomeModal = ({
    show,
    handleCloseModal,
}) => {

    return (
        <div className="fixed z-30 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={show}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </Transition>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block absolute md:relative bottom-0 md:bottom-auto left-0 align-bottom md:align-center bg-white  rounded-t-3xl md:rounded-2xl text-left overflow-auto shadow-xl transform transition-all md:my-8 sm:align-middle sm:max-w-2xl sm:w-full w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="">
                        {/* Mbile Only */}
                    <div className="md:hidden block relative text-center pt-14 px-5">
                            <p className="text-6xl">🎉</p>
                            <p className="mt-6 text-temp-secondary font-semibold text-xl md:text-xl lg:text-2xl">Welcome to tempown</p>
                            <p className="mt-4 w-full mx-auto text-temp-primary font-light text-sm">
                            We are Africa&apos;s most insured online rental marketplace to rent and lend any item, 
                            equipment, or gadget. We connect individuals, creatives, and project executives to 
                            thousands of items and equipment owners. Now you can temporarily own any item on TempOwn.
                            </p>
                        </div>
                        {/* Desktop Only */}
                        <div className="hidden md:block relative text-center px-5 sm:px-12 bg-green-gradient pt-20 pb-20">
                            <p className="text-8xl">🎉</p>
                            <p className="mt-2 text-white font-semibold text-lg md:text-xl lg:text-2xl">Welcome to tempown</p>
                            <p className="mt-4 w-11/12 lg:w-10/12 mx-auto text-white text-sm-15 lg:text-base">
                            We are Africa&apos;s most insured online rental marketplace to rent and lend any item, 
                            equipment, or gadget. We connect individuals, creatives, and project executives to 
                            thousands of items and equipment owners. Now you can temporarily own any item on TempOwn.
                            </p>
                        </div>
                        <div className="px-5 sm:px-12 pt-6 md:pt-16 pb-20 mt-4 text-center">
                            <Link
                                to={"/complete-verification"}
                                onClick={handleCloseModal}
                                className="w-full md:w-auto block md:inline-block text-white font-semibold text-sm-15 md:text-base lg:text-lg bg-gradient py-4 px-6 lg:px-12 rounded-xl"
                            >
                                Complete Verification
                            </Link>
                            <button
                                onClick={handleCloseModal}
                                className="mt-12 mx-auto block underline text-temp-green-500 focus:outline-none text-sm md:text-sm-15 lg:text-base"
                            >
                                I will do this later
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeModal;
