import React from 'react'
import ProgressIndicator from '../../../components/element/ProgressIndicator'

const UploadTitlAndProgressIndicator = ({ progressPercent, title, subTitle }) => {
    return (
        <>
            <h3 className="text-lg md:text-xl lg:text-2xl text-temp-primary lg:mb-3 font-bold">
                Confirm Pickup
            </h3>

            {/* Large screen */}
            <div className="pt-4 w-6/12 hidden lg:block">
                <ProgressIndicator percent={progressPercent || 30} />
            </div>

            {/* mobile screen */}
            <div className="pt-4 w-full lg:hidden block">
                <ProgressIndicator strokeWidth={1} trailWidth={1} percent={progressPercent || 30} />
            </div>

            <div className="mt-10 lg:mt-14">
                <p className="font-bold text-sm-15 lg:text-base text-temp-gray pb-1">
                    {title}
                </p>
                <p className="text-temp-gray text-xs lg:text-sm-15">
                    {subTitle}
                </p>
            </div>
        </>
    )
}

export default UploadTitlAndProgressIndicator
