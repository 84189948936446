import { useNavigate } from "react-router-dom";
import store from "store";
import axios from "../../axios/axios";
import Actions from "./actionTypes";
import { alertActions } from "./alertActions";

const uploadImage = async (itemId, payload) => {
  try {
    const url = `/item/${itemId}/image`;

    const response = await axios.put(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteUploadImageApi = async (itemId, payload) => {
  try {
    const url = `/item/${itemId}/image`;

    const response = await axios.delete(url, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const createItemApi = (rentalUnitType, payload, image) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = rentalUnitType === "byCalender" ?
    "/item/calender/equipment" :
    rentalUnitType === "bySiUnits" ?
      "/item/si-unit/equipment" :
      "/item";

  try {
    const response = await axios.post(url, payload);
    const responseData = response.data.data;

    if (response.data.success) {
      await uploadImage(responseData._id, image);

      dispatch(alertActions.success("Item Created Successfully"));
    }
  } catch (error) {
    dispatch(alertActions.error(error?.response?.data?.message));
  }
};

export const updateItemApi = (
  rentalUnitType,
  id,
  payload,
  image,
  imageLength,
) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = rentalUnitType === "byCalender" ?
    `/item/calender/equipment/${id}` :
    rentalUnitType === "bySiUnits" ?
      `/item/si-unit/equipment/${id}` :
      `/item/${id}`;

  try {
    const response = await axios.put(url, payload);
    const responseData = response.data.data;

    if (response.data.success) {
      if (imageLength !== 0) {
        await uploadImage(responseData._id, image);
      }

      dispatch(alertActions.success("Item Updated Successfully"));
    }
  } catch (error) {
    dispatch(alertActions.error(error?.response?.data?.message));
  }
};

export const getItemsApi = (params) => async (dispatch) => {
  dispatch(alertActions.clear());
  dispatch({
    type: Actions.CLEAR_ITEM,
  });

  const url = "/item";
  try {
    const response = await axios.get(url, {
      params,
    });
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_ITEMS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const getSingleItemApi = (itemId) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/item/${itemId}`;
  try {
    const response = await axios.get(url);
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_SINGLE_ITEM,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error?.response?.data?.message));
  }
};

export const deleteItemApi = (itemId, history) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/item/${itemId}`;
  try {
    const response = await axios.delete(url);

    dispatch({
      type: Actions.DELETE_ITEM,
      payload: {
        type: "success",
        message: response.data.message || "Item deleted succefully",
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.DELETE_ITEM,
        payload: {
          type: "",
          message: "",
        },
      });

      history.push("/my-items");
    }, 3000);
  } catch (error) {
    dispatch({
      type: Actions.DELETE_ITEM,
      payload: {
        type: "error",
        message:
          error?.response?.data?.message || "Unable to authenticate token",
      },
    });

    setTimeout(() => {
      dispatch({
        type: Actions.DELETE_ITEM,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  }
};

export const checkItemAvailabilityApi = (
  rentalUnitType,
  payload,
  history,
  reRoute = true,
  isExtend = false,
  orderId
) => async (dispatch) => {
  dispatch(alertActions.clear());

  const navigate = useNavigate()
  const url = rentalUnitType === "bySiUnits" ? `/item/${payload?.itemId}/si-unit-rental-dates` : "/item/availability";
  try {
    const response = await axios.post(url, payload);

    if (reRoute) {
      if (isExtend) {
        store.set("pt_id", orderId);
      }
      navigate(
        `/checkout?id=${payload.itemId}&qty=${payload.quantity}&startDate=${payload.startDate || ""}${(rentalUnitType === "bySiUnits") ?
          `&unitOrdered=${payload?.unitOrdered}` :
          `&endDate=${payload.endDate || ""}`
        }`
      );
    } else {
      dispatch({
        type: Actions.CHECK_ITEM_AVAILABILITY,
        payload: response.data.data,
      });
    }
  } catch (error) {
    dispatch(
      alertActions.error(
        error?.response?.data?.message || "Unable to authenticate token"
      )
    );
  }
};

// Voucher Apply
export const applyVoucherApi = (voucherCode, payload) => async (
  dispatch
) => {
  dispatch(alertActions.clear());

  const url = `/vouchers/${voucherCode}/apply`;
  try {
    const response = await axios.post(url, payload);
    const responseData = response.data.data;

    if (response.data.success) {
      dispatch({
        type: Actions.APPLY_VOUCHER,
        payload: responseData,
      });

      return { status: "success" };
    }
  } catch (error) {
    return { error: error?.response?.data?.message };
  }
};

// Appply referral bonus
export const applyReferralOnItemAvailability = (payload) => async (
  dispatch
) => {
  dispatch(alertActions.clear());

  dispatch({
    type: Actions.APPLY_REFERRAL_BONUS_ON_ITEM_AVAILABILITY,
    payload: payload,
  });

  return { status: "success", data: payload?.referralBonus };
};


// Get Available Serial Numbers
export const getSerialNumberApi = (itemId) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/item/${itemId}/available-serial-numbers`;
  try {
    const response = await axios.get(url);
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_AVAILABLE_SERIAL_NUMBERS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};