import React from "react";
import { FailedIcon } from "../../../components/svgs";

const ItemAvailabilityStatus = ({ status }) => (
  <div className="mt-4 lg:mt-10 w-full mx-auto flex items-center space-x-3">
    {status ? (
      <svg
        className="w-4 h-4 lg:h-7 lg:w-7"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3652 20.1089C4.84223 20.1089 0.365234 15.6319 0.365234 10.1089C0.365234 4.58589 4.84223 0.108887 10.3652 0.108887C15.8882 0.108887 20.3652 4.58589 20.3652 10.1089C20.3652 15.6319 15.8882 20.1089 10.3652 20.1089ZM10.3652 18.1089C12.487 18.1089 14.5218 17.266 16.0221 15.7657C17.5224 14.2655 18.3652 12.2306 18.3652 10.1089C18.3652 7.98715 17.5224 5.95232 16.0221 4.45203C14.5218 2.95174 12.487 2.10889 10.3652 2.10889C8.2435 2.10889 6.20867 2.95174 4.70838 4.45203C3.20809 5.95232 2.36523 7.98715 2.36523 10.1089C2.36523 12.2306 3.20809 14.2655 4.70838 15.7657C6.20867 17.266 8.2435 18.1089 10.3652 18.1089V18.1089ZM9.36823 14.1089L5.12523 9.86589L6.53923 8.45189L9.36823 11.2809L15.0242 5.62389L16.4392 7.03789L9.36823 14.1089Z"
          fill="#08A05C"
        />
      </svg>
    ) : (
      <FailedIcon className="w-4 h-4 lg:h-8 lg:w-8" />
    )}

    <h3
      className={`text-lg md:text-xl lg:text-2xl font-medium ${
        status ? "text-temp-secondary" : "text-red-600"
      }`}
    >
      {status ? "Item is available" : "Item is not available"}
    </h3>
  </div>
);

export default ItemAvailabilityStatus;
