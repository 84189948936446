export const countDown = (duration, handleCount) => {
  let start = new Date();
  var timerId = setInterval(countdown, 1000);

  function countdown() {
    let current = new Date();
    let timeLeft = duration - Math.round((+current - +start) / 1000);
    if (timeLeft === -1) {
      clearTimeout(timerId);
      return "done";
    } else {
      handleCount(timeLeft);
    }
  }
};
