import React from "react";
import HighlistItem from "../../../components/blocks/HighlistItem";

const WhatYouWillGetSection = () => {
  return (
    <div className="container mx-auto px-4 pt-16 pb-32 lg:pt-20 lg:pb-44">
          <div className="block mb-4">
            <h3 className="text-3xl lg:text-3xl xl:text-4xl font-semibold text-temp-primary">
              What you will get
            </h3>
            <p className="text-temp-primary text-sm lg:text-base xl:text-lg py-4 leading-6">
              A marketplace built for you. Do more with us.
            </p>
          </div>

        <div className="mt-10 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              <HighlistItem
                title="Take control"
                content={`With a dedicated dashboard, you can enlist your items, control availability 
                checks, and calendar booking, and make other decisions whatsoever. You simply run the show!`}
              />
         
              <HighlistItem
                title="Equipment utilization"
                content="Maximize your equipment potential. Better to have others use them than lie dormant in a corner. You can make extra cash while offsetting the cost of your equipment."
              />
         
              <HighlistItem
                title="Increased revenue"
                content="Onboarding on our platform equates to increased revenue with relatively little effort from you. Growing your rental income is our priority."
              />
         
              <HighlistItem
                title="Safe Net"
                content="With TempOwn Protect, you can rent your equipment out confidently knowing we've got you covered."
              />
        </div>
    </div>
  );
};

export default WhatYouWillGetSection;
