import React from "react";
import SecureImage from "../../../assets/images/svg/rental-activities/secure.svg";
import MoneyImage from "../../../assets/images/svg/rental-activities/money.svg";
import LocationImage from "../../../assets/images/svg/rental-activities/location.svg";
import ReturnImage from "../../../assets/images/svg/rental-activities/return.svg";

const Card = ({ image, title }) => {
  return (
    <div className="text-center mx-auto flex flex-col justify-between">
      <div className="mb-6">
        <img src={image || MoneyImage} className="mx-auto" alt="" />
      </div>
      <p className="text-xs lg:text-sm">{title}</p>
    </div>
  );
};

const EquipmentHowTo = ({ loggedInUserDetails, orderDetails }) => {
  const borrower = [
    {
      id: 1,
      image: MoneyImage,
      title: "Make payment",
    },
    {
      id: 2,
      image: LocationImage,
      title: "Pickup on time",
    },
    {
      id: 3,
      image: SecureImage,
      title: "Use item with caret",
    },
    {
      id: 4,
      image: ReturnImage,
      title: "Return on time",
    },
  ];

  const lender = [
    {
      id: 1,
      image: MoneyImage,
      title: "Confirm item",
    },
    {
      id: 2,
      image: LocationImage,
      title: "Deliver on time",
    },
    {
      id: 3,
      image: SecureImage,
      title: "Provide guidance",
    },
    {
      id: 4,
      image: ReturnImage,
      title: "Maintain item",
    },
  ];

  const isBorrower = loggedInUserDetails?._id === orderDetails?.borrower?._id;
  const data = isBorrower ? borrower : lender;
  return (
    <div className="mt-14 border border-temp-gray-light rounded-xl">
      <div className="border-b border-temp-gray-light py-4 px-6 text-black font-medium text-sm-15 lg:text-base">
        {isBorrower
          ? "How to get the best of your rental"
          : "How to be a great host"}
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 py-8 lg:py-12 px-6">
        {data?.map((a) => (
          <Card key={a?.id} image={a?.image} title={a?.title} />
        ))}
      </div>

      <div className="bg-temp-green-100 text-temp-secondary inline-flex justify-center text-xs w-full relative rounded-b-xl  py-3">
        <a
          href="mailto:hello@tempown.com"
          className="font-bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact us{" "}
        </a>{" "}
        <span className="pl-1">if you have any enquiry</span>
      </div>
    </div>
  );
};

export default EquipmentHowTo;
