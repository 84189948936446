import React from "react";
import { numberFormat } from "../../../utils/numberFormat";

const Card = ({ id, percentageOff, title, value }) => {
  return (
    <div className="relative discount-card px-4 pt-6 pb-4 bg-white">
      <p className="text-xs text-temp-gray pb-1">{title}</p>
      <p className="text-sm-15 text-temp-secondary font-semibold">{value}</p>

      {id !== 1 && (
        <div
          className={`discount-${id} absolute right-0 top-0 rounded-bl-2xl rounded-tr-2xl bg-temp-secondary text-white px-4 text-xs font-medium`}
        >
          {percentageOff}%
        </div>
      )}
    </div>
  );
};

const EquipmentDiscountDetails = ({ item }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
      {/* siUnitDiscount -note */}
      <Card
        id={1}
        title={
          item?.rentalUnitType === "bySiUnits"
            ? "Per " + item?.siUnitType
            : "Per Day"
        }
        value={`₦${numberFormat(item?.price?.amount)}`}
      />

      <Card
        id={2}
        title="Discount/Week"
        percentageOff={item?.price?.discountPerMonth}
        value={`₦${numberFormat(
              item?.price?.amount -
                (item?.price?.discountPerMonth / 100) * item?.price?.amount
            )}`}
      />

      <Card
        id={3}
        title="Discount/Month"
        percentageOff={item?.price?.discountPerMonth}
        value={`₦${numberFormat(
              item?.price?.amount -
                (item?.price?.discountPerMonth / 100) * item?.price?.amount
            )}`}
      />
    </div>
  );
};

export default EquipmentDiscountDetails;
