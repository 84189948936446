/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-else-if */
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, } from "react-router-dom";
import store from "store";
import IdentityImage from "../../../assets/images/svg/identity.svg";
import CustomSelectGroup from "../../../components/element/CustomSelectGroup";
import InputDatePicker from "../../../components/element/InputDatePicker";
import InputGroup2 from "../../../components/element/InputGroup2";
import OnboardingContainer from "../../../components/layout/OnboardingContainer";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";

const VerifyUserIdentity = (props) => {
  const [loading, setLoading] = useState(false);
  const [ninInfoModal, setNinInfoModal] = useState(false);
  const navigate = useNavigate()
  const identificationTypeOptions = [
    {
      label: "Virtual National Identity Number (VNIN)",
      value: "virtual-nin",
    },
    {
      label: "Driver's Licence",
      value: "drivers-license",
    },
    {
      label: "International Passport",
      value: "passport",
    },
    {
      label: "Voters Card",
      value: "vin",
    },
  ];

  const initialState = {
    type: "",
    number: "",
    firstName: "",
    lastName: "",
    dob: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.type) {
      errors.type = "Identification is required is required";
    }

    // if (!values.number) {
    //   errors.number = "Card number is required";
    // }

    if (!values.firstName) {
      errors.firstName = "First name is required";
    }

    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }

    return errors;
  };

  const handleType = (e) => {
    if (e.value === "virtual-nin") {
      setNinInfoModal(true);
    }
  };

  useEffect(() => {
    const userData = store.get("temp_usER12_dATa");
    if (!userData) {
      navigate("/");
    }
    if (userData?.verified_id) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <OnboardingContainer pageTitle="Verification">
        <div className="flex justify-center mb-10">
          <div className="w-full lg:w-7/12 pt-8 lg:pt-4 mx-auto px-0 lg:px-10 text-center">
            <div className="pt-4 pb-6">
              <img src={IdentityImage} className="mx-auto" alt="" />
            </div>
            <h2 className="text-temp-green-500 font-semibold text-xl lg:text-2xl xl:text-3xl">
              Identification
            </h2>
            <div className="mt-20 mb-4 lg:mb-6 w-full lg:w-10/12 mx-auto">
              <Formik
                initialValues={initialState}
                validate={validate}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  setLoading(true);

                  if (values.type.value === "vin" && values.dob === "") {
                    alert("Please input date of birth");
                  }

                  if (values.type.value !== "vin" && values.number === "") {
                    alert("Please input identification number");
                  }

                  let payload = {
                    number: values.number,
                    firstname: values.firstName,
                    lastname: values.lastName,
                    type: values.type?.value,
                  };

                  if (values.type?.value === "vin") {
                    payload.dob = moment(values.dob).format("YYYY-MM-DD");
                  }

                  await props.verifyUserIdentityCardApi(
                    values.type?.value === "bvn" ? "/verify/bvn" : "/verify/id",
                    payload
                  );

                  setLoading(false);
                  setSubmitting(false);

                  if (props.alert.type === "success") {
                    resetForm({ values: initialState });
                  }
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="w-full text-left">
                      <p className="text-sm lg:text-base">
                        Please select identification type
                      </p>
                      <CustomSelectGroup
                        className="mb-1 lg:mb-2"
                        placeholder="Select"
                        name="type"
                        value={values.type}
                        options={identificationTypeOptions}
                        onChange={(e) => {
                          setFieldValue("type", e);
                          handleType(e);
                        }}
                        onBlur={handleBlur}
                        isClearable={true}
                        error={errors.type && touched.type && errors.type}
                      />
                    </div>

                    {values?.type && (
                      <>
                        <div className="pt-2 pb-4 text-left">
                          {values?.type?.value === "virtual-nin" && (
                            <button
                              type="button"
                              onClick={() => setNinInfoModal(true)}
                              style={{
                                backgroundColor: "#E8FFF5",
                                borderRadius: "3px",
                              }}
                              className="flex items-center space-x-3 py-4 px-3 w-full">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M8.00065 1.33276C11.6873 1.33276 14.6673 4.3201 14.6673 7.99943C14.6673 11.6801 11.6873 14.6661 8.00065 14.6661C4.32065 14.6661 1.33398 11.6801 1.33398 7.99943C1.33398 4.3201 4.32065 1.33276 8.00065 1.33276ZM8.00065 9.95343C7.68065 9.95343 7.42065 10.2134 7.42065 10.5334C7.42065 10.8534 7.68065 11.1201 8.00732 11.1201C8.32732 11.1201 8.58732 10.8534 8.58732 10.5334C8.58732 10.2134 8.32732 9.95343 8.00065 9.95343ZM8.00065 4.8861C7.68065 4.8861 7.41398 5.15343 7.41398 5.47276V8.41943C7.41398 8.7401 7.68065 8.99943 8.00065 8.99943C8.32065 8.99943 8.58065 8.7401 8.58065 8.41943V5.47276C8.58065 5.15343 8.32065 4.8861 8.00065 4.8861Z"
                                  fill="#08A05C"
                                />
                              </svg>

                              <span className="text-temp-secondary text-xs">
                                Find out how to request for your VNIN
                              </span>
                            </button>
                          )}
                        </div>
                        <div className="w-full md:pb-4 lg:pb-0">
                          <InputGroup2
                            className="mb-2 lg:mb-4"
                            placeholder={
                              values?.type?.value === "virtual-nin"
                                ? "Virtual Identification Number"
                                : values?.type?.value === "passport"
                                ? "Passport Number"
                                : "Identification Number"
                            }
                            name="number"
                            value={values.number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.number && touched.number && errors.number
                            }
                          />
                        </div>
                        <div className="w-full grid grid-cols-1 lg:grid-cols-1 gap-x-6 mb-4">
                          <div className="w-full">
                            <InputGroup2
                              className="mb-2 lg:mb-4"
                              placeholder="First Name"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.firstName &&
                                touched.firstName &&
                                errors.firstName
                              }
                            />
                          </div>
                          <div className="w-full">
                            <InputGroup2
                              className="mb-2 lg:mb-4"
                              placeholder="Last Name"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.lastName &&
                                touched.lastName &&
                                errors.lastName
                              }
                            />
                          </div>

                          {values?.type?.value === "vin" ? (
                            <div className="w-full">
                              <InputDatePicker
                                placeholder="Date of Birth"
                                name="dob"
                                value={values.dob}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                showMonthDropdown
                                autoComplete="off"
                                maxDate={new Date()}
                                onChange={(e) => {
                                  setFieldValue("dob", e[0]);
                                }}
                                error={errors.dob && touched.dob && errors.dob}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="text-center">
                          {values?.type.value !== "vin" ? (
                            <button
                              type="submit"
                              disabled={
                                isSubmitting ||
                                values.number.length === 0 ||
                                values.firstName.length === 0 ||
                                values.lastName.length === 0
                              }
                              className={`w-full h-12 lg:h-14 rounded-xl text-sm lg:text-base py-4 px-8 text-white font-bold
                                                        ${
                                                          isSubmitting ||
                                                          values.number
                                                            .length === 0 ||
                                                          values.firstName
                                                            .length === 0 ||
                                                          values.lastName
                                                            .length === 0
                                                            ? "bg-temp-green-500 opacity-60"
                                                            : "bg-gradient"
                                                        }
                                                        }`}>
                              Submit
                            </button>
                          ) : (
                            <button
                              type="submit"
                              disabled={
                                isSubmitting ||
                                values.number.length === 0 ||
                                values.dob.length === 0 ||
                                values.firstName.length === 0 ||
                                values.lastName.length === 0
                              }
                              className={`w-full h-12 lg:h-14 rounded-xl text-sm lg:text-base py-4 px-8 text-white font-bold
                                                        ${
                                                          isSubmitting ||
                                                          values.number
                                                            .length === 0 ||
                                                          values.dob.length ===
                                                            0 ||
                                                          values.firstName
                                                            .length === 0 ||
                                                          values.lastName
                                                            .length === 0
                                                            ? "bg-temp-green-500 opacity-60"
                                                            : "bg-gradient"
                                                        }
                                                        }`}>
                              Submit
                            </button>
                          )}
                        </div>
                      </>
                    )}
                    <div
                      className={`${
                        values?.type ? "mt-32" : "mt-80"
                      } text-temp-gray text-sm lg:text-base text-center`}>
                      <Link
                        className="text-temp-green-500 underline focus:outline-none"
                        to="/">
                        I will do this later
                      </Link>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          {/* Trigger modal after form submission */}
          {loading && <LoadingModal show={loading} />}

          {/* Trigger Message */}
          {props.alert.message && (
            <AlertModal
              show={props.alert.type ? true : false}
              type={props.alert.type}
              content={props.alert.message}
              linkUrl={
                props.alert.message &&
                props.alert.message?.includes("Verification successful")
                  ? "/complete-verification"
                  : ""
              }
            />
          )}
        </div>
      </OnboardingContainer>

      {ninInfoModal ? (
        <div
          onClick={() => setNinInfoModal(!ninInfoModal)}
          className="fixed top-0 right-0 left-0 bottom-0 w-full h-screen flex items-center justify-center"
          style={{
            background: "rgba(0, 0, 0, 0.35)",
          }}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              borderRadius: "10px",
              maxWidth: "653px",
            }}
            className="w-11/12 md:w-1/2 lg:w-2/3 bg-white py-10 px-5 lg:px-8">
            <h2 className="text-base text-temp-secondary mb-5 font-bold text-center lg:text-lg lg:mb-8">
              How to request for your Virtual NIN
            </h2>

            <div
              style={{
                background: "rgba(232, 255, 245, 0.45)",
                borderRadius: "10px",
                border: "0.4px solid #89D8C9",
              }}
              className="px-5 py-8 lg:px-8 lg:py-10">
              <div
                style={{
                  borderBottom: "1px solid #08A05C",
                }}
                className="pb-4">
                <h3 className="text-base text-temp-secondary uppercase mb-3 font-medium lg:text-lg lg:mb-4">
                  USSD
                </h3>
                <p className="text-sm text-temp-secondary mb-1 lg:text-base">
                  Dial <b>*346*3*YourNIN*AgentCode#</b>
                </p>
                <p className="text-sm text-temp-secondary lg:text-base">
                  Use Agent code: 715461
                </p>
              </div>
              <div className="pt-4">
                <h3 className="text-base text-temp-secondary mb-3 font-medium lg:text-lg lg:mb-4">
                  NIMC mobile app
                </h3>
                <ul className="flex space-y-3 flex-col">
                  <li className="text-sm text-temp-secondary lg:text-base">
                    <b>1.</b> Download the <b>NIMC</b> app from Google Playstore
                    or Apple Appstore
                  </li>
                  <li className="text-sm text-temp-secondary lg:text-base">
                    <b>2.</b> Open the NIMC app and select{" "}
                    <b>“Get Virtual NIN”</b>
                  </li>
                  <li className="text-sm text-temp-secondary lg:text-base">
                    <b>3.</b> Select <b>“Type Enterprise’s ID”</b>
                  </li>
                  <li className="text-sm text-temp-secondary lg:text-base">
                    <b>4.</b> Enter the agent code <b>“715461”</b>
                  </li>
                  <li className="text-sm text-temp-secondary lg:text-base">
                    <b>5.</b> Click <b>“Submit”</b> and get your Virtual NIN
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(VerifyUserIdentity)
);
