import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const getEmissionsApi = () => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/emission/user";
  try {
    const response = await axios.get(url);
    const responseData = response.data.data;
    dispatch({
      type: Actions.GET_EMISSIONS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const getEmissionsLBApi = (params) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/emission/leaderboard";
  try {
    const response = await axios.get(url, {
      params,
    });
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_EMISSION_LB,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const createEmissionApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/emission";
  try {
    await axios.post(url, payload);
    // const responseData = response.data.data;

    dispatch(alertActions.success("Item Created Successfully"));
  } catch (error) {
    dispatch(alertActions.error(error?.response?.data?.message));
  }
};
