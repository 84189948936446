import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import store from "store";

export const lenderAttestationApi = () => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/attestation/lender`;
  try {
    const response = await axios.get(url);

    const userData = store.get("temp_usER12_dATa");

    store.set("temp_usER12_dATa", {
      ...userData,
      type: response.data.type,
      verified_lender: true,
    });

    dispatch(alertActions.success(response.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};
