import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router-dom";
import InputGroup2 from "../../../../components/element/InputGroup2";
import AlertModal from "../../../../components/modals/AlertModal";
import InfoModal from "../../../../components/modals/InfoModal";
import * as actions from "../../../../store/actions";

const BankDetails = (props) => {
  const initialState = {
    accountNumber: "",
    bankCode: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.accountNumber) {
      errors.accountNumber = "Required";
    } else if (!/.*?(\d)[^\d]*/g.test(values.accountNumber)) {
      errors.accountNumber = "Input must be a number";
    }

    if (!values.bankCode) {
      errors.bankCode = "Select your bank";
    }

    return errors;
  };

  const [banks, setBanks] = useState([]);

  const [bankFetchingError, setBankFetchingError] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleShowInfoModal = (val) => setShowInfoModal(val);

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = async () => {
    await axios
      .get("https://api.paystack.co/bank")
      .then((data) => setBanks(data.data.data))
      .catch((e) => setBankFetchingError(e));
  };

  return (
    <div className="w-full lg:w-9/12 mt-14">
      <div className="">
        <Formik
          initialValues={initialState}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            const payload = {
              accountNumber: values.accountNumber,
              bankCode: values.bankCode,
              bankName: banks.find((a) => a.code === values.bankCode)?.name,
            };

            await props.addBankApi(payload, props.history);

            props.handeStepChange("step3");

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="pt-6 pb-6 text-left md:pt-6 md:pb-4 lg:pt-8 lg:pb-8"
            >
              <InputGroup2
                label={"Account Number"}
                name="accountNumber"
                value={values.accountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.accountNumber &&
                  touched.accountNumber &&
                  errors.accountNumber
                }
              />

              <div className="mb-4">
                <label
                  htmlFor={"bankCode"}
                  className={`text-sm lg:text-base text-temp-primary`}
                >
                  Select Bank
                </label>
                <div className="relative input input-field w-full mt-3 mb-2">
                  <select
                    name="bankCode"
                    value={values.bankCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`rounded-xl placeholder-temp-primary text-sm lg:text-base w-full h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-temp-primary focus:outline-none bg-white border ${errors.bankCode
                      ? "border-red-600"
                      : "border-temp-gray-light"
                      }`}
                  >
                    <option>Bank</option>
                    {banks?.map((bank) => (
                      <option key={bank.id} value={bank.code}>
                        {bank.name}
                      </option>
                    ))}
                  </select>
                </div>
                <span className="text-red-600 text-xs lg:text-sm">
                  {errors.bankCode && touched.bankCode && errors.bankCode}
                </span>
              </div>
              <p
                className=" text-sm text-temp-green-500 inline-flex cursor-pointer focus:outline-none"
                style={{
                  webkitTapHighlightColor: "rgba(255, 255, 255, 0)",
                }}
                onClick={() => handleShowInfoModal(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex mr-1"
                  width="20"
                  height="18"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#08a05c"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="12" y1="8" x2="12.01" y2="8" />
                  <polyline points="11 12 12 12 12 16 13 16" />
                </svg>
                <span className="self-end"> Why we need this?</span>
              </p>

              {bankFetchingError && (
                <p className="text-temp-primary py-4">{bankFetchingError}</p>
              )}
              <div className="block relative mt-4 text-right">
                <button
                  disabled={
                    isSubmitting ||
                    values.accountNumber.length === 0 ||
                    values.bankCode.length === 0
                  }
                  type="submit"
                  className={`w-full md:w-auto whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white text-sm lg:text-base font-semibold px-12 lg:px-16 py-4
                  ${isSubmitting ||
                      values.accountNumber.length === 0 ||
                      values.bankCode.length === 0
                      ? "bg-gradient opacity-60"
                      : "bg-gradient"
                    }`}
                >
                  {isSubmitting ? "Process... Please wait" : "Next"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}

      {showInfoModal && (
        <InfoModal
          show={showInfoModal}
          handleShow={handleShowInfoModal}
          content={
            <React.Fragment>
              <p className="mb-2">
                Your account details are required for you to withdraw funds from
                your wallet.
              </p>
              <p className="mb-2">
                When a borrower pays the rental fee for an equipment, the fee is
                deposited into your wallet. Upon withdrawal request from you, it
                is paid into your bank account you provide.
              </p>
            </React.Fragment>
          }
          subText={"Your BVN is not required for this."}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    banks: state.banks,
    alert: state.alert,
  };
};

export default (connect(mapStateToProps, actions)(BankDetails));
