import React from "react";
import LenderInformationWithViewButton from "../../../components/blocks/itemBits/LenderInformationWithViewButton";
import CheckImage from "../../../assets/images/svg/checkgradient.svg";
import BorrowerInformationWithViewButton from "../../../components/blocks/itemBits/BorrowerInformationWithViewButton";

import { Link } from "react-router-dom";

const PickUpDisplayForBorrowerOnly = ({ code }) => {
  return (
    <>
      <div className="mt-6 px-6 lg:px-8 py-3">
        <p className="text-temp-primary text-sm-15 text-left">
          Pick up Code: <span className="font-bold text-temp-secondary pl-2">{code}</span>
        </p>
      </div>
      <div className="px-6 lg:px-8">
        <p className="text-xs lg:text-sm text-temp-gray text-left leading-5">
          Please give the Lender the code above. You will be required to upload
          a picture of the item to confirm pickup.
        </p>
      </div>
    </>
  );
};

const CodeSubmittedDisplayForLenderOnly = () => {
  return (
    <div className="text-center mx-auto bg-temp-green-100 py-2 flex flex-wrap justify-center items-center">
      <img src={CheckImage} className="mr-2" alt="" />
      <p className="text-sm text-temp-green-500">Code submitted</p>
    </div>
  );
};

const ConfirmPickupCard = ({
  loggedInUserDetails,
  orderDetails,
  itemDetails,
  handleShowModal,
  handleShowEnterCodeModal,
}) => {
  return (
    <div
      className="rounded-xl py-6 lg:py-8 mx-auto"
      style={{
        border: "0.5px solid #B0B0B0",
      }}
    >
      <div className="text-temp-secondary font-semibold px-6 lg:px-8 mb-4 lg:mb-6 text-base lg:text-lg">Order Activities</div>
      <div className="flex flex-wrap items-baseline pr-8">
        <p className="px-6 lg:px-8 text-sm lg:text-sm-15 pb-4 text-temp-gray">
          Status
        </p>
        <p className="px-4 lg:px-6 font-medium text-xs lg:text-sm accepted-status rounded-xl pb-3 pt-3">
          Accepted
        </p>
      </div>

      {/* 
        If the lender hasnt uploaded the equipment image and selec serial 
        number, show the button to do that else display the pickup code button 
      */}

      {loggedInUserDetails?._id === orderDetails?.lender?._id &&
        orderDetails?.confirmPickup?.lenderImages?.length === 0 ? (
        <div className="px-6 lg:px-8 mt-4 lg:mt-6">
          <Link
            to={`/order/upload-image/?id=${orderDetails?._id}&itemId=${orderDetails?.itemId?._id}`}
            className="w-full block text-center mt-5 font-bold text-xs lg:text-sm py-4 px-12 text-white rounded-xl focus:outline-none bg-gradient"
          >
            Complete Order Process
          </Link>

          <p className="text-temp-gray text-sm text-left mt-4">
            You are required to upload the equipment images and also select
            serial numbers (if applicable) of the items to be rented.
          </p>
        </div>
      ) : (
        <>
          <div className="px-6 lg:px-8 mt-4 lg:mt-6">
            {loggedInUserDetails?._id === orderDetails?.lender?._id &&
              orderDetails?.confirmPickup?.tokenConfirmed ? (
              ""
            ) : (
              <>
                {orderDetails?.lender?._id !== orderDetails?.borrower?._id && (
                  <button
                    onClick={
                      loggedInUserDetails?._id === orderDetails?.borrower?._id
                        ? handleShowModal
                        : handleShowEnterCodeModal
                    }
                    className="w-full mt-2 font-semibold text-xs lg:text-sm py-4 px-12 text-white rounded-xl focus:outline-none bg-gradient"
                  >
                    Confirm Pickup
                  </button>
                )}{" "}
              </>
            )}
          </div>

          {loggedInUserDetails?._id === orderDetails?.lender?._id &&
            orderDetails?.confirmPickup?.tokenConfirmed ? (
            <CodeSubmittedDisplayForLenderOnly />
          ) : (
            <>
              {loggedInUserDetails?._id === orderDetails?.borrower?._id &&
                !orderDetails?.confirmPickup?.tokenConfirmed ? (
                <PickUpDisplayForBorrowerOnly
                  code={orderDetails?.confirmPickup?.token?.content}
                />
              ) : loggedInUserDetails?._id === orderDetails?.lender?._id ? (
                <div className="mt-6 px-6 lg:px-8">
                  <p className="text-temp-gray text-sm text-left">
                    Request for pickup code from the Borrower.
                  </p>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </>
      )}

      {/* Desktop Only */}
      <div className="hidden lg:block mt-12">
        {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
          <LenderInformationWithViewButton
            title="Lender"
            item={itemDetails}
            pickupLocation={orderDetails?.itemId?.pickupLocation}
            pickupContact={orderDetails?.lender?.phone}
          />
        ) : (
          <BorrowerInformationWithViewButton
            title="Borrower"
            user={orderDetails?.borrower}
            pickupContact={orderDetails?.borrower?.phone}
          />
        )}
      </div>
    </div>
  );
};

export default ConfirmPickupCard;
