import React, { useState } from "react";
import { connect } from "react-redux";
import InputGroup2 from "../../../components/element/InputGroup2";
import * as actions from "../../../store/actions";
import VoucherFormMobile from "./VoucherFormMobile";

const VoucherForm = ({ itemAvailability, startDate, endDate, ...props }) => {
  const [voucher, setVoucher] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (voucher?.length === 0) {
      setError("Invalid voucher");
    } else {
      setError("");
      setLoading(true);

      const {
        baseFee,
        total,
        itemId,
        serviceCharge,
      } = itemAvailability?.summary;

      const payload = {
        baseFee,
        serviceCharge,
        total,
        itemId,
        startDate,
        endDate,
      };

      const resp = await props.applyVoucherApi(voucher, payload);

      if (resp?.status === "success" ) {
        setShowForm(false);
        setResponseStatus(resp?.status);
      } else if (resp.error) {
        setError(resp.error);
      }

      setLoading(false);
    }
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };
  return (
    <>
      <div className="relative">
        <div
          className={`relative flex flex-row justify-between items-center  ${
            !showForm && "mb-6"
          }`}
        >
          <p className="text-temp-primary text-sm lg:text-sm-15">
            Got a Voucher?
          </p>

          {responseStatus?.length === 0 ? (
            <button
              disabled={loading}
              className="focus:outline-none text-temp-secondary underline text-sm lg:text-sm-15"
              onClick={handleShowForm}
            >
              <p className="">Use Voucher</p>
            </button>
          ) : (
            <div className="flex items-center">
              <div className="bg-white">
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.01738" cy="7.2498" r="7.13456" fill="#08A05C" />
                  <path
                    d="M7.00982 10.4204C6.83526 10.4206 6.66738 10.3506 6.54119 10.2249L4.52872 8.21338C4.46455 8.14927 4.41313 8.07262 4.3774 7.9878C4.34167 7.90299 4.32232 7.81167 4.32046 7.71907C4.3186 7.62647 4.33427 7.53439 4.36657 7.4481C4.39887 7.3618 4.44717 7.28298 4.50871 7.21613C4.57026 7.14928 4.64383 7.09571 4.72525 7.05849C4.80666 7.02126 4.89431 7.00111 4.9832 6.99917C5.0721 6.99724 5.16048 7.01356 5.24332 7.04721C5.32615 7.08086 5.40181 7.13118 5.46598 7.19529L6.96024 8.68835L10.5207 4.33366C10.578 4.26176 10.6484 4.20243 10.7278 4.1591C10.8072 4.11577 10.8941 4.08931 10.9835 4.08124C11.0728 4.07318 11.1628 4.08369 11.2482 4.11214C11.3336 4.1406 11.4127 4.18644 11.481 4.24701C11.5493 4.30758 11.6053 4.38167 11.6459 4.46498C11.6864 4.54829 11.7107 4.63917 11.7173 4.73233C11.7239 4.82549 11.7126 4.91909 11.6842 5.00769C11.6558 5.09629 11.6108 5.17812 11.5517 5.24845L7.52524 10.1724C7.46541 10.2456 7.39185 10.3052 7.30908 10.3477C7.22631 10.3902 7.13608 10.4147 7.04391 10.4195C7.03261 10.4201 7.0213 10.4204 7.00982 10.4204Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-temp-green-500 text-sm lg:text-sm-15 ml-1">
                Applied
              </p>
            </div>
          )}
        </div>

        {showForm && (
          <div className="hidden lg:block px-4 lg:px-6 pt-4 pb-6 rounded-xl shadow-xl absolute top-0 bg-white w-full z-10">
            <form onSubmit={handleSubmit}>
              <div className="relative lg:flex flex-col">
                <div className="">
                  <p className="font-semibold text-temp-primary mb-4 text-sm-15 lg:text-base">
                    Apply Voucher
                  </p>
                </div>
                <div className="w-full">
                  <InputGroup2
                    placeholder="Enter code"
                    placeholderColor="text-temp-gray"
                    name="voucher"
                    value={voucher}
                    onChange={(e) => {
                      setError("");
                      setVoucher(e.target.value);
                    }}
                    error={error}
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setVoucher("");
                      setError("");
                      setShowForm(false);
                      setResponseStatus("");
                    }}
                    className="font-semibold h-12 w-full lg:w-4/12 lg:h-12 text-red-600 rounded-xl text-sm lg:text-sm-15 focus:outline-none"
                  >
                    Close
                  </button>
                  <button
                    disabled={
                      (voucher?.length === 0 ||
                        itemAvailability?.summary?.total === 0) &&
                      true
                    }
                    className={`
      font-semibold h-12 w-full lg:w-4/12 lg:h-12 bg-gradient text-white rounded-xl text-sm lg:text-sm-15 focus:outline-none
       ${(voucher?.length === 0 || loading) && "opacity-75"}
      `}
                  >
                    {loading ? "Loading..." : "Apply"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {showForm && (
        <VoucherFormMobile
          show={showForm}
          setShowForm={setShowForm}
          voucher={voucher}
          error={error}
          setError={setError}
          setVoucher={setVoucher}
          setResponseStatus={setResponseStatus}
          itemAvailability={itemAvailability}
          loading={loading}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default connect(null, actions)(VoucherForm);
