import React, { useState } from "react";

const InputPasswordGroup = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
}) => {
  const [toggleShow, setToggleShow] = useState(false);
  const handleToggleShow = () => {
    setToggleShow(!toggleShow);
  };

  return (
    <div className="mb-6">
      <div className="relative input input-field w-full mb-2">
        <input
          type={toggleShow ? "text" : "password"}
          className={`block px-5 h-14 w-full rounded-xl border bg-white placeholder-temp-gray focus:outline-none text-sm md:text-base  ${error ? "border-red-600" : " border-temp-gray-light"
            }`}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />

        <label className="text-temp-secondary input-label text-xs lg:text-xs">{label}</label>

        <p
          className="absolute bg-white right-1 top-6 w-8 rounded-lg mx-auto text-center text-xs lg:text-sm text-temp-green-500 font-medium"
          onClick={handleToggleShow}
        >
          {toggleShow ?
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.58599 0.835449C9.73132 0.835449 12.3482 3.09878 12.8971 6.08545C12.3487 9.07212 9.73132 11.3354 6.58599 11.3354C3.44065 11.3354 0.823819 9.07212 0.274902 6.08545C0.823236 3.09878 3.44065 0.835449 6.58599 0.835449ZM6.58599 10.1688C7.77568 10.1685 8.93005 9.76443 9.86015 9.02263C10.7903 8.28083 11.441 7.24528 11.7059 6.08545C11.44 4.92654 10.7889 3.89212 9.85885 3.1513C8.92883 2.41048 7.775 2.00709 6.58599 2.00709C5.39698 2.00709 4.24314 2.41048 3.31312 3.1513C2.38311 3.89212 1.73193 4.92654 1.46607 6.08545C1.73096 7.24528 2.38171 8.28083 3.31182 9.02263C4.24193 9.76443 5.3963 10.1685 6.58599 10.1688ZM6.58599 8.71045C5.88979 8.71045 5.22211 8.43389 4.72983 7.9416C4.23755 7.44932 3.96099 6.78164 3.96099 6.08545C3.96099 5.38926 4.23755 4.72158 4.72983 4.22929C5.22211 3.73701 5.88979 3.46045 6.58599 3.46045C7.28218 3.46045 7.94986 3.73701 8.44214 4.22929C8.93442 4.72158 9.21099 5.38926 9.21099 6.08545C9.21099 6.78164 8.93442 7.44932 8.44214 7.9416C7.94986 8.43389 7.28218 8.71045 6.58599 8.71045ZM6.58599 7.54378C6.97276 7.54378 7.34369 7.39014 7.61718 7.11665C7.89067 6.84316 8.04432 6.47222 8.04432 6.08545C8.04432 5.69868 7.89067 5.32774 7.61718 5.05425C7.34369 4.78076 6.97276 4.62712 6.58599 4.62712C6.19921 4.62712 5.82828 4.78076 5.55479 5.05425C5.2813 5.32774 5.12765 5.69868 5.12765 6.08545C5.12765 6.47222 5.2813 6.84316 5.55479 7.11665C5.82828 7.39014 6.19921 7.54378 6.58599 7.54378Z" fill="black" />
            </svg>
            :
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.20735 7.6643L4.08093 7.36213L4.54001 5.64771C3.85283 5.39427 3.21417 5.02493 2.65176 4.55571L1.39585 5.81221L0.570431 4.9868L1.82693 3.73088C1.11764 2.88141 0.641065 1.86233 0.443848 0.773381L1.59185 0.563965C2.0346 3.01513 4.17893 4.8748 6.75785 4.8748C9.33618 4.8748 11.4811 3.01513 11.9238 0.563965L13.0718 0.772798C12.8749 1.86189 12.3985 2.88118 11.6893 3.73088L12.9453 4.9868L12.1198 5.81221L10.8639 4.55571C10.3015 5.02493 9.66287 5.39427 8.97568 5.64771L9.43476 7.36271L8.30835 7.6643L7.84868 5.9493C7.12672 6.07301 6.38898 6.07301 5.66701 5.9493L5.20735 7.6643Z" fill="black" />
            </svg>
          }
        </p>
      </div>
      <span className="text-red-600 text-xs lg:text-sm">{error}</span>
    </div>
  );
};

export default InputPasswordGroup;
