import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const InputPhoneNumberGroup = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  ...props
}) => {
  return (
    <div className="mb-6">
      <div className="relative input input-field w-full mb-2">
        <PhoneInput
          country={"ng"}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          countryCodeEditable={false}
          containerClass={` block h-14 w-full rounded-xl border bg-white placeholder-temp-gray focus:outline-none text-sm md:text-base ${
            error ? "border-red-600" : " border-temp-gray-light"
          }`}
          buttonStyle={{
            borderTopLeftRadius: ".5rem",
            borderBottomLeftRadius: ".5rem",
            background: "transparent",
            border: "none",
          }}
          inputStyle={{
            height: "inherit",
            border: "none",
            background: "transparent",
            width: "100%",
            borderTopRightRadius: ".5rem",
            borderBottomRightRadius: ".5rem",
          }}
          buttonClass="bg-white"
          {...props}
        />
        <label className="text-temp-secondary input-label text-xs lg:text-xs">{label}</label>
      </div>
      <span className="text-red-600 text-xs lg:text-sm">{error}</span>
    </div>
  );
};

export default InputPhoneNumberGroup;
