import React from "react";
import CheckIcon from "../../assets/images/svg/checked.svg";
import { capitalizeFistLetterInSentence } from "../../utils/capitalizeFirstLetterInSentence";
import StarRating from "./StarRating";
import AvatarImage from "../../assets/images/svg/user.svg";

const UserRatingCard = ({
  image,
  name,
  membershipStartDate = new Date(),
  rating,
  padding = "p-6",
  imageSize,
  imageWidth,
  imageHeight,
  content,
  flexItem,
  backgroundColor,
  showStarRating = true,
  ...props
}) => {
  return (
    <div
      className={`${padding} pb-3 rounded-lg ${backgroundColor || "bg-white"}`}
    >
      <div className={`flex flex-wrap ${flexItem || "items-center"}`}>
        <div className="relative mr-4 pb-3  flex-shrink">
          <img
            src={image || AvatarImage}
            width={imageSize || "80%"}
            className={`${imageWidth || "w-14"} ${
              imageHeight || "h-14"
            } object-cover rounded-full border-none outline-none bg-white`}
            alt=""
          />
          <img
            src={CheckIcon}
            width="22rem"
            className="border-2 border-white rounded-full absolute right-0 -mt-4"
            alt=""
          />
        </div>
        <div className="relative block  flex-1 mb-3">
          <h4 className="text-sm md:text-sm-15 xl:text-base font-medium text-temp-primary">
            {capitalizeFistLetterInSentence(name?.toLowerCase())}
          </h4>
          <p className="text-xs text-temp-gray">
            Member since{" "}
            {new Date(membershipStartDate).toLocaleDateString(
              "en-US",

              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
          </p>

          {showStarRating && <StarRating rating={rating} {...props} />}

          {content}
        </div>
      </div>
    </div>
  );
};

export default UserRatingCard;
