/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import MainContainer from "../../components/layout/MainContainer";

import AlertModal from "../../components/modals/AlertModal";
import * as actions from "../../store/actions";

import AlertModal2 from "../../components/modals/AlertModal2";

import MapIcon from "../../assets/images/svg/map.svg";
import DeleteModal from "../../components/modals/DeleteModal";
import LoadingModal from "../../components/modals/LoadingModal";
import AddAddressModal from "./components/AddAddressModal";
import AddressDetails from "./components/AddressDetails";
import EditAddressModal from "./components/EditAddressModal";
import EmptyCard from "./components/EmptyCard";
import ProfileHeader from "./components/ProfileHeader";

const AddressDetailsWrapper = ({
  handleAddAddress,
  handleToggleShow,
  handleShowEditModal,
  toggleShow,
  handleShowDeleteModal,
  addresses,
  loadingAddresses,
  handleMakeDefaultAddress,
  alert,
  selectedAddress,
}) => {
  return (
    <>
      {addresses?.length === 0 ? (
        <EmptyCard
          icon={<img src={MapIcon} className="mx-auto" alt="" />}
          title="No address added yet"
          description="This could serve as pick up location for your items"
          button={
            <button
              onClick={handleAddAddress}
              className="text-xs lg:text-sm mt-10 bg-temp-green-500 bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white font-bold px-8 py-4 focus:outline-none"
            >
              Add Address
            </button>
          }
        />
      ) : (
        <AddressDetails
          selectedAddress={selectedAddress}
          handleToggleShow={handleToggleShow}
          handleAddAddress={handleAddAddress}
          toggleShow={toggleShow}
          loadingAddresses={loadingAddresses}
          addresses={addresses}
          handleShowEditModal={handleShowEditModal}
          handleShowDeleteModal={handleShowDeleteModal}
          handleMakeDefaultAddress={handleMakeDefaultAddress}
        />
      )}

      {/* Trigger Message */}
      {alert.message && (
        <AlertModal
          show={alert.type ? true : false}
          type={alert.type}
          content={alert.message}
        />
      )}
    </>
  );
};

// Main Wrapper
const Address = (props) => {
  const [toggleShow, setToggleShow] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);

  const [loadingAddresses, setLoadingAddresses] = useState(false);

  const handleAddAddress = () => {
    setShowAddAddress(!showAddAddress);
  };

  const [selectedAddress, setSelectedAddress] = useState("");
  const handleToggleShow = (id) => {
    setSelectedAddress(id);
    setToggleShow(!toggleShow);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAddressData, setSelectedAddressData] = useState({});
  const handleShowEditModal = () => {
    setSelectedAddressData(
      props.addresses?.find((a) => a._id === selectedAddress)
    );
    setShowEditModal(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = (id) => {
    setSelectedAddress(id)
    setShowDeleteModal(true);
  };

  const handleDeleteAddress = async () => {
    setDeleteLoading(true);
    await props.deleteAddressApi(selectedAddress, props.history);
    setDeleteLoading(false);
  };

  useEffect(() => {
    setShowDeleteModal(false);
  }, [props.deleteAddressStatus.type]);

  const processCreateAddress = async (payload) => {
    await props.createAddressApi(payload);
  };

  useEffect(() => {
    if (props.createAddressStatus.type === "success") {
      setShowAddAddress(false);

      fecthAddresses();
    }
  }, [props.createAddressStatus.type]);

  useEffect(() => {
    fecthAddresses();
  }, []);

  const fecthAddresses = async () => {
    setLoadingAddresses(true);
    await props.getAddressesApi();
    setLoadingAddresses(false);
  };

  const [loadingMakeDefaultAddress, setLoadingMakeDefaultAddress] = useState(
    false
  );
  const handleMakeDefaultAddress = async (data) => {
    const payload = {
      ...data,
      isDefault: true,
    };
    setLoadingMakeDefaultAddress(true);

    await props.updateAddressApi(payload._id, payload);

    setLoadingMakeDefaultAddress(false);
  };

  const processUpdateAddress = async (payload, id) => {
    await props.updateAddressApi(id, payload);
  };

  useEffect(() => {
    setShowEditModal(false);
  }, [props.updateAddressStatus.type]);

  const [addresses, setAddresses] = useState([]);
  useEffect(() => {
    setAddresses(props.addresses);
  }, [props.addresses]);

  return (
    <MainContainer hasMainContainer={false}>
      <>
        <div className="w-full pb-36 relative">
          <ProfileHeader
            title="Address"
            currentPageName="Address"
          />
          <div className="container mx-auto px-4 w-full">

            <AddressDetailsWrapper
              loadingAddresses={loadingAddresses}
              handleAddAddress={handleAddAddress}
              toggleShow={toggleShow}
              handleToggleShow={handleToggleShow}
              alert={props.alert}
              addresses={addresses}
              handleShowEditModal={handleShowEditModal}
              handleShowDeleteModal={handleShowDeleteModal}
              handleMakeDefaultAddress={handleMakeDefaultAddress}
              selectedAddress={selectedAddress}
            />
          </div>
        </div>

        {/* Show Add Card Form */}
        {showAddAddress && (
          <AddAddressModal
            show={showAddAddress}
            handleCloseModal={() => setShowAddAddress(false)}
            processCreateAddress={processCreateAddress}
          />
        )}

        {/* Edit modal */}
        {showEditModal && (
          <EditAddressModal
            show={showEditModal}
            selectedAddressData={selectedAddressData}
            handleCloseModal={() => setShowEditModal(false)}
            processUpdateAddress={processUpdateAddress}
          />
        )}

        {/* Delete modal */}
        {showDeleteModal && (
          <DeleteModal
            title="Delete Address"
            description="Are you sure you want to delete this address?"
            show={showDeleteModal}
            deleteLoading={deleteLoading}
            handleDelete={handleDeleteAddress}
            handleClose={() => setShowDeleteModal(false)}
          />
        )}

        {loadingMakeDefaultAddress && (
          <LoadingModal show={loadingMakeDefaultAddress} />
        )}

        {props.createAddressStatus.type && (
          <AlertModal2
            show={props.createAddressStatus.type ? true : false}
            type={props.createAddressStatus.type}
            content={props.createAddressStatus.message}
          />
        )}

        {props.updateAddressStatus.type && (
          <AlertModal2
            show={props.updateAddressStatus.type ? true : false}
            type={props.updateAddressStatus.type}
            content={props.updateAddressStatus.message}
          />
        )}
      </>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    addresses: state.address.addresses,
    createAddressStatus: state.address.createAddressStatus,
    updateAddressStatus: state.address.updateAddressStatus,
    deleteAddressStatus: state.address.deleteAddressStatus,
    alert: state.alert,
  };
};

export default (connect(mapStateToProps, actions)(Address));
