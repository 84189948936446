import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import store from "store";
import Actions from "./actionTypes";
import { firebaseSignInUser } from "../../helpers/firebaseConn";

export const GoogleSSO = (payload, history) => async (dispatch) => {
  dispatch(alertActions.clear());
  const { network, accessToken, googleData } = payload;
  const { familyName, givenName, email } = googleData?.profileObj;
  const url = "auth/login/social";
  try {
    const response = await axios.post(url, { network, accessToken });
    const responseData = response.data.data;
    store.set("temp_access_token", responseData.token);
    store.set("temp_usER12_dATa", {
      ...responseData.user,
      firstname: familyName,
      lastname: givenName,
      email,
      address: responseData.address?.address,
      // verified_email: false, // currently false in responseData.user
    });

    const userData = {
      ...responseData.user,
      firstname: familyName,
      lastname: givenName,
      // verified_email: false,
    };
    if (userData?.firstname && !userData?.verified_phone) {
      history("/verify");
    } else if (userData?.verified_phone && !userData?.verified_id) {
      history("/complete-verification");
    } else if (userData?.verified_phone && !userData?.image) {
      history("/complete-verification");
    } else if (
      userData?.verified_phone &&
      userData?.verified_id &&
      userData?.image &&
      !userData?.verified_email
    ) {
      history("/verify-email");
    } else {
      history("/");
    }
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const loginApi = (payload, history) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/auth/login";
  try {
    const response = await axios.post(url, payload);
    const responseData = response.data.data;
    store.set("temp_access_token", responseData.token);
    store.set("temp_usER12_dATa", {
      ...responseData.user,
      address: responseData.address?.address,
    });

    // Redirect based on verification status
    const userData = responseData.user;

    if (userData?.firstname) {
      // Signin firebase user account for chatting
      await firebaseSignInUser(payload, userData);
    }

    if (userData?.firstname && !userData?.verified_phone) {
      history("/verify");
    } else if (userData?.verified_phone && !userData?.verified_id) {
      history("/complete-verification");
    } else if (userData?.verified_phone && !userData?.image) {
      history("/complete-verification");
    } else if (
      userData?.verified_phone &&
      userData?.verified_id &&
      userData?.image &&
      !userData?.verified_email
    ) {
      history("/verify-email");
    } else {
      history("/");
    }
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const signupApi = (payload, history, params) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/auth/register";
  try {
    const response = await axios.post(url, payload, {
      params,
    });

    const responseData = response?.data?.data?.user;

    // Create firebase user account for chatting
    if (responseData?.firstname) {
      // Signin firebase user account for chatting
      await firebaseSignInUser(payload, responseData);
    }

    store.set("temp_usER12_dATa", responseData);
    store.set("temp_access_token", response.data.data.token);

    history("/verify");
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const NINsignupApi = (payload, history) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/verify/user";
  try {
    const response = await axios.post(url, payload);

    const responseData = response?.data?.data;

    // Create firebase user account for chatting
    if (responseData?.firstname) {
      // Signin firebase user account for chatting
      await firebaseSignInUser(payload, responseData);
    }

    history({ pathname: "/verify", state: responseData?.user });
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const completeVerifyEmailApi =
  (payload, history) => async (dispatch) => {
    dispatch(alertActions.clear());

    const url = "/auth/verify/email";
    try {
      const response = await axios.post(url, payload);

      store.set("temp_usER12_dATa", {
        ...response.data.data.user,
        verified_email: true,
      });

      dispatch({
        type: Actions.USER,
        payload: response.data.data.user,
      });

      dispatch({
        type: Actions.VERIFY_EMAIL,
        payload: {
          type: "success",
          message: response.data.message,
        },
      });
      setTimeout(() => {
        dispatch({
          type: Actions.VERIFY_EMAIL,
          payload: {
            type: "",
            message: "",
          },
        });
      }, 3000);
      history("/");
    } catch (error) {
      dispatch({
        type: Actions.VERIFY_EMAIL,
        payload: {
          type: "error",
          message: error.response?.data.message || "Email Verification Failed.",
        },
      });
      setTimeout(() => {
        dispatch({
          type: Actions.VERIFY_EMAIL,
          payload: {
            type: "",
            message: "",
          },
        });
      }, 3000);
    }
  };

export const sendOTPVerifyEmailApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/auth/verify/email/send";
  try {
    const response = await axios.post(url, payload);
    dispatch({
      type: Actions.RESEND_EMAIL,
      payload: {
        type: "success",
        message: response.data?.message || "Email Verification Was Resent",
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.RESEND_EMAIL,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
    localStorage.setItem("sentEmail", "true");
    return {
      success: true,
    };
  } catch (error) {
    dispatch({
      type: Actions.RESEND_EMAIL,
      payload: {
        type: "error",
        message:
          error.response?.data.message || "Resend Email Verification Failed.",
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.RESEND_EMAIL,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  }
};

// Send OTP
export const verifyPhoneNumberApi = () => async (dispatch) => {
  dispatch(alertActions.clear());
  const url = "/auth/verify/phone";
  try {
    const token = store.get("temp_access_token");
    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });

    dispatch({
      type: Actions.RESEND_CODE,
      payload: {
        type: "success",
        message: response.data.message,
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.RESEND_CODE,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
    localStorage.setItem("sentOTP", "true");
  } catch (error) {
    dispatch({
      type: Actions.RESEND_CODE,
      payload: {
        type: "error",
        message: error.response?.data.message || "Unable to authenticate token",
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.RESEND_CODE,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  }
};
// Change phone number and send otp
export const changePhoneNumberAndSendOTPApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/auth/updatephone/sendotp";
  try {
    const token = store.get("temp_access_token");
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: token,
      },
    });
    dispatch({
      type: Actions.CHANGE_PHONE_NUMBER_AND_RESEND_CODE,
      payload: {
        type: "success",
        message: response.data.message,
      },
    });

    setTimeout(() => {
      dispatch({
        type: Actions.CHANGE_PHONE_NUMBER_AND_RESEND_CODE,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
    return response.data?.success;
  } catch (error) {
    dispatch({
      type: Actions.CHANGE_PHONE_NUMBER_AND_RESEND_CODE,
      payload: {
        type: "error",
        message: error.response?.data.message || "Unable to authenticate token",
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.CHANGE_PHONE_NUMBER_AND_RESEND_CODE,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
    return false;
  }
};

export const complteVerifyPhoneNumberApi =
  (payload, token, history) => async (dispatch) => {
    dispatch(alertActions.clear());

    const url = "/auth/verify/phone";
    try {
      await axios.post(url, payload, {
        headers: {
          Authorization: token,
        },
      });

      const storedUserData = store.get("temp_usER12_dATa");

      store.set("temp_usER12_dATa", {
        ...storedUserData,
        verified_phone: true,
      });

      dispatch({
        type: Actions.VERIFY_PHONE_NUMBER_SUCCESSFUL,
        payload: true,
      });

      history("/");
    } catch (error) {
      dispatch(alertActions.error(error.response?.data.message));
    }
  };

export const verifyUserIdentityCardApi = (url, payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  try {
    const response = await axios.post(url, payload);

    const storedUserData = store.get("temp_usER12_dATa");

    store.set("temp_usER12_dATa", {
      ...storedUserData,
      verified_id: true,
    });
    dispatch(alertActions.success(response.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const resendEmailApi = (payload, token) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/auth/verify/email/send";
  try {
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: token,
      },
    });

    dispatch({
      type: Actions.RESEND_EMAIL,
      payload: {
        type: "success",
        message: response.data.message,
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.RESEND_EMAIL,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: Actions.RESEND_EMAIL,
      payload: {
        type: "error",
        message: error.response.data.message,
      },
    });
    setTimeout(() => {
      dispatch({
        type: Actions.RESEND_EMAIL,
        payload: {
          type: "",
          message: "",
        },
      });
    }, 3000);
  }
};

export const resetPasswordRequestApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/auth/password/reset?phone=${payload}`;
  try {
    const response = await axios.get(url);
    dispatch(alertActions.success(response.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const completeResetPasswordRequestApi =
  (payload, phone) => async (dispatch) => {
    dispatch(alertActions.clear());

    const url = `/auth/password/reset?phone=${phone}`;
    try {
      const response = await axios.post(url, payload, {
        // headers: {
        //   Authorization: token,
        // },
      });
      dispatch(alertActions.success(response.data.message));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
    }
  };

export const updatePasswordApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/auth/password/update`;
  try {
    const response = await axios.put(url, payload);
    dispatch(alertActions.success(response.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const verifyPasswordApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/auth/verify/password`;
  try {
    const response = await axios.post(url, payload);
    dispatch({
      type: Actions.VERIFY_PASSWORD,
      payload: response.data.message,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};
