import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import EquipmentSEODescription from "../../components/blocks/itemBits/EquipmentSEODescription";
import ItemListing from "../../components/blocks/itemBits/ItemListing";
import ItemPreloader from "../../components/blocks/preloader/ItemPreloader";
import MainContainer from "../../components/layout/MainContainer";
import * as actions from "../../store/actions";
import { capitalizeFistLetterInSentence } from "../../utils/capitalizeFirstLetterInSentence";
import Paginator from "../../utils/pagination";
import { scrollToTop } from "../../utils/scrollToTop";
import { CategoryFilter } from "./components/CategoryFilter";
import CategoryHeader from "./components/CategoryHeader";
import CategorySelectorModal from "./components/CategorySelectorModal";
import EmptyCategoryList from "./components/EmptyCategoryList";

const Category = (props) => {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [currentCategoryName, setCurrentCategoryName] = useState("");
  const [itemLoading, setItemLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState({});

  const [loadingType, setLoadingType] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [pagination, setPagination] = useState({});

  const qs = queryString.parse(props.location.search);

  useEffect(() => {
    scrollToTop();

    const name = qs?.name;
    const type = qs?.type;
    setCurrentCategoryName(name);

    setSelectedCategory({
      value: name,
      label: capitalizeFistLetterInSentence(name),
    });

    setSelectedType(
      type
        ? {
            value: type || "",
            label: capitalizeFistLetterInSentence(type || ""),
          }
        : null
    );

    fetchItem({
      category: name,
      type,
    });
  }, [qs?.name, qs?.type]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await props.getCategoriesApi();
  };

  useEffect(() => {
    setCategories(props?.categories);

    fetchTypeData(
      props?.categories.find((a) => a?.name === currentCategoryName)?._id
    );
  }, [props?.categories]);

  const fetchItem = async (params) => {
    setItemLoading(true);
    await props.getItemsApi(params);

    setItemLoading(false);
  };

  useEffect(() => {
    setItems(props.items);
    setPagination(props.pagination);
  }, [props.items]);

  const fetchTypeData = async (id) => {
    setLoadingType(true);
    if (id) {
      await props.getTypesApi(id);
    }

    setLoadingType(false);
  };

  useEffect(() => {
    setTypes(props.types);
  }, [props.types]);

  const handleCategoryChange = async (data) => {
    setSelectedCategory(data);
    fetchTypeData(categories.find((a) => a?.name === data.value)?._id);

    navigate(`/category/?name=${data.value}`);
  };

  const handleTypeChange = async (data) => {
    setSelectedType(data);
    navigate(
      `/category/?name=${currentCategoryName}&type=${data.value}`
    );
  };

  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const handleViewCategoryModal = () => {
    setShowCategoryModal(!showCategoryModal);
  };

  return (
    <MainContainer hasMainContainer={false}>
      <div className="container px-4 mx-auto">
        <CategoryHeader
          currentCategoryImage={
            categories?.find((a) => a?.name === selectedCategory?.value)?.icon
          }
          currentCategoryName={currentCategoryName}
          handleViewCategoryModal={handleViewCategoryModal}
        />

        <CategoryFilter
          types={types}
          loadingType={loadingType}
          selectedType={selectedType}
          handleTypeChange={handleTypeChange}
        />

        {itemLoading ? (
          <div className="mt-16">
            <ItemPreloader />
          </div>
        ) : items?.length > 0 ? (
          <>
            <ItemListing items={items} />
            <Paginator
              pageLimit={pagination?.limit}
              maxPages={pagination?.totalPages}
              currentPage={pagination?.page}
              getPageApi={fetchItem}
              setLoading={setItemLoading}
            />
            {props.seoContent && (
              <div className="mt-36">
                <EquipmentSEODescription
                  title={capitalizeFistLetterInSentence(currentCategoryName)}
                  seoContent={props.seoContent}
                />
              </div>
            )}
          </>
        ) : (
          <EmptyCategoryList categories={categories} />
        )}

        {showCategoryModal && (
          <CategorySelectorModal
            show={showCategoryModal}
            handleCloseModal={() => setShowCategoryModal(false)}
            categories={categories}
            handleCategoryChange={handleCategoryChange}
          />
        )}
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  items: state.items.items,
  types: state.category.types,
  categories: state.category.categories,
  pagination: state.items.pagination,
  seoContent: state.items.seoContent,
});

export default (connect(mapStateToProps, actions)(Category));
