import React from 'react';
import GiftImage from "../../../assets/images/svg/gift.svg";

const ReferShareLinkSection = ({
    loading,
    referralDetails,
    handleClickCopy,
    copiedLink
}) => {
    return <div className="bg-white refer-stat-card py-6 lg:py-8 pl-6 lg:pl-8 pr-6 lg:pr-8 mt-8 rounded-lg flex flex-wrap lg:space-x-6">
        <img src={GiftImage} className="flex-shrink" alt="" />

        <div className="w-full lg:w-10/12 mt-4 lg:mt-0 lg:pl-4">
            <p className="text-temp-green-500 text-sm md:text-sm-15">
            Earn more by referring your friends and family to use TempOwn for 
            their rental needs.
                <strong>Share your referral link today.</strong>
            </p>

            <div className="mt-4 flex flex-wrap lg:space-x-4">
                {loading ? (
                    <div className="bg-temp-green-200 h-5 w-8/12"></div>
                ) : (
                    <>
                        <p
                            id="referralLink"
                            className="overflow-auto text-xs py-2 px-4 referral-link-box rounded-lg mb-2"
                        >
                            https://www.tempown.com/signup?referralCode=
                            {referralDetails?.code}
                        </p>
                        <button
                            onClick={() =>
                                handleClickCopy(
                                    `https://www.tempown.com/signup?referralCode=${referralDetails?.code}`
                                )
                            }
                            className={`inline-flex py-2 px-3 items-center text-center text-sm bg-temp-green-500 bg-gradient rounded-lg text-white mb-2 focus:outline-none ${copiedLink && "opacity-30"
                                }`}
                        >
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0)">
                                    <path
                                        d="M9.99998 2.5H1.5C0.671572 2.5 0 3.17157 0 4V12.5C0 13.3284 0.671572 14 1.5 14H9.99998C10.8284 14 11.5 13.3284 11.5 12.5V4C11.5 3.17157 10.8284 2.5 9.99998 2.5ZM10.5 12.5C10.5 12.7762 10.2761 13 9.99998 13H1.5C1.22385 13 0.99999 12.7762 0.99999 12.5V4C0.99999 3.72385 1.22385 3.49999 1.5 3.49999H9.99998C10.2761 3.49999 10.5 3.72385 10.5 4V12.5Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M12.5 0H3.5C2.67157 0 2 0.671572 2 1.5C2 1.77615 2.22386 2.00001 2.50001 2.00001C2.77616 2.00001 2.99999 1.77615 2.99999 1.5C2.99999 1.22385 3.22385 0.99999 3.5 0.99999H12.5C12.7761 0.99999 13 1.22385 13 1.5V10.5C13 10.7761 12.7761 11 12.5 11C12.2238 11 12 11.2239 12 11.5C12 11.7762 12.2238 12 12.5 12C13.3284 12 14 11.3284 14 10.5V1.5C14 0.671572 13.3284 0 12.5 0Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <span className="pl-3">
                                {copiedLink ? "Copied" : "Copy"}
                            </span>
                        </button>
                    </>
                )}
            </div>
        </div>
    </div>
};

export default ReferShareLinkSection;
