export const rentalUnits = [
    {
        id: 1,
        name: "By Calender",
        value: "byCalender",
        description: "You will be required to provide the price renting your equipment in either Days, Weeks, Months or Years"
    },
    {
        id: 2,
        name: "By SI Units",
        value: "bySiUnits",
        description: "You will be required to provide the price renting your equipment in either Hours, Kilograms, Hectares, Miles."
    },
]