import React, { useEffect, useState } from "react";
import MainContainer from "../../../components/layout/MainContainer";
import { ArrowLeftIcon } from "../../../components/svgs";

import DealIcon from "../../../assets/images/svg/deal.svg";
import PresentIcon from "../../../assets/images/svg/present.svg";
import TreeIcon from "../../../assets/images/svg/trees.svg";

import FarmImage from "../../../assets/images/png/bigframe.jpg";
import TreeImage from "../../../assets/images/png/cooltree.png";

import { Link } from "react-router-dom";
import LeaderboardModal from "./components/LeaderboardModal";
import RedeemModal from "./components/RedeemModal";

import { connect } from "react-redux";
import { } from "react-router";
import * as actions from "../../../store/actions";

const HowItWorksCard = ({ icon, number, title }) => {
  return (
    <div className="px-6 py-5 bg-temp-green-200 rounded-lg flex flex-wrap flex-row justify-between items-center">
      <div className="w-4/12 md:w-4/12 mb-3">
        <img src={icon} width="80rem" alt="" />
      </div>
      <div className="w-7/12  md:w-8/12">
        <p
          className="font-bold text-base lg:text-xl pb-1"
          style={{ color: "#D5B450" }}
        >
          {number}
        </p>
        <p className="text-temp-secondary text-base lg:text-xl">{title}</p>
      </div>
    </div>
  );
};

const Emission = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);

  useEffect(async () => {
    await props.getEmissionsApi();
    await props.getEmissionsLBApi();
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseRedeemModal = () => {
    setShowRedeemModal(false);
  };

  return (
    <MainContainer>
      <div className="w-full pt-10 lg:pt-16 pb-36 relative">
        <Link to="/profile" className="inline-flex items-center">
          <ArrowLeftIcon />{" "}
          <span className="pl-3 pt-1 text-temp-primary text-sm lg:text-base">
            Back to Profile
          </span>
        </Link>

        <div className="hero-emission mt-8 pt-12 lg:pt-20 pb-24 lg:pb-32 px-4 lg:px-20 text-center">
          <div className="pb-8 md:pb-14">
            <h2 className="text-white font-serif text-2xl md:text-3xl lg:text-4xl xl:text-5xl mb-5">
              Your Emission Saving
            </h2>
            <p className="text-temp-green-light text-base md:text-lg mb-4">
              Rent More. Save more.
            </p>
          </div>

          <div className="pb-12">
            <h3 className="text-white font-serif text-2xl md:text-3xl xl:text-4xl mb-5">
              Level 1
            </h3>
            <p
              className="text-sm md:text-lg lg:text-xl font-bold"
              style={{ color: "#F4F08B" }}
            >
              Save 120,000 KgCO2 to complete this level
            </p>
          </div>

          <div className="pb-8">
            <h3 className="text-3xl lg:text-4xl xl:text-6xl text-temp-green-light pb-6">
              {props?.emissions?.emissionSaved === undefined
                ? "0 %"
                : `${Math.floor(
                    (props?.emissions?.emissionSaved * 100) /
                      props?.emissions?.required
                  )} %`}
            </h3>

            <p className="text-lg font-bold pb-6" style={{ color: "#CDF5A0" }}>
              {props?.emissions?.emissionSaved === undefined
                ? "0 KgCO2 saved"
                : `${props?.emissions?.emissionSaved} KgCO2 saved`}
            </p>
          </div>

          <div className="pb-14 md:pb-20">
            <button
              onClick={handleShowModal}
              disabled={props?.emissions?.emissionSaved === undefined}
              className="text-white font-bold text-base md:text-lg lg:text-xl xl:text-2xl focus:outline-none"
            >
              View Leaderboard 🏆
            </button>
          </div>
        </div>

        <div className="mt-8 pt-14 lg:pt-20 pb-14">
          <h3 className="text-temp-primary text-2xl lg:text-3xl font-serif">
            How it works
          </h3>

          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <HowItWorksCard icon={DealIcon} number="01" title="Rent an item" />
            <HowItWorksCard icon={TreeIcon} number="02" title="Earn KgCO2" />
            <HowItWorksCard
              icon={PresentIcon}
              number="03"
              title="Redeem price"
            />
          </div>
        </div>

        <div className="lg:mt-8 pt-5 pb-20">
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-20 lg:gap-28">
            <div className="relative bg-black hidden md:block">
              <img
                src={FarmImage}
                width="100%"
                className="object-cover"
                alt=""
              />
            </div>
            <div className="">
              <h3
                className="text-2xl lg:text-3xl font-serif pb-6 md:pb-10"
                style={{ color: "#738611" }}
              >
                Why we are doing this
              </h3>
              <p className="text-temp-gray leading-6 lg:leading-7 text-sm lg:text-base">
                In the last century alone, the earth&apos;s average temperature has
                increased by about 2° Fahrenheit. Islands and coastal cities are
                at risk of being destroyed due to rising sea levels, the
                degradation of the ozone layer is causing extreme heat and poor
                health conditions, droughts have drastically reduced yield in
                agriculture.
                <br />
                <br />
                Every time you opt for rental, you save the earth the sum of
                emission released when producing a piece of new equipment you
                could have bought. For this, we have designed a reward plan for
                you. When you rent an equipment on the TempOwn platform, we
                generate your emission saving score on that equipment. This is
                the amount of Green House Gas (GHG) emission saved because you
                opted to rent rather than buying the equipment. Whenever you hit
                a milestone, you qualify to redeem a prize.
              </p>

              <div
                className="mt-8 md:mt-16 px-4 lg:px-8 py-4 lg:py-8 rounded-xl flex flex-wrap flex-row"
                style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)" }}
              >
                <div className="mb-3 w-3/12">
                  <img src={TreeImage} alt="" />
                </div>
                <div className="text-temp-green-500 w-9/12 px-4 md:px-6">
                  <p className="leading-6 lg:leading-7 text-sm lg:text-base pb-5">
                    A research was conducted to Predict GHG Emissions Saving
                    From Machinery and Equipment Rental Operations. Want to know
                    more?
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.notion.so/Predicting-the-Greenhouse-Gas-GHG-Emission-Savings-From-Machinery-and-Equipment-Rental-Operations--3430a868fe594da7846d3c5e26a9590c"
                    className="font-bold text-sm lg:text-base"
                  >
                    Read the White Paper here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <LeaderboardModal
          show={showModal}
          handleCloseModal={handleCloseModal}
          data={props?.emissionsLB.docs}
        />
      )}

      {showRedeemModal && (
        <RedeemModal
          show={showRedeemModal}
          handleCloseModal={handleCloseRedeemModal}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  emissions: state.emissions.emissions,
  emissionsLB: state.emissions.emissionsLB,
});

export default (connect(mapStateToProps, actions)(Emission));
