const convertSeconds = (seconds) => {
  var measuredTime = new Date(null);
  measuredTime.setSeconds(seconds); // specify value of SECONDS
  var MHSTime = new Date(measuredTime.toISOString());

  return MHSTime;
};

export const convertSecondsToAMPM = (seconds) => {
  if (seconds) {
    const MHSTime = convertSeconds(seconds);

    var hours = MHSTime.getHours();
    var minutes = MHSTime.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
};

export const convertSecondsToDateOnly = (seconds) => {
  if (seconds) {
    const MHSTime = convertSeconds(seconds);
    return new Date(MHSTime).getUTCDate();
  }
};

export const getFormatedDate = (seconds) => {
  if (seconds) {
    const MHSTime = convertSeconds(seconds);

    const option = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const dateFormat = new Intl.DateTimeFormat("en-US", option);
    return dateFormat.format(MHSTime);
  }
};
