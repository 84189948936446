import React from "react";
import DatePicker, { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";

const Tag = ({ title, bgColor }) => {
  return (
    <div className="inline-flex items-center mr-8">
      <span
        className={`rounded-full border border-temp-gray w-3 h-3 mr-3 ${bgColor}`}
      ></span>
      <span className="pt-1">{title}</span>
    </div>
  );
};

const MultipleDatePicker = ({
  label,
  labelColor,
  placeholder,
  name,
  value,
  error,
  onChange,
  tip = false,
  bottomTip = false,
  showTag = true,
  minDate,
  todayColor,
  datePickerType = "picker", // [picker, calendar]
  ...props
}) => {
  return (
    <div className="mb-8">
      <label
        htmlFor={name}
        className={`text-sm lg:text-base ${labelColor || "text-temp-primary"}`}
      >
        {label}{" "}
        {tip && (
          <div className="bg-temp-green-100 px-6 py-6 rounded-lg text-sm my-5 mb-10">
            Your equipment is always available. Select the days you don&apos;t
            want it available. You can always make changes after you publish.
          </div>
        )}
      </label>
      <div className="relative input input-field w-full mb-2 mt-4">
        {datePickerType === "picker" ? (
          <DatePicker
            className="rmdp-mobile"
            name={name}
            value={value}
            sort
            minDate={minDate || new Date()}
            format="ddd, MMM DD, YYYY"
            onChange={onChange}
            placeholder={placeholder}
            inputClass={`text-temp-gray block px-5 h-14 w-full rounded-lg border bg-white placeholder-temp-gray focus:outline-none text-xs md:text-sm ${
              error ? "border-red-600" : " border-temp-gray-light"
            }`}
            containerClassName="w-full"
            // plugins={[<DatePanel />]}
            mapDays={({ date, isSameDate, today }) => {
              let props = {};
              props.style = {
                // border: "1px solid #ccc",
                borderRadius: "100%",
              };
              if (isSameDate(date, today)) props.style.backgroundColor = "#fff";

              return props;
            }}
            {...props}
          />
        ) : (
          <>
            <Calendar
              name={name}
              value={value}
              sort
              minDate={minDate || new Date()}
              format="DD/MM/YYYY"
              onChange={onChange}
              placeholder={placeholder}
              className="w-full"
              shadow={false}
              mapDays={({ date, isSameDate, today }) => {
                let props = {};

                props.style = {
                  // border: "1px solid #ccc",
                  borderRadius: "100%",
                };
                if (isSameDate(date, today)) {
                  props.style = {
                    ...props.style,
                    backgroundColor: "#fff",
                    color: todayColor || "#000",
                  };
                }

                return props;
              }}
              {...props}
            />

            {bottomTip && (
              <p className="text-xs text-temp-primary pt-5">
                Click on date you want to block out then click on update to save
              </p>
            )}

            {showTag && (
              <div className="text-sm text-temp-gray flex flex-row flex-wrap mt-4">
                <Tag title="Available" />
                <Tag title="Blocked" bgColor="bg-gray-300" />
              </div>
            )}
          </>
        )}
      </div>

      <span className="text-red-600 text-xs md:text-sm">{error}</span>
    </div>
  );
};

export default MultipleDatePicker;
