import React from 'react';

const NextButton = ({ onClick, disabled, title, type, ...props }) => {
    return <button
        type={type || "button"}
        disabled={disabled}
        onClick={onClick}
        className={`font-semibold right-10 text-md h-12 sm:h-14 pt-0.5 px-8 sm:px-14 text-white rounded-xl focus:outline-none ${disabled
            ? "bg-temp-gray opacity-40"
            : "bg-gradient"
            }
        `}
        {...props}
    >
        {title}
    </button>
};

export default NextButton;
