import React from 'react';
import UserActivityImage from "../../../assets/images/svg/useractivity.svg";

const EmptyLateRental = ({handleGoBack}) => {
    return <div className="my-16 pb-64 relative w-full flex justify-center">
        <div className="mx-auto text-center">
            <img src={UserActivityImage} alt="" className="mx-auto" />

            <p className="py-8 text-temp-primary text-sm lg:text-base">
                <strong>Late Returns Cleared!</strong> <br />You don&apos;t have any late rentals. Click the button below to continue.
            </p>

            <button onClick={handleGoBack} className="bg-temp-green-500 bg-gradient text-white text-sm-15 rounded-xl focus:outline-none ring-0 focus:ring-0 py-4 px-6 block w-full">
                &lt; Go Back
            </button>
        </div>
    </div>
};

export default EmptyLateRental;
