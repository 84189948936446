import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TestimonialImg1 from "../../../assets/images/png/user/testimonial/darlinton.jpg";
import TestimonialImg2 from "../../../assets/images/png/user/testimonial/jacob.jpg";
import { StarIcon } from "../../../components/svgs";

const TestimonialSection = () => {
  const data = [
    {
      id: 1,
      image: TestimonialImg2,
      type: "Lender",
      name: "Darlington",
      message: `Every day, innovations emerge, especially online.
      It's always exciting to see an innovation that really meets a need.
      We can't wait to use this one as a Relocation and 
      Cleaning Services Group.`,
    },
    {
      id: 2,
      image: TestimonialImg1,
      type: "Lender",
      name: "Jacob-Eyaba",
      message: `As a Security Consultant and Project Manager, 
      having the right equipment to complete a project on 
      time is always a challenge. With TempOwn, I can now get 
      all of the equipment I need with the flick of a finger.`,
    },
  ];

  const Testimony = ({ image, name, message }) => {
    return (
      <div className="my-7 ml-4 max-w-xl md:max-w-xs lg:max-w-xl h-full item testimonial-slider-item relative px-4 lg:px-6 py-4 lg:py-6 rounded-xl">
        <div className="flex flex-wrap lg:h-56">
          <div className="w-1/12 md:w-2/12 lg:w-4/12 h-full object-cover rounded-xl">
            <img
              src={image}
              className="w-full h-10 md:h-24 lg:h-full rounded-xl object-cover"
              alt=""
            />
          </div>
          <div className="w-11/12 md:w-10/12 lg:w-8/12 pl-4 md:pl-6 lg:pl-10 pr-4 lg:pr-6">
            <div className="flex flex-col flex-wrap justify-between">
              <p className="mb-4 text-temp-primary text-sm-15 font-semibold flex items-baseline">
                <StarIcon fill="#08A05C" /> <span className="pl-1">5.0</span>
              </p>
              <p className="text-temp-primary text-xs lg:text-sm leading-5">
                {message}
              </p>

              <div>
                <p className="font-medium text-temp-primary pt-4 lg:pt-6 pb-6 lg:pb-0 text-xs md:text-sm md:text-sm-15">
                  {name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-3 lg:mb-2">
      <div className="relative pt-14 pb-20">
        <div className="w-full md:w-6/12 lg:w-4/12 xl:w-5/12">
          <h3 className="text-3xl leading-9 md:text-3xl lg:text-4xl xl:text-5xl font-semibold text-temp-primary  pb-4 lg:pb-8">
            See how{" "}
            <span className="text-temp-secondary">
              temp<span className="font-bold">own</span>
            </span>{" "}
            is making life better
          </h3>
        </div>

        <div className="mt-4 md:mt-8 lg:mt-12">
          <OwlCarousel
            className="owl-carousel owl-theme testimonial-slider"
            items={3}
            margin={8}
            loop={true}
            // autoWidth={true}
            nav={false}
            dots={true}
            autoplay={false}
            responsiveClass={true}
            responsive={{
              0: {
                items: 1,
                nav: false,
              },
              600: {
                items: 2,
                nav: false,
              },
              1000: {
                items: 2,
                nav: false,
                loop: false,
              },
            }}
          >
            {data.map((item) => (
              <React.Fragment key={item.id}>
                <Testimony
                  image={item.image}
                  type={item.type}
                  name={item.name}
                  message={item.message}
                />
              </React.Fragment>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
