import React from "react";
import Avatar from "../../../assets/images/svg/user.svg";
import { convertSecondsToAMPM } from "../../../utils/timeFormatAMPM";

const User = ({ user, initializeChatWithUser, lastMessage }) => {
  return (
    <button
      onClick={() => initializeChatWithUser(user)}
      className="w-full flex flex-wrap flex-row focus:outline-none items-center"
    >
      <div className="w-2/12 relative">
        <img
          src={user?.profilePicture || Avatar}
          className=" h-10 w-10 object-cover rounded-full"
          alt=""
        />
        <div
          className={`w-3 h-3 rounded-full absolute left-7 bottom-0 ${
            user?.isOnline ? "bg-temp-green-500" : "bg-temp-gray"
          }`}
        />
      </div>
      <div className="w-10/12 text-left">
        <div className="flex flex-wrap justify-between">
          <p
            className={`${
              !lastMessage?.isView && "font-bold"
            } text-sm-15 lg:text-base text-temp-secondary`}
          >
            {user?.firstName}
          </p>
          <p className="font-light text-xs pt-2 text-temp-green-500">
            {convertSecondsToAMPM(lastMessage?.createdAt?.seconds) || ""}
          </p>
        </div>

        <p
          className={`w-10/12 truncate text-default text-xs lg:text-sm ${
            !lastMessage?.isView && "font-semibold"
          }`}
        >
          {(lastMessage?.message?.length > 40
            ? lastMessage?.message?.slice(0, 40) + "..."
            : lastMessage?.message) || ""}
        </p>
      </div>
    </button>
  );
};

const ChatUsers = ({
  users,
  conversationList,
  initializeChatWithUser,
}) => {
  return (
    <div className="pt-6 pb-20 space-y-8 px-6 overflow-auto">
      {users?.length > 0 ? (
        users.map((user, index) => {
          return (
            <User
              key={user?.userId}
              user={user}
              lastMessage={
                conversationList[index]?.["content"][
                  conversationList[index]?.["content"]?.length - 1
                ]
              }
              initializeChatWithUser={initializeChatWithUser}
            />
          );
        })
      ) : (
        <p className="text-center pt-5 text-temp-primary">No User Found</p>
      )}
    </div>
  );
};

export default ChatUsers;
