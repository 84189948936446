import React from "react";
import { Link } from "react-router-dom";
import BorrowerInformationWithViewButton from "../../../components/blocks/itemBits/BorrowerInformationWithViewButton";

const OverdueOrderCard = ({ loggedInUserDetails, orderDetails }) => {
    return (
        <div
            className="rounded-xl py-8 lg:py-8 mx-auto"
            style={{
                border: "0.5px solid #B0B0B0",
            }}
        >
            <div className="text-temp-secondary font-semibold px-6 lg:px-8 mb-4 lg:mb-6 text-base lg:text-lg">
                Order Activities
            </div>
            <div className="flex flex-wrap items-baseline pr-8">
                <p className="px-6 lg:px-8 text-sm lg:text-sm-15 pb-4 text-temp-gray">
                    Status
                </p>
                <p className="px-4 lg:px-6 font-medium text-xs lg:text-sm overdue-status rounded-xl pb-3 pt-3">
                    Overdue
                </p>
            </div>

            <div className="px-6 lg:px-8 mt-4 lg:mt-6 pb-6">
                {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
                    <>
                        <Link
                            to="/late-rental"
                            className="py-4 w-full block text-center px-4 mt-5 font-bold text-xs lg:text-sm bg-gradient text-white rounded-lg focus:outline-none"
                        >
                            Pay Late Rental Fee
                        </Link>
                        <p className="text-xs lg:text-sm pt-4 text-center">
                            This order is overdue which means it can&apos;t be further extended.
                        </p>
                    </>
                ) : (
                    <>
                        <p className="text-xs lg:text-sm pt-4 text-center">
                            This borrower is late on extending or returning your item.
                            We are monitoring that and assure the safety of your item.
                            All accumalated cost will be paid by the borrower to your wallet.
                        </p>

                        <div className="pt-4">
                            <BorrowerInformationWithViewButton
                                className="px-0"
                                title="Borrower"
                                user={orderDetails?.borrower}
                                pickupContact={orderDetails?.lender?.phone}
                            />

                        </div>
                    </>
                )}


            </div>
        </div>
    );
};

export default OverdueOrderCard;
