import React, { useState } from "react";
import ReviewCard from "../../../components/blocks/ReviewCard";
import StarRating from "../../../components/blocks/StarRating";
import { getTotalStarRatingValue } from "../../../utils/getTotalStarRatingValue";

const ReviewsList = ({ item }) => {
  const [isShowAll, setIsShowAll] = useState(false);

  const handleShowAll = () => {
    setIsShowAll(!isShowAll);
  };
  return (
    <div
      id="review"
      className="order-2 lg:order-3 w-full  relative mt-16 lg:mt-0">
      <h3 className="text-base lg:text-lg xl:text-xl font-semibold text-temp-secondary pb-4 lg:pb-6">
        Reviews
      </h3>

      <div className="mb-12 py-3 lg:py-4 rounded-lg lg:rounded-xl bg-temp-green-100 px-4 flex flex-wrap justify-between items-center">
        <div className="inline-flex flex-wrap items-start">
          <p className="text-lg lg:text-xl xl:text-2xl text-temp-green-500 font-semibold pr-2">
            {item?.reviews?.length > 0
              ? getTotalStarRatingValue(item?.reviews).toFixed(2)
              : 0}
          </p>
          <div className="lg:pt-1">
            {item && item?.reviews && (
              <StarRating
                rating={
                  item?.reviews?.length > 0
                    ? getTotalStarRatingValue(item?.reviews)
                    : 0
                }
              />
            )}
          </div>
        </div>

        <div className="inline-flex flex-wrap items-center">
          <p className="pr-4 text-sm lg:text-sm-15">
            {item?.reviews?.length} reviews
          </p>
          <div className="relative inline-flex pt-1 lg:pt-0">
            {item?.reviews?.slice(0, 2)?.map((a, index) => (
              <div
                key={index}
                className={`w-7 h-7 lg:w-9 lg:h-9 object-cover border border-white rounded-full ${
                  index === 1 && "-ml-2"
                }`}>
                <img
                  src={a?.userId?.image}
                  className="w-7 h-7 lg:w-9 lg:h-9 object-cover rounded-full"
                  alt=""
                />
              </div>
            ))}

            {item?.reviews?.length > 2 && (
              <div
                className="w-7 h-7 lg:w-9 lg:h-9 object-cover border border-white rounded-full -ml-2 bg-temp-green-500 text-white text-xs pt-1 lg:pt-2 text-center"
                style={{ fontSize: ".5rem" }}>
                +{item?.reviews?.slice(2)?.length}
              </div>
            )}
          </div>
        </div>
      </div>

      {
        item?.reviews?.length > 0 && (
          <>
            <div className="space-y-12 lg:space-y-16 mb-8">
              {item?.reviews?.slice(0, 10)?.map((a) => (
                <ReviewCard key={a?._id} data={a} />
              ))}
              {isShowAll &&
                item?.reviews
                  ?.slice(10)
                  ?.map((a) => <ReviewCard key={a?._id} data={a} />)}
            </div>
            {item?.reviews?.length > 10 && (
              <button
                onClick={handleShowAll}
                className="rounded-xl text-temp-secondary px-6 py-4 focus:outline-none text-sm lg:text-base"
                style={{
                  background: "#F9F9F9",
                }}>
                {isShowAll ? "Minimize reviews" : "View all reviews"}
              </button>
            )}
          </>
        )
        //   :
        //    (
        //     <div className="mb-8 bg-temp-green-100 border border-temp-green-light py-10 px-4 text-center rounded-lg text-sm lg:text-base">
        //       No Reviews Yet.
        //     </div>
        //   )
      }
    </div>
  );
};

export default ReviewsList;
