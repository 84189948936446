import React from "react";

export const MoneyIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9607 15.585C27.9606 15.5849 27.9606 15.5848 27.9606 15.5847L22.6741 2.31071C22.5619 2.02867 22.2422 1.89098 21.9601 2.00318C21.9599 2.00325 21.9597 2.00332 21.9595 2.00346L0.512821 10.5433C0.425358 10.5811 0.349507 10.6414 0.292962 10.7181C0.114601 10.8108 0.00192377 10.9942 0 11.1952V25.486C0 25.7896 0.246105 26.0357 0.549647 26.0357H23.6348C23.9384 26.0357 24.1845 25.7896 24.1845 25.486V17.681L27.6539 16.2992C27.9358 16.1868 28.0732 15.867 27.9607 15.585ZM23.0852 24.9364H1.09929V11.7449H23.0852V24.9364ZM9.17087 10.6483L18.3742 6.98106C19.1955 7.84442 20.3337 8.33512 21.5253 8.33979L22.4432 10.6483H9.17087ZM24.1845 16.5004V11.1952C24.1845 10.8917 23.9384 10.6456 23.6348 10.6456H23.626L22.4613 7.72034C22.4484 7.69595 22.4334 7.67273 22.4163 7.65108C22.3573 7.38636 22.1122 7.20573 21.8419 7.22785C20.7331 7.32892 19.6485 6.86186 18.9601 5.98675C18.7888 5.77534 18.4877 5.72278 18.2549 5.86363C18.2288 5.86672 18.2029 5.87153 18.1774 5.87792L6.9415 10.3521C6.80553 10.4054 6.69677 10.5111 6.63974 10.6456H3.22753L21.8562 3.22807L26.7365 15.4825L24.1845 16.5004Z"
        fill="#08A05C"
      />
      <path
        d="M2.59069 21.2258C3.65783 21.5412 4.49288 22.3753 4.80962 23.442C4.87908 23.6748 5.09323 23.8344 5.33618 23.8344C5.36469 23.8326 5.393 23.8284 5.42082 23.8218C5.44549 23.8288 5.47071 23.8339 5.49613 23.8372H18.6877C18.7103 23.8342 18.7327 23.8296 18.7547 23.8234C19.0293 23.8902 19.3061 23.7218 19.3729 23.4472C19.3731 23.4462 19.3734 23.4452 19.3736 23.4442C19.6895 22.3764 20.5247 21.5412 21.5926 21.2253C21.8515 21.1461 22.0124 20.888 21.9696 20.6206C21.977 20.594 21.9823 20.5668 21.9855 20.5393V16.1421C21.9823 16.1146 21.9768 16.0874 21.9691 16.0608C22.0121 15.7933 21.8512 15.5351 21.592 15.4562C20.5242 15.1403 19.6892 14.3048 19.3742 13.2367C19.2946 12.9759 19.034 12.8146 18.7652 12.8596C18.7397 12.8526 18.7138 12.8474 18.6877 12.8442H5.49613C5.46844 12.8475 5.44102 12.853 5.41423 12.8607C5.14689 12.8181 4.88897 12.979 4.80962 13.2378C4.4935 14.3054 3.65838 15.1403 2.59069 15.4562C2.33174 15.5353 2.17083 15.7934 2.21363 16.0608C2.20649 16.0875 2.20133 16.1146 2.19824 16.1421V20.5393C2.2014 20.5651 2.20656 20.5907 2.21363 20.6157C2.16856 20.8848 2.32981 21.1458 2.59069 21.2258ZM3.29754 16.3653C4.40123 15.922 5.27599 15.0472 5.71928 13.9435H18.464C18.9075 15.0472 19.7824 15.922 20.8863 16.3653V20.3161C19.7829 20.7599 18.9083 21.6345 18.4645 22.7379H5.71928C5.27551 21.6345 4.40089 20.7599 3.29754 20.3161V16.3653Z"
        fill="#08A05C"
      />
      <path
        d="M12.0909 21.6385C13.9122 21.6385 15.3887 20.162 15.3887 18.3406C15.3887 16.5192 13.9122 15.0427 12.0909 15.0427C10.2695 15.0427 8.79297 16.5192 8.79297 18.3406C8.79476 20.1612 10.2702 21.6367 12.0909 21.6385ZM12.0909 16.142C13.3051 16.142 14.2894 17.1264 14.2894 18.3406C14.2894 19.5548 13.3051 20.5392 12.0909 20.5392C10.8766 20.5392 9.89226 19.5548 9.89226 18.3406C9.89226 17.1264 10.8766 16.142 12.0909 16.142Z"
        fill="#08A05C"
      />
      <path
        d="M6.04615 19.1651C6.50147 19.1651 6.87062 18.7959 6.87062 18.3406C6.87062 17.8853 6.50147 17.5161 6.04615 17.5161C5.59084 17.5161 5.22168 17.8853 5.22168 18.3406C5.22168 18.796 5.59084 19.1651 6.04615 19.1651ZM6.04615 18.0658C6.19792 18.0658 6.32097 18.1888 6.32097 18.3406C6.32097 18.4924 6.19792 18.6154 6.04615 18.6154C5.89438 18.6154 5.77133 18.4924 5.77133 18.3406C5.77133 18.1888 5.89438 18.0658 6.04615 18.0658Z"
        fill="#08A05C"
      />
      <path
        d="M18.137 19.1651C18.5923 19.1651 18.9614 18.7959 18.9614 18.3406C18.9614 17.8853 18.5923 17.5161 18.137 17.5161C17.6817 17.5161 17.3125 17.8853 17.3125 18.3406C17.3125 18.796 17.6817 19.1651 18.137 19.1651ZM18.137 18.0658C18.2887 18.0658 18.4118 18.1888 18.4118 18.3406C18.4118 18.4924 18.2887 18.6154 18.137 18.6154C17.9852 18.6154 17.8621 18.4924 17.8621 18.3406C17.8621 18.1888 17.9852 18.0658 18.137 18.0658Z"
        fill="#08A05C"
      />
    </svg>
  );
};
