import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
const LocationCard = ({ id, url, image, name, itemCount }) => {
    return <div className="item relative mb-4 md:mb-8">
        <Link to={url || "/"}>
            <div className="card relative flex flex-col min-w-0 h-full w-full box-border rounded-xl bg-temp-green-100">
                <div className="card-header rounded-tl-xl rounded-tr-xl">
                    <img
                        src={image}
                        className="w-full object-cover rounded-tl-xl rounded-tr-xl h-32 md:h-52 "
                        alt=""
                    />
                </div>
                <div className={`card-body bg-gradient rounded-b-xl text-white text-sm md:text-base  flex-1 p-3 py-4  md:p-5 md:pt-6 md:pb-8 card-body-${id}`}>
                    <p className="text-sm-15 md:text-base lg:text-lg xl:text-xl font-medium leading-7 one-liner">
                        {name}
                    </p>
                    <p className='text-xs md:text-sm md:mt-1'>
                      {/* {itemCount} items available */}
                      View items available
                    </p>
                </div>
            </div>
        </Link>
    </div>
};

export default LocationCard;
