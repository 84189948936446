/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import store from "store";
import CheckBoxGroup from "../../../../components/element/CheckBoxGroup";
import AlertModal from "../../../../components/modals/AlertModal";
import * as actions from "../../../../store/actions";

const Attestation = (props) => {
  const userData = store.get("temp_usER12_dATa");
  const initialState = {
    isAgreed: false,
  };
  const validate = (values) => {
    const errors = {};

    if (!values.isAgreed) {
      errors.isAgreed = "Required";
    }

    return errors;
  };

  return (
    <div className="w-full lg:w-9/12 mt-3">
      <div className="">
        <Formik
          initialValues={initialState}
          validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            await props.lenderAttestationApi(props.history);

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="pt-6 pb-6 text-left md:pt-6 md:pb-4 lg:pt-6 lg:pb-6"
            >
              <div className="flex items-center mb-4">
                <div
                  className="h-96 overflow-auto flex-shrink leading-6 text-sm bg-temp-green-200 rounded-xl border border-temp-green-500 p-6"
                  style={{ color: "#5E605F" }}
                >
                  <p>
                    TempOwn is committed to providing a safe renting
                    marketplace. Because of this, we are asking for your
                    commitment by attesting and pledging to comply with the
                    following:
                  </p>
                  <br />
                  <ol className="list-inside list-decimal space-y-3">
                    <li>
                      I agree that I have read TempOwn’s Terms of Use, Privacy
                      Policy, Rental Policy and any other accompanying documents
                      (which together constitute “Terms and Conditions”) and
                      agree to be bound by the same.
                    </li>
                    <li>
                      I agree to conduct myself in accordance with TempOwn’s
                      prescribed code of conduct.
                    </li>
                    <li>
                      I agree to provide true, current, complete and accurate
                      information to TempOwn, and not to misrepresent my
                      identity.
                    </li>
                    <li>
                      I agree to keep my Information current and to update my
                      Information if any of my Information changes.
                    </li>
                    <li>
                      I agree to submit to an amicable settlement of disputes
                      whenever it arises by submitting all
                      concerns/queries/grievances/ to{" "}
                      <a
                        href="mailto:hello@tempown.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-temp-primary"
                      >
                        hello@tempown.com
                      </a>
                      .
                    </li>
                    <li>
                      I agree that if I do not insure my item with TempOwn under
                      “TempOwn Protect”, I, solely and not TempOwn will be
                      responsible for any loss/ damage to such item.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="block relative mt-6">
                {/* IF ACCOUNT PHONE NUMBER IS VERIFIED, THEN COMPLETE REGISTRATION; ELSE GO AND COMPLETE IT */}
                {userData?.verified_phone &&
                  userData?.verified_id ? (
                  <>
                    <div className="w-full mt-4 lg:mt-6">
                      <CheckBoxGroup
                        label={
                          <span className="text-temp-primary text-xs lg:text-sm pl-8">
                            I have read and agreed to the{" "}
                            <Link
                              to="/terms-of-service"
                              className="font-semibold text-temp-green-500"
                              target="_blank"
                            >
                              terms of service
                            </Link>
                          </span>
                        }
                        name="isAgreed"
                        value={values.isAgreed}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.isAgreed && touched.isAgreed && errors.isAgreed}
                      />
                    </div>
                    <div className="text-right mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting || !values.isAgreed}
                        className={`w-full md:w-auto bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white text-sm lg:text-base font-semibold px-12 lg:px-16 py-4
                  ${isSubmitting || !values.isAgreed
                            ? "bg-temp-green-500 opacity-60"
                            : "bg-temp-green-500"
                          }`}
                      >
                        {isSubmitting ? "Process... Please wait" : "Submit"}
                      </button>
                    </div>

                  </>
                ) : (
                  <div className="mt-8 p-6 w-full bg-red-100 text-left text-red-600 rounded-lg">
                    <p className="font-semibold pb-2">Warning!!!</p>
                    <p>
                      You can&apos;t complete this registration because your{" "}
                      {!userData?.verified_id && "ID"}{" "}
                      {!userData?.verified_id &&
                        !userData?.verified_phone &&
                        " & "}
                      {!userData?.verified_phone && " phone number "} is not
                      verified.
                    </p>
                    <p className="my-6">
                      <Link
                        to={
                          !userData?.verified_id || !userData?.image
                            ?
                            "/complete-verification" :
                            "/verify-email"
                        }
                        className="bg-red-600 py-4 px-5 rounded-xl focus:outline-none text-white"
                      >
                        Click to verify account &gt;&gt;
                      </Link>
                    </p>
                  </div>
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl="/"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    alert: state.alert,
  };
};

export default (connect(mapStateToProps, actions)(Attestation));
