import React from "react";
import { Link } from "react-router-dom";
import ItemListingInCarousel from "../../../components/blocks/itemBits/ItemListingInCarousel";

const ItemsYouMayLike = ({ item, items, itemsLoading }) => {
  return (
    <ItemListingInCarousel
      sectionTitle="You might also like"
      items={items}
      itemsLoading={itemsLoading}
    >
      <div className="mx-auto text-center w-full mt-14 mb-12">
        {
            items?.length > 8 &&  <Link
            to={`/search?q=${item.type?.replace(" ", "+")}`}
            className="bg-temp-green-500 bg-gradient px-8 py-4 lg:py-5 lg:px-10 text-white rounded-xl text-center text-sm lg:text-base"
          >
            See more
          </Link>
        }
       
      </div>
    </ItemListingInCarousel>
  );
};

export default ItemsYouMayLike;
