import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const addBankApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/bank/ng";
  try {
    const response = await axios.post(url, payload);
    const responseData = response.data.data;

    dispatch({
      type: Actions.SUCCESSFULLY_ADD_BANK_DETAILS,
      payload: true,
      data: responseData
    });
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const getBankDetailsApi = () => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/bank/";
  try {
    const response = await axios.get(url);
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_BANK_DETAILS,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};

export const getBankCardsApi = () => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = "/bank/cards";
  try {
    const response = await axios.get(url);
    const responseData = response.data.data;

    dispatch({
      type: Actions.GET_BANK_CARD,
      payload: responseData,
    });
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};
