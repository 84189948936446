export const siUnitsChargeTypes = [
    {
        id: 1,
        name: "Per Hour",
        value: "Hour",
        description: "Items must be rented for a day and more"
    },
    {
        id: 2,
        name: "Per Hectares",
        value: "Hectares",
        description: "Items must be rented for a day and more"
    },
    {
        id: 3,
        name: "Per Plots",
        value: "Plots",
        description: "Items must be rented for a day and more"
    },
    {
        id: 4,
        name: "Per Feats",
        value: "Feats",
        description: "Items must be rented for a day and more"
    },
    {
        id: 5,
        name: "Per Lots",
        value: "Lots",
        description: "Items must be rented for a day and more"
    },
    {
        id: 6,
        name: "Per Dozens",
        value: "Dozens",
        description: "Items must be rented for a day and more"
    },
    {
        id: 7,
        name: "Per Set",
        value: "Set",
        description: "Items must be rented for a day and more"
    },
    {
        id: 8,
        name: "Per Kilometers",
        value: "Kilometers",
        description: "Items must be rented for a day and more"
    },
    {
        id: 9,
        name: "Per Meter",
        value: "Meter",
        description: "Items must be rented for a day and more"
    },
    {
        id: 10,
        name: "Per Kilogram",
        value: "Kilogram",
        description: "Items must be rented for a day and more"
    },
    {
        id: 11,
        name: "Per Tonnes",
        value: "Tonnes",
        description: "Items must be rented for a day and more"
    },
    {
        id: 12,
        name: "Per Miles",
        value: "Miles",
        description: "Items must be rented for a day and more"
    },
    {
        id: 13,
        name: "Per Nautical miles",
        value: "Nautical miles",
        description: "Items must be rented for a day and more"
    },

    {
        id: 14,
        name: "Per Square Meter",
        value: "Square Meter",
        description: "Items must be rented for a day and more"
    },
    {
        id: 15,
        name: "Per Pounds",
        value: "Pounds",
        description: "Items must be rented for a day and more"
    },

]
