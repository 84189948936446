import React from "react";
import { Helmet } from "react-helmet";
import OnboardingHeader from "./headers/OnboardingHeader";
import PropTypes from "prop-types";
import Container from "../blocks/Container";

const OnboardingContainer = (props) => {
  return (
    <>
      <Helmet>
        <title>TempOwn | {props.pageTitle || "Onboarding"}</title>
      </Helmet>

      <Container>
        <>
          <OnboardingHeader />

          <main>{props.children}</main>
        </>
      </Container>
    </>
  );
};

OnboardingContainer.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default OnboardingContainer;
