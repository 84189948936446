import React from "react";
import InputFileGroup from "../../../../components/element/InputFileGroup";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";
import NextButton from "./componentsBit/NextButton";
import PreviousButton from "./componentsBit/PreviousButton";
import TipCard from "./componentsBit/TipCard";

const ImagesForm = ({
  isLoading,
  displayImages,
  handleChangeCurrentStep,
  handleInputChange,
  handleRemoveImage,
  handleProcessCreateItem,
  noOfImageError,
}) => {
  // eslint-disable-next-line no-unused-vars
  const handleSubmitable = (isSubmitting, displayImages) => {
    if (isSubmitting) return false;
    if (displayImages.filter((e) => e !== "").length < 2) return false;
    return true;
  };
  return (
    <div className="flex justify-around xl:pr-20">
      <div className="w-4/12 hidden xl:block">
        <PreviousButton
          onClick={() => handleChangeCurrentStep("Step7")}
        />
      </div>
      <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12">
        <h2
          className="text-xl sm:text-2xl text-temp-primary font-medium"
        >
          Equipment Images
        </h2>
        <TipCard
          text="Make sure you capture the full view, side-view and other important part of the equipment for easy recognition of the item by borrower."
        />

        <div className="">
          <div className="mt-10 mb-44 xl:mb-20">
            <div className="group w-full pt-6">
              <label
                htmlFor="uploadImage"
                className="text-temp-primary text-sm lg:text-base"
              >
                Upload image*
              </label>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-6 lg:gap-x-4 gap-y-8 lg:gap-y-6 mt-5">
                <InputFileGroup
                  preview={displayImages[0]}
                  onDrop={(e) => handleInputChange(e, 0)}
                  accept="image/*"
                  onDeleteClick={() => handleRemoveImage(0)}
                  marginBottom="mb-0"
                  width="w-full"
                />
                <InputFileGroup
                  preview={displayImages[1]}
                  onDrop={(e) => handleInputChange(e, 1)}
                  accept="image/*"
                  onDeleteClick={() => handleRemoveImage(1)}
                  marginBottom="mb-0"
                  width="w-full"
                />
                <InputFileGroup
                  preview={displayImages[2]}
                  onDrop={(e) => handleInputChange(e, 2)}
                  accept="image/*"
                  onDeleteClick={() => handleRemoveImage(2)}
                  marginBottom="mb-0"
                  width="w-full"
                />
                <InputFileGroup
                  preview={displayImages[3]}
                  onDrop={(e) => handleInputChange(e, 3)}
                  accept="image/*"
                  onDeleteClick={() => handleRemoveImage(3)}
                  marginBottom="mb-0"
                  width="w-full"
                />
                <InputFileGroup
                  preview={displayImages[4]}
                  onDrop={(e) => handleInputChange(e, 4)}
                  accept="image/*"
                  onDeleteClick={() => handleRemoveImage(4)}
                  marginBottom="mb-0"
                  width="w-full"
                />
                <InputFileGroup
                  preview={displayImages[5]}
                  onDrop={(e) => handleInputChange(e, 5)}
                  accept="image/*"
                  onDeleteClick={() => handleRemoveImage(5)}
                  marginBottom="mb-0"
                  width="w-full"
                />
              </div>

              {!handleSubmitable(isLoading, displayImages) && (
                <p className="pt-8 text-left text-sm text-red-600">
                  {noOfImageError ||
                    "Kindly upload at least two (2) or more images of the item been created."}
                </p>
              )}
            </div>
            <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mt-12 mb-28">
              <NextButton
                disabled={!handleSubmitable(isLoading, displayImages)}
                onClick={handleProcessCreateItem}
                title="Finish"
              />
            </div>
          </div>

          <FixedBottomPrevNextButton
            handlePrevClick={() => handleChangeCurrentStep("Step7")}
            disabled={!handleSubmitable(isLoading, displayImages)}
            onClick={handleProcessCreateItem}
            title="Finish"
          />
        </div>
      </div>
    </div>
  );
};

export default ImagesForm;
