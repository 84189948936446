import React from "react";
import ItemCounter from "../../../../components/element/ItemCounter";
import PreviousButton from "./componentsBit/PreviousButton";
import NextButton from "./componentsBit/NextButton";
import SelectButtonWithDescription from "./componentsBit/SelectButtonWithDescription";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";



const ItemQuantityForm = ({
  handleQuantity,
  initialState,
  handleChangeCurrentStep,
}) => {
  const { itemCount } = initialState;
  const handleCount = (type, count) => {
    count && count > 2 ? handleQuantity(count) : handleQuantity(2);
  };

  return (
    <div className="flex justify-around xl:pr-20">
      <div className="w-4/12 hidden xl:block">
        <PreviousButton
          onClick={() => handleChangeCurrentStep("Step1")}
        />
      </div>
      <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12  mb-44 xl:mb-20">
        <h2
          className="text-xl sm:text-2xl text-temp-primary font-medium"
        >
          How many items do you want to upload?
        </h2>

        <div className="mt-8">
          <SelectButtonWithDescription
            title="Single Item"
            selected={itemCount === 1}
            handleClick={() => handleQuantity(1)}
            content="Upload a single item or a collection of items of different models and categories."
            icon={<svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.834 2.40381H2.08398V16.4038H14.334V5.90381H10.834V2.40381ZM0.333984 1.52181C0.333984 1.04231 0.725109 0.653809 1.20811 0.653809H11.709L16.084 5.02881V17.2727C16.0848 17.3876 16.063 17.5015 16.0197 17.608C15.9765 17.7145 15.9127 17.8114 15.832 17.8932C15.7514 17.975 15.6553 18.0402 15.5495 18.0849C15.4436 18.1296 15.33 18.153 15.2151 18.1538H1.20286C0.973065 18.1522 0.753125 18.0603 0.59055 17.8979C0.427975 17.7354 0.335817 17.5156 0.333984 17.2858V1.52181ZM9.08398 9.40381V12.9038H7.33398V9.40381H4.70898L8.20898 5.90381L11.709 9.40381H9.08398Z"
                fill={itemCount === 1 ? "#ffffff" : "#08A05C"}
              />
            </svg>}
          />
          <SelectButtonWithDescription
            title="Multiple Items"
            selected={itemCount > 1}
            handleClick={() => handleQuantity(2)}
            content="Items must be of the same model and category. The maximum number of items you can list is five (5)."
            icon={<svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_12060_25218)">
                <path d="M17.8692 3.7409H9.98016V16.3634H21.0249V6.89653H17.8692V3.7409ZM8.40234 2.94568C8.40234 2.51336 8.75499 2.16309 9.19046 2.16309H18.6581L22.6027 6.10763V17.1468C22.6034 17.2504 22.5837 17.3531 22.5448 17.4491C22.5058 17.5451 22.4483 17.6325 22.3755 17.7063C22.3028 17.7801 22.2162 17.8388 22.1208 17.8791C22.0253 17.9194 21.9229 17.9405 21.8193 17.9412H9.18573C8.97854 17.9398 8.78024 17.8569 8.63367 17.7105C8.48709 17.564 8.404 17.3658 8.40234 17.1586V2.94568ZM16.2914 10.0522V13.2078H14.7136V10.0522H12.3469L15.5025 6.89653L18.6581 10.0522H16.2914Z"
                  fill={itemCount > 1 ? "#ffffff" : "#08A05C"}
                />
              </g>
              <path fillRule="evenodd" clipRule="evenodd" d="M5.92871 6.4165H1.27054C0.835064 6.4165 0.482422 6.76678 0.482422 7.1991V21.4121C0.484074 21.6192 0.567165 21.8175 0.713744 21.9639C0.860323 22.1103 1.05862 22.1932 1.26581 22.1947H13.8994C14.003 22.1939 14.1054 22.1728 14.2009 22.1325C14.2963 22.0922 14.3829 22.0335 14.4556 21.9597C14.5284 21.8859 14.5859 21.7986 14.6248 21.7026C14.6638 21.6066 14.6835 21.5038 14.6828 21.4002V19.5195H13.1049V20.6168H2.06024V7.99432H5.92871V6.4165ZM5.92871 12.8038L4.42696 14.3056H5.92871V12.8038Z"
                fill={itemCount > 1 ? "#ffffff" : "#08A05C"}
              />
              <defs>
                <clipPath id="clip0_12060_25218">
                  <rect width="18.9338" height="18.9338" fill="white" transform="translate(6.03613 0.585449)" />
                </clipPath>
              </defs>
            </svg>
            }
          />
        </div>
        {itemCount > 1 && (
          <div className="group w-full">
            <div className="mt-6 flex flex-wrap items-center justify-center md:justify-between ml-4 mr-6">
              <p className="text-temp-primary text-sm lg:text-lg font-medium mr-5 mt-1 mb-4 md:mb-0">
                Select equipment quantity?
              </p>
              <ItemCounter
                disabled={itemCount <= 2}
                handleCount={handleCount}
                count={itemCount}
              />
            </div>
          </div>
        )}

        <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mt-14 mb-28">
          <NextButton
            disabled={itemCount === 0}
            onClick={() => handleChangeCurrentStep("Step3")}
            title="Next"
          />
        </div>
      </div>

      <FixedBottomPrevNextButton
        handlePrevClick={() => handleChangeCurrentStep("Step1")}
        disabled={itemCount === 0}
        onClick={() => handleChangeCurrentStep("Step3")}
        title="Next"
      />
    </div>
  );
};

export default ItemQuantityForm;
