import React from 'react';
import ReeddiLogo from "../../../assets/images/svg/logo/reddi-white.svg"
import ReeddiBlackLogo from "../../../assets/images/svg/logo/reeddi.svg"

const AuthFooterCompany = () => {
  return <div className='text-center mx-auto pt-12 pb-8'>
  <div className='text-temp-primary lg:text-white space-y-4 w-full lg:w-10/12 mx-auto'>
    <p className='text-xs'>A product of</p>

    <div className=''>
      <img src={ReeddiLogo} className="mx-auto hidden lg:block" alt="" />
      <img src={ReeddiBlackLogo} className="mx-auto lg:hidden block" alt="" />
    </div>

    <p className='text-xs text-temp-gray lg:text-white'>TempOwn is a product of Reeddi. Reeddi is a climate company that seeks to bridge the
      gap of accessibility through sustainable and limitless innovation.
    </p>
  </div>
</div>
};

export default AuthFooterCompany;
