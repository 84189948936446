import React, { useState, useEffect } from "react";

// import Clock from "../../../assets/images/svg/clock.svg";
// import SecuredIcon from "../../../assets/images/svg/secured.svg";

const RentalTimer = ({ rentalDetails, show }) => {
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-10-1`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  // const [year] = useState(new Date().getFullYear());
  // console.log(calculateTimeLeft());
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(<span>{timeLeft[interval]}: </span>);
  });

  return (
    <div className={`${show ? "block" : "hidden"} relative`}>
      <div className="timer-section w-full relative">
        <div className="">
          <div
            className={`relative flex flex-row h-full items-center ${
              !rentalDetails
                ? "justify-center lg:justify-between"
                : "justify-center"
            } py-2 lg:py-3`}>
            {!rentalDetails && (
              <div>
                <p className="hidden lg:block text-white text-sm md:text-base">
                  <span className="text-left text-temp-primary">
                    New request alert:
                  </span>{" "}
                  <span className="text-temp-secondary">
                    You have new request from Ademola
                  </span>
                </p>
              </div>
            )}
            <div className="hidden lg:flex items-center space-x-3 text-white text-sm md:text-base">
              <p className="inline-block text-temp-primary text-sm md:text-base">
                Request expires in
              </p>
              <p className="inline-block font-semibold text-temp-secondary text-sm md:text-base">
                {timerComponents.length ? (
                  <span>
                    {timeLeft.hours}: {timeLeft.minutes}: {timeLeft.seconds}
                  </span>
                ) : (
                  <span>Request Expired</span>
                )}
              </p>
            </div>

            {/* //*  for mobile-only */}
            <div className="flex lg:hidden items-center space-x-3 text-white text-sm md:text-base">
              <p className="inline-block text-temp-primary text-sm md:text-base">
                <span className="text-left">New request alert:</span>{" "}
              </p>
              <p className="inline-block font-semibold text-temp-secondary text-sm md:text-base">
                {timerComponents.length ? (
                  <span>
                    {timeLeft.hours}: {timeLeft.minutes}: {timeLeft.seconds}
                  </span>
                ) : (
                  <span>Request Expired</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalTimer;
