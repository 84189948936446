import React from "react";
import { Link } from "react-router-dom";
import UserImg from "../../../assets/images/svg/user.svg";
import StarRating from "../../../components/blocks/StarRating";
import GoogleMap from "../GoogleMap";
import CheckIcon from "../../../assets/images/svg/checked.svg";
import VerifyCard from "./VerifyCard";

const LenderInformation = ({ item }) => {
  return (
    <div id="lender" className="w-full">
      <h3 className="text-base lg:text-lg xl:text-xl font-semibold text-temp-secondary pb-6">
        Lender
      </h3>

      <div className="lg:pt-4 pb-14">
        <div className="w-full flex flex-wrap items-start flex-row mb-2">
          <div className="relative mr-4 lg:mr-6 pb-3 flex flex-shrink justify-center lg:block">
            <img
              src={item.userId?.image || UserImg}
              width="100%"
              className="h-14 w-14 lg:h-20 lg:w-20 object-cover rounded-full border-none outline-none bg-white"
              alt=""
            />
            <img
              src={CheckIcon}
              width="22rem"
              className="border-2 border-white rounded-full absolute lg:right-0 ml-12 lg:mr-0 bottom-2 lg:bottom-3 -mt-4"
              alt=""
            />
          </div>
          <div className="w-8/12">
            <p className="text-sm-15 md:text-base xl:text-lg text-temp-primary font-semibold">
              {item.userId?.firstname}
            </p>
            <p className="text-xs md:text-sm text-temp-gray pt-1">
              Member since{" "}
              {new Date(item?.userId?.createdAt || "").toLocaleDateString(
                "en-US",

                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </p>
            <div className="lg:py-2 lg:block">
              <StarRating rating={item.userId?.rating} />
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap mt-4 lg:mt-6">
          <div className="lg:w-1/12" />
          <div className="w-full lg:w-6/12">
            <div className="mb-8 flex flex-wrap flex-row justify-center md:justify-start items-center">
              <VerifyCard title="Identity verified" />
              <VerifyCard title="Contact verified" />
            </div>

            <div className="mb-4" />
            {/* <p className="leading-6 lg:leading-7 text-temp-secondary py-3 mb-6 text-sm lg:text-base">
            {item.userId?.bio}
          </p> */}

            <Link
              to={`/lender-profile/${item.userId?._id}`}
              className="text-temp-secondary text-sm lg:text-sm-15"
            >
              View full profile
            </Link>
          </div>
        </div>
        <div className="w-full mt-12 lg:mt-20">
          <GoogleMap item={item} className="w-full h-96 rounded-xl" />
        </div>
      </div>
    </div>
  );
};

export default LenderInformation;
