import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";

import AvatarImg from "../../../assets/images/svg/user.svg";

import store from "store";
import LoadingModal from "../../modals/LoadingModal";
import AuthMenu from "./AuthMenu";

const AuthHeader = ({
  isOpen,
  onClick,
  logOutClick,
  handleLogout,
  handleCloseMenu,
  ...props
}) => {
  const userData = store.get("temp_usER12_dATa");
  const [user, setUser] = useState(userData);

  useEffect(() => {
    setUser(userData);
  }, [props.history, userData?.image]);

  return (
    <>
      {/* Show on desktop */}
      <div className="mt-3 md:block mr-9">
        {user?.verified_lender || user?.type === "lender" ? (
          <Link
            to="/upload-item/start"
            className="hidden md:inline-flex text-sm-15 ml-8 bg-temp-secondary whitespace-nowrap  items-center justify-center rounded-xl text-temp-white font-semibold px-6 py-3"
          >
            Upload an item
          </Link>
        ) : (
          <Link
            to="/lender"
            className="hidden md:inline-flex text-sm-15 ml-8 bg-temp-green-500 bg-gradient whitespace-nowrap  items-center justify-center rounded-xl text-temp-white font-semibold px-6 py-3"
          >
            Become a Lender
          </Link>
        )}
      </div>

      <div className="md:block -mt-7 lg:-mt-3">
        <Link to="/profile" className="ml-8 object-cover">
          <img
            src={user?.image || AvatarImg}
            className="w-9 h-9 lg:w-12 lg:h-12 object-cover rounded-full "
            alt=""
          />
        </Link>
      </div>

      <div className="mt-4 md:block">
        <button
          type="button"
          className={`${
            props.bg || "bg-white"
          } rounded-md ml-3 lg:ml-4 p-2 inline-flex items-center justify-center text-temp-green-500  focus:outline-none`}
          onClick={onClick}
        >
          <span className="sr-only">Open menu</span>
          <svg
            className="h-7 w-7 lg:h-9 lg:w-9 -mt-4 lg:-mt-0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        <AuthMenu
          isOpen={isOpen}
          user={user}
          handleLogout={handleLogout}
          handleCloseMenu={handleCloseMenu}
        />
      </div>

      {logOutClick && (
        <LoadingModal
          title="Thanks for using the platform."
          content="Hope to see you very soon."
          show={logOutClick}
        />
      )}
    </>
  );
};

export default (AuthHeader);
