import React from 'react'

const TopSection = () => {
  return (
    <div className="emission-top-section">
      <div className="container mx-auto px-4 py-16 lg:py-24 xl:py-32">
        <div className="mb-28 md:mb-32 lg:mb-52">
          <div className="w-full md:w-8/12 lg:w-7/12 xl:w-6/12 px-4 lg:px-6 mx-auto">
            <h1 className="text-temp-primary text-3xl lg:text-4xl xl:text-6xl leading-normal xl:leading-tight font-semibold pb-4 text-center">
            We are <span className='text-temp-green-500'>saving</span> the planet
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopSection