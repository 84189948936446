import React from "react";
import AboutImage1 from "../../../assets/images/png/about/about1.webp";
import AboutImage2 from "../../../assets/images/png/about/about2.webp";
import AboutImage3 from "../../../assets/images/png/about/about3.webp";
import AboutImage4 from "../../../assets/images/png/about/about4.webp";

import AboutImage1SM from "../../../assets/images/png/about/about-1-sm.webp";
import AboutImage2SM from "../../../assets/images/png/about/about-2-sm.webp";

const AboutHeaderSection = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="pt-10 pb-24 lg:pt-16 lg:pb-32">
        <div className="w-full lg:w-9/12 mx-auto">
          <h1 className="text-temp-primary text-4xl lg:text-5xl xl:text-7xl font-semibold text-center">
            We help you <span className="text-temp-green-500">own</span>{" "}
            anything!
          </h1>
        </div>

        <div className="w-full lg:w-9/12 mx-auto mt-12 lg:mt-16">
          <div className="hidden lg:grid grid-cols-2 lg:grid-cols-4 gap-2">
            <div className="">
              <img src={AboutImage1} alt="" />
            </div>
            <div className="">
              <img src={AboutImage2} alt="" />
            </div>
            <div className="">
              <img src={AboutImage3} alt="" />
            </div>
            <div className="">
              <img src={AboutImage4} alt="" />
            </div>
          </div>

          <div className="flex flex-row justify-center space-x-2 md:space-x-4 lg:hidden">
          <div className="">
              <img src={AboutImage1SM} alt="" />
            </div>
            <div className="">
              <img src={AboutImage2SM} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHeaderSection;
