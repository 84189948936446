import React from 'react';

const SelectButton = ({ handleClick, selected, content }) => {
    return (
        <button
            type="button"
            onClick={handleClick}
            className={`flex items-center justify-start focus:outline-none py-3 lg:py-4 px-4 sm:px-6 ${selected ? "bg-temp-green-100 border-temp-green-500" : "bg-transparent border-temp-gray-light"
                } border  text-temp-gray text-sm-15 md:text-base lg:text-lg rounded-xl transition-colors duration-700 transform hover:bg-temp-green-100 focus:border-4 focus:border-temp-green-300 w-full`}
        >
            {content}
        </button>
    );
};

export default SelectButton;
