/* eslint-disable no-unused-vars */
import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import EnergyIcon from "../../../assets/images/png/category/energy.webp";
import MediaIcon from "../../../assets/images/png/category/media.webp";
import AgricIcon from "../../../assets/images/png/category/agriculture.webp";
import ConstructionIcon from "../../../assets/images/png/category/construction.webp";
import GeneralIcon from "../../../assets/images/png/category/general.webp";

const LoadingCard = () => {
    const Card = () => <div className="mb-6 mr-4 md:mr-8 lg:mr-12 xl:mr-14 category-icon-card">
        <div className="py-3 px-3 rounded-xl bg-temp-green-100 w-20 h-20 flex justify-center items-center" />
        <p className="text-sm mt-4 text-center py-2 w-32 bg-temp-gray-light"></p>
    </div>
    return (
        <div className="flex flex-wrap flex-row justify-center mt-7">
            <Card />
            <Card />
            <Card />
            <Card />
        </div>
    );
};

const Card = ({ icon, title, url }) => {
    return (
        <div className="mb-6 category-icon-card bg-temp-green-100 rounded-xl">
            <Link
                to={url || "/"}
                className=""
            >
                <div className="w-full">
                    <img src={icon} alt="" />
                </div>
                <p className="pt-3 pb-3 pl-4 lg:pt-4 lg:pb-4 text-sm lg:text-sm-15 text-left font-medium">{title}</p>
            </Link>
        </div>

    );
};

const BrowseCategoriesSection = ({ loading, categories }) => {
    return (
        <div className="container mx-auto px-4 pt-20 pb-14 md:pb-16 lg:pb-16">
            {(categories?.length > 0 || loading) && (
                <div className="pt-4 lg:pt-2 pb-0 md:pt-10 md:pb-4 mx-auto">
                    <h3 className="font-semibold text-xl lg:text-2xl xl:text-3xl text-temp-primary leading-7 pb-1 lg:pb-6">
                        Browse by Category
                    </h3>
                    {loading ? (
                        <LoadingCard />
                    ) : (
                        <div className="mt-4  mx-auto browse-catergory-list">
                            <OwlCarousel
                                className="owl-carousel owl-theme"
                                items={5}
                                loop={true}
                                nav={false}
                                dots={false}
                                margin={25}
                                stageClass="pt-5"
                                responsiveClass={true}
                                responsive={{
                                    0: {
                                        items: 2,
                                        nav: false,
                                        margin: 15,
                                    },
                                    600: {
                                        items: 4,
                                        nav: false,
                                        margin: 20,
                                    },
                                    1000: {
                                        items: 5,
                                        nav: false,
                                        loop: false,
                                        margin: 25,
                                    },
                                }}
                            >
                                <Card
                                    icon={EnergyIcon}
                                    title="Energy"
                                    url={"/category/?name=energy"}
                                />
                                <Card
                                    icon={MediaIcon}
                                    title="Media"
                                    url={"/category/?name=media"}
                                />
                                <Card
                                    icon={AgricIcon}
                                    title="Agriculture"
                                    url={"/category/?name=agriculture"}
                                />
                                <Card
                                    icon={ConstructionIcon}
                                    title="Construction"
                                    url={"/category/?name=construction"}
                                />
                                <Card
                                    icon={GeneralIcon}
                                    title="General"
                                    url={"/category/?name=general"}
                                />
                            </OwlCarousel>
                        </div>
                    )}

                    <div className="mt-8 lg:mt-10 relative flex flex-row w-auto justify-center ">
                        <div className=" text-xs md:text-sm">
                            <p>
                                Looking for an item or category not listed here?{" "}
                                <Link
                                    to={"/search"}
                                    className="text-temp-secondary font-medium"
                                >
                                    See All
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BrowseCategoriesSection;
