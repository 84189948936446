import moment from "moment";

function findClosest(blockedDays, availableFrom) {
  const today = new Date();

  if (availableFrom) {
    const AvailableFrom = new Date(availableFrom);
    if (AvailableFrom === today) {
      return undefined;
    }
    if (AvailableFrom > today) return moment(availableFrom);
  }

  if (blockedDays.length > 0) {
    blockedDays.sort(function (a, b) {
      var distancea = Math.abs(today - new Date(a));
      var distanceb = Math.abs(today - new Date(b));
      return distancea - distanceb; // sort a before b when the distance is smaller
    });

    if (!blockedDays.includes(moment(today).format("MM/DD/YYYY"))) {
      return undefined;
    }
    var i = 0;
    var closestDate = moment().add(1, "days");

    while (blockedDays.includes(closestDate.format("MM/DD/YYYY")) && i < 365) {
      closestDate = closestDate.add(1, "days");
      i++;
    }
    return closestDate;
  }
}

export const checkUnavailability = (items) => {
  items.forEach((e) => {
    if (e.availableFrom) {
      e.availableFrom = findClosest(e?.blockedDays, e.availableFrom);
    } else e.availableFrom = findClosest(e?.blockedDays);
  });
  return items;
};
