/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import MainContainer from "../../components/layout/MainContainer";
import {
    AtmCardIcon,
    BankIcon,
} from "../../components/svgs";
import AddBankDetailsModal from "./components/AddBankDetailsModal";
import AddCardInformationModal from "./components/AddCardInformationModal";

import AlertModal from "../../components/modals/AlertModal";
import * as actions from "../../store/actions";
import BanksDetails from "./components/BanksDetails";
import CardsDetails from "./components/CardsDetails";
import EditBankDetailsModal from "./components/EditBankDetailsModal";
import EmptyCard from "./components/EmptyCard";
import ProfileHeader from "./components/ProfileHeader";

const CardDetailsWrapper = ({
  toggleShow,
  handleAddCard,
  getBankCardsApi,
  bankCards,
  alert,
}) => {
  const [loadingBankCards, setLoadingBankCards] = useState(false);

  useEffect(() => {
    fecthBankCards();
  }, []);

  const fecthBankCards = async () => {
    setLoadingBankCards(true);

    await getBankCardsApi();

    setLoadingBankCards(false);
  };
  return (
    <>
      {bankCards?.length === 0 || bankCards === null ? (
        <EmptyCard
          icon={<AtmCardIcon className="mx-auto w-16 lg:w-auto" />}
          title="No card added yet"
          description="All your transactions payments will be done using this card(s)"
        />
      ) : (
        <CardsDetails
          loadingBankCards={loadingBankCards}
          toggleShow={toggleShow}
          handleAddCard={handleAddCard}
          bankCards={bankCards}
        />
      )}

      {/* Trigger Message */}
      {alert.message && (
        <AlertModal
          show={alert.type ? true : false}
          type={alert.type}
          content={alert.message}
        />
      )}
    </>
  );
};



const BankDetailsWrapper = ({
  handleAddBankDetails,
  handleToggleShow,
  handleShowEditModal,
  toggleShow,
  getBankDetailsApi,
  bank,
  alert,
}) => {
  const [loadingBankDetails, setLoadingBankDetails] = useState(false);

  useEffect(() => {
    fecthBankDetails();
  }, []);

  const fecthBankDetails = async () => {
    setLoadingBankDetails(true);

    await getBankDetailsApi();

    setLoadingBankDetails(false);
  };

  return (
    <>
      {bank?.accountNumber?.length === 0 || bank === null ? (
        <EmptyCard
          icon={<BankIcon className="mx-auto w-16 lg:w-auto" />}
          title="No bank added yet"
          description="All your withdrawal will be made into this account"
          button={
            <button
              onClick={handleAddBankDetails}
              className="mt-10 bg-temp-green-500 bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white text-sm font-bold px-8 py-4 focus:outline-none"
            >
              Add Bank Details
            </button>
          }
        />
      ) : (
        <BanksDetails
          handleToggleShow={handleToggleShow}
          handleAddBankDetails={handleAddBankDetails}
          toggleShow={toggleShow}
          loadingBankDetails={loadingBankDetails}
          bank={bank}
          handleShowEditModal={handleShowEditModal}
        />
      )}

      {/* Trigger Message */}
      {alert.message && (
        <AlertModal
          show={alert.type ? true : false}
          type={alert.type}
          content={alert.message}
        />
      )}
    </>
  );
};

// Main Wrapper

const CardAndBanks = (props) => {
  const [currentTab, setCurrentTab] = useState("Cards");

  const [toggleShow, setToggleShow] = useState(false);

  const [showAddCard, setShowAddCard] = useState(false);
  const [showAddBank, setShowAddBank] = useState(false);

  const handleChangeCurrentTab = (value) => {
    setCurrentTab(value);
  };

  const handleAddCard = () => {
    setShowAddCard(!showAddCard);
  };

  const handleAddBankDetails = () => {
    setShowAddBank(!showAddBank);
  };

  const handleToggleShow = () => {
    return setToggleShow(!toggleShow);
  };

  const [showEditModal, setShowEditModal] = useState(false);

  const handleShowEditModal = () => {
    setShowEditModal(true);
  };

  return (
    <MainContainer hasMainContainer={false}>
      <>
        <div className="w-full pb-36 relative">
          <ProfileHeader
            title="Cards and Banks"
            currentPageName="Cards and Banks"
            tabList={[
              "Cards",
              "Bank Details"
            ]}
            handleChangeCurrentTab={handleChangeCurrentTab}
            selectedTab={currentTab}
          />
          <div className="container mx-auto px-4 w-full">

            {/* Display Cards */}
            {currentTab === "Cards" ? (
              <CardDetailsWrapper
                handleAddCard={handleAddCard}
                toggleShow={true}
                getBankCardsApi={props.getBankCardsApi}
                alert={props.alert}
                bankCards={props?.banks?.bankCards}
              />
            ) : currentTab === "Bank Details" ? (
              <BankDetailsWrapper
                handleAddBankDetails={handleAddBankDetails}
                toggleShow={toggleShow}
                handleToggleShow={handleToggleShow}
                getBankDetailsApi={props.getBankDetailsApi}
                alert={props.alert}
                bank={props?.banks.bankDetails}
                handleShowEditModal={handleShowEditModal}
              />
            ) : (
              ""
            )}
          </div>

          {/* Show Add Card Form */}
          {showAddCard && currentTab === "Cards" && (
            <AddCardInformationModal
              show={showAddCard}
              handleCloseModal={() => setShowAddCard(false)}
            />
          )}

          {/* Show Add Card Form */}
          {showAddBank && currentTab == "Bank Details" && (
            <AddBankDetailsModal
              show={showAddBank}
              handleCloseModal={() => setShowAddBank(false)}
            />
          )}

          {/* Edit modal */}
          {showEditModal && (
            <EditBankDetailsModal
              show={showEditModal}
              bankDetails={props?.banks?.bankDetails}
              handleCloseModal={() => setShowEditModal(false)}
            />
          )}
        </div>
      </>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    banks: state.banks,
    alert: state.alert,
  };
};

export default (connect(mapStateToProps, actions)(CardAndBanks));
