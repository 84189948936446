import React from 'react';
import { numberFormat } from '../../../utils/numberFormat';

const DiscountCalculation = ({ formattedPrice, price, discount, discountType, show, handleShow }) => {
    return <div className="w-full lg:w-4/12 relative">
        <div className="lg:mt-9 h-14 w-full text-sm lg:text-base inline-flex items-center px-6 rounded-lg bg-temp-green-100 text-temp-green-500">
            {formattedPrice}
        </div>
        <button
            type="button"
            className="text-temp-secondary mt-2 text-xs lg:text-sm focus:outline-none"
            onClick={handleShow}
        >
            See calculation
        </button>
        {show && (
            <div className="absolute top-7 left-28 lg:left-0 bg-white shadow-lg rounded-lg py-6 px-6 text-xs text-temp-gray">
                {numberFormat(price)} -{" "}
                {(discount / 100) * price} ={" "}
                {numberFormat(
                    price -
                    (discount / 100) * price
                )}
                /{discountType || "day"}
            </div>
        )}
    </div>
}

export default DiscountCalculation;
