import React, { useEffect, useState } from "react";
import { PaystackButton } from "react-paystack";
import { connect } from "react-redux";
import { } from "react-router-dom";
import store from "store";
import Loader from "../../components/blocks/Loader";
import AlertModal from "../../components/modals/AlertModal";
import LoadingModal from "../../components/modals/LoadingModal";
import SendReportModal from "../../components/modals/SendReportModal";
import * as actions from "../../store/actions";
import { numberFormat } from "../../utils/numberFormat";
import EmptyLateRental from "./components/EmptyLateRental";
import LateRentalEquipment from "./components/LateRentalEquipment";
import LateReturnRentalUser from "./components/LateReturnRentalUser";

const LateRental = (props) => {
    const userData = store.get("temp_usER12_dATa");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState({});
    useEffect(() => {
        fetchLateReturns();
    }, []);

    const fetchLateReturns = async () => {
        setLoading(true);
        await props.getLateRentalsApi();
        setLoading(false);
    };

    useEffect(() => {
        setData(props.lateReturns?.lateReturns);
        setSummary(props.lateReturns?.summary);
    }, [props.lateReturns]);

    const handleGoBack = () => {
        props.history.go(-1);
    };

    const componentProps = {
        email: userData?.email,
        amount: parseInt(summary?.total).toFixed(2) * 100,
        metadata: {
            name: userData?.firstname + " " + userData?.lastname,
            phone: userData?.phone,
        },
        publicKey: process.env.REACT_APP_PAYSTACK_API,
        text: (
            <>
                {" "}
                <strong>PAY NOW</strong>{" "}
                <span className="font-normal">({numberFormat(summary?.total)})</span>
            </>
        ),
        onSuccess: (response) => {
            const lateReturnsArray = data?.map((a) => a?._id);
            let payload = {
                ref: response?.reference,
                total: summary?.total,
                lateReturnFee: summary?.lateReturnFee,
                serviceCharge: summary?.serviceCharge,
                baseFee: summary?.baseFee,
                lateReturns: [...lateReturnsArray],
            };

            verifyPayment(payload);
        },
    };

    const [verifyLoading, setVerifyLoading] = useState(false);
    const verifyPayment = async (payload) => {
        setVerifyLoading(true);
        await props.payLateRentalFeeApi(payload);
        setVerifyLoading(false);
    };

    const [showSendReport, setShowSendReport] = useState(false);
    const handleShowSendReportModal = () => {
        setShowSendReport(true);
    };

    const handleCloseModal = () => {
        setShowSendReport(false);
    };

    const [isSubmitReportSuccess, setIsSubmitReportSuccess] = useState(null);
    const processSubmitFeedback = async (formData) => {
        const resp = await props.submitLateRentalContendingFormApi(formData);
        if (resp?.success) {
            setIsSubmitReportSuccess(resp);
        } else {
            setIsSubmitReportSuccess(null);
        }
    };

    return (
        <section className="relative">
            <div className="container mx-auto px-4">
                <div className="w-full md:10/12 lg:w-7/12 xl:w-5/12 overflow-y-auto min-h-screen mx-auto text-center flex flex-col justify-start pt-10 md:pt-16 lg:pt-24 items-center">
                    <button
                        onClick={() => handleGoBack()}
                        className="text-temp-green-500 text-sm font-semibold text-left w-full mb-6 ring-0 focus:ring-0 focus:outline-none"
                    >
                        &lt; Go Back
                    </button>

                    <LateReturnRentalUser />

                    {
                        data?.length > 0 && <div className="text-temp-gray text-base w-11/12 lg:w-10/12 mx-auto mt-6">
                            Please complete your late return fee payment to continue renting on
                            TempOwn
                        </div>
                    }


                    {loading ? (
                        <div className="my-14">
                            <Loader />
                        </div>
                    ) : data?.length > 0 ? (
                        <div className="mt-4 pb-96">
                            {data?.map((datum) => (
                                <LateRentalEquipment key={datum?._id} item={datum} />
                            ))}
                        </div>
                    ) : (
                        <EmptyLateRental handleGoBack={handleGoBack} />
                    )}
                </div>
                {
                    data?.length > 0 &&
                    <div className="fixed bottom-0 left-0 w-full late-rental-footer z-40 bg-white">
                        <div className="container px-4 mx-auto flex flex-wrap flex-row items-center justify-center py-6 lg:py-10 md:space-x-4 lg:space-x-6 text-xs md:text-sm lg:text-sm-15">
                            <button
                                onClick={handleGoBack}
                                className="text-temp-green-500  focus:outline-none ring-0 focus:ring-0 font-semibold hidden lg:block order-3 lg:order-1 mb-2 md:mb-0"
                            >
                                I WILL PAY LATER
                            </button>

                            <button
                                onClick={handleShowSendReportModal}
                                className="ml-2 order-2 border border-temp-green-500 text-temp-green-500 font-semibold rounded-xl focus:outline-none ring-0 focus:ring-0 py-4 px-4 md:px-6 mb-2 md:mb-0"
                            >
                                SEND A REPORT
                            </button>

                            <div className="-mt-8 md:-mt-10 mb-4 md:mb-0 order-1 lg:order-3">
                                <PaystackButton
                                    className="paystack-button bg-gradient px-8 pt-4 md:pt-5 pb-8 md:pb-10 rounded-xl text-xs md:text-sm lg:text-sm-15"
                                    {...componentProps}
                                />
                            </div>

                            <div className="order-4 text-center text-temp-gray text-xs lg:text-sm w-11/12 lg:w-10/12 mx-auto mt-3 lg:mt-6">
                                PS: You won&apos;t be able to make any new rental requests until you pay the late return fee.
                            </div>
                        </div>
                    </div>
                }
            </div>

            {showSendReport && (
                <SendReportModal
                    show={showSendReport}
                    handleCloseModal={handleCloseModal}
                    items={data}
                    processSubmitFeedback={processSubmitFeedback}
                />
            )}

            {verifyLoading && (
                <LoadingModal
                    show={verifyLoading}
                    content="Payment verification in progress...."
                />
            )}

            {/* Trigger Message */}
            {props.alert.message && (
                <AlertModal
                    show={props.alert.type ? true : false}
                    type={props.alert.type}
                    content={props.alert.message}
                    linkUrl={undefined}
                    onClose={() => {
                        if (props?.alert?.type === "success") {
                            props.history.go(-1);
                            setData([]);
                            setSummary({});
                        }
                    }}
                />
            )}

            {isSubmitReportSuccess?.message && (
                <AlertModal
                    show={isSubmitReportSuccess?.message ? true : false}
                    type={isSubmitReportSuccess?.success ? "success" : "error"}
                    content={isSubmitReportSuccess.message}
                    onClose={() => {
                        if (isSubmitReportSuccess?.success) {
                            setIsSubmitReportSuccess(null);
                            setShowSendReport(false);
                        }
                    }}
                />
            )}
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        lateReturns: state.lateReturns.rentals,
        alert: state.alert,
    };
};

export default (connect(mapStateToProps, actions)(LateRental));
