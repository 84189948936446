import React from "react";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";
import NextButton from "./componentsBit/NextButton";
import SelectButton from "./componentsBit/SelectButton";


const SerialNumberForm = ({
  handleHasSerialNo,
  initialState,
  handleChangeCurrentStep,
}) => {
  const { hasSerialNumber } = initialState;
  return (
    <div className="flex justify-around xl:pr-20">
      <div className="w-4/12 hidden xl:block" />
      <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12  mb-44 xl:mb-20">
        <h2
          className="text-xl sm:text-2xl text-temp-primary font-medium"
        >
          Does your equipment have serial number?
        </h2>
        <p className="mt-4 text-sm-15 lg:text-base text-temp-secondary">
          This will determine the type of insurance
        </p>
        <div className="mt-8 lg:mt-14 grid grid-cols-2 gap-4 lg:gap-6">
          <SelectButton
            selected={hasSerialNumber === true}
            handleClick={() => handleHasSerialNo(true)}
            content={<p className="whitespace-nowrap">Yes</p>}
          />
          <SelectButton
            selected={hasSerialNumber === false}
            handleClick={() => handleHasSerialNo(false)}
            content={<p className="whitespace-nowrap">No</p>}
          />
        </div>

        <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mt-14 mb-28">
          <NextButton
            disabled={hasSerialNumber === null}
            onClick={() => handleChangeCurrentStep("Step1")}
            title="Next"
          />
        </div>
      </div>
      <FixedBottomPrevNextButton
        hasPrev={false}
        disabled={hasSerialNumber === null}
        onClick={() => handleChangeCurrentStep("Step1")}
        title="Next"
      />
    </div>

  );
};

export default SerialNumberForm;
