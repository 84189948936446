import React from 'react'
import { Line } from "rc-progress";

const ProgressIndicator = ({ percent, strokeWidth, trailWidth }) => {
    return (
        <Line
            percent={percent || "0"}
            strokeWidth={strokeWidth || "2"}
            trailWidth={trailWidth || "2"}
            strokeColor="#08A05C"
        />
    )
}

export default ProgressIndicator
