import React from "react";
import BorrowerInformationWithViewButton from "../../../components/blocks/itemBits/BorrowerInformationWithViewButton";
import LenderInformationWithViewButton from "../../../components/blocks/itemBits/LenderInformationWithViewButton";
import CheckImage from "../../../assets/images/svg/checkgradient.svg";

const CompletedOrderCard = ({
  itemDetails,
  orderDetails,
  loggedInUserDetails,
  handleCheckItemAvailabilityModalShow,
}) => {

  const handleScrollToRatingSection = () => {
    document.querySelector("#complete-order-review-form").scrollIntoView({
      "behavior": "smooth"
    })
  }
  return (
    <div
      className="rounded-xl py-6 lg:py-8 mx-auto"
      style={{
        border: "0.5px solid #B0B0B0",
      }}
    >
      <div className="text-temp-secondary font-semibold px-6 lg:px-8 mb-4 lg:mb-6 text-base lg:text-lg">
        Order Activities
      </div>
      <div className="flex flex-wrap items-baseline pr-8">
        <p className="px-6 lg:px-8 text-sm lg:text-sm-15 pb-4 text-temp-gray">
          Status
        </p>
        <p className="px-4 lg:px-6 font-medium text-xs lg:text-sm completed-status rounded-xl pb-3 pt-3">
          Completed
        </p>
      </div>

      <div className="mt-2 lg:mt-4">
        {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
          <div className="px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
            <button
              type="button"
              onClick={handleScrollToRatingSection}
              className="w-full mt-5 font-bold text-xs lg:text-sm py-4 px-4 border border-temp-green-500 text-temp-green-500 rounded-xl focus:outline-none bg-white"
            >
              Rate
            </button>
            <button
              type="button"
              onClick={handleCheckItemAvailabilityModalShow}
              className="w-full mt-5 font-bold text-xs lg:text-sm py-4 px-4 text-white rounded-xl focus:outline-none bg-gradient"
            >
              Request again
            </button>
          </div>
        ) :
          <div className="mt-4 lg:mt-8 text-center mx-auto bg-temp-green-100 py-2 flex flex-wrap justify-center items-center">
            <img src={CheckImage} className="mr-2" alt="" />
            <p className="text-sm text-temp-green-500">Item returned successfully</p>
          </div>
        }
      </div>

      {/* Desktop Only */}
      <div className="pt-4 px-6 lg:px-8 hidden lg:block">
        {loggedInUserDetails?._id === orderDetails?.borrower?._id ? (
          <LenderInformationWithViewButton
            className="px-0"
            title="Lender"
            item={itemDetails}
            showChatButton={false}
          />
        ) : (
          <BorrowerInformationWithViewButton
            className="px-0"
            title="Borrower"
            user={orderDetails?.borrower}
            showChatButton={false}
          />
        )}
      </div>
    </div>
  );
};

export default CompletedOrderCard;
