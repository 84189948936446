import React from 'react';
import {
    SecurePlatformIcon,
} from "../../../components/svgs";

const EmptyCard = ({icon, title, description, button}) => {
    return (
        <div className="my-14 relative w-full flex justify-center">
            <div className="mx-auto text-center">
                {icon}

                <p className="pt-4 md:pt-8 pb-8 text-temp-primary text-sm">
                 {title}
                </p>

                <p className="py-4 px-8 bg-temp-green-100 rounded-lg text-temp-primary text-sm">
                    {description}
                </p>

                {button}

                <SecurePlatformIcon className="mx-auto mt-10" />
            </div>
        </div>
    );
};

export default EmptyCard;
