import React from "react";
import AboutEquipmentCard from "./AboutEquipmentCard";
import { capitalizeFistLetterInSentence } from "../../../utils/capitalizeFirstLetterInSentence";

const EquipmentFeatures = ({ item }) => {
  return (
    <div className="mt-10 lg:mt-16 mb-16">
      <p className="text-base lg:text-lg xl:text-xl font-semibold text-temp-secondary pb-4">
        About this equipment
      </p>

      <div className="mt-4 lg:mt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-8 lg:gap-y-12">
          {item?.features?.map((d, index) => (
            <AboutEquipmentCard
              key={index}
              icon={d?.feature?.icon}
              name={capitalizeFistLetterInSentence(d?.feature?.name)}
              value={d?.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EquipmentFeatures;
