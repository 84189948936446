import React from "react";

const CheckBoxGroup = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  error,
  checked,
}) => {
  return (
    <label
      className="custom-checkbox"
      // style={{ paddingLeft: paddingLeft || "35px" }}
    >
      {label}
      <input
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        checked={checked}
      />
      <span className={`checkmark ${error && "border-red-600"}`}></span>
    </label>
  );
};

export default CheckBoxGroup;
