import React from "react";

const EquipmentDescription = ({ item }) => {
  return (
    <div className="desc mb-6 pt-4 lg:pt-6">
      <p className="text-base lg:text-lg xl:text-xl font-semibold text-temp-secondary pb-4">
        Description
      </p>
      <p className="text-temp-gray text-sm lg:text-sm-15 leading-6 lg:leading-7">
        {item?.description}
      </p>
    </div>
  );
};

export default EquipmentDescription;
