import React from "react";
import StarRatings from "react-star-ratings";

const StarRating = ({
  rating,
  starDimension,
  starSpacing,
  numberOfStars,
  starRatedColor,
  ...props
}) => {
  return (
    <div className="flex flex-shrink">
      <StarRatings
        rating={rating || 0}
        starRatedColor={starRatedColor || "#08A05C"}
        starEmptyColor="#89D8C9"
        starHoverColor="#000000"
        starDimension={starDimension || "12px"}
        starSpacing={starSpacing || "0px"}
        numberOfStars={numberOfStars || 5}
        name="rating"
        {...props}
      />
    </div>
  );
};

export default StarRating;
