import React from "react";
import { Link } from "react-router-dom";
import {
  getRentalActivityClassNameByStatus,
  getRentalActivityNameByStatus,
} from "../../../helpers/rentalActivityStatus";
import {
  getRentalItemName,
  getRentalStartAndEndDate,
  getRentalTotalAmount,
  getRentalUnitOrderedAndType,
} from "../../../helpers/rentalFlexibilityHelper";

const Status = ({ className, statusName }) => {
  return (
    <div
      className={`py-1 px-3 text-xs font-medium text-center rounded-b-xl absolute right-6 capitalize 
    ${className}`}
    >
      {statusName}
    </div>
  );
};

const ImageContainer = ({ image }) => {
  return (
    <img
      src={image}
      className="flex-shrink rounded-lg bg-temp-primary-100 h-20 object-cover"
      alt=""
    />
  );
};

const Quantity = ({ quantity }) => {
  return (
    <>
      {quantity > 1 && (
        <div className="absolute -top-1 w-5 h-5 bg-gradient-to-r from-yellow-300 to-yellow-500 rounded-full text-white font-medium text-xs flex items-center justify-center">
          {quantity}
        </div>
      )}
    </>
  );
};

const ItemName = ({ itemName }) => (
  <h3 className="text-sm-15 md:text-base lg:text-lg xl:text-lg text-temp-primary font-medium">
    {itemName}
  </h3>
);

const BorrowerName = ({ borrowerFirstAndLastName }) => (
  <div className="text-temp-green-500 text-xs lg:text-sm">
    {borrowerFirstAndLastName}
  </div>
);

const Amount = ({ totalAmount }) => (
  <p className="text-xs lg:text-sm inline-flex mt-2 lg:mt-2 mb-2 lg:mb-2 text-temp-gray">
    {totalAmount}
  </p>
);

const UnitOrderAndDate = ({ unitOrdered, dateRange }) => {
  return (
    <p className="text-xs lg:text-xs mb-5">
      {unitOrdered}{" "}
      <span className="text-temp-green-500 text-xs">({dateRange})</span>
    </p>
  );
};

// Main Wrapper
const AllActivities = ({ data }) => {
  return (
    <div className="container mx-auto px-4">
      <div className="my-12 lg:my-16 relative w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 lg:gap-y-10">
        {data.map((datum) => {
          const className = getRentalActivityClassNameByStatus(datum);
          const statusName = getRentalActivityNameByStatus(datum);
          const linkUlrl = `/rental-activities/order/?id=${datum?._id}&itemId=${datum?.itemId?._id}`;
          const image = datum?.itemId?.images[0]?.image?.url;
          const borrowerFirstAndLastName =
            datum?.borrower?.firstname + " " + datum?.borrower?.lastname;
          const totalAmount = getRentalTotalAmount(datum);
          const dateRange = getRentalStartAndEndDate(datum);
          const itemName = getRentalItemName(datum, 24, 24);
          const unitOrdered = getRentalUnitOrderedAndType(datum);
          return (
            <div
              key={datum._id}
              className="w-full rounded-2xl relative rental-activity-card"
              style={{
                boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Link to={linkUlrl}>
                <Status className={className} statusName={statusName} />
                <div className="px-5 pt-8 pb-4 flex flex-wrap">
                  <div className="w-4/12 pb-6 relative pr-5">
                    <ImageContainer image={image} />
                    <Quantity quantity={datum.quantity} />
                  </div>
                  <div className="w-8/12 flex-shrink">
                    <ItemName itemName={itemName} />
                    <BorrowerName
                      borrowerFirstAndLastName={borrowerFirstAndLastName}
                    />
                    <Amount totalAmount={totalAmount} />
                    <UnitOrderAndDate
                      unitOrdered={unitOrdered}
                      dateRange={dateRange}
                    />
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllActivities;
