import React from "react";
import { Link } from "react-router-dom";
import { capitalizeFistLetterInSentence } from "../../../utils/capitalizeFirstLetterInSentence";

const CategoryHeader = ({ currentCategoryImage, currentCategoryName, handleViewCategoryModal }) => {
  return (
    <div className="pt-6 lg:pt-6 pb-6 lg:pb-10">
      <div className="space-x-2 mb-6 lg:mb-10 text-sm lg:text-sm-15 cursor-pointer">
        <span><Link to="/">Home</Link></span>{" "}
        <span>&gt;</span>{" "}
        <span>
          <button
            className="outline-none focus:outline-none ring-0 focus:ring-0"
            onClick={handleViewCategoryModal}
          >
            Category
          </button>
        </span>{" "}
        <span>&gt;</span>{" "}
        {currentCategoryName && <span className="text-temp-secondary">{capitalizeFistLetterInSentence(currentCategoryName)}</span>}
      </div>
      <div className="flex items-center">
        <div className="category-icon-card mr-4">
          <div className="py-3 px-3 rounded-xl bg-temp-green-100 w-16 h-16 flex justify-center items-center">
            <img src={currentCategoryImage} alt="" />
          </div>
        </div>
        <h1 className="text-xl md:text-2xl lg:text-4xl font-bold text-temp-primary">
          {capitalizeFistLetterInSentence(currentCategoryName)}
        </h1>
      </div>
    </div>
  );
};

export default CategoryHeader;
