import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const payLateRentalFeeApi = (payload) => async (dispatch) => {
    dispatch(alertActions.clear());

    const url = "/late-returns/pay";
    try {
        const response = await axios.post(url, payload);
        const responseData = response.data.data;
        dispatch(alertActions.success(response?.data?.message));
        return responseData;
    } catch (error) {
        dispatch(alertActions.error(error.response.data.message));
    }
};

export const getLateRentalsApi = () => async (dispatch) => {
    dispatch(alertActions.clear());

    const url = "/late-returns";
    try {
        const response = await axios.get(url);
        const responseData = response.data.data;

        dispatch({
            type: Actions.GET_LATE_RETURNS,
            payload: responseData,
        });
    } catch (error) {
        dispatch(alertActions.error(error.response?.data.message));
    }
};

export const submitLateRentalContendingFormApi = (payload) => async (dispatch) => {
    dispatch(alertActions.clear());

    const url = "/late-returns/report";
    try {
        const response = await axios.post(url, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        return {
            failed: true
        }
    }
};