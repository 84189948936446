import React, { useState } from "react";
import { Link, } from 'react-router-dom';
import { redirectToChatPageWithUserDataApi } from "../../store/actions/chatActions";
import { ChatIcon } from "../svgs";
import UserRatingCard from "./UserRatingCard";

const ProfileWithViewButton = ({
  userType,
  image,
  user,
  firstname,
  membershipStartDate,
  title,
  id,
  className,
  pickupLocation,
  pickupContact,
  showChatButton = true,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const handleOpenChatPage = async () => {
    const data = {
      ...user,
    };

    setLoading(true);
    await redirectToChatPageWithUserDataApi(data, props.history);
    setLoading(false);
  };

  return (
    <div className={`mt-6 mb-6 ${className}`}>
      {firstname && (
        <p className="text-base lg:text-lg xl:text-lg font-semibold text-temp-secondary">{title}</p>
      )}

      <div className="">
        <UserRatingCard
          image={image}
          name={firstname}
          membershipStartDate={membershipStartDate}
        />

        {pickupLocation && (
          <div className="-mt-4 mb-2 ml-24 text-xs">
            <p>{pickupLocation.address}
              {pickupLocation.town && ", " + pickupLocation.town}
              {pickupLocation.state &&  ", " + pickupLocation.state}
              {pickupLocation.country && ", " + pickupLocation.country}.
            </p>
          </div>
        )}

        {pickupContact && (
             <div className="-mt-1 mb-2 ml-24 text-xs">
               <span className="font-medium">Phone:</span> {pickupContact}
           </div>
        )}

        <div className={`flex flex-wrap ${showChatButton && "lg:justify-center"}`}>
          {
            showChatButton && <button
              disabled={loading}
              onClick={handleOpenChatPage}
              className={`w-full md:w-auto mr-0 md:mr-4 inline-flex py-3 px-4 items-center justify-center mt-5 font-bold text-sm text-white rounded-lg focus:outline-none bg-temp-green-500 bg-gradient 
            ${loading && "opacity-80"}
          `}
            >
              <ChatIcon />
              <span className="pl-3">
                {loading ? (
                  "Connecting..."
                ) : (
                  <>Chat {userType === "borrower" ? "Borrower" : "Owner"}</>
                )}
              </span>
            </button>
          }

          <Link
            to={
              userType === "borrower"
                ? `/borrower-profile/${id}`
                : `/lender-profile/${id}`
            }
            className={`w-full md:w-auto py-3 px-4 font-bold text-sm inline-flex items-center justify-center text-temp-green-500 rounded-lg focus:outline-none border border-temp-green-500 ${showChatButton ? "mt-5" : "ml-6"}`}
          >
            View Profile
          </Link>
        </div>
      </div>
    </div>
  );
};

export default (ProfileWithViewButton);
