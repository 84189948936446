import React from "react";
import Dropzone from "react-dropzone";
const InputFileGroup = ({
  onDrop,
  accept,
  preview,
  onDeleteClick,
  marginBottom,
  width,
  height
}) => {
  return (
    <>
      <div
        className={`${marginBottom || "mb-6"
          } dropzone dropzone-default text-center bg-temp-green-100 rounded-xl ${width || "w-32"} ${height || "h-32"} border border-temp-gray-light border-dashed flex items-center justify-center`}
        id="kt_dropzone_1"
      >
        {preview ? (
          <div className="relative w-full h-full">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${preview?.image?.url ? preview?.image?.url : preview})`,
                  backgroundPosition: "center",
                }}
                className={`relative rounded-xl bg-cover ${width || "w-32"} ${height || "h-32"} md:mr-4`}
              />
            </div>
            <button
              type="button"
              className="absolute top-3 right-3 h-6 w-6 rounded-md bg-red-600 flex justify-center items-center"
              onClick={onDeleteClick}
            >
              <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.05563 1.24167H3.94387V1.60743H3.18124V1.19046C3.18115 0.785794 3.50081 0.456543 3.89374 0.456543H6.10576C6.49869 0.456543 6.81836 0.785794 6.81836 1.19046V1.60743H6.05563V1.24167Z" fill="white" />
                <path d="M1.42352 4.71826H8.58303C8.77921 4.71826 8.93364 4.89053 8.91783 5.09187L8.31928 12.7105C8.28592 13.1358 7.94129 13.4634 7.52725 13.4634H2.4794C2.06536 13.4634 1.72072 13.1358 1.68737 12.7104L1.08881 5.09187C1.07291 4.89053 1.22734 4.71826 1.42352 4.71826ZM6.84945 12.6508C6.85745 12.6513 6.86545 12.6516 6.87336 12.6516C7.07329 12.6516 7.24113 12.4913 7.25356 12.2831L7.62866 6.02862C7.64158 5.81219 7.48165 5.62594 7.27149 5.61264C7.06201 5.59954 6.8803 5.76377 6.86738 5.98029L6.49238 12.2348C6.47937 12.4512 6.6393 12.6374 6.84945 12.6508ZM4.61767 12.259C4.61767 12.4757 4.7884 12.6515 4.99903 12.6515C5.20967 12.6515 5.3804 12.4757 5.3804 12.259V6.00441C5.3804 5.78758 5.20967 5.61185 4.99903 5.61185C4.78849 5.61185 4.61767 5.78758 4.61767 6.00441V12.259ZM2.37789 6.02753L2.73602 12.282C2.74788 12.4906 2.916 12.6515 3.11632 12.6515C3.12384 12.6515 3.13146 12.6513 3.13907 12.6509C3.34932 12.6381 3.50973 12.4523 3.4973 12.2359L3.13926 5.98129C3.12692 5.76486 2.94704 5.59974 2.73621 5.61254C2.52596 5.62524 2.36555 5.8111 2.37789 6.02753Z" fill="white" />
                <path d="M0.104548 3.5068L0.354999 2.73398C0.421033 2.53026 0.606316 2.39282 0.815024 2.39282H9.19121C9.39983 2.39282 9.58521 2.53026 9.65114 2.73398L9.90159 3.5068C9.94989 3.65585 9.88704 3.80787 9.76972 3.88368C9.7219 3.91454 9.66531 3.9331 9.60314 3.9331H0.403007C0.340828 3.9331 0.284143 3.91454 0.236425 3.88358C0.119105 3.80777 0.0562515 3.65575 0.104548 3.5068Z" fill="white" />
              </svg>

            </button>
          </div>
        ) : (
          <Dropzone onDrop={onDrop} accept={accept}>
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone-msg dz-message cursor-pointer">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="relative">
                    <div className="w-full mx-auto text-center">
                      <p className="text-temp-primary text-xl">+</p>

                      <button type="button" className="text-temp-primary bg-transparent text-xs focus:outline-none">
                        Add image
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </>
  );
};

export default InputFileGroup;
