import React from "react";
import { Link } from "react-router-dom";

const UnAuthHeader = () => {
  return (
    <>
      <div className="mt-3 md:block">
        <Link
          to="/signin"
          className="text-sm-15 ml-8 whitespace-nowrap text-base font-semibold text-temp-primary"
        >
          Sign In
        </Link>
      </div>

      <div className="mt-4 md:block">
        <Link
          to="/signup"
          className="text-sm-15 ml-8 bg-temp-secondary bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white font-semibold px-6 py-3"
        >
          Get Started
        </Link>
      </div>
    </>
  );
};

export default UnAuthHeader;
