import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../../assets/images/svg/logo/logo2.svg"
import AuthFooterCompany from './AuthFooterCompany';
import AuthImage from "../../../assets/images/png/authImage.webp"

const AuthLeftContainer = () => {
    return (
        <div className='relative hidden lg:flex flex-col w-5/12 2xl:w-4/12 min-h-screen px-10 pt-8'>
            <div className='auth-bg-gradient lg:flex flex-col rounded-2xl'>
                <div className='absolute top-20 left-12'>
                    <Link to="/">
                        <span className="sr-only">TempOwn</span>
                        <img className="h-6 w-auto md:h-8 lg:h-9" src={Logo} alt="Logo" />
                    </Link>
                </div>

                <div className=''>
                    <img src={AuthImage} className="auth-left-image" alt="Auth Image" />
                </div>

                <AuthFooterCompany />
            </div>
        </div>
    )
}

export default AuthLeftContainer;
