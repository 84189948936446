import React from "react";


const ItemNavigation = ({ selectedMenu, handleSelectedMenu }) => {
  return (
    // sticky top-0 z-10  - fix later
    <div className="py-3 lg:py-4 rental-activity-header sticky top-20 lg:top-24 z-20">
      <div className="container mx-auto px-4 space-x-6 overflow-auto text-sm lg:text-sm-15">
        <button
          className={`focus:outline-none ${
            selectedMenu === "overview"
              ? "text-temp-green-500"
              : "text-temp-primary"
          }`}
          onClick={() => handleSelectedMenu("overview")}
        >
          Overview
        </button>
        <button
          className={`focus:outline-none ${
            selectedMenu === "review"
              ? "text-temp-green-500"
              : "text-temp-primary"
          }`}
          onClick={() => handleSelectedMenu("review")}
        >
          Review
        </button>
        <button
          className={`focus:outline-none ${
            selectedMenu === "lender"
              ? "text-temp-green-500"
              : "text-temp-primary"
          }`}
          onClick={() => handleSelectedMenu("lender")}
        >
          Lender
        </button>
      </div>
    </div>
  );
};

export default ItemNavigation;
