import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "store";
import InputGroup from "../../components/element/InputGroup";
import MainContainer from "../../components/layout/MainContainer";
import AlertModal from "../../components/modals/AlertModal";
import LoadingModal from "../../components/modals/LoadingModal";
import { firebaseSignOutUser } from "../../helpers/firebaseConn";
import * as actions from "../../store/actions";
import { capitalizeFistLetterInSentence } from "../../utils/capitalizeFirstLetterInSentence";
import { scrollToTop } from "../../utils/scrollToTop";
import ProfileHeader from "./components/ProfileHeader";

const PasswordWithChangeButton = ({ handleShowResetPasswordForm }) => {
  return (
    <div className="flex flex-wrap justify-between mt-8">
      <p className="text-temp-primary mb-2 text-sm lg:text-base">
        *************
      </p>
      <button
        className="text-temp-green-500 focus:outline-none mb-2 text-sm lg:text-base"
        onClick={handleShowResetPasswordForm}>
        Change Password
      </button>
    </div>
  );
};

const ChangePasswordForm = ({
  updatePasswordApi,
  handleShowResetPasswordForm,
}) => {
  const initialState = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.oldPassword) {
      errors.oldPassword = "Old Password is equired";
    } else if (values.oldPassword.length < 6) {
      errors.oldPassword = "Password too short i.e minimum of 6";
    }

    if (!values.newPassword) {
      errors.newPassword = "New password is required";
    } else if (values.newPassword.length < 6) {
      errors.newPassword = "New Password too short i.e minimum of 6";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Retype password";
    } else if (values.confirmPassword !== values.newPassword) {
      errors.confirmPassword = "Password does not match the new password";
    }

    return errors;
  };

  return (
    <div className="mt-8 w-full">
      <Formik
        initialValues={initialState}
        validate={validate}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          const payload = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          };

          await updatePasswordApi(payload);

          handleShowResetPasswordForm(false);
          setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="w-full">
              <InputGroup
                placeholder="Old Password"
                type="password"
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.oldPassword &&
                  touched.oldPassword &&
                  errors.oldPassword
                }
              />
            </div>
            <div className="w-full">
              <InputGroup
                placeholder="New Password"
                type="password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.newPassword &&
                  touched.newPassword &&
                  errors.newPassword
                }
              />
            </div>

            <div className="w-full">
              <InputGroup
                placeholder="Confirm New Password"
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.confirmPassword &&
                  touched.confirmPassword &&
                  errors.confirmPassword
                }
              />
            </div>

            {/* <PasswordStrengthStatus /> */}

            <div className="block relative mt-6 w-full text-right">
              <button
                type="submit"
                disabled={isSubmitting && true}
                className={`bg-temp-green-500 bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-lg text-temp-white text-sm font-bold px-8 py-3 ${
                  isSubmitting && "opacity-50"
                }`}>
                {isSubmitting ? "Updating... Please wait" : "Update"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
const Security = (props) => {
  const navigate = useNavigate();

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [logOutClick, setLogOutClick] = useState(false);
  const [lastLoginDetails, setLastLoginDetails] = useState({});

  const handleShowResetPasswordForm = () => {
    setShowResetPasswordForm(!showResetPasswordForm);
  };

  const handleLogout = () => {
    setLogOutClick(true);

    setTimeout(() => {
      setLogOutClick(false);
      store.remove("temp_usER12_dATa");
      store.remove("temp_access_token");

      // Signout from firebase
      const storedUid = store.get("tempOwnfiReBaseUserAuth");
      let parsedUid;

      try {
        parsedUid = JSON.parse(storedUid);
      } catch (error) {
        parsedUid = storedUid;
      }

      const firebaseUid = parsedUid?.userId || parsedUid?.uid;

      if (firebaseUid) {
        firebaseSignOutUser(firebaseUid);
      } else {
        console.warn("No valid Firebase UID found for sign out");
      }

      navigate("/");
    }, 1000);
  };

  useEffect(() => {
    scrollToTop();
    setLastLoginDetails(store.get("temp_usER12_dATa")?.lastLogin);
  }, []);

  return (
    <MainContainer hasMainContainer={false}>
      <div className="w-full pb-36 relative">
        <ProfileHeader title="Security" currentPageName="Security" />

        <div className="container mx-auto px-4 w-full md:w-10/12 lg:w-8/12 xl:w-6/12 mt-14 lg:mt-20">
          <p className="text-base lg:text-lg text-temp-primary font-medium">
            Your Password
          </p>

          {showResetPasswordForm ? (
            <ChangePasswordForm
              updatePasswordApi={props.updatePasswordApi}
              handleShowResetPasswordForm={handleShowResetPasswordForm}
            />
          ) : (
            <>
              <PasswordWithChangeButton
                handleShowResetPasswordForm={handleShowResetPasswordForm}
              />
              {/* <PasswordStrengthStatus /> */}
            </>
          )}

          {/* <hr className="my-10 border-t border-temp-green-light" />

          <div className="">
            <div className="w-full mt-8">
              <CustomSelectGroup
                label={
                  <p className="text-base lg:text-lg text-temp-primary font-bold">
                    Who can can send you message
                  </p>
                }
              />
            </div>
          </div> */}

          <hr className="my-10 border-t border-temp-green-light" />

          <div className="">
            <p className="text-base lg:text-lg text-temp-primary font-medium">
              Last Login
            </p>

            <div className="text-xs lg:text-sm text-temp-primary mt-4 flex flex-wrap justify-between mb-4">
              <div className="w-80 mb-4">
                <div className="flex flex-wrap justify-between">
                  <p className="pb-2">Device</p>
                  <p className="pb-2">
                    {capitalizeFistLetterInSentence(
                      lastLoginDetails?.deviceType
                    )}
                  </p>
                </div>

                <div className="flex flex-wrap justify-between">
                  <p className="pb-2">Location</p>
                  <p className="pb-2">{lastLoginDetails?.location}</p>
                </div>

                <div className="flex flex-wrap justify-between">
                  <p className="pb-2">Date and time</p>
                  <p className="pb-2">{lastLoginDetails?.dateAndTime}</p>
                </div>
              </div>
              <div className="">
                <button
                  onClick={handleLogout}
                  className="pb-2 text-red-600 focus:outline-none">
                  Logout
                </button>
              </div>
            </div>
          </div>

          <div className="block relative mt-8 w-full text-right">
            <button
              type="submit"
              className="bg-temp-green-500 bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-lg text-temp-white text-sm font-bold px-9 py-3">
              Save
            </button>
          </div>
        </div>
      </div>
      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}

      {logOutClick && (
        <LoadingModal
          title="Thanks for using the platform."
          content="Hope to see you very soon."
          show={logOutClick}
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, actions)(Security);
