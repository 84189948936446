import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const withdrawFundsApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());
  dispatch({
    type: Actions.INITIATE_PAYMENT_WITHDRAWAL,
    payload: "",
  });

  const url = `/payment/payout`;
  try {
    const response = await axios.post(url, payload);

    const responseData = response.data.data;

    dispatch({
      type: Actions.UPDATE_WALLETS_AFTER_WITHDRAWAL,
      payload: responseData,
    });

    dispatch({
      type: Actions.INITIATE_PAYMENT_WITHDRAWAL,
      payload: response.data?.message,
    });

    dispatch(alertActions.success(response.data?.message));
  } catch (error) {
    dispatch(alertActions.error(error.response.data.message));
  }
};

export const verifyPaymentApi = (payload) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/payment/verify`;
  try {
    await axios.post(url, payload);
    // dispatch(alertActions.success(response?.data?.message));

    return {
      data: {
        success: true
      }
    }
  } catch (error) {
    dispatch(alertActions.error(error?.response?.data?.message));
  }
};
