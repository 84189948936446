import React from "react";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import TextAreaGroup from "../../../../components/element/TextAreaGroup";

const RentalRequestDisapproveModal = ({
  show,
  handleCloseModal,
  orderDetails,
  disapprovalLoading,
  processDisapproval,
}) => {
  const initialState = {
    reason: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.reason) {
      errors.reason = "Kindly state the reason for declining the order.";
    }
    return errors;
  };

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center absolute bottom-0 lg:bottom-auto lg:relative bg-white rounded-t-2xl lg:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="text-left lg:text-center">
                <h3
                  className="leading-6 text-lg lg:text-xl xl:text-2xl font-medium text-red-600"
                  id="modal-headline"
                >
                  Decline Request
                </h3>
                <div className="mt-6 lg:mt-10">
                  <Formik
                    initialValues={initialState}
                    validate={validate}
                    onSubmit={async (values) => {
                      const data = {
                        note: values.reason,
                      };
                      await processDisapproval(orderDetails, data);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="w-full text-left">
                            <TextAreaGroup
                              showTip={false}
                              label="Tell us why you are declining this request"
                              labelColor="text-temp-gray"
                              name="reason"
                              value={values.reason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.reason}
                            />
                          </div>
                          <div className="text-xs lg:text-sm  font-light flex items-start justify-start text-left" style={{
                            color: "#F58539"
                          }}>
                            <svg className="h-6 w-6" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.9069 13.7933C3.2249 13.7933 0.240234 10.8086 0.240234 7.12663C0.240234 3.44463 3.2249 0.459961 6.9069 0.459961C10.5889 0.459961 13.5736 3.44463 13.5736 7.12663C13.5736 10.8086 10.5889 13.7933 6.9069 13.7933ZM6.9069 12.46C8.32139 12.46 9.67794 11.8981 10.6781 10.8979C11.6783 9.89767 12.2402 8.54112 12.2402 7.12663C12.2402 5.71214 11.6783 4.35559 10.6781 3.35539C9.67794 2.3552 8.32139 1.79329 6.9069 1.79329C5.49241 1.79329 4.13586 2.3552 3.13566 3.35539C2.13547 4.35559 1.57357 5.71214 1.57357 7.12663C1.57357 8.54112 2.13547 9.89767 3.13566 10.8979C4.13586 11.8981 5.49241 12.46 6.9069 12.46ZM6.24023 3.79329H7.57357V5.12663H6.24023V3.79329ZM6.24023 6.45996H7.57357V10.46H6.24023V6.45996Z" fill="#F58539" />
                            </svg>

                            <span className="pl-2">
                              Declining requests multiple times might lead to temporary deactivation of your account
                            </span>
                          </div>
                        </div>

                        <div className="mt-2 flex flex-wrap items-baseline justify-end flex-end">
                          <button
                            onClick={handleCloseModal}
                            className="order-2 lg:order-1 w-full lg:w-auto text-xs lg:text-sm font-bold text-temp-green-500 lg:mr-8 mb-4 focus:outline-none"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            disabled={disapprovalLoading}
                            className={`order-1 lg:order-2 w-full lg:w-auto text-xs lg:text-sm mt-5 mb-8 lg:mb-4 py-4 px-10 bg-danger-gradient font-bold text-white rounded-xl focus:outline-none ${disapprovalLoading && "opacity-70"
                              }`}
                          >
                            {disapprovalLoading
                              ? "Declining Order..."
                              : "Decline"}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalRequestDisapproveModal;
