import React from "react";
import InputWithSuffixPrefixGroup from "../../../components/element/InputWithSuffixPrefixGroup";
import SearchIcon from "../../../assets/images/svg/search/search.svg"

const SearchInputSection = ({ className, searchInput, onChange, handleSubmit }) => {
  return (
    <form className={className || "mx-auto w-full  md:w-11/12 lg:w-7/12 xl:w-5/12 relative pt-6 text-left md:pt-8 lg:pt-12 flex flex-wrap"}>
      <div className="relative flex-1">
        <InputWithSuffixPrefixGroup
          prefixIcon={SearchIcon}
          onClick={handleSubmit}
          buttonType="submit"
          placeholder="What do you want to rent today?"
          type="text"
          name="searchInput"
          value={searchInput}
          onChange={onChange}
          btnText="Search"
        />
      </div>
    </form>
  );
};

export default SearchInputSection;
