import React, { useEffect, useState } from "react";
import MainContainer from "../../components/layout/MainContainer";
import ProfileHeader from "./components/ProfileHeader"

import {
  AlarmIcon,
  ArrowRightIcon,
  EmissionIcon,
  GiftIcon,
  LockIcon,
  MapIcon,
  MoneyIcon,
  UserIcon,
} from "../../components/svgs";
import { Link } from "react-router-dom";

import store from "store";
import ProfileUserDetails from "./components/ProfileUserDetails";

const ProfileMain = () => {
  const data = [
    {
      id: 1,
      name: "Account Information",
      icon: <UserIcon />,
      url: "/profile/account-information",
    },
    {
      id: 2,
      name: "Cards and Bank",
      icon: <MoneyIcon />,
      url: "/profile/cards-and-banks",
    },
    {
      id: 3,
      name: "Security",
      icon: <LockIcon />,
      url: "/profile/security",
    },
    {
      id: 4,
      name: "Notification",
      icon: <AlarmIcon />,
      url: "/profile/notification-settings",
    },
    {
      id: 5,
      name: "Address",
      icon: <MapIcon width="26" height="26" />,
      url: "/profile/address",
    },
    {
      id: 6,
      name: "Refer",
      icon: <GiftIcon />,
      url: "/refer",
    },
    {
      id: 7,
      name: "Emision Saving",
      icon: <EmissionIcon />,
      url: "/profile/emission-savings",
    },
  ];
  const userData = store.get("temp_usER12_dATa");
  const [user, setUser] = useState(userData);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  const QuickLink = ({ name, url, icon }) => {
    return (
      <>
        <Link
          to={url}
          className="w-full bg-temp-green-100 px-6 py-4 rounded-xl flex flex-wrap justify-between items-center"
        >
          <div className="flex-shrink flex flex-wrap items-center">
            <div className="pr-4">{icon}</div>
            <div className="">
              <p className="text-temp-primary text-xs lg:text-sm">{name}</p>
            </div>
          </div>

          <div className="pl-2 flex-shrink">
            <ArrowRightIcon />
          </div>
        </Link>
      </>
    );
  };
  return (
    <MainContainer hasMainContainer={false}>
      <div className="w-full pb-36 relative">
        <ProfileHeader title="Profile" />

        <div className="container mx-auto px-4 w-full md:w-10/12 lg:w-8/12 xl:w-6/12 mt-14 lg:mt-20">
          <ProfileUserDetails user={user} />

          <div className="mt-10 w-full relative">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
              {data.map((d) => (
                <QuickLink key={d.id} name={d.name} icon={d.icon} url={d.url} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ProfileMain;
