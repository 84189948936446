import axios from "../../axios/axios";
import { alertActions } from "./alertActions";
import Actions from "./actionTypes";

export const updateNotificationPrefApi = async (payload) => {
  const url = "/notifications/preferences";
  try {
    const response = await axios.post(url, payload);
    return response.data.success;
  } catch (error) {
    console.error(error.response?.data.message);
  }
};

export const getNotificationPrefApi = async () => {
  const url = "/notifications/preferences";
  try {
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    console.error(error.response?.data.message);
  }
};

export const getUserNotificationsApi = (params) => async (dispatch) => {
  dispatch(alertActions.clear());

  const url = `/notifications`;
  try {
    const response = await axios.get(url, {
      params,
    });
    const responseData = response.data.data.notifications;
    if (response.data.success) {
      dispatch({
        type: Actions.GET_NOTIFICATIONS,
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch(alertActions.error(error?.response?.data?.message));
  }
};

export const markUserNotificationsApi = (notificationId) => async (
  dispatch
) => {
  dispatch(alertActions.clear());

  const url = `/notifications/${notificationId}/mark?seen=true`;
  try {
    const response = await axios.get(url);
    await getUserNotificationsApi();

    dispatch(alertActions.success(response?.data.message));
  } catch (error) {
    dispatch(alertActions.error(error.response?.data.message));
  }
};
