import React from "react";
import UserActivityImage from "../../../assets/images/svg/useractivity.svg";
import BrowseOtherCategories from "../../../components/BrowseOtherCategories";

const EmptyActivities = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="my-16 relative w-full flex justify-center">
        <div className="mx-auto text-center">
          <img src={UserActivityImage} alt="" className="mx-auto" />

          <p className="py-8 text-temp-primary text-sm lg:text-base">
            No activities to show. Book an item now.
          </p>
        </div>
      </div>

      <BrowseOtherCategories />
    </div>
  );
};

export default EmptyActivities;
