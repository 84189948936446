import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CookieNoticePopup = ({ onClick, hide }) => {
  const [slideIn, setSlideIn] = useState("");

  useEffect(() => {
    setTimeout(() => setSlideIn("tY-0"), 200);
  }, []);

  return (
    <div
      className={`${hide ? "hide-popup-bottom" : ""} tY-bottom-100 ${slideIn}
      animate-close st-bottom box-border bg-temp-primary flex flex-row items-center justify-between mx-auto h-auto w-full py-4 text-white px-4 z-30`}
    >
      <div className="flex-1 pl-4 md:px-0">
        <p className=" md:text-center text-xs md:text-base font-sans font-light tracking-wide">
          We use cookies 🍪🍪.{" "}
          <span>
            <Link className="text-temp-green-light" to="/policy/privacy">
              Learn how cookies
            </Link>{" "}
            help us remember your account settings
          </span>
        </p>
      </div>
      <div className="pointer flex-shrink-0 px-4" onClick={onClick}>
        <p className="text-3xl text-temp-green-light">x</p>
      </div>
    </div>
  );
};

export default CookieNoticePopup;
