import React from "react";

const ItemChargesRow = ({ name, value, hasSwitch }) => {
  return (
    <div className="w-full flex flex-1 justify-between mb-4 text-sm lg:text-sm-15 xl:text-base text-temp-gray">
      <p className="inline-flex">
        {name}{" "}
        {hasSwitch && (
          <div className="ml-4">
            <label className="toggle" htmlFor={name}>
              <input
                className="toggle__input"
                name=""
                type="checkbox"
                id={name}
              />
              <div className="toggle__fill"></div>
            </label>
          </div>
        )}
      </p>
      <p className="w-4/12">{value}</p>
    </div>
  );
};

export default ItemChargesRow;
