import React from 'react';
import { Transition } from "@headlessui/react";
import { CheckMarkCircleIcon, MoreIcon } from '../../../components/svgs';
import Loader from '../../../components/blocks/Loader';
import MapIcon from "../../../assets/images/svg/map.svg";

const ButtonWithOptions = ({
    id,
    selectedAddress,
    handleToggleShow,
    toggleShow,
    handleShowEditModal,
    handleShowDeleteModal,
}) => {
    return (
        <button className="focus:outline-none" onClick={handleToggleShow}>
            <MoreIcon />

            {selectedAddress === id && (
                <Transition
                    show={toggleShow}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="absolute w-32 top-6 right-2 bg-temp-white  custom-select-list rounded-xl">
                        <ul className="space-y-3 py-5 px-5 text-xs text-temp-primary cursor-pointer text-left">
                            <li>
                                <button
                                    className="focus:outline-none"
                                    onClick={handleShowEditModal}
                                >
                                    Edit Address
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={handleShowDeleteModal}
                                    className="text-red-600 focus:outline-none"
                                >
                                    Delete Address
                                </button>
                            </li>
                        </ul>
                    </div>
                </Transition>
            )}
        </button>
    );
};

const AddressDetails = ({
    loadingAddresses,
    handleAddAddress,
    handleToggleShow,
    toggleShow,
    addresses,
    handleShowEditModal,
    handleShowDeleteModal,
    handleMakeDefaultAddress,
    selectedAddress,
}) => {
    return (
        <>
            <div className="my-16 relative w-full">
                {loadingAddresses ? (
                    <div className="w-full">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
                            {addresses
                                ?.sort((a) => {
                                    if (a.isDefault === true) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                })
                                .map((a) => (
                                    <div
                                        key={a._id}
                                        className={`inline-flex flex-col justify-between pt-7 rounded-2xl ${a.isDefault
                                            ? "border border-temp-green-light"
                                            : "border-thin"
                                            }`}
                                    >
                                        <div className="px-8 pb-8 lg:pb-10 relative flex justify-between items-start">
                                            <p className="flex-shrink w-10/12">
                                                {" "}
                                                <img src={MapIcon} className="h-7 lg:h-10" alt="" />
                                            </p>
                                            <ButtonWithOptions
                                                id={a._id}
                                                selectedAddress={selectedAddress}
                                                handleToggleShow={() => handleToggleShow(a?._id)}
                                                toggleShow={toggleShow}
                                                handleShowEditModal={handleShowEditModal}
                                                handleShowDeleteModal={() => handleShowDeleteModal(a?._id)}
                                            />
                                        </div>

                                        <p className="px-8 text-temp-primary pb-6 lg:pb-8 text-sm lg:text-sm-15 leading-6 w-10/12">
                                            {a.address}
                                            <br />
                                            {a.country}
                                        </p>

                                        <div
                                            className={`h-12 py-3 pr-8 w-full relative ${a.isDefault
                                                ? "border-t border-temp-green-light"
                                                : "border-thin-top"
                                                }`}
                                        >
                                            {a.isDefault ? (
                                                <button className="w-full text-left inline-flex justify-between items-center focus:outline-none">
                                                    <p className="text-temp-green-500 text-xs lg:text-sm px-8 flex-grow">
                                                        Default Address
                                                    </p>
                                                    <CheckMarkCircleIcon />
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => handleMakeDefaultAddress(a)}
                                                    className="w-full text-left inline-flex justify-between items-center focus:outline-none"
                                                >
                                                    <p className="text-temp-gray text-sm px-8 flex-grow">
                                                        Make Default
                                                    </p>
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="8"
                                                            cy="8"
                                                            r="7.5"
                                                            fill="white"
                                                            stroke="#616564"
                                                        />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="w-full mx-auto text-center mt-20">
                            <button
                                onClick={handleAddAddress}
                                className="text-xs lg:text-sm text-center mx-auto bg-temp-green-500 bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white font-bold px-8 py-4 focus:outline-none"
                            >
                                Add Address
                            </button>
                        </div>
                    </>
                )}
            </div>

        </>
    );
};

export default AddressDetails;
