import React from "react";

import ChatHeader from "./ChatHeader";
import ChatMessages from "./ChatMessages";
import ChatTitleWithOption from "./ChatTitleWithOption";
import ChatUsers from "./ChatUsers";

const Conversation = ({
  users,
  conversationList,
  initializeChatWithUser,
  seletectedChatUser,
  handleChange,
  handleSubmit,
  message,
  conversations,
  currentUserUid,
  formattedConversationsByDay,
}) => {
  return (
    <div className="mt-10 w-full relative">
      <div className="w-full flex flex-row flex-wrap items-start">
        <div className="w-full lg:w-5/12 xl:w-4/12 chat-conversation-list rounded-xl">
          <ChatTitleWithOption />
          <ChatUsers
            users={users}
            conversationList={conversationList}
            conversations={conversations}
            initializeChatWithUser={initializeChatWithUser}
          />
        </div>
        <div className="relative w-full lg:w-7/12 xl:w-8/12 lg:px-6 mt-8 lg:mt-0">
          <div className="chat-conversation-body rounded-xl">
          <ChatHeader seletectedChatUser={seletectedChatUser} />
          <ChatMessages
            seletectedChatUser={seletectedChatUser}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            message={message}
            formattedConversationsByDay={formattedConversationsByDay}
            currentUserUid={currentUserUid}
          />
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Conversation;
