import React from "react";
import { Transition } from "@headlessui/react";
import InputGroup2 from "../../../components/element/InputGroup2";

const VoucherFormMobile = ({
    show,
    setShowForm,
    voucher,
    error,
    setError,
    setVoucher,
    setResponseStatus,
    itemAvailability,
    loading,
  handleSubmit,
}) => {
  return (
    <div className="fixed z-20 inset-0 overflow-y-auto block lg:hidden">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="absolute bottom-0 left-0 align-bottom  lg:inline-block lg:align-center bg-white rounded-t-3xl lg:rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-full sm:max-w-lg sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 lg:px-10 pt-2 lg:pt-8 pb-10">
            <div className="">
              <div className="pb-6 w-full mx-auto"></div>
              <div className="">
                <h3
                  className={`text-left leading-6 text-lg lg:text-xl font-semibold text-temp-primary`}
                  id="modal-headline"
                >
                  Apply Voucher
                </h3>

                <div className={`mt-4 ${show && "mt-8"}`}>
                <form onSubmit={handleSubmit}>
            <div className="relative lg:flex flex-col">
              <div className="w-full">
                <InputGroup2
                  placeholder="Enter code"
                  placeholderColor="text-temp-gray"
                  name="voucher"
                  value={voucher}
                  onChange={(e) => {
                    setError("");
                    setVoucher(e.target.value);
                  }}
                  error={error}
                />
              </div>
              <div className="">
                <button
                  disabled={
                    (voucher?.length === 0 ||
                      itemAvailability?.summary?.total === 0) &&
                    true
                  }
                  className={` mt-2 
      font-semibold w-full lg:w-4/12 py-4 bg-gradient text-white rounded-xl text-sm lg:text-sm-15 focus:outline-none
       ${(voucher?.length === 0 || loading) && "opacity-75"}
      `}
                >
                  {loading ? "Loading..." : "Apply"}
                </button>

                <button
                  onClick={() => {
                    setVoucher("");
                    setError("");
                    setShowForm(false);
                    setResponseStatus("");
                  }}
                  className="mt-4 font-semibold h-12 w-full lg:w-4/12 lg:h-12 text-red-600 rounded-xl text-sm lg:text-sm-15 focus:outline-none"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherFormMobile;
