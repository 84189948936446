import React from "react";

const IncreaseOrDecreaseButton = ({ title, onClick, disabled }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${
        disabled ? "text-gray-300" : "text-temp-gray"
      } w-8 h-8 text-xl focus:outline-none`}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default IncreaseOrDecreaseButton;
