import React from "react";
import BrowseOtherCategories from "../../../components/BrowseOtherCategories";

const EmptySearchResult = ({ keyword }) => {

  return (
    <div>
      <div className="mt-8 md:mt-10 mb-20 md:mb-28 w-full relative text-center">
        <h2 className="text-temp-primary text-lg md:text-lg lg:text-xl xl:text-2xl mb-2 md:mb-4">
          Search result
        </h2>
        <p className="text-temp-primary text-sm md:text-sm-15 lg:text-base xl:text-lg">
          No Results Found For{" "}
          <strong className="text-temp-green-500">&quot;{keyword}&quot;</strong>
        </p>
      </div>

      <div className="text-center mb-24 lg:mb-32 relative">
        <BrowseOtherCategories />
      </div>
    </div>
  );
};

export default EmptySearchResult;
