import React from "react";

export const FailedIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4046 25.8091C19.2554 25.8091 24.8091 20.2554 24.8091 13.4046C24.8091 6.55371 19.2554 1 12.4046 1C5.55371 1 0 6.55371 0 13.4046C0 20.2554 5.55371 25.8091 12.4046 25.8091Z"
        fill="#E74C4C"
      />
      <path
        d="M12.4051 23.3409C17.8927 23.3409 22.3414 18.8922 22.3414 13.4046C22.3414 7.91689 17.8927 3.46826 12.4051 3.46826C6.91738 3.46826 2.46875 7.91689 2.46875 13.4046C2.46875 18.8922 6.91738 23.3409 12.4051 23.3409Z"
        fill="#E7F8FC"
      />
      <path
        d="M15.038 19C14.906 18.736 14.744 18.442 14.552 18.118C14.36 17.794 14.15 17.464 13.922 17.128C13.694 16.78 13.46 16.438 13.22 16.102C12.98 15.766 12.746 15.448 12.518 15.148C12.29 15.448 12.056 15.772 11.816 16.12C11.576 16.456 11.342 16.798 11.114 17.146C10.898 17.482 10.694 17.812 10.502 18.136C10.31 18.46 10.148 18.748 10.016 19H8.288C8.708 18.184 9.206 17.356 9.782 16.516C10.37 15.664 10.952 14.854 11.528 14.086L8.432 9.64H10.322L12.554 12.916L14.804 9.64H16.586L13.562 13.978C14.138 14.758 14.726 15.586 15.326 16.462C15.938 17.326 16.454 18.172 16.874 19H15.038Z"
        fill="#D61313"
      />
    </svg>
  );
};
