import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthFooterCompany from "../../../components/blocks/authContainer/AuthFooterCompany";
import MainAuthContainer from "../../../components/blocks/authContainer/MainAuthContainer";
import CheckBoxGroup from "../../../components/element/CheckBoxGroup";
import InputGroup from "../../../components/element/InputGroup";
import InputPasswordGroup from "../../../components/element/InputPasswordGroup";
import InputPhoneNumberGroup from "../../../components/element/InputPhoneNumberGroup";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";

const initialState = {
  firstname: "",
  lastname: "",
  phone: "",
  password: "",
  confirmPassword: "",
  isAgreed: false,
};

const Signup = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [referralCode, setReferralCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "Required";
    } else if (values.firstname.length < 1) {
      errors.firstName = "Must be a valid name";
    }

    if (!values.lastname) {
      errors.lastname = "Required";
    } else if (values.lastname.length < 1) {
      errors.lastname = "Must be a valid name";
    }
    if (!values.phone) {
      errors.phone = "Required";
    } else if (values.phone?.length < 8) {
      errors.phone =
        "Invalid phone number format try this format +234 81x xxx xxx x";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Password too short i.e minimum of 6";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = "Password does not match";
    }
    if (!values.isAgreed) {
      errors.isAgreed = "Required";
    }
    return errors;
  };

  const handleDisable = (values, isSubmitting) => {
    return (
      isSubmitting ||
      values.firstname?.length === 0 ||
      values.lastname?.length === 0 ||
      values.phone?.length === 0 ||
      values.password?.length === 0
    );
  };

  useEffect(() => {
    const parsed = queryString.parse(props?.location?.search);
    setReferralCode(parsed?.referralCode);
  }, []);

  return (
    <MainAuthContainer>
      <Formik
        initialValues={initialState}
        validate={validate}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setIsLoading(true);

          const payload = {
            firstname: values.firstname,
            lastname: values.lastname,
            password: values.password,
            phone:
              "+" +
              (values?.phone?.startsWith("2340")
                ? values?.phone?.replace("2340", "234")
                : values?.phone),
          };

          const param = {
            refCode: referralCode,
          };

          // Signup APi call
          await props.signupApi(payload, navigate, param);

          setIsLoading(false);
          setSubmitting(false);

          if (props.alert.type === "success") {
            resetForm({ values: initialState });
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap justify-between">
              <div className="grid grid-cols-2 gap-4 lg:gap-6 w-full">
                <div className="w-full">
                  <InputGroup
                    label="First Name"
                    placeholder="First Name"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.firstname && touched.firstname && errors.firstname
                    }
                  />
                </div>

                <div className="w-full">
                  <InputGroup
                    label="Last Name"
                    placeholder="Last Name"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.lastname && touched.lastname && errors.lastname
                    }
                  />
                </div>
              </div>

              <div className="w-full">
                <InputPhoneNumberGroup
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="phone"
                  value={values.phone}
                  onChange={(e) => {
                    setFieldValue("phone", e);
                  }}
                  onBlur={handleBlur}
                  error={values.phone === "" ? undefined : errors.phone}
                />
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 w-full">
                <div className="w-full">
                  <InputPasswordGroup
                    label="Password"
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.password && touched.password && errors.password
                    }
                  />
                </div>

                <div className="w-full">
                  <InputPasswordGroup
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                  />
                </div>
              </div>
            </div>

            <div className="w-full">
              <CheckBoxGroup
                label={
                  <span className="text-xs pl-8 relative">
                    I acknowledge that I have read and do hereby accept the{" "}
                    <Link
                      to="/terms-of-service"
                      className="underline text-temp-secondary"
                      target="_blank">
                      terms and conditions
                    </Link>
                  </span>
                }
                name="isAgreed"
                value={values.isAgreed}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.isAgreed && touched.isAgreed && errors.isAgreed}
              />
            </div>

            <div className="w-full flex flex-row justify-end mt-8 lg:mt-8">
              <button
                type="submit"
                disabled={handleDisable(values, isSubmitting)}
                className={`font-bold block w-full lg:w-auto h-12 lg:h-14 px-14 text-white rounded-xl focus:outline-none text-sm-15 lg:text-base ${
                  handleDisable(values, isSubmitting)
                    ? "bg-temp-green-500 opacity-60"
                    : "bg-gradient"
                }
              `}>
                Get Started
              </button>
            </div>
          </form>
        )}
      </Formik>

      <div className="mt-10 lg:mt-16 mb-7">
        <div className="flex flex-wrap justify-center">
          <p className="text-temp-gray text-sm lg:text-base">
            Have an account?{" "}
            <span className="text-temp-green-500 ">
              <Link to="/signin">Sign In</Link>
            </span>
          </p>
        </div>
      </div>

      <div className="mt-20 block lg:hidden">
        <AuthFooterCompany />
      </div>

      {/* Trigger modal after form submission */}
      {isLoading && (
        <LoadingModal show={isLoading} title="Creating Account ..." />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </MainAuthContainer>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, actions)(Signup);
