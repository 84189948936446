import React from "react";
import { Transition } from "@headlessui/react";

const LoadingModal = (props) => {
  return (
    <div className="fixed z-40 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={props.show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="text-center">
              <div className="py-6 pb-10 w-full mx-auto">
                <div className="loading-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <h3
                  className="leading-6 text-2xl md:text-3xl font-medium text-temp-secondary"
                  id="modal-headline"
                >
                  {props.title || "Processing ..."}
                </h3>
                <div className="my-5">
                  <p className="text-sm md:text-base font-sans text-temp-gray">
                    {props.content ||
                      "Please wait a while for this information to be processed"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingModal;
