import React from "react";

const OurMissionSection = () => {
  return (
    <div className="bg-temp-green-100">
      <div className="container mx-auto px-4">
        <div className="py-20 lg:py-32">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-start">
            <div className="w-full md:w-8/12 lg:w-9/12 mx-auto md:mx-0 lg:mx-auto">
              <h3 className="text-temp-green-500 font-semibold text-3xl lg:text-4xl xl:text-5xl xl:leading-tight pb-2 md:pb-2 lg:pb-6">
                Our rental platform helps people own anything without hassle.
              </h3>
            </div>
            <div className="w-full md:w-10/12 text-temp-primary mt-2">
              <p className="text-sm-15 md:text-base lg:text-lg lg:leading-8">
                We are Africa&apos;s first fully insured and secure go-to-source
                for equipment rental solutions. Tempown connects individuals,
                creatives and project executives to thousands of equipment
                renting owners. The core and spirit of TempOwn is about one
                thing and one thing only;{" "}
                <span className="font-semibold">
                  &quot;Bridging the accessibility gap.&quot;
                </span>
                <br /> <br />
                TempOwn ensures strict compliance of users to its policies and
                you do not have to worry about fraud. You and your items are
                safe with us. Why not get started on TempOwn to work with the
                right tools and reach more secure users?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMissionSection;
