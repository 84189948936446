import React from "react";

export const ChatIcon = ({width, height}) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0938 3.1875H3.71875C3.4255 3.1875 3.1875 3.4255 3.1875 3.71875C3.1875 4.012 3.4255 4.25 3.71875 4.25H10.0938C10.387 4.25 10.625 4.012 10.625 3.71875C10.625 3.4255 10.387 3.1875 10.0938 3.1875Z"
        fill="white"
      />
      <path
        d="M7.96875 5.3125H3.71875C3.4255 5.3125 3.1875 5.5505 3.1875 5.84375C3.1875 6.137 3.4255 6.375 3.71875 6.375H7.96875C8.262 6.375 8.5 6.137 8.5 5.84375C8.5 5.5505 8.262 5.3125 7.96875 5.3125Z"
        fill="white"
      />
      <path
        d="M11.6875 0H2.125C0.953062 0 0 0.953062 0 2.125V12.75C0 12.9561 0.119 13.1442 0.306 13.2313C0.377187 13.2642 0.45475 13.2812 0.53125 13.2812C0.653437 13.2812 0.774562 13.2387 0.87125 13.158L3.91106 10.625H11.6875C12.8594 10.625 13.8125 9.67194 13.8125 8.5V2.125C13.8125 0.953062 12.8594 0 11.6875 0ZM12.75 8.5C12.75 9.08544 12.274 9.5625 11.6875 9.5625H3.71875C3.59444 9.5625 3.47438 9.60606 3.37875 9.68575L1.0625 11.6163V2.125C1.0625 1.53956 1.5385 1.0625 2.125 1.0625H11.6875C12.274 1.0625 12.75 1.53956 12.75 2.125V8.5Z"
        fill="white"
      />
      <path
        d="M14.875 4.25C14.5817 4.25 14.3438 4.488 14.3438 4.78125C14.3438 5.0745 14.5817 5.3125 14.875 5.3125C15.4615 5.3125 15.9375 5.78956 15.9375 6.375V15.3627L14.144 13.9283C14.0505 13.8539 13.9326 13.8125 13.8125 13.8125H6.375C5.7885 13.8125 5.3125 13.3354 5.3125 12.75V12.2188C5.3125 11.9255 5.0745 11.6875 4.78125 11.6875C4.488 11.6875 4.25 11.9255 4.25 12.2188V12.75C4.25 13.9219 5.20306 14.875 6.375 14.875H13.6255L16.1362 16.8842C16.2329 16.9607 16.3508 17 16.4688 17C16.5463 17 16.6249 16.983 16.6993 16.9479C16.8831 16.8587 17 16.6728 17 16.4688V6.375C17 5.20306 16.0469 4.25 14.875 4.25Z"
        fill="white"
      />
    </svg>
  );
};
