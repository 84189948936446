import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

const AddressInputAutoComplete = ({
    error,
    label,
    onPlaceSelected,
    placeholder,
    name,
    value,
    onChange
}) => {
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
        onPlaceSelected: onPlaceSelected,
        options: {
            types: "regions",
        },
    })

    return <div className="mb-6">
        <div className="relative input input-field w-full mb-2">
            <input
                ref={ref}
                value={value}
                className={`text-temp-primary block px-5 h-14 w-full rounded-lg border bg-white placeholder-temp-gray focus:outline-none text-sm md:text-base ${error ? "border-red-600" : " border-temp-gray-light"
                    }`}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
            />
            <label className="text-temp-secondary input-label">{label}</label>
        </div>
        <span className="text-red-600 text-sm">{error}</span>
    </div>
};

export default AddressInputAutoComplete;
