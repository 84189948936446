import React from 'react'
import InputFileGroup from '../../../components/element/InputFileGroup'

const UploadImagesForm = ({
    orderDetails,
    displayImages,
    handleImageChange,
    handleRemoveImage,
    handleProceed,
    handleAddMoreImage }) => {
    return (
        <>
            <div className="pt-4 relative w-full lg:w-10/12">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-1 lg:gap-x-4 gap-y-6 lg:gap-y-12 mt-5">
                    {
                        displayImages?.map((a, index) => <InputFileGroup
                            key={index}
                            preview={a}
                            onDrop={(e) => handleImageChange(e, index)}
                            accept="image/*"
                            onDeleteClick={() => handleRemoveImage(index)}
                            marginBottom="mb-0"
                        />)
                    }
                </div>

                {
                    (orderDetails?.quantity > 1 && ((displayImages?.length < orderDetails?.quantity * 4))) ? <div className='mt-8'>
                        <button className='text-temp-primary focus:outline-none text-sm-15' onClick={handleAddMoreImage}>
                            + Add more image
                        </button>
                    </div> : ""
                }



                <div className="group w-full inline-flex flex-row flex-wrap justify-end text-right pt-12 pb-32 lg:pb-0 space-x-4 pr-4">
                    <button
                        onClick={handleProceed}
                        disabled={displayImages?.filter(a => a !== "").length < 4 && true}
                        className={`w-full lg:w-auto font-bold text-xs lg:text-sm h-12 px-14 text-white rounded-lg focus:outline-none
                            ${displayImages?.filter(a => a !== "").length >= 4 ? " bg-temp-green-500" : " bg-temp-disabled"}
                        `}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default UploadImagesForm
