import { combineReducers } from "redux";
import AddressReducer from "./AddressReducer";
import AlertReducer from "./AlertReducer";
import AuthReducer from "./AuthReducer";
import BankReducer from "./BankReducer";
import CategoryReducer from "./CategoryReducer";
import ContactReducer from "./ContactReducer";
import ItemReducer from "./ItemReducer";
import OrderReducer from "./OrderReducer";
import PaymentReducer from "./PaymentReducer";
import ReviewReducer from "./ReviewReducer";
import TransactionReducer from "./TransactionReducer";
import UserReducer from "./UserReducer";
import WalletReducer from "./WalletReducer";
import VisitReducer from "./VisitReducer";
import EmissionReducer from "./EmissionReducer";
import NotificationReducer from "./NotificationReducer";
import ReferralReducer from "./ReferralReducer";
import ChatReducer from "./ChatReducer";
import LateRentalReducer from "./LateRentalReducer";

export default combineReducers({
  auth: AuthReducer,
  alert: AlertReducer,
  users: UserReducer,
  banks: BankReducer,
  transactions: TransactionReducer,
  wallets: WalletReducer,
  payments: PaymentReducer,
  items: ItemReducer,
  category: CategoryReducer,
  address: AddressReducer,
  contact: ContactReducer,
  order: OrderReducer,
  review: ReviewReducer,
  visit: VisitReducer,
  emissions: EmissionReducer,
  notifications: NotificationReducer,
  referral: ReferralReducer,
  chat: ChatReducer,
  lateReturns: LateRentalReducer
});
