import React from "react";

export const MapBlackIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80903 8.41934L5.37621 11.8522L1.94338 8.41934C1.26444 7.74039 0.80208 6.87536 0.614764 5.93364C0.427449 4.99192 0.523592 4.0158 0.891036 3.12872C1.25848 2.24164 1.88072 1.48344 2.67907 0.95C3.47743 0.416559 4.41604 0.131836 5.37621 0.131836C6.33638 0.131836 7.27498 0.416559 8.07334 0.95C8.87169 1.48344 9.49393 2.24164 9.86138 3.12872C10.2288 4.0158 10.325 4.99192 10.1376 5.93364C9.95033 6.87536 9.48797 7.74039 8.80903 8.41934ZM5.37621 6.06534C5.66233 6.06534 5.93673 5.95168 6.13905 5.74936C6.34137 5.54704 6.45503 5.27264 6.45503 4.98651C6.45503 4.70039 6.34137 4.42599 6.13905 4.22367C5.93673 4.02135 5.66233 3.90769 5.37621 3.90769C5.09008 3.90769 4.81568 4.02135 4.61336 4.22367C4.41104 4.42599 4.29738 4.70039 4.29738 4.98651C4.29738 5.27264 4.41104 5.54704 4.61336 5.74936C4.81568 5.95168 5.09008 6.06534 5.37621 6.06534Z"
        fill="#616564"
      />
    </svg>
  );
};
