import React from 'react';
import InputGroupWithPrefix from '../../element/InputGroupWithPrefix';

const InputDiscount = ({ title, name, value, handleChange, handleBlur }) => {
    return <InputGroupWithPrefix
        className="group w-full lg:w-7/12"
        label={<>
            Discount{" "}
            <span className="text-xs md:text-sm text-temp-primary">
                {title}
            </span>
        </>}
        prefixValue={"%"}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
    />

}

export default InputDiscount;
