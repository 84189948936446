import Actions from "../actions/actionTypes";

const initialState = {
  chatUsers: {},
  conversations: [],
  conversation: {},
  conversationsListWithMessage: [],
  sentChatMessage: {}
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_REALTIME_FIREBASE_CHAT_USERS:
      return {
        ...state,
        chatUsers: action.payload,
      };

    case Actions.GET_REALTIME_MESSAGES:
      return {
        ...state,
        conversations: action.payload,
      };

    case Actions.GET_REALTIME_MESSAGES_FOR_ALL_USERS:
      return {
        ...state,
        conversationsListWithMessage: action.payload,
      };

      case Actions.STORE_CHAT_SENT_MESSAGE: 
      return {
        ...state,
        sentChatMessage: action.payload
      }

    default:
      return state;
  }
};

export default ChatReducer;
