import React from "react";
import { Transition } from "@headlessui/react";

// import { DriveIcon } from "../../../../components/svgs";
import moment from "moment";
import RentalTimer from "../../../home/components/RentalTimer";

const DateDetails = ({ startDate, endDate, dayCount, unitOrdered, item }) => {
  return (
    <p className="text-xs md:text-sm lg:text-sm-15 text-white font-medium lg:font-semibold">
      {moment(startDate).format("MMMM D, YYYY")}  - {moment(endDate).format("MMMM D, YYYY")}
      ({" "}
      {item?.rentalUnitType === "bySiUnits"
        ? (unitOrdered || 1) + " " + (item?.siUnitType || "hour")
        : dayCount + " " + (item?.calenderType || "days")}
      )
    </p>
  );
};

const RentalRequestDetailsModal = ({
  show,
  handleShowDisapprove,
  handleCloseRequestModal,
  orderDetails,
  processApproval,
}) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-right sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block rounded-tl-3xl rounded-tr-3xl absolute  lg:relative bottom-0 align-bottom bg-white text-left overflow-auto shadow-xl transform transition-all h-5/6 lg:h-full lg:min-h-screen sm:max-w-2xl w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white">
            <div className="">
              <div className="lg:pb-3 w-full mx-auto"></div>
              <div className="">
                <div className="mt-4 px-6 lg:px-12 flex flex-row justify-between items-center">
                  <h3
                    className="mt-2 font-medium text-base lg:text-lg xl:text-xl text-temp-secondary"
                    id="modal-headline"
                  >
                    Rental Request Details
                  </h3>

                  <button
                    onClick={handleCloseRequestModal}
                    className="px-4 py-2 rounded-full bg-white shadow-xl text-temp-secondary focus:outline-none"
                  >
                    x
                  </button>
                </div>
                <div className="mt-6 lg:mt-12">
                  <RentalTimer rentalDetails show={true} />
                </div>

                <div className="rental-request-modal-daterange py-3 lg:py-4 px-6 lg:px-12 w-full">
                  {orderDetails?.extensions?.length > 0 ? (
                    <DateDetails
                      item={orderDetails?.itemId}
                      unitOrdered={orderDetails?.unitOrdered}
                      dayCount={
                        orderDetails?.extensions?.[
                          orderDetails?.extensions?.length - 1
                        ]?.dayCount
                      }
                      startDate={
                        orderDetails?.extensions?.[
                          orderDetails?.extensions?.length - 1
                        ]?.startDate
                      }
                      endDate={
                        orderDetails?.extensions?.[
                          orderDetails?.extensions?.length - 1
                        ]?.endDate
                      }
                    />
                  ) : (
                    <DateDetails
                      item={orderDetails?.itemId}
                      unitOrdered={orderDetails?.unitOrdered}
                      dayCount={orderDetails?.dayCount}
                      startDate={orderDetails?.startDate}
                      endDate={orderDetails?.endDate}
                    />
                  )}
                </div>

                <div className="px-6 lg:px-12 mt-10 mb-4 lg:mb-8 xl:mx-auto">
                  <div className="grid grid-cols-1 gap-2 lg:gap-2 xl:gap-2">
                    <div className="w-full mb-5">
                      <p className="uppercase text-temp-secondary tracking-widest text-sm lg:text-base">
                        ITEM
                      </p>

                      <div className="mt-2 md:mt-4 lg:mt-5 text-temp-primary">
                        <img
                          src={orderDetails?.itemId?.images[0]?.image?.url}
                          className="w-full h-40 lg:h-52 object-cover rounded-lg"
                          alt=""
                        />
                        <div className="mt-4">
                          <p className="font-medium text-sm-15 md:text-base lg:text-lg pb-2" style={{
                            color: "#484C32"
                          }}>
                            {orderDetails?.itemId?.name}
                          </p>

                          {/* <p className="flex items-center text-xs lg:text-sm">
                            <DriveIcon />{" "}
                            <span className="pl-2 lg:pl-4 lg:pt-2">
                              Operator needed
                            </span>
                          </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="w-full mb-2 lg:mb-5">
                      <p className="uppercase text-temp-secondary tracking-widest text-sm lg:text-base">
                        BORROWER
                      </p>
                      <div className="mt-2 md:mt-2 lg:mt-4 flex items-start text-temp-primary">
                        <img
                          src={orderDetails?.borrower?.image}
                          className="mr-4 lg:mr-6 w-14 h-12 md:w-16 md:h-16 lg:w-20 lg:h-16 rounded-lg object-cover"
                          alt=""
                        />
                        <div className="" style={{
                          color: "#484C32"
                        }}>
                          <p className="font-medium text-sm md:text-sm-15 lg:text-base pb-2">
                            {orderDetails?.borrower?.firstname}{" "}
                            {orderDetails?.borrower?.lastname}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-6 lg:px-12 mt-8 mb-6 lg:mb-20 grid grid-cols-2 gap-4 lg:gap-8">
                  <button
                    onClick={handleShowDisapprove}
                    className="font-bold text-xs lg:text-sm py-4 px-8 lg:px-14 rounded-xl focus:outline-none bg-red-600 text-white"
                  >
                    Decline
                  </button>
                  <button
                    onClick={processApproval}
                    className="font-bold text-xs lg:text-sm py-4 px-8 lg:px-14 text-white rounded-xl focus:outline-none bg-temp-green-500 bg-gradient"
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalRequestDetailsModal;
