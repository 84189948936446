import { Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthFooterCompany from "../../../components/blocks/authContainer/AuthFooterCompany";
import MainAuthContainer from "../../../components/blocks/authContainer/MainAuthContainer";
import InputGroup from "../../../components/element/InputGroup";
import InputPasswordGroup from "../../../components/element/InputPasswordGroup";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";

const Signin = (props) => {
  const initialState = {
    phoneOrEmail: "",
    password: "",
  };

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.phoneOrEmail) {
      errors.phoneOrEmail = "Required";
    } else if (values.phoneOrEmail?.length < 8) {
      errors.phoneOrEmail = "Invalid email or password";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Password too short i.e minimum of 6";
    }

    return errors;
  };

  return (
    <MainAuthContainer
      admin={props.admin}
      title="Sign In"
      subTitle={"Welcome back. We missed you"}
      className="pt-0  lg:pt-24 xl:pt-36">
      <Formik
        initialValues={initialState}
        validate={validate}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setIsLoading(true);

          const payload = {
            phoneOrEmail:
              values.phoneOrEmail.startsWith("0") ||
              values.phoneOrEmail.includes("234")
                ? (!values.phoneOrEmail.includes("+") ? "+" : "") +
                  (values?.phoneOrEmail?.startsWith("2340")
                    ? values?.phoneOrEmail?.replace("2340", "234")
                    : values?.phoneOrEmail?.startsWith("0")
                      ? values?.phoneOrEmail?.replace("0", "234")
                      : values?.phoneOrEmail)
                : values.phoneOrEmail,
            password: values.password,
          };

          await props.loginApi(payload, navigate);

          setIsLoading(false);
          setSubmitting(false);

          if (props.alert.type === "success") {
            resetForm({ values: initialState });
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap justify-between">
              <div className="w-full">
                <InputGroup
                  label="Email or Phone Number"
                  placeholder="Email or Phone Number"
                  name="phoneOrEmail"
                  value={values.phoneOrEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.phoneOrEmail &&
                    touched.phoneOrEmail &&
                    errors.phoneOrEmail
                  }
                />
              </div>

              <div className="w-full">
                <InputPasswordGroup
                  label="Password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password && errors.password}
                />
              </div>
            </div>

            <div className="">
              <Link
                className="text-temp-green-500 text-sm lg:text-base"
                to="/forgot-password">
                Forgot Password?
              </Link>
            </div>

            <div className="w-full text-right mt-12 lg:mt-8">
              <button
                type="submit"
                disabled={
                  isSubmitting ||
                  values.phoneOrEmail.length === 0 ||
                  values.password.length === 0
                }
                className={`font-bold w-full lg:w-auto h-12 lg:h-14 px-16 text-white rounded-xl focus:outline-none text-sm-15 lg:text-base ${
                  isSubmitting ||
                  values.phoneOrEmail.length === 0 ||
                  values.password.length === 0
                    ? "bg-temp-green-500 opacity-60"
                    : "bg-gradient"
                }
                            `}>
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>

      <div className="text-center pt-14 lg:pt-20 mb-7">
        <p className="text-temp-gray text-sm lg:text-base">
          New user?{" "}
          <Link to="/signup" className="text-temp-green-500">
            Sign Up
          </Link>
        </p>
      </div>

      <div className="mt-20 block lg:hidden">
        <AuthFooterCompany />
      </div>

      {/* Trigger modal after form submission */}
      {isLoading && <LoadingModal show={isLoading} />}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </MainAuthContainer>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, actions)(Signin);
