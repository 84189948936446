/* eslint-disable no-unused-vars */
import moment from "moment";
import React from "react";
import { numberFormat } from "../../../utils/numberFormat";
import ChargesItem from "./ChargesItem";
import AlertIcon from "../../../assets/images/svg/alarm.svg";
import store from "store";
import { PaystackButton } from "react-paystack";
import FixedBottomBox from "../../../components/blocks/FixedBottomBox";
import { Link } from "react-router-dom";
import VoucherForm from "./VoucherForm";
import ApplyReferral from "./ApplyReferral";
import { getRentalUnitType } from "../../../helpers/rentalFlexibilityHelper";

const SelectPickupLocation = ({
    name,
    value,
    title,
    subTitle,
    onChange,
    checked,
}) => {
    return (
        <label className="radio-label">
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <span className="styling mr-3"></span>
            <p className="text text-temp-gray">
                {title}
                <br />
                <span className="text-temp-green-500 text-xs">{subTitle}</span>
            </p>
        </label>
    );
};

const CheckoutButton = ({ itemAvailability, item, componentProps }) => {
    const user = store.get("temp_usER12_dATa");
    return (
        <>
            {!user?.verified_id ||
                !user?.verified_email ||
                !user?.verified_phone ||
                !user?.image ? (
                <div className="inline-flex space-x-4 items-center mt-6">
                    <img src={AlertIcon} alt="" width="20rem" />
                    <p className="text-red-600 text-xs lg:text-sm">
                        Account verification not complete. Click{" "}
                        <Link
                            to={
                                !user?.verified_id || !user?.image
                                    ? "/complete-verification"
                                    : "/verify-email"
                            }
                            className="font-bold"
                        >
                            here
                        </Link>{" "}
                        to complete verification and continue.
                    </p>
                </div>
            ) : user?._id === item?.userId?._id ? (
                <div className="inline-flex space-x-4 items-center mt-6">
                    <img src={AlertIcon} alt="" width="20rem" />
                    <p className="text-red-600 text-xs lg:text-sm">
                        You can&apos;t place order for the item you created.
                    </p>
                </div>
            ) : !itemAvailability?.available ? (
                <div className="inline-flex space-x-4 items-center mt-6">
                    <img src={AlertIcon} alt="" width="20rem" />
                    <p className="text-red-600 text-xs lg:text-sm">
                        You can&apos;t place order for the item because it is not available
                        at the moment. Kindly check back later.
                    </p>
                </div>
            ) : (
                <>
                    <PaystackButton
                        className="paystack-button bg-gradient mt-3 lg:mt-0 rounded-xl h-12 lg:h-14 font-sans"
                        {...componentProps}
                    />
                </>
            )}
        </>
    );
};

const ItemChargesAndCheckout = ({
    days,
    feePerDiscount,
    startDate,
    endDate,
    itemAvailability,
    item,
    formData,
    handleInputChange,
    componentProps,
    discountOn,
    discount,
    unitOrdered,
}) => {
    return (
        <>
            <div className="rounded-lg py-6 lg:py-8 mx-auto border checkout-card">
                <div className="flex justify-between flex-wrap px-6 pb-4">
                    <p className="text-base lg:text-lg xl:text-xl font-semibold text-temp-secondary">
                        Charges
                    </p>

                    <p className="text-temp-gray text-sm-15 lg:text-base xl:text-lg">
                        <span className="font-semibold">
                            ₦{numberFormat(feePerDiscount)}
                        </span>{" "}
                        <span className="text-sm lg:text-sm-15 xl:text-base">
                            /{" "}
                            {getRentalUnitType(item)}
                        </span>
                    </p>
                </div>

                <hr className="my-1 border-t border-temp-gray-light" />

                <div className="px-6 mt-4 lg:mt-6">
                    <div className="pb-1 lg:pb-4">
                        <ChargesItem
                            name="Base Fee"
                            value={`₦${numberFormat(itemAvailability?.summary?.baseFee)}`}
                            discount={discountOn === "base fee" && discount}
                            details={
                                <p className="text-temp-secondary text-xs lg:text-sm">
                                    {`₦${Intl.NumberFormat("en-US").format(
                                        item?.price?.amount
                                    )} x ${itemAvailability?.summary?.quantity} items x ${item?.rentalUnitType === "bySiUnits"
                                        ? unitOrdered + " " + (item?.siUnitType || "hour")
                                        : itemAvailability?.summary?.days +
                                        1 +
                                        " " +
                                        (item?.calenderType || "days")
                                        } `}
                                </p>
                            }
                        />

                        {itemAvailability?.summary?.discount !== 0 && (
                            <ChargesItem
                                name={`Discount Offer (${itemAvailability?.summary?.discount}% off)`}
                                value={`-₦${numberFormat(
                                    itemAvailability?.summary?.discountPrice
                                )}`}
                            />
                        )}

                        <ChargesItem
                            name="Service charge"
                            value={`₦${numberFormat(
                                itemAvailability?.summary?.serviceCharge
                            )}`}
                            discount={discountOn === "service charge" && discount}
                        />

                        <ChargesItem
                            name={
                                <span className="text-temp-secondary font-semibold">Total</span>
                            }
                            value={
                                <span className="text-temp-secondary font-semibold">{`₦${numberFormat(
                                    itemAvailability?.summary?.total
                                )}`}</span>
                            }
                            discount={discountOn === "total" && discount}
                        />
                    </div>
                </div>

                <hr className="my-1 border-t border-temp-gray-light" />

                <div className="px-6">
                    <ApplyReferral itemAvailability={itemAvailability} />
                    <VoucherForm
                        startDate={startDate}
                        endDate={endDate}
                        itemAvailability={itemAvailability}
                    />
                </div>

                <div className="px-6 mt-4">
                    <p className="text-sm lg:text-sm-15">Pick up and Delivery option</p>

                    <div className="mt-2">
                        <p className="text-xs lg:text-sm text-temp-gray">
                            Delivery & Pickup to Location
                        </p>
                        {/* <div className="group w-full mb-4 text-sm lg:text-base">
              <SelectPickupLocation
                title="Pickup from Location"
                name="deliveryOption"
                value="Pickup from Location"
                onChange={handleInputChange}
                checked={
                  formData.deliveryOption === "Pickup from Location"
                    ? true
                    : false
                }
              />
            </div> */}
                    </div>
                    {/* Desktop only */}
                    <div className="hidden lg:block mt-6">
                        <CheckoutButton
                            itemAvailability={itemAvailability}
                            item={item}
                            componentProps={componentProps}
                        />
                    </div>

                    {/* Mobile only */}
                    <div className="lg:hidden block">
                        <FixedBottomBox
                            title={<>
                                {/* <p className="">Rent this item now</p> */}
                                {/* <p className="text-lg">₦{numberFormat(
                  itemAvailability?.summary?.total
                )}</p> */}
                            </>}
                            isCheckoutButton={true}
                            checkoutButton={
                                <CheckoutButton
                                    itemAvailability={itemAvailability}
                                    item={item}
                                    componentProps={componentProps}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
            <p className="mt-5 text-temp-primary text-xs">
                <span className="font-semibold">Free cancellation:</span> {" "}
                Get full refund before {moment(itemAvailability?.summary?.startDate)?.add(6, 'h').format('MMM D, hh:mm A')}
            </p>
        </>
    );
};

export default ItemChargesAndCheckout;
