import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CheckMarkIcon } from "../../../components/svgs";
import { createNewsletterApi } from "../../../store/actions/newsletterActions";

import PhoneImage from "../../../assets/images/png/iphone.webp";
import InputWithSuffixPrefixGroup from "../../../components/element/InputWithSuffixPrefixGroup";

const GetUpdateSection = () => {
  const [message, setMessage] = useState({});
  const initialState = {
    email: "",
  };

  useEffect(() => {
    setMessage({});
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  };
  return (
    <section className="get-update-section py-20 lg:py-24 mb-4 lg:mb-10">
      <div className="container mx-auto px-4 mb-6 rounded-xl">
        <div className="relative px-4 md:px-6 lg:px-14 py-12 md:py-16 flex flex-row flex-wrap items-center justify-between">
          <div className="w-full md:w-6/12 lg:w-6/12">
            <h3 className="w-11/12 md:w-full lg:w-8/12 font-semibold lg:font-semibold text-3xl leading-9  md:text-4xl lg:text-4xl xl:text-5xl text-temp-primary pb-4">
              Get updates from{" "}
              <span className="text-temp-secondary">
                temp<span className="font-bold">own</span>
              </span>
            </h3>
            <p className="text-temp-gray tracking-wide text-sm md:text-base">
              Subscribe to our Newsletter to receive updates from us 📩
            </p>

            <Formik
              initialValues={initialState}
              validate={validate}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                const payload = {
                  email: values.email,
                };

                try {
                  const res = await createNewsletterApi(payload);
                  setMessage(res);
                  setSubmitting(false);
                  resetForm({
                    email: ""
                  })
                } catch (error) {
                  // setMessage(error);
                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
              }) => (
                <div className="mt-5 lg:mt-8">
                  <div className="mb-4 w-full lg:w-11/12">
                    <form
                      onSubmit={handleSubmit}
                    >
                      <InputWithSuffixPrefixGroup
                        type="email"
                        label="Email"
                        placeholder="Enter your email address"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email && errors.email}
                        disabled={isSubmitting || message?.type === "success"}
                        isSubmitting={isSubmitting || message?.type === "success"}
                        buttonType="submit"
                        onClick={handleSubmit}
                        btnText={
                          <>
                            {isSubmitting ? (
                              "Processing..."
                            ) : message?.type === "success" ? (
                              <p className="inline-flex items-center">
                                <span className="pr-3 pl-1 pt-1">Submitted</span>{" "}
                                <span>
                                  <CheckMarkIcon className="w-6 h-6" />
                                </span>
                              </p>
                            ) : (
                              "Submit"
                            )}
                          </>
                        }
                      />
                    </form>
                  </div>
                  <p className="text-xs text-red-600">{errors.email}</p>
                </div>
              )}
            </Formik>
          </div>

          <div className="w-full md:w-5/12 lg:w-5/12">
            <div className="relative w-10/12 lg:w-9/12 mx-auto mt-10 md:-mt-24 lg:-mt-40">
              <img src={PhoneImage} alt="Phone" />

              <div className="lg:pl-6 mt-6 lg:mt-10 flex flex-col lg:flex-row space-x-3 items-center">
                <p className="text-sm lg:text-sm-15 font-light">
                  Available soon on
                </p>

                <div className="flex flex-row space-x-3 mt-4 lg:-mt-1">
                  <svg
                    width="24"
                    height="28"
                    viewBox="0 0 24 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.984 14.9351C19.984 12.6455 21.2845 10.688 23.1304 9.86451C22.6166 9.23271 21.9276 8.63871 21.0003 8.01771C19.8003 7.21311 17.142 7.12131 15.3994 7.53981C14.0759 7.85571 12.9477 8.46591 12.3449 8.49291C11.7994 8.51991 10.6281 7.83411 9.29032 7.53981C7.67693 7.18341 5.91713 7.38321 4.70852 8.01771C1.19467 9.86451 0.631989 11.7518 0.631989 15.6506C0.631989 20.1434 2.36308 23.9531 6.23292 26.6261C7.08267 27.212 8.60994 27.5576 9.79845 27.104C10.8205 26.7125 11.3659 26.2346 12.3449 26.1509C13.7745 26.0294 14.4405 26.4803 15.1295 26.8826C15.7554 27.2471 17.1994 27.5414 17.9458 27.1067C20.5611 25.5839 22.6194 23.0945 23.7075 20.2217C21.5601 19.5737 19.984 17.4542 19.984 14.9351ZM16.9266 4.67781C17.9659 3.14421 17.9687 0.851907 17.9458 0.860007C17.5152 0.995007 16.0625 0.676407 13.8721 2.29101C13.1085 2.85531 12.1697 4.24311 11.8338 5.15301C11.5783 5.84421 11.3286 7.53711 11.3228 7.53981C11.297 7.54251 13.0023 7.45341 13.8692 7.06191C14.7592 6.66231 16.2549 5.67141 16.9266 4.67781Z"
                      fill="black"
                    />
                  </svg>
                  <svg
                    width="24"
                    height="26"
                    viewBox="0 0 24 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.04391 8.68805C1.5683 8.68805 1.16476 8.83826 0.833043 9.13869C0.501327 9.43911 0.335419 9.79962 0.335419 10.2204V16.6807C0.335419 17.112 0.501277 17.4771 0.833043 17.7776C1.16476 18.078 1.5683 18.2283 2.04391 18.2283C2.51918 18.2283 2.92027 18.0781 3.24685 17.7776C3.57277 17.4772 3.73619 17.112 3.73619 16.6807V10.2204C3.73619 9.79957 3.57033 9.43911 3.23856 9.13869C2.9068 8.83826 2.5085 8.68805 2.04391 8.68805Z"
                      fill="black"
                    />
                    <path
                      d="M15.7797 2.88838L16.9575 0.920562C17.0348 0.790284 17.0073 0.690383 16.8747 0.620136C16.7308 0.559563 16.6203 0.590256 16.543 0.710047L15.3487 2.6936C14.2978 2.2728 13.1863 2.06201 12.0142 2.06201C10.8419 2.06201 9.7303 2.27284 8.67979 2.6936L7.48545 0.710047C7.40783 0.590256 7.29728 0.559834 7.15373 0.620136C7.02086 0.6907 6.99336 0.790284 7.07097 0.920562L8.24885 2.88838C7.0545 3.44001 6.10328 4.20825 5.39552 5.19478C4.68777 6.18186 4.33374 7.26052 4.33374 8.43266H19.678C19.678 7.26079 19.324 6.18209 18.6163 5.19478C17.9085 4.20825 16.9627 3.44001 15.7797 2.88838ZM8.96998 5.75061C8.84261 5.86625 8.69047 5.92379 8.51364 5.92379C8.33645 5.92379 8.18741 5.86625 8.06582 5.75061C7.94424 5.63552 7.88345 5.49828 7.88345 5.33758C7.88345 5.17743 7.94424 5.03992 8.06582 4.92455C8.18741 4.80947 8.3368 4.75192 8.51364 4.75192C8.69047 4.75192 8.84261 4.80947 8.96998 4.92455C9.09706 5.04019 9.1609 5.17743 9.1609 5.33758C9.16055 5.49801 9.09676 5.63552 8.96998 5.75061ZM15.962 5.75061C15.8401 5.86625 15.6908 5.92379 15.5142 5.92379C15.337 5.92379 15.1849 5.86625 15.0578 5.75061C14.9304 5.63552 14.8669 5.49828 14.8669 5.33758C14.8669 5.17743 14.9304 5.03992 15.0578 4.92455C15.1849 4.80947 15.337 4.75192 15.5142 4.75192C15.6911 4.75192 15.8401 4.80947 15.962 4.92455C16.0837 5.04019 16.1444 5.17743 16.1444 5.33758C16.1444 5.49801 16.0836 5.63552 15.962 5.75061Z"
                      fill="black"
                    />
                    <path
                      d="M4.39963 18.9788C4.39963 19.44 4.57647 19.8304 4.93019 20.1507C5.28422 20.4711 5.71526 20.6312 6.22412 20.6312H7.45176L7.46858 24.0421C7.46858 24.4728 7.63444 24.8385 7.96615 25.139C8.29787 25.4395 8.69622 25.5897 9.1605 25.5897C9.63581 25.5897 10.0396 25.4395 10.3714 25.139C10.7031 24.8385 10.869 24.4728 10.869 24.0421V20.6316H13.1582V24.0421C13.1582 24.4728 13.324 24.8385 13.6557 25.139C13.9875 25.4395 14.3909 25.5897 14.8666 25.5897C15.3419 25.5897 15.7457 25.4395 16.0775 25.139C16.4092 24.8385 16.5751 24.4728 16.5751 24.0421V20.6316H17.8193C18.3168 20.6316 18.7423 20.4714 19.0967 20.151C19.4504 19.8306 19.6273 19.4402 19.6273 18.9791V8.97272H4.39963V18.9788Z"
                      fill="black"
                    />
                    <path
                      d="M21.9839 8.68805C21.5193 8.68805 21.1213 8.83605 20.7895 9.13123C20.4578 9.42695 20.2919 9.79021 20.2919 10.2204V16.6807C20.2919 17.112 20.4577 17.4771 20.7895 17.7776C21.1213 18.0781 21.5196 18.2283 21.9839 18.2283C22.4592 18.2283 22.863 18.0781 23.1948 17.7776C23.5265 17.4771 23.6924 17.112 23.6924 16.6807V10.2204C23.6924 9.79017 23.5265 9.42695 23.1948 9.13123C22.863 8.83605 22.4592 8.68805 21.9839 8.68805Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetUpdateSection;
