import React from 'react';
import NextButton from './NextButton';
import PreviousButton from './PreviousButton';

const FixedBottomPrevNextButton = ({ handlePrevClick, disabled, handleNextClick, title, hasPrev = true, ...props }) => {
    return <div className="xl:hidden fixed bottom-0 left-0 shadow-2xl bg-white w-full py-4">
        <div className={`flex ${hasPrev ? "justify-between" : "justify-end"} container px-4 mx-auto`}>
            {
                hasPrev && <PreviousButton
                    onClick={handlePrevClick}
                />
            }

            <NextButton
                disabled={disabled}
                onClick={handleNextClick}
                title={title}
                {...props}
            />
        </div>
    </div>
};

export default FixedBottomPrevNextButton;
