import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader3 from "../../../components/blocks/Loader3";
import * as actions from "../../../store/actions";
import { numberFormat } from "../../../utils/numberFormat";

const AppliedMessage = () => {
  return (
    <div className="text-temp-green-500 text-sm lg:text-sm-15 flex flex-row items-center -mt-1">
      <span>
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8.01738" cy="7.2498" r="7.13456" fill="#08A05C" />
          <path
            d="M7.00982 10.4204C6.83526 10.4206 6.66738 10.3506 6.54119 10.2249L4.52872 8.21338C4.46455 8.14927 4.41313 8.07262 4.3774 7.9878C4.34167 7.90299 4.32232 7.81167 4.32046 7.71907C4.3186 7.62647 4.33427 7.53439 4.36657 7.4481C4.39887 7.3618 4.44717 7.28298 4.50871 7.21613C4.57026 7.14928 4.64383 7.09571 4.72525 7.05849C4.80666 7.02126 4.89431 7.00111 4.9832 6.99917C5.0721 6.99724 5.16048 7.01356 5.24332 7.04721C5.32615 7.08086 5.40181 7.13118 5.46598 7.19529L6.96024 8.68835L10.5207 4.33366C10.578 4.26176 10.6484 4.20243 10.7278 4.1591C10.8072 4.11577 10.8941 4.08931 10.9835 4.08124C11.0728 4.07318 11.1628 4.08369 11.2482 4.11214C11.3336 4.1406 11.4127 4.18644 11.481 4.24701C11.5493 4.30758 11.6053 4.38167 11.6459 4.46498C11.6864 4.54829 11.7107 4.63917 11.7173 4.73233C11.7239 4.82549 11.7126 4.91909 11.6842 5.00769C11.6558 5.09629 11.6108 5.17812 11.5517 5.24845L7.52524 10.1724C7.46541 10.2456 7.39185 10.3052 7.30908 10.3477C7.22631 10.3902 7.13608 10.4147 7.04391 10.4195C7.03261 10.4201 7.0213 10.4204 7.00982 10.4204Z"
            fill="white"
          />
        </svg>
      </span>

      <span className="pl-1">Bonus Applied</span>
    </div>
  );
};

const ApplyReferral = ({ itemAvailability, ...props }) => {
  const [referalData, setReferalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [applyStat, setApplyStat] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      await props.getLoggedInReferralDetailsApi();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setReferalData(props.referralDetails);
  }, [props.referralDetails]);

  const handleApplyReferral = async () => {
    const payload = {
      referralBonus: referalData?.referralEarning,
    };
    const resp = await props.applyReferralOnItemAvailability(payload);
    setApplyStat(resp?.status);

    if (resp?.data) {
      setReferalData({
        ...referalData,
        referralEarning:
          parseInt(itemAvailability?.summary?.total) <= resp.data
            ? resp.data / 2
            : 0,
      });
    }
  };
  return (
    <>
      <div className="flex flex-row flex-wrap justify-between items-center pt-4 pb-2">
        <div className="pb-2">
          <p className="text-sm lg:text-sm-15 text-temp-primary inline-flex">
            Referral Bonus:{" "}
            {loading ? (
              <span className="ml-5">
                <Loader3 />
              </span>
            ) : (
              <span className="ml-1 font-semibold">
                ₦{numberFormat(referalData?.referralEarning || 0)}
              </span>
            )}
          </p>
        </div>

        <div className="pb-2">
          <>
            {applyStat === "success" ? (
              <AppliedMessage />
            ) : (
              <button
                disabled={loading}
                className={`text-temp-green-500 focus:outline-none underline text-sm lg:text-sm-15 ${
                  loading && "opacity-50"
                }`}
                onClick={handleApplyReferral}
              >
                Apply Bonus
              </button>
            )}
          </>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  referralDetails: state.referral.referralDetails,
});

export default connect(mapStateToProps, actions)(ApplyReferral);
