/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, } from "react-router-dom";
import InputGroup2 from "../../../../components/element/InputGroup2";
import AlertModal from "../../../../components/modals/AlertModal";
import LoadingModal from "../../../../components/modals/LoadingModal";
import {
    RibbonIconLeft,
    RibbonIconRight,
    SuccessGradientIcon
} from "../../../../components/svgs";
import * as actions from "../../../../store/actions";

const NotSignUp = () => {
  return (
    <div className="w-full lg:px-10">
      <div className="pt-6 pb-6 md:pt-6 md:pb-4 lg:pt-6 lg:pb-6 text-center">
        <div className="relative flex justify-center text-center mx-auto w-full py-6">
          <div className="relative left-8">
            <RibbonIconLeft />
          </div>
          <div className="z-10">
            <SuccessGradientIcon />
          </div>
          <div className="relative -left-8">
            <RibbonIconRight />
          </div>
        </div>
        <p className="pt-2 text-temp-primary text-base lg:text-lg mb-4">
          Setup your Account to continue. 😉😉
        </p>
        <Link
          to="/signup"
          className="w-full md:w-auto bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white text-sm lg:text-base font-semibold px-10 py-4"
        >
          Sign Up
        </Link>
      </div>
    </div>
  );
};

const AlreadyALender = () => {
  return (
    <div className="pt-6 pb-6 md:pt-6 md:pb-4 lg:pt-6 lg:pb-6 text-center">
      <div className="relative flex justify-center text-center mx-auto w-full py-6">
        <div className="relative left-8">
          <RibbonIconLeft />
        </div>
        <div className="z-10">
          <SuccessGradientIcon />
        </div>
        <div className="relative -left-8">
          <RibbonIconRight />
        </div>
      </div>
      <p className="pt-2 text-temp-primary text-base lg:text-lg mb-4">
        Hello there, You are already a lender. 😉😉
      </p>
      <Link
        to="/upload-item/start"
        className="w-full md:w-auto bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white text-sm lg:text-base font-semibold px-10 py-4"
      >
        Upload item now
      </Link>
    </div>
  );
};

const NotCompleteVerification = ({ userDetails }) => {
  return (
    <div className="mt-4 p-6 w-full bg-red-100 text-left text-red-600 rounded-lg">
      <p className="font-semibold pb-2">Warning!!!</p>
      <p>
        You can&apos;t complete this registration because your{" "}
        {!userDetails?.verified_id && "ID"}{" "}
        {!userDetails?.image && "picture"}{" "}
        {!userDetails?.image && !userDetails?.verified_phone && " & "}
        {!userDetails?.verified_phone && " phone number "} is not verified.
      </p>
      <p className="mt-8 mb-6">
        <Link
          to={
            !userDetails?.verified_id || !userDetails?.image
              ?
              "/complete-verification" :
              "/verify-email"
          }
          className="bg-red-600 py-4 px-6 rounded-xl focus:outline-none text-white"
        >
          Click to verify account &gt;&gt;
        </Link>
      </p>
    </div>
  );
};

const InformationForm = ({ userDetails, handleConfirm }) => {
  return (
    <div className="">
      <div className="">
        <p className="font-semibold text-temp-primary mb-2 text-base lg:text-lg">Confirm Your Name</p>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <InputGroup2
          autoComplete="off"
          name="type"
          value={userDetails?.firstname}
          disabled
        />
        <InputGroup2
          autoComplete="off"
          name="type"
          value={userDetails?.lastname}
          disabled
        />
      </div>

      <div className="block relative mt-2 text-right">
        <button
          onClick={handleConfirm}
          className="w-full md:w-auto bg-gradient whitespace-nowrap inline-flex items-center justify-center rounded-xl text-temp-white text-sm lg:text-base font-semibold px-12 lg:px-16 py-4"
        >
          Next
        </button>
      </div>
    </div>
  );
};

const AccountInformation = (props) => {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await props.getUserApi();

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setUserDetails(props.users.user);
  }, [props.users.user]);

  // Go to the next step
  const handleConfirm = () => {
    props.handeStepChange("step2");
  };

  return (
    <div className="w-full lg:w-9/12 mt-14">
      {!userDetails.firstname ? (
        <NotSignUp />
      ) : userDetails?.verified_lender ? (
        <AlreadyALender />
      ) : !userDetails?.verified_phone ||
        !userDetails?.verified_id ||
        !userDetails?.image ? (
        <NotCompleteVerification userDetails={userDetails} />
      ) : (
        <InformationForm
          handleConfirm={handleConfirm}
          userDetails={userDetails}
        />
      )}

      {/* Trigger modal after form submission */}
      {loading && <LoadingModal show={loading} />}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    alert: state.alert,
  };
};

export default (
  connect(mapStateToProps, actions)(AccountInformation)
);
