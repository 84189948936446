import React from "react";

export const AtmCardIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.8811 58.2906L85.9227 26.0996C86.4469 22.833 84.2242 19.7593 80.9573 19.2336L12.9283 8.31462C9.6616 7.79039 6.58812 10.0131 6.06389 13.2798L3.64648 28.3343V58.1033L80.8811 58.2906Z"
        fill="#146AA8"
      />
      <path
        d="M80.959 19.2334L61.6373 16.1331C48.6602 35.9894 27.6818 49.7424 11.5938 58.1226L80.8826 58.2904L85.9242 26.0995C86.4484 22.8326 84.2257 19.7592 80.959 19.2334Z"
        fill="#126099"
      />
      <path
        d="M5.99127 26.8364H74.8906C78.1992 26.8364 80.8818 29.5191 80.8818 32.8277V71.7708C80.8818 75.0795 78.1992 77.7621 74.8906 77.7621H5.99127C2.68262 77.7619 0 75.0795 0 71.7708V32.8277C0 29.5191 2.68262 26.8364 5.99127 26.8364Z"
        fill="#4398D1"
      />
      <path
        d="M74.8899 26.8364H73.6916C59.5522 51.9383 33.1324 69.1571 17.0039 77.7619H74.8899C78.1985 77.7619 80.8811 75.0793 80.8811 71.7707V32.8277C80.8811 29.5191 78.1985 26.8364 74.8899 26.8364Z"
        fill="#3E8CC7"
      />
      <path
        d="M22.4664 34.3257H7.48828V46.3082H22.4664V34.3257Z"
        fill="#FDB62F"
      />
      <path
        d="M11.9818 38.8191H7.48828V41.8146H11.9818V38.8191Z"
        fill="#FD7B2F"
      />
      <path
        d="M22.4661 38.8191H17.9727V41.8146H22.4661V38.8191Z"
        fill="#FD7B2F"
      />
      <path
        d="M65.9031 70.273C70.0392 70.273 73.3921 66.9201 73.3921 62.784C73.3921 58.6479 70.0392 55.2949 65.9031 55.2949C61.767 55.2949 58.4141 58.6479 58.4141 62.784C58.4141 66.9201 61.767 70.273 65.9031 70.273Z"
        fill="#FDB62F"
      />
      <path
        d="M56.9168 70.273C61.0529 70.273 64.4058 66.9201 64.4058 62.784C64.4058 58.6479 61.0529 55.2949 56.9168 55.2949C52.7807 55.2949 49.4277 58.6479 49.4277 62.784C49.4277 66.9201 52.7807 70.273 56.9168 70.273Z"
        fill="#DE4C3C"
      />
      <path
        d="M14.979 56.7927H5.99219V59.7883H14.979V56.7927Z"
        fill="#87CED9"
      />
      <path
        d="M29.9575 56.7927H20.9707V59.7883H29.9575V56.7927Z"
        fill="#87CED9"
      />
      <path
        d="M44.936 56.7927H35.9492V59.7883H44.936V56.7927Z"
        fill="#87CED9"
      />
      <path
        d="M44.9353 65.7798H5.99219V68.7753H44.9353V65.7798Z"
        fill="#87CED9"
      />
      <path
        d="M55.4194 34.3257H52.4238V40.317H55.4194V34.3257Z"
        fill="#87CED9"
      />
      <path
        d="M61.4116 34.3257H58.416V40.317H61.4116V34.3257Z"
        fill="#87CED9"
      />
      <path
        d="M67.4038 34.3257H64.4082V40.317H67.4038V34.3257Z"
        fill="#87CED9"
      />
      <path d="M73.392 34.3257H70.3965V40.317H73.392V34.3257Z" fill="#87CED9" />
    </svg>
  );
};
