/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MainContainer from "../../../components/layout/MainContainer";
import EquipmentInfoForm from "./components/EquipmentInfoForm";
import * as actions from "../../../store/actions";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import AddAddressModal from "../../profile/components/AddAddressModal";
import AlertModal2 from "../../../components/modals/AlertModal2";
import { scrollToTop } from "../../../utils/scrollToTop";
import { compressImage } from "../../../helpers/compressImage";
import SerialNumberForm from "./components/SerialNumberForm";
import AlertIcon from "../../../assets/images/svg/alarm.svg";
import ItemQuantityForm from "./components/ItemQuantityForm";
import AvailabilityForm from "./components/AvailabilityForm";
import LocationInfoForm from "./components/LocationInfoForm";
import InsuranceForm from "./components/InsuranceForm";
import PricingForm from "./components/PricingForm";
import ImagesForm from "./components/ImagesForm";
import store from "store";
import OperatorForm from "./components/OperatorForm";
import ProgressBar from "../../../components/blocks/ProgressBar";
import { uploadItemStepData } from "../../../utils/uploadItemStepData";
import TipContainer from "./components/componentsBit/TipContainer";

const LenderUploadItemForm = (props) => {
  const [user] = useState(store.get("temp_usER12_dATa"));
  const [currentStep, setCurrentStep] = useState("Step0");
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedMap, setSelectedMap] = useState({});

  const [features, setFeatures] = useState([]);
  const [hasFeatures, setHasFeatures] = useState([]);

  const [selectedFeatures, setSelectedFeatures] = useState({});

  const [noOfImageError, setNoOfImageError] = useState("");

  // Display feature list based on the selected category
  const handeChangeFeatures = (data) => {
    setFeatures(data);

    setHasFeatures(
      data?.map((a) => ({
        [`has${a.name.replaceAll(" ", "")}`]: false,
      }))
    );

    setSelectedFeatures({});
  };

  // Handle checkbox check on each feature list
  const handleCheckedFeature = (e, index) => {
    hasFeatures[index][e.target.name] = !hasFeatures[index][e.target.name];
    setHasFeatures([...hasFeatures]);

    handleClearSelectedFeaturesField(e.target.value);

    delete selectedFeatures[e.target.value];
    setSelectedFeatures({ ...selectedFeatures });
  };

  // Handle input field value change for each feature checked
  const handleOnChangeCheckedInput = (e) => {
    setSelectedFeatures({
      ...selectedFeatures,
      [e.target.name]: e.target.value,
    });
  };

  // Handle clear field value on click of the X icon on each checked features
  const handleClearSelectedFeaturesField = (name) => {
    setSelectedFeatures({
      ...selectedFeatures,
      [name]: "",
    });
  };

  const [initialState, setInitialState] = useState({
    name: "",
    category: "",
    type: "",
    description: "",
    serialNumbers: [],

    pickupLocation: "",
    pickupNote: "",
    pickupTimeOpening: "",
    pickupTimeClosing: "",

    deliveryTimeOpening: "",
    deliveryTimeClosing: "",

    days: [],

    rentalUnitType: "",
    calenderType: "",

    siUnitType: "",
    maximumCapacityPerDay: "",
    maximumOrderForSiUnitsDiscount: "",
    siUnitDiscount: "",

    price: 0,
    discountPerWeek: 0,
    discountBiWeekly: 0,
    discountPerMonth: 0,
    discountMonthlyQuarterly: 0,
    discountMonthlyBiAnnually: 0,
    discountYearly: 0,

    minimumRentalDays: null,

    itemCount: 0,
    tempownProtect: null,
    hasSerialNumber: null,
    hasOperator: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingCategory(true);
    setLoadingAddress(true);
    await props.getCategoriesApi();
    await props.getAddressesApi();
    setLoadingCategory(false);
    setLoadingAddress(false);
  };

  const processFetchTypeData = async (id) => {
    setLoadingType(true);
    await props.getTypesApi(id);
    setLoadingType(false);
  };

  useEffect(() => {
    setTypes(props.types);
  }, [props.types]);

  const fetchAddressData = async () => {
    setLoadingAddress(true);
    await props.getAddressesApi();
    setLoadingAddress(false);
  };

  useEffect(() => {
    setCategories(props.categories);
  }, [props.categories]);

  useEffect(() => {
    setAddresses(props.addresses);
    setSelectedMap(props.addresses?.find((a) => a.isDefault === true));
  }, [props.addresses]);

  const [images, setImages] = useState(["", "", "", "", "", ""]);
  const [displayImages, setDisplayImages] = useState(["", "", "", "", "", ""]);

  const handleImageChange = (e, key) => {
    // compress image
    compressImage(e, (data) => {
      images[key] = data;
      setImages([...images]);
    });

    var reader = new FileReader();
    reader.onload = function (e) {
      displayImages[key] = e.target.result;
      setDisplayImages([...displayImages]);
    };
    reader.readAsDataURL(e[0]);
  };

  const handleRemoveImage = (key) => {
    images[key] = "";
    setImages([...images]);

    displayImages[key] = "";
    setDisplayImages([...displayImages]);
  };

  const [addNewAddressModal, setAddNewAddressModal] = useState(false);
  const handleShowAddAddressModal = () => {
    setAddNewAddressModal(true);
  };

  const processCreateAddress = async (payload) => {
    await props.createAddressApi(payload);
  };

  useEffect(() => {
    if (props.createAddressStatus?.type === "success") {
      setAddNewAddressModal(false);
      fetchAddressData();
    }
  }, [props.createAddressStatus?.type]);

  const handleSelectAddress = (data) => {
    setSelectedMap(data);

    setAddresses(
      addresses?.map((a) =>
        a._id == data?._id
          ? {
              ...data,
              isDefault: true,
            }
          : {
              ...a,
              isDefault: false,
            }
      )
    );
  };

  const handleChangeCurrentStep = (step, data) => {
    setCurrentStep(step);

    if (data) {
      setInitialState((prev) => ({
        ...prev,
        ...data,
      }));
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [currentStep]);

  // General Category Equipment Addition START
  const [showAddEquipmentTypeField, setShowAddEquipmentTypeField] = useState(
    false
  );
  const handleShowAddEquipmentTypeField = (bool) => {
    setShowAddEquipmentTypeField(bool);
  };
  // General Category Equipment Addition END

  const handleHasOperator = (val) => {
    if (val === false)
      return setInitialState((prev) => ({
        ...prev,
        hasOperator: val,
      }));
    setInitialState((prev) => ({ ...prev, hasOperator: val }));
  };

  const handleHasSerialNo = (val) => {
    if (val === false)
      return setInitialState((prev) => ({
        ...prev,
        hasSerialNumber: val,
        tempownProtect: false,
      }));
    setInitialState((prev) => ({ ...prev, hasSerialNumber: val }));
  };
  const handleItemCount = (val) => {
    setInitialState((prev) => ({
      ...prev,
      itemCount: val,
      serialNumbers: Array(val).fill(""),
    }));
  };
  const handleTempownProtect = (val) => {
    setInitialState((prev) => ({
      ...prev,
      tempownProtect: val,
    }));
  };

  const handleProcessCreateItem = async (e) => {
    e.preventDefault();

    const featuresKey = features.filter(
      (a) => Object.keys(selectedFeatures).includes(a?.name) === true
    );
    const featuresValues = Object.values(selectedFeatures);

    const refactoredFeatures = featuresKey.map((a, index) => ({
      feature: a._id,
      value: featuresValues[index],
    }));

    const {
      name,
      description,
      category,
      type,

      pickupLocation,
      pickupTimeClosing,
      pickupTimeOpening,

      rentalUnitType,
      calenderType,

      siUnitType,
      maximumCapacityPerDay,
      maximumOrderForSiUnitsDiscount,
      siUnitDiscount,

      discountPerWeek,
      discountBiWeekly,
      discountPerMonth,
      discountMonthlyQuarterly,
      discountMonthlyBiAnnually,
      discountYearly,
      minimumRentalDays,

      price,

      tempownProtect,
      serialNumbers,
      itemCount,
      days,
      hasOperator,
    } = initialState;
    let payload = {
      name,
      description,
      type: types.filter((e) => e.name === type.value)[0]?._id,
      category: categories.filter((e) => e.name === category.value)[0]?._id,

      pickupLocation,
      pickupTimeOpening,
      pickupTimeClosing,
      deliveryTimeOpening: pickupTimeOpening,
      deliveryTimeClosing: pickupTimeClosing,

      rentalUnitType: rentalUnitType.value,

      price,

      quantity: itemCount,
      features: refactoredFeatures,
      selfInsurance: !tempownProtect,
      tempownProtect: tempownProtect,
      blockedDays: days?.map((a) => new Intl.DateTimeFormat("en-US").format(a)),

      operator: hasOperator,
    };

    if ((initialState?.hasSerialNumber === true) && serialNumbers?.length > 0) {
      payload["serialNumbers"] = serialNumbers;
    }

    if (payload?.rentalUnitType === "byCalender") {
      payload["calenderType"] = calenderType.value;

      if (calenderType?.value === "day") {
        payload["minimumBookingDays"] = minimumRentalDays.value;
        payload["discountPerWeek"] = discountPerWeek;
        payload["discountPerMonth"] = discountPerMonth;
      }
    } else if (payload?.rentalUnitType === "bySiUnits") {
      payload["siUnitType"] = siUnitType.value;

      payload["maximumCapacityPerDay"] = parseInt(maximumCapacityPerDay || 0);
      payload["maximumOrderForSiUnitsDiscount"] = parseInt(
        maximumOrderForSiUnitsDiscount || 0
      );
      payload["siUnitDiscount"] = siUnitDiscount;
    }

    const filterImages = images?.filter((a) => a !== "");

    const isEmptyImage = filterImages?.length < 2 ? true : false;
    if (isEmptyImage) {
      setNoOfImageError(
        "Kindly upload  atleast two (2) or more images of the item been created."
      );
      return;
    } else {
      setNoOfImageError("");
      setIsLoading(true);

      // Create type if type === "general"
      const addTypePayload = {
        categoryId: categories?.find(
          (a) => a?.name === initialState.category?.value
        )?._id,
        name: types?.includes(initialState.type)
          ? initialState.type?.value
          : initialState.type,
      };

      if (initialState.category?.value === "general" && initialState.type) {
        try {
          const res = await props.addTypeApi(addTypePayload);
          payload["type"] = res?.data?._id;
        } catch (error) {
          setIsLoading(false);
          return;
        }
      }

      const formData = new FormData();
      for (const key in filterImages) {
        if (Object.hasOwnProperty.call(filterImages, key)) {
          formData.append("images", filterImages[key]);
        }
      }
      // Create item endpoint
      if (payload["type"]) {
        await props.createItemApi(
          payload?.rentalUnitType,
          payload,
          formData,
          props.history
        );
      }
      setIsLoading(false);
    }
  };
  const verified_borrower =
    user?.verified_id && user?.verified_phone;
  return (
    <MainContainer noFooter hasMainContainer={false}>
      <div id="uploadItemContainer" className="w-full">
        {/* IF ACCOUNT IS NOT VERIFIED */}
        <ProgressBar progress={uploadItemStepData[currentStep]?.progress} />

        {verified_borrower && user?.verified_lender ? (
          <div className="container px-4 pt-10 lg:pt-12 xl:pt-20 mx-auto flex flex-row flex-wrap items-start justify-center  xl:justify-between">
            <div className="w-full xl:w-9/12 mx-auto order-2 xl:order-1">
              {currentStep === "Step0" ? (
                <SerialNumberForm
                  handleHasSerialNo={handleHasSerialNo}
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                />
              ) : currentStep === "Step1" ? (
                <OperatorForm
                  handleHasOperator={handleHasOperator}
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                />
              ) : currentStep === "Step2" ? (
                <ItemQuantityForm
                  handleQuantity={handleItemCount}
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                />
              ) : currentStep === "Step3" ? (
                <EquipmentInfoForm
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                  loadingCategory={loadingCategory}
                  loadingType={loadingType}
                  categories={categories}
                  types={types}
                  processFetchTypeData={processFetchTypeData}
                  features={features}
                  hasFeatures={hasFeatures}
                  handleCheckedFeature={handleCheckedFeature}
                  handeChangeFeatures={handeChangeFeatures}
                  handleOnChangeCheckedInput={handleOnChangeCheckedInput}
                  selectedFeatures={selectedFeatures}
                  handleClearSelectedFeaturesField={
                    handleClearSelectedFeaturesField
                  }
                  handleShowAddEquipmentTypeField={
                    handleShowAddEquipmentTypeField
                  }
                  showAddEquipmentTypeField={showAddEquipmentTypeField}
                />
              ) : currentStep === "Step4" ? (
                <LocationInfoForm
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                  selectedMap={selectedMap}
                  addresses={addresses}
                  loadingAddress={loadingAddress}
                  handleShowAddAddressModal={handleShowAddAddressModal}
                  handleSelectAddress={handleSelectAddress}
                />
              ) : currentStep === "Step5" ? (
                <InsuranceForm
                  handleTempownProtect={handleTempownProtect}
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                />
              ) : currentStep === "Step6" ? (
                <PricingForm
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                />
              ) : currentStep === "Step7" ? (
                <AvailabilityForm
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                />
              ) : currentStep === "Step8" ? (
                <ImagesForm
                  initialState={initialState}
                  handleChangeCurrentStep={handleChangeCurrentStep}
                  images={images}
                  displayImages={displayImages}
                  handleInputChange={handleImageChange}
                  handleRemoveImage={handleRemoveImage}
                  handleProcessCreateItem={handleProcessCreateItem}
                  noOfImageError={noOfImageError}
                />
              ) : (
                ""
              )}
            </div>
            <TipContainer
              stepData={uploadItemStepData}
              currentStep={currentStep}
            />
          </div>
        ) : (
          <div className="w-full flex items-start justify-center">
            <div className="flex flex-col space-x-4 items-center  mt-14">
              <img src={AlertIcon} alt="" width="50rem" />
              <p className="text-red-600 text-sm lg:text-lg mt-10 text-center">
                Account verification not complete. Click{" "}
                <Link
                  to={verified_borrower ? "/lender" : "/complete-verification"}
                  className="font-bold"
                >
                  here
                </Link>{" "}
                to complete verification and continue.
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Show add address modal */}
      {addNewAddressModal && (
        <AddAddressModal
          show={addNewAddressModal}
          handleCloseModal={() => setAddNewAddressModal(false)}
          processCreateAddress={processCreateAddress}
        />
      )}

      {/* trigger new address modal */}
      {props.createAddressStatus?.type && (
        <AlertModal2
          show={props.createAddressStatus.type ? true : false}
          type={props.createAddressStatus.type}
          content={props.createAddressStatus.message}
        />
      )}

      {/* Trigger modal after form submission */}
      {isLoading && <LoadingModal show={isLoading} title="Creating Item ..." />}

      {/* Trigger Message */}
      {props.alert.message && !isLoading && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl="/my-items"
        />
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    addresses: state.address.addresses,
    createItemStatus: state.items.createItemStatus,
    createAddressStatus: state.address.createAddressStatus,
    types: state.category.types,
    alert: state.alert,
  };
};

export default connect(mapStateToProps, actions)(LenderUploadItemForm);
