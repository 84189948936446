import { Formik } from "formik";
import React, { useState } from "react";
import TextAreaGroup from "../../../../components/element/TextAreaGroup";
import PickUpLocation from "../../../../components/blocks/PickUpLocation";
import InputDatePicker from "../../../../components/element/InputDatePicker";
import PreviousButton from "./componentsBit/PreviousButton";
import NextButton from "./componentsBit/NextButton";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";

const LocationInfoForm = ({
    initialState,
    handleChangeCurrentStep,
    selectedMap,
    loadingAddress,
    handleShowAddAddressModal,
    handleSelectAddress,
    addresses,
}) => {
    const [pickUpLocationError, setPickUpLocationError] = useState("");

    const handleSubmitable = (isSubmitting, values) => {
        if (isSubmitting) return false;
        if (values?.pickupTimeOpening === "") return false;
        if (values?.pickupTimeClosing === "") return false;
        return true;
    };
    const validate = (values) => {
        const errors = {};

        if (!values.pickupTimeOpening || values.pickupTimeOpening.length < 1) {
            errors.pickupTimeOpening = "Set an opening pickup time";
        }
        if (!values.pickupTimeClosing || values.pickupTimeClosing.length < 1) {
            errors.pickupTimeClosing = "Set an closing pickup time";
        }
        if (!selectedMap?._id || selectedMap?._id?.length < 0) {
            errors.pickupLocation = "Pickup Location not added";
        }

        return errors;
    };

    const handleSubmitForm = (values) => {
        const payload = {
            pickupLocation: selectedMap?._id,
            pickupNote: values.pickupNote,
            pickupTimeOpening: values.pickupTimeOpening,
            pickupTimeClosing: values.pickupTimeClosing,
        };

        handleChangeCurrentStep("Step5", payload);
    };
    return (
        <div className="flex justify-around xl:pr-20">
            <div className="w-4/12 hidden xl:block">
                <PreviousButton
                    onClick={() => handleChangeCurrentStep("Step3")}
                />
            </div>
            <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12">
                <h2
                    className="text-xl sm:text-2xl text-temp-primary font-medium"
                >
                    Location
                </h2>

                <div className="mt-4">
                    <Formik
                        initialValues={initialState}
                        validate={validate}
                        onSubmit={async (values) => {
                            const newValues = {
                                ...values,
                                pickupLocation: selectedMap?._id,
                            };

                            handleChangeCurrentStep("Step5", newValues);
                            setPickUpLocationError("");
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleBlur,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <>
                                <form
                                    className="relative mt-14"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="mb-44 xl:mb-20">
                                        <div className="lg:pb-4">
                                            <div className="relative mt-3 mb-10">
                                                <p className="text-temp-primary pb-3 text-sm lg:text-base">
                                                    Pick up Location<span className="text-red-400">*</span>{" "}
                                                    <span className="text-xs text-red-600 pl-2">
                                                        {pickUpLocationError}
                                                    </span>
                                                </p>
                                                <div className="">
                                                    <PickUpLocation
                                                        addresses={addresses}
                                                        loading={loadingAddress}
                                                        handleShowAddAddressModal={handleShowAddAddressModal}
                                                        handleSelectAddress={handleSelectAddress}
                                                        pickUpLocationError={pickUpLocationError}
                                                    />
                                                </div>
                                            </div>

                                            <div className="group w-full pt-4 lg:pb-4">
                                                <TextAreaGroup
                                                    label="Pick up note"
                                                    name="pickupNote"
                                                    placeholder="Any additional information that'd help your borrower locate you..."
                                                    textColor="text-temp-secondary placeholder-temp-gray"
                                                    value={values.pickupNote}
                                                    onChange={(e) => {
                                                        setFieldValue("pickupNote", e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.pickupNote &&
                                                        touched.pickupNote &&
                                                        errors.pickupNote
                                                    }
                                                    rows="10"
                                                />
                                            </div>

                                            <div className="">
                                                <p className="text-temp-primary pb-3 text-sm lg:text-base">
                                                    Set a pickup and return time window
                                                    <span className="text-red-400">*</span>
                                                </p>
                                                <div className="grid grid-cols-2 gap-4 md:gap-6">
                                                    <div className="group w-full lg:pb-8">
                                                        <InputDatePicker
                                                            autoComplete="off"
                                                            name="pickupTimeOpening"
                                                            value={values.pickupTimeOpening}
                                                            placeholderText="Start Time"
                                                            onChange={(e) => {
                                                                setFieldValue("pickupTimeOpening", e);
                                                                setFieldValue("deliveryTimeOpening", e);
                                                            }}
                                                            error={
                                                                errors.pickupTimeOpening &&
                                                                touched.pickupTimeOpening &&
                                                                errors.pickupTimeOpening
                                                            }
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                        />
                                                    </div>

                                                    <div className="group w-full pb-8">
                                                        <InputDatePicker
                                                            autoComplete="off"
                                                            name="pickupTimeClosing"
                                                            value={values.pickupTimeClosing}
                                                            placeholderText="End Time"
                                                            onChange={(e) => {
                                                                setFieldValue("pickupTimeClosing", e);
                                                                setFieldValue("deliveryTimeClosing", e);
                                                            }}
                                                            error={
                                                                errors.pickupTimeClosing &&
                                                                touched.pickupTimeClosing &&
                                                                errors.pickupTimeClosing
                                                            }
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                            minTime={new Date(values.pickupTimeOpening)}
                                                            disabled={!values.pickupTimeOpening}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mb-28">
                                            <NextButton
                                                disabled={!handleSubmitable(isSubmitting, values)}
                                                onClick={() => handleSubmitForm(values)}
                                                title="Next"
                                            />
                                        </div>
                                    </div>

                                    <FixedBottomPrevNextButton
                                        handlePrevClick={() => handleChangeCurrentStep("Step3")}
                                        disabled={!handleSubmitable(isSubmitting, values)}
                                        onClick={() => handleSubmitForm(values)}
                                        title="Next"
                                    />
                                </form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default LocationInfoForm;
