import React from "react";
import SendIcon from "../../../assets/images/svg/send.svg";
import Avatar from "../../../assets/images/svg/user.svg";
import { convertSecondsToAMPM } from "../../../utils/timeFormatAMPM";
import store from "store";
import { getFormatedDate } from "../../../utils/timeFormatAMPM";

const WriteMessage = ({ handleChange, handleSubmit, message }) => {
  return (
    <div className="type-message relative rounded-b-xl -mt-2 w-full py-6 shadow-2xl px-4 bg-white lg:px-6">
      <div className="flex flex-row items-center">
        <div className="w-10/12 lg:w-10/12 flex-1 mr-4">
          <input
            type="text"
            placeholder="Type a message"
            className="text-temp-gray block px-5 h-12 lg:h-14 w-full rounded-xl border bg-white placeholder-temp-gray focus:outline-none text-sm md:text-sm-15 border-temp-gray-light"
            onChange={handleChange}
            value={message}
          />
        </div>
        <div className="w-2/12 lg:w-1/12">
          <button onClick={handleSubmit} className="focus:outline-none">
            <img src={SendIcon} alt="" className="" width="80%" />
          </button>
        </div>
      </div>
    </div>
  );
};

const ChatMessages = ({
  seletectedChatUser,
  handleChange,
  handleSubmit,
  message,
  formattedConversationsByDay,
  currentUserUid,
  // user,
}) => {
  return (
    <div className="relative max-h-screen pt-5 flex flex-col justify-between">
      {seletectedChatUser && (
        <>
          <div className="chat-body max-h-screen overflow-auto pb-40">
            <div className="mt-10">
              {formattedConversationsByDay?.map((d, index) => (
                <div key={index} className="">
                  <div className="date text-center mb-8">
                    <p className="text-xs lg:text-xs text-temp-secondary">
                      {getFormatedDate(d?.createdAt?.seconds)}
                    </p>
                  </div>
                  {d?.conversations.map((conversation, index) =>
                    conversation?.user_uid_1 === currentUserUid ? (
                      <div key={index} className="flex px-8 mb-10">
                        <div className="hidden md:block md:w-4/12 lg:w-6/12"></div>
                        <div className="w-full md:w-8/12 lg:w-6/12">
                          <div className="flex items-end">
                            <div className="w-9/12 flex-1">
                              <p className="mr-2 text-sm text-right pr-3 text-temp-gray">
                                You
                              </p>
                              <div className=" message sender-message py-4 px-4 text-xs lg:text-sm  mr-2">
                                {conversation?.message}
                              </div>
                            </div>
                            <div className="">
                              <img
                                src={
                                  store?.get("temp_usER12_dATa")?.image ||
                                  Avatar
                                }
                                className=" h-8 w-8 lg:h-10 lg:w-10 object-cover rounded-full"
                                alt=""
                              />
                            </div>
                          </div>
                          <p className="text-xs text-temp-gray pt-2">
                            {convertSecondsToAMPM(
                              conversation?.createdAt?.seconds
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div key={index} className="flex px-8 mb-10">
                        <div className="w-full md:w-8/12 lg:w-6/12">
                          <div className="flex items-end">
                            <div className="">
                              <img
                                src={
                                  seletectedChatUser?.profilePicture || Avatar
                                }
                                className=" h-8 w-8 lg:h-10 lg:w-10 object-cover rounded-full"
                                alt=""
                              />
                            </div>
                            <div className="w-9/12 flex-1">
                              <p className="mr-2 text-sm text-left pl-3 text-temp-gray">
                                {seletectedChatUser?.firstName}
                              </p>
                              <div className=" message reciever-message py-4 px-4 text-xs lg:text-sm  ml-2">
                                {conversation?.message}
                              </div>
                            </div>
                          </div>
                          <p className="text-xs text-temp-gray pt-2 text-right">
                            {convertSecondsToAMPM(
                              conversation?.createdAt?.seconds
                            )}
                          </p>
                        </div>
                        <div className="hidden md:block md:w-4/12 lg:w-6/12"></div>
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>

          <WriteMessage
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            message={message}
          />
        </>
      )}
    </div>
  );
};

export default ChatMessages;
