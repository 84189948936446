import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "../svgs";

const GoBackButton = ({ url }) => {
  return (
    <div className="">
      <Link
        to={url}
        className="inline-flex text-temp-green-500 text-sm lg:text-base"
      >
        {" "}
        <ArrowLeftIcon fill="#08a05c" />{" "}
        <span className="pl-3 font-semibold">Go Back</span>
      </Link>
    </div>
  );
};

export default GoBackButton;
