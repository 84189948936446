import Actions from "../actions/actionTypes";

const initialState = {
  userNotifications: [],
  pagination: {},
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: action.payload?.docs,
        pagination: {
          hasNextPage: action.payload?.hasNextPage,
          hasPrevPage: action.payload?.hasPrevPage,
          limit: action.payload?.limit,
          nextPage: action.payload?.nextPage,
          page: action.payload?.page,
          pagingCounter: action.payload?.pagingCounter,
          prevPage: action.payload?.prevPage,
          totalDocs: action.payload?.totalDocs,
          totalPages: action.payload?.totalPages,
        },
      };

    default:
      return state;
  }
};

export default NotificationReducer;
