import React from "react";

export const LockIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0748 20.0684L12.6937 23.4958C12.6748 23.6663 12.7297 23.8374 12.8441 23.9654C12.9586 24.0934 13.1226 24.1666 13.2943 24.1666H15.711C15.8827 24.1666 16.0467 24.0934 16.1612 23.9654C16.2757 23.8374 16.3305 23.6663 16.3116 23.4958L15.9305 20.0684C16.5494 19.6183 16.9193 18.9061 16.9193 18.1249C16.9193 16.7921 15.8355 15.7083 14.5026 15.7083C13.1698 15.7083 12.0859 16.7921 12.0859 18.1249C12.0859 18.9061 12.4559 19.6183 13.0748 20.0684ZM14.5026 16.9166C15.1687 16.9166 15.7109 17.4588 15.7109 18.1249C15.7109 18.5881 15.4478 19.0029 15.0242 19.2082C14.7935 19.3203 14.6584 19.5646 14.6867 19.8188L15.036 22.9583H13.9693L14.3186 19.8188C14.3469 19.5645 14.2118 19.3203 13.9811 19.2082C13.5575 19.0029 13.2944 18.5881 13.2944 18.1249C13.2943 17.4588 13.8365 16.9166 14.5026 16.9166Z"
        fill="#08A05C"
      />
      <path
        d="M24.7708 10.875H22.9583V8.45831C22.9583 3.79435 19.164 0 14.5 0C9.83598 0 6.04169 3.79435 6.04169 8.45831V10.875H4.22919C3.89523 10.875 3.625 11.1452 3.625 11.4792V26.5834C3.625 27.9162 4.70882 29 6.04169 29H22.9584C24.2912 29 25.375 27.9162 25.375 26.5833V11.4792C25.375 11.1452 25.1048 10.875 24.7708 10.875ZM7.25 8.45831C7.25 4.46045 10.5021 1.20831 14.5 1.20831C18.4979 1.20831 21.75 4.46045 21.75 8.45831V10.875H20.5417V8.45831C20.5417 5.12711 17.8312 2.41663 14.5 2.41663C11.1688 2.41663 8.45831 5.12717 8.45831 8.45831V10.875H7.25V8.45831ZM19.3333 8.45831V10.875H9.66669V8.45831C9.66669 5.79326 11.8349 3.625 14.5 3.625C17.1651 3.625 19.3333 5.79326 19.3333 8.45831ZM24.1667 26.5833C24.1667 27.2494 23.6245 27.7916 22.9584 27.7916H6.04169C5.37559 27.7916 4.83337 27.2494 4.83337 26.5833V12.0833H24.1667V26.5833Z"
        fill="#08A05C"
      />
    </svg>
  );
};
