import React from 'react'

const WhitePaperSection = () => {
    return (
        <div className='relative pt-14 lg:pt-20 pb-20 lg:pb-24'>
            <div className='container px-4 mx-auto'>
                <div className='w-full md:w-10/12 lg:w-8/12  mx-auto'>
                    <p className='text-temp-green-500 font-light text-sm-15 lg:text-base'>WHITE PAPER</p>
                    <p className='mt-4 mb-6 lg:mb-8 font-semibold text-lg lg:text-xl lg:leading-8'>
                        Predicting the Greenhouse Gas (GHG) Emission
                        Savings From Machinery and Equipment Rental Operations: A TempOwn Case Study
                    </p>

                    <a
                        href='https://drive.google.com/file/d/1GNKyPD987Vaqyo-l3gPYsb3TEtKGNcjw/view'
                        target="_blank"
                        rel="noopener noreferrer"
                        className='uppercase font-semibold text-temp-green-500 flex flex-row items-center'>
                        Read Paper <span className='ml-3'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.375 1.875V3.125H1.25V10H8.125V6.875H9.375V10.625C9.375 10.7908 9.30915 10.9497 9.19194 11.0669C9.07473 11.1842 8.91576 11.25 8.75 11.25H0.625C0.45924 11.25 0.300268 11.1842 0.183058 11.0669C0.065848 10.9497 0 10.7908 0 10.625V2.5C0 2.33424 0.065848 2.17527 0.183058 2.05806C0.300268 1.94085 0.45924 1.875 0.625 1.875H4.375ZM11.25 0V5H10V2.13313L5.12937 7.00437L4.24563 6.12063L9.11562 1.25H6.25V0H11.25Z" fill="#08A05C" />
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default WhitePaperSection