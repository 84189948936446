/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, } from "react-router-dom";
import store from "store";
import OnboardingContainer from "../../../components/layout/OnboardingContainer";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";

import SelfieImage from "../../../assets/images/svg/selfie.svg";
import Image from "../../../assets/images/svg/user2.svg";

import $ from "jquery";
import ProfileImage from "../../../assets/images/svg/profile.svg";
import CheckBoxGroup from "../../../components/element/CheckBoxGroup";
import { compressImage } from "../../../helpers/compressImage";

const UploadProfilePicture = (props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [picture, setPicture] = useState("");

  const [selectedImage, setSelectedImage] = useState("");

  const [verified, setVerified] = useState(false);

  const [showUploadOption, setShowUploadOption] = useState(false);

  const handleClickUpload = () => {
    setShowUploadOption(true);
  };

  const handleVerify = () => {
    setVerified((prev) => !prev);
  };

  useEffect(() => {
    const userData = store.get("temp_usER12_dATa");
    if(!userData?.verified_id && !userData?.image) {
      navigate("/complete-verification");
    }
    if (userData?.image) {
      navigate("/");
    }
  }, []);

  //   Upload profile picture from file
  const handleProfilePictureUpload = () => {
    $("#avatar_file").click();
    $("#avatar_file").change(function (e) {
      e.preventDefault();
      profilePhoto(this);
    });
  };

  function profilePhoto(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);

      // compress image
      compressImage([input.files[0]], (data) => {
        setPicture(data);
      });
      setShowUploadOption(false);
    }
  }

  const handleSubmitProfilePicture = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("image", picture);

    await props.updateUserProfilePictureApi(formData, true);

    setLoading(false);
  };

  return (
    <div>
      <OnboardingContainer pageTitle="Verification">
        <div className="flex justify-center mb-10">
          <div className="w-full lg:w-7/12 pt-8 lg:pt-4 mx-auto px-0 lg:px-10 text-center">
            <div className="pt-4 pb-6">
              <img src={ProfileImage} className="mx-auto" alt="" />
            </div>
            <h2 className="text-temp-green-500 font-semibold text-xl lg:text-2xl xl:text-3xl">
              Upload Photo
            </h2>

            <div className="mt-20 mb-4 lg:mb-6 w-full lg:w-10/12 mx-auto">
              <div className="relative mx-auto mt-6 lg:mt-2 mb-6 text-center ">
                <button
                  className="focus:outline-none"
                  onClick={handleClickUpload}
                >
                  <div
                    className="w-40 h-40 lg:w-64 lg:h-64 bg-temp-green-100 rounded-2xl mx-auto border border-dashed border-temp-gray-light"
                    style={{
                      backgroundImage: `url(${selectedImage || Image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: selectedImage && "cover",
                    }}
                  ></div>

                  <p className="text-temp-primary mt-2 mb-4 pt-3 text-sm lg:text-sm-15">
                    Tap <span className="text-temp-secondary font-medium">here</span> to {picture ? "change" : "upload"} picture
                  </p>
                </button>

                {showUploadOption && (
                  <div
                    className="w-full lg:w-10/12 rounded-lg shadow-lg mx-auto absolute -top-10 lg:top-0 lg:left-10 z-20 bg-white"
                    style={{ boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.2)" }}
                  >
                    <div className="grid grid-cols-1 gap-6 py-20 px-12">
                      <button
                        className="mx-auto text-center focus:outline-none"
                        onClick={handleProfilePictureUpload}
                      >
                        <img
                          src={SelfieImage}
                          className="mb-6 mx-auto"
                          alt=""
                        />
                        <p className="font-bold text-temp-secondary text-sm lg:text-lg">
                          Take a selfie
                        </p>
                        <input
                          type="file"
                          hidden
                          id="avatar_file"
                          accept="image/*"
                          capture="user"
                        />
                      </button>
                    </div>
                    <div className="bg-temp-green-200 rounded-bl-lg rounded-br-lg py-4 lg:py-2">
                      <p className="text-temp-gray text-xs lg:text-sm">
                        Kindly ensure the picture is of good quality
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {picture && (
              <>
                <div className="flex w-full justify-start md:justify-center items-center">
                  <span className="w-8 h-8">
                    <CheckBoxGroup
                      label=""
                      value={verified}
                      onChange={handleVerify}
                    />
                  </span>
                  <p className="text-temp-secondary mt-2 mb-4 pt-1 text-xs md:text-sm lg:text-sm-15">
                  I acknowledge that the pictures displayed above are mine
                  </p>
                </div>


                <button
                  disabled={verified ? false : true}
                  onClick={handleSubmitProfilePicture}
                  className={`w-full md:w-auto relative mx-auto text-center mt-8 font-bold text-sm h-12 px-16 text-white rounded-xl focus:outline-none 
                ${verified
                      ? "bg-gradient"
                      : "bg-temp-green-500 opacity-60"
                    }`}
                >
                  Confirm
                </button>
              </>
            )}

            <div className={`mt-32 text-temp-gray text-sm lg:text-base text-center`}>
              <Link
                className="text-temp-green-500 underline focus:outline-none"
                to="/"
              >
                I will do this later
              </Link>
            </div>
          </div>

          {/* Trigger modal after form submission */}
          {loading && <LoadingModal show={loading} />}

          {/* Trigger Message */}
          {props.alert.message && (
            <AlertModal
              show={props.alert.type ? true : false}
              type={props.alert.type}
              content={props.alert.message}
              linkUrl="/"
            />
          )}
        </div>
      </OnboardingContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default (
  connect(mapStateToProps, actions)(UploadProfilePicture)
);
