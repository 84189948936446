import React from "react";

const Tabs = ({ isLender, currentRentalOwner, handleSwitchRentalOwner }) => {
  return (
    <div
      className="relative py-4"
    >
      <div className="container mx-auto">
        <div className="relative flex flex-row text-xs lg:text-sm-15">
          {isLender ? (
            <button
              className={`pb-4 mr-8 relative rental-activity-tab focus:outline-none ${
                currentRentalOwner === "ownedByYou"
                  ? "bg-white text-temp-green-500 active"
                  : ""
              } `}
              onClick={() => handleSwitchRentalOwner("ownedByYou")}
            >
              Owned by you
            </button>
          ) : (
            ""
          )}

          <button
            className={`pb-4 relative rental-activity-tab focus:outline-none ${
              currentRentalOwner === "rentedByYou"
                ? "bg-white text-temp-green-500 active"
                : ""
            } `}
            onClick={() => handleSwitchRentalOwner("rentedByYou")}
          >
            Rented by you
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
