import React from "react";
import ItemListingInCarousel from "../../../components/blocks/itemBits/ItemListingInCarousel";

const RecommendedSection = ({ items, itemsLoading }) => {
  return (
    <ItemListingInCarousel
      sectionTitle="Recommended for you"
      items={items?.slice(6, 12)}
      itemsLoading={itemsLoading}
    />
  );
};

export default RecommendedSection;
