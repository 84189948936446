import React from "react";

export const BankIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1094 27.8438L31.6406 22.7812H18.9844L21.5156 27.8438H29.1094Z"
        fill="#F5A431"
      />
      <path
        d="M21.5156 27.8438H29.1094V48.0938H21.5156V27.8438Z"
        fill="#EB8117"
      />
      <path
        d="M18.9844 53.1562L21.5156 48.0938H29.1094L31.6406 53.1562H18.9844Z"
        fill="#F5A431"
      />
      <path
        d="M59.4844 27.8438L62.0156 22.7812H49.3594L51.8906 27.8438H59.4844Z"
        fill="#F5A431"
      />
      <path
        d="M51.8906 27.8438H59.4844V48.0938H51.8906V27.8438Z"
        fill="#EB8117"
      />
      <path
        d="M49.3594 53.1562L51.8906 48.0938H59.4844L62.0156 53.1562H49.3594Z"
        fill="#F5A431"
      />
      <path
        d="M3.79688 53.1562H77.2031V63.2812H3.79688V53.1562Z"
        fill="#F7C04A"
      />
      <path
        d="M3.79688 56.9531H77.2031V59.4844H3.79688V56.9531Z"
        fill="#F5A431"
      />
      <path
        d="M44.2969 27.8438L46.8281 22.7812H34.1719L36.7031 27.8438H44.2969Z"
        fill="#F5A431"
      />
      <path
        d="M36.7031 27.8438H44.2969V48.0938H36.7031V27.8438Z"
        fill="#EB8117"
      />
      <path
        d="M3.79688 15.1875H77.2031V22.7812H3.79688V15.1875Z"
        fill="#F7C04A"
      />
      <path
        d="M70.875 15.1875L40.5 3.79688L10.125 15.1875H70.875Z"
        fill="#F5A431"
      />
      <path
        d="M13.9219 27.8438L16.4531 22.7812H3.79688L6.32812 27.8438H13.9219Z"
        fill="#F5A431"
      />
      <path
        d="M6.32812 27.8438H13.9219V48.0938H6.32812V27.8438Z"
        fill="#EB8117"
      />
      <path
        d="M3.79688 53.1562L6.32812 48.0938H13.9219L16.4531 53.1562H3.79688Z"
        fill="#F5A431"
      />
      <path
        d="M50.625 36.7031H30.375C28.977 36.7031 27.8438 37.8364 27.8438 39.2344V74.6719C27.8438 76.0698 28.977 77.2031 30.375 77.2031H50.625C52.023 77.2031 53.1562 76.0698 53.1562 74.6719V39.2344C53.1562 37.8364 52.023 36.7031 50.625 36.7031Z"
        fill="#5771E5"
      />
      <path
        d="M45.5625 36.7031C45.5627 37.0389 45.4295 37.361 45.1923 37.5986L43.9267 38.8642C43.689 39.1014 43.367 39.2345 43.0312 39.2344H37.9688C37.633 39.2345 37.311 39.1014 37.0733 38.8642L35.8077 37.5986C35.5705 37.361 35.4373 37.0389 35.4375 36.7031H45.5625Z"
        fill="#4356E6"
      />
      <path
        d="M31.6406 44.2969H49.3594V56.9531H31.6406V44.2969Z"
        fill="#5EBD92"
      />
      <path
        d="M31.6406 51.8906H49.3594V56.9531H31.6406V51.8906Z"
        fill="#51A37E"
      />
      <path d="M31.6406 60.75H34.1719V63.2812H31.6406V60.75Z" fill="#4356E6" />
      <path d="M36.7031 60.75H49.3594V63.2812H36.7031V60.75Z" fill="#4356E6" />
      <path
        d="M31.6406 65.8125H37.9688V68.3438H31.6406V65.8125Z"
        fill="#4356E6"
      />
      <path d="M40.5 65.8125H43.0312V68.3438H40.5V65.8125Z" fill="#4356E6" />
      <path
        d="M31.6406 70.875H35.4375V73.4062H31.6406V70.875Z"
        fill="#4356E6"
      />
      <path
        d="M45.5625 70.875H49.3594V73.4062H45.5625V70.875Z"
        fill="#4356E6"
      />
      <path
        d="M35.4375 46.8281H45.5625V49.3594H35.4375V46.8281Z"
        fill="#51A37E"
      />
      <path
        d="M74.6719 27.8438L77.2031 22.7812H64.5469L67.0781 27.8438H74.6719Z"
        fill="#F5A431"
      />
      <path
        d="M67.0781 27.8438H74.6719V48.0938H67.0781V27.8438Z"
        fill="#EB8117"
      />
      <path
        d="M64.5469 53.1562L67.0781 48.0938H74.6719L77.2031 53.1562H64.5469Z"
        fill="#F5A431"
      />
      <path
        d="M38.6016 44.2969H42.3984V51.8906H38.6016V44.2969Z"
        fill="#F7C04A"
      />
      <path
        d="M38.6016 51.8906H42.3984V56.9531H38.6016V51.8906Z"
        fill="#F5A431"
      />
    </svg>
  );
};
