/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import store from "store";
import { firebaseSignOutUser } from "../../helpers/firebaseConn";
import { SEO } from "../../utils/helmet";
import Container from "../blocks/Container";
import CookieNoticePopup from "../blocks/CookieNoticePopup";
import Livechat from "../blocks/Livechat";
import Footer from "./Footer";
import Header from "./Header";

const KYCPopUp = (props) => {
  return (
    <div className="bg-kyc">
      <div className={`${!props.hasMainContainer && "container mx-auto px-4"}`}>
        <Link
          to={props.kycCompletionUrl}
          className="py-3 md:py-5 px-6 lg:px-8 xl:px-10 mb-8 w-full flex flex-col lg:flex-row lg:items-center flex-1">
          <p className="text-temp-primary text-xs md:text-sm lg:text-base mb-2 lg:mb-0 w-full text-center">
            Hello {props?.user?.firstname}, kindly complete your verification to
            fully verify your account 🔐.{" "}
            <span className="text-temp-green-500 underline pl-2">
              Complete Verification
            </span>
          </p>
        </Link>
      </div>
    </div>
  );
};

const MainContainer = (props) => {
  const [kycCompletePercentage, setKycCompletePercentage] = useState(0);
  const [kycCompletionUrl, setKycCompletionUrl] = useState("");
  const [completeKYC, setCompleteKYC] = useState(false);
  const [user, setUser] = useState({});
  const [showCookiePopup, setShowCookiePopup] = useState(true);
  const [cookies, setCookie] = useCookies(["visits"]);

  const [logOutClick, setLogOutClick] = useState(false);
  const [popUpDelay, setPopUpDelay] = useState(false);

  const navigate = useNavigate();
  const displayCookiePopUp = popUpDelay && cookies.closeCookiePopUp !== "true";

  const handleRemoveCookiePopup = () => {
    setShowCookiePopup(false);
    setCookie("closeCookiePopUp", true, { path: "/" });
  };

  const handleLogout = () => {
    setLogOutClick(true);

    setTimeout(() => {
      setLogOutClick(false);
      store.remove("temp_usER12_dATa");
      store.remove("temp_access_token");

      // Signout from firebase
      const storedUid = store.get("tempOwnfiReBaseUserAuth");
      let parsedUid;

      try {
        parsedUid = JSON.parse(storedUid);
      } catch (error) {
        parsedUid = storedUid;
      }

      const firebaseUid = parsedUid?.userId || parsedUid?.uid;

      if (firebaseUid) {
        firebaseSignOutUser(firebaseUid);
      } else {
        console.warn("No valid Firebase UID found for sign out");
      }

      setCompleteKYC(false);
      navigate("/");
    }, 1000);
  };

  useEffect(() => {
    const userData = store.get("temp_usER12_dATa");
    setUser(userData);
    setTimeout(() => setPopUpDelay(true), 2000);
    if (userData?.firstname && !userData?.verified_phone) {
      setCompleteKYC(true);
      setKycCompletionUrl("/verify");
      setKycCompletePercentage(25);
    } else if (userData?.verified_phone && !userData?.verified_id) {
      setCompleteKYC(true);
      setKycCompletionUrl("/complete-verification");
      setKycCompletePercentage(75);
    } else if (userData?.verified_phone && !userData?.image) {
      setCompleteKYC(true);
      setKycCompletionUrl("/complete-verification");
      setKycCompletePercentage(50);
    } else if (
      userData?.verified_phone &&
      userData?.verified_id &&
      userData?.image &&
      !userData?.verified_email
    ) {
      setCompleteKYC(true);
      setKycCompletionUrl("/verify-email");
      setKycCompletePercentage(75);
    } else {
      setCompleteKYC(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      setShowCookiePopup(false);
      setCookie("closeCookiePopUp", true, { path: "/" });
    };
  }, [setCookie]);

  return (
    <>
      <SEO
        title={props.pageTitle || "Own anything temporarily"}
        description={
          props.pageDescription ||
          "TempOwn is a secure and fully insured peer-to-peer rental marketplace for essential items and equipment."
        }
      />

      {displayCookiePopUp && (
        <CookieNoticePopup
          hide={!showCookiePopup}
          onClick={handleRemoveCookiePopup}
        />
      )}

      <Container hasMainContainer={props.hasMainContainer}>
        <>
          <Header
            hasMainContainer={props.hasMainContainer}
            logOutClick={logOutClick}
            handleLogout={handleLogout}
            bg={props.bg}
          />

          {completeKYC && (
            <KYCPopUp
              user={user}
              kycCompletePercentage={kycCompletePercentage}
              kycCompletionUrl={kycCompletionUrl}
              hasMainContainer={props.hasMainContainer}
            />
          )}

          <main>{props.children}</main>

          {!props.noFooter && (
            <Footer hasMainContainer={props.hasMainContainer} />
          )}
        </>
      </Container>

      <Livechat />
    </>
  );
};

MainContainer.propTypes = {
  pageTitle: PropTypes.string,
};

export default MainContainer;
