import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import InputGroup2 from "../element/InputGroup2";
import TextAreaGroup from "../element/TextAreaGroup";
import { compressImage } from "../../helpers/compressImage";
import InputFileGroup from "../element/InputFileGroup";

const OrderComplaintFormModal = ({
  show,
  handleCloseModal,
  processSubmitComplaint,
}) => {
  const initialState = {
    orderId: "",
    subject: "",
    feedback: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.subject) {
      errors.subject = "Subject is required";
    } else if (values.subject.length < 1) {
      errors.subject = "Subject is required";
    }

    if (!values.feedback) {
      errors.feedback = "Description of the complaint is required";
    } else if (values.feedback.length < 1) {
      errors.feedback = "Description of the complaint is required";
    }

    return errors;
  };

  const [images, setImages] = useState(["", ""]);
  const [displayImages, setDisplayImages] = useState(["", ""]);

  const handleImageChange = (e, key) => {
    // compress image
    compressImage(e, (data) => {
      images[key] = data;
      setImages([...images]);
    });

    var reader = new FileReader();
    reader.onload = function (e) {
      displayImages[key] = e.target.result;
      setDisplayImages([...displayImages]);
    };
    reader.readAsDataURL(e[0]);
  };

  const handleRemoveImage = (key) => {
    images[key] = "";
    setImages([...images]);

    displayImages[key] = "";
    setDisplayImages([...displayImages]);
  };

  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true">
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white py-10 sm:py-12">
            <div className="text-center">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="relative text-center px-5 sm:px-12">
                <button
                  onClick={handleCloseModal}
                  className="absolute text-temp-green-500 text-lg rounded-full shadow-lg  pt-1 px-2 right-8 -top-10 focus:outline-none">
                  x
                </button>
                <h3
                  className="leading-6 text-xl  md:text-2xl lg:text-2xl font-medium text-temp-secondary lg:font-semibold"
                  id="modal-headline">
                  Complaint Form
                </h3>
                <div className="mt-8 md:mt-10">
                  <Formik
                    initialValues={initialState}
                    validate={validate}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);

                      const payload = {
                        subject: values.subject,
                        feedback: values.feedback,
                      };

                      await processSubmitComplaint(payload, images);
                      setSubmitting(false);
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="w-full flex flex-wrap justify-between text-left">
                            <div className="w-full">
                              <InputGroup2
                                labelColor="text-temp-gray"
                                className="mb-6"
                                type="text"
                                label="Subject *"
                                name="subject"
                                placeholder={"E.g Item Damaged"}
                                value={values.subject}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.subject &&
                                  touched.subject &&
                                  errors.subject
                                }
                              />
                            </div>
                            <div className="w-full">
                              <TextAreaGroup
                                labelColor="text-temp-gray"
                                marginBottom="mb-6"
                                label="Feedback *"
                                name="feedback"
                                value={values.feedback}
                                onChange={handleChange}
                                showTip={false}
                                error={
                                  errors.feedback &&
                                  touched.feedback &&
                                  errors.feedback
                                }
                              />
                            </div>

                            <div className="w-full mb-6">
                              <p className="text-temp-gray pb-4">
                                Attach Images
                              </p>
                              <div className="flex flex-wrap flex-row space-x-4">
                                {displayImages?.map((a, index) => (
                                  <InputFileGroup
                                    key={index}
                                    preview={a}
                                    onDrop={(e) => handleImageChange(e, index)}
                                    accept="image/*"
                                    onDeleteClick={() =>
                                      handleRemoveImage(index)
                                    }
                                    marginBottom="mb-0"
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex items-baseline justify-end flex-end">
                          <button
                            disabled={isSubmitting ? true : false}
                            type="submit"
                            className="mb-4 py-4 px-10 text-sm bg-gradient font-bold text-white rounded-xl focus:outline-none">
                            {isSubmitting ? "Processing..." : "Submit"}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="border-t border-temp-green-light mt-4">
                <div className="px-5 sm:px-12 text-sm-15 text-temp-gray">
                  <p className="pt-6 ">
                    Head Office: 32b Okunola Aina Street, Maryland, Lagos.
                  </p>
                  <div className="w-full inline-flex flex-wrap items-baseline justify-center space-x-1 lg:space-x-2 text-sm">
                    <p>
                      Enquires:{" "}
                      <a
                        href="mailto:hello@tempown.com"
                        className="text-temp-green-500 font-bold">
                        hello@tempown.com
                      </a>
                    </p>
                    <p>|</p>
                    <p>08122888638</p> <p>09158428464</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderComplaintFormModal;
