import Actions from "../actions/actionTypes";

const initialState = {
  categories: [],
  types: [],
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case Actions.GET_TYPES:
      return {
        ...state,
        types: action.payload,
      };

    default:
      return state;
  }
};

export default CategoryReducer;
