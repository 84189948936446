import React from "react";

const InsurancePolicy = () => {
  return (
    <div className="mb-16">
      <p className="leading-7 text-base">
        TempOwn offers compulsory insurance to Owners of rental items/equipment
        on its platform. The
        Insurance Policy insures rental items/equipment against damage and loss
        on a per rental basis as described in this document subject to the
        terms, conditions and exclusions of the policy. This Policy covers
        rental items/equipment with <strong>serial numbers</strong> only.
      </p>
      <br />
      <p>
        In the following sections, <strong>You</strong> will learn everything
        you need to know about our Insurance Policy and how to contact{" "}
        <strong>Us</strong> in case <strong>You</strong> have a claim.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Definitions
      </h3>

      <p>
        <strong>“Serial Number”</strong> - A unique identifier assigned
        incrementally or sequentially to an item to uniquely identify it.
      </p>
      <br />
      <p>
        <strong>“Property Insured”</strong> - The rental item/equipment{" "}
      </p>
      <br />

      <p>
        <strong>“Us”/”Company”</strong> - TempOwn
      </p>
      <br />
      <p>
        <strong>“You”/”Insured”/”PolicyHolder”</strong> - The Owner who insures
        a rental item/ equipment under TempOwn Protect.
      </p>
      <br />
      <p>
        <strong>“Insurer”</strong> - The Insurance Company providing the insured
        with financial coverage against loss or damage. In this instance,
        Consolidated Hallmark Insurance Plc.
      </p>
      <br />

      <p>
        <strong>“Broker”</strong> - The Company that arranges the insurance
        cover with the Insurer. In this instance, Stanbic IBTC
      </p>
      <br />

      <p>
        <strong>“Accidental damage”</strong> - Any sudden, unforeseen and
        unintended damage to the insured property.
      </p>
      <br />

      <p>
        <strong>“Claim”</strong> - shall mean claims or series of claims arising
        out of one cause
      </p>
      <br />
      <p>
        <strong>“Terms”</strong> - Terms include conditions, warranties and
        exceptions of this Policy.
      </p>
      <br />
      <p>
        <strong>“Material Facts”</strong> - Every information which can
        influence the Company’s decision in accepting the risk and determining
        the terms.
      </p>
      <br />
      <p>
        <strong>“Insurance Period”</strong> - The period when the insured
        equipment is rented and ended (i.e. the rental start date to rental end
        date as specified in the booking ticket). It does not cover late returns
        but covers extended dates approved by TempOwn.
      </p>
      <br />
      <p>
        <strong>“Replacement Value”</strong> - Cost of replacement of the
        insured equipment by equipment of the same type, capacity and quality.
      </p>
      <br />
      <p>
        <strong>“Current Value”</strong> - The replacement cost of equipment,
        less allowance for depreciation taking into account its age.
      </p>
      <br />
      <p>
        <strong>“Purchase Value”</strong> - The original value a rental item/
        equipment was bought for, evidenced by a purchase receipt.
      </p>
      <br />
      <p>
        <strong>“Insurance Fee”</strong> - The fee the Insured pays to insure
        their rental equipment at the point of rental. This is 10% of the total
        rental cost.
      </p>
      <br />
      <p>
        <strong>“Service Charge”</strong> - TempOwn charges a service fee of 19%
        of the total rental cost
      </p>
      <br />
      <p>
        <strong>“Policy Excess”</strong> - This is the amount that the insurer
        shall not be liable to pay on any claim brought by the insured in
        respect of which indemnity is provided by this policy. The amount is set
        at 10% of the claim value.
      </p>
      <br />
      <p>
        <strong>“Depreciation”</strong> - The reduction in the value of an asset
        over time, due in particular to wear and tear.
      </p>
      <br />
      <p>
        <strong>“Depreciation Fee”</strong> - A 10% fee charged by the Insurer
        on items insured by a policyholder.
      </p>
      <br />
      <p>
        <strong>“Total Sum Insured”</strong> - The insurance fee paid on the
        property insured.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Scope of Cover
      </h3>
      <p>
        If at any time during the Period of Insurance the property insured or
        any part thereof be lost, destroyed or damaged by any accident or
        misfortune other than that specifically excluded, subject to the terms
        of this Policy, the Insured will indemnify the Insured in respect of
        such damage or loss by payment, reinstatement, replacement or repair at
        the option of the Insurer to an amount not exceeding the total sum
        insured.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Scope of Cover{" "}
      </h3>
      <p>
        If at any time during the Period of Insurance stated in the schedule,
        the equipment or any part thereof suffer any accidental loss or damage
        from any cause other than that specifically excluded, in a manner
        necessitating repair or replacement, the Company will indemnify the
        Insured in respect of such loss or damage.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Exclusions
      </h3>
      <p>
        TempOwn Protect policy does not cover:
        <br />
        <br />
        a) Items, tools and equipment without serial numbering or unique
        identification numbering.
        <br />
        <br />
        b) Losses arising from faults or defects existing at the time of
        commencement of this policy which are within the knowledge of the Vendor
        or his representatives, whether such faults or defects were known to
        TempOwn or not.
        <br />
        <br />
        c) Electrical or mechanical derangement not caused by accidental means;
        <br />
        <br />
        d) Loss or damage caused by or arising from:- (i) Wear, tear and
        depreciation; (ii) Gradual deterioration from whatever cause; or (iii)
        Inherent vice or defects.
        <br />
        <br />
        e) Costs incurred in connection with the elimination of functional
        failures unless such failures were caused by an indemnifiable loss or
        damage to the insured equipment;
        <br />
        <br />
        f) Any cost or expenses incurred in connection with the maintenance of
        the insured equipment;
        <br />
        <br />
        g) loss or damage for which the manufacturer or supplier of the insured
        equipment is responsible either by law or under contract;
        <br />
        <br />
        h) Loss of or damage to rented or hired equipment for which the owner is
        responsible by law or under a lease and/or maintenance agreement;
        <br />
        <br />
        i) Aesthetic defects, such as scratches on painted polished or enamelled
        surfaces;
        <br />
        <br />
        j) Willful acts or negligence of the Insured or his representative;
        <br />
        <br />
        k) Loss or damage resulting from use of any equipment after the damage
        has occurred but before permanent repairs have been affected and normal
        operations restored;
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Basis of Settlement
      </h3>
      <p>
        a) In cases where the damaged equipment can be repaired, the Insurer
        will pay the expenses necessarily incurred to restore the damaged
        equipment to its former state of serviceability including the cost of
        dismantling and re-erection (if any) incurred for the purpose of
        effecting the repairs, to the extent that such costs have been included
        in the sum insured.
        <br />
        <br />
        b) In the event that an insured item is lost or totally destroyed, the
        Insurer shall pay either the purchase value or the current market value
        of the rental item/equipment immediately before the loss. Such value
        will be calculated by deducting depreciation of 10% from the replacement
        value of the equipment and a policy excess fee of 10% from the remaining
        sum.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Documentary Evidence for loss
      </h3>
      <p>
        In the event of a claim for loss arising under this policy, the Insured
        will produce Documentary Evidence of the amount of value of the property
        insured immediately prior to the happening of the loss.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Eligibility of Claim
      </h3>
      <p>To claim a TempOwn guarantee on any equipment, you must:</p>
      <br />
      <ol className="space-y-3 list-inside list-decimal">
        <li>Have a verified TempOwn account</li>
        <li>
          The verified account must have hosted the equipment on which a claim
          is being made
        </li>
        <li>
          Provide proof of ownership and any other required documentation on
          that rental item/equipment.
        </li>
      </ol>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Claims Procedures
      </h3>
      <p>
        In event of any happening which may give rise to a claim under this
        Policy, the Insured:{" "}
      </p>
      <br />
      <ol className="space-y-3 list-inside list-decimal">
        <li>shall give immediate notice in writing to the Insurer:</li>
        <li>
          in the event of theft or any attempt threat or loss, shall give
          immediate notice thereof to the Police or equivalent authority;
        </li>
        <li>
          take practicable steps towards the discovery and punishment of guilty
          person and to trace and recover the property lost or stolen;
        </li>
        <li>
          shall as soon as possible and in any case within fourteen days of the
          event giving rise thereto at his own expenses deliver to the Insurer a
          claim with full particulars of the circumstances of the event, the
          property affected, the value thereof, and of the loss or damage
          thereto;
        </li>
        <li>
          shall furnish all such vouchers, proof, explanation, and other
          evidence as may reasonably be required by the Insurer together with a
          statutory declaration if required in the verification of claims;
        </li>
        <li>
          shall, at the expense of the Insurer, give the Insurer all such
          assistance as they may reasonably require with a view to the Insured
          may have against any Police or other authority or against anyone in
          respect of any loss whether it has or has not been paid or any
          fraudulent devices are used by the Insured or anyone acting on his
          behalf to obtain any benefit under this Policy all benefit thereunder
          and all money paid in respect thereof shall be fortified.
        </li>
      </ol>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Fraudulent Claims
      </h3>
      <p>
        If such a claim is in any respect fraudulent or any fraudulent devices
        are used by the Insured or anyone acting on his behalf to obtain any
        benefit under this Policy all benefit thereunder and all money paid in
        respect thereof shall be forfeited. The Company may, in addition, refer
        the matter to the relevant law enforcement authorities.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Claim Procedure Continued
      </h3>
      <p>
        The Insurer may at any time after the occurrence of damage to the
        Property Insured take and keep possession of the property concerned and
        deal with the salvage, and this policy shall be proof of leave and
        license for such purpose, and if the Insured or anyone acting on his
        behalf shall obstruct or prevent the Insurer from so doing, all benefit
        under this Policy shall be forfeited. Upon payment of any claim for loss
        under this Policy, the property in respect of which the payment is made
        shall belong to the Insurer. No property may be abandoned by the
        Insurer.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Transfer of Rights
      </h3>
      <p>
        Nothing contained in this Policy shall, unless expressly stated, give
        any rights against the Company to any person other than the Insured, and
        the Company will not be bound by any passing of the interest otherwise
        than by death or operation of law.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Automatic Cancellation/Termination
      </h3>
      <p>
        This Policy automatically terminates at the end of the rental period and
        cannot be cancelled once the rental period takes effect.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Out-of-State Use of Rental Item
      </h3>
      <p>
        Where an Insured (without the express consent of TempOwn) grants
        approval for the use of his/her rental item out of the state where it is
        rented, such Insured will be responsible for all and any losses and
        damages that may happen to the rental item. Approval of the use of a
        rental item/equipment outside the state where it was rented nullifies
        any coverage on such rental item/ equipment by TempOwn (including
        TempOwn Protect).
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Third-Party Exclusion
      </h3>
      <p>
        TempOwn Protect does not cover third party damages. TempOwn shall not be
        responsible to the Insured for claims by third parties.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        No Third-Party Cover
      </h3>
      <p>
        This policy only covers the insured rental item/equipment when it is
        rented by Borrowers through the TempOwn platform. It does not cover
        damages or losses incurred on the rental item when used by a person who
        is not a party to the rental.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Due observance
      </h3>
      <p>
        Compliance, observance and fulfilment of the terms of this Policy by the
        Insured shall be a condition precedent to any liability attaching under
        this Policy.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Dispute Resolution
      </h3>
      <ol className="space-y-3 list-inside list-disc">
        <li>
          For any disputes arising out of this Policy, the insured shall
          endeavour to resolve the matter by negotiation with the Company.
        </li>
        <li>
          Any disputes or issues not resolved by negotiation 30 days after the
          dispute arising may be resolved through a sole mediator jointly
          appointed by the parties in writing. This will be the last resort of
          the Parties.
        </li>
      </ol>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Resolved Claims
      </h3>
      <p>
        This policy does not cover any cost incurred after a claim is filed,
        during the processing of the claim and after the claims have been
        resolved.
      </p>
      <br />

      <h3
        id="depositPolicy"
        className="text-3xl text-temp-green-500 font-serif mb-6 mt-4"
      >
        TempOwn Deposit Policy
      </h3>

      <p>
        This Policy applies to Owners with rental items/equipment without
        categorization and serial numbers. The Policy provides a form of
        collateral to Owners of rental items/equipment against damage and loss.
        Under this policy, Owners will be free to choose from our pre-selected
        deposit fee options which TempOwners are required to pay before the
        booking start date.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Definitions
      </h3>

      <p>
        <strong>“Deposit Fee”</strong> - A refundable sum of money deposited as
        security for good use of a rental item/equipment.
      </p>
      <br />
      <p>
        <strong>“Service Charge”</strong> - TempOwn charges the Owner a service
        fee of 19% of the total rental amount.
      </p>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Deposit Claim Procedure
      </h3>
      <p>
        In event of any happening which may give rise to a deposit claim under
        this Policy, the Owner:
      </p>
      <br />
      <ol className="space-y-3 list-inside list-decimal">
        <li>shall within 2 days, give notice to the Company:</li>
        <li>
          shall furnish proof, explanation, photographic and other evidence of
          any damage to the rental item/equipment as may reasonably be required
          by the Company;
        </li>
        <li>
          the Company shall within 2 days of the notice, investigate the
          validity of the claim and take necessary steps;
        </li>
        <li>
          where the claim is valid, the Company shall immediately pay the
          deposit fee to the Owner.
        </li>
        <li>
          where the claim is not valid, the Company shall refund the TempOwner
          the deposit fee paid.
        </li>
      </ol>
      <br />

      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        TempOwn’s Discretion
      </h3>
      <p>
        The Company shall have absolute discretion as to the determination of
        the validity or otherwise of a deposit claim.
      </p>
      <br />
      <h3 className="font-semibold text-xl text-temp-primary mb-4 mt-3">
        Dispute Resolution
      </h3>
      <br />
      <ol className="space-y-3 list-inside list-decimal">
        <li>
          For any disputes arising out of this Policy, the insured shall
          endeavour to resolve the matter by negotiation with the Company.
        </li>
        <li>
          Any disputes or issues not resolved by negotiation 30 days after the
          dispute arising may be resolved through a sole mediator jointly
          appointed by the parties in writing. This will be the last resort of
          the Parties.
        </li>
      </ol>
      <br />
    </div>
  );
};

export default InsurancePolicy;
