import React from "react";

export const GiftIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M24.375 13.0001H1.625C0.729083 13.0001 0 12.271 0 11.3751V7.04175C0 6.14583 0.729083 5.41675 1.625 5.41675H24.375C25.2709 5.41675 26 6.14583 26 7.04175V11.3751C26 12.271 25.2709 13.0001 24.375 13.0001ZM1.625 6.50008C1.32708 6.50008 1.08333 6.74275 1.08333 7.04175V11.3751C1.08333 11.6741 1.32708 11.9167 1.625 11.9167H24.375C24.6729 11.9167 24.9167 11.6741 24.9167 11.3751V7.04175C24.9167 6.74275 24.6729 6.50008 24.375 6.50008H1.625Z"
          fill="#08A05C"
        />
        <path
          d="M21.1263 26.0001H4.8763C3.38239 26.0001 2.16797 24.7846 2.16797 23.2918V12.4584C2.16797 12.1594 2.41064 11.9167 2.70964 11.9167C3.00864 11.9167 3.2513 12.1594 3.2513 12.4584V23.2918C3.2513 24.1877 3.98039 24.9168 4.8763 24.9168H21.1263C22.0222 24.9168 22.7513 24.1877 22.7513 23.2918V12.4584C22.7513 12.1594 22.994 11.9167 23.293 11.9167C23.592 11.9167 23.8346 12.1594 23.8346 12.4584V23.2918C23.8346 24.7846 22.6202 26.0001 21.1263 26.0001Z"
          fill="#08A05C"
        />
        <path
          d="M12.9987 26.0001C12.6997 26.0001 12.457 25.7574 12.457 25.4584V12.4584C12.457 12.1594 12.6997 11.9167 12.9987 11.9167C13.2977 11.9167 13.5404 12.1594 13.5404 12.4584V25.4584C13.5404 25.7574 13.2977 26.0001 12.9987 26.0001Z"
          fill="#08A05C"
        />
        <path
          d="M13.0013 6.49992C12.9623 6.49992 12.9233 6.49559 12.8843 6.48692C12.2018 6.33525 8.74922 5.53467 7.83705 4.6225C7.32464 4.11225 7.04297 3.43192 7.04297 2.70825C7.04297 1.98459 7.32464 1.30425 7.83597 0.79292C8.89222 -0.26333 10.6104 -0.26333 11.6656 0.79292C12.5777 1.704 13.3783 5.15767 13.53 5.84125C13.5701 6.02217 13.5148 6.21067 13.3837 6.34067C13.2819 6.44359 13.1432 6.49992 13.0013 6.49992ZM9.75131 1.08434C9.33531 1.08434 8.91822 1.2425 8.60189 1.55884C8.29531 1.8665 8.1263 2.27384 8.1263 2.70825C8.1263 3.14267 8.29531 3.55 8.60189 3.85767C9.02331 4.27909 10.7848 4.84784 12.269 5.227C11.8887 3.74284 11.3211 1.98134 10.8996 1.55992C10.5844 1.2425 10.1673 1.08434 9.75131 1.08434Z"
          fill="#08A05C"
        />
        <path
          d="M12.9996 6.50006C12.8577 6.50006 12.719 6.44372 12.6161 6.34189C12.485 6.21081 12.4298 6.02231 12.4699 5.84247C12.6215 5.15889 13.4221 1.70631 14.3343 0.794141C15.3905 -0.263193 17.1087 -0.261026 18.1638 0.794141C18.6763 1.30439 18.9579 1.98472 18.9579 2.70839C18.9579 3.43206 18.6763 4.11239 18.1649 4.62372C17.2528 5.53589 13.8002 6.33647 13.1177 6.48814C13.0776 6.49572 13.0386 6.50006 12.9996 6.50006ZM16.2496 1.08447C15.8336 1.08447 15.4165 1.24264 15.1002 1.55897C14.6788 1.98039 14.1111 3.74189 13.7309 5.22606C15.215 4.84689 16.9765 4.27814 17.3979 3.85672C17.7056 3.55014 17.8746 3.14281 17.8746 2.70839C17.8746 2.27397 17.7056 1.86664 17.399 1.55897C17.0827 1.24264 16.6656 1.08447 16.2496 1.08447ZM14.7178 1.17656H14.7286H14.7178Z"
          fill="#08A05C"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
