import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, } from 'react-router-dom';
import InputGroup from '../../../components/element/InputGroup';
// const queryString = require("query-string");
import moment from "moment";
import { connect } from 'react-redux';
import MainAuthContainer from '../../../components/blocks/authContainer/MainAuthContainer';
import InputDatePicker from '../../../components/element/InputDatePicker';
import InputPhoneNumberGroup from '../../../components/element/InputPhoneNumberGroup';
import AlertModal from '../../../components/modals/AlertModal';
import LoadingModal from '../../../components/modals/LoadingModal';
import * as actions from "../../../store/actions";

const Signup = (props) => {
  const initialState = {
    firstname: "",
    lastname: "",
    phone: "",
    nin: "",
    dob: "",
  }

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "Required";
    } else if (values.firstname.length < 1) {
      errors.firstName = "Must be a valid name";
    }

    if (!values.lastname) {
      errors.lastname = "Required";
    } else if (values.lastname.length < 1) {
      errors.lastname = "Must be a valid name";
    }
    if (!values.phone) {
      errors.phone = "Required";
    } else if (values.phone?.length < 8) {
      errors.phone =
        "Invalid phone number format try this format +234 81x xxx xxx x";
    }

    if (!values.nin) {
      errors.nin = "Required";
    } else if (values.nin?.length < 11) {
      errors.nin = "Must be a valid NIN";
    }
    if (!values.dob) {
      errors.dob = "Date of birth is required";
    }


    return errors;
  };

  // const [referralCode, setReferralCode] = useState("");
  useEffect(() => {
    // const parsed = queryString.parse(props.location.search);
    // setReferralCode(parsed?.referralCode);
  }, []);

  const handleDisable = (isAdmin, values, isSubmitting) => {
    return (
      isSubmitting ||
      values.firstname?.length === 0 ||
      values.lastname?.length === 0 ||
      values.phone?.length === 0 ||
      values.nin?.length === 0 ||
      values.dob?.length === 0
    );

  };

  return <MainAuthContainer admin={true}>
    <Formik
      initialValues={initialState}
      validate={validate}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        setIsLoading(true);

        const payload = {
          number: values.nin,
          firstname: values.firstname,
          lastname: values.lastname,
          phone: "+" + (values?.phone?.startsWith("2340") ? values?.phone?.replace("2340","234") : values?.phone),
          dob: moment(values.dob).format("DD-MM-YYYY"),
        }

        await props.NINsignupApi(payload, props.history)
        setIsLoading(false);
        setSubmitting(false);

        if (props.alert.type === "success") {
          resetForm({ values: initialState });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap justify-between">
            <div className="grid grid-cols-2 gap-4 lg:gap-6 w-full">
              <div className="w-full">
                <InputGroup
                  label="First Name"
                  placeholder="First Name"
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.firstname &&
                    touched.firstname &&
                    errors.firstname
                  }
                />
              </div>

              <div className="w-full">
                <InputGroup
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.lastname && touched.lastname && errors.lastname
                  }
                />
              </div>
            </div>

            <div className="w-full">
              <InputPhoneNumberGroup
                type="tel"
                label="Phone Number"
                placeholder="Phone Number"
                name="phone"
                value={values.phone}
                onChange={(e) => {
                  setFieldValue("phone", e);
                }}
                onBlur={handleBlur}
                error={values.phone === "" ? undefined : errors.phone}
              // error={errors.phone && touched.phone && errors.phone} onBlur seems not to be working or called
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 w-full">
              <div className="w-full">
                <InputGroup
                  label="NIN"
                  placeholder="NIN"
                  name="nin"
                  value={values.nin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.nin && touched.nin && errors.nin}
                />
              </div>

              <div className="w-full -mt-3">
                <InputDatePicker
                  placeholder="Date of Birth"
                  name="dob"
                  value={values.dob}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  showMonthDropdown
                  autoComplete="off"
                  maxDate={new Date()}
                  onChange={(e) => {
                    setFieldValue("dob", e[0]);
                  }}
                  error={errors.dob && touched.dob && errors.dob}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-row justify-end mt-8 lg:mt-8">
            <button
              type="submit"
              disabled={handleDisable(props.admin, values, isSubmitting)}
              className={`font-bold block w-full lg:w-auto h-12 lg:h-14 px-14 text-white rounded-lg focus:outline-none text-sm-15 lg:text-base ${handleDisable(props.admin, values, isSubmitting)
                ? "bg-temp-green-500 opacity-60"
                : "bg-temp-green-500 bg-gradient"
                }
                                                `}
            >
              Get Started
            </button>
          </div>
        </form>
      )}
    </Formik>

    <div className="my-7">
      <div className="flex flex-wrap justify-center">
        <p className="text-temp-gray text-sm lg:text-base">
          Have an account?{" "}
          <span className="text-temp-green-500 font-bold">
            <Link to="/signin">Sign In</Link>
          </span>
        </p>
      </div>
    </div>

    {/* Trigger modal after form submission */}
    {isLoading && (
      <LoadingModal show={isLoading} title="Creating Account ..." />
    )}

    {/* Trigger Message */}
    {props.alert.message && (
      <AlertModal
        show={props.alert.type ? true : false}
        type={props.alert.type}
        content={props.alert.message}
      />
    )}
  </MainAuthContainer>
};


const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default (connect(mapStateToProps, actions)(Signup));
