import React, { useEffect } from "react";
import MainContainer from "../../components/layout/MainContainer";
import { scrollToTop } from "../../utils/scrollToTop";
import AboutHeaderSection from "./components/AboutHeaderSection";
import BottomSection from "./components/BottomSection";
import OurMissionSection from "./components/OurMissionSection";
import BriefSection from "./components/BriefSection"

const About = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MainContainer hasMainContainer={false}>
      <AboutHeaderSection />
      <OurMissionSection />

      <BriefSection />

      <BottomSection />
    </MainContainer>
  );
};

export default About;
