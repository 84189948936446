/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Formik } from "formik";
import { numberFormat } from "../../../../utils/numberFormat";
import CustomSelectGroup from "../../../../components/element/CustomSelectGroup";
import { rentalUnits } from "../../../../utils/rentalUnits";
import { calenderChargeTypes } from "../../../../utils/calenderChargeTypes";
import { siUnitsChargeTypes } from "../../../../utils/siUnitsChargeTypes";
import InputGroup2 from "../../../../components/element/InputGroup2";
import InputDiscount from "../../../../components/blocks/itemUploadBits/InputDiscount";
import DiscountCalculation from "../../../../components/blocks/itemUploadBits/DiscountCalculation";
import { formatPrice } from "../../../../utils/formatPrice";
import InputGroupWithPrefix from "../../../../components/element/InputGroupWithPrefix";
import PreviousButton from "./componentsBit/PreviousButton";
import TipCard from "../components/componentsBit/TipCard"
import NextButton from "./componentsBit/NextButton";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";

const PricingForm = ({ initialState, handleChangeCurrentStep }) => {
  const [showCalculationPerweek, setShowCalculationPerweek] = useState(false);
  const handleShowCalculationPerWeek = () => {
    setShowCalculationPerweek(!showCalculationPerweek);
  };

  const [showCalculationPermonth, setShowCalculationPermonth] = useState(false);
  const handleShowCalculationPerMonth = () => {
    setShowCalculationPermonth(!showCalculationPermonth);
  };

  const [showCalculationSIUnitDiscount, setShowCalculationSIUnitDiscount] = useState(false);
  const handleShowCalculationSIUnitDiscount = () => {
    setShowCalculationSIUnitDiscount(!showCalculationSIUnitDiscount);
  };

  return (
    <div className="flex justify-around xl:pr-20">
      <div className="w-4/12 hidden xl:block">
        <PreviousButton
          onClick={() => handleChangeCurrentStep("Step5")}
        />
      </div>
      <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12">
        <h2
          className="text-xl sm:text-2xl text-temp-primary font-medium"
        >
          Pricing
        </h2>
        <TipCard
          text="Pricing on this equipment is calculated in days, weeks and months.
        Having customer-friendly pricing attracts more users to you."
        />

        <div className="mt-4">
          <Formik
            initialValues={initialState}
            onSubmit={async (values) => {
              const payload = {
                price: values.price,

                rentalUnitType: values?.rentalUnitType,
                calenderType: values?.calenderType || "",

                siUnitType: values?.siUnitType,
                maximumCapacityPerDay: values?.maximumCapacityPerDay,
                maximumOrderForSiUnitsDiscount: values?.maximumOrderForSiUnitsDiscount,
                siUnitDiscount: values?.siUnitDiscount,

                discountPerWeek: values?.discountPerWeek || 0,
                discountBiWeekly: values?.discountBiWeekly || 0,
                discountPerMonth: values?.discountPerMonth || 0,
                discountMonthlyQuarterly: values?.discountMonthlyQuarterly || 0,
                discountMonthlyBiAnnually: values?.discountMonthlyBiAnnually || 0,
                discountYearly: values?.discountYearly || 0,
              };

              handleChangeCurrentStep("Step7", payload);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              isSubmitting,
              setFieldValue
            }) => {
              const handleSubmitable = (isSubmitting, values) => {
                if (isSubmitting) return false;
                if (!values?.rentalUnitType) return false;
                if (values?.rentalUnitType?.value === "byCalender" && !values?.calenderType?.value) return false;
                if (values?.rentalUnitType?.value === "bySiUnits" && !values?.siUnitType?.value) return false;
                if (values?.price === 0 || !values?.price) return false;
                return true;
              };
              return (
                <form className="mt-14" onSubmit={handleSubmit}>
                  <div className="mb-44 xl:mb-20">
                    <div className="group w-full">
                      <CustomSelectGroup
                        label={
                          <span>
                            Select Rental Units<span className="text-red-400">*</span>
                          </span>
                        }
                        name="rentalUnitType"
                        value={values.rentalUnitType}
                        error={
                          errors.rentalUnitType && touched.rentalUnitType && errors.rentalUnitType
                        }
                        options={rentalUnits?.map((unit) => ({
                          value: unit.value,
                          label: unit.name,
                          //   label: <>
                          //   <p className="text-black">{unit.name}</p>
                          //   <p className="text-xs lg:text-sm text-temp-gray mt-2 pb-2">{unit.description}</p>
                          // </>,
                        }))}
                        onChange={(e) => {
                          setFieldValue("rentalUnitType", e);

                          setFieldValue("calenderType", e?.value === "byCalender" ? {
                            value: calenderChargeTypes[0]?.value,
                            label: calenderChargeTypes[0]?.name,
                          } : "");
                          setFieldValue('siUnitType', "")
                          setFieldValue("price", 0);
                          setFieldValue("minimumRentalDays", "")
                        }}
                      />
                    </div>

                    {/* If value is selected,  */}
                    {
                      values?.rentalUnitType?.value && <div className="group w-full">
                        <CustomSelectGroup
                          label={
                            <span>
                              Select how you want to charge<span className="text-red-400">*</span>
                            </span>
                          }
                          name={values?.rentalUnitType?.value === "byCalender" ? "calenderType" : "siUnitType"}
                          value={values?.rentalUnitType?.value === "byCalender" ? values.calenderType : values.siUnitType}
                          options={values?.rentalUnitType?.value === "byCalender" ?
                            calenderChargeTypes?.map((type) => ({
                              value: type.value,
                              label: type.name,
                            })) : siUnitsChargeTypes?.map((type) => ({
                              value: type.value,
                              label: type.name,
                            }))}
                          onChange={(e) => {
                            setFieldValue(values?.rentalUnitType?.value === "byCalender" ? "calenderType" : "siUnitType", e);
                            setFieldValue("price", 0);

                            setFieldValue("discountPerWeek", 0);
                            setFieldValue("discountPerMonth", 0);

                            setFieldValue("maximumCapacityPerDay", 0);
                            setFieldValue("maximumOrderForSiUnitsDiscount", 0);
                            setFieldValue("siUnitDiscount", 0);

                            setFieldValue("minimumRentalDays", "")
                          }}
                        />
                      </div>
                    }

                    {
                      values?.rentalUnitType?.value &&
                      <InputGroupWithPrefix
                        className={"group w-full md:w-7/12 pt-3"}
                        label={<>
                          Set Price<span className="text-red-400">*</span>
                        </>}
                        error={errors.price && touched.price && errors.price}
                        prefixValue={"NGN"}
                        name="price"
                        value={values.price}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }

                    <>
                      {
                        values?.calenderType?.value === "day" ?

                          <>
                            {/* set discount for week and biweek only */}
                            <div className="flex flex-wrap justify-between pt-4 lg:space-x-4">
                              <InputDiscount
                                title={"Per Week"}
                                name={"discountPerWeek"}
                                value={values?.discountPerWeek}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />

                              <DiscountCalculation
                                formattedPrice={formatPrice(values?.price, values?.discountPerWeek)}
                                price={values?.price}
                                discount={values?.discountPerWeek}
                                show={showCalculationPerweek}
                                handleShow={handleShowCalculationPerWeek}
                                discountType={"week"}
                              />
                            </div>

                            <div className="flex flex-wrap justify-between pt-4 lg:space-x-4">
                                <InputDiscount
                                  title={"Per Month"}
                                  name={"discountPerMonth"}
                                  value={values?.discountPerMonth}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />

                                <DiscountCalculation
                                  formattedPrice={formatPrice(values?.price, values?.discountPerMonth)}
                                  price={values?.price}
                                  discount={values?.discountPerMonth}
                                  show={showCalculationPermonth}
                                  handleShow={handleShowCalculationPerMonth}
                                  discountType={"month"}
                                />
                              </div>
                          </> :
                          ""
                      }
                    </>

                    <>
                      {
                        values?.siUnitType?.value && <>
                          <div className="group w-full">
                            <InputGroup2
                              autoComplete="off"
                              type={"number"}
                              label={
                                <span>
                                  Item Maximum Capacity Per Day  <span className="text-xs md:text-sm text-temp-primary">
                                    (in {values?.siUnitType?.value})
                                  </span>
                                </span>
                              }
                              placeholder={`Enter number of ${values?.siUnitType?.value}`}
                              name="maximumCapacityPerDay"
                              value={values.maximumCapacityPerDay}
                              onChange={(e) => {
                                setFieldValue("maximumCapacityPerDay", e.target.value);
                              }}
                              onBlur={handleBlur}
                              error={errors.maximumCapacityPerDay && touched.maximumCapacityPerDay && errors.maximumCapacityPerDay}
                            />
                          </div>

                          <div className="group w-full">
                            <InputGroup2
                              autoComplete="off"
                              type={"number"}
                              label={
                                <span>
                                  Maximum No. of {values?.siUnitType?.value} Eligible for Discount
                                </span>
                              }
                              placeholder={`Enter max. number of ${values?.siUnitType?.value}`}
                              name="maximumOrderForSiUnitsDiscount"
                              value={values.maximumOrderForSiUnitsDiscount}
                              onChange={(e) => {
                                setFieldValue("maximumOrderForSiUnitsDiscount", e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                          </div>

                          {/* set discount for max equipment ordered for si unit */}
                          <div className="flex flex-wrap justify-between pt-4 lg:space-x-4">
                            <InputDiscount
                              title={`Per Max. number of ${values?.siUnitType?.value}`}
                              name={"siUnitDiscount"}
                              value={values?.siUnitDiscount}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />

                            <DiscountCalculation
                              formattedPrice={formatPrice(values?.price, values?.siUnitDiscount)}
                              price={values?.price}
                              discount={values?.siUnitDiscount}
                              show={showCalculationSIUnitDiscount}
                              handleShow={handleShowCalculationSIUnitDiscount}
                              discountType={"max. order"}
                            />
                          </div>
                        </>
                      }
                    </>
                    <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mt-8 mb-28">
                      <NextButton
                        type={"submit"}
                        disabled={!handleSubmitable(isSubmitting, values)}
                        onClick={() => false}
                        title="Next"
                      />
                    </div>
                  </div>

                  <FixedBottomPrevNextButton
                    handlePrevClick={() => handleChangeCurrentStep("Step5")}
                    type={"submit"}
                    disabled={!handleSubmitable(isSubmitting, values)}
                    onClick={() => false}
                    title="Next"
                  />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PricingForm;
