import React from 'react';
import MasterCardLogo from "../../../assets/images/svg/mastercard.svg";
import VisaCardLogo from "../../../assets/images/png/visa.png";
import Loader from "../../../components/blocks/Loader";

const CardsDetails = ({
  loadingBankCards,
  bankCards,
}) => {
  return (
    <>
      <div className="my-16 relative w-full">
        {loadingBankCards ? (
          <div className="w-full">
            <Loader />
          </div>
        ) : (
          <>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
              {bankCards.map((card, index) => (
                <div key={index} className="mb-6">
                  <div
                    className="px-8 py-7 border border-temp-green-500 rounded-2xl"
                    style={{
                      boxShadow: "0px 3.46067px 17.3034px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div className="pb-10 relative flex justify-between items-start">
                      <img src={card?.card_type === "visa " ? VisaCardLogo : MasterCardLogo} className="flex-shrink w-14 h-14 object-contain" alt="" />
                    </div>

                    <p className="text-temp-primary pb-2 text-sm">
                      {card?.account_name}
                    </p>
                    <p className="text-lg md:text-xl text-temp-primary pb-2 font-bold">
                      ***{card?.last4}
                    </p>

                    <p className="text-temp-green-500 text-sm">{card?.exp_month}-{card?.exp_year}</p>
                  </div>
                </div>
              ))}
            </div>

          </>
        )}
      </div>
    </>
  );
};

export default CardsDetails;
