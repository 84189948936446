import { Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, } from "react-router-dom";
import AuthFooterCompany from "../../../components/blocks/authContainer/AuthFooterCompany";
import MainAuthContainer from "../../../components/blocks/authContainer/MainAuthContainer";
import InputPhoneNumberGroup from "../../../components/element/InputPhoneNumberGroup";
import AlertModal from "../../../components/modals/AlertModal";
import LoadingModal from "../../../components/modals/LoadingModal";
import * as actions from "../../../store/actions";

const ForgotPassword = (props) => {
  const initialState = {
    phone: "",
  };

  const [tel, setTel] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.phone) {
      errors.phone = "Required";
    } else if (values.phone?.length < 8) {
      errors.phone =
        "Invalid phone number format try this format +234 81x xxx xxx x";
    }

    return errors;
  };

  return (
    <MainAuthContainer
      admin={props.admin}
      title="Forgot Password"
      subTitle={"Can't remember your password? Kindly fill the form below."}
      className="pt-0"
    >

      <Formik
        initialValues={initialState}
        validate={validate}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setIsLoading(true);

          const payload = {
            phone: "+" + (values?.phone?.startsWith("2340") ? values?.phone?.replace("2340", "234") : values?.phone),
          };

          setTel(payload.phone)

          await props.resetPasswordRequestApi(payload.phone);

          setIsLoading(false);
          setSubmitting(false);

          if (props.alert.type === "success") {
            resetForm({ values: initialState });
          }
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          handleBlur,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap justify-between">
            <div className="w-full">
                <InputPhoneNumberGroup
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="phone"
                  value={values.phone}
                  onChange={(e) => {
                    setFieldValue("phone", e);
                  }}
                  onBlur={handleBlur}
                  error={values.phone === "" ? undefined : errors.phone}
                />
              </div>
            </div>

            <div className="w-full">
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  disabled={isSubmitting || !values.phone}
                  className={`font-bold w-full lg:w-auto py-4 px-14 text-white rounded-lg focus:outline-none text-sm-15 lg:text-base ${
                    isSubmitting || !values.phone ? "bg-gradient opacity-75" : "bg-gradient"
                  }`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <div className="text-center pt-14 lg:pt-20 mb-7">
        <p className="text-temp-gray text-sm lg:text-base">
          Don’t have an account?{" "}
          <Link
            to="/signup"
            className="text-temp-green-500"
          >
            Sign up
          </Link>
        </p>
      </div>

      <div className="mt-20 block lg:hidden">
        <AuthFooterCompany />
      </div>

      {/* Trigger modal after form submission */}
      {isLoading && (
        <LoadingModal show={isLoading} title="Processing Request ..." />
      )}

      {/* Trigger Message */}
      {props.alert.message && (
        <AlertModal
          show={props.alert.type ? true : false}
          type={props.alert.type}
          content={props.alert.message}
          linkUrl={props.alert.type === "success" ? `/reset-password?tel=${tel?.slice(1)}` : null}
        />
      )}
    </MainAuthContainer>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default (connect(mapStateToProps, actions)(ForgotPassword));
