import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const InputPhoneNumberGroup2 = ({
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  tip,
  ...props
}) => {
  return (
    <div className="mb-4">
      <PhoneInput
        country={"ng"}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        countryCodeEditable={false}
        containerClass={`w-full rounded-xl placeholder-temp-gray text-sm sm:text-base h-12 sm:h-12 md:h-14 lg:h-14 px-4 sm:px-6 text-temp-primary focus:outline-none bg-temp-green-100
      }`}
        buttonStyle={{
          borderTopLeftRadius: ".5rem",
          borderBottomLeftRadius: ".5rem",
          background: "transparent",
          border: "none",
        }}
        inputStyle={{
          height: "inherit",
          border: "none",
          background: "transparent",
          width: "100%",
          borderTopRightRadius: ".5rem",
          borderBottomRightRadius: ".5rem",
        }}
        {...props}
      />
      {tip && (
        <p className="pt-2 pb-2 text-temp-secondary text-xs lg:text-sm">{tip}</p>
      )}
      <span className="text-red-600 text-xs lg:text-sm">{error}</span>
    </div>
  );
};

export default InputPhoneNumberGroup2;
