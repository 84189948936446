import React from "react";
import StarRating from "../StarRating";

const ItemPreloader = () => {
  const items = [
    {
      id: "cat1",
      title: "Tesla XNS 2019",
      image: "",
      url: "",
    },
    {
      id: "cat2",
      title: "Tractor M120e",
      image: "",
      url: "",
    },
    {
      id: "cat3",
      title: "Nicon Camera",
      image: "",
      url: "",
    },
    {
      id: "cat4",
      title: "Tractor XD192",
      image: "",
      url: "",
    },
  ];

  return (
    <div className="mb-10 md:mb-20">
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 justify-center md:justify-between opacity-80">
        {items.map((item) => (
          <div key={item.id} className="item relative mb-4 md:mb-8">
            <div>
              <div className="card relative flex flex-col min-w-0 h-full w-full box-border rounded-lg bg-temp-green-100">
                <div className="">
                  <div className="w-full h-32 md:h-52 rounded-tl-lg rounded-tr-lg border-none bg-temp-green-light opacity-40"></div>
                </div>

                <div className="card-body text-temp-primary opacity-40 text-sm md:text-base font-bold flex-1 p-5 py-6">
                  <p className="text-base text-temp-primary leading-7 h-6 w-10/12 bg-temp-primary rounded-md"></p>
                </div>
                <div className="card-footer px-3 md:px-5 pb-5">
                  <div className="flex flex-wrap justify-between items-center">
                    <div className="flex items-center">
                      <div className="mr-2 md:mr-3 w-8 h-8 md:h-9 md:w-9 object-cover bg-temp-primary rounded-full opacity-40"></div>
                      <div className="flex flex-col">
                        <p className="text-sm text-temp-secondary w-8/12 h-2 mb-2 bg-temp-primary opacity-40"></p>
                        <ul className="flex justify-start opacity-40">
                          <StarRating rating={0} />
                        </ul>
                      </div>
                    </div>
                    <div className="">
                      <div className="price-tag text-temp-green rounded-lg w-32 font-bold text-xs py-2 px-6 bg-white">
                        <p className="h-3 bg-temp-green-light opacity-40"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemPreloader;
