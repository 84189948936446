/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { } from "react-router";
import MainContainer from "../../../components/layout/MainContainer";

import store from "store";
import ItemPreloader from "../../../components/blocks/preloader/ItemPreloader";
import * as actions from "../../../store/actions";

import { Link } from "react-router-dom";
import FixedBottomBox from "../../../components/blocks/FixedBottomBox";
import NoRecordReturned from "../../../components/blocks/NoRecordReturned";
import ItemListing from "../../../components/blocks/itemBits/ItemListing";
import Paginator from "../../../utils/pagination";
import { scrollToTop } from "../../../utils/scrollToTop";

const LenderItems = (props) => {
  const [userId, setUserId] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [selectedOption, setSelectedOption] = useState("all status");
  const [params, setParams] = useState({});

  const handleChangeSelectedOption = (value) => {
    setSelectedOption(value);
    if (value === "all status") {
      setParams({
        ...params,
        status: undefined
      })
      fetchItem({
        ...params,
        status: undefined
      })
    } else {
      const param = {
        status: value === "approved" ? "approved" : value === "pending" ? "pending" : value === "rejected" ? "rejected" : undefined
      }
      setParams({
        ...params,
        ...param
      })

      fetchItem({
        ...params,
        ...param
      })
    }
  };
  useEffect(() => {
    scrollToTop();
    const id = store.get("temp_usER12_dATa")?._id;
    setUserId(id);

    setParams({
      ...params,
      userId: id
    })

    fetchItem({
      userId: id,
    });
  }, []);

  useEffect(() => {
    setItems(props.items);
    setPagination(props.pagination);
  }, [props.items]);

  const fetchItem = async (param) => {
    setLoading(true);
    await props.getItemsApi(param);

    setLoading(false);
  };

  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchValue(value)
    if (e.keyCode === 13) {
      if (searchValue === "") {
        setParams({
          ...params,
          search: undefined
        })
        fetchItem({
          ...params,
          search: undefined
        })
      } else {
        setParams({
          ...params,
          search: searchValue
        })

        fetchItem({
          ...params,
          search: searchValue
        })
      }

    }

  };
  return (
    <MainContainer hasMainContainer={false}>
      <div className="container px-4 mx-auto w-full py-10 lg:py-16">
        <div className="flex justify-between items-baseline flex-wrap">
          <h3 className="font-medium text-2xl lg:text-3xl text-temp-primary self-end">
            My Items
          </h3>
          {/* Desktop only */}
          <Link
            to="/upload-item/start"
            className="hidden md:block bg-temp-green-500 bg-gradient rounded-lg py-3 px-4 lg:px-6 text-white font-semibold focus:outline-none text-sm lg:text-base"
          >
            Create New Item
          </Link>
        </div>

        <div className="flex py-8 lg:py-12">
          <input
            onKeyUp={handleSearch}
            className="w-full md:w-5/12 lg:w-4/12 xl:w-3/12 rounded-xl border border-temp-secondary placeholder-temp-gray text-sm sm:text-base  h-12 lg:h-14 px-4 sm:px-6 text-temp-secondary focus:outline-none"
            placeholder="Search"
            type="text"
          />
          {/* <SelectStatusOptions
            selectedOption={selectedOption}
            handleChangeSelectedOption={handleChangeSelectedOption}
          /> */}
        </div>

        <div className="">
          {loading ? (
            <ItemPreloader />
          ) : items?.length > 0 ? (
            <>
              <ItemListing
                urlPath="my-items"
                items={items}
              />
              <Paginator
                pageLimit={12}
                maxPages={pagination?.totalPages}
                currentPage={pagination?.page}
                getPageApi={fetchItem}
                setLoading={setLoading}
                objectParams={params}
              />
            </>
          ) : (
            <NoRecordReturned selectedImage={2} />
          )}
        </div>

        {/* Mobile only */}
        <FixedBottomBox
          title={<span className="pl-4">Earn More today!</span>}
          isCheckoutButton={true}
          checkoutButton={
            <Link
              to="/upload-item/start"
              className="bg-temp-green-500 bg-gradient rounded-lg py-3 px-4 text-white font-semibold focus:outline-none text-xs"
            >
              Create New Item
            </Link>
          }
        />
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  items: state.items.items,
  pagination: state.items.pagination,
});

export default (connect(mapStateToProps, actions)(LenderItems));
