import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, updateDoc } from "firebase/firestore";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import store from "store";

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const messaging = getMessaging(firebaseApp);
const db = getFirestore(firebaseApp);

export const firebaseSignInUser = async (user, userData) => {
  try {
    const userDocRef = doc(db, "users", userData._id);
    await setDoc(userDocRef, {
      userId: userData._id,
      firstName: userData.firstname,
      lastName: userData.lastname,
      accountCreatedAt: userData.createdAt,
      profilePicture: userData.image,
      userType: userData.verified_lender ? "lender" : "borrower",
      createdAt: new Date(),
      isOnline: true,
    });

    // successful
    const loggedInUser = {
      userId: userData._id,
      firstName: userData.firstname,
      lastName: userData.lastname,
      email: user.email,
    };
    store.set("tempOwnfiReBaseUserAuth", JSON.stringify(loggedInUser));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const firebaseSignOutUser = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      isOnline: false,
    });
    // successfully
    store.remove("tempOwnfiReBaseUserAuth");
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// export const firebaseNotificationGetToken = () => {
//   //Realtime Notification Configuration
//   return new Promise((resolve, reject) => {
//     messaging
//       .requestPermission()
//       .then(() =>
//         messaging.getToken({
//           vapidKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_APIKEY,
//         })
//       )
//       .then((currentToken) => {
//         resolve(currentToken);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const onMessageListener = () => {
//   //Realtime Notification Configuration
//   return new Promise((resolve) => {
//     onMessage((payload) => {
//       resolve(payload);
//     });
//   });
// };

export const firebaseNotificationGetToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_APIKEY,
  });
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
