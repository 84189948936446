import React from "react";
import { Transition } from "@headlessui/react";
import CheckBoxGroup from "../../../components/element/CheckBoxGroup";

const ConfirmReturnModal = ({
  show,
  handleCloseModal,
  selectedImage,
  isItemInGoodCondition,
  errors,
  handleProfilePictureUpload,
  handleDeleteImage,
  handleChangeItemInGoodCondition,
  loadingProcess,
  handleProcessConfirmReturn,
}) => {
  return (
    <div className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={show}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-5 py-5 sm:p-12">
            <div className="">
              <div className="pb-3 w-full mx-auto"></div>
              <div className="">
                <h3
                  className="text-center leading-6 text-xl md:text-2xl font-medium text-temp-secondary"
                  id="modal-headline"
                >
                  Confirm Return
                </h3>
                <p className="text-temp-gray text-sm pt-4 text-center">
                  Please confirm that the item has been returned.
                </p>
                <div className="mt-10">
                  <form onSubmit={handleProcessConfirmReturn}>
                    {selectedImage ? (
                      <>
                        <div
                          className="w-full h-64 bg-temp-green-100 rounded-xl mb-6"
                          style={{
                            backgroundImage: `url(${selectedImage})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: selectedImage && "cover",
                          }}
                        ></div>

                        <div className="flex justify-end items-center flex-row mb-3">
                          <button
                            type="button"
                            onClick={handleDeleteImage}
                            className="px-3 py-2 text-red-600 bg-red-100 rounded-md text-xs mb-3 focus:outline-none"
                          >
                            Delete Image
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="w-full mb-6">
                        <button
                          type="button"
                          className="focus:outline-none text-sm-15 inline-flex justify-between items-center border border-temp-gray-light w-full rounded-xl py-4 px-6"
                          onClick={handleProfilePictureUpload}
                        >
                          <span className="text-temp-primary">
                            Take picture of Item
                          </span>
                          <input
                            type="file"
                            hidden
                            id="avatar_file"
                            accept="image/*"
                            capture="user"
                          />

                          <span className="text-temp-green-500 bg-temp-green-200 px-3 py-1 rounded-md text-xs">
                            Browse
                          </span>
                        </button>
                        <p className="text-temp-gray text-sm pt-6">
                          Image should not be more than 1MB
                        </p>
                      </div>
                    )}

                    <div className="w-full">
                      <CheckBoxGroup
                        label={
                          <span className="text-temp-green-500 text-xs pl-8">
                            Item was returned in good condition
                          </span>
                        }
                        name="isAgreed"
                        value={isItemInGoodCondition}
                        onChange={handleChangeItemInGoodCondition}
                        checked={isItemInGoodCondition ? true : false}
                        error={errors.isItemInGoodCondition}
                      />
                    </div>

                    <div className="flex items-baseline justify-end flex-end">
                      <button
                        onClick={handleCloseModal}
                        className="text-sm font-bold text-temp-green-500 mr-8 mb-4 focus:outline-none"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={loadingProcess}
                        className={`mt-5 mb-4 py-3 px-8 text-sm bg-temp-green-500 bg-gradient font-bold text-white rounded-lg focus:outline-none ${loadingProcess && "opacity-70"
                          }`}
                      >
                        {loadingProcess ? "Processing ..." : "Confirm"}
                      </button>
                    </div>

                    <div className="text-red-600 text-sm pt-2 text-center">
                      {errors.selectedImage}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmReturnModal;
