import React from "react";
import NextButton from "./componentsBit/NextButton";
import PreviousButton from "./componentsBit/PreviousButton";
import SelectButton from "./componentsBit/SelectButton";
import FixedBottomPrevNextButton from "./componentsBit/FixedBottomPrevNextButton";

const OperatorForm = ({
    handleHasOperator,
    initialState,
    handleChangeCurrentStep,
}) => {
    const { hasOperator } = initialState;
    return (
        <div className="flex justify-around xl:pr-20">
            <div className="w-4/12 hidden xl:block">
                <PreviousButton onClick={() => handleChangeCurrentStep("Step0")} />
            </div>
            <div className="relative w-full mx-auto md:w-10/12 lg:w-8/12 xl:w-8/12  mb-44 xl:mb-20">
                <h2 className="text-xl sm:text-2xl text-temp-primary font-medium">
                    Does your equipment come with an Operator?
                </h2>
                <p className="mt-4 text-sm-15 lg:text-base text-temp-secondary">
                    Kindly indicate if this item(s) has an Operator inclusive
                </p>

                <div className="mt-14 grid grid-cols-2 gap-6">
                    <SelectButton
                        selected={hasOperator === true}
                        handleClick={() => handleHasOperator(true)}
                        content={<p className="whitespace-nowrap">Yes</p>}
                    />
                    <SelectButton
                        selected={hasOperator === false}
                        handleClick={() => handleHasOperator(false)}
                        content={<p className="whitespace-nowrap">No</p>}
                    />
                </div>

                <div className="hidden xl:inline-flex group w-full flex-row flex-wrap justify-end text-right mt-14 mb-28">
                    <NextButton
                        disabled={hasOperator === null}
                        onClick={() => handleChangeCurrentStep("Step2")}
                        title="Next"
                    />
                </div>
            </div>

            <FixedBottomPrevNextButton
                handlePrevClick={() => handleChangeCurrentStep("Step0")}
                disabled={hasOperator === null}
                handleNextClick={() => handleChangeCurrentStep("Step2")}
                title="Next"
            />
        </div>
    );
};

export default OperatorForm;
