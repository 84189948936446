import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainContainer from "../../components/layout/MainContainer";
import BrowseCategoriesSection from "./components/BrowseCategoriesSection";
import GetUpdateSection from "./components/GetUpdateSection";
import PopularItemsSection from "./components/PopularItemsSection";
import RecommendedSection from "./components/RecommendedSection";
import TestimonialSection from "./components/TestimonialSection";
import TopSection from "./components/TopSection";
import RentalTimer from "./components/RentalTimer";
import * as actions from "../../store/actions";
import { scrollToTop } from "../../utils/scrollToTop";
import WelcomeModal from "../../components/modals/WelcomeModal"
import configureStore from "../../store";
import Actions from "../../store/actions/actionTypes";
import WhatWeAreDoingSection from "./components/WhatWeAreDoingSection";
import ExploreByCity from "./components/ExploreByCity";
import CTASliderSection from "./components/CTASliderSection"

const Home = (props) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [itemsLoading, setItemsLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    scrollToTop();
    fetchData();

    fetchItem({
      limit: 12,
    });
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await props.getCategoriesApi();
    setLoading(false);
  };

  const fetchItem = async (params) => {
    setItemsLoading(true);
    await props.getItemsApi(params);

    setItemsLoading(false);
  };

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  useEffect(() => {
    setCategories(props?.categories);
  }, [props?.categories]);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    const store = configureStore();
    store.dispatch({
      type: Actions.VERIFY_PHONE_NUMBER_SUCCESSFUL,
      payload: false
    })
    setShowModal(false);
  }

  useEffect(() => {
    setShowModal(props?.completePhoneNumberVerification ? true : false)
  }, [props?.completePhoneNumberVerification]);



  return (
    <MainContainer hasMainContainer={false}>

      <RentalTimer rentalDetails={false} show={false} />

      <TopSection />

      <BrowseCategoriesSection loading={loading} categories={categories} />

      <PopularItemsSection items={items} itemsLoading={itemsLoading} />

      <RecommendedSection items={items} itemsLoading={itemsLoading} />

      <ExploreByCity />

      <CTASliderSection />

      <WhatWeAreDoingSection />

      <TestimonialSection />

      <GetUpdateSection />

      {
        showModal && <WelcomeModal show={showModal} handleCloseModal={handleClose} />
      }
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    items: state.items.items,
    alert: state.alert,
    completePhoneNumberVerification: state.auth.completePhoneNumberVerification
  };
};

export default connect(mapStateToProps, actions)(Home);
