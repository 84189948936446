import Actions from "../actions/actionTypes";

const initialState = {
  addresses: [],
  createAddressStatus: {
    type: "",
    message: "",
  },
  updateAddressStatus: {
    type: "",
    message: "",
  },
  deleteAddressStatus: {
    type: "",
    message: "",
  },
};

const AddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };

    case Actions.CREATE_ADDRESS:
      return {
        ...state,
        createAddressStatus: action.payload,
      };

    case Actions.UPDATE_ADDRESS:
      return {
        ...state,
        updateAddressStatus: action.payload,
        addresses:
          action.payload.type === "success"
            ? state.addresses.map((a) =>
                a._id === action.payload?.id ? action.payload?.data : a
              )
            : state.addresses,
      };

    case Actions.DELETE_ADDRESS:
      return {
        ...state,
        deleteAddressStatus: action.payload,
        addresses:
          action.payload.type === "success"
            ? state.addresses.filter((a) => a._id !== action.payload?.id)
            : state.addresses,
      };

    default:
      return state;
  }
};

export default AddressReducer;
