import React from "react";

const ReferContent = () => {
  return (
    <div className="text-sm lg:text-base">
      <p>
        Every time you refer a friend to TempOwn, you earn{" "}
        <span className="text-temp-primary font-bold">&#8358;100</span>. All you
        have to do is share your unique referral link and make sure the person
        you referred signs up to TempOwn through that link.
      </p>
      <p className="mt-4">
        The more friends you refer, the more money comes your way!
      </p>

      <p className="mt-4">
        <span className="text-temp-primary font-bold">
          Just 2 requirements:
        </span>
        <ul className="list-disc ml-4">
          <li className="mt-1">You must have completed your KYC verification.</li>
          <li className="mt-1">
            The user referred must be a vendor and not a borrower i.e  the user must have
            listed an item on TempOwn marketplace.
          </li>
        </ul>
      </p>

      <p className="mt-5 text-temp-green-500 ">
        Keep Refering, Keep Earning
      </p>
    </div>
  );
};

export default ReferContent;
